/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo } from "react";
import { Drawer, Button, Table, Tag, Select, Form } from "antd";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

const ORDERING_ITEM_REPLACEMENT_REQUEST = gql`
  mutation orderItemReplacementRequest($input: [OrderItemReplacementInput]) {
    orderItemReplacementRequest(input: $input)
  }
`;

const ChangeEquipmentDrawer = (props) => {
  const { selectedOrderingItems, onClose } = props;

  const availableEquipment = useMemo(
    () =>
      selectedOrderingItems.filter(
        (item) =>
          item?.product?.leasableQuantity > 0 ||
          item?.product?.washingQuantity > 0
      ),
    [selectedOrderingItems]
  );

  const {
    register,
    setValue,
    errors,
    handleSubmit,
    watch,
    trigger,
  } = useForm();

  const [onReplacementRequest, { data, loading }] = useMutation(
    ORDERING_ITEM_REPLACEMENT_REQUEST
  );

  const onSubmit = useCallback(
    async (data) => {
      const input = availableEquipment?.map((item) => ({
        orderingItemId: item?.id,
        freeReplacement: data?.[item?.id] === "free",
      }));
      try {
        await onReplacementRequest({
          variables: {
            input,
          },
        });
        onClose();
      } catch (err) {
        console.error(err);
      }
    },
    [availableEquipment, onClose, onReplacementRequest]
  );

  const columns = [
    {
      title: "正在更換的器材",
      render: (text, record) =>
        record?.product?.customReferenceId ??
        record?.inventoryRecord?.[0]?.referenceId,
    },
    {
      title: "款號",
      render: (text, record) =>
        record?.product?.referenceId ??
        record?.inventoryRecord?.[0]?.product?.referenceId,
    },
    {
      title: "名稱",
      render: (text, record) =>
        record?.product?.customName ??
        record?.inventoryRecord?.[0]?.product?.productInfo?.nameChi,
    },
    {
      title: "可租用數量",
      dataIndex: "product.leasableQuantity",
      // render: (text, record) => !record?.parentId && text,
    },
    {
      title: "正在清洗數量",
      dataIndex: "product.washingQuantity",
      // render: (text, record) => !record?.parentId && text,
    },
    {
      title: "輪侯人數",
      dataIndex: "product.waitingQuantity",
      // render: (text, record) => !record?.parentId && text,
    },
    {
      title: "更換方式",
      render: (text, record) => {
        if (record?.parentId) return null;
        return (
          <div>
            {record?.product?.leasableQuantity > 0 ||
            record?.product?.washingQuantity > 0 ? (
              <Form.Item
                validateStatus={errors?.[record?.id] ? "error" : ""}
                help={errors?.[record?.id] && "請選擇更換方式"}
              >
                <Select
                  value={watch(record?.id)}
                  onChange={(value) => {
                    setValue(record?.id, value);
                  }}
                  onBlur={() => trigger(record?.id)}
                  style={{ minWidth: "100px" }}
                >
                  <option value="paid">收費更換</option>
                  <option value="free">免費更換</option>
                </Select>
              </Form.Item>
            ) : (
              <Tag color="red">未能更換</Tag>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    availableEquipment.map((equipment) =>
      register(equipment.id, { required: true })
    );
  }, [availableEquipment, register]);

  return (
    <Drawer width="50%" visible={true} title={`額外更換器材`} onClose={onClose}>
      <div>
        <Table
          dataSource={selectedOrderingItems}
          columns={columns}
          pagination={false}
          childrenColumnName="partOrderingItem"
          scroll={{x:"max-content"}}
        />
      </div>
      <Button
        style={{ marginTop: "8px" }}
        loading={loading}
        onClick={handleSubmit(onSubmit)}
        disabled={availableEquipment?.length === 0}
        type="primary"
      >
        確定
      </Button>
    </Drawer>
  );
};

ChangeEquipmentDrawer.propTypes = {
  selectedOrderingItems: PropTypes.array,
  onClose: PropTypes.func,
};

export default ChangeEquipmentDrawer;
