import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import moment from "moment";
import converter from "number-to-chinese-words";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useDispatch } from "react-redux";

// Actions
import { setIsLoadingAction } from "@actions/storeAction";

// Components
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Tabs,
  Typography,
  Upload,
} from "antd";

// Constants
import { MOMENT_FORMAT, NOTIFICATION_CONFIG } from "@utils/constants";

// Styles
import "./largeForm.scss";

// Utils
import { getTokenHeader } from "@utils/api";
import { hideHkid } from "@utils/common";
import { getFileUploadUrl } from "@utils/env";
import { isEmptyObj } from "@utils/validate";

const API = gql`
  {
    enumAssessmentMethodList {
      id
      name
    }
    enumResidenceCategoryList {
      id
      name
    }
    enumGenderList {
      id
      name
    }
    enumMaritalStatusList {
      id
      name
    }
    enumEducationLevelList {
      id
      name
    }
    enumLivingConditionList {
      id
      name
    }
    enumRegionList {
      id
      name
    }
    enumAreaList {
      id
      name
    }
    enumZoneList {
      id
      name
    }
    enumLanguageList {
      id
      name
    }
    enumReferralAgencyNameList
    enumDischargeStatusList {
      id
      name
    }
    enumCommunicationSkillList {
      id
      name
    }
    enumVisualAssessmentList {
      id
      name
    }
    enumAuditoryAssessmentList {
      id
      name
    }
    enumCognitiveAbilityAssessmentList {
      id
      name
    }
    enumMedicalRecordList {
      name
      id
    }
    enumIDALList {
      name
      id
    }
    enumFamilyMonthlyIncomeList {
      name
      id
    }
    enumLivingStatusList {
      id
      name
    }
    enumContactTypeList {
      name
      id
    }
  }
`;

const GET_USER = gql`
  query UserGet($id: ID) {
    userGet(id: $id) {
      acceptPlanOfService
      agreedDate
      approvalRemark
      approvalStatus
      area {
        id
      }
      assessmentResult {
        id
        title
        assessmentResultId
        assessmentExpectedDate
        assessmentStartDate
        assessmentMethod {
          id
          name
        }
        assessmentStatus {
          id
          name
        }
        personInCharge {
          displayName
        }
        remark
      }
      buildingName
      contactPerson {
        name
        relationship
        telephone
        whatsappNumber
        livingStatus {
          id
        }
        otherLivingStatus
        contactType {
          id
        }
      }
      dateOfBirth
      economicStatus {
        familyMonthlyIncome {
          id
        }
        cssaFileNum
      }
      elevatorFailToReachFloor
      elevatorToReachFloor
      estateName
      educationLevel {
        id
      }
      flat
      floor
      healthStatus {
        medicalRecord {
          id
        }
        otherHealthStatus
        idal {
          id
        }
        communicationSkill {
          id
        }
        visualLeft {
          id
        }
        visualRight {
          id
        }
        auditoryLeft {
          id
        }
        hearingAidLeft
        auditoryRight {
          id
        }
        hearingAidRight
        cognitiveAbility {
          id
        }
        otherCognitiveAbility
      }
      hkid
      gender {
        id
      }
      language {
        id
      }
      livingCondition {
        id
      }
      livingRelation
      mailBilling
      masterAgreement {
        key
        url
      }
      maritalStatus {
        id
      }
      nameChi
      nameEng
      numOfLivingTogether
      otherLanguage
      personInCharge {
        tbId
        displayName
      }
      prescription {
        id
        name
        datetime
        personInCharge {
          displayName
        }
        relatedAssessment {
          id
          assessmentStartDate
        }
      }
      referralInfo {
        referralAgencyName
        referralAgencyDepartment
        referralAgencyFax
        referralAgencyTelephone
        referrerName
        referrerPosition
        referrerTelephone
        referrerEmail
        referralDate
        dischargeStatus {
          id
        }
        dischargeDate
        referenceId
        reason
      }
      region {
        id
      }
      relationshipToApplicant
      residenceCategory {
        id
      }
      telephone
      userId
      username
      zone {
        id
      }
    }
  }
`;

function DemoLargeFormPage(props, ref) {
  const dispatch = useDispatch();

  notification.config(NOTIFICATION_CONFIG);

  const ID = "ID-0-pending";
  // const ID = 'a9f4ea75-68f1-4d27-bde8-9ae711a7b0db';
  const MODE = "create";
  const IS_READ_ONLY = MODE === "view";

  const NUMBER_OF_CONTACT_PERSON = 3;
  const formRef = useRef(null);

  const {
    clearError,
    control,
    errors,
    getValues,
    handleSubmit,
    register,
    setValue,
    triggerValidation,
    watch,
  } = useForm({
    reValidateMode: "onSubmit",
  });

  const [isUploading, setIsUploading] = useState(false);
  const [isPreviewShow, setIsPreviewShow] = useState(false);
  const [isShowHkid, setIsShowHkid] = useState(false);
  const [previewImage, setPreviewImage] = useState(undefined);

  const { loading, error, data } = useQuery(API);
  const [
    getUser,
    { loading: getUserLoading, error: getUserError, data: getUserData },
  ] = useLazyQuery(GET_USER, {
    variables: { id: ID },
  });

  // Changed
  useEffect(() => {
    register({ name: "referralForm" });

    // eslint-disable-next-line
  }, []);

  const contactPerson = () => {
    const contactPersonForm = [];
    for (let i = 1; i <= NUMBER_OF_CONTACT_PERSON; i++) {
      contactPersonForm.push(
        <div className="section" key={i}>
          <div className="section-title">
            緊急聯絡人 ({converter.toWords(i)})
          </div>
          <Row gutter={[16, 16]} type="flex">
            <Col xs={6}>
              <Form.Item>
                <span>姓名</span>
                <Controller
                  as={<Input />}
                  className={errors[`contactPerson${i}Name`] ? "error" : null}
                  control={control}
                  disabled={IS_READ_ONLY}
                  name={`contactPerson${i}Name`}
                  rules={{ required: i === 1 ? "請輸入姓名" : false }}
                />
                <ErrorMessage
                  as={<Typography.Text type="danger" />}
                  errors={errors}
                  name={`contactPerson${i}Name`}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item>
                <span>與申請者關係</span>
                <Controller
                  as={<Input />}
                  className={
                    errors[`contactPerson${i}Relationship`] ? "error" : null
                  }
                  control={control}
                  disabled={IS_READ_ONLY}
                  name={`contactPerson${i}Relationship`}
                  rules={{ required: i === 1 ? "請輸入與申請者關係" : false }}
                />
                <ErrorMessage
                  as={<Typography.Text type="danger" />}
                  errors={errors}
                  name={`contactPerson${i}Relationship`}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item>
                <span>聯絡電話</span>
                <Controller
                  as={<Input />}
                  className={
                    errors[`contactPerson${i}Telephone`] ? "error" : null
                  }
                  control={control}
                  disabled={IS_READ_ONLY}
                  name={`contactPerson${i}Telephone`}
                  rules={{ required: i === 1 ? "請輸入聯絡電話" : false }}
                />
                <ErrorMessage
                  as={<Typography.Text type="danger" />}
                  errors={errors}
                  name={`contactPerson${i}Telephone`}
                />
              </Form.Item>
            </Col>
            <Col className="align-self-end" xs={6}>
              <Form.Item>
                <Controller
                  as={<Checkbox>選擇加入WhatsApp號碼</Checkbox>}
                  control={control}
                  disabled={IS_READ_ONLY}
                  name={`contactPerson${i}WhatsappOptIn`}
                  valueName={"checked"}
                  defaultValue={false}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item>
                <span>交往狀況</span>
                <Controller
                  as={
                    <Select>
                      {data?.enumLivingStatusList.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                  className={
                    errors[`contactPerson${i}LivingStatus`] ? "error" : null
                  }
                  control={control}
                  disabled={IS_READ_ONLY}
                  mode="multiple"
                  name={`contactPerson${i}LivingStatus`}
                  rules={{ required: i === 1 ? "請選擇交往狀況" : false }}
                />
                <ErrorMessage
                  as={<Typography.Text type="danger" />}
                  errors={errors}
                  name={`contactPerson${i}LivingStatus`}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item>
                <span>聯絡人其他交往狀況</span>
                <Controller
                  as={<Input />}
                  control={control}
                  disabled={IS_READ_ONLY}
                  name={`contactPerson${i}OtherLivingStatus`}
                />
              </Form.Item>
              <Form.Item>
                <Controller
                  control={control}
                  name={`contactPerson${i}OtherLivingStatus`}
                  render={({ value, onChange }) => {
                    return <Input value={value} onChange={onChange} />;
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item>
                <span>保證人 / 聯絡人</span>
                <Controller
                  as={
                    <Select>
                      {data?.enumContactTypeList.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                  className={
                    errors[`contactPerson${i}ContactType`] ? "error" : null
                  }
                  control={control}
                  disabled={IS_READ_ONLY}
                  name={`contactPerson${i}ContactType`}
                  rules={{
                    required: i === 1 ? "請選擇保證人 / 聯絡人身份" : false,
                  }}
                />
                <ErrorMessage
                  as={<Typography.Text type="danger" />}
                  errors={errors}
                  name={`contactPerson${i}ContactType`}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      );
    }

    return <>{contactPersonForm}</>;
  };

  // Pending
  const onSubmit = async (formData, evt) => {
    console.table(formData, errors);
    console.log(evt);
    // if (!isEmptyObj(errors)) return;
    //
    // let contactPersons = [];
    //
    // for (let i = 1; i <= NUMBER_OF_CONTACT_PERSON; i++) {
    //     const contactPerson = {
    //         name: data[`contactPerson${i}Name`],
    //         relationship: data[`contactPerson${i}Relationship`],
    //         telephone: data[`contactPerson${i}Telephone`],
    //         whatsappNumber: data[`contactPerson${i}WhatsappOptIn`],
    //         livingStatus: data[`contactPerson${i}LivingStatus`],
    //         otherLivingStatus: data[`contactPerson${i}OtherLivingStatus`],
    //         contactType: data[`contactPerson${i}ContactType`],
    //     };
    //     contactPersons.push(contactPerson);
    // }
    //
    // // Dummy data in ~/dummay_data/userCreate-input.json for testing in GraphQL
    // const input = {
    //     masterAgreement: {
    //         key: isEmpty(data.referralForm) ? undefined : data.referralForm[0].key
    //     },
    //     nameChi: data.chineseName,
    //     nameEng: data.englishName,
    //     gender: data.gender,
    //     dateOfBirth: data.dob.format('YYYY-MM-DD'),
    //     hkid: data.hkid,
    //     maritalStatus: data.maritalStatus,
    //     educationLevel: data.eduLevel,
    //     livingCondition: data.livingConditions,
    //     livingRelation: data.livingRelation,
    //     numOfLivingTogether: data.numberOfLive,
    //     acceptPlanOfService: data.acceptService,
    //     relationshipToApplicant: data.relationshipToApplicant,
    //     agreedDate: data.agreedDate.format('YYYY-MM-DD'),
    //     residenceCategory: data.residenceCategory,
    //     region: data.region,
    //     area: data.area,
    //     zone: data.zone,
    //     estateName: data.estateName,
    //     buildingName: data.buildingName,
    //     floor: data.floor,
    //     flat: data.flat,
    //     mailBilling: data.billByMail,
    //     telephone: [data.telephonePrimary, data.telephoneSecondary],
    //     elevatorFailToReachFloor: data.elevatorFailToReachFloor,
    //     elevatorToReachFloor: !data.elevatorToReachFloor,
    //     language: data.language,
    //     otherLanguage: data.otherLanguage,
    //     referralInfo: {
    //         referralAgencyName: data.referralAgencyName,
    //         referralAgencyDepartment: data.referralAgencyDepartment,
    //         referralAgencyFax: data.referralAgencyFax,
    //         referralAgencyTelephone: data.referralAgencyTelephone,
    //         referrerName: data.referrerName,
    //         referrerPosition: data.referrerPosition,
    //         referrerTelephone: data.referrerTelephone,
    //         referrerEmail: data.referrerEmail,
    //         referralDate: data.referralDate ? data.referralDate.format('YYYY-MM-DD') : undefined,
    //         dischargeStatus: data.dischargeStatus,
    //         dischargeDate: data.dischargeDate ? data.dischargeDate.format('YYYY-MM-DD') : undefined,
    //         reason: data.reason,
    //     },
    //     healthStatus: {
    //         medicalRecord: data.medicalRecord,
    //         otherHealthStatus: data.otherHealthStatus,
    //         idal: data.idal,
    //         communicationSkill: data.communicationSkill,
    //         visualLeft: data.visualLeft,
    //         visualRight: data.visualRight,
    //         auditoryLeft: data.auditoryLeft,
    //         hearingAidLeft: data.hearingAidLeft,
    //         auditoryRight: data.auditoryRight,
    //         hearingAidRight: data.hearingAidRight,
    //         cognitiveAbility: data.cognitiveAbility,
    //         otherCognitiveAbility: data.otherCognitiveAbility,
    //     },
    //     economicStatus: {
    //         familyMonthlyIncome: data.familyMonthlyIncome,
    //         cssaFileNum: data.cssaFileNum,
    //     },
    //     contactPerson: contactPersons,
    //     approvalRemark: data.remark,
    // };
    //
    // if (MODE === 'create') {
    //     userCreate({
    //         variables: {
    //             input: input
    //         }
    //     });
    // } else {
    //     userUpdate({
    //         variables: {
    //             input: {
    //                 userId: getUserData.userGet.userId,
    //                 ...input
    //             }
    //         }
    //     });
    // }
    //
    // props.changeMode('view');
  };

  // Not Change
  useEffect(() => {
    dispatch(setIsLoadingAction(loading));
    if (data) {
      if (ID) {
        getUser();
      }
    }

    // eslint-disable-next-line
  }, [loading, error, data]);
  useEffect(() => {
    dispatch(setIsLoadingAction(getUserLoading));

    if (!getUserLoading && getUserData) {
      console.log(getUserData.userGet);
      setValue(
        "personInCharge",
        getUserData.userGet.personInCharge?.displayName
      );
      setValue("remark", getUserData.userGet.approvalRemark);

      setValue("referralForm", [
        { uid: 1, ...getUserData.userGet.masterAgreement },
      ]);
      setValue("chineseName", getUserData.userGet.nameChi);
      setValue("englishName", getUserData.userGet.nameEng);
      setValue("gender", getUserData.userGet.gender?.id);
      setValue("dob", moment(getUserData.userGet.dateOfBirth));
      setValue("age", moment().diff(getUserData.userGet.dateOfBirth, "years"));
      setValue("hkid", getUserData.userGet.hkid);
      setValue("maritalStatus", getUserData.userGet.maritalStatus?.id);
      setValue("eduLevel", getUserData.userGet.educationLevel?.id);
      setValue("livingConditions", getUserData.userGet.livingCondition?.id);
      setValue("livingRelation", getUserData.userGet.livingRelation);
      setValue("numberOfLive", getUserData.userGet.numOfLivingTogether);
      setValue("acceptService", getUserData.userGet.acceptPlanOfService);
      setValue(
        "relationshipToApplicant",
        getUserData.userGet.relationshipToApplicant
      );
      setValue("agreedDate", moment(getUserData.userGet.agreedDate));

      setValue("residenceCategory", getUserData.userGet.residenceCategory?.id);
      setValue("region", getUserData.userGet.region?.id);
      setValue("area", getUserData.userGet.area?.id);
      setValue("zone", getUserData.userGet.zone?.id);
      setValue("estateName", getUserData.userGet.estateName);
      setValue("buildingName", getUserData.userGet.buildingName);
      setValue("floor", getUserData.userGet.floor);
      setValue("flat", getUserData.userGet.flat);
      setValue("billByMail", getUserData.userGet.mailBilling);
      setValue("telephonePrimary", getUserData.userGet.telephone[0]);
      setValue("telephoneSecondary", getUserData.userGet.telephone[1]);
      setValue(
        "elevatorFailToReachFloor",
        getUserData.userGet.elevatorFailToReachFloor
      );
      setValue(
        "elevatorToReachFloor",
        !getUserData.userGet.elevatorToReachFloor
      );
      setValue(
        "language",
        getUserData.userGet.language
          ? getUserData.userGet.language.map((l) => l.id)
          : []
      );
      setValue("otherLanguage", getUserData.userGet.otherLanguage);

      setValue(
        "referralAgencyName",
        getUserData.userGet.referralInfo.referralAgencyName
      );
      setValue(
        "referralAgencyDepartment",
        getUserData.userGet.referralInfo.referralAgencyDepartment
      );
      setValue(
        "referralAgencyFax",
        getUserData.userGet.referralInfo.referralAgencyFax
      );
      setValue(
        "referralAgencyTelephone",
        getUserData.userGet.referralInfo.referralAgencyTelephone
      );

      setValue("referrerName", getUserData.userGet.referralInfo.referrerName);
      setValue(
        "referrerPosition",
        getUserData.userGet.referralInfo.referrerPosition
      );
      setValue(
        "referrerTelephone",
        getUserData.userGet.referralInfo.referrerTelephone
      );
      setValue("referrerEmail", getUserData.userGet.referralInfo.referrerEmail);
      setValue(
        "referralDate",
        getUserData.userGet.referralInfo.referralDate
          ? moment(getUserData.userGet.referralInfo.referralDate)
          : null
      );
      setValue(
        "dischargeStatus",
        getUserData.userGet.referralInfo.dischargeStatus?.id
      );
      setValue(
        "dischargeDate",
        getUserData.userGet.referralInfo.dischargeDate
          ? moment(getUserData.userGet.referralInfo.dischargeDate)
          : null
      );
      setValue("reason", getUserData.userGet.referralInfo.reason);

      setValue(
        "medicalRecord",
        getUserData.userGet.healthStatus.medicalRecord
          ? getUserData.userGet.healthStatus.medicalRecord.reduce(
              (acc, curr) => [...acc, curr.id],
              []
            )
          : []
      );
      setValue(
        "otherHealthStatus",
        getUserData.userGet.healthStatus.otherHealthStatus
      );
      setValue(
        "idal",
        getUserData.userGet.healthStatus.idal
          ? getUserData.userGet.healthStatus.idal.reduce(
              (acc, curr) => [...acc, curr.id],
              []
            )
          : []
      );
      setValue(
        "communicationSkill",
        getUserData.userGet.healthStatus.communicationSkill?.id
      );
      setValue("visualLeft", getUserData.userGet.healthStatus.visualLeft?.id);
      setValue("visualRight", getUserData.userGet.healthStatus.visualRight?.id);
      setValue(
        "auditoryLeft",
        getUserData.userGet.healthStatus.auditoryLeft?.id
      );
      setValue(
        "hearingAidLeft",
        getUserData.userGet.healthStatus.hearingAidLeft
      );
      setValue(
        "auditoryRight",
        getUserData.userGet.healthStatus.auditoryRight?.id
      );
      setValue(
        "hearingAidRight",
        getUserData.userGet.healthStatus.hearingAidRight
      );
      setValue(
        "cognitiveAbility",
        getUserData.userGet.healthStatus.cognitiveAbility?.id
      );
      setValue(
        "otherCognitiveAbility",
        getUserData.userGet.healthStatus.otherCognitiveAbility
      );

      setValue(
        "familyMonthlyIncome",
        getUserData.userGet.economicStatus.familyMonthlyIncome?.id
      );
      setValue("cssaFileNum", getUserData.userGet.economicStatus.cssaFileNum);

      for (let i = 0; i <= getUserData.userGet.contactPerson.length - 1; i++) {
        setValue(
          `contactPerson${i + 1}Name`,
          getUserData.userGet.contactPerson[i].name
        );
        setValue(
          `contactPerson${i + 1}Relationship`,
          getUserData.userGet.contactPerson[i].relationship
        );
        setValue(
          `contactPerson${i + 1}Telephone`,
          getUserData.userGet.contactPerson[i].telephone
        );
        setValue(
          `contactPerson${i + 1}WhatsappOptIn`,
          getUserData.userGet.contactPerson[i].whatsappNumber
        );
        setValue(
          `contactPerson${i + 1}LivingStatus`,
          getUserData.userGet.contactPerson[i].livingStatus
            ? getUserData.userGet.contactPerson[i].livingStatus.reduce(
                (acc, curr) => [...acc, curr.id],
                []
              )
            : []
        );
        setValue(
          `contactPerson${i + 1}OtherLivingStatus`,
          getUserData.userGet.contactPerson[i].otherLivingStatus
        );
        setValue(
          `contactPerson${i + 1}ContactType`,
          getUserData.userGet.contactPerson[i].contactType?.id
        );
      }
    }

    // eslint-disable-next-line
  }, [getUserLoading, getUserError, getUserData]);
  useEffect(() => {
    if (!isEmptyObj(errors)) {
      notification.error({
        message: "資料有遺漏，請重試",
      });
    }
  }, [errors]);
  const filterOption = (inputValue, option) => {
    if (option.props.children.includes(inputValue)) {
      return option;
    }
  };
  const onVisibleClick = () => setIsShowHkid(!isShowHkid);
  const onFileChange = ({ fileList }) => {
    let fl = [...fileList];

    fl = fl.slice(-1);

    fl = fl.map((file) => {
      if (file.error) {
        notification.error({
          message: "上載失敗",
        });
        setIsUploading(false);
      } else if (file?.status === "uploading") {
        setIsUploading(true);
      } else if (file.response) {
        file.name = file.response.data.name;
        file.size = file.response.data.size;
        file.key = file.response.data.key;
        file.url = file.response.data.url;
        setIsUploading(false);
      }
      return file;
    });

    fl = fl.filter((file) => !file.error);

    setValue("referralForm", fl);
  };
  const onFileRemove = (file) => {
    setValue("referralForm", []);
  };
  const onFilePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      })(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setIsPreviewShow(true);
  };
  const onFilePreviewCancel = () => setIsPreviewShow(false);

  return (
    <Tabs className="Demo-large-form-page" defaultActiveKey="info">
      <Tabs.TabPane className="info" tab="申請人資料" key="info">
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="user-info-form"
          ref={formRef}
        >
          {MODE !== "create" && (
            <div className="section">
              <Row gutter={[16, 16]} type="flex">
                <Col xs={6}>
                  <Form.Item>
                    <span>審批負責人</span>
                    <Controller
                      as={<Input />}
                      disabled={true}
                      control={control}
                      name={"personInCharge"}
                    />
                  </Form.Item>
                </Col>
                <Col className="align-self-end" xs={18}>
                  <Form.Item>
                    <span>備註</span>
                    <Controller
                      as={<Input.TextArea />}
                      disabled={IS_READ_ONLY}
                      control={control}
                      name="remark"
                      rows={4}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}
          <Row className="section" gutter={[16, 16]} type="flex">
            <Col className="referral-form" xs={24}>
              <Upload
                name="file"
                className="referral-form-uploader"
                action={getFileUploadUrl()}
                disabled={isUploading || IS_READ_ONLY}
                fileList={watch("referralForm")}
                headers={{
                  "x-token": getTokenHeader(),
                  "X-Requested-With": null,
                }}
                onChange={onFileChange}
                onPreview={onFilePreview}
                onRemove={isUploading || IS_READ_ONLY ? null : onFileRemove}
                showUploadList={{ showDownloadIcon: false }}
                listType="picture-card"
              >
                <Button disabled={isUploading || IS_READ_ONLY}>
                  <Icon type="upload" /> 上載轉介/登記表
                </Button>
              </Upload>
              <Modal
                visible={isPreviewShow}
                footer={null}
                onCancel={onFilePreviewCancel}
              >
                <img
                  alt="轉介 / 登記表"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </Col>
            <Col xs={24}>
              <Row gutter={[16, 16]} type="flex">
                <Col xs={6}>
                  <Form.Item>
                    <span>中文姓名</span>
                    <Controller
                      as={<Input />}
                      className={errors.chineseName ? "error" : null}
                      disabled={IS_READ_ONLY}
                      control={control}
                      rules={{ required: "請輸入中文姓名" }}
                      name={"chineseName"}
                    />
                    <ErrorMessage
                      as={<Typography.Text type="danger" />}
                      errors={errors}
                      name="chineseName"
                    />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item>
                    <span>英文姓名</span>
                    <Controller
                      as={<Input />}
                      className={errors.englishName ? "error" : null}
                      disabled={IS_READ_ONLY}
                      control={control}
                      rules={{ required: "請輸入英文姓名" }}
                      name={"englishName"}
                    />
                    <ErrorMessage
                      as={<Typography.Text type="danger" />}
                      errors={errors}
                      name="englishName"
                    />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item>
                    <span>性別</span>
                    <Controller
                      as={
                        <Select>
                          {data?.enumGenderList.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      }
                      className={errors.gender ? "error" : null}
                      disabled={IS_READ_ONLY}
                      control={control}
                      rules={{ required: "請選擇性別" }}
                      name={"gender"}
                    />
                    <ErrorMessage
                      as={<Typography.Text type="danger" />}
                      errors={errors}
                      name="gender"
                    />
                  </Form.Item>
                </Col>
                <Col className="dob" xs={6}>
                  <Form.Item>
                    <span>出生日期</span>
                    <div id="xxx" style={{ position: "relative" }}>
                      <Controller
                        as={<DatePicker />}
                        className={errors.dob ? "error" : null}
                        disabled={IS_READ_ONLY}
                        format={MOMENT_FORMAT}
                        control={control}
                        name="dob"
                        placeholder={undefined}
                        rules={{ required: "請輸入出生日期" }}
                        onChange={([date]) => {
                          if (date) {
                            setValue("age", moment().diff(date, "years"));
                          } else {
                            setValue("age", undefined);
                          }
                          return date;
                        }}
                      />
                    </div>
                    <ErrorMessage
                      as={<Typography.Text type="danger" />}
                      errors={errors}
                      name="dob"
                    />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item>
                    <span>年齡</span>
                    <Controller
                      as={<Input />}
                      control={control}
                      disabled
                      name="age"
                    />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item>
                    <span>身份証號碼</span>
                    <Controller
                      as={
                        <Input
                          onBlur={(evt) => {
                            const hkid = evt.target.value;
                            if (hkid) {
                              triggerValidation("hkid").then((isValid) => {
                                if (isValid) {
                                  setIsShowHkid(false);
                                } else {
                                  setIsShowHkid(true);
                                }
                              });
                            } else {
                              clearError("hkid");
                            }
                          }}
                          suffix={
                            <Icon
                              onClick={() => !errors.hkid && onVisibleClick}
                              style={{
                                cursor: `${
                                  errors.hkid ? "not-allowed" : "pointer"
                                }`,
                              }}
                              type={isShowHkid ? "eye-invisible" : "eye"}
                            />
                          }
                        />
                      }
                      className={`${errors.hkid ? "error" : ""} ${
                        isShowHkid ? "" : "hide"
                      }`}
                      control={control}
                      name="hkid"
                      rules={{
                        required: "請輸入身份証號碼",
                        pattern: {
                          value: /^[A-Z][0-9]{7}$/i,
                          message: "如身份証號碼為A123456(7)，請輸入A1234567",
                        },
                      }}
                    />
                    <Input
                      className={!isShowHkid ? "" : "hide"}
                      disabled
                      value={hideHkid(getValues().hkid)}
                      onClick={onVisibleClick}
                      suffix={
                        <Icon
                          onClick={onVisibleClick}
                          style={{
                            color: "unset",
                            cursor: "pointer",
                          }}
                          type={isShowHkid ? "eye-invisible" : "eye"}
                        />
                      }
                    />
                    <ErrorMessage
                      as={<Typography.Text type="danger" />}
                      errors={errors}
                      name="hkid"
                    />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item>
                    <span>婚姻狀況</span>
                    <Controller
                      as={
                        <Select>
                          {data?.enumMaritalStatusList.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      }
                      className={errors.maritalStatus ? "error" : null}
                      control={control}
                      disabled={IS_READ_ONLY}
                      name="maritalStatus"
                      rules={{ required: "請選擇婚姻狀況" }}
                    />
                    <ErrorMessage
                      as={<Typography.Text type="danger" />}
                      errors={errors}
                      name="maritalStatus"
                    />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item>
                    <span>教育程度</span>
                    <Controller
                      as={
                        <Select>
                          {data?.enumEducationLevelList.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      }
                      className={errors.eduLevel ? "error" : null}
                      control={control}
                      disabled={IS_READ_ONLY}
                      name="eduLevel"
                      rules={{ required: "請選擇教育程度" }}
                    />
                    <ErrorMessage
                      as={<Typography.Text type="danger" />}
                      errors={errors}
                      name="eduLevel"
                    />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item>
                    <span>居住狀況</span>
                    <Controller
                      as={
                        <Select>
                          {data?.enumLivingConditionList.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      }
                      className={errors.livingConditions ? "error" : null}
                      control={control}
                      disabled={IS_READ_ONLY}
                      name="livingConditions"
                      rules={{ required: "請選擇居住狀況" }}
                    />
                    <ErrorMessage
                      as={<Typography.Text type="danger" />}
                      errors={errors}
                      name="livingConditions"
                    />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item>
                    <span>同住關係</span>
                    <Controller
                      as={<Input />}
                      className={errors.livingRelation ? "error" : null}
                      control={control}
                      disabled={IS_READ_ONLY}
                      name="livingRelation"
                      rules={{ required: "請輸入同住關係" }}
                    />
                    <ErrorMessage
                      as={<Typography.Text type="danger" />}
                      errors={errors}
                      name="livingRelation"
                    />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item>
                    <span>同住人數</span>
                    <Controller
                      as={<Input />}
                      className={errors.numberOfLive ? "error" : null}
                      control={control}
                      disabled={IS_READ_ONLY}
                      min={0}
                      name="numberOfLive"
                      type="number"
                      rules={{ required: "請輸入同住人數" }}
                    />
                    <ErrorMessage
                      as={<Typography.Text type="danger" />}
                      errors={errors}
                      name="numberOfLive"
                    />
                  </Form.Item>
                </Col>
                <Col xs={14} xl={8}>
                  <Form.Item>
                    <Controller
                      as={<Checkbox>申請人/親屬同意接受本計劃的服務</Checkbox>}
                      className={errors.acceptService ? "error" : null}
                      control={control}
                      disabled={IS_READ_ONLY}
                      name="acceptService"
                      rules={{ required: "請同意接受本計劃的服務" }}
                      valueName="checked"
                      defaultValue={false}
                    />
                    <div>
                      <ErrorMessage
                        as={<Typography.Text type="danger" />}
                        errors={errors}
                        name="acceptService"
                      />
                    </div>
                    <Controller
                      as={<Input />}
                      control={control}
                      disabled={IS_READ_ONLY}
                      name="relationshipToApplicant"
                      placeholder="與申請人關係"
                    />
                  </Form.Item>
                </Col>
                <Col className="agreed-date" xs={10} xl={4}>
                  <Form.Item>
                    <span>同意日期</span>
                    <div>
                      <Controller
                        as={<DatePicker />}
                        className={errors.agreedDate ? "error" : null}
                        control={control}
                        disabled={IS_READ_ONLY}
                        format={MOMENT_FORMAT}
                        name="agreedDate"
                        placeholder={undefined}
                        rules={{ required: "請輸入同意日期" }}
                      />
                    </div>
                    <ErrorMessage
                      as={<Typography.Text type="danger" />}
                      errors={errors}
                      name="agreedDate"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="section">
            <div className="section-title">住址及聯絡方法</div>
            <Row gutter={[16, 16]} type="flex">
              <Col xs={6}>
                <Form.Item>
                  <span>類別</span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumResidenceCategoryList.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="residenceCategory"
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>區域</span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumRegionList.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="region"
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>地區</span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumAreaList.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="area"
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>地區內分區</span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumZoneList.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="zone"
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>屋苑/屋邨名稱</span>
                  <Controller
                    as={<Input />}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="estateName"
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>大廈/樓宇名稱</span>
                  <Controller
                    as={<Input />}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="buildingName"
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>樓層</span>
                  <Controller
                    as={<Input />}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="floor"
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>單位</span>
                  <Controller
                    as={<Input />}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="flat"
                  />
                </Form.Item>
              </Col>
              <Col className="align-self-end" xs={6}>
                <Form.Item>
                  <div>
                    <Controller
                      as={<Checkbox>需使用郵寄賬單方式</Checkbox>}
                      control={control}
                      disabled={IS_READ_ONLY}
                      name="billByMail"
                      valueName="checked"
                      defaultValue={false}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>聯絡電話 (主要)</span>
                  <Controller
                    as={<Input />}
                    className={errors.telephonePrimary ? "error" : null}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="telephonePrimary"
                    rules={{ required: "請輸主要聯絡電話" }}
                  />
                  <ErrorMessage
                    as={<Typography.Text type="danger" />}
                    errors={errors}
                    name="telephonePrimary"
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>聯絡電話 (次要)</span>
                  <Controller
                    as={<Input />}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="telephoneSecondary"
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>電梯未能直達樓層數量</span>
                  <Controller
                    as={<Input />}
                    control={control}
                    disabled={IS_READ_ONLY}
                    min={0}
                    name="elevatorFailToReachFloor"
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col className="align-self-end" xs={6}>
                <Form.Item>
                  <div>
                    <Controller
                      as={<Checkbox>電梯未能直達樓層</Checkbox>}
                      control={control}
                      disabled={IS_READ_ONLY}
                      name="elevatorToReachFloor"
                      valueName="checked"
                      defaultValue={false}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>能操方言</span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumLanguageList.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    control={control}
                    disabled={IS_READ_ONLY}
                    mode="multiple"
                    name="language"
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>能操其他方言</span>
                  <Controller
                    as={<Input />}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="otherLanguage"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="section">
            <div className="section-title">轉介機構</div>
            <Row gutter={[16, 16]} type="flex">
              <Col xs={6}>
                <Form.Item>
                  <span>機構名稱</span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumReferralAgencyNameList.map((option) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    className={errors.referralAgencyName ? "error" : null}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="referralAgencyName"
                    rules={{ required: "請選擇機構名稱" }}
                  />
                  <ErrorMessage
                    as={<Typography.Text type="danger" />}
                    errors={errors}
                    name="referralAgencyName"
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>轉介單位</span>
                  <Controller
                    as={<Input />}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="referralAgencyDepartmentreferralAgencyDepartment"
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>傳真號碼</span>
                  <Controller
                    as={<Input />}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="referralAgencyFax"
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>聯絡電話</span>
                  <Controller
                    as={<Input />}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="referralAgencyTelephone"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="section">
            <div className="section-title">轉介者</div>
            <Row gutter={[16, 16]} type="flex">
              <Col xs={8} lg={6}>
                <Form.Item>
                  <span>姓名</span>
                  <Controller
                    as={<Input />}
                    className={errors.referrerName ? "error" : null}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="referrerName"
                    rules={{ required: "請輸入姓名" }}
                  />
                  <ErrorMessage
                    as={<Typography.Text type="danger" />}
                    errors={errors}
                    name="referrerName"
                  />
                </Form.Item>
              </Col>
              <Col xs={8} lg={6}>
                <Form.Item>
                  <span>職位</span>
                  <Controller
                    as={<Input />}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="referrerPosition"
                  />
                </Form.Item>
              </Col>
              <Col xs={8} lg={6}>
                <Form.Item>
                  <span>聯絡電話</span>
                  <Controller
                    as={<Input />}
                    className={errors.referrerTelephone ? "error" : null}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="referrerTelephone"
                    rules={{ required: "請輸入聯絡電話" }}
                  />
                  <ErrorMessage
                    as={<Typography.Text type="danger" />}
                    errors={errors}
                    name="referrerTelephone"
                  />
                </Form.Item>
              </Col>
              <Col xs={8} lg={6}>
                <Form.Item>
                  <span>電郵地址</span>
                  <Controller
                    as={<Input />}
                    className={errors.referrerEmail ? "error" : null}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="referrerEmail"
                    rules={{
                      required: "請輸入電郵地址",
                      // pattern: {
                      //     value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      //     message: '請輸入正確格式的電郵地址'
                      // }
                    }}
                  />
                  <ErrorMessage
                    as={<Typography.Text type="danger" />}
                    errors={errors}
                    name="referrerEmail"
                  />
                </Form.Item>
              </Col>
              <Col xs={8} lg={6}>
                <Form.Item>
                  <span>轉介日期</span>
                  <div>
                    <Controller
                      as={<DatePicker />}
                      className={errors.referralDate ? "error" : null}
                      control={control}
                      disabled={IS_READ_ONLY}
                      format={MOMENT_FORMAT}
                      name="referralDate"
                      placeholder={undefined}
                      rules={{ required: "請輸轉介日期" }}
                    />
                  </div>
                  <ErrorMessage
                    as={<Typography.Text type="danger" />}
                    errors={errors}
                    name="referralDate"
                  />
                </Form.Item>
              </Col>
              <Col xs={8} lg={6}>
                <Form.Item>
                  <span>出院 / 預算出院</span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumDischargeStatusList.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="dischargeStatus"
                  />
                </Form.Item>
              </Col>
              <Col xs={8} lg={6}>
                <Form.Item>
                  <span>出院 / 預算出院日期</span>
                  <div>
                    <Controller
                      as={<DatePicker />}
                      control={control}
                      disabled={IS_READ_ONLY}
                      format={MOMENT_FORMAT}
                      name="dischargeDate"
                      placeholder={undefined}
                    />
                  </div>
                </Form.Item>
              </Col>
              {MODE !== "create" && (
                <Col xs={8} lg={6}>
                  <Form.Item>
                    <span>轉介編號</span>
                    <Controller
                      as={<Input />}
                      control={control}
                      disabled
                      name="referenceId"
                    />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24}>
                <Form.Item>
                  <span>轉介原因</span>
                  <Controller
                    as={<Input.TextArea />}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="reason"
                    rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="section">
            <div className="section-title">健康狀況</div>
            <Row gutter={[16, 16]} type="flex">
              <Col xs={12}>
                <Form.Item>
                  <span>病歷 / 健康狀況 (經醫生証實之病症)</span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumMedicalRecordList.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    control={control}
                    disabled={IS_READ_ONLY}
                    mode="multiple"
                    name="medicalRecord"
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item>
                  <span>其他病歷 / 健康狀況</span>
                  <Controller
                    as={<Input />}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="otherHealthStatus"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item>
                  <span>活動能力</span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumIDALList.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    control={control}
                    disabled={IS_READ_ONLY}
                    mode="multiple"
                    name="idal"
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Item>
                  <span>溝通能力</span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumCommunicationSkillList.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="communicationSkill"
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
              <Col xs={6} lg={4}>
                <Form.Item>
                  <span>左眼視力</span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumVisualAssessmentList.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="visualLeft"
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
              <Col xs={6} lg={4}>
                <Form.Item>
                  <span>右眼視力</span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumVisualAssessmentList.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="visualRight"
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>左耳聽力</span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumAuditoryAssessmentList.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="auditoryLeft"
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
              <Col className="align-self-end" xs={6}>
                <Form.Item>
                  <Controller
                    as={<Checkbox>需使用助聽器?</Checkbox>}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="hearingAidLeft"
                    valueName={"checked"}
                    defaultValue={false}
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item>
                  <span>右耳聽力</span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumAuditoryAssessmentList.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="auditoryRight"
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
              <Col className="align-self-end" xs={6}>
                <Form.Item>
                  <Controller
                    as={<Checkbox>需使用助聽器?</Checkbox>}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="hearingAidRight"
                    valueName={"checked"}
                    defaultValue={false}
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item>
                  <span>認知能力</span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumCognitiveAbilityAssessmentList.map(
                          (option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.name}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    }
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="cognitiveAbility"
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item>
                  <span>其他認知能力</span>
                  <Controller
                    as={<Input />}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="otherCognitiveAbility"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="section">
            <div className="section-title">經濟狀況</div>
            <Row gutter={[16, 16]} type="flex">
              <Col xs={12}>
                <Form.Item>
                  <span>
                    家庭住戶每月收入中位數
                    <br />
                    (根據社署長者社區照顧服務券試驗計劃為評估準則)
                  </span>
                  <Controller
                    as={
                      <Select>
                        {data?.enumFamilyMonthlyIncomeList.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    className={errors.familyMonthlyIncome ? "error" : null}
                    control={control}
                    disabled={IS_READ_ONLY}
                    filterOption={filterOption}
                    name="familyMonthlyIncome"
                    rules={{ required: "請選擇家庭住戶每月收入中位數" }}
                  />
                  <ErrorMessage
                    as={<Typography.Text type="danger" />}
                    errors={errors}
                    name="familyMonthlyIncome"
                  />
                </Form.Item>
              </Col>
              <Col className="align-self-end" xs={12} lg={6}>
                <Form.Item>
                  <span>綜援編號</span>
                  <Controller
                    as={<Input />}
                    control={control}
                    disabled={IS_READ_ONLY}
                    name="cssaFileNum"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          {contactPerson()}
          {MODE === "create" && (
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={isUploading}>
                儲存
              </Button>
            </Form.Item>
          )}
        </Form>
      </Tabs.TabPane>
    </Tabs>
  );
}

export default forwardRef(DemoLargeFormPage);

DemoLargeFormPage.propTypes = {
  id: PropTypes.string,
  activeTab: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
  mode: PropTypes.string.isRequired,
  changeMode: PropTypes.func,
};

DemoLargeFormPage.defaultProps = {
  id: undefined,
  changeMode: () => {},
};
