import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store";

import { ApolloProvider } from "@apollo/client";

import { defaultDataIdFromObject, InMemoryCache } from "@apollo/client/cache";
import { ApolloClient, ApolloLink, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { HashRouter as Router } from "react-router-dom";

// Components
import { ConfigProvider, notification } from "antd";

// Constants
import { NOTIFICATION_CONFIG } from "@utils/constants";

// Styles
import "./index.css";

// Utils
import { getTokenHeader, setTokenHeader } from "@utils/api";
import { getApiUrl, getOptionJsUrl, isDev } from "@utils/env";

const startApp = async (config) => {
  notification.config(NOTIFICATION_CONFIG);

  const { client } = config;
  const pjson = require("../package.json");

  setTokenHeader(client.token);

  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, path, extensions }) => {
        console.error(
          `[GraphQL error]: Code: ${extensions?.msgCode}, Message: ${message}, Path: ${path}`
        );
        notification.error({
          message: extensions?.msgCode || "Query Error",
          description: message,
          duration: 5,
        });

        return null;
      });
    }
    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  const headerLink = setContext((request, previousContext) => ({
    headers: {
      ...previousContext.headers,
      "x-token": getTokenHeader(),
    },
  }));

  const httpLink = createHttpLink({ uri: getApiUrl() });

  const link = ApolloLink.from([errorLink, headerLink, httpLink]);

  console.log("httpLink", httpLink);

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      dataIdFromObject: (object) => {
        /**
         *  https://www.apollographql.com/docs/react/caching/cache-configuration/#custom-identifiers
         *  Workaround for __typename: Assessment
         *  Reason: Query userGet -> assessmentResult -> id is using assessment reference id (i.e. AS0001, AS002 etc.)
         *  which will duplicate if the same type of assessment create again
         *  Solution: Add custom identifiers for __typename: Assessment
         */
        switch (object.__typename) {
          case "Assessment":
            return object.assessmentResultId;
          default:
            return defaultDataIdFromObject(object);
        }
      },
    }),
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
      },
    },
  });

  ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <Provider store={configureStore()}>
        <Router>
          <ConfigProvider
            getPopupContainer={(node) =>
              node ? node.parentNode : document.body
            }
          >
            <App />
          </ConfigProvider>
        </Router>
      </Provider>
    </ApolloProvider>,
    document.getElementById("root")
  );

  document.querySelector("noscript").append(`Version: ${pjson.version}`);
};

window.startApp = startApp;

if (isDev()) {
  window.onload = function () {
    window.startApp({
      client: {
        token: "",
      },
    });
  };
} else {
  const optionScript = document.createElement("script");
  optionScript.setAttribute("src", `${getOptionJsUrl()}/options.js`);
  document.querySelector("body").appendChild(optionScript);
}

serviceWorker.unregister();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
