/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Constants
import { PAGE } from "@utils/constants";

// Components
import OrderingItemsCartDrawer from "../createOrderingItemsDrawer/orderingItemsCartDrawer.jsx";

// Styles
import "./availablePartDrawer.scss";

// Utils
import {
  Button,
  Card,
  Col,
  Drawer,
  InputNumber,
  message,
  Row,
  Table,
  Typography,
  notification,
} from "antd";
import { replaceUrlByParams } from "@utils/api";
import { classList } from "@utils/common";

const DEVICE_GET = gql`
  query DeviceGet($id: ID!) {
    deviceGet(id: $id) {
      id
      leasableQuantity
      washingQuantity
      waitingQuantity
      category {
        name
        mainCategory {
          name
        }
      }
      defaultPart {
        part {
          id
          referenceId
          productInfo {
            nameEng
            nameChi
          }
          leasableQuantity
          washingQuantity
          waitingQuantity
        }
      }
      optionalPart {
        part {
          category {
            name
            mainCategory {
              name
            }
          }
          id
          referenceId
          productInfo {
            nameEng
            nameChi
          }
          leasableQuantity
          washingQuantity
          waitingQuantity
        }
      }
      defaultConsumable {
        consumable {
          id
          referenceId
          category {
            name
            mainCategory {
              name
            }
          }
          productInfo {
            nameEng
            nameChi
          }
        }
        quantity
      }
    }
  }
`;

const PART_GET = gql`
  query PartGet($id: ID!) {
    partGet(id: $id) {
      id
      defaultDevice {
        id
        waitingQuantity
        washingQuantity
        leasableQuantity
        referenceId
        category {
          name
          mainCategory {
            name
          }
        }
        productInfo {
          nameEng
          nameChi
        }
        defaultPart {
          part {
            waitingQuantity
            washingQuantity
            leasableQuantity
            id
            productInfo {
              nameEng
              nameChi
            }
          }
        }
        optionalPart {
          part {
            category {
              name
              mainCategory {
                name
              }
            }
            waitingQuantity
            washingQuantity
            leasableQuantity
            id
            productInfo {
              nameEng
              nameChi
            }
          }
        }
      }
      optionalDevice {
        id
        waitingQuantity
        washingQuantity
        leasableQuantity
        referenceId
        category {
          name
          mainCategory {
            name
          }
        }
        productInfo {
          nameEng
          nameChi
        }
        defaultPart {
          part {
            waitingQuantity
            washingQuantity
            leasableQuantity
            id
            productInfo {
              nameEng
              nameChi
            }
          }
        }
        optionalPart {
          part {
            category {
              name
              mainCategory {
                name
              }
            }
            waitingQuantity
            washingQuantity
            leasableQuantity
            id
            productInfo {
              nameEng
              nameChi
            }
          }
        }
      }
    }
  }
`;

const ORDERING_CREATE = gql`
  mutation OrderingItemBulkCreate($input: [OrderingItemCreateInput]) {
    orderingItemBulkCreate(inupt: $input) {
      id
    }
  }
`;

const AvailablePartDrawer = ({
  className,
  userId,
  id,
  orderId,
  type,
  visible,
  onClose,
  onSubmit,
}) => {
  const [orderingItemsCreate, { data: orderingItemsCreateData }] = useMutation(
    ORDERING_CREATE
  );
  const [isCartDrawerShow, setIsCartDrawerShow] = useState(false);
  const [items, setItems] = useState({});
  const [
    getDevice,
    { loading: getDeviceLoading, data: getDeviceData },
  ] = useLazyQuery(DEVICE_GET);
  const [
    getPart,
    { loading: getPartLoading, data: getPartData },
  ] = useLazyQuery(PART_GET);

  const device = useMemo(() => {
    return getDeviceData?.deviceGet;
  }, [getDeviceData]);

  const part = useMemo(() => {
    return getPartData?.partGet;
  }, [getPartData]);

  const defaultConsumable = useMemo(() => {
    return device?.defaultConsumable;
  }, [device]);

  const onCartDrawerClose = () => {
    setIsCartDrawerShow(false);
  };

  const onCartDrawerSubmit = async () => {
    const submit = Object.values(items).map((item) => {
      return {
        productId: item.id,
        parentOrderId: orderId,
        quantity: item.qty,
        userId: userId,
      };
    });
    await orderingItemsCreate({
      variables: {
        input: submit,
      },
    });

    onCartDrawerClose();
    onClose();
  };

  useEffect(() => {
    if (orderingItemsCreateData) {
      notification.success({
        message: "成功新增訂單項目",
      });
      setItems({});

      if (onSubmit) {
        onSubmit();
      }
    }

    // eslint-disable-next-line
  }, [orderingItemsCreateData]);

  const optionalPartColumns = useMemo(() => {
    return [
      {
        title: "型號",
        dataIndex: "part.part.referenceId",
        render: (text, { part }) => {
          return (
            <Link
              to={`${replaceUrlByParams(PAGE.STOCK_PART_DETAIL, {
                id: part.part.id,
              })}`}
              target="_blank"
            >
              {text}
            </Link>
          );
        },
      },
      {
        title: "名稱",
        dataIndex: "part.part.productInfo.nameChi",
      },
      {
        title: "可租用數量",
        dataIndex: "leasableQuantity",
      },
      {
        title: "正在清洗數量",
        dataIndex: "washingQuantity",
      },
      {
        title: "輪候人數",
        dataIndex: "waitingQuantity",
      },
      {
        dataIndex: "part",
        render: (part, record, index) => {
          let max = 0;
          if (
            part.__typename === "Device" ||
            part.__typename === "EquippedPart"
          ) {
            max = part.part.leasableQuantity;
          } else {
            max = part.part.quantity;
          }
          return (
            <Button
              type="primary"
              shape="circle"
              icon="plus"
              size="small"
              onClick={() => {
                const num = items[part.part.referenceId]?.qty || 0;
                if (num === max) {
                  message.warning("已選取上限數量");
                  return;
                }
                setItems((prev) => ({
                  ...prev,
                  [part.part.referenceId]: {
                    ...part.part,
                    qty: (prev[part.part.referenceId]?.qty || 0) + 1,
                    max: max,
                  },
                }));
                message.success("成功加到選取清單");
              }}
            />
          );
        },
      },
    ];
  }, [items]);

  const defaultConsumableColumns = useMemo(() => {
    return [
      {
        title: "型號",
        dataIndex: "consumable.referenceId",
        render: (text, { consumable }) => {
          return (
            <Link
              to={`${replaceUrlByParams(PAGE.STOCK_CONSUMABLE_DETAIL, {
                id: consumable?.id,
              })}`}
              target="_blank"
            >
              {text}
            </Link>
          );
        },
      },
      {
        title: "名稱",
        dataIndex: "consumable.productInfo.nameChi",
      },
      {
        title: "數量",
        dataIndex: "quantity",
      },
      {
        dataIndex: "consumable",
        render: (part, record, index) => {
          let max = 0;
          if (
            part.__typename === "Device" ||
            part.__typename === "EquippedPart"
          ) {
            max = record.leasableQuantity;
          } else {
            max = record.quantity;
          }
          return (
            <Button
              type="primary"
              shape="circle"
              icon="plus"
              size="small"
              onClick={() => {
                const num = items[part.referenceId]?.qty || 0;
                if (num === max) {
                  message.warning("已選取上限數量");
                  return;
                }
                setItems((prev) => ({
                  ...prev,
                  [part.referenceId]: {
                    ...part,
                    qty: (prev[part.referenceId]?.qty || 0) + 1,
                    max: max,
                  },
                }));
                message.success("成功加到選取清單");
              }}
            />
          );
        },
      },
    ];
  }, [items]);

  const optionalDeviceColumns = useMemo(() => {
    return [
      {
        title: "型號",
        dataIndex: "device.referenceId",
        render: (text, record, index) => {
          return (
            <Link
              to={`${replaceUrlByParams(PAGE.STOCK_DEVICE_DETAIL, {
                id: record?.device?.id,
              })}`}
              target="_blank"
            >
              {text}
            </Link>
          );
        },
      },
      {
        title: "名稱",
        dataIndex: "device.productInfo.nameChi",
      },
      {
        title: "可租用數量",
        dataIndex: "leasableQuantity",
      },
      {
        title: "正在清洗數量",
        dataIndex: "washingQuantity",
      },
      {
        title: "輪候人數",
        dataIndex: "waitingQuantity",
      },
      {
        dataIndex: "device",
        render: (text, record, index) => {
          let max = 0;
          if (
            record.device.__typename === "Device" ||
            record.device.__typename === "EquippedPart"
          ) {
            max = record.leasableQuantity;
          } else {
            max = record.quantity;
          }
          return (
            <Button
              type="primary"
              shape="circle"
              icon="plus"
              size="small"
              onClick={() => {
                const num = items[record.device.referenceId]?.qty || 0;
                if (num === max) {
                  message.warning("已選取上限數量");
                  return;
                }
                setItems((prev) => ({
                  ...prev,
                  [record.device.referenceId]: {
                    ...record.device,
                    qty: (prev[record.device.referenceId]?.qty || 0) + 1,
                    max: max,
                  },
                }));
                message.success("成功加到選取清單");
              }}
            />
          );
        },
      },
    ];
  }, [device, items, part]);

  const optionalDevice = useMemo(() => {
    return part?.optionalDevice.map((device) => {
      const waitingQuantity = device.waitingQuantity;
      const washingQuantity = device.washingQuantity;
      const leasableQuantity = device.leasableQuantity;
      return {
        waitingQuantity,
        washingQuantity,
        leasableQuantity,
        device,
      };
    });
  }, [part, id]);

  const optionalPart = useMemo(() => {
    return device?.optionalPart.map((part) => {
      const waitingQuantity = part.part.waitingQuantity;
      const washingQuantity = part.part.washingQuantity;
      const leasableQuantity = part.part.leasableQuantity;
      return {
        waitingQuantity,
        washingQuantity,
        leasableQuantity,
        part,
      };
    });
  }, [device, id]);

  useEffect(() => {
    if (id) {
      switch (type) {
        case "Device": {
          getDevice({
            variables: {
              id,
            },
          });
          break;
        }
        case "Part": {
          getPart({
            variables: {
              id,
            },
          });
          break;
        }
        default:
          break;
      }
    }
  }, [id, type, getDevice, getPart]);

  const getClassList = () =>
    classList({
      "AvailablePartDrawer-Component": true,
      [className]: true,
    });

  return (
    <Drawer
      className={getClassList()}
      title="查看適用貨物/零件/消耗品"
      onClose={onClose}
      visible={visible}
      width="50vw"
    >
      {type === "Device" && (
        <div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card title="額外零件">
                <Table
                  rowKey={(row) => row.part.id}
                  loading={getDeviceLoading}
                  pagination={false}
                  dataSource={optionalPart}
                  columns={optionalPartColumns}
                  scroll={{x:"max-content"}}
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card title="預設消耗品">
                <Table
                  rowKey={(row) => row.consumable.id}
                  loading={getDeviceLoading}
                  pagination={false}
                  dataSource={defaultConsumable}
                  columns={defaultConsumableColumns}
                  scroll={{x:"max-content"}}
                />
              </Card>
            </Col>
          </Row>
        </div>
      )}
      {type === "Part" && (
        <div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card title="額外貨物">
                <Table
                  rowKey={(row) => row.device.id}
                  loading={getPartLoading}
                  pagination={false}
                  dataSource={optionalDevice}
                  columns={optionalDeviceColumns}
                  scroll={{x:"max-content"}}
                />
              </Card>
            </Col>
          </Row>
        </div>
      )}
      <div className="btn-container" style={{ marginTop: "40px" }}>
        <Button
          className="btn bottom-pinned"
          type="primary"
          onClick={() => setIsCartDrawerShow(true)}
        >
          查看選取清單
        </Button>
      </div>
      <OrderingItemsCartDrawer
        visible={isCartDrawerShow}
        onClose={onCartDrawerClose}
        onSubmit={onCartDrawerSubmit}
        items={items}
        setItems={setItems}
      ></OrderingItemsCartDrawer>
    </Drawer>
  );
};

AvailablePartDrawer.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

AvailablePartDrawer.defalutProps = {
  className: "",
};

export default AvailablePartDrawer;
