import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

// Styles
import "./contractTable.scss";

// Utils
import { Button, Table, Tag, Upload, notification } from "antd";
import { MOMENT_FORMAT } from "@utils/constants";
import { getFileUploadUrl } from "@utils/env";
import { getTokenHeader } from "@utils/api";
import moment from "moment";

function ContractTable({
  data,
  loading,
  title,
  onUpload,
  onCreate,
  onCreateLoading,
}) {
  console.log("data, ContractTable", data);
  const [isShowUpload, setIsShowUpload] = useState([]);
  const [fileUploadList, setFileUploadList] = useState([]);
  const [isFirstRun, setIsFirstRun] = useState(false);

  const showUpload = useCallback(
    (index) => {
      const newIsShowUpload = [...isShowUpload];
      newIsShowUpload[index] = true;
      setIsShowUpload(newIsShowUpload);
    },
    [isShowUpload]
  );

  const dontShowUpload = useCallback(
    (index) => {
      const newIsShowUpload = [...isShowUpload];
      newIsShowUpload[index] = false;
      setIsShowUpload(newIsShowUpload);
    },
    [isShowUpload]
  );

  //logg
  useEffect(() => {
    // console.log("fileUploadList",fileUploadList)
  }, [fileUploadList]);

  function getUuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      // eslint-disable-next-line no-mixed-operators
      let r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  useEffect(() => {
    if (!isFirstRun && data.length !== 0) {
      const newIsShowUpload = [];
      const newFileUploadList = [];
      data.forEach((element) => {
        // console.log("data",data)
        // console.log("element",element)
        newIsShowUpload.push(false);
        if (element.file) {
          const newFile = element.file;
          newFile.uid = getUuid();
          newFileUploadList.push([newFile]);
        } else {
          newFileUploadList.push([]);
        }
      });
      setIsShowUpload(newIsShowUpload);
      setFileUploadList(newFileUploadList);
      // console.log("newFileUploadList",newFileUploadList)
      // console.log("fileUploadList",fileUploadList)
      setIsFirstRun(true);
    }
  }, [data, fileUploadList, isFirstRun]);

  const cols = [
    {
      title: "合約編號",
      dataIndex: "referenceId",
      render: (text, contract) => (
        <Button
          type="link"
          onClick={() => window.open(contract?.file?.url)}
          style={{ paddingLeft: "0px" }}
        >
          <span style={{ textDecoration: "underline" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "更新日期",
      dataIndex: "lastModifiedDate",
      render: (timestamp) => (
        <span>{moment(timestamp).format(MOMENT_FORMAT) || "-"}</span>
      ),
    },
    {
      title: "產生日期",
      dataIndex: "createDate",
      render: (timestamp) => (
        <span>{moment(timestamp).format(MOMENT_FORMAT) || "-"}</span>
      ),
    },
    {
      title: "負責人",
      dataIndex: "pic.displayName",
    },
    {
      title: "狀態",
      dataIndex: "status",
      render: (status) =>
        ({
          pending: <Tag className="tag warning">{status.name}</Tag>,
          confirmed: <Tag className="tag success">{status.name}</Tag>,
          cancelled: <Tag className="tag danger">{status.name}</Tag>,
        }[status.id]),
    },
    {
      dataIndex: "action",
      render: (text, contract, index) => {
        // console.log('contract',text, contract , index)
        if (contract.status?.id === "pending") {
          return (
            <div>
              <Upload
                className="contract-uploader"
                headers={{
                  "x-token": getTokenHeader(),
                  "X-Requested-With": null,
                }}
                action={getFileUploadUrl()}
                fileList={
                  isShowUpload[index] && fileUploadList
                    ? fileUploadList[index]
                    : false
                }
                onChange={(info) => handleUploadChange(info, contract, index)}
                onRemove={() => {
                  setIsFirstRun(false);
                }}
                accept="image/*,.pdf"
                beforeUpload={handleBeforeUpload}
              >
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    showUpload(index);
                  }}
                >
                  上載簽署合約
                </Button>
              </Upload>
              {fileUploadList && isShowUpload[index] ? (
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    onUpload(fileUploadList[index][0], contract);
                    const newFileUploadList = [...fileUploadList];
                    newFileUploadList[index] = undefined;
                    setFileUploadList(newFileUploadList);
                    dontShowUpload(index);
                    setIsFirstRun(false);
                  }}
                  style={{ marginTop: "10px" }}
                >
                  確認上載
                </Button>
              ) : (
                false
              )}
            </div>
          );
        } else {
          return <div />;
        }
      },
    },
  ];

  const checkFileType = (file) => {
    if (file.type.includes("image/") || file.type.includes("application/pdf"))
      return true;
    return false;
  };

  const handleUploadChange = (info, contract, index) => {
    // console.log("fileUploadList",fileUploadList)
    if (checkFileType(info.file)) {
      let fileList = [...info.fileList];
      // console.log("fileList 138",fileList)
      // only one file allowed
      fileList = fileList.slice(-1);
      // console.log("fileList 141",fileList)
      fileList = fileList.map((file) => {
        if (file.response?.data) {
          file.key = file.response.data?.key;
          file.url = file.response.data?.url;
        }
        return file;
      });
      // console.log("fileList 147",fileList)
      const newFileUploadList = [...fileUploadList];
      newFileUploadList[index] = fileList;
      // console.log("fileUploadList",fileUploadList)
      // console.log("newFileUploadList",newFileUploadList)
      setFileUploadList(newFileUploadList);
    } else {
      notification.error({
        message: `不支援 ${info.file.name} 的檔案類型, 請上載圖片或PDF`,
      });
    }
  };

  const handleBeforeUpload = (file) => {
    return checkFileType(file);
  };

  return (
    <div className="ContractTable">
      <div className="table-header">
        <div className="table-title">
          <span className="text">{title}</span>
        </div>
        <Button
          className="btn btn-secondary"
          loading={onCreateLoading ?? false}
          onClick={onCreate}
          style={{ marginLeft: "24px" }}
        >
          產生合約
        </Button>
      </div>
      <div>
        <Table
          rowKey="id"
          loading={loading}
          dataSource={data}
          columns={cols}
          pagination={false}
          scroll={{x:"max-content"}}
        />
      </div>
    </div>
  );
}

ContractTable.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onUpload: PropTypes.func,
  onCreate: PropTypes.func,
  onCreateLoading: PropTypes.bool,
};

ContractTable.defaultProps = {
  loading: false,
  title: "",
};

export default ContractTable;
