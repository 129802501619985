/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

// Actions
import { setIsLoadingAction } from "@actions/storeAction";

// Components
import { notification, Spin, Icon, Typography } from "antd";
import AssessmentFormComponent from "@components/assessment/form/form";

// Constants
import { PAGE, PAGE_NAME } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

// Styles
import "./userAssessment.scss";

// Utils
import { replaceUrlByParams } from "@utils/api";
import moment from "moment";

const API = gql`
  query AssessmentResultGet($id: ID) {
    assessmentResultGet(id: $id) {
      id
      title
      assessmentStartDate
      assessmentEndDate
      section {
        title
        question {
          id
          title
          input {
            id
            inputType
            ... on Text {
              label
              textAnswer: answer
            }
            ... on Number {
              numberAnswer: answer
            }
            ... on Checkbox {
              label
              checkboxAnswer: answer
            }
            ... on FileUpload {
              label
              fileAnswer: answer {
                key
                name
                url
                size
              }
            }
            ... on SingleChoice {
              option {
                value
                extraTextInputId
                extraTextInputAnswer
              }
              singleChoiceAnswer: answer
            }
            ... on MultipleChoice {
              option {
                value
                extraTextInputId
                extraTextInputAnswer
              }
              multipleChoiceAnswer: answer
            }
          }
        }
        displaySubTotalScore
        score
        totalScore
      }
      score
      totalScore
      attachment {
        key
        url
        name
        size
      }
    }
  }
`;

const ASSESSMENT_RESULT_UPDATE = gql`
  mutation AssessmentResultUpdate($input: AssessmentResultUpdateInput) {
    assessmentResultUpdate(input: $input) {
      id
    }
  }
`;

const RelativeTimerRender = ({ timestamp }) => {
  function getTimeDiff(timestamp) {
    const diff = moment().diff(timestamp, "seconds");
    return diff;
  }

  const [time, setTime] = useState(getTimeDiff(timestamp));

  useEffect(() => {
    setTime(getTimeDiff(timestamp));
    const interval = setInterval(() => {
      setTime(getTimeDiff(timestamp));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timestamp]);

  return <Typography.Text>{`${time} 秒前`}</Typography.Text>;
};
RelativeTimerRender.propTypes = {
  timestamp: PropTypes.number,
};

const autoSaveStatus = {
  success: "success",
  fail: "fail",
};

function UserAssessmentPage(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  let history = useHistory();
  const store = useSelector((state) => state.storeReducer);

  const [lastAutoSave, setLastAutoSave] = useState({
    timestamp: new Date().valueOf(),
    status: autoSaveStatus.success,
  });

  let urlParams = new URLSearchParams(location.search);
  const fromOrdering = urlParams.get("from") === "ordering";

  const ID = props.match.params.id;
  const ASSESSMENT_ID = props.match.params.assessmentId;
  const BREADCRUMB = [
    {
      path:
        replaceUrlByParams(PAGE.USER_DETAIL, { id: ID }) +
        "?activeTab=assessment",
      name: PAGE_NAME.USER_DETAIL,
    },
    {
      path: PAGE.USER_ASSESSMENT,
      name: PAGE_NAME.USER_ASSESSMENT,
    },
  ];

  const [
    assessmentResultUpdate,
    {
      loading: assessmentResultUpdateLoading,
      error: assessmentResultUpdateError,
      data: assessmentResultUpdateData,
    },
  ] = useMutation(ASSESSMENT_RESULT_UPDATE);
  const [
    assessmentResultAutoSave,
    {
      loading: assessmentResultAutoSaveLoading,
      error: assessmentResultAutoSaveError,
      data: assessmentResultAutoSaveData,
    },
  ] = useMutation(ASSESSMENT_RESULT_UPDATE, {
    onCompleted: (data) => {
      setLastAutoSave({
        timestamp: new Date().valueOf(),
        status: autoSaveStatus.success,
      });
    },
    onError: (error) => {
      setLastAutoSave((prev) => ({
        timestamp: prev.timestamp,
        status: autoSaveStatus.fail,
      }));
    },
  });
  const { loading, error, data } = useQuery(API, {
    variables: { id: ASSESSMENT_ID },
  });

  useEffect(() => {
    dispatch(setIsLoadingAction(loading));

    // eslint-disable-next-line
  }, [loading, error, data]);

  useEffect(() => {
    dispatch(setIsLoadingAction(assessmentResultUpdateLoading));

    if (assessmentResultUpdateData) {
      notification.success({
        message: "成功更新評估",
      });
      if (fromOrdering) {
        history.push(
          replaceUrlByParams(PAGE.ORDERING_DETAIL, { id: ID }) +
            "?tabKey=assessment"
        );
      } else {
        history.push(
          replaceUrlByParams(PAGE.USER_DETAIL, { id: ID }) +
            "?activeTab=assessment"
        );
      }
    }

    // eslint-disable-next-line
  }, [
    assessmentResultUpdateLoading,
    assessmentResultUpdateError,
    assessmentResultUpdateData,
  ]);

  const onSubmit = ({
    input,
    assessmentStartDate,
    assessmentEndDate,
    attachment,
  }) => {
    const args = {
      assessmentResultId: ASSESSMENT_ID,
      assessmentStatus: "complete",
      personInCharge: store.user.tbId,
      input,
      assessmentStartDate,
      assessmentEndDate,
      attachment,
    };
    assessmentResultUpdate({
      variables: {
        input: args,
      },
    });
  };

  const onAutoSave = useCallback(
    ({ input, assessmentStartDate, assessmentEndDate, attachment }) => {
      const args = {
        assessmentResultId: ASSESSMENT_ID,
        assessmentStatus: "incomplete",
        personInCharge: store.user.tbId,
        input,
        assessmentStartDate,
        assessmentEndDate,
        attachment,
      };
      assessmentResultAutoSave({
        variables: {
          input: args,
        },
      });
    },
    [ASSESSMENT_ID, assessmentResultAutoSave, store.user.tbId]
  );

  const renderIcon = useMemo(() => {
    if (assessmentResultAutoSaveLoading)
      return (
        <Spin
          className="autoSave-icon"
          spinning={true}
          indicator={<Icon type="loading" />}
        />
      );

    switch (lastAutoSave.status) {
      case autoSaveStatus.fail:
        return (
          <Icon
            className="autoSave-icon"
            type="close-circle"
            theme="twoTone"
            twoToneColor="#eb2f96"
          />
        );
      case autoSaveStatus.success:
      default:
        return (
          <Icon
            className="autoSave-icon"
            type="check-circle"
            theme="twoTone"
            twoToneColor="#52c41a"
          />
        );
    }
  }, [assessmentResultAutoSaveLoading, lastAutoSave.status]);

  return (
    <StickyBreadcrumbPageComponent
      className="User-assessment-page"
      breadcrumb={BREADCRUMB}
    >
      <div className="autoSave">
        {"上次自動儲存："}
        <RelativeTimerRender timestamp={lastAutoSave.timestamp} />
        <div className="autoSave-icon">{renderIcon}</div>
      </div>
      <div className="content-inner">
        {data && (
          <AssessmentFormComponent
            data={data.assessmentResultGet}
            onSubmit={onSubmit}
            onAutoSave={onAutoSave}
          />
        )}
      </div>
    </StickyBreadcrumbPageComponent>
  );
}

export default UserAssessmentPage;

UserAssessmentPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }),
};
