import React from "react";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Styles
import "./deliveryFilter.scss";

// Utils
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Row,
  Select,
  Typography,
} from "antd";
import { MOMENT_FORMAT } from "@utils/constants";
import { isEmpty } from "@utils/validate";
import moment from "moment";

const API = gql`
  query EnumList {
    enumZoneList {
      id
      name
    }
    enumDeliveryStatusList {
      id
      name
    }
  }
`;

function DeliveryFilter(props) {
  const {
    onFilterSearch,
    onResetFilter,
    isFilterShow,
    filterValue,
    onFilterValueChange,
    availableDeliveryStatus,
  } = props;

  const { data: enumList } = useQuery(API);

  const { RangePicker } = DatePicker;

  return (
    <div className={`Filter ${isFilterShow ? "show" : "hide"}`}>
      <div className="filter-container">
        <div className="options-container">
          <Typography>篩選條件</Typography>
          <div className="options">
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <div className="option">
                  <Typography>訂單最新繳費狀態</Typography>
                  <Select
                    mode="tags"
                    value={filterValue.paymentStatus}
                    placeholder="請選擇"
                    onChange={(paymentStatus) =>
                      onFilterValueChange((filterValue) => {
                        return {
                          ...filterValue,
                          paymentStatus,
                        };
                      })
                    }
                  >
                    <Select.Option value="pending">待付款</Select.Option>
                    {/* <Select.Option value='paidManually'>已提供付款證明</Select.Option> */}
                    <Select.Option value="paid">已付款</Select.Option>
                    {/* <Select.Option value='overdue'>已過期</Select.Option>
                                        <Select.Option value='confirmed'>已確認付款</Select.Option>
                                        <Select.Option value='toBeRefunded'>待退款</Select.Option>
                                        <Select.Option value='refunded'>已退款</Select.Option> */}
                  </Select>
                </div>
              </Col>
              <Col span={6}>
                <div className="option">
                  <Typography>工作狀態</Typography>
                  <Select
                    mode="tags"
                    value={filterValue.deliveryStatus}
                    placeholder="請選擇"
                    onChange={(deliveryStatus) =>
                      onFilterValueChange((filterValue) => {
                        return {
                          ...filterValue,
                          deliveryStatus,
                        };
                      })
                    }
                  >
                    {enumList?.enumDeliveryStatusList.map((option) => (
                      <Select.Option
                        key={option.id}
                        value={option.id}
                        disabled={!availableDeliveryStatus?.includes(option.id)}
                      >
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </Col>
              <Col span={6}>
                <div className="option">
                  <Typography>工作類型</Typography>
                  <Select
                    mode="tags"
                    value={filterValue.type}
                    placeholder="請選擇"
                    onChange={(type) =>
                      onFilterValueChange((filterValue) => {
                        return {
                          ...filterValue,
                          type,
                        };
                      })
                    }
                  >
                    <Select.Option value="delivery">送貨</Select.Option>
                    <Select.Option value="reclaim">收回</Select.Option>
                    <Select.Option value="installation">安裝</Select.Option>
                    <Select.Option value="uninstallation">拆卸</Select.Option>
                  </Select>
                </div>
              </Col>
              <Col span={6}>
                <div className="option">
                  <Typography>分區</Typography>
                  <Select
                    mode="tags"
                    value={filterValue.zone}
                    placeholder="請選擇"
                    onChange={(zone) =>
                      onFilterValueChange((filterValue) => {
                        return {
                          ...filterValue,
                          zone,
                        };
                      })
                    }
                  >
                    {enumList?.enumZoneList.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </Col>
            </Row>
          </div>
          <div className="options">
            <Row>
              <div className="option">
                <Typography>預計日期</Typography>
                <RangePicker
                  placeholder={["開始日", "結束日"]}
                  value={[
                    !isEmpty(filterValue.deliveryStartDate)
                      ? moment(filterValue.deliveryStartDate, MOMENT_FORMAT)
                      : null,
                    !isEmpty(filterValue.deliveryEndDate)
                      ? moment(filterValue.deliveryEndDate, MOMENT_FORMAT)
                      : null,
                  ]}
                  onChange={(value, dateString) => {
                    onFilterValueChange((filterValue) => {
                      return {
                        ...filterValue,
                        deliveryStartDate: dateString[0],
                        deliveryEndDate: dateString[1],
                      };
                    });
                  }}
                />
              </div>
            </Row>
          </div>
        </div>
        <div className="action-container">
          <Row>
            <Col span={12}>
              <div className="action-checkbox-container">
                <Checkbox
                  checked={filterValue.selfPick === "true"}
                  onChange={(e) => {
                    const selfPick = e.target.checked.toString();
                    onFilterValueChange((filterValue) => {
                      return {
                        ...filterValue,
                        selfPick,
                      };
                    });
                  }}
                >
                  只顯示自提項目
                </Checkbox>
              </div>
            </Col>
            <Col span={12}>
              <div className="action-btn-container">
                <Button className="btn btn-secondary" onClick={onResetFilter}>
                  清除設定
                </Button>
                <Button className="btn" type="primary" onClick={onFilterSearch}>
                  搜尋
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default DeliveryFilter;

DeliveryFilter.propTypes = {
  filterTypes: PropTypes.arrayOf(PropTypes.string),
  filterValue: PropTypes.shape({
    paymentStatus: PropTypes.arrayOf(PropTypes.string),
    deliveryStatus: PropTypes.arrayOf(PropTypes.string),
    zone: PropTypes.arrayOf(PropTypes.string),
    deliveryStartDate: PropTypes.string,
    deliveryEndDate: PropTypes.string,
    selfPick: PropTypes.string,
    type: PropTypes.string,
  }),
  onFilterValueChange: PropTypes.func,
  isFilterShow: PropTypes.bool.isRequired,
  onFilterSearch: PropTypes.func,
  onResetFilter: PropTypes.func,
  availableDeliveryStatus: PropTypes.array,
};
