/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useMemo, useCallback, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import {
  SortableHandle,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import { gql } from "@apollo/client";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

import Picker from "@pages/stock/picker/picker";

//styles
import "./InspectionCreate.scss";

//components
import {
  Input,
  Form,
  Button,
  Select,
  Spin,
  Icon,
  Card,
  Typography,
  notification,
  Col,
  Row,
} from "antd";

// Utils
// import { classList } from "@utils/common";
import { replaceUrlByParams } from "@utils/api";
import { useAccessLevel } from "@utils/hook";

//constants
import { PAGE, PAGE_NAME, ACCESS_LEVELS } from "@utils/constants";
import { useEffect } from "react";
import { closeSync } from "fs-extra";
import Text from "antd/lib/typography/Text";

const { TextArea } = Input;

const CREATE_BREADCRUMB = [
  {
    path: PAGE.WORKSHOP_INSPECTION_CREATE,
    name: PAGE_NAME.WORKSHOP_INSPECTION_CREATE,
  },
];
const EDIT_BREADCRUMB = [
  {
    path: PAGE.WORKSHOP_INSPECTION_EDIT,
    name: PAGE_NAME.WORKSHOP_INSPECTION_EDIT,
  },
];

const INSPECTION_CREATE = gql`
  mutation InspectionCreate($input: InspectionCreateInput) {
    inspectionCreate(input: $input) {
      id
    }
  }
`;

const INSPECTION_UPDATE = gql`
  mutation InspectionUpdate($input: InspectionUpdateInput) {
    inspectionUpdate(input: $input) {
      id
    }
  }
`;

const INPECTION_GET = gql`
  query inspectionGet($id: ID!) {
    inspectionGet(id: $id) {
      id
      name
      device {
        referenceId
        id
      }
      part {
        referenceId
        id
      }
      inspectionCheckListQuestion {
        questionId
        title
        option
      }
      remark
      trace {
        creator {
          tbId
          username
          displayName
          allowedMutation
        }
      }
    }
  }
`;

const Dragable = SortableHandle((props) => {
  return <Icon className="dragable" type="drag" />;
});

const SortableItem = SortableElement(
  ({
    item: question,
    itemIndex: questionIndex,
    control,
    questionRemove,
    disableRemoveButton = false,
    setValue,
    errors,
    trigger,
    isDisabled,
  }) => {
    return (
      <div className="inspectionCreateCard">
        <div className="card space">
          <Controller
            as={<Input type="hidden" />}
            name={`inspectionCheckListQuestion[${questionIndex}].questionId`}
            control={control}
            defaultValue={question?.id}
          />
          {!isDisabled && <Dragable />}
          {!isDisabled && !disableRemoveButton && (
            <Icon
              type="close"
              className="question-remove-btn"
              onClick={() => questionRemove(questionIndex)}
            />
          )}
          <div className="add">
            <div className="subtitle">
              <Text>項目名稱</Text>
            </div>
          </div>
          <Controller
            as={<Input placeholder="項目名稱" readOnly={isDisabled} />}
            name={`inspectionCheckListQuestion[${questionIndex}].title`}
            control={control}
            defaultValue={question?.title ?? ""}
            rules={{ validate: (value) => value.length > 0 }}
          />
          {errors?.inspectionCheckListQuestion?.[questionIndex]?.title && (
            <Typography.Text type="danger">請填寫項目名稱</Typography.Text>
          )}
          <div className="subtitle">
            <Text>選項</Text>
          </div>
          <Controller
            control={control}
            defaultValue={question?.option ?? []}
            name={`inspectionCheckListQuestion[${questionIndex}].option`}
            className="space right"
            rules={{
              validate: {
                emptyStringOption: (value) =>
                  value.filter((item) => item === "").length === 0 ||
                  "請填寫選項名稱",
                duplicateOptionName: (value) =>
                  !value.some(
                    (x) => value.indexOf(x) !== value.lastIndexOf(x)
                  ) || "重複的選項名稱, 請更改或删除重複選項",
              },
            }}
            render={({ value: options = [], onChange }) => {
              return (
                <div>
                  {options?.map((option, index) => {
                    return (
                      <div key={`question_${index}`}>
                        <div className="option vertical-space">
                          <Input
                            value={options[index]}
                            readOnly={isDisabled}
                            onChange={(e) => {
                              const newValue = [...options];
                              newValue.splice(index, 1, e.target.value);
                              onChange(newValue);
                            }}
                          />
                          {!isDisabled && options.length > 2 && (
                            <Icon
                              type="close"
                              key={`${question?.option}_remove`}
                              onClick={(e) => {
                                const newValue = [...options];
                                newValue.splice(index, 1);
                                onChange(newValue);
                              }}
                              className="space grey"
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div>
                    {errors?.inspectionCheckListQuestion?.[questionIndex]
                      ?.option && (
                      <Typography.Text type="danger">
                        {
                          errors?.inspectionCheckListQuestion?.[questionIndex]
                            ?.option?.message
                        }
                      </Typography.Text>
                    )}
                  </div>
                  {!isDisabled && (
                    <Button
                      className="vertical-space"
                      onClick={() => {
                        setValue(
                          `inspectionCheckListQuestion[${questionIndex}].option`,
                          [...options, ""]
                        );
                        trigger(
                          `inspectionCheckListQuestion[${questionIndex}].option`
                        );
                      }}
                    >
                      新增選項
                    </Button>
                  )}
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
);

const SortableQuestions = SortableContainer(
  ({
    control,
    fields,
    questionRemove,
    setValue,
    getValues,
    errors,
    trigger,
    isDisabled,
  }) => {
    return (
      <div className="questions">
        {fields.map((question, index) => {
          return (
            <SortableItem
              key={question.id}
              item={question}
              itemIndex={index}
              index={index}
              control={control}
              questionRemove={questionRemove}
              disableRemoveButton={fields?.length < 2}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              trigger={trigger}
              isDisabled={isDisabled}
              disabled={isDisabled}
            />
          );
        })}
      </div>
    );
  }
);

const InspectionCreate = (props) => {
  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.WORKSHOP_MANAGEMENT,
  ]);

  const inspectionId = props?.match?.params?.id;
  // eslint-disable-next-line no-unused-vars
  const {
    handleSubmit,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    reset,
    clearErrors,
    errors,
    trigger,
  } = useForm({
    defaultValues: {
      name: "",
      device: [],
      part: [],
      remark: "",
      inspectionCheckListQuestion: [
        {
          title: "",
          option: ["正常", ""],
        },
      ],
    },
  });
  const watchValue = watch();

  const [
    getInspection,
    {
      data: getInspectionData,
      loading: getInspectionLoading,
      error: getInspectionError,
    },
  ] = useLazyQuery(INPECTION_GET);

  const [
    createInspection,
    { data: createInspectionData, loading: createInspectionLoading },
  ] = useMutation(INSPECTION_CREATE);

  const [
    updateInspection,
    { data: updateInspectionData, loading: updateInspectionLoading },
  ] = useMutation(INSPECTION_UPDATE);

  const history = useHistory();

  useEffect(() => {
    if (inspectionId) {
      const variables = {
        id: inspectionId,
      };
      getInspection({ variables: variables });
    }
    if (getInspectionError) {
      history.push(PAGE.WORKSHOP_INSPECTION_CHECKLIST);
    }
  }, [getInspection, getInspectionError, history, inspectionId]);

  useEffect(() => {
    if (getInspectionData) {
      const inspection = getInspectionData?.inspectionGet ?? {};
      const prefillData = {
        ...inspection,
        name: inspection?.name,
        device: (inspection?.device ?? []).map((item) => ({
          key: item?.id,
          label: item?.referenceId,
        })),
        part: (inspection?.part ?? []).map((item) => ({
          key: item?.id,
          label: item?.referenceId,
        })),
        inspectionCheckListQuestion: (
          inspection.inspectionCheckListQuestion ?? []
        ).map(({ questionId: id, ...question }) => {
          return {
            id,
            ...question,
          };
        }),
      };
      reset(prefillData);
    }
  }, [getInspectionData, reset]);

  const [pickerParams, setPickerParams] = useState({
    visible: false,
  });

  const {
    fields: questionFields,
    append: questionAppend,
    remove: questionRemove,
    move: questionMove,
  } = useFieldArray({
    control,
    name: "inspectionCheckListQuestion",
  });

  const onAddDeviceChange = (data) => {
    const newValue = [...watchValue?.device];
    for (const { id, referenceId } of data) {
      if (watchValue?.device.find((item) => item.key === id)) continue;
      newValue.push({ key: id, label: referenceId });
    }
    setValue("device", newValue);
  };

  const onAddPartChange = (data) => {
    const newValue = [...watchValue?.part];
    for (const { id, referenceId } of data) {
      if (watchValue?.part.find((item) => item.key === id)) continue;
      newValue.push({ key: id, label: referenceId });
    }
    setValue("part", newValue);
  };

  const onClickSubmitBtn = useCallback(
    async (value) => {
      const { device, name, remark, part } = value;

      let submitData = {
        input: {
          ...(inspectionId ? { id: inspectionId } : {}),
          name: name,
          deviceId: device.map((item) => item?.key),
          partId: part.map((item) => item?.key),
          remark: remark,
          inspectionCheckListQuestion: value?.inspectionCheckListQuestion,
        },
      };
      try {
        if (inspectionId) {
          await updateInspection({ variables: submitData });
        } else {
          await createInspection({ variables: submitData });
        }
      } catch (err) {
        notification.error({
          message: `儲存失敗,${err}`,
          duration: 5,
        });
      }
    },
    [createInspection, inspectionId, updateInspection]
  );

  useEffect(() => {
    if (createInspectionData) {
      notification.success({
        message: "成功新增檢查貨物記錄表",
        duration: 5,
      });
      history.push(PAGE.WORKSHOP_INSPECTION_CHECKLIST);
    }
    if (updateInspectionData) {
      notification.success({
        message: "成功編輯檢查貨物記錄表",
        duration: 5,
      });
    }
  }, [createInspectionData, history, updateInspectionData]);

  return (
    <Spin spinning={getInspectionLoading}>
      <StickyBreadcrumbPageComponent
        className="inspection-create-page"
        breadcrumb={inspectionId ? EDIT_BREADCRUMB : CREATE_BREADCRUMB}
      >
        <div className="status-container">
          <Button
            type="secondary"
            onClick={() => history.push(PAGE.WORKSHOP_INSPECTION_CHECKLIST)}
          >
            {!isDenied ? "取消" : "返回"}
          </Button>
          {!isDenied && (
            <Button type="primary" onClick={handleSubmit(onClickSubmitBtn)}>
              儲存
            </Button>
          )}
        </div>

        <Form layout="horizontal">
          <Row gutter={[16, 16]}>
            <Col span="12">
              <Form.Item>
                <Text>名稱</Text>
                <Controller
                  as={<Input disabled={isDenied} />}
                  name="name"
                  control={control}
                  rules={{ validate: (value) => value.length > 0 }}
                />
                {errors?.name && (
                  <Typography.Text type="danger">請填寫名稱</Typography.Text>
                )}
              </Form.Item>
              <Form.Item>
                <Text>器材款號</Text>
                {!isDenied && (
                  <div className="horizontal">
                    <Button
                      className="btn btn-secondary ref-btn-space"
                      style={{ display: "block" }}
                      onClick={() =>
                        setPickerParams({
                          pickerType: "device",
                          visible: true,
                          visibleColumns: ["referenceId", "name"],
                          multiple: true,
                          onChange: onAddDeviceChange,
                          onClose: () =>
                            setPickerParams({
                              visible: false,
                            }),
                        })
                      }
                    >
                      新增
                    </Button>
                  </div>
                )}
                <Controller
                  as={
                    <Select
                      mode="multiple"
                      allowClear={true}
                      open={false}
                      labelInValue={true}
                      disabled={isDenied}
                    ></Select>
                  }
                  name="device"
                  control={control}
                />
                {errors?.device && (
                  <Typography.Text type="danger">
                    請選擇至少一個器材或零件
                  </Typography.Text>
                )}
              </Form.Item>
              <Form.Item>
                <Text>零件款號</Text>
                {!isDenied && (
                  <Button
                    className="btn btn-secondary ref-btn-space"
                    style={{ display: "block" }}
                    onClick={() =>
                      setPickerParams({
                        pickerType: "part",
                        visible: true,
                        visibleColumns: ["referenceId", "name"],
                        multiple: true,
                        onChange: onAddPartChange,
                        onClose: () =>
                          setPickerParams({
                            visible: false,
                          }),
                      })
                    }
                  >
                    新增
                  </Button>
                )}
                <Controller
                  as={
                    <Select
                      mode="multiple"
                      allowClear={true}
                      open={false}
                      labelInValue={true}
                      disabled={isDenied}
                    ></Select>
                  }
                  name="part"
                  control={control}
                />
                {errors?.part && (
                  <Typography.Text type="danger">
                    請選擇至少一個器材或零件
                  </Typography.Text>
                )}
              </Form.Item>
              <Form.Item>
                <Text>備註</Text>
                <Controller
                  as={<TextArea rows={4} disabled={isDenied} />}
                  name="remark"
                  control={control}
                  defaultValue=""
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <div className="questions">
                <SortableQuestions
                  onSortEnd={({ oldIndex, newIndex }) => {
                    window.requestAnimationFrame(() =>
                      questionMove(oldIndex, newIndex)
                    );
                  }}
                  control={control}
                  fields={questionFields}
                  useDragHandle={true}
                  questionRemove={questionRemove}
                  getValues={getValues}
                  setValue={setValue}
                  errors={errors}
                  trigger={trigger}
                  isDisabled={isDenied}
                />
              </div>
              {!isDenied && (
                <div className="add">
                  <Button
                    type="primary"
                    shape="circle"
                    icon="plus"
                    size="small"
                    className="space"
                    onClick={() => {
                      questionAppend({
                        title: "",
                        option: ["正常", ""],
                      });
                    }}
                  />
                  <div className="subtitle">新增檢查/維修項目</div>
                </div>
              )}
            </Col>
          </Row>
          {pickerParams.visible && <Picker {...pickerParams} />}
        </Form>
      </StickyBreadcrumbPageComponent>
    </Spin>
  );
};
export default InspectionCreate;
