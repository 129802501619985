/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import { useParams, useHistory, Link } from "react-router-dom";
import {
  Tabs,
  Form,
  Descriptions,
  Tag,
  Typography,
  Tooltip,
  Avatar,
  Comment,
  Col,
  Row,
  Input,
  Button,
  Divider,
  Table,
  Icon,
  Select,
  Menu,
  Popover,
  Modal,
} from "antd";
import moment from "moment";
import { gql } from "@apollo/client";
import { useLazyQuery, useQuery } from "@apollo/client";

// Constants
import { PAGE, PAGE_NAME, ACCESS_LEVELS } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

// Utils
import { StatusTag } from "@utils/commonComponents";
import { MOMENT_FORMAT } from "@utils/constants";
import { replaceUrlByParams } from "@utils/api";
import { amountDisplay } from "@utils/common";
import { pricesList } from "@utils/common";
import { useAccessLevel } from "@utils/hook";

// Components
import CreateInventoryDrawer from "@components/createInventoryDrawer/createInventoryDrawer";
import CreatePurchaseInfoDrawer from "@components/createPurchaseInfoDrawer/createPurchaseInfoDrawer";
import ActivityHistoryComponent from "@components/activityHistory/activityHistory";
import CreatePriceDrawer from "@components/createPriceDrawer/createPriceDrawer";
import CustPaginationComponent from "@components/custPagination/custPagination";

//Styles
import "./_consumableDetail.scss";
import FormItem from "antd/lib/form/FormItem";

const { TabPane } = Tabs;

const API = gql`
  query EnumList {
    enumInventoryStatusList {
      id
      name
    }
  }
`;

const PURCHASE_INFO_LIST = gql`
  query PurcahseInfoList($id: ID!, $size: Int!, $offset: Int!) {
    consumableGet(id: $id) {
      purchaseInfo(size: $size, offset: $offset) {
        id
        referenceId
        quantity
        distributor
        distributorContact
        warranty
        funder
        purchaseDate
        purchasePrice
        document {
          key
          url
          size
          name
        }
      }
    }
  }
`;

const PRICE_LIST = gql`
  query PriceList($id: ID!) {
    consumableGet(id: $id) {
      itemPrice {
        id
        referenceId
        monthlyRentalPrice
        depositPrice
        purchasePrice
        activeDate
        lastModifiedDate
        responsible {
          tbId
        }
      }
    }
  }
`;

const CONSUMABLE_GET = gql`
  query ConsumableGet($id: ID!) {
    consumableGet(id: $id) {
      id
      referenceId
      productInfo {
        nameEng
        nameChi
        modelNumber
        thumbnail {
          key
          url
        }
        dimension
        netWeight
        totalWeight
        colour
        specification
        characteristicTag
        manufacturer
      }
      quantity
      category {
        id
        name
      }
    }
  }
`;

const INVENTORY_RECORD_LIST = gql`
  query ConsumableGet($id: ID!) {
    consumableGet(id: $id) {
      id
      inventoryRecord {
        id
        referenceId
        serialNumber
        status {
          id
          name
        }
        activeDate
        firstStorageDate
        warehouse {
          id
          name
          storeLocationCode
        }
        createDate
        displayItem
      }
      quantity
    }
  }
`;

const PAGE_SIZE = 10;

const ConsumableDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [selectedInventory, setSelectedInventory] = useState([]);
  const [keyword, setKeyword] = useState("");
  const query = useMemo(() => {
    const { page, pageSize } = queryString.parse(location.search);
    return {
      ...queryString.parse(location.search),
      page: page ? Number(page) : undefined,
      // pageSize: pageSize ? Number(pageSize) : PAGE_SIZE,
      pageSize: PAGE_SIZE,
    };
  }, [location.search]);
  const { isMatched: addPriceIsDenied } = useAccessLevel([
    ACCESS_LEVELS.ADMINISTRATION,
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.CASE_MANAGEMENT,
    ACCESS_LEVELS.OPERATION_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.CLERICAL_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.ADMIN_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.FINANCIAL_MANAGEMENT,
    ACCESS_LEVELS.SERVICE_TEAM,
  ]);

  const { isMatched: addPriceIsAllowed } = useAccessLevel([
    ACCESS_LEVELS.SUPERADMIN_EQUIPMENT_MANAGEMENT,
  ]);

  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.CASE_MANAGEMENT,
    ACCESS_LEVELS.FINANCIAL_MANAGEMENT,
    ACCESS_LEVELS.SERVICE_TEAM,
  ]);

  const isQueryReady = !!query?.page && !!query?.pageSize;

  // const isQueryReady =
  //   query.tabKey &&
  //   (query.tabKey !== "purchaseInfo" || (!!query?.page && !!query?.pageSize));

  const [localQuery, setLocalQuery] = useState(query);

  useEffect(() => {
    setLocalQuery(query);
  }, [query]);

  // const onSearch = useCallback(() => {
  //   const query = {
  //     tabKey: localQuery.tabKey,
  //     page: localQuery.page,
  //     pageSize: localQuery.pageSize,
  //   };

  //   localQuery.storeLocationList &&
  //     (query.storeLocationList = localQuery.storeLocationList);
  //   localQuery.status && (query.status = localQuery.status);

  //   history.push({
  //     pathname: location.pathname,
  //     search: queryString.stringify(query),
  //   });
  // }, [history, localQuery, location.pathname]);

  const [inventoryDrawerVisible, setInventoryDrawerVisible] = useState(false);
  const [inventoryDrawerParams, setInventoryDrawerParams] = useState(undefined);

  const [purchaseInfoDrawerVisible, setPurchaseInfoDrawerVisible] = useState(
    false
  );
  const [purchaseInfoDrawerParams, setPurchaseInfoDrawerParams] = useState(
    undefined
  );

  const [priceDrawerVisible, setPriceDrawerVisible] = useState(false);
  const [priceDrawerParams, setPriceDrawerParams] = useState(undefined);

  const [deleteInventoryParams, setDeleteInventoryParams] = useState({
    visible: false,
  });

  // const [filterVisible, setFilterVisible] = useState(false);

  const [quantity, setQuantity] = useState(undefined);

  const { data: enumList } = useQuery(API);

  const [
    getConsumable,
    {
      data: getConsumableData,
      loading: getConsumableLoading,
      error: getConsumableError,
    },
  ] = useLazyQuery(CONSUMABLE_GET);

  const [
    getInventoryRecord,
    {
      data: getInventoryRecordData,
      loading: getInventoryRecordLoading,
      error: getInventoryRecordError,
    },
  ] = useLazyQuery(INVENTORY_RECORD_LIST);

  const [
    getPurchaseInfoList,
    {
      data: getPurchaseInfoListData,
      loading: getPurchaseInfoListLoading,
      error: getPurchaseInfoListError,
    },
  ] = useLazyQuery(PURCHASE_INFO_LIST);
  const [
    getPriceList,
    {
      data: getPriceListData,
      loading: getPriceListLoading,
      error: getPriceListError,
    },
  ] = useLazyQuery(PRICE_LIST);

  const consumable = useMemo(() => {
    console.log("consumable", getConsumableData?.consumableGet);
    return getConsumableData?.consumableGet;
  }, [getConsumableData]);

  const purchaseInfo = useMemo(() => {
    return (getPurchaseInfoListData?.consumableGet?.purchaseInfo ?? []).slice(
      0,
      query.pageSize
    );
  }, [getPurchaseInfoListData, query.pageSize]);

  const inventoryRecords = useMemo(() => {
    const record = getInventoryRecordData?.consumableGet?.inventoryRecord;
    return record ? [record] : [];
  }, [getInventoryRecordData]);

  const prices = useMemo(() => {
    return pricesList(getPriceListData?.consumableGet?.itemPrice ?? []);
  }, [getPriceListData]);

  const isMaxPage = useMemo(() => {
    switch (query.tabKey) {
      case "purchaseInfo":
        return purchaseInfo.length < query.pageSize;
      case "priceList":
        return prices.length < query.pageSize;
      default:
        return false;
    }
  }, [purchaseInfo.length, prices.length, query.pageSize, query.tabKey]);

  const refreshInventoryRecordList = useCallback(() => {
    getInventoryRecord({
      variables: {
        id,
      },
    });
  }, [getInventoryRecord, id]);

  const refreshPurchaseInfoList = useCallback(() => {
    getPurchaseInfoList({
      variables: {
        id,
        size: query.pageSize + 1,
        offset: (query.page - 1) * query.pageSize,
      },
    });
  }, [getPurchaseInfoList, id, query.page, query.pageSize]);

  const refreshPriceList = useCallback(() => {
    getPriceList({
      variables: {
        id,
      },
    });
  }, [getPriceList, id]);

  useEffect(() => {
    if (id && isQueryReady) {
      switch (query.tabKey) {
        case "quantityInfo":
          getInventoryRecord({
            variables: {
              id,
            },
          });
          break;
        case "purchaseInfo":
          getPurchaseInfoList({
            variables: {
              id,
              size: query.pageSize + 1,
              offset: (query.page - 1) * query.pageSize,
            },
          });
          break;
        case "priceList":
          getPriceList({
            variables: {
              id,
            },
          });
          break;
        default:
      }
    }
  }, [
    getInventoryRecord,
    getPriceList,
    getPurchaseInfoList,
    id,
    isQueryReady,
    query.page,
    query.pageSize,
    query.tabKey,
  ]);

  useEffect(() => {
    if (id) {
      getConsumable({
        variables: {
          id,
        },
      });
    }
  }, [getConsumable, id]);

  useEffect(() => {
    if (getConsumableData?.consumableGet) {
      setQuantity(getConsumableData?.consumableGet?.quantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getConsumableData?.consumableGet]);

  useEffect(() => {
    if (getInventoryRecordData?.consumableGet) {
      setQuantity(getInventoryRecordData?.consumableGet?.quantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInventoryRecordData?.consumableGet]);

  const unfilledText = <Typography.Text disabled>(未填寫)</Typography.Text>;

  const inventoryInfoColumns = [
    {
      title: "工場",
      dataIndex: "warehouse.name",
      render: (text) => text || "-",
    },
    {
      title: "狀態",
      dataIndex: "status",
      render: (status) => <StatusTag status={status} />,
    },
    {
      title: "貨物新增日期",
      dataIndex: "createDate",
      render: (createDate) =>
        createDate ? moment(createDate).format(MOMENT_FORMAT) : "-",
    },
    {
      title: "首次入倉日期",
      dataIndex: "firstStorageDate",
      render: (firstStorageDate) =>
        firstStorageDate ? moment(firstStorageDate).format(MOMENT_FORMAT) : "-",
    },
    {
      title: "上架日期",
      dataIndex: "activeDate",
      render: (activeDate) =>
        activeDate ? moment(activeDate).format("YYYY-MM-DD") : "-",
    },
    {
      title: "",
      render: (record) => {
        return (
          !isDenied && (
            <Button
              onClick={() => {
                setInventoryDrawerVisible(true);
                setInventoryDrawerParams({
                  productId: id,
                  quantity: quantity,
                  ...record,
                });
              }}
              icon="edit"
            ></Button>
          )
        );
      },
    },
    {
      title: "",
      render: (record) => {
        return !isDenied && record?.status?.id === "inactive" ? (
          <Button
            type="danger"
            onClick={() => setDeleteInventoryParams({ visible: true, record })}
          >
            刪除
          </Button>
        ) : null;
      },
    },
  ];

  const purchaseInfoColumns = [
    {
      title: "採購單號",
      dataIndex: "referenceId",
    },
    {
      title: "保養年期",
      dataIndex: "warranty",
    },
    {
      title: "分銷商",
      dataIndex: "distributor",
    },
    {
      title: "分銷商電話",
      dataIndex: "distributorContact",
    },
    {
      title: "出資者",
      dataIndex: "funder",
    },
    {
      title: "購買日期",
      dataIndex: "purchaseDate",
      render: (purchaseDate) =>
        purchaseDate ? moment(purchaseDate).format("YYYY-MM-DD") : "-",
    },
    {
      title: "價格",
      dataIndex: "purchasePrice",
    },
    {
      title: "數量",
      dataIndex: "quantity",
    },
    {
      title: "收據",
      dataIndex: "document",
      render: (doc) => doc?.url && <a href={doc.url}>查看</a>,
    },
  ];

  const priceInfoColumns = [
    {
      title: "生效日期",
      dataIndex: "activeDate",
      render: (activeDate) => (activeDate ? activeDate : "-"),
    },
    {
      title: "價格",
      dataIndex: "purchasePrice",
      render: (amount) => <span>{amountDisplay(amount)}</span>,
    },
    {
      title: "更新日期",
      dataIndex: "lastModifiedDate",
      render: (lastModifiedDate) =>
        lastModifiedDate ? moment(lastModifiedDate).format("YYYY-MM-DD") : "-",
    },
    {
      title: "狀態",
      dataIndex: "status",
      render: (status) => {
        switch (status) {
          case "inactive":
            return <Tag className="tag warning">已失效</Tag>;
          case "active":
            return <Tag className="tag success">生效中</Tag>;
          case "pending":
            return <Tag className="tag info">待生效</Tag>;
          default:
        }
      },
    },
    {
      title: "",
      render: (record) => {
        if (record.status === "pending") {
          return (
            (addPriceIsAllowed || !addPriceIsDenied) && (
              <Button
                onClick={() => {
                  setPriceDrawerVisible(true);
                  setPriceDrawerParams({
                    productId: id,
                    type: "Consumable",
                    ...record,
                  });
                }}
                icon="edit"
              ></Button>
            )
          );
        }
      },
    },
  ];

  if (!isQueryReady) {
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({
        tabKey: query?.tabKey ?? "quantityInfo",
        page: query?.page ?? 1,
        pageSize: query?.pageSize ?? PAGE_SIZE,
      }),
    });
  }

  // if (!isQueryReady) {
  //   const tabKey = query?.tabKey ?? "purchaseInfo";
  //   history.replace({
  //     pathname: location.pathname,
  //     search: queryString.stringify({
  //       tabKey: query?.tabKey ?? "quantityInfo",

  //       ...(tabKey === "purchaseInfo" && {
  //         page: query?.page ?? 1,
  //         pageSize: query?.pageSize ?? PAGE_SIZE,
  //       }),
  //     }),
  //   });
  // }

  const BREADCRUMB = [
    {
      path: PAGE.STOCK_CONSUMABLE_DETAIL,
      name: `${PAGE_NAME.STOCK_CONSUMABLE_DETAIL} - ${consumable?.referenceId ??
        ""}`,
    },
  ];

  return (
    <StickyBreadcrumbPageComponent
      className="consumable-detail-page"
      breadcrumb={BREADCRUMB}
    >
      <Modal
        title="刪除存貨"
        visible={deleteInventoryParams.visible}
        onOk={() => setDeleteInventoryParams((_) => ({ ..._, visible: false }))}
        onCancel={() =>
          setDeleteInventoryParams((_) => ({ ..._, visible: false }))
        }
        okText="確定"
        cancelText="取消"
        okButtonProps={{ type: "danger" }}
      >
        確定刪除存貨 {deleteInventoryParams?.record?.referenceId} ?
      </Modal>
      <div className="status-container">
        {!isDenied && (
          <Button
            type="primary"
            onClick={() =>
              history.push(
                replaceUrlByParams(PAGE.STOCK_CONSUMABLE_EDIT, {
                  id: id,
                })
              )
            }
          >
            編輯
          </Button>
        )}
        {/* <Button
            type="primary"
            onClick={() =>
                history.push(PAGE.STOCK_CONSUMABLE)
            }
        >
          返回
        </Button> */}
      </div>
      <Row gutter={[16, 16]} style={{ marginTop: "8px" }}>
        <Col span={6}>
          {consumable?.productInfo?.thumbnail?.url ? (
            <img
              width="100%"
              alt="device"
              src={consumable?.productInfo?.thumbnail?.url ?? ""}
            />
          ) : (
            "目前沒有圖片"
          )}
        </Col>
        <Col span={18}>
          <Descriptions layout="vertical">
            <Descriptions.Item label="子類別">
              {consumable?.category?.name ?? unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="消耗品英文名稱">
              {consumable?.productInfo?.nameEng ?? unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="消耗品中文名稱">
              {consumable?.productInfo?.nameChi ?? unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="消耗品型號">
              {consumable?.modelNumber ?? unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="淨重（公斤）">
              {consumable?.netWeight ?? unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="總重量（公斤）">
              {consumable?.totalWeight ?? unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="尺寸">
              {consumable?.productInfo?.dimension?.length > 0
                ? consumable?.productInfo?.dimension.map((dimension) => (
                    <Tag key={dimension}>{dimension}</Tag>
                  ))
                : unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="顏色">
              {consumable?.productInfo?.colour?.length > 0
                ? consumable?.productInfo?.colour.map((colour) => (
                    <Tag key={colour}>{colour}</Tag>
                  ))
                : unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="品牌">
              {consumable?.productInfo?.brand?.length > 0
                ? consumable?.productInfo?.brand.map((brand) => (
                    <Tag key={brand}>{brand}</Tag>
                  ))
                : unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="生產廠家">
              {consumable?.productInfo?.manufacturer ?? unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="產品特點">
              {consumable?.productInfo?.characteristicTag?.length > 0
                ? consumable?.productInfo?.characteristicTag.map((tag) => (
                    <Tag key={tag}>{tag}</Tag>
                  ))
                : unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="存量">{quantity ?? 0}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Divider />
      <Tabs
        activeKey={query.tabKey}
        onChange={(tabKey) => {
          history.replace({
            pathname: location.pathname,
            search: queryString.stringify({
              tabKey,
            }),
          });
        }}
      >
        <TabPane className="section" tab="生產及採購資料" key="purchaseInfo">
          <div className="toolbar">
            <div className="left">
              {!isDenied && (
                <Button
                  className="add-btn"
                  type="primary"
                  onClick={() => {
                    setPurchaseInfoDrawerVisible(true);
                    setPurchaseInfoDrawerParams({
                      productId: id,
                    });
                  }}
                >
                  增加採購單
                </Button>
              )}
            </div>
            <div className="right">
              <CustPaginationComponent
                isMinPage={query.page === 1}
                isMaxPage={isMaxPage}
                page={query.page}
                // pageSize={query.pageSize || PAGE_SIZE}
                pageSize={PAGE_SIZE}
                onPageChange={(page) => {
                  history.push({
                    pathname: location.pathname,
                    search: queryString.stringify({
                      ...query,
                      page,
                    }),
                  });
                }}
              />
            </div>
          </div>
          <Table
            loading={getPurchaseInfoListLoading}
            pagination={false}
            rowKey="id"
            columns={purchaseInfoColumns}
            dataSource={purchaseInfo}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (isDenied) return;
                  setPurchaseInfoDrawerVisible(true);
                  setPurchaseInfoDrawerParams({
                    productId: id,
                    ...record,
                  });
                },
              };
            }}
            scroll={{x:"max-content"}}
          />
        </TabPane>
        <TabPane className="section" tab="數量及存倉資料" key="quantityInfo">
          {inventoryRecords?.length <= 0 && (
            <div className="toolbar">
              <div className="left">
                {!isDenied && (
                  <Button
                    className="add-btn"
                    type="primary"
                    onClick={() => {
                      setInventoryDrawerVisible(true);
                      setInventoryDrawerParams({
                        productId: id,
                      });
                    }}
                  >
                    增加存貨
                  </Button>
                )}
              </div>
            </div>
          )}
          {/* {filterVisible && (
            <div className="filter">
              <div className="filter-container">
                <FormItem label="存倉位置">
                  <Input
                    style={{ width: 200 }}
                    value={localQuery?.storeLocation}
                    onChange={({ target: { value } }) =>
                      setLocalQuery((localQuery) => ({
                        ...localQuery,
                        storeLocation: value,
                      }))
                    }
                  />
                </FormItem>
                <FormItem label="狀態">
                  <Select
                    mode="multiple"
                    style={{ width: 200 }}
                    value={localQuery?.status}
                    onChange={(value) =>
                      setLocalQuery((localQuery) => ({
                        ...localQuery,
                        status: value,
                      }))
                    }
                  >
                    {enumList?.enumInventoryStatusList.map(option =>
                      <Select.Option key={option.id} value={option.id}>{option.name}</Select.Option>)}
                  </Select>
                </FormItem>
              </div>
              <div className="footer-container">
                <Button
                  type="secondary"
                  onClick={() =>
                    setLocalQuery((localQuery) => ({
                      ...localQuery,
                      status: null,
                      storeLocation: null,
                    }))
                  }
                >
                  重設
                  </Button>
                <Button type="primary" onClick={onSearch}>
                  搜尋
                  </Button>
              </div>
            </div>
          )} */}
          <Table
            loading={getInventoryRecordLoading}
            pagination={false}
            rowKey="id"
            columns={inventoryInfoColumns}
            dataSource={inventoryRecords}
            scroll={{x:"max-content"}}
          />
        </TabPane>
        <TabPane className="section" tab="價格資料" key="priceList">
          <div className="toolbar">
            <div className="left">
              {(addPriceIsAllowed || !addPriceIsDenied) && (
                <Button
                  className="add-btn"
                  type="primary"
                  onClick={() => {
                    setPriceDrawerVisible(true);
                    setPriceDrawerParams({
                      productId: id,
                      type: "Consumable",
                    });
                  }}
                >
                  增加價格
                </Button>
              )}
            </div>
            <div className="right">
              <CustPaginationComponent
                isMinPage={query.page === 1}
                isMaxPage={isMaxPage}
                page={query.page}
                // pageSize={query.pageSize || PAGE_SIZE}
                pageSize={PAGE_SIZE}
                onPageChange={(page) => {
                  history.push({
                    pathname: location.pathname,
                    search: queryString.stringify({
                      ...query,
                      page,
                    }),
                  });
                }}
              />
            </div>
          </div>
          <Table
            loading={getPriceListLoading}
            pagination={false}
            rowKey="id"
            columns={priceInfoColumns}
            dataSource={prices}
            scroll={{x:"max-content"}}
          />
        </TabPane>
        <TabPane className="section" tab="活動記錄" key="activityLog">
          <ActivityHistoryComponent id={id} type={"Consumable"} />
        </TabPane>
      </Tabs>
      {inventoryDrawerVisible && (
        <CreateInventoryDrawer
          productType="consumable"
          onClose={() => {
            setInventoryDrawerVisible(false);
            refreshInventoryRecordList();
          }}
          defaultValues={inventoryDrawerParams}
        />
      )}
      {purchaseInfoDrawerVisible && (
        <CreatePurchaseInfoDrawer
          productType="consumable"
          onClose={() => {
            setPurchaseInfoDrawerVisible(false);
            refreshPurchaseInfoList();
          }}
          defaultValues={purchaseInfoDrawerParams}
        />
      )}
      {priceDrawerVisible && (
        <CreatePriceDrawer
          onClose={() => {
            setPriceDrawerVisible(false);
            refreshPriceList();
          }}
          defaultValues={priceDrawerParams}
        />
      )}
    </StickyBreadcrumbPageComponent>
  );
};

export default ConsumableDetail;
