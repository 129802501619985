/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import queryString from "query-string";
import { gql } from "@apollo/client";

// Components
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";
import ActivityHistoryComponent from "@components/activityHistory/activityHistory";
import CategoryFilter from "@components/categoryFilter/categoryFilter";
import StockServicePriceList from "./servicePriceList";

// Style
import "./serviceDetail.scss";

// Utils
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  message,
  notification,
  Row,
  Select,
  Spin,
  Tabs,
  Typography,
  Upload,
} from "antd";
import { getTokenHeader, replaceUrlByParams } from "@utils/api";
import { PAGE, PAGE_NAME, ACCESS_LEVELS } from "@utils/constants";
import { getFileUploadUrl } from "@utils/env";
import { useAccessLevel } from "@utils/hook";

const API = gql`
  query {
    characteristicTagList(type: ["service"])
  }
`;

const SERVICE_GET = gql`
  query ServiceGet($id: ID!) {
    serviceGet(id: $id) {
      id
      referenceId
      category {
        id
      }
      nameEng
      nameChi
      thumbnail {
        key
        url
        name
        size
      }
      status {
        id
        name
      }
      description
      characteristicTag
      remark
    }
  }
`;

const SERVICE_CREATE = gql`
  mutation ServiceCreate($input: ServiceCreateInput) {
    serviceCreate(input: $input) {
      id
      referenceId
      category {
        id
      }
      nameEng
      nameChi
      thumbnail {
        key
        name
        size
      }
      status {
        id
        name
      }
      description
      characteristicTag
      remark
    }
  }
`;

const SERVICE_UPDATE = gql`
  mutation ServiceUpdate($input: ServiceUpdateInput) {
    serviceUpdate(input: $input) {
      id
      referenceId
      category {
        id
      }
      nameEng
      nameChi
      thumbnail {
        key
        size
        name
      }
      status {
        id
        name
      }
      description
      characteristicTag
      remark
    }
  }
`;

const StockServiceDetailPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();

  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.CASE_MANAGEMENT,
    ACCESS_LEVELS.FINANCIAL_MANAGEMENT,
    ACCESS_LEVELS.SERVICE_TEAM,
  ]);

  const { TabPane } = Tabs;

  const IS_READ_ONLY = isDenied;

  const [isUploading, setIsUploading] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(true);

  const {
    clearError,
    errors,
    handleSubmit,
    register,
    setValue,
    triggerValidation,
    watch,
    getValues,
    validate,
  } = useForm({
    defaultValues: {
      id: undefined,
      referenceId: undefined,
      categoryId: undefined,
      nameEng: "",
      nameChi: "",
      thumbnail: undefined,
      remark: "",
      characteristicTag: [],
    },
  });

  const { data: tagsData } = useQuery(API);

  const [
    getService,
    {
      loading: getServiceLoading,
      error: getServiceError,
      data: getServiceData,
    },
  ] = useLazyQuery(SERVICE_GET);

  const [
    createService,
    {
      loading: createServiceLoading,
      error: createServiceError,
      data: createServiceData,
    },
  ] = useMutation(SERVICE_CREATE);

  const [
    updateService,
    {
      loading: updateServiceLoading,
      error: updateServiceError,
      data: updateServiceData,
    },
  ] = useMutation(SERVICE_UPDATE);

  const watchValue = watch();

  // console.log("getValues",getValues())
  console.log("getServiceData", getServiceData);

  const query = useMemo(() => {
    const q = queryString.parse(location.search);
    switch (q.tabKey) {
      case "serviceDetail":
      case "priceList": {
        q.page = undefined;
        q.pageSize = undefined;
        break;
      }
      case "activityLog":
      default:
        break;
    }
    return q;
  }, [location.search]);

  useEffect(() => {
    if (id) {
      getService({ variables: { id } });
    }
  }, [getService, id]);

  useEffect(() => {
    const id = createServiceData?.serviceCreate?.id;
    if (id) {
      history.push(
        replaceUrlByParams(PAGE.STOCK_SERVICE_DETAIL, { id: id, mode: "view" })
      );
    }
  }, [createServiceData, history]);

  useEffect(() => {
    const id = updateServiceData?.serviceUpdate?.id;
    if (id) {
      history.push(
        replaceUrlByParams(PAGE.STOCK_SERVICE_DETAIL, { id: id, mode: "view" })
      );
    }
  }, [updateServiceData, history]);

  useEffect(() => {
    if (getServiceData?.serviceGet) {
      const data = getServiceData?.serviceGet;
      setValue("id", data.id);
      setValue("referenceId", data?.referenceId ?? undefined);
      setValue("categoryId", data?.category?.id ?? undefined);
      setValue("nameEng", data?.nameEng ?? "");
      setValue("nameChi", data?.nameChi ?? "");
      setValue("thumbnail", data?.thumbnail ?? undefined);
      setValue("remark", data?.remark ?? "");
      setValue("characteristicTag", data?.characteristicTag ?? []);
    }
  }, [getServiceData, setValue]);

  useEffect(() => {
    register({ name: "id" });
    register({ name: "referenceId" }, { required: true });
    register({ name: "categoryId" }, { required: true });
    register({ name: "nameEng" }, { required: true });
    register({ name: "nameChi" }, { required: true });
    register({ name: "thumbnail" });
    register({ name: "remark" });
    register({ name: "characteristicTag" });
  }, [register]);

  function beforeUpload(file) {
    // size checking
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      notification.error("Image must be smaller than 5MB!");
    }
    return isLt5M;
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const onSaveButtonClick = useCallback(
    async (value) => {
      try {
        let serviceFormValue = JSON.parse(JSON.stringify(value));

        if (serviceFormValue?.thumbnail?.key) {
          serviceFormValue.thumbnail = {
            key: serviceFormValue?.thumbnail?.key,
            name: serviceFormValue?.thumbnail?.name,
            size: serviceFormValue?.thumbnail?.size,
          };
        }
        console.log("serviceFormValue", serviceFormValue);

        if (id) {
          delete serviceFormValue?.service?.thumbnail?.url;
          await updateService({
            variables: {
              input: serviceFormValue,
            },
          });
          message.success("成功更新服務");
        } else {
          await createService({
            variables: {
              input: serviceFormValue,
            },
          });
          message.success("成功新增服務");
        }
      } catch (error) {
        message.error(error.message);
      }
    },
    [id, createService, updateService]
  );

  const handleChange = (info) => {
    console.log("info", info);
    if (info.file.status === "uploading") {
      setIsUploading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        if (info.file.response?.data) {
          const data = info.file.response?.data;
          console.log("data", data);
          setValue("thumbnail", {
            key: data.key,
            url: data.url,
            name: info.file.name,
            size: info.file.size,
          });
        } else {
          setValue("thumbnail", {
            key: info.file.key,
            url: imageUrl,
            name: info.file.name,
            size: info.file.siz,
          });
        }
        setIsUploading(false);
      });
    }
  };

  const onTabChange = (tabKey) => {
    setShowSaveButton(tabKey === "serviceDetail");
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        tabKey,
      }),
    });
  };

  if (!query?.tabKey) {
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({
        tabKey: "serviceDetail",
      }),
    });
  }

  const uploadButton = (
    <div className="service-upload-btn">
      {isUploading ? <Spin /> : <Icon type="camera" theme="filled" />}
      <div className="ant-upload-text">拖拉以上載相片</div>
    </div>
  );

  const BREADCRUMB = [
    {
      path: id ? PAGE.STOCK_SERVICE_DETAIL : PAGE.STOCK_SERVICE_CREATE,
      name: id
        ? `${PAGE_NAME.STOCK_SERVICE_DETAIL} - ${getServiceData?.serviceGet
            ?.referenceId ?? ""}`
        : PAGE_NAME.STOCK_SERVICE_CREATE,
    },
  ];

  return (
    <div className="stock-service-detail">
      <StickyBreadcrumbPageComponent
        className="stock-service-detail-create"
        breadcrumb={BREADCRUMB}
      >
        <div className="status-container">
          <Button
            type="secondary"
            onClick={() => history.push(PAGE.STOCK_SERVICE)}
          >
            返回
          </Button>
          {!IS_READ_ONLY && showSaveButton && (
            <Button type="primary" onClick={handleSubmit(onSaveButtonClick)}>
              儲存
            </Button>
          )}
        </div>
        <div className="content-inner">
          <Form layout="vertical">
            <Tabs activeKey={query?.tabKey} onChange={onTabChange}>
              <TabPane className="section" tab="基本資料" key="serviceDetail">
                <Row xs={2} type="flex">
                  <Col>
                    <Form.Item>
                      <div className="service-uploader-container">
                        {!IS_READ_ONLY ? (
                          <Upload
                            className="service-uploader"
                            headers={{
                              "x-token": getTokenHeader(),
                              "X-Requested-With": null,
                            }}
                            listType="picture-card"
                            action={getFileUploadUrl()}
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                          >
                            {watchValue.thumbnail?.url ? (
                              <img
                                src={watchValue.thumbnail?.url}
                                alt="avatar"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        ) : watchValue.thumbnail?.url ? (
                          <img
                            src={watchValue.thumbnail?.url}
                            alt="avatar"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          "未有相片"
                        )}
                      </div>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <span>選擇分類 及 子類別</span>
                      <CategoryFilter
                        className={errors?.categoryId ? "error" : null}
                        name="categoryId"
                        value={watchValue.categoryId}
                        onChange={(categoryId) => {
                          setValue("categoryId", categoryId);
                        }}
                        disabled={IS_READ_ONLY}
                      />
                      {errors?.categoryId && (
                        <Typography.Text type="danger">
                          請選擇分類
                        </Typography.Text>
                      )}
                    </Form.Item>
                    <Form.Item>
                      <span>服務英文名稱</span>
                      <Input
                        className={errors?.nameEng ? "error" : null}
                        disabled={IS_READ_ONLY}
                        name="nameEng"
                        onChange={({ target: { value } }) =>
                          setValue("nameEng", value)
                        }
                        value={watchValue.nameEng}
                      ></Input>
                      {errors?.nameEng && (
                        <Typography.Text type="danger">
                          請填寫服務英文名稱
                        </Typography.Text>
                      )}
                    </Form.Item>
                    <Form.Item>
                      <span>服務中文名稱</span>
                      <Input
                        className={errors?.nameChi ? "error" : null}
                        disabled={IS_READ_ONLY}
                        name="nameChi"
                        onChange={({ target: { value } }) =>
                          setValue("nameChi", value)
                        }
                        value={watchValue.nameChi}
                      ></Input>
                      {errors?.nameChi && (
                        <Typography.Text type="danger">
                          請填寫服務中文名稱
                        </Typography.Text>
                      )}
                    </Form.Item>
                    <Form.Item>
                      <span>服務編號</span>
                      <Input
                        className={errors?.referenceId ? "error" : null}
                        disabled={IS_READ_ONLY || id}
                        name="referenceId"
                        onChange={({ target: { value } }) =>
                          setValue("referenceId", value)
                        }
                        value={watchValue.referenceId}
                      ></Input>
                      {errors?.referenceId && (
                        <Typography.Text type="danger">
                          請填寫服務編號
                        </Typography.Text>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>服務特點</span>
                      <Select
                        disabled={IS_READ_ONLY}
                        multiple={true}
                        mode="tags"
                        className={errors?.characteristicTag ? "error" : null}
                        name="characteristicTag"
                        onChange={(value) =>
                          setValue("characteristicTag", value)
                        }
                        value={watchValue.characteristicTag}
                      >
                        {(tagsData?.characteristicTagList ?? []).map(
                          (characteristicTag) => (
                            <Select.Option
                              value={characteristicTag}
                              key={characteristicTag}
                            >
                              {characteristicTag}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>備註</span>
                      <Input.TextArea
                        disabled={IS_READ_ONLY}
                        rows={6}
                        className={errors?.remark ? "error" : null}
                        name="remark"
                        onChange={({ target: { value } }) =>
                          setValue("remark", value)
                        }
                        value={watchValue.remark}
                      ></Input.TextArea>
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane className="section" tab="價格" key="priceList">
                <StockServicePriceList />
              </TabPane>
              <TabPane className="section" tab="活動記錄" key="activityLog">
                <ActivityHistoryComponent id={id} type={"Service"} />
              </TabPane>
            </Tabs>
          </Form>
        </div>
      </StickyBreadcrumbPageComponent>
    </div>
  );
};

export default StockServiceDetailPage;
