import React from 'react';

// Components
import OrderingList from "./orderingList";

// Constants
import { PAGE, PAGE_NAME } from '@utils/constants';

function OrderingReserve(props) {

    const BREADCRUMB = [
        {
            path: PAGE.ORDER_IN_SERVICE,
            name: PAGE_NAME.ORDER_IN_SERVICE
        }
    ];

    const availableOrderingStatus = ['inService'];

    return (
        <OrderingList {...props} breadcrumb={BREADCRUMB} availableOrderingStatus={availableOrderingStatus}/>
    );
}

export default OrderingReserve;
