import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Actions
import {
  setUserAction,
  setIsLoadingAction,
  setIsLoggedInAction,
} from "@actions/storeAction";

// Components
import { Layout } from "antd";

// Layouts
import NavbarComponent from "@layouts/navbar/navbar";

// Style
import "./basePage.scss";

// Utils

import queryString from "query-string";

const STAFF_PROFILE = gql`
  {
    staffProfile {
      displayName
      tbId
      username
      accessLevel
    }
  }
`;

function BasePageComponent(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.storeReducer?.user);
  let history = useHistory();
  const loginWithState = {
    pathname: "/login",
    search:
      "fromUrl=" +
      encodeURIComponent(
        queryString.parse(history.location.search).fromUrl ??
          `${history.location.pathname.substring(1)}${history.location.search}`
      ),
  };

  const { children, navBarVisible } = props;

  const [
    staffProfile,
    {
      called: staffProfileCalled,
      loading: staffProfileLoading,
      error: staffProfileError,
      data: staffProfileData,
    },
  ] = useLazyQuery(STAFF_PROFILE);

  useEffect(() => {
    if (!user?.tbId) staffProfile();
  }, [staffProfile, user]);

  useEffect(() => {
    if (staffProfileError) {
      history.push(loginWithState);
    } else if (staffProfileCalled && staffProfileData) {
      dispatch(setUserAction(staffProfileData.staffProfile));
      dispatch(setIsLoggedInAction(true));
      dispatch(setIsLoadingAction(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [
    staffProfileLoading,
    staffProfileError,
    staffProfileData,
    staffProfileCalled,
  ]);

  return (
    <Layout className="Base-page-component">
      {navBarVisible && <NavbarComponent />}
      <Layout className="right-part">
        <Layout.Content className="content">{children}</Layout.Content>
      </Layout>
    </Layout>
  );
}

export default BasePageComponent;

BasePageComponent.propTypes = {
  children: PropTypes.node,
  navBarVisible: PropTypes.bool,
};

BasePageComponent.defaultProps = {
  children: null,
  navBarVisible: true,
};
