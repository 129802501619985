/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import moment from "moment";

// Constants
import { PAGE, PAGE_SIZE } from "@utils/constants";

// Components

// Styles
import "./alertPanelDrawer.scss";

// Utils
import {
  Button,
  Card,
  Col,
  Drawer,
  InputNumber,
  message,
  Row,
  Table,
  Typography,
  notification,
  Icon,
  Skeleton,
  List,
  Spin,
} from "antd";
import { replaceUrlByParams } from "@utils/api";
import { classList } from "@utils/common";

const ALERT_MESSAGE_LIST_GET = gql`
  query alertMessageList($size: Int, $offset: Int) {
    alertMessageList(size: $size, offset: $offset) {
      id
      content
      objectId {
        id
        actionUrl
      }
      actionUrl
      target
      datetime
    }
  }
`;

const AlertPanelDrawer = ({ visible, onClose }) => {
  const [alertMessageOffset, setAlertMessageOffset] = useState(0);
  const [alertMessageListByDate, setAlertMessageListByDate] = useState({});

  const [
    getAlertMessageList,
    { data: getAlertMessageListData, loading: getAlertMessageListLoading },
  ] = useLazyQuery(ALERT_MESSAGE_LIST_GET);

  const refreshAlertList = useCallback(() => {
    setAlertMessageListByDate({});
    setAlertMessageOffset(0);
    getAlertMessageList({
      variables: {
        size: PAGE_SIZE + 1,
        offset: 0,
      },
    });
  }, [getAlertMessageList]);

  const fetchMoreAlertList = useCallback(() => {
    getAlertMessageList({
      variables: {
        size: PAGE_SIZE + 1,
        offset: alertMessageOffset + PAGE_SIZE,
      },
    });
    setAlertMessageOffset((prev) => prev + PAGE_SIZE);
  }, [alertMessageOffset, getAlertMessageList]);

  useEffect(() => {
    if (visible) refreshAlertList();
  }, [refreshAlertList, visible]);

  useEffect(() => {
    if (getAlertMessageListData?.alertMessageList) {
      setAlertMessageListByDate((prevState) => {
        return [...(getAlertMessageListData?.alertMessageList || [])]
          .slice(0, PAGE_SIZE)
          .reduce(
            (accu, curr) => {
              const date = moment(curr.datetime).format("YYYY-MM-DD");
              return { ...accu, [date]: [...(accu[date] || []), curr] };
            },
            { ...prevState }
          );
      });
    }
  }, [getAlertMessageListData]);

  // useEffect(() => {
  //   console.log(alertMessageListByDate);
  // }, [alertMessageListByDate]);

  const drawerTitle = (
    <div className="drawerTitle">
      <Icon className="alert-icon" type="bell" theme="filled" />
      工作提示
      <Button
        type="link"
        disabled={getAlertMessageListLoading}
        onClick={refreshAlertList}
      >
        <Icon type={`${getAlertMessageListLoading ? "loading" : "reload"}`} />
      </Button>
    </div>
  );

  const renderMessageList = useCallback(() => {
    const render = [];
    for (const date in alertMessageListByDate) {
      render.push(
        <List
          key={date}
          dataSource={alertMessageListByDate[date]}
          header={<div className="list-title">{date}</div>}
          rowKey={(item) => item.id}
          renderItem={(item) => (
            <List.Item>
              <Row gutter={[16, 16]}>
                <Col>
                  <div className="alert-content">
                    {item.content}
                    <div>
                      {item.objectId?.map((item) => (
                        <span key={item.id} className="alert-object">
                          <a href={item.actionUrl}>{item.id}</a>
                        </span>
                      ))}
                    </div>
                  </div>
                  <span className="alert-actionUrl">
                    <a href={item.actionUrl}>{item.actionUrl}</a>
                  </span>
                  <div className="alert-datetime">
                    {moment(item.datetime).format("YYYY-MM-DD | HH:mm")}
                  </div>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      );
    }
    if (
      getAlertMessageListData?.alertMessageList?.length > PAGE_SIZE &&
      render.length !== 0
    )
      render.push(
        <div className="load-more" key="load-more">
          <Button block onClick={fetchMoreAlertList}>
            更多...
          </Button>
        </div>
      );

    if (render.length === 0) {
      render.push(
        <div key="empty" className="alertEmpty">
          {getAlertMessageListLoading ? "" : "未有工作提示"}
        </div>
      );
    }
    return render;
  }, [
    alertMessageListByDate,
    fetchMoreAlertList,
    getAlertMessageListData,
    getAlertMessageListLoading,
  ]);

  return (
    <Drawer
      className="alertPanelDrawer"
      title={drawerTitle}
      onClose={onClose}
      visible={visible}
      // visible={true}
      width="30vw"
    >
      <Spin spinning={getAlertMessageListLoading}>{renderMessageList()}</Spin>
    </Drawer>
  );
};

AlertPanelDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

AlertPanelDrawer.defalutProps = {
  className: "",
};

export default AlertPanelDrawer;
