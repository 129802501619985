import Axios from "axios";
import { getApiUrl } from "./env";

const _options = {
	baseURL: getApiUrl(),
	headers: {},
};

export const setToken = (token) => (_options.headers["x-tw-token"] = token);
export const getToken = () => _options.headers["x-tw-token"];

const base = ({ url, params, headers }) => {
	return Axios.get(url, {
		baseURL: _options.baseURL || "",
		params: {
			...params,
		},
		headers: {
			..._options.headers,
			...headers,
		},
	});
};

const get = ({ url, params, headers, responseType }, meta = {}) => {
	return Axios.get(url, {
		baseURL: _options.baseURL || "",
		params: {
			...params,
		},
		responseType,
		headers: {
			..._options.headers,
			...headers,
		},
	})
		.then((r) => (meta.getResponse ? r : r.data))
		.then((data) => {
			if (data.error) {
				throw data.error;
			} else {
				return data;
			}
		})
		.catch((error) => {
			if (error.response && error.response.data.error) {
				throw error.response.data.error;
			} else {
				throw error;
			}
		});
};

const post = (
	{ url, body, headers, responseType, useFormData = false },
	meta = {}
) => {
	if (useFormData && !(body instanceof FormData)) {
		body = Object.entries(body).reduce((formData, [name, value]) => {
			if (value instanceof Blob) {
				formData.set(name, value);
			} else if (typeof value === "object") {
				formData.set(name, JSON.stringify(value));
			} else if (Array.isArray(value)) {
				// TODO: handle if not string array
				formData.set(name, `["${JSON.stringify(value).join("", "")}"]`);
			} else {
				formData.set(name, value);
			}
			return formData;
		}, new FormData());
	}
	return Axios.post(url, body, {
		baseURL: _options.baseURL || "",

		responseType,
		headers: {
			..._options.headers,
			...headers,
		},
	})
		.then((r) => (meta.getResponse ? r : r.data))
		.catch((error) => {
			if (error.response && error.response.data.error) {
				throw error.response.data.error;
			} else {
				throw error;
			}
		});
};

const apiw = {
	base,
	get,
	post,
};

export default apiw;
