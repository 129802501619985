/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Link, useHistory, useLocation } from "react-router-dom";

// Constants
import { PAGE, PAGE_NAME, PAGE_SIZE, ACCESS_LEVELS } from "@utils/constants";

// Layouts
import TableView from "@layouts/tableView/tableView";

// Styles

// Utils
import { replaceUrlByParams } from "@utils/api";
import { Button, Modal, notification, Tag } from "antd";
import Filter from "../../filter/filter";
import { useAccessLevel } from "@utils/hook";

const CONSUMABLE_LIST = gql`
  query consumableList(
    $size: Int!
    $offset: Int!
    $keyword: String
    $categoryId: [String]
    $brand: [String]
    $dimension: [String]
    $characteristicTag: [String]
    $reverseOrder: Boolean
    $sort: SortProductList
  ) {
    consumableList(
      size: $size
      offset: $offset
      keyword: $keyword
      categoryId: $categoryId
      brand: $brand
      dimension: $dimension
      characteristicTag: $characteristicTag
      reverseOrder: $reverseOrder
      sort: $sort
    ) {
      id
      referenceId
      productInfo {
        modelNumber
        nameChi
      }
      quantity
      category {
        id
        name
        mainCategory {
          id
          name
        }
        minInventory
      }
    }
  }
`;

const CONSUMABLE_DELETE = gql`
  mutation consumableDelete($id: ID) {
    consumableDelete(id: $id)
  }
`;

const CONSUMABLE_COUNT = gql`
  query consumableCount(
    $categoryId: [String]
    $brand: [String]
    $dimension: [String]
    $characteristicTag: [String]
    $keyword: String
  ) {
    consumableCount(
      categoryId: $categoryId
      brand: $brand
      dimension: $dimension
      characteristicTag: $characteristicTag
      keyword: $keyword
    )
  }
`;

function ConsumableListPage(props) {
  const history = useHistory();
  const { location } = props;

  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.CASE_MANAGEMENT,
  ]);

  const BREADCRUMB = [
    {
      path: PAGE.STOCK_CONSUMABLE,
      name: PAGE_NAME.STOCK_CONSUMABLE,
    },
  ];

  const [deleteConsumableParams, setDeleteConsumableParams] = useState({
    visible: false,
  });

  const columns = [
    {
      title: "款號",
      width: "10%",
      dataIndex: "referenceId",
      sorter: true,
      render: (text, record) => (
        <Link
          className="link"
          to={replaceUrlByParams(PAGE.STOCK_CONSUMABLE_DETAIL, {
            id: record.id,
            mode: "view",
          })}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "型號",
      width: "10%",
      dataIndex: "productInfo.modelNumber",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "名稱",
      dataIndex: "productInfo.nameChi",
    },
    {
      title: "分類",
      dataIndex: "category.mainCategory.name",
    },
    {
      title: "子類別",
      width: "15%",
      dataIndex: "category",
      render: (category) => {
        return (
          <Link
            className="link"
            onClick={(e) => {
              e.preventDefault();
              history.push({
                search: queryString.stringify({
                  page: 1,
                  pageSize: PAGE_SIZE,
                  categoryId: category.id,
                }),
              });
            }}
          >
            {category.name}
          </Link>
        );
      },
    },
    {
      title: "存貨數量",
      dataIndex: "quantity",
    },
    {
      title: "最低存貨量",
      dataIndex: "category.minInventory",
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record) =>
        record.quantity < record.category.minInventory && (
          <Tag className="tag warning">補貨</Tag>
        ),
    },
    {
      title: "",
      render: (record) => {
        return (
          !isDenied && (
            <Button
              type="danger"
              onClick={() =>
                setDeleteConsumableParams({ visible: true, record })
              }
            >
              刪除
            </Button>
          )
        );
      },
    },
  ];

  const [
    getConsumableList,
    {
      loading: consumableListLoading,
      error: consumableListError,
      data: consumableListData,
    },
  ] = useLazyQuery(CONSUMABLE_LIST);
  const [
    getConsumableCount,
    { loading: consumableCountLoading, data: consumableCountData },
  ] = useLazyQuery(CONSUMABLE_COUNT);

  const [consumableDelete] = useMutation(CONSUMABLE_DELETE);

  const [isFilterShow, setIsFilterShow] = useState(false);

  // fetch PAGE_SIZE + 1, to check if there is next page.
  const consumableList = useMemo(() => {
    return (consumableListData?.consumableList || []).slice(0, PAGE_SIZE);
  }, [consumableListData]);
  const isMaxPage = useMemo(() => {
    const queryParams = queryString.parse(location.search);
    return (
      queryParams.pageSize >= (consumableListData?.consumableList || []).length
    );
  }, [consumableListData, location.search]);

  const [query, setQuery] = useState(queryString.parse(location.search));
  useEffect(() => {
    setQuery(queryString.parse(location.search));
  }, [location.search]);

  const fetchList = useCallback(() => {
    const {
      page,
      pageSize,
      keyword,
      sortField,
      categoryId,
      brand,
      dimension,
      characteristicTag,
      sortOrder,
    } = queryString.parse(location.search);

    const queryParamToArray = (x) => (x ? (!Array.isArray(x) ? [x] : x) : []);

    getConsumableList({
      variables: {
        offset: +((page - 1) * pageSize) || 0,
        size: +(pageSize || PAGE_SIZE) + 1,
        keyword: keyword,
        sort: sortField,
        categoryId: categoryId,
        brand: queryParamToArray(brand),
        dimension: queryParamToArray(dimension),
        characteristicTag: queryParamToArray(characteristicTag),
        reverseOrder: sortOrder ? sortOrder === "descend" : undefined,
      },
    });

    getConsumableCount({
      variables: {
        keyword: keyword,
        categoryId: categoryId,
        brand: queryParamToArray(brand),
        dimension: queryParamToArray(dimension),
        characteristicTag: queryParamToArray(characteristicTag),
      },
    })
  }, [getConsumableCount, getConsumableList, location.search]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const onFilterSearch = () => {
    history.push({
      search: queryString.stringify(query),
    });
  };

  const onFilterClick = (state) => {
    setIsFilterShow(state);
  };

  const onDeleteConsumable = async (record) => {
    try {
      const { data } = await consumableDelete({
        variables: { id: record?.id },
      });
      if (data.consumableDelete) {
        notification.success({
          message: `成功刪除${record?.referenceId}`,
          duration: 5,
        });
        fetchList();
      } else {
        notification.error({
          message: `刪除${record?.referenceId}失敗`,
          duration: 5,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        title={`刪除${BREADCRUMB[0].name}`}
        visible={deleteConsumableParams.visible}
        onOk={() => {
          onDeleteConsumable(deleteConsumableParams?.record);
          setDeleteConsumableParams((_) => ({ ..._, visible: false }));
        }}
        onCancel={() =>
          setDeleteConsumableParams((_) => ({ ..._, visible: false }))
        }
        okText="確定"
        cancelText="取消"
        okButtonProps={{ type: "danger" }}
      >
        <div>
          確定{`刪除${BREADCRUMB[0].name}`}{" "}
          {deleteConsumableParams?.record?.referenceId} ?
        </div>
        <div>所有有關存貨亦會被刪除。</div>
      </Modal>
      <TableView
        {...props}
        addBtnConfig={{
          url: PAGE.STOCK_CONSUMABLE_CREATE,
          text: "新增消耗品",
        }}
        breadcrumb={BREADCRUMB}
        rowKey="id"
        columns={columns}
        {...(!isDenied && { hasAddBtn: true })}
        hasFilter
        isFilterShow={isFilterShow}
        isMaxPage={isMaxPage}
        isTableLoading={consumableListLoading}
        onFilterClick={onFilterClick}
        searchBarPlaceholder="搜尋消耗品"
        tableData={consumableList}
        total={consumableCountData?.consumableCount}
      >
        <Filter
          filterType={[""]}
          filterValue={query}
          onFilterSearch={onFilterSearch}
          onResetFilter={() => {
            history.push({
              search: queryString.stringify({ page: 1, pageSize: PAGE_SIZE }),
            });
          }}
          isFilterShow={isFilterShow}
          onFilterValueChange={setQuery}
        />
      </TableView>
    </>
  );
}

export default ConsumableListPage;

ConsumableListPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
