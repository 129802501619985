/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Styles
import "./paymentStatementEdit.scss";

// Utils
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Input,
  message,
  Row,
  Select,
} from "antd";
import { MOMENT_FORMAT } from "@utils/constants";
import moment from "moment";

const API = gql`
  query EnumList {
    enumPaymentStatusList {
      id
      name
    }
  }
`;

const PAYMENT_STATEMENT_UPDATE = gql`
  mutation PaymentStatementUpdate($input: PaymentStatementUpdateInput!) {
    paymentStatementUpdate(input: $input) {
      id
    }
  }
`;

const PaymentStatementEdit = ({
  paymentStatement,
  visible,
  onClose,
  onSuccessEdit,
}) => {
  const [
    updatePaymentStatement,
    {
      loading: updatePaymentStatementLoading,
      data: updatePaymentStatementData,
    },
  ] = useMutation(PAYMENT_STATEMENT_UPDATE);

  const { data: enumList } = useQuery(API);

  const [statusId, setStatusId] = useState(undefined);
  const [availablePaymentStatus, setAvailablePaymentStatus] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState(undefined);
  const [paymentDueDate, setPaymentDueDate] = useState(undefined);
  // const [invoiceNumber, setInvoiceNumber] = useState(undefined);
  const [remark, setRemark] = useState("");

  const getStatusOptions = () => {
    let allowedStatus = [];
    switch (paymentStatement?.status?.id) {
      case "pending":
        allowedStatus = ["pending", "paidManually"];
        break;
      case "paid":
        allowedStatus = ["paid", "confirmed"];
        break;
      case "overdue":
        allowedStatus = ["pending", "overdue", "paidManually"];
        break;
      case "paidManually":
        allowedStatus = ["pending", "paidManually"];
        break;
      case "confirmed":
        allowedStatus = ["confirmed"];
        break;
      default:
        allowedStatus = [];
    }
    return enumList?.enumPaymentStatusList?.map((option) => (
      <Select.Option
        key={option.id}
        value={option.id}
        disabled={!allowedStatus.includes(option.id)}
      >
        {option.name}
      </Select.Option>
    ));
  };

  useEffect(() => {
    if (paymentStatement) {
      setStatusId(paymentStatement.status.id);
      setPaymentMethodId(paymentStatement.paymentMethod?.id);
      setPaymentDueDate(paymentStatement.paymentDueDate);
      // setInvoiceNumber(paymentStatement.invoiceNumber);
      setRemark(paymentStatement?.remark);
    }
  }, [paymentStatement]);

  const onStatusChange = (value) => {
    setStatusId(value);
  };

  const onPaymentMethodChange = (value) => {
    setPaymentMethodId(value);
  };

  const onPaymentDueDateChange = (date, dateString) => {
    setPaymentDueDate(dateString);
  };

  // const onInvoiceNumberChange = (e) => {
  //   setInvoiceNumber(e.target.value);
  // };

  const onConfirmClick = useCallback(async () => {
    try {
      const input = {
        id: paymentStatement.id,
        referenceID: paymentStatement.referenceId,
        paymentDueDate: paymentDueDate,
        status: statusId,
        paymentMethod: paymentMethodId,
        // invoiceNumber: invoiceNumber ?? undefined,
        remark,
      };
      await updatePaymentStatement({
        variables: {
          input: input,
        },
      });
      message.success("成功更新帳單項目");
      onSuccessEdit();
    } catch (error) {
      message.error(error.message);
    }
    // eslint-disable-next-line
  }, [
    updatePaymentStatement,
    paymentStatement,
    paymentDueDate,
    statusId,
    paymentMethodId,
    // invoiceNumber,
    remark,
  ]);

  return (
    <div className="PaymentStatementEdit">
      <Drawer
        className="PaymentStatementEdit-drawer"
        width="50vw"
        visible={visible}
        onClose={onClose}
      >
        <div className="PaymentStatementEdit-content">
          <h1>編輯帳單</h1>
          <div>
            <Row>
              <Col>
                <div className="header">
                  <span>付款狀態</span>
                </div>
                <div>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="請選擇付款狀態"
                    value={statusId}
                    onChange={onStatusChange}
                  >
                    {/* {availablePaymentStatus?.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))} */}
                    {getStatusOptions()}
                  </Select>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col>
                <div className="header">
                  <span>付款方式</span>
                </div>
                <div>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="請選擇付款方式"
                    value={paymentMethodId}
                    onChange={onPaymentMethodChange}
                  >
                    <Select.Option key="FPS" value="FPS">
                      轉數快
                    </Select.Option>
                    <Select.Option key="sevenEleven" value="sevenEleven">
                      7-11便利店
                    </Select.Option>
                  </Select>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col>
                <div className="header">
                  <span>應繳日期</span>
                </div>
                <div>
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="請選擇日期"
                    value={
                      paymentDueDate
                        ? moment(paymentDueDate, MOMENT_FORMAT)
                        : null
                    }
                    onChange={onPaymentDueDateChange}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col>
                <div className="header">
                  <span>備註</span>
                </div>
                <div>
                  <Input.TextArea
                    style={{ width: "100%" }}
                    placeholder="請填寫備註"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col>
                <div className="header">
                  <span>負責人</span>
                </div>
                <div>
                  {paymentStatement?.personInCharge
                    ? paymentStatement?.personInCharge.displayName
                    : "-"}
                </div>
              </Col>
            </Row>
          </div>
          <div className="btn-container">
            <Row type="flex" gutter={12}>
              <Col>
                <Button className="btn btn-secondary" onClick={onClose}>
                  取消
                </Button>
              </Col>
              <Col>
                <Button className="btn" type="primary" onClick={onConfirmClick}>
                  儲存
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

PaymentStatementEdit.propTypes = {
  paymentStatement: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccessEdit: PropTypes.func,
};

PaymentStatementEdit.defaultProps = {
  visible: false,
};

export default PaymentStatementEdit;
