/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// Actions
import {
  setIsLoggedInAction,
  setUserAction,
  setIsLoadingAction,
} from "@actions/storeAction";

// Components
import { Typography, Avatar, Dropdown, Card, notification } from "antd";

// Constants
import { PAGE, PAGE_NAME, KEY } from "@utils/constants";

// Style
import "./navbar.scss";

// Utils
import { setTokenHeader } from "@utils/api";

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

function NavbarComponent() {
  const dispatch = useDispatch();
  let history = useHistory();
  const store = useSelector((state) => state.storeReducer);

  const { user } = store;

  const [
    logout,
    { loading: logoutLoading, error: logoutError, data: logoutData },
  ] = useMutation(LOGOUT);

  useEffect(() => {
    if (logoutData) {
      if (logoutData.logout) {
        setTokenHeader();
        dispatch(setIsLoggedInAction(false));
        dispatch(setUserAction());
        history.push("/login");
      } else {
        notification.error({
          message: "請重試",
        });
      }

      dispatch(setIsLoadingAction(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutLoading, logoutError, logoutData]);

  const onLogoutClick = async () => {
    dispatch(setIsLoadingAction(true));
    await logout();
  };

  const userCard = (
    <div>
      <Card id="navbar-user-card" selectable="true">
        <div>
          <div className="username">{user.displayName}</div>
        </div>
        <div className="link-container">
          {history.location.pathname !== "/mobile" && (
            <Link
              to={history.location.pathname.replace(/^\/mobile/g, "/desktop")}
            >
              <div>
                <Typography.Text>切換到電腦版本</Typography.Text>
              </div>
            </Link>
          )}
          <Link to={PAGE.WORKSHOP_DASHBOARD}>
            <div>
              <Typography.Text>{PAGE_NAME.WORKSHOP_DASHBOARD}</Typography.Text>
            </div>
          </Link>
          <Link to={PAGE.MOBILE_SCANNER}>
            <div>
              <Typography.Text>{PAGE_NAME.MOBILE_SCANNER}</Typography.Text>
            </div>
          </Link>
        </div>
        <Typography.Text className="logout-btn" onClick={onLogoutClick}>
          登出
        </Typography.Text>
      </Card>
    </div>
  );

  return (
    <div className="mobile-logo-container" style={{ display: "inline-flex" }}>
      {/*TODO TBC*/}
      {/*<div className='logo'/>
       */}
      <Dropdown overlay={userCard}>
        <div>
          <Avatar>{user.displayName[0]}</Avatar>
        </div>
      </Dropdown>
    </div>
  );
}

export default NavbarComponent;
