/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo } from "react";
// Components
// eslint-disable-next-line no-unused-vars
import { Button, Radio, Modal, Select, Typography, message, Spin } from "antd";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import { PAGE } from "@utils/constants";
import { replaceUrlByParams } from "@utils/api";
import { Controller, useForm } from "react-hook-form";

const INVENTORY_INSPECTION_CREATE = gql`
  mutation InventoryInspectionCreate($input: InventoryInspectionCreateInput) {
    inventoryInspectionCreate(input: $input) {
      id
    }
  }
`;

const INVENTORY_RECORD_GET = gql`
  query inventoryRecordGet($id: ID!) {
    inventoryRecordGet(id: $id) {
      product {
        id
        ... on Device {
          inspection {
            id
            name
          }
        }
        ... on Part {
          inspection {
            id
            name
          }
        }
      }
    }
  }
`;

const InventoryInspectionCreateModal = ({
  visible,
  params: { inventoryRecordId, orderingItemId, inspectionType },
  onClose,
}) => {
  const { Option } = Select;
  const history = useHistory();
  const { Text } = Typography;

  const { handleSubmit, control, errors } = useForm({
    defaultValues: {
      inventoryRecordId: null,
      orderingItemId: null,
      inspectionType: "centerInspection",
      inspectionId: null,
    },
  });

  const [inventoryRecordGet, { data, loading, error }] = useLazyQuery(
    INVENTORY_RECORD_GET
  );

  useEffect(() => {
    if (visible) {
      inventoryRecordGet({ variables: { id: inventoryRecordId } });
    }
  }, [inventoryRecordGet, inventoryRecordId, visible]);

  const inspections = data?.inventoryRecordGet?.product?.inspection ?? [];

  const [
    createInventoryInspection,
    { loading: inventoryInspectionLoading, data: inventoryInspectionData },
  ] = useMutation(INVENTORY_INSPECTION_CREATE);

  useEffect(() => {
    if (inventoryInspectionData) {
      history.push(
        replaceUrlByParams(PAGE.MOBILE_INSPECTION_FORM, {
          id: inventoryInspectionData?.inventoryInspectionCreate?.id,
        })
      );
    }
  }, [history, inventoryInspectionData]);

  const onClickSumbit = useCallback(
    async ({ inspectionId }) => {
      try {
        await createInventoryInspection({
          variables: {
            input: {
              inspectionId,
              inventoryRecordId,
              inspectionType,
              ...(orderingItemId && { orderingItemId }),
            },
          },
        });
      } catch (error) {
        message.error(error.message);
      } finally {
        onClose();
      }
    },
    [
      createInventoryInspection,
      inventoryRecordId,
      inspectionType,
      orderingItemId,
      onClose,
    ]
  );

  return (
    <Modal
      title={
        inspectionType === "doorToDoorInspection"
          ? `新增上門檢查/維修紀錄表`
          : `新增中心檢查/維修紀錄表`
      }
      centered
      visible={visible}
      width={500}
      onCancel={onClose}
      footer={
        inspections?.length > 0 && (
          <Button
            loading={inventoryInspectionLoading}
            onClick={handleSubmit(onClickSumbit)}
          >
            確定
          </Button>
        )
      }
    >
      <Spin spinning={loading}>
        <div className="vertical-container">
          <div className="v-space">
            <div>
              <Typography>紀錄表名稱</Typography>
            </div>
            {inspections?.length > 0 ? (
              <div>
                <Controller
                  control={control}
                  name="inspectionId"
                  rules={{
                    required: "請選擇記錄表",
                  }}
                  as={
                    <Select style={{ minWidth: "380px" }}>
                      {inspections.map((record) => (
                        <Option value={record.id} key={record.id}>
                          {record.name}
                        </Option>
                      ))}
                    </Select>
                  }
                ></Controller>
                <br />
                <Text type="danger">{errors?.inspectionId?.message}</Text>
              </div>
            ) : (
              <Typography>沒有適用的檢查紀錄表，請創建一個</Typography>
            )}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default InventoryInspectionCreateModal;

InventoryInspectionCreateModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
