/* eslint-disable */
import React, { useState } from 'react';
import ContractDrawer from '@pages/demo/contract/drawer'

import { Button } from 'antd';

import './contract.scss';


function DemoContract() {
    const FAKE_USER_ID = 1234
    const [shouldDrawerTrigger, setDrawer] = useState(false);

    const showDrawer = () => {
        setDrawer(!shouldDrawerTrigger);
        
    };

    return (
        <React.Fragment>
            <Button type="primary" onClick={showDrawer}>
                click me
            </Button>
            <p>current user id: {FAKE_USER_ID}</p>
            <ContractDrawer userId={FAKE_USER_ID} shouldDrawerTrigger={shouldDrawerTrigger} showDrawer={showDrawer} />
        </React.Fragment>
    )

}

export default DemoContract;