import {
  Form,
  Button,
  Input,
  Row,
  Col,
  Select,
  Typography,
  Icon,
  notification,
  Spin,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { gql } from "@apollo/client";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

// Components

// Constants
import { PAGE, PAGE_NAME, ACCESS_LEVELS } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

//Styles
import "./cleaningFlowEdit.scss";

// Utils
import { replaceUrlByParams } from "@utils/api";
import Picker from "@pages/stock/picker/picker";
import Text from "antd/lib/typography/Text";
import { useAccessLevel } from "@utils/hook";

const API = gql`
  query cleaningStageList {
    cleaningStageList {
      id
      name
      remark
    }
  }
`;

const CLEANINGFLOW_GET = gql`
  query cleaningFlowGet($id: ID) {
    cleaningFlowGet(id: $id) {
      name
      device {
        id
        referenceId
      }
      part {
        id
        referenceId
      }
      cleaningStage {
        id
        name
        remark
      }
      remark
    }
  }
`;

const CLEANINGFLOW_CREATE = gql`
  mutation cleaningFlowCreate($input: CleaningFlowCreateInput) {
    cleaningFlowCreate(input: $input) {
      id
    }
  }
`;

const CLEANINGFLOW_UPDATE = gql`
  mutation cleaningFlowUpdate($input: CleaningFlowUpdateInput) {
    cleaningFlowUpdate(input: $input) {
      id
    }
  }
`;

const CleaningFlowEdit = () => {
  const { id } = useParams();
  const history = useHistory();
  const { Option } = Select;

  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.WORKSHOP_MANAGEMENT,
  ]);

  const BREADCRUMB = [
    {
      path: id
        ? PAGE.WORKSHOP_CLEANING_FLOW_EDIT
        : PAGE.WORKSHOP_CLEANING_FLOW_CREATE,
      name: id
        ? PAGE_NAME.WORKSHOP_CLEANING_FLOW_EDIT
        : PAGE_NAME.WORKSHOP_CLEANING_FLOW_CREATE,
    },
  ];

  const { data: APIData, loading: APILoading } = useQuery(API);

  const [
    createCleaningFlow,
    { data: createCleaningFlowData, loading: createCleaningFlowLoading },
  ] = useMutation(CLEANINGFLOW_CREATE);

  const [
    updateCleaningFlow,
    { loading: updateCleaningFlowLoading },
  ] = useMutation(CLEANINGFLOW_UPDATE);

  const [
    getCleaningFlow,
    { data: getCleaningFlowData, loading: getCleaningFlowLoading },
  ] = useLazyQuery(CLEANINGFLOW_GET);

  const [pickerParams, setPickerParams] = useState({
    visible: false,
  });

  const isLoading =
    createCleaningFlowLoading ||
    updateCleaningFlowLoading ||
    getCleaningFlowLoading ||
    APILoading;

  useEffect(() => {
    if (id) getCleaningFlow({ variables: { id: id } });
  }, [getCleaningFlow, id]);

  const cleaningStageOptions = useMemo(
    () =>
      APIData?.cleaningStageList?.map((stage) => {
        if (stage.id === "other") return null;
        else
          return (
            <Option key={stage.id} value={stage.id}>
              {stage.name}
            </Option>
          );
      }),
    [APIData]
  );

  const { handleSubmit, register, setValue, watch, errors } = useForm({
    defaultValues: {
      name: "",
      deviceId: [],
      partId: [],
      remark: "",
      cleaningStageId: [],
    },
  });

  const watchValue = watch();

  useEffect(() => {
    register({ name: "name" }, { required: true });
    register({ name: "deviceId" });
    register({ name: "partId" });
    register({ name: "remark" });
    register(
      { name: "cleaningStageId" },
      { validate: (value) => value.length > 0 }
    );
  }, [register]);

  useEffect(() => {
    if (getCleaningFlowData && getCleaningFlowData?.cleaningFlowGet) {
      const { cleaningFlowGet } = getCleaningFlowData;
      setValue("name", cleaningFlowGet.name);
      setValue(
        "deviceId",
        cleaningFlowGet.device.map((item) => ({
          key: item.id,
          label: item.referenceId,
        }))
      );
      setValue(
        "partId",
        cleaningFlowGet.part.map((item) => ({
          key: item.id,
          label: item.referenceId,
        }))
      );
      setValue("remark", cleaningFlowGet.remark);
      setValue(
        "cleaningStageId",
        cleaningFlowGet.cleaningStage.map((item) => item.id)
      );
    }
  }, [getCleaningFlowData, setValue]);

  const onAddDeviceChange = (data) => {
    const newValue = [...watchValue?.deviceId];
    for (const { id, referenceId } of data) {
      if (watchValue?.deviceId.find((item) => item.key === id)) continue;
      newValue.push({ key: id, label: referenceId });
    }
    setValue("deviceId", newValue);
  };

  const onAddPartChange = (data) => {
    const newValue = [...watchValue?.partId];
    for (const { id, referenceId } of data) {
      if (watchValue?.partId.find((item) => item.key === id)) continue;
      newValue.push({ key: id, label: referenceId });
    }
    setValue("partId", newValue);
  };

  const onSaveButtonClick = async (data) => {
    const input = { ...data };
    input.deviceId = data.deviceId.map((item) => item.key);
    input.partId = data.partId.map((item) => item.key);

    try {
      if (id) {
        input.id = id;
        await updateCleaningFlow({ variables: { input } });
      } else {
        await createCleaningFlow({ variables: { input } });
      }
      notification.success({
        message: "更新成功",
        duration: 5,
      });
    } catch (err) {
      notification.error({
        message: "更新失敗",
      });
    }
  };

  useEffect(() => {
    if (
      createCleaningFlowData &&
      createCleaningFlowData?.cleaningFlowCreate?.id
    ) {
      history.push(
        replaceUrlByParams(PAGE.WORKSHOP_CLEANING_FLOW_EDIT, {
          id: createCleaningFlowData?.cleaningFlowCreate?.id,
        })
      );
    }
  }, [createCleaningFlowData, history]);

  const CleaningStageItem = SortableElement(
    ({ value, itemIndex, isDisabled }) => (
      <div className={`cleaningStageItem ${!isDisabled && "draggable"}`}>
        {`${itemIndex + 1}. ${
          APIData?.cleaningStageList?.find((stage) => stage.id === value)?.name
        }`}
        <Button
          type="primary"
          onClick={() => {
            const newArray = [...watchValue?.cleaningStageId];
            newArray.splice(itemIndex, 1);
            setValue("cleaningStageId", newArray);
          }}
        >
          {!isDisabled && <Icon type="close" />}
        </Button>
      </div>
    )
  );

  const CleaningStageContainer = SortableContainer(({ items, isDisabled }) => {
    return (
      <div className="cleaningStageContainer">
        {items.map((stage, index) => (
          <CleaningStageItem
            key={`item-${stage}`}
            index={index}
            value={stage}
            itemIndex={index}
            isDisabled={isDisabled}
            disabled={isDisabled}
          />
        ))}
      </div>
    );
  });

  return (
    <Spin spinning={isLoading}>
      <div className="cleaingFlow-edit">
        <StickyBreadcrumbPageComponent
          className="cleaningFlow-edit-page"
          breadcrumb={BREADCRUMB}
        >
          <div className="status-container">
            <Button
              type="secondary"
              onClick={() =>
                history.push(
                  replaceUrlByParams(PAGE.WORKSHOP_CLEANING_FLOW, {
                    id: id,
                  })
                )
              }
            >
              {!isDenied ? "取消" : "返回"}
            </Button>
            {!isDenied && (
              <Button type="primary" onClick={handleSubmit(onSaveButtonClick)}>
                儲存
              </Button>
            )}
          </div>

          <div className="content-inner">
            <Form>
              <Row gutter={[16, 16]} type="flex">
                <Col span={12}>
                  <Col>
                    <Form.Item>
                      <Text>名稱</Text>
                      <Input
                        disabled={isDenied}
                        type="text"
                        onChange={({ target: { value } }) =>
                          setValue("name", value)
                        }
                        value={watchValue?.name}
                      />
                      {errors?.name && (
                        <Typography.Text type="danger">
                          請填寫清潔流程名稱
                        </Typography.Text>
                      )}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Text>貨物款號</Text>
                      {!isDenied && (
                        <Button
                          className="btn btn-secondary"
                          style={{ display: "block" }}
                          onClick={() =>
                            setPickerParams({
                              pickerType: "device",
                              visible: true,
                              visibleColumns: ["referenceId", "name"],
                              multiple: true,
                              onChange: onAddDeviceChange,
                              onClose: () =>
                                setPickerParams({
                                  visible: false,
                                }),
                            })
                          }
                        >
                          新增
                        </Button>
                      )}
                      <Select
                        disabled={isDenied}
                        mode="multiple"
                        value={watchValue?.deviceId}
                        onChange={(data) => setValue("deviceId", data)}
                        allowClear={true}
                        open={false}
                        labelInValue={true}
                      ></Select>
                      {errors?.deviceId && (
                        <Typography.Text type="danger">
                          請選擇貨物款號
                        </Typography.Text>
                      )}
                    </Form.Item>
                    <Form.Item>
                      <Text>零件款號</Text>
                      {!isDenied && (
                        <Button
                          className="btn btn-secondary"
                          style={{ display: "block" }}
                          onClick={() =>
                            setPickerParams({
                              pickerType: "part",
                              visible: true,
                              visibleColumns: ["referenceId", "name"],
                              multiple: true,
                              onChange: onAddPartChange,
                              onClose: () =>
                                setPickerParams({
                                  visible: false,
                                }),
                            })
                          }
                        >
                          新增
                        </Button>
                      )}
                      <Select
                        disabled={isDenied}
                        mode="multiple"
                        value={watchValue?.partId}
                        onChange={(data) => setValue("partId", data)}
                        allowClear={true}
                        open={false}
                        labelInValue={true}
                      ></Select>
                      {errors?.partId && (
                        <Typography.Text type="danger">
                          請選擇零件款號
                        </Typography.Text>
                      )}
                    </Form.Item>
                    <Form.Item>
                      <Text>備註</Text>
                      <Input.TextArea
                        disabled={isDenied}
                        rows={4}
                        onChange={({ target: { value } }) =>
                          setValue("remark", value)
                        }
                        value={watchValue?.remark}
                      />
                    </Form.Item>
                  </Col>
                </Col>
                <Col span={12}>
                  <Col>
                    <Form.Item>
                      <Text>清潔流程</Text>
                      {!isDenied && (
                        <Select
                          value={undefined}
                          onChange={(value) => {
                            setValue("cleaningStageId", [
                              ...watchValue?.cleaningStageId,
                              value,
                            ]);
                          }}
                          loading={APILoading}
                          placeholder="新增清潔步驟"
                        >
                          {cleaningStageOptions}
                        </Select>
                      )}
                      {errors?.cleaningStageId && (
                        <Typography.Text type="danger">
                          請選擇清潔步驟
                        </Typography.Text>
                      )}
                    </Form.Item>
                    <Form.Item>
                      <CleaningStageContainer
                        isDisabled={isDenied}
                        items={watchValue?.cleaningStageId}
                        onSortEnd={({ oldIndex, newIndex }) => {
                          if (oldIndex !== newIndex)
                            setValue(
                              "cleaningStageId",
                              arrayMove(
                                watchValue?.cleaningStageId,
                                oldIndex,
                                newIndex
                              )
                            );
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            </Form>
          </div>
          {pickerParams.visible && <Picker {...pickerParams} />}
        </StickyBreadcrumbPageComponent>
      </div>
    </Spin>
  );
};

export default CleaningFlowEdit;
