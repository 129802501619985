/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Row, DatePicker, Typography, Spin } from "antd";
import { useForm } from "react-hook-form";
import { gql } from "@apollo/client";
import queryString from "query-string";
import moment from "moment";

// Constants
import { DEMO_PAGE, PAGE, PAGE_NAME } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

// Styles
import "./overview.scss";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useMemo } from "react";

const GET_DASHBOARD = gql`
  query dashboardGet($start: LocalDate!, $end: LocalDate!) {
    dashboardGet {
      userDashboard(start: $start, end: $end) {
        newRegistration
      }
      deliveryDashboard(start: $start, end: $end) {
        pendingOrderInDelivery
        confirmedOrderInDelivery
        checkedOutOrderInDelivery
      }
      userServiceDashboard {
        registeredUser
        terminatedUser
      }
      inventoryDashBoard {
        device
        part
        consumable
      }
      orderDashboard {
        unpaid
        inService
      }
    }
  }
`;

function OverviewPage() {
  const [queryDate, setQueryDate] = useState([
    moment().subtract("1", "day"),
    moment(),
  ]);

  const BREADCRUMB = [
    {
      path: PAGE.OVERVIEW,
      name: PAGE_NAME.OVERVIEW,
    },
  ];

  const [getDashboard, { data, loading }] = useLazyQuery(GET_DASHBOARD);

  const dashboard = useMemo(() => data?.dashboardGet, [data]);

  useEffect(() => {
    getDashboard({
      variables: {
        start: queryDate[0].format("YYYY-MM-DD"),
        end: queryDate[1].format("YYYY-MM-DD"),
      },
    });
  }, [getDashboard, queryDate]);

  return (
    <StickyBreadcrumbPageComponent
      className="Overview-page"
      breadcrumb={BREADCRUMB}
    >
      <Spin spinning={loading}>
        <div className="content-inner">
          <div className="dashboard-container">
            <Row gutter={[24, 0]}>
              <Col>
                <div
                  className="dashboard-col"
                  style={{ padding: "16px", marginBottom: "24px" }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span="24">
                      <DatePicker.RangePicker
                        allowClear={false}
                        value={queryDate}
                        onChange={(dates) => setQueryDate(dates)}
                        placeholder={["開始日", "結束日"]}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} type="flex" align="middle">
                    <Col span="4" style={{ borderRight: "2px solid darkgray" }}>
                      <div className="dashboard-item dashboard-item-title">
                        用戶狀態
                      </div>
                    </Col>
                    <Col span="4">
                      <div className="dashboard-item dashboard-item-description">
                        <span>新增用戶</span>
                        <Link
                          to={{
                            pathname: PAGE.USER_REGISTERED,
                            search: queryString.stringify({
                              startRegistrationDate: queryDate[0].format(
                                "YYYY-MM-DD"
                              ),
                              endRegistrationDate: queryDate[1].format(
                                "YYYY-MM-DD"
                              ),
                            }),
                          }}
                        >
                          {dashboard?.userDashboard?.newRegistration ?? "..."}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} type="flex" align="middle">
                    <Col span="4" style={{ borderRight: "2px solid darkgray" }}>
                      <div className="dashboard-item dashboard-item-title">
                        運送安排狀態
                      </div>
                    </Col>
                    <Col span="4">
                      <div className="dashboard-item dashboard-item-description">
                        <span>待命安排</span>
                        <Link
                          to={{
                            pathname: PAGE.STOCK_DELIVERY_ALL,
                            search: queryString.stringify({
                              deliveryStatus: "pending",
                              deliveryStartDate: queryDate[0].format(
                                "YYYY-MM-DD"
                              ),
                              deliveryEndDate: queryDate[1].format(
                                "YYYY-MM-DD"
                              ),
                            }),
                          }}
                        >
                          {dashboard?.deliveryDashboard
                            ?.pendingOrderInDelivery ?? "..."}
                        </Link>
                      </div>
                    </Col>
                    <Col span="4">
                      <div className="dashboard-item dashboard-item-description">
                        <span>已確認安排</span>
                        <Link
                          to={{
                            pathname: PAGE.STOCK_DELIVERY_ALL,
                            search: queryString.stringify({
                              deliveryStatus: "confirmedDelivery",
                              deliveryStartDate: queryDate[0].format(
                                "YYYY-MM-DD"
                              ),
                              deliveryEndDate: queryDate[1].format(
                                "YYYY-MM-DD"
                              ),
                            }),
                          }}
                        >
                          {dashboard?.deliveryDashboard
                            ?.confirmedOrderInDelivery ?? "..."}
                        </Link>
                      </div>
                    </Col>
                    <Col span="4">
                      <div className="dashboard-item dashboard-item-description">
                        <span>已發貨安排</span>
                        <Link
                          to={{
                            pathname: PAGE.STOCK_DELIVERY_ALL,
                            search: queryString.stringify({
                              deliveryStatus: "checkedOut",
                              deliveryStartDate: queryDate[0].format(
                                "YYYY-MM-DD"
                              ),
                              deliveryEndDate: queryDate[1].format(
                                "YYYY-MM-DD"
                              ),
                            }),
                          }}
                        >
                          {dashboard?.deliveryDashboard
                            ?.checkedOutOrderInDelivery ?? "..."}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col span="8">
                <div className="dashboard-col dashboard-card">
                  <Col span="24">
                    <div
                      className="dashboard-item dashboard-item-title"
                      style={{ borderBottom: "2px solid darkgray" }}
                    >
                      用戶服務狀態
                    </div>
                  </Col>
                  <Col span="24">
                    <div className="dashboard-item dashboard-item-description">
                      <span>累計登記用戶</span>
                      <Link to={PAGE.USER_REGISTERED}>
                        {dashboard?.userServiceDashboard?.registeredUser ??
                          "..."}
                      </Link>
                    </div>
                  </Col>
                  <Col span="24">
                    <div className="dashboard-item dashboard-item-description">
                      <span>累計己終止訂單人次</span>
                      {dashboard?.userServiceDashboard?.terminatedUser ?? "..."}
                    </div>
                  </Col>
                </div>
              </Col>
              <Col span="8">
                <div className="dashboard-col dashboard-card">
                  <Col span="24">
                    <div
                      className="dashboard-item dashboard-item-title"
                      style={{ borderBottom: "2px solid darkgray" }}
                    >
                      貨物狀態
                    </div>
                  </Col>
                  <Col span="24">
                    <div className="dashboard-item dashboard-item-description">
                      <span>器材存量</span>
                      <Link to={PAGE.STOCK_DEVICE}>
                        {dashboard?.inventoryDashBoard?.device ?? "..."}
                      </Link>
                    </div>
                  </Col>
                  <Col span="24">
                    <div className="dashboard-item dashboard-item-description">
                      <span>零件存量</span>
                      <Link to={PAGE.STOCK_PART}>
                        {dashboard?.inventoryDashBoard?.part ?? "..."}
                      </Link>
                    </div>
                  </Col>
                  <Col span="24">
                    <div className="dashboard-item dashboard-item-description">
                      <span>消耗品存量</span>
                      <Link to={PAGE.STOCK_CONSUMABLE}>
                        {dashboard?.inventoryDashBoard?.consumable ?? "..."}
                      </Link>
                    </div>
                  </Col>
                </div>
              </Col>
              <Col span="8">
                <div className="dashboard-col dashboard-card">
                  <Col span="24">
                    <div
                      className="dashboard-item dashboard-item-title"
                      style={{ borderBottom: "2px solid darkgray" }}
                    >
                      訂單狀態
                    </div>
                  </Col>
                  <Col span="24">
                    <div className="dashboard-item dashboard-item-description">
                      <span>現時服務中</span>
                      <Link to={PAGE.ORDER_IN_SERVICE}>
                        {dashboard?.orderDashboard?.inService ?? "..."}
                      </Link>
                    </div>
                  </Col>
                  <Col span="24">
                    <div className="dashboard-item dashboard-item-description">
                      <span>現時未付款</span>
                      {dashboard?.orderDashboard?.unpaid ?? "..."}
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </StickyBreadcrumbPageComponent>
  );
}

export default OverviewPage;
