/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import {
  Tabs,
  Form,
  Row,
  Col,
  Select,
  Input,
  Upload,
  Button,
  notification,
  Icon,
  Modal,
  InputNumber,
  Avatar,
  Tooltip,
  Comment,
  Card,
  message,
  Table,
  Typography,
  AutoComplete,
} from "antd";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useParams, useHistory, Link } from "react-router-dom";
import moment from "moment";
import { gql } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";

// Components
import ActivityHistoryComponent from "@components/activityHistory/activityHistory";
import AdditionalInfoComponent from "@components/additionalInfo/additionalInfo";

// Constants
import { PAGE, PAGE_NAME } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

//Styles
import "./_deviceForm.scss";

// Utils

import { getTokenHeader, replaceUrlByParams } from "@utils/api";
import CategoryFilter from "../../../../components/categoryFilter/categoryFilter";
import Picker from "../../picker/picker";
import { getFileUploadUrl } from "../../../../utils/env";

const { TabPane } = Tabs;

const SELECTION_CHOICES = gql`
  query {
    characteristicTagList(type: ["device", "part", "consumable"])
    brandList(type: ["device", "part", "consumable"])
    dimensionList(type: ["device", "part", "consumable"])
  }
`;

const DEVICE_GET = gql`
  query DeviceGet($id: ID!) {
    deviceGet(id: $id) {
      id
      productInfo {
        nameEng
        nameChi
        modelNumber
        thumbnail {
          key
          name
          size
          url
        }
        dimension
        brand
        netWeight
        totalWeight
        colour
        specification
        characteristicTag
        manufacturer
      }
      category {
        id
      }
      defaultPart {
        part {
          id
          referenceId
          productInfo {
            nameEng
            nameChi
          }
        }
        quantity
      }
      optionalPart {
        part {
          id
          referenceId
          productInfo {
            nameEng
            nameChi
          }
        }
        quantity
      }
      defaultConsumable {
        consumable {
          id
          referenceId
          productInfo {
            nameEng
            nameChi
          }
        }
        quantity
      }
    }
  }
`;

const DEVICE_CREATE = gql`
  mutation DeviceCreate($input: DeviceCreateInput) {
    deviceCreate(input: $input) {
      id
      productInfo {
        nameEng
        nameChi
        modelNumber
        thumbnail {
          key
          name
          size
        }
        dimension
        brand
        netWeight
        totalWeight
        colour
        specification
        characteristicTag
        manufacturer
      }
      category {
        id
      }
    }
  }
`;

const DEVICE_UPDATE = gql`
  mutation DeviceUpdate($input: DeviceUpdateInput) {
    deviceUpdate(input: $input) {
      id
      productInfo {
        nameEng
        nameChi
        modelNumber
        thumbnail {
          key
          name
          size
        }
        dimension
        brand
        netWeight
        totalWeight
        colour
        specification
        characteristicTag
        manufacturer
      }
      category {
        id
      }
    }
  }
`;

const DeviceForm = () => {
  const { id, mode } = useParams();
  const history = useHistory();

  const BREADCRUMB = [
    {
      path: id ? PAGE.STOCK_DEVICE_EDIT : PAGE.STOCK_DEVICE_CREATE,
      name: id ? PAGE_NAME.STOCK_DEVICE_EDIT : PAGE_NAME.STOCK_DEVICE_CREATE,
    },
  ];

  const [activeTabKey, setActiveTabKey] = useState("productInfo");
  const [isUploading, setIsUploading] = useState(false);
  const [isPreviewShow, setIsPreviewShow] = useState(false);
  const [isShowHkid, setIsShowHkid] = useState(false);
  const [previewImage, setPreviewImage] = useState(undefined);

  const [pickerParams, setPickerParams] = useState({
    visible: false,
  });

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(undefined);

  const [additionalInfo, setAdditionalInfo] = useState(undefined);
  const [remark, setRemark] = useState(undefined);

  const IS_READ_ONLY = false;

  const [
    getDevice,
    { data: getDeviceData, loading: getDeviceLoading, error: getDeviceError },
  ] = useLazyQuery(DEVICE_GET);
  const {
    data: selectionChoices,
    loading: selectionChoiceLoading,
    error: selectionChoiceError,
  } = useQuery(SELECTION_CHOICES);

  const [
    _createDevice,
    {
      loading: createDeviceLoading,
      error: createDeviceError,
      data: createDeviceData,
    },
  ] = useMutation(DEVICE_CREATE);
  const [
    _updateDevice,
    {
      loading: updateDeviceLoading,
      error: updateDeviceError,
      data: updateDeviceData,
    },
  ] = useMutation(DEVICE_UPDATE);

  const {
    clearError,
    errors,
    handleSubmit,
    register,
    setValue,
    triggerValidation,
    watch,
    getValues,
    validate,
  } = useForm({
    defaultValues: {
      id: undefined,
      categoryId: undefined,
      productInfo: {
        nameEng: "",
        nameChi: "",
        modelNumber: "",
        thumbnail: undefined,
        dimension: [],
        brand: undefined,
        netWeight: 0,
        totalWeight: 0,
        colour: [],
        specification: "",
        characteristicTag: [],
      },

      defaultPart: [],
      optionalPart: [],
      defaultConsumable: [],
    },
  });

  const watchValue = watch();

  useEffect(() => {
    if (id) {
      getDevice({ variables: { id } });
    }
  }, [getDevice, id]);

  useEffect(() => {
    if (getDeviceData?.deviceGet) {
      const data = getDeviceData?.deviceGet;
      setValue("id", data.id);
      setValue("categoryId", data?.category?.id ?? undefined);
      setValue("productInfo.nameEng", data?.productInfo?.nameEng ?? "");
      setValue("productInfo.nameChi", data?.productInfo?.nameChi ?? "");
      setValue("productInfo.modelNumber", data?.productInfo?.modelNumber ?? "");
      setValue(
        "productInfo.thumbnail",
        data?.productInfo?.thumbnail ?? undefined
      );
      setValue("productInfo.dimension", data?.productInfo?.dimension ?? []);
      setValue("productInfo.brand", data?.productInfo?.brand ?? undefined);
      setValue(
        "productInfo.manufacturer",
        data?.productInfo?.manufacturer ?? undefined
      );
      setValue("productInfo.netWeight", data?.productInfo?.netWeight ?? 0);
      setValue("productInfo.totalWeight", data.productInfo.totalWeight ?? 0);
      setValue("productInfo.colour", data?.productInfo?.colour ?? []);
      setValue(
        "productInfo.specification",
        data?.productInfo?.specification ?? ""
      );
      setValue(
        "productInfo.characteristicTag",
        data?.productInfo.characteristicTag ?? []
      );

      //TODO
      setValue("defaultPart", data.defaultPart ?? []);
      setValue("optionalPart", data.optionalPart ?? []);
      setValue("defaultConsumable", data.defaultConsumable ?? []);
    }
  }, [getDeviceData, setValue]);

  useEffect(() => {
    const id = createDeviceData?.deviceCreate?.id;
    if (id) {
      history.push(replaceUrlByParams(PAGE.STOCK_DEVICE_EDIT, { id: id }));
    }
  }, [createDeviceData, history]);

  const { log } = console;
  log("watch", watch());

  useEffect(() => {
    register({ name: "id" });
    register({ name: "categoryId" }, { required: true });
    register({ name: "productInfo" });
    register({ name: "productInfo.nameEng" });
    register({ name: "productInfo.nameChi" }, { required: true });
    register({ name: "productInfo.modelNumber" }, { required: true });
    register({ name: "productInfo.thumbnail" });
    register({ name: "productInfo.dimension" });
    register({ name: "productInfo.brand" });
    register({ name: "productInfo.manufacturer" });
    register({ name: "productInfo.netWeight" });
    register({ name: "productInfo.totalWeight" });
    register({ name: "productInfo.colour" });
    register({ name: "productInfo.specification" });
    register({ name: "productInfo.characteristicTag" });

    register({ name: "defaultPart" });
    register({ name: "optionalPart" });
    register({ name: "defaultConsumable" });
  }, [register]);

  const onFileChange = ({ fileList }) => {
    let fl = [...fileList];

    fl = fl.slice(-1);

    fl = fl.map((file) => {
      if (file.error) {
        notification.error({
          message: "上載失敗",
        });
        setIsUploading(false);
      } else if (file?.status === "uploading") {
        setIsUploading(true);
      } else if (file.response) {
        file.name = file.response.data.name;
        file.size = file.response.data.size;
        file.key = file.response.data.key;
        file.url = file.response.data.url;
        setIsUploading(false);
      }
      return file;
    });

    fl = fl.filter((file) => !file.error);
    if (fl[0]) {
      const { key, url, name, size } = fl[0];
      if (url && key && name && size) {
        fl[0] = { key, url, name, size };
      }
    }
    setValue("productInfo.thumbnail", fl[0] ?? undefined);
  };

  const onFileRemove = (file) => {
    setValue("productInfo.thumbnail", undefined);
  };

  const onFilePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      })(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setIsPreviewShow(true);
  };

  const onFilePreviewCancel = () => setIsPreviewShow(false);

  const onDefaultPartAdd = useCallback(
    (part) => {
      const newPart = [];
      part.forEach((part) => {
        newPart.push({ part, quantity: 0 });
      });
      const newDefaultParts = [...getValues().defaultPart, ...newPart];
      setValue(`defaultPart`, newDefaultParts);
    },
    [getValues, setValue]
  );

  const onDefaultPartRemove = useCallback(
    (index) => {
      Modal.confirm({
        title: "確認刪除此預設零件嗎？",
        okText: "確定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          const newDefaultParts = getValues().defaultPart;
          newDefaultParts.splice(index, 1);
          setValue(`defaultPart`, newDefaultParts);
        },
      });
    },
    [getValues, setValue]
  );

  const onOptionalPartAdd = useCallback(
    (part) => {
      const newPart = [];
      part.forEach((part) => {
        newPart.push({ part, quantity: 0 });
      });
      const newOptionalParts = [...getValues().optionalPart, ...newPart];
      setValue(`optionalPart`, newOptionalParts);
    },
    [getValues, setValue]
  );

  const onOptionalPartRemove = useCallback(
    (index) => {
      Modal.confirm({
        title: "確認刪除此額外零件嗎？",
        okText: "確定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          const newOptionalParts = getValues().optionalPart;
          newOptionalParts.splice(index, 1);
          setValue(`optionalPart`, newOptionalParts);
        },
      });
    },
    [getValues, setValue]
  );

  const onDefaultConsumableAdd = useCallback(
    (consumable) => {
      const newConsumables = [];
      consumable.forEach((consumable) => {
        newConsumables.push({ consumable, quantity: 0 });
      });
      const newDefaultConsumables = [
        ...getValues().defaultConsumable,
        ...newConsumables,
      ];
      setValue(`defaultConsumable`, newDefaultConsumables);
    },
    [getValues, setValue]
  );

  const onDefaultConsumableRemove = useCallback(
    (index) => {
      Modal.confirm({
        title: "確認刪除此預設消耗品嗎？",
        okText: "確定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          const newDefaultConsumables = getValues().defaultConsumable;
          newDefaultConsumables.splice(index, 1);
          setValue(`defaultConsumable`, newDefaultConsumables);
        },
      });
    },
    [getValues, setValue]
  );

  const onQuantityChange = useCallback(
    (type, index, quantity) => {
      const list = getValues()[type];
      list.splice(index, 1, { ...list[index], quantity });
      setValue(type, list);
    },
    [getValues, setValue]
  );

  const defaultPartColumns = useMemo(() => {
    return [
      {
        title: "型號",
        dataIndex: "part.referenceId",
        render: (text, { part }) => {
          return (
            <Link
              to={`${replaceUrlByParams(PAGE.STOCK_PART_DETAIL, {
                id: part?.id,
              })}`}
              target="_blank"
            >
              {text}
            </Link>
          );
        },
      },
      {
        title: "名稱",
        dataIndex: "part.productInfo.nameChi",
      },
      {
        title: "數量",
        dataIndex: "quantity",
        render: (quantity, record, index) => {
          return (
            <InputNumber
              value={Number(quantity)}
              onChange={(qty) => onQuantityChange("defaultPart", index, qty)}
            />
          );
        },
      },
      {
        title: "",
        dataIndex: "part",
        render: (part, record, index) => {
          // TODO remove item
          return (
            <Button
              size="small"
              shape="circle"
              type="danger"
              icon="minus-circle"
              onClick={() => onDefaultPartRemove(index)}
            />
          );
        },
      },
    ];
  }, [onDefaultPartRemove, onQuantityChange]);

  const optionalPartColumns = useMemo(() => {
    return [
      {
        title: "型號",
        dataIndex: "part.referenceId",
        render: (text, { part }) => {
          return (
            <Link
              to={`${replaceUrlByParams(PAGE.STOCK_PART_DETAIL, {
                id: part?.id,
              })}`}
              target="_blank"
            >
              {text}
            </Link>
          );
        },
      },
      {
        title: "名稱",
        dataIndex: "part.productInfo.nameChi",
      },
      {
        title: "數量",
        dataIndex: "quantity",
        render: (quantity, record, index) => {
          return (
            <InputNumber
              value={Number(quantity)}
              onChange={(qty) => onQuantityChange("optionalPart", index, qty)}
            />
          );
        },
      },
      {
        title: "",
        dataIndex: "part",
        render: (part, record, index) => {
          // TODO remove item
          return (
            <Button
              size="small"
              shape="circle"
              type="danger"
              icon="minus-circle"
              onClick={() => onOptionalPartRemove(index)}
            />
          );
        },
      },
    ];
  }, [onOptionalPartRemove, onQuantityChange]);

  const defaultConsumableColumns = useMemo(() => {
    return [
      {
        title: "型號",
        dataIndex: "consumable.referenceId",
        render: (text, { consumable }) => {
          return (
            <Link
              to={`${replaceUrlByParams(PAGE.STOCK_CONSUMABLE_DETAIL, {
                id: consumable?.id,
              })}`}
              target="_blank"
            >
              {text}
            </Link>
          );
        },
      },
      {
        title: "名稱",
        dataIndex: "consumable.productInfo.nameChi",
      },
      {
        title: "數量",
        dataIndex: "quantity",
        render: (quantity, record, index) => {
          return (
            <InputNumber
              size="small"
              value={Number(quantity)}
              onChange={(qty) =>
                onQuantityChange("defaultConsumable", index, qty)
              }
            />
          );
        },
      },
      {
        title: "",
        dataIndex: "consumable",
        render: (consumable, record, index) => {
          // TODO remove item
          return (
            <Button
              size="small"
              shape="circle"
              type="danger"
              icon="minus-circle"
              onClick={() => onDefaultConsumableRemove(index)}
            />
          );
        },
      },
    ];
  }, [onDefaultConsumableRemove, onQuantityChange]);

  const onSaveButtonClick = useCallback(
    async (value) => {
      try {
        const deviceFormValue = JSON.parse(JSON.stringify(value));
        deviceFormValue.defaultPart = deviceFormValue?.defaultPart.map(
          ({ part, quantity }) => ({ partId: part.id, quantity })
        );
        deviceFormValue.optionalPart = deviceFormValue?.optionalPart.map(
          ({ part, quantity }) => ({ partId: part.id, quantity })
        );
        deviceFormValue.defaultConsumable = deviceFormValue?.defaultConsumable.map(
          ({ consumable, quantity }) => ({
            consumableId: consumable.id,
            quantity,
          })
        );

        if (deviceFormValue?.productInfo?.thumbnail) {
          const { key, name, size } = deviceFormValue?.productInfo?.thumbnail;
          deviceFormValue.productInfo.thumbnail = { key, name, size };
        }

        if (additionalInfo) {
          additionalInfo.brochureList = additionalInfo?.brochureList?.map(
            ({ key, name, size }) => ({ key, name, size })
          );
          additionalInfo.photoList = additionalInfo?.photoList?.map(
            ({ key, name, size }) => ({ key, name, size })
          );
          additionalInfo.userManual = additionalInfo?.userManual?.map(
            ({ key, name, size }) => ({ key, name, size })
          );
          deviceFormValue.productInfo = {
            ...deviceFormValue?.productInfo,
            ...additionalInfo,
          };
        }

        if (remark) {
          deviceFormValue.remark = remark;
        }

        if (id) {
          await _updateDevice({
            variables: {
              input: deviceFormValue,
            },
          });
          message.success("成功更新型號資料");
        } else {
          await _createDevice({
            variables: {
              input: deviceFormValue,
            },
          });
          message.success("成功新增型號資料");
        }
      } catch (error) {
        message.error(error.message);
      }
    },
    [additionalInfo, remark, id, _updateDevice, _createDevice]
  );

  const onAdditionalInfoChange = useCallback(({ info, remark }) => {
    setAdditionalInfo(info);
    setRemark(remark);
  }, []);

  return (
    <div className="device-form">
      <StickyBreadcrumbPageComponent
        className="device-create-page"
        breadcrumb={BREADCRUMB}
      >
        <div className="status-container">
          <Button
            loading={createDeviceLoading}
            type="secondary"
            onClick={() =>
              history.push(
                replaceUrlByParams(PAGE.STOCK_DEVICE_DETAIL, {
                  id: id,
                })
              )
            }
          >
            取消
          </Button>
          <Button
            loading={createDeviceLoading}
            type="primary"
            onClick={handleSubmit(onSaveButtonClick)}
          >
            儲存
          </Button>
        </div>

        <div className="content-inner">
          <Form layout="vertical">
            <Tabs
              activeKey={activeTabKey}
              onChange={(key) => setActiveTabKey(key)}
            >
              <TabPane className="section" tab="基本資料" key="productInfo">
                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>分類</span>
                      <CategoryFilter
                        disabled={IS_READ_ONLY}
                        name="categoryId"
                        value={watchValue.categoryId}
                        onChange={(categoryId) =>
                          setValue("categoryId", categoryId)
                        }
                      />
                      {errors?.categoryId && (
                        <Typography.Text type="danger">
                          請選擇分類
                        </Typography.Text>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>器材英文名稱</span>
                      <Input
                        className={
                          errors?.productInfo?.nameEng ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.nameEng"
                        onChange={({ target: { value } }) =>
                          setValue("productInfo.nameEng", value)
                        }
                        value={watchValue.productInfo.nameEng}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>器材中文名稱</span>
                      <Input
                        className={
                          errors?.productInfo?.nameChi ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.nameChi"
                        onChange={({ target: { value } }) =>
                          setValue("productInfo.nameChi", value)
                        }
                        value={watchValue.productInfo.nameChi}
                      ></Input>
                      {errors?.categoryId && (
                        <Typography.Text type="danger">
                          請填寫器材中文名稱
                        </Typography.Text>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>器材型號</span>
                      <Input
                        className={
                          errors?.productInfo?.modelNumber ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.modelNumber"
                        onChange={({ target: { value } }) =>
                          setValue("productInfo.modelNumber", value)
                        }
                        value={watchValue.productInfo.modelNumber}
                      ></Input>
                      {errors?.categoryId && (
                        <Typography.Text type="danger">
                          請填寫器材型號
                        </Typography.Text>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>型號縮略圖</span>
                      <Upload
                        name="file"
                        className="thumbnail-uploader"
                        action={getFileUploadUrl()}
                        disabled={isUploading || IS_READ_ONLY}
                        fileList={
                          watchValue.productInfo.thumbnail
                            ? [
                                {
                                  uid: 1,
                                  ...watchValue.productInfo.thumbnail,
                                },
                              ]
                            : undefined
                        }
                        headers={{
                          "x-token": getTokenHeader(),
                          "X-Requested-With": null,
                        }}
                        onChange={onFileChange}
                        onPreview={onFilePreview}
                        onRemove={
                          isUploading || IS_READ_ONLY ? null : onFileRemove
                        }
                        showUploadList={{
                          showDownloadIcon: false,
                        }}
                        listType="picture-card"
                      >
                        <Button disabled={isUploading || IS_READ_ONLY}>
                          <Icon type="upload" /> 上載
                        </Button>
                      </Upload>
                      <Modal
                        visible={isPreviewShow}
                        footer={null}
                        onCancel={onFilePreviewCancel}
                      >
                        <img
                          alt="轉介 / 登記表"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>尺寸</span>
                      <Select
                        mode="tags"
                        multiple={true}
                        options={selectionChoices?.dimensionList}
                        className={
                          errors?.productInfo?.dimension ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.dimension"
                        onChange={(value) =>
                          setValue("productInfo.dimension", value)
                        }
                        value={watchValue.productInfo.dimension}
                      >
                        {(selectionChoices?.dimensionList || []).map((tag) => {
                          return (
                            <Select.Option key={tag} value={tag}>
                              {tag}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>顏色</span>
                      <Select
                        mode="tags"
                        multiple={true}
                        className={errors?.productInfo?.colour ? "error" : null}
                        disabled={IS_READ_ONLY}
                        name="productInfo.colour"
                        onChange={(value) =>
                          setValue("productInfo.colour", value)
                        }
                        value={watchValue.productInfo.colour}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>品牌</span>
                      <AutoComplete
                        dataSource={selectionChoices?.brandList ?? []}
                        filterOption={(inputValue, option) => {
                          return (
                            option.props.children
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          );
                        }}
                        className={errors?.productInfo?.brand ? "error" : null}
                        disabled={IS_READ_ONLY}
                        name="productInfo.brand"
                        onChange={(value) =>
                          setValue("productInfo.brand", value)
                        }
                        value={watchValue.productInfo.brand}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>生產廠家</span>
                      <Input
                        className={
                          errors?.productInfo?.manufacturer ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.manufacturer"
                        onChange={({ target: { value } }) =>
                          setValue("productInfo.manufacturer", value)
                        }
                        value={watchValue.productInfo.manufacturer}
                      ></Input>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>總重量(公斤）</span>
                      <InputNumber
                        className={
                          errors?.productInfo?.totalWeight ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.totalWeight"
                        onChange={(value) =>
                          setValue("productInfo.totalWeight", Number(value))
                        }
                        value={watchValue.productInfo.totalWeight}
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>淨重(公斤）</span>
                      <InputNumber
                        className={
                          errors?.productInfo?.netWeight ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.netWeight"
                        onChange={(value) =>
                          setValue("productInfo.netWeight", Number(value))
                        }
                        value={watchValue.productInfo.netWeight}
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>功能和規格（不超過200個字）</span>
                      <Input.TextArea
                        row={5}
                        className={
                          errors?.productInfo?.specification ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.specification"
                        onChange={({ target: { value } }) =>
                          setValue("productInfo.specification", value)
                        }
                        value={watchValue.productInfo.specification}
                      ></Input.TextArea>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>產品特點</span>
                      <Select
                        mode="tags"
                        multiple={true}
                        className={
                          errors?.productInfo?.characteristicTag
                            ? "error"
                            : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.characteristicTag"
                        onChange={(value) =>
                          setValue("productInfo.characteristicTag", value)
                        }
                        value={watchValue.productInfo.characteristicTag}
                      >
                        {(selectionChoices?.characteristicTagList || []).map(
                          (tag) => {
                            return (
                              <Select.Option key={tag} value={tag}>
                                {tag}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>

              {/* <TabPane tab="清潔/檢查流程" key="clean">
                        </TabPane> */}
              <TabPane tab="其他適用貨物" key="parts">
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Card
                      title="預設零件"
                      extra={
                        <Button
                          onClick={() =>
                            setPickerParams({
                              pickerType: "part",
                              visible: true,
                              multiple: false,
                              onChange: onDefaultPartAdd,
                              onClose: () =>
                                setPickerParams({
                                  visible: false,
                                }),
                            })
                          }
                        >
                          新增零件型號
                        </Button>
                      }
                    >
                      <Table
                        pagination={false}
                        dataSource={watchValue.defaultPart}
                        columns={defaultPartColumns}
                        scroll={{x:"max-content"}}
                      />
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card
                      title="額外零件"
                      extra={
                        <Button
                          onClick={() =>
                            setPickerParams({
                              pickerType: "part",
                              visible: true,
                              multiple: false,
                              onChange: onOptionalPartAdd,
                              onClose: () =>
                                setPickerParams({
                                  visible: false,
                                }),
                            })
                          }
                        >
                          新增零件型號
                        </Button>
                      }
                    >
                      <Table
                        pagination={false}
                        dataSource={watchValue.optionalPart}
                        columns={optionalPartColumns}
                        scroll={{x:"max-content"}}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Card
                      title="預設消耗品"
                      extra={
                        <Button
                          onClick={() =>
                            setPickerParams({
                              pickerType: "consumable",
                              visible: true,
                              multiple: false,
                              onChange: onDefaultConsumableAdd,
                              onClose: () =>
                                setPickerParams({
                                  visible: false,
                                }),
                            })
                          }
                        >
                          新增消耗品型號
                        </Button>
                      }
                    >
                      <Table
                        rowKey={(row) => row.consumable.id}
                        pagination={false}
                        dataSource={watchValue.defaultConsumable}
                        columns={defaultConsumableColumns}
                        scroll={{x:"max-content"}}
                      />
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane className="section" tab="附加資料" key="additionalInfo">
                <AdditionalInfoComponent
                  id={id}
                  type={"Device"}
                  onChange={onAdditionalInfoChange}
                />
              </TabPane>
              <TabPane className="section" tab="活動記錄" key="activityLog">
                <ActivityHistoryComponent id={id} type={"Device"} />
              </TabPane>
            </Tabs>
          </Form>
        </div>
        {pickerParams.visible && <Picker {...pickerParams} />}
      </StickyBreadcrumbPageComponent>
    </div>
  );
};

export default DeviceForm;
