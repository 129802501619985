import React from 'react';
import PropTypes from 'prop-types';

// Style
import './question.scss';

// Utils
import {classList} from '@utils/common';

function AssessmentQuestionComponent(props) {
    const {
        children,
        className,
        questionId,
        title
    } = props;

    const getClassList = () => classList({
        'Assessment-Component': true,
        'AssessmentQuestion-Component': true,
        [className]: true
    });

    return (
        <div className={getClassList()}>
            <div className='questionId'>
                {questionId}
            </div>
            <pre className='question'>
                {title}
            </pre>
            <div className='inputs'>
                {children}
            </div>
        </div>
    );
}

export default AssessmentQuestionComponent;

AssessmentQuestionComponent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    questionId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

AssessmentQuestionComponent.defalutProps = {
    children: null,
    className: ''
};
