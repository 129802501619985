import React from "react";
import { gql } from "@apollo/client";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Button, Col, Row, Spin } from "antd";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import moment from "moment";

// Components
import DeliveryList from "./deliveryList";

// Constants
import { PAGE, PAGE_NAME, PAGE_SIZE } from "@utils/constants";

const DELIVERY_DASHBOARD_GET = gql`
  query deliveryDashboardGet {
    deliveryDashboardGet {
      todayOrderInDelivery
      confirmedOrderInDelivery
      inventoryReadyOrderInDelivery
      checkedOutOrderInDelivery
      selfPickOrderInDelivery
    }
  }
`;

function DeliveryAll(props) {
  const history = useHistory();

  const BREADCRUMB = [
    {
      path: PAGE.STOCK_DELIVERY_ALL,
      name: PAGE_NAME.STOCK_DELIVERY_AND_DISASSEMBLY_ALL,
    },
  ];

  const availableDeliveryStatus = [
    "pending",
    "confirmedDelivery",
    "inventoryReady",
    "checkedOut",
    "completed",
    "cancelled",
  ];

  const {
    data: getDeliveryDashboardData,
    loading: getDeliveryDashboardLoading,
  } = useQuery(DELIVERY_DASHBOARD_GET);

  const onClickFilterDelivery = (filter) => {
    history.push({
      search: queryString.stringify({
        page: 1,
        pageSize: PAGE_SIZE,
        ...filter,
      }),
    });
  };

  const renderDashboard = (
    <Row gutter={[16, 32]}>
      <Spin spinning={getDeliveryDashboardLoading}>
        <div className="dashboard-container">
          <Col span={4}>
            <div className="dashboard-item">
              <div className="dashboard-item-title">今日工作</div>
              <div className="dashboard-item-description">
                <Button
                  type="link"
                  onClick={() =>
                    onClickFilterDelivery({
                      deliveryStartDate: moment().format("YYYY-MM-DD"),
                      deliveryEndDate: moment().format("YYYY-MM-DD"),
                    })
                  }
                >
                  {
                    getDeliveryDashboardData?.deliveryDashboardGet
                      ?.todayOrderInDelivery
                  }
                </Button>
              </div>
            </div>
          </Col>
          <Col span={1}>
            <div className="vertical-divider"></div>
          </Col>
          <Col span={4}>
            <div className="dashboard-item">
              <div className="dashboard-item-title">已確認安排</div>
              <div className="dashboard-item-description">
                <Button
                  type="link"
                  onClick={() =>
                    onClickFilterDelivery({
                      deliveryStatus: ["confirmedDelivery"],
                    })
                  }
                >
                  {
                    getDeliveryDashboardData?.deliveryDashboardGet
                      ?.confirmedOrderInDelivery
                  }
                </Button>
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className="dashboard-item">
              <div className="dashboard-item-title">已備貨安排</div>
              <div className="dashboard-item-description">
                <Button
                  type="link"
                  onClick={() =>
                    onClickFilterDelivery({
                      deliveryStatus: ["inventoryReady"],
                    })
                  }
                >
                  {
                    getDeliveryDashboardData?.deliveryDashboardGet
                      ?.inventoryReadyOrderInDelivery
                  }
                </Button>
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className="dashboard-item">
              <div className="dashboard-item-title">已發貨安排</div>
              <div className="dashboard-item-description">
                <Button
                  type="link"
                  onClick={() =>
                    onClickFilterDelivery({ deliveryStatus: ["checkedOut"] })
                  }
                >
                  {
                    getDeliveryDashboardData?.deliveryDashboardGet
                      ?.checkedOutOrderInDelivery
                  }
                </Button>
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className="dashboard-item">
              <div className="dashboard-item-title">自行提取安排</div>
              <div className="dashboard-item-description">
                <Button
                  type="link"
                  onClick={() => onClickFilterDelivery({ selfPick: true })}
                >
                  {
                    getDeliveryDashboardData?.deliveryDashboardGet
                      ?.selfPickOrderInDelivery
                  }
                </Button>
              </div>
            </div>
          </Col>
        </div>
      </Spin>
    </Row>
  );

  return (
    <DeliveryList
      {...props}
      breadcrumb={BREADCRUMB}
      availableDeliveryStatus={availableDeliveryStatus}
      extraChildren={renderDashboard}
    />
  );
}

export default DeliveryAll;

DeliveryAll.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
