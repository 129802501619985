import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Collapse,
  Descriptions,
  message,
  Spin,
  Table,
  Typography,
} from "antd";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import "./prescriptionRecordExpander.scss";
import moment from "moment";

const { Panel } = Collapse;

const USER_GET = gql`
  query UserGet($id: ID) {
    userGet(id: $id) {
      prescription {
        id
        name
        datetime
        prescriptionDevice {
          device {
            id
            referenceId
            quantity
            category {
              id
              name
              mainCategory {
                id
                name
              }
            }
            productInfo {
              nameChi
            }
            leasableQuantity
            washingQuantity
            waitingQuantity
          }
          reason
        }
        relatedAssessment {
          id
          assessmentStartDate
        }
      }
    }
  }
`;

function PrescriptionRecordExpander(props) {
  const { userId, isDisplay, items, setItems, currentItems } = props;
  const [
    userGet,
    { loading: userGetLoading, data: userGetData },
  ] = useLazyQuery(USER_GET);
  const { Text } = Typography;
  useEffect(() => {
    userGet({
      variables: { id: userId },
    });
  }, [userGet, userId]);

  const latestRecord = useMemo(() => {
    return userGetData?.userGet?.prescription?.sort(
      (a, b) => moment(b.datetime).valueOf() - moment(a.datetime).valueOf()
    )[0];
  }, [userGetData]);

  const cols = useMemo(
    () => [
      {
        title: "貨物型號",
        dataIndex: "referenceId",
        render: (text, record) => {
          return (
            <div className="device">
              <div>{record.device.referenceId}</div>
              <div>{record.device.productInfo.nameChi}</div>
            </div>
          );
        },
      },
      {
        title: "原因",
        dataIndex: "reason",
      },
      {
        title: "可租用",
        dataIndex: "device.leasableQuantity",
      },
      {
        title: "清洗中",
        dataIndex: "device.washingQuantity",
      },
      {
        title: "輪候中",
        dataIndex: "device.waitingQuantity",
      },
      {
        dataIndex: "action",
        render: (text, record) => {
          const num = items ? items[record.device.referenceId]?.qty || 0 : 0;
          const max = record.device.leasableQuantity ?? 0;
          return (
            <div className="ordering-action">
              <Text>
                {currentItems.includes(record.device.id) ? (
                  <span style={{ color: "green" }}>已加入訂單</span>
                ) : (
                  <span style={{ color: "black" }}>未加入訂單</span>
                )}
              </Text>
              {!isDisplay && (
                <Button
                  type="primary"
                  shape="circle"
                  icon="plus"
                  size="small"
                  onClick={() => {
                    if (num === max) {
                      message.warning("已選取上限數量");
                      return;
                    }
                    setItems((prev) => ({
                      ...prev,
                      [record.device.referenceId]: {
                        ...record.device,
                        qty: (prev[record.device.referenceId]?.qty || 0) + 1,
                        max: max,
                      },
                    }));
                    message.success("成功加到選取清單");
                  }}
                />
              )}
            </div>
          );
        },
      },

      // eslint-disable-next-line
    ],
    [userGetData, items, currentItems]
  );

  const renderComponent = () => {
    const view = userGetLoading ? (
      <div style={{ textAlign: "center" }}>
        <Spin />
      </div>
    ) : (
      <Collapse
        className="prescription-record-expander"
        defaultActiveKey={["0"]}
        expandIconPosition="right"
      >
        {latestRecord ? (
          <Panel header={`${latestRecord.id} - ${latestRecord.name}`}>
            {!isDisplay && (
              <Descriptions colon={false}>
                <Descriptions.Item label="相關評估">
                  {latestRecord.relatedAssessment
                    .map((assessment) => assessment.id)
                    .join(", ")}
                </Descriptions.Item>
              </Descriptions>
            )}
            <Table
              rowKey="referenceId"
              pagination={false}
              loading={userGetLoading}
              columns={cols}
              dataSource={latestRecord.prescriptionDevice}
              scroll={{x:"max-content"}}
            />
          </Panel>
        ) : (
          <span>(暫時未有診斷紀錄)</span>
        )}
      </Collapse>
    );
    return view;
  };

  return renderComponent();
}

export default PrescriptionRecordExpander;

PrescriptionRecordExpander.propTypes = {
  userId: PropTypes.string.isRequired,
  isDisplay: PropTypes.bool.isRequired,
  items: PropTypes.object,
  setItems: PropTypes.func,
  currentItems: PropTypes.array.isRequired,
};
