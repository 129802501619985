/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Components
import CustPaginationComponent from "@components/custPagination/custPagination";

// Styles
import "./activityHistory.scss";

// Utils
import { Comment, Tooltip } from "antd";
import { MOMENT_DATETIME_FORMAT, PAGE_SIZE } from "@utils/constants";
import { traceFormattedDate } from "@utils/common";
import moment from "moment";

const ACTIVITY_HISTORY_FRAGMENT = gql`
  fragment Fields on ActivityHistory {
    staff {
      tbId
      displayName
    }
    content
    loggedDate
  }
`;

const SERVICE_GET = gql`
  query ServiceGet($id: ID!, $size: Int, $offSet: Int) {
    serviceGet(id: $id) {
      id
      trace {
        activityHistory(size: $size, offSet: $offSet) {
          ...Fields
        }
      }
    }
  }
  ${ACTIVITY_HISTORY_FRAGMENT}
`;

const DEVICE_GET = gql`
  query DeviceGet($id: ID!, $size: Int, $offSet: Int) {
    deviceGet(id: $id) {
      id
      trace {
        activityHistory(size: $size, offSet: $offSet) {
          ...Fields
        }
      }
    }
  }
  ${ACTIVITY_HISTORY_FRAGMENT}
`;

const PART_GET = gql`
  query PartGet($id: ID!, $size: Int, $offSet: Int) {
    partGet(id: $id) {
      id
      trace {
        activityHistory(size: $size, offSet: $offSet) {
          ...Fields
        }
      }
    }
  }
  ${ACTIVITY_HISTORY_FRAGMENT}
`;

const CONSUMABLE_GET = gql`
  query ConsumableGet($id: ID!, $size: Int, $offSet: Int) {
    consumableGet(id: $id) {
      id
      trace {
        activityHistory(size: $size, offSet: $offSet) {
          ...Fields
        }
      }
    }
  }
  ${ACTIVITY_HISTORY_FRAGMENT}
`;

const INVENTORY_RECORD_GET = gql`
  query InventoryRecordGet($id: ID!, $size: Int, $offSet: Int) {
    inventoryRecordGet(id: $id) {
      id
      trace {
        activityHistory(size: $size, offSet: $offSet) {
          ...Fields
        }
      }
    }
  }
  ${ACTIVITY_HISTORY_FRAGMENT}
`;

const ActivityHistoryComponent = ({ id, type }) => {
  const location = useLocation();
  const history = useHistory();

  const [
    getService,
    { loading: serviceLoading, data: serviceData },
  ] = useLazyQuery(SERVICE_GET);

  const [
    getDevice,
    { loading: deviceLoading, data: deviceData },
  ] = useLazyQuery(DEVICE_GET);

  const [getPart, { loading: partLoading, data: partData }] = useLazyQuery(
    PART_GET
  );

  const [
    getConsumable,
    { loading: consumableLoading, data: consumableData },
  ] = useLazyQuery(CONSUMABLE_GET);

  const [
    getInventoryRecord,
    { loading: inventoryRecordLoading, data: inventoryRecordData },
  ] = useLazyQuery(INVENTORY_RECORD_GET);

  const query = useMemo(() => {
    const { page, pageSize } = queryString.parse(location.search);
    return {
      ...queryString.parse(location.search),
      page: page ? Number(page) : 1,
      // pageSize: pageSize ? Number(pageSize) : PAGE_SIZE,
      pageSize: PAGE_SIZE,
    };
  }, [location.search]);

  const histories = useMemo(() => {
    switch (type) {
      case "Service": {
        return serviceData?.serviceGet?.trace?.activityHistory || [];
      }
      case "Device": {
        return deviceData?.deviceGet?.trace?.activityHistory || [];
      }
      case "Part": {
        return partData?.partGet?.trace?.activityHistory || [];
      }
      case "Consumable": {
        return consumableData?.consumableGet?.trace?.activityHistory || [];
      }
      case "Inventory": {
        return (
          inventoryRecordData?.inventoryRecordGet?.trace?.activityHistory || []
        );
      }
      default:
        return [];
    }

    // eslint-disable-next-line
  }, [serviceData, deviceData, partData, consumableData, inventoryRecordData]);

  const isMaxPage = useMemo(() => {
    return histories ? histories.length <= PAGE_SIZE : true;
  }, [histories]);

  const list = useMemo(() => {
    return histories.slice(0, PAGE_SIZE);
  }, [histories]);

  useEffect(() => {
    if (id) {
      const variables = {
        id,
        size: query.pageSize + 1,
        offSet: (query.page - 1) * query.pageSize,
      };
      switch (type) {
        case "Service": {
          getService({
            variables: variables,
          });
          break;
        }
        case "Device": {
          getDevice({
            variables: variables,
          });
          break;
        }
        case "Part": {
          getPart({
            variables: variables,
          });
          break;
        }
        case "Consumable": {
          getConsumable({
            variables: variables,
          });
          break;
        }
        case "Inventory": {
          getInventoryRecord({
            variables: variables,
          });
          break;
        }
        default:
          break;
      }
    }

    // eslint-disable-next-line
  }, [id, query]);

  return (
    <div className="ActivityHistoryComponent">
      <div className="paging-container">
        <div className="right">
          <CustPaginationComponent
            isMinPage={query.page === 1}
            isMaxPage={isMaxPage}
            page={query.page}
            // pageSize={query.pageSize || PAGE_SIZE}
            pageSize={PAGE_SIZE}
            onPageChange={(page) => {
              history.push({
                pathname: location.pathname,
                search: queryString.stringify({
                  ...query,
                  page,
                }),
              });
            }}
          />
        </div>
      </div>
      {list &&
        list.map((history, index) => (
          <Comment
            key={index}
            author={history?.staff?.displayName ?? "System"}
            content={<p>{history?.content}</p>}
            datetime={moment(history?.loggedDate).format(
              MOMENT_DATETIME_FORMAT
            )}
          />
        ))}
    </div>
  );
};

ActivityHistoryComponent.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
};

ActivityHistoryComponent.defaultProps = {
  id: "",
  type: "",
};

export default ActivityHistoryComponent;
