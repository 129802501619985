import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import queryString from "query-string";
import { gql } from "@apollo/client";

// Constants
import { PAGE, PAGE_NAME, ACCESS_LEVELS } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";
import OrderingItem, {
  BottomBar,
} from "@pages/ordering/orderingItem/orderingItem";
import OrderingAssessment from "@pages/ordering/orderingAssessment/orderingAssessment";
import OrderingPayment from "@pages/ordering/orderingPayment/orderingPayment";
import OrderingActivityHistory from "@pages/ordering/orderingActivityHistory/orderingActivityHistory";

// Styles
import { Descriptions, Tabs, Alert, message, notification } from "antd";
// import { Row, Col, Typography } from "antd";
import "./orderingDetail.scss";

// Utils
import moment from "moment";
import { useAccessLevel } from "@utils/hook";

const ORDERING_GET = gql`
  query OrderingGet($userId: ID) {
    orderingGet(userId: $userId) {
      paymentStatus {
        id
        name
      }
      user {
        userId
        username
        nameChi
        gender {
          name
        }
        dateOfBirth
        contactPerson {
          name
          telephone
        }
        telephone
        zone {
          name
        }
        region {
          name
        }
        buildingName
        estateName
        floor
        flat
        paymentStatementContactMethod {
          id
          name
        }
        paymentStatementOtherAddress
        paymentStatementEmail
      }
      denyCreatingOrder
      redDot {
        missingActiveServiceContract
        missingServiceStartDate
        expiryAssessment
        expiryService
        unissuingPaymentStatement
        unissuingLastPaymentStatement
      }
      trace {
        activityHistory {
          staff {
            tbId
            displayName
          }
          content
          loggedDate
        }
      }
    }
  }
`;

function OrderingDetailPage() {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();

  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.OPERATION_EQUIPMENT_MANAGEMENT,
  ]);

  const BREADCRUMB = [
    {
      path: PAGE.ORDERING_DETAIL,
      name: PAGE_NAME.ORDERING_DETAIL,
    },
  ];

  const [MODE, setMODE] = useState("view");

  const [getOrdering, { data: { orderingGet: ordering } = {} }] = useLazyQuery(
    ORDERING_GET
  );

  const [totalOrderingItemAmount, setTotalOrderingItemAmount] = useState(0);
  const [
    totalOtherOrderingItemAmount,
    setTotalOtherOrderingItemAmount,
  ] = useState(0);

  const [updateDeliveryParams, setUpdateDeliveryParams] = useState({
    visible: false,
  });
  const [
    orderingTerminationDrawerParams,
    setOrderingTerminationDrawerParams,
  ] = useState({ visible: false, orderingItemId: [] });

  const [
    changeEquipmentDrawerParams,
    setChangeEquipmentDrawerParams,
  ] = useState({
    visible: false,
    selectedOrderingItems: [],
  });

  const [batchEditMode, setBatchEditMode] = useState({ undefined });
  const [batchEditText, setBatchEditText] = useState(undefined);
  const [isBatchEdit, setIsBatchEdit] = useState(false);

  const [selectedOrderingItems, setSelectedOrderingItems] = useState([]);
  const [selectedOtherOrderingItems, setSelectedOtherOrderingItems] = useState(
    []
  );

  const [paymentPreviewVisible, setPaymentPreviewVisible] = useState(false);

  const batchDelivery = () => {
    setBatchEditMode("delivery");
    setBatchEditText("安裝送貨");
    setIsBatchEdit(!isBatchEdit);
    setSelectedOrderingItems([]);
    setSelectedOtherOrderingItems([]);
  };

  const batchTerminateOrderingItem = () => {
    setBatchEditMode("terminate");
    setBatchEditText("準備終止");
    setIsBatchEdit(!isBatchEdit);
    setSelectedOrderingItems([]);
    setSelectedOtherOrderingItems([]);
  };

  const batchReclaim = () => {
    setBatchEditMode("reclaim");
    setBatchEditText("安裝收回");
    setIsBatchEdit(!isBatchEdit);
    setSelectedOrderingItems([]);
    setSelectedOtherOrderingItems([]);
  };

  const batchChangeEquipment = () => {
    setBatchEditMode("changeEquipment");
    setBatchEditText("額外更換");
    setIsBatchEdit(!isBatchEdit);
    setSelectedOrderingItems([]);
    setSelectedOtherOrderingItems([]);
  };

  const createDelivery = (selectedItems) => {
    setUpdateDeliveryParams(
      {
        userId: id,
        deliveryId: undefined,
        createMode: "delivery",
        visible: true,
        orderingItems: selectedItems,
        onClose: () => setUpdateDeliveryParams({ visible: false }),
        onEditSuccess: () => {
          setUpdateDeliveryParams({ visible: false });
          history.push(PAGE.STOCK_DELIVERY);
        },
      },
      () => {
        console.log("updatting");
        console.log(updateDeliveryParams);
      }
    );
  };

  const createReclaim = (selectedItems) => {
    setUpdateDeliveryParams(
      {
        userId: id,
        deliveryId: undefined,
        createMode: "reclaim",
        visible: true,
        orderingItems: selectedItems,
        onClose: () => setUpdateDeliveryParams({ visible: false }),
        onEditSuccess: () => {
          setUpdateDeliveryParams({ visible: false });
          history.push(PAGE.STOCK_DELIVERY);
        },
      },
      () => {
        console.log("updatting");
        console.log(updateDeliveryParams);
      }
    );
  };

  const batchEdit = (isConfirm) => {
    if (isConfirm) {
      if (
        selectedOrderingItems?.length === 0 &&
        selectedOtherOrderingItems?.length === 0
      ) {
        message.error("未有選擇項目");
        return;
      }
    }
    switch (batchEditMode) {
      case "delivery": {
        if (isConfirm) {
          const nItems = [
            ...selectedOrderingItems,
            ...selectedOtherOrderingItems,
          ];
          createDelivery(JSON.parse(JSON.stringify(nItems)));
        }
        batchDelivery();
        break;
      }
      case "terminate": {
        if (isConfirm) {
          const nItems = [
            ...selectedOrderingItems,
            ...selectedOtherOrderingItems,
          ];
          setOrderingTerminationDrawerParams({
            visible: true,
            orderingItemId: nItems.map(({ id }) => id),
          });
        }
        batchTerminateOrderingItem();
        break;
      }
      case "reclaim": {
        if (isConfirm) {
          createReclaim(JSON.parse(JSON.stringify(selectedOrderingItems)));
        }
        batchReclaim();
        break;
      }

      case "changeEquipment": {
        if (isConfirm) {
          setChangeEquipmentDrawerParams({
            visible: true,
            selectedOrderingItems,
          });
        }
        batchChangeEquipment();
        break;
      }
      default:
        break;
    }
  };

  const query = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  if (!query?.tabKey) {
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({
        tabKey: "orderingItem",
      }),
    });
  } else if (query?.tabKey === "orderingItem" && !query?.listType) {
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({
        tabKey: "orderingItem",
        listType: "current",
        ...query,
      }),
    });
  }

  useEffect(() => {
    if (id) {
      getOrdering({
        variables: {
          userId: id,
        },
      });
    }
  }, [id, getOrdering]);

  const canChangeEquipment = useMemo(() => {
    return !["pending", "overdue"].includes(ordering?.paymentStatus?.id);
  }, [ordering]);

  console.log(ordering?.redDot);

  const alertComponent = useMemo(() => {
    const getDescriptionByKey = (key) => {
      switch (key) {
        case "missingActiveServiceContract":
          return "沒有有效的服務協議";
        case "missingServiceStartDate":
          return "沒有實際開始租借日";
        case "expiryAssessment":
          return "評估過期";
        case "expiryService":
          return "預計服務日期已過";
        case "unissuingPaymentStatement":
          return "帳單尚未發出";
        case "unissuingLastPaymentStatement":
          return "最後付款通知尚未發出";
        default:
          return "";
      }
    };

    const hasRedPoint = Object.entries(ordering?.redDot ?? {}).reduce(
      (acc, [key, flag]) => acc || (flag && key !== "__typename"),
      false
    );
    if (!hasRedPoint) {
      return;
    }

    return (
      <Alert
        message="需處理項目"
        type="warning"
        description={
          <ul>
            {Object.entries(ordering?.redDot ?? {})
              .filter(([key, flag]) => flag && key !== "__typename")
              .map(([key, flag]) => (
                <li key={key}>{getDescriptionByKey(key)}</li>
              ))}
          </ul>
        }
      />
    );
  }, [ordering]);

  return (
    <StickyBreadcrumbPageComponent
      className="ordering-detail-page"
      breadcrumb={BREADCRUMB}
    >
      <div className="content-inner">
        {alertComponent}
        <Descriptions column={{xs: 2, sm: 4}} size="small" layout="horizontal">
          <Descriptions.Item label="用戶編號">
            {ordering?.user?.username || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="中文姓名">
            {ordering?.user?.nameChi || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="性別">
            {ordering?.user?.gender?.name || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="年齡">
            {ordering?.user?.dateOfBirth
              ? moment().diff(ordering?.user?.dateOfBirth, "years")
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="用戶聯絡電話(手提)">
            {ordering?.user?.telephone || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="主要照顧者姓名">
            {ordering?.user?.contactPerson
              ? ordering?.user?.contactPerson[0]?.name
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="主要照顧者電話">
            {ordering?.user?.contactPerson
              ? ordering?.user?.contactPerson[0]?.telephone
              : "-"}
          </Descriptions.Item>
          {/* <Descriptions.Item label=""></Descriptions.Item> */}
          <Descriptions.Item label="地址">
            {(() => {
              const address = `${ordering?.user?.area?.name ?? ""} ${ordering
                ?.user?.region?.name ?? ""} 
            ${ordering?.user?.buildingName ?? ""} ${ordering?.user
                ?.estateName ?? ""} 
            ${ordering?.user?.floor ?? ""} ${ordering?.user?.flat ?? ""}`;

              return `${address}`.trim() ? address : "-";
            })()}
          </Descriptions.Item>
          <Descriptions.Item label="聯絡方法(賬單寄送方法)">
            {ordering?.user?.paymentStatementContactMethod
              ? ordering?.user?.paymentStatementContactMethod[0]?.name
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="其他地址">
            {ordering?.user?.paymentStatementOtherAddress || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="電郵">
            {ordering?.user?.paymentStatementEmail || "-"}
          </Descriptions.Item>
        </Descriptions>
        <Tabs
          activeKey={query?.tabKey}
          onChange={(tabKey) => {
            if (MODE === "edit") {
              notification.warn({
                message: "正在編輯紀錄，請先儲存",
              });
              return;
            }

            history.replace({
              pathname: location.pathname,
              search: queryString.stringify({
                tabKey,
              }),
            });
          }}
        >
          <Tabs.TabPane
            className="tab orderingItem"
            tab={<>訂單項目</>}
            key="orderingItem"
          >
            <OrderingItem
              setTotalOrderingItemAmount={setTotalOrderingItemAmount}
              setTotalOtherOrderingItemAmount={setTotalOtherOrderingItemAmount}
              batchEdit={batchEdit}
              isBatchEdit={isBatchEdit}
              setIsBatchEdit={setIsBatchEdit}
              batchEditText={batchEditText}
              setBatchEditText={setBatchEditText}
              selectedOrderingItems={selectedOrderingItems}
              setSelectedOrderingItems={setSelectedOrderingItems}
              selectedOtherOrderingItems={selectedOtherOrderingItems}
              setSelectedOtherOrderingItems={setSelectedOtherOrderingItems}
              orderingTerminationDrawerParams={orderingTerminationDrawerParams}
              setOrderingTerminationDrawerParams={
                setOrderingTerminationDrawerParams
              }
              changeEquipmentDrawerParams={changeEquipmentDrawerParams}
              setChangeEquipmentDrawerParams={setChangeEquipmentDrawerParams}
              batchDelivery={batchDelivery}
              batchReclaim={batchReclaim}
              batchChangeEquipment={batchChangeEquipment}
              batchEditMode={batchEditMode}
              updateDeliveryParams={updateDeliveryParams}
              batchTerminateOrderingItem={batchTerminateOrderingItem}
              paymentPreviewVisible={paymentPreviewVisible}
              setPaymentPreviewVisible={setPaymentPreviewVisible}
              userAddress={`${ordering?.user?.area?.name || ""} ${ordering?.user
                ?.region?.name || ""} ${ordering?.user?.buildingName ||
                ""} ${ordering?.user?.estateName || ""} ${ordering?.user
                ?.floor || ""} ${ordering?.user?.flat || ""}`}
              denyCreatingOrder={ordering?.denyCreatingOrder}
              canChangeEquipment={canChangeEquipment}
            />
          </Tabs.TabPane>
          {!isDenied && (
            <Tabs.TabPane tab="付款資料" key="payment">
              <OrderingPayment userId={ordering?.user?.userId} />
            </Tabs.TabPane>
          )}
          {!isDenied && (
            <Tabs.TabPane tab="用戶評估" key="assessment">
              <OrderingAssessment
                query={query}
                userId={ordering?.user?.userId}
                MODE={MODE}
                setMODE={setMODE}
              />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane tab="活動記錄" key="activityLog">
            <OrderingActivityHistory userId={ordering?.user?.userId} />
          </Tabs.TabPane>
        </Tabs>

        {query?.tabKey === "orderingItem" && (
          <BottomBar
            totalOrderingItemAmount={totalOrderingItemAmount}
            totalOtherOrderingItemAmount={totalOtherOrderingItemAmount}
            batchEdit={batchEdit}
            isBatchEdit={isBatchEdit}
            batchEditText={batchEditText}
            batchEditMode={batchEditMode}
            selectedOrderingItems={selectedOrderingItems}
            selectedOtherOrderingItems={selectedOtherOrderingItems}
            setPaymentPreviewVisible={setPaymentPreviewVisible}
          />
        )}
      </div>
    </StickyBreadcrumbPageComponent>
  );
}

export default OrderingDetailPage;
