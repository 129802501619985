import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Constants
import { PAGE } from "@utils/constants";

// Styles
import "./deliveryEditDrawer.scss";

// Utils
import { MOMENT_FORMAT } from "@utils/constants";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { replaceUrlByParams } from "@utils/api";

const DELIVERY_UPDATE = gql`
  mutation DeliveryUpdate($input: DeliveryUpdateInput) {
    deliveryUpdate(input: $input) {
      id
    }
  }
`;

const DELIVERY_CREATE = gql`
  mutation DeliveryCreate($input: DeliveryCreateInput) {
    deliveryCreate(input: $input)
  }
`;

const RECLAIM_CREATE = gql`
  mutation ReclaimCreate($input: ReclaimCreateInput) {
    reclaimCreate(input: $input)
  }
`;

const API = gql`
  query EnumList {
    enumRegionList {
      id
      name
      area {
        id
        name
        zone {
          id
          name
        }
      }
    }
    enumDeliveryStatusList {
      id
      name
    }
  }
`;

const ORDERING_GET = gql`
  query OrderingGet($userId: ID) {
    orderingGet(userId: $userId) {
      user {
        userId
        nameChi
        contactPerson {
          name
          telephone
        }
        zone {
          id
        }
        area {
          id
        }
        region {
          id
        }
        buildingName
        estateName
        floor
        flat
      }
    }
  }
`;

const INVENTORY_RECORD_BULK_GET = gql`
  query inventoryRecordBulkGet($referenceId: [String]) {
    inventoryRecordBulkGet(referenceId: $referenceId) {
      id
      referenceId
      status {
        id
        name
      }
      product {
        __typename
        id
        referenceId
        ... on Device {
          productInfo {
            nameChi
          }
        }
        ... on Part {
          productInfo {
            nameChi
          }
        }
        ... on Consumable {
          productInfo {
            nameChi
          }
        }
      }
    }
  }
`;

const DELIVERY_GET = gql`
  query DeliveryGet($id: ID) {
    deliveryGet(id: $id) {
      id
      referenceId
      type {
        id
        name
      }
      deliveryStatus {
        id
        name
      }
      deliveryDate
      deliveryPeriod {
        id
        name
      }
      contactPerson
      region {
        id
        name
      }
      area {
        id
        name
      }
      zone {
        id
        name
      }
      selfPick
      remark
      estateName
      buildingName
      floor
      flat
      responsibleStaff
      warehouse {
        id
      }
      inventoryRecord {
        id
        referenceId
        defaultPart {
          id
          referenceId
        }
        product {
          __typename
          id
          referenceId
          ... on Device {
            productInfo {
              nameChi
            }
          }
          ... on Part {
            productInfo {
              nameChi
            }
          }
          ... on Consumable {
            productInfo {
              nameChi
            }
          }
        }
      }
    }
  }
`;

const WAREHOUSE_LIST_GET = gql`
  query warehouseList {
    warehouseList {
      id
      name
    }
  }
`;

const DeliveryEditDrawer = (props) => {
  const {
    userId,
    deliveryId,
    createMode,
    // editMode,
    visible,
    orderingItems,
    onClose,
    mode,
    onEditSuccess,
    // workMode,
  } = props;
  const { TextArea } = Input;
  const isCreateMode = deliveryId === undefined;

  const columns = [
    {
      title: "器材編號",
      dataIndex: "referenceId",
      render: (text, inventoryRecord, index) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {isCreateMode ? (
            <span>{text}</span>
          ) : (
            <Form.Item
              style={{ width: "100%" }}
              validateStatus={
                errors?.inventoryRecordRefId &&
                errors?.inventoryRecordRefId[index] &&
                "error"
              }
              help={
                errors?.inventoryRecordRefId &&
                errors?.inventoryRecordRefId[index] &&
                errors?.inventoryRecordRefId[index].message
              }
            >
              <Input
                value={
                  watchValue?.inventoryRecordRefId
                    ? watchValue?.inventoryRecordRefId[index]
                    : text
                }
                disabled={isCreateMode || defaultPartList.includes(text)}
                onChange={({ target: { value } }) => {
                  const field = [...watchValue?.inventoryRecordRefId];
                  field[index] = value;
                  setValue("inventoryRecordRefId", field);
                }}
              />
            </Form.Item>
          )}
        </div>
      ),
    },
    {
      title: "器材款號",
      dataIndex: "product.referenceId",
      render: (text, inventoryRecord) => (
        <Link
          className="link"
          to={() => {
            switch (inventoryRecord?.product?.__typename) {
              case "Device":
                return replaceUrlByParams(PAGE.STOCK_DEVICE_DETAIL, {
                  id: inventoryRecord?.product?.id,
                });
              case "Part":
                return replaceUrlByParams(PAGE.STOCK_PART_DETAIL, {
                  id: inventoryRecord?.product?.id,
                });
              case "Consumable":
                return replaceUrlByParams(PAGE.STOCK_CONSUMABLE_DETAIL, {
                  id: inventoryRecord?.product?.id,
                });
              default:
                return null;
            }
          }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "器材名稱",
      width: "40%",
      dataIndex: "product.productInfo",
      render: (productInfo) => {
        return <div className="text">{productInfo?.nameChi}</div>;
      },
    },
  ];

  const {
    setError,
    clearErrors,
    errors,
    handleSubmit,
    register,
    setValue,
    watch,
    trigger,
  } = useForm({
    defaultValues: {
      deliveryDate: null,
      deliveryPeriodId: undefined,
      deliveryStatusId: undefined,
      installationDate: undefined,
      installationPeriodId: undefined,
      type: undefined,
      responsibleStaff: undefined,
      contactPerson: "",
      regionId: undefined,
      areaId: undefined,
      zoneId: undefined,
      selfPick: undefined,
      remark: "",
    },
  });

  const onBulkGetInventoryRecordCompleted = async (data) => {
    if (data) {
      const formValid = await trigger();
      const inventoryRecord = [];

      watchValue.inventoryRecordRefId.forEach((referenceId, index) => {
        const oldInventory =
          getDeliveryData?.deliveryGet?.inventoryRecord[index];
        const newInventory = data.inventoryRecordBulkGet.find(
          (item) => item.referenceId === referenceId
        );

        //checking
        if (newInventory) {
          if (
            oldInventory.product.referenceId !==
            newInventory.product.referenceId
          ) {
            setError(`inventoryRecordRefId[${index}]`, {
              message: "器材款號不正確",
            });
          }

          if (
            oldInventory.id !== newInventory.id &&
            newInventory.status.id !== "readyForLease"
          ) {
            setError(`inventoryRecordRefId[${index}]`, {
              message: `器材狀態不可租用 (${newInventory.status.name})`,
            });
          }
        } else {
          setError(`inventoryRecordRefId[${index}]`, {
            message: "找不到器材編號",
          });
        }

        // valid and changed
        if (newInventory && oldInventory.id !== newInventory.id) {
          inventoryRecord.push({
            updateFrom: oldInventory.id,
            updateTo: newInventory.id,
          });
        }
      });

      if (formValid && !errors?.inventoryRecordRefId?.length) {
        saveDelivery(watchValue, inventoryRecord);
      }
    }
  };

  const [
    updateDelivery,
    { loading: updateDeliveryLoading, data: updateDeliveryData },
  ] = useMutation(DELIVERY_UPDATE);

  const [
    createDelivery,
    { loading: createDeliveryLoading, data: createDeliveryData },
  ] = useMutation(DELIVERY_CREATE);

  const [
    createReclaim,
    { loading: createReclaimLoading, data: createReclaimData },
  ] = useMutation(RECLAIM_CREATE);

  const [
    getDelivery,
    { loading: getDeliveryLoading, data: getDeliveryData },
  ] = useLazyQuery(DELIVERY_GET);

  const [
    bulkGetInventoryRecord,
    { loading: bulkGetInventoryRecordLoading },
  ] = useLazyQuery(INVENTORY_RECORD_BULK_GET, {
    onCompleted: onBulkGetInventoryRecordCompleted,
  });

  const { data: warehouseListData, loading: warehouseListLoading } = useQuery(
    WAREHOUSE_LIST_GET
  );

  const [
    getOrdering,
    { data: { orderingGet: ordering } = {}, loading: getOrderingLoading },
  ] = useLazyQuery(ORDERING_GET);

  const { data: enumList } = useQuery(API);

  const isLoading =
    updateDeliveryLoading ||
    createDeliveryLoading ||
    createReclaimLoading ||
    getDeliveryLoading ||
    bulkGetInventoryRecordLoading ||
    warehouseListLoading ||
    getOrderingLoading;

  const workMode = useMemo(() => getDeliveryData?.deliveryGet?.type?.name, [
    getDeliveryData,
  ]);

  const watchValue = watch();
  // console.log("watchValue", watchValue);

  const [tableData, setTableData] = useState(undefined);

  const [savingDelivery, setSavingDelivery] = useState(false);

  const defaultPartList = useMemo(
    () =>
      getDeliveryData?.deliveryGet?.inventoryRecord
        .map((item) => item.defaultPart.map((part) => part?.referenceId))
        .flat(),
    [getDeliveryData]
  );
  // console.log(defaultPartList);
  useEffect(() => {
    if (orderingItems) {
      const inventories = [];
      orderingItems.forEach(
        (item) =>
          item.inventoryRecord[0] && inventories.push(item.inventoryRecord[0])
      );
      setTableData(inventories);
    }
  }, [orderingItems]);

  useEffect(() => {
    register(
      { name: "deliveryDate" },
      { required: !(isCreateMode && createMode === "reclaim") }
    );
    register(
      { name: "deliveryPeriodId" },
      { required: !(isCreateMode && createMode === "reclaim") }
    );
    register(
      { name: "installationDate" },
      { required: isCreateMode && createMode === "delivery" }
    );
    register(
      { name: "installationPeriodId" },
      { required: isCreateMode && createMode === "delivery" }
    );

    register(
      { name: "reclaimDate" },
      { required: isCreateMode && createMode === "reclaim" }
    );
    register(
      { name: "reclaimPeriodId" },
      { required: isCreateMode && createMode === "reclaim" }
    );
    register(
      { name: "disassemblyDate" },
      { required: isCreateMode && createMode === "reclaim" }
    );
    register(
      { name: "disassemblyPeriodId" },
      { required: isCreateMode && createMode === "reclaim" }
    );

    register({ name: "deliveryStatusId" });
    register({ name: "type" });
    register({ name: "typeName" });
    register({ name: "responsibleStaff" });
    register({ name: "contactPerson" }, { required: true });
    register({ name: "regionId" }, { required: true });
    register({ name: "areaId" }, { required: true });
    register({ name: "zoneId" }, { required: true });
    register({ name: "selfPick" });
    register({ name: "remark" });
    register({ name: "estateName" });
    register({ name: "buildingName" });
    register({ name: "floor" });
    register({ name: "flat" });
    register({ name: "warehouseId" }, { required: watchValue?.selfPick });
    register({ name: "inventoryRecordRefId" });
  }, [register, isCreateMode, createMode, watchValue]);

  useEffect(() => {
    if (getDeliveryData?.deliveryGet) {
      const data = getDeliveryData?.deliveryGet;
      setValue("deliveryDate", data.deliveryDate);
      setValue("deliveryPeriodId", data.deliveryPeriod?.id);
      setValue("deliveryStatusId", data.deliveryStatus?.id);
      setValue("type", data.type);
      setValue("contactPerson", data.contactPerson);
      setValue("regionId", data.region?.id);
      setValue("areaId", data.area?.id);
      setValue("zoneId", data.zone?.id);
      setValue("selfPick", data.selfPick);
      setValue("remark", data.remark);
      setValue("estateName", data.estateName);
      setValue("buildingName", data.buildingName);
      setValue("floor", data.floor);
      setValue("flat", data.flat);
      setValue("responsibleStaff", data.responsibleStaff);
      setValue("warehouseId", data.warehouse?.id);
      setValue(
        "inventoryRecordRefId",
        data.inventoryRecord?.map(({ referenceId }) => referenceId)
      );
      setTableData(data.inventoryRecord);
    }
  }, [getDeliveryData, setValue]);

  useEffect(() => {
    if (userId && isCreateMode) {
      getOrdering({
        variables: {
          userId: userId,
        },
      });
    }
    // eslint-disable-next-line
  }, [userId, isCreateMode]);

  useEffect(() => {
    if (ordering) {
      const user = ordering.user;
      setValue("contactPerson", user.nameChi);
      setValue("regionId", user.region?.id);
      setValue("areaId", user.area?.id);
      setValue("zoneId", user.zone?.id);
      setValue("estateName", user.estateName);
      setValue("buildingName", user.buildingName);
      setValue("floor", user.floor);
      setValue("flat", user.flat);
    }
  }, [ordering, setValue]);

  useEffect(() => {
    if (deliveryId) {
      const id = deliveryId;
      getDelivery({
        variables: {
          id,
        },
      });
    }
  }, [getDelivery, deliveryId]);

  useEffect(() => {
    if (updateDeliveryData) {
      onEditSuccess(true);
    }
  }, [updateDeliveryData, onEditSuccess]);

  useEffect(() => {
    if (createDeliveryData) {
      onEditSuccess(true);
    }
  }, [createDeliveryData, onEditSuccess]);

  useEffect(() => {
    if (createReclaimData) {
      onEditSuccess(true);
    }
  }, [createReclaimData, onEditSuccess]);

  const onSaveButtonClick = () => {
    if (isCreateMode) {
      handleSubmit(saveDelivery)();
    } else {
      clearErrors();
      bulkGetInventoryRecord({
        variables: { referenceId: watchValue.inventoryRecordRefId },
      });
    }
  };

  const saveDelivery = useCallback(
    async (value, inventoryRecord = []) => {
      if (savingDelivery === false) {
        setSavingDelivery(true);
        try {
          const baseValue = {
            responsibleStaff: value.responsibleStaff,
            contactPerson: value.contactPerson,
            selfPick: value.selfPick,
            remark: value.remark,
            region: value.regionId,
            area: value.areaId,
            zone: value.zoneId,
            estateName: value.estateName,
            buildingName: value.buildingName,
            floor: value.floor,
            flat: value.flat,
            warehouseId: value.warehouseId,
          };
          if (isCreateMode) {
            let cValue = {};
            if (createMode === "delivery") {
              cValue = {
                ...baseValue,
                deliveryDate: value.deliveryDate,
                deliveryPeriod: value.deliveryPeriodId,
                installationDate: value.installationDate,
                installationPeriod: value.installationPeriodId,
                orderingItemId: orderingItems.map((item) => item.id),
              };
              const deliveryFormValue = JSON.parse(JSON.stringify(cValue));
              await createDelivery({
                variables: {
                  input: deliveryFormValue,
                },
              });
              message.success("成功新增送貨安裝");
            } else {
              // reclaim mode
              cValue = {
                ...baseValue,
                reclaimDate: value.reclaimDate,
                reclaimPeriod: value.reclaimPeriodId,
                disassemblyDate: value.disassemblyDate,
                disassemblyPeriod: value.disassemblyPeriodId,
                orderingItemId: orderingItems.map((item) => item.id),
              };
              const deliveryFormValue = JSON.parse(JSON.stringify(cValue));
              await createReclaim({
                variables: {
                  input: deliveryFormValue,
                },
              });
              message.success("成功新增收回安裝");
            }
          } else {
            const uValue = {
              ...baseValue,
              deliveryStatus: value.deliveryStatusId,
              deliveryDate: value.deliveryDate,
              deliveryPeriod: value.deliveryPeriodId,
              id: deliveryId,
              inventoryRecord:
                inventoryRecord.length > 0 ? inventoryRecord : undefined,
            };
            const deliveryFormValue = JSON.parse(JSON.stringify(uValue));
            await updateDelivery({
              variables: {
                input: deliveryFormValue,
              },
            });
            message.success("成功更新送貨安裝");
          }
        } catch (error) {
          message.error(error.message);
        }
        setSavingDelivery(false);
      }
    },
    [
      savingDelivery,
      isCreateMode,
      createMode,
      orderingItems,
      createDelivery,
      createReclaim,
      deliveryId,
      updateDelivery,
    ]
  );

  const onRegionChange = (value) => {
    setValue("regionId", value);
    const firstArea =
      enumList?.enumRegionList.find((region) => region.id === value)?.area[0] ??
      undefined;
    const firstZone = firstArea?.zone[0];
    setValue("areaId", firstArea?.id ?? undefined);
    setValue("zoneId", firstZone?.id ?? undefined);
  };

  const onAreaChange = (value) => {
    setValue("areaId", value);
    const area =
      enumList?.enumRegionList
        .find((region) => region.id === watchValue.regionId)
        ?.area?.find((area) => area.id === value) ?? undefined;
    const firstZone = area?.zone[0] ?? undefined;
    setValue("zoneId", firstZone?.id ?? undefined);
  };

  const onZoneChange = (value) => {
    setValue("zoneId", value);
  };

  const drawerModeName = () => {
    switch (mode) {
      case "edit":
        return "編輯";
      default:
        return "新增";
    }
  };

  const renderDrawerTitle = () =>
    `${drawerModeName()}${workMode ? workMode : ""}安排`;

  return (
    <div className="DeliveryEditDrawer">
      <Drawer
        className="delivery-edit-drawer"
        width="50vw"
        visible={visible}
        onClose={onClose}
      >
        <Spin spinning={isLoading}>
          <div className="delivery-edit-content">
            <h1>{renderDrawerTitle()}</h1>
            <div className="delivery-main-content">
              <div className="delivery-form">
                <Form layout="vertical">
                  {!isCreateMode && (
                    <div>
                      <Form.Item>
                        <div className="header">
                          <span>工作狀態</span>
                        </div>
                        <Select
                          style={{ width: "49%" }}
                          placeholder="請選擇工作狀態"
                          value={watchValue.deliveryStatusId}
                          onChange={(value) =>
                            setValue("deliveryStatusId", value)
                          }
                        >
                          {enumList?.enumDeliveryStatusList.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item>
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <div className="header">
                              {"預計" + workMode + "日期"}
                            </div>
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="請選擇日期"
                              value={
                                watchValue.deliveryDate
                                  ? moment(
                                      watchValue.deliveryDate,
                                      MOMENT_FORMAT
                                    )
                                  : null
                              }
                              onChange={(date, dateString) =>
                                setValue("deliveryDate", dateString)
                              }
                            />
                            <div>
                              {errors.deliveryDate && (
                                <Typography.Text type="danger">
                                  請選擇日期
                                </Typography.Text>
                              )}
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="header">{workMode + "時段"}</div>
                            <Select
                              placeholder="請選擇時段"
                              value={watchValue.deliveryPeriodId}
                              onChange={(value) =>
                                setValue("deliveryPeriodId", value)
                              }
                            >
                              <Select.Option value="am">上午</Select.Option>
                              <Select.Option value="pm">下午</Select.Option>
                            </Select>
                            <div>
                              {errors.deliveryPeriodId && (
                                <Typography.Text type="danger">
                                  請選擇時段
                                </Typography.Text>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Form.Item>
                    </div>
                  )}
                  {isCreateMode && createMode === "delivery" && (
                    <div>
                      <Form.Item>
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <div className="header">預計送貨日期</div>
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="請選擇日期"
                              value={
                                watchValue.deliveryDate
                                  ? moment(
                                      watchValue.deliveryDate,
                                      MOMENT_FORMAT
                                    )
                                  : null
                              }
                              onChange={(date, dateString) =>
                                setValue("deliveryDate", dateString)
                              }
                            />
                            <div>
                              {errors.deliveryDate && (
                                <Typography.Text type="danger">
                                  請選擇日期
                                </Typography.Text>
                              )}
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="header">送貨時段</div>
                            <Select
                              placeholder="請選擇時段"
                              value={watchValue.deliveryPeriodId}
                              onChange={(value) =>
                                setValue("deliveryPeriodId", value)
                              }
                              onBlur={() => {
                                setValue(
                                  "installationPeriodId",
                                  watchValue.deliveryPeriodId
                                );
                              }}
                            >
                              <Select.Option value="am">上午</Select.Option>
                              <Select.Option value="pm">下午</Select.Option>
                            </Select>
                            <div>
                              {errors.deliveryPeriodId && (
                                <Typography.Text type="danger">
                                  請選擇時段
                                </Typography.Text>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item>
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <div className="header">預計安裝日期</div>
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="請選擇日期"
                              value={
                                watchValue.installationDate
                                  ? moment(
                                      watchValue.installationDate,
                                      MOMENT_FORMAT
                                    )
                                  : null
                              }
                              onChange={(date, dateString) =>
                                setValue("installationDate", dateString)
                              }
                            />
                            <div>
                              {errors.installationDate && (
                                <Typography.Text type="danger">
                                  請選擇日期
                                </Typography.Text>
                              )}
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="header">安裝時段</div>
                            <Select
                              placeholder="請選擇時段"
                              value={watchValue.installationPeriodId}
                              onChange={(value) =>
                                setValue("installationPeriodId", value)
                              }
                            >
                              <Select.Option value="am">上午</Select.Option>
                              <Select.Option value="pm">下午</Select.Option>
                            </Select>
                            <div>
                              {errors.installationPeriodId && (
                                <Typography.Text type="danger">
                                  請選擇時段
                                </Typography.Text>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Form.Item>
                    </div>
                  )}
                  {isCreateMode && createMode === "reclaim" && (
                    <div>
                      <Form.Item>
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <div className="header">預計收回日期</div>
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="請選擇日期"
                              value={
                                watchValue.reclaimDate
                                  ? moment(
                                      watchValue.reclaimDate,
                                      MOMENT_FORMAT
                                    )
                                  : null
                              }
                              onChange={(date, dateString) =>
                                setValue("reclaimDate", dateString)
                              }
                              onBlur={() => {
                                setValue(
                                  "disassemblyDate",
                                  watchValue.reclaimDate
                                    ? moment(watchValue.reclaimDate).format(
                                        MOMENT_FORMAT
                                      )
                                    : null
                                );
                              }}
                            />
                            <div>
                              {errors.reclaimDate && (
                                <Typography.Text type="danger">
                                  請選擇日期
                                </Typography.Text>
                              )}
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="header">收回時段</div>
                            <Select
                              placeholder="請選擇時段"
                              value={watchValue.reclaimPeriodId}
                              onChange={(value) =>
                                setValue("reclaimPeriodId", value)
                              }
                              onBlur={() => {
                                setValue(
                                  "disassemblyPeriodId",
                                  watchValue.reclaimPeriodId
                                );
                              }}
                            >
                              <Select.Option value="am">上午</Select.Option>
                              <Select.Option value="pm">下午</Select.Option>
                            </Select>
                            <div>
                              {errors.reclaimPeriodId && (
                                <Typography.Text type="danger">
                                  請選擇時段
                                </Typography.Text>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item>
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <div className="header">預計拆卸日期</div>
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="請選擇日期"
                              value={
                                watchValue.disassemblyDate
                                  ? moment(
                                      watchValue.disassemblyDate,
                                      MOMENT_FORMAT
                                    )
                                  : null
                              }
                              onChange={(date, dateString) =>
                                setValue("disassemblyDate", dateString)
                              }
                            />
                            <div>
                              {errors.disassemblyDate && (
                                <Typography.Text type="danger">
                                  請選擇日期
                                </Typography.Text>
                              )}
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="header">拆卸時段</div>
                            <Select
                              placeholder="請選擇時段"
                              value={watchValue.disassemblyPeriodId}
                              onChange={(value) =>
                                setValue("disassemblyPeriodId", value)
                              }
                            >
                              <Select.Option value="am">上午</Select.Option>
                              <Select.Option value="pm">下午</Select.Option>
                            </Select>
                            <div>
                              {errors.disassemblyPeriodId && (
                                <Typography.Text type="danger">
                                  請選擇時段
                                </Typography.Text>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Form.Item>
                    </div>
                  )}
                  <Form.Item>
                    <div className="header">
                      <span>服務職員</span>
                    </div>
                    <Input
                      placeholder="填寫服務職員"
                      value={watchValue.responsibleStaff}
                      onChange={(e) =>
                        setValue("responsibleStaff", e.target.value)
                      }
                    />
                    <div>
                      {errors.responsibleStaff && (
                        <Typography.Text type="danger">
                          請填寫服務職員
                        </Typography.Text>
                      )}
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div className="header">
                      <span>送貨聯絡人</span>
                    </div>
                    <Input
                      placeholder="填寫若與登記用戶資料不附"
                      value={watchValue.contactPerson}
                      onChange={(e) =>
                        setValue("contactPerson", e.target.value)
                      }
                    />
                    <div>
                      {errors.contactPerson && (
                        <Typography.Text type="danger">
                          請填寫送貨聯絡人
                        </Typography.Text>
                      )}
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div className="header">
                      <span>送貨地址</span>
                    </div>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <Select
                          placeholder="區域"
                          value={watchValue.regionId}
                          onChange={onRegionChange}
                        >
                          {enumList?.enumRegionList.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                        <div>
                          {errors.regionId && (
                            <Typography.Text type="danger">
                              請選擇區域
                            </Typography.Text>
                          )}
                        </div>
                      </Col>
                      <Col span={8}>
                        <Select
                          placeholder="地區"
                          value={watchValue.areaId}
                          onChange={onAreaChange}
                        >
                          {enumList?.enumRegionList
                            .find((region) => region.id === watchValue.regionId)
                            ?.area?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option.name}
                              </Select.Option>
                            ))}
                        </Select>
                        <div>
                          {errors.areaId && (
                            <Typography.Text type="danger">
                              請選擇地區
                            </Typography.Text>
                          )}
                        </div>
                      </Col>
                      <Col span={8}>
                        <Select
                          placeholder="地區內分區"
                          value={watchValue.zoneId}
                          onChange={onZoneChange}
                        >
                          {enumList?.enumRegionList
                            .find((region) => region.id === watchValue.regionId)
                            ?.area?.find(
                              (area) => area.id === watchValue.areaId
                            )
                            ?.zone?.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option.name}
                              </Select.Option>
                            ))}
                        </Select>
                        <div>
                          {errors.zoneId && (
                            <Typography.Text type="danger">
                              請選擇地區內分區
                            </Typography.Text>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Input
                      style={{ marginTop: "8px" }}
                      placeholder="屋苑名稱（填寫若與登記地址資料不附）"
                      value={watchValue.estateName}
                      onChange={(e) => setValue("estateName", e.target.value)}
                    />
                    <Input
                      style={{ marginTop: "8px" }}
                      placeholder="大廈名稱（填寫若與登記地址資料不附）"
                      value={watchValue.buildingName}
                      onChange={(e) => setValue("buildingName", e.target.value)}
                    />
                    <Input
                      style={{ marginTop: "8px" }}
                      placeholder="樓層（填寫若與登記地址資料不附）"
                      value={watchValue.floor}
                      onChange={(e) => setValue("floor", e.target.value)}
                    />
                    <Input
                      style={{ marginTop: "8px" }}
                      placeholder="單位（填寫若與登記地址資料不附）"
                      value={watchValue.flat}
                      onChange={(e) => setValue("flat", e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Checkbox
                      checked={watchValue.selfPick}
                      onChange={(e) => {
                        setValue("selfPick", e.target.checked);
                      }}
                    >
                      用家自行提取貨物
                    </Checkbox>
                  </Form.Item>
                  <Form.Item label="工場">
                    <Select
                      placeholder="請選擇工場"
                      loading={warehouseListLoading}
                      value={watchValue?.warehouseId}
                      onChange={(data) => setValue("warehouseId", data)}
                    >
                      {warehouseListData?.warehouseList?.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                    {errors?.warehouseId && (
                      <Typography.Text type="danger">
                        請選擇工場
                      </Typography.Text>
                    )}
                  </Form.Item>
                  <Form.Item>
                    <div className="header">
                      <span>備註</span>
                    </div>
                    <TextArea
                      placeholder="填寫備註"
                      value={watchValue.remark}
                      rows={5}
                      onChange={(e) => setValue("remark", e.target.value)}
                    />
                  </Form.Item>
                </Form>
              </div>
              <div
                className="delivery-table-content"
                style={{ marginLeft: "10px", marginRight: "10px" }}
              >
                <div>
                  <span>已選擇器材</span>
                </div>
                <div style={{ marginTop: "8px" }}>
                  <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                    scroll={{x:"max-content"}}
                  ></Table>
                </div>
              </div>
            </div>
            <div className="btn-container">
              <Button className="btn btn-secondary" onClick={onClose}>
                取消
              </Button>
              <Button
                className="btn"
                type="primary"
                onClick={onSaveButtonClick}
              >
                儲存
              </Button>
            </div>
          </div>
        </Spin>
      </Drawer>
    </div>
  );
};

DeliveryEditDrawer.propTypes = {
  userId: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  deliveryId: PropTypes.string, // If deliveryId !== null, edit mode, else create mode
  createMode: PropTypes.string, // mode: delivery, reclaim
  // editMode: PropTypes.string, // mode: delivery, reclaim, installation, uninstallation
  orderingItems: PropTypes.array,
  onEditSuccess: PropTypes.func,
  mode: PropTypes.string,
  // workMode: PropTypes.string,
};

DeliveryEditDrawer.defaultProps = {
  userId: "",
  visible: false,
  createMode: "delivery",
  // editMode: "delivery",
};

export default DeliveryEditDrawer;
