import React from 'react';
import { useSelector } from 'react-redux';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Style
import './loading.scss';

function LoadingComponent() {
    const store = useSelector((state) => state.storeReducer);

    const isLoading = store.isLoading;

    return (
        <>
            {
                isLoading &&
                <div className='Loading-component'>
                    <FontAwesomeIcon icon={['fas', 'spinner']} size={'4x'} pulse />
                </div>
            }
        </>
    );
}

export default LoadingComponent;
