import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { setIsLoadingAction } from "@actions/storeAction";

// Components
import {
  Row,
  Col,
  Table,
  Select,
  Input,
  Modal,
  notification,
  DatePicker,
} from "antd";

// Style
import "./createAssessmentModal.scss";

// Utils
import { classList } from "@utils/common";
import { DEFAULT_MSG } from "@utils/constants";

const API = gql`
  {
    enumAssessmentMethodList {
      id
      name
    }
    assessmentList {
      id
      title
    }
  }
`;

const ASSESSMENT_RESULT_CREATE = gql`
  mutation AssessmentResultCreate($input: AssessmentResultCreateInput) {
    assessmentResultCreate(input: $input) {
      id
    }
  }
`;

function CreateAssessmentModalComponent(props) {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.storeReducer);

  const { className, visible, onClose, onSubmit, userId } = props;

  const [createAssessment, setCreateAssessment] = useState({
    assessment: [],
    assessmentExpectedDate: undefined,
    method: undefined,
    remark: undefined,
  });

  const [
    assessmentResultCreate,
    {
      loading: assessmentResultCreateLoading,
      error: assessmentResultCreateError,
      data: assessmentResultCreateData,
    },
  ] = useMutation(ASSESSMENT_RESULT_CREATE, {
    variables: {
      input: {
        assessmentId: createAssessment.assessment[0],
        assessmentMethod: createAssessment.method,
        assessmentExpectedDate: moment(
          createAssessment.assessmentExpectedDate
        ).format("YYYY-MM-DD"),
        personInCharge: store.user.tbId,
        remark: createAssessment.remark,
        userId: userId,
      },
    },
  });
  const { data } = useQuery(API);

  useEffect(() => {
    dispatch(setIsLoadingAction(assessmentResultCreateLoading));

    // eslint-disable-next-line
  }, [
    assessmentResultCreateLoading,
    assessmentResultCreateError,
    assessmentResultCreateData,
  ]);

  const getClassList = () =>
    classList({
      "CreateAssessmentModal-Component": true,
      [className]: true,
    });

  const onCreateAssessmentExpectedDateChange = (value) => {
    console.log(value);
    setCreateAssessment({
      ...createAssessment,
      assessmentExpectedDate: value ? +value.format("x") : undefined,
    });
  };

  const onCreateAssessmentMethodChange = (value) => {
    setCreateAssessment({
      ...createAssessment,
      method: value,
    });
  };

  const onCreateAssessmentRemarkChange = (evt) => {
    setCreateAssessment({
      ...createAssessment,
      remark: evt.target.value,
    });
  };

  const onCreateAssessmentSelectChange = (selectedRowKeys, selectedRows) => {
    setCreateAssessment({
      ...createAssessment,
      assessment: selectedRowKeys,
    });
  };

  const onCreateAssessmentModalCancel = () => {
    resetData();
    onClose();
  };

  const onCreateAssessmentModalSubmit = async () => {
    const res = await assessmentResultCreate();
    if (res.data.assessmentResultCreate) {
      notification.success({
        message: "成功新增評估",
      });
      resetData();
      onSubmit();
    } else {
      notification.error({
        message: DEFAULT_MSG.error,
      });
    }
  };

  const resetData = () => {
    setCreateAssessment({
      assessment: [],
      assessmentExpectedDate: undefined,
      method: undefined,
      remark: undefined,
    });
  };

  return (
    <Modal
      className={getClassList()}
      cancelText="取消"
      okButtonProps={{
        disabled:
          !createAssessment.assessment.length ||
          !createAssessment.method ||
          !createAssessment.assessmentExpectedDate,
      }}
      onCancel={() => onCreateAssessmentModalCancel()}
      onOk={onCreateAssessmentModalSubmit}
      okText="確定"
      title="可供選擇的評估"
      visible={visible}
      centered
    >
      <Row gutter={[16, 16]} type="flex">
        <Col xs={24}>
          <Table
            scroll={{x: "max-content" }}
            columns={[
              {
                title: "編號",
                dataIndex: "id",
              },
              {
                title: "評估名稱",
                dataIndex: "title",
              },
            ]}
            dataSource={data?.assessmentList}
            pagination={false}
            rowKey="id"
            rowSelection={{
              hideDefaultSelections: true,
              onChange: onCreateAssessmentSelectChange,
              selectedRowKeys: createAssessment.assessment,
              type: "radio",
            }}
          />
        </Col>
        <Col xs={6}>
          <div>評估方法</div>
          <Select
            onChange={onCreateAssessmentMethodChange}
            placeholder="請選擇"
            style={{ width: "100%" }}
            value={createAssessment.method}
          >
            {data?.enumAssessmentMethodList.map((method) => (
              <Select.Option key={method.id}>{method.name}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={18}>
          <div>預計評估日期</div>
          <DatePicker
            onChange={onCreateAssessmentExpectedDateChange}
            placeholder="選擇日期"
            value={
              createAssessment.assessmentExpectedDate
                ? moment(createAssessment.assessmentExpectedDate)
                : undefined
            }
          />
        </Col>
        <Col xs={24}>
          <div>備註</div>
          <Input
            onChange={onCreateAssessmentRemarkChange}
            placeholder="填寫備註"
            value={createAssessment.remark}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default CreateAssessmentModalComponent;

CreateAssessmentModalComponent.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};

CreateAssessmentModalComponent.defalutProps = {
  className: "",
};
