import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Link, useHistory } from "react-router-dom";

// Components
import { Button, notification, Table, Upload, Icon } from "antd";
import CustPaginationComponent from "@components/custPagination/custPagination";
import SearchBarComponent from "@components/searchBar/searchBar";

// Constants
import { NOTIFICATION_CONFIG } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

// Styles
import "./tableView.scss";

// Utils
import { classList } from "@utils/common";
import { usePrevious } from "@utils/hook";
import { PAGE_SIZE } from "@utils/constants";
import { isEmpty } from "@utils/validate";
import { getFileUploadUrl } from "@utils/env";
import { getTokenHeader } from "@utils/api";

function TableView(props) {
  notification.config(NOTIFICATION_CONFIG);

  // eslint-disable-next-line react/prop-types
  const {
    addBtnConfig,
    breadcrumb,
    setFilterKeyword,
    className,
    rowKey,
    columns,
    extraBtn,
    tableData,
    hasAddBtn,
    hasFilter,
    isFilterShow,
    isMaxPage,
    total,
    location,
    onFilterClick,
    searchBarPlaceholder,
    isTableLoading,
    rowSelection,
    extraChildren,
    showSearchBar,
  } = props;

  const history = useHistory();
  const [cols, setCols] = useState(columns);
  const [isLoading, setIsLoading] = useState(false);
  const [fileUploadList, setFileUploadList] = useState(undefined);
  const [query, setQuery] = useState({
    ...queryString.parse(location.search),
    keyword: undefined,
    page: 1,
    pageSize: PAGE_SIZE,
    sortField: undefined,
    sortOrder: undefined,
  });
  const prevQuery = usePrevious(query);

  // useEffect(() => {
  //   // getFilterKeyword(filterKeyword);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterKeyword]);

  const isUploadShow = useMemo(() => {
    const showUploadPathList = [
      "/order_terminated",
      "/order_reserve",
      "/order_in_service",
      "/order_to_be_terminate",
    ];
    if (showUploadPathList.includes(location.pathname)) {
      return true;
    } else {
      return false;
    }
  }, [location.pathname]);

  useEffect(() => {
    const { keyword, page, pageSize, sortField, sortOrder, ...search } =
      queryString.parse(location.search);
    setQuery({
      ...search,
      keyword,
      page: isNaN(page) || page < 1 ? 1 : +page,
      pageSize: isNaN(pageSize) || pageSize < 1 ? PAGE_SIZE : +pageSize,
      sortField,
      sortOrder,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setIsLoading(isTableLoading);
  }, [isTableLoading]);

  useEffect(() => {
    if (!isEmpty(query)) {
      if (query.page !== prevQuery?.page) {
        if (query.keyword) {
          onKeywordSearch(query);
        } else {
          onSortChangeSearch();
        }
      } else {
        if (prevQuery?.keyword !== query.keyword) {
          if (query.keyword) {
            onKeywordSearch(query);
          } else {
            // onKeywordSearch(queryString.parse(location.search));
            const q = {
              ...queryString.parse(location.search),
              keyword: undefined,
            };
            onKeywordSearch(q);
          }
        } else if (
          prevQuery?.sortField !== query.sortField ||
          prevQuery?.sortOrder !== query.sortOrder
        ) {
          onSortChangeSearch();
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const getClassList = () =>
    classList({
      "Table-View": true,
      [className]: true,
    });

  const onPageChange = (page) => {
    setQuery({
      ...query,
      page,
    });
  };

  const onSearch = (data) => {
    const value = !isEmpty(data) ? data : undefined;
    const q = {
      ...query,
      keyword: value,
      page: 1,
    };
    setQuery(q);
  };
  console.log("setFilterKeyword", setFilterKeyword);

  const onSearchInputChange = useCallback(
    (evt) => {
      const value = !isEmpty(evt) ? evt : undefined;
      if (setFilterKeyword) {
        setFilterKeyword({
          ...query,
          keyword: value,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSortChange = (pagination, filters, sorter) => {
    setQuery({
      ...query,
      sortField: sorter.order ? sorter.field : undefined,
      sortOrder: sorter.order,
    });
  };

  const onKeywordSearch = (query) => {
    history.push({
      search: `?${queryString.stringify(query)}`,
    });
  };

  const onSortChangeSearch = () => {
    let q = queryString.parse(location.search);
    q = { ...q, ...query };

    if (prevQuery?.page === query?.page) {
      q.page = 1;
    }
    setQuery(q);

    history.push({
      search: `?${queryString.stringify(q)}`,
    });

    let cols = columns.reduce((acc, col) => {
      acc.push({
        ...col,
        sortOrder: col.dataIndex === query.sortField ? query.sortOrder : false,
      });
      return acc;
    }, []);
    setCols(cols);
  };

  const onAttachmentChange = (info) => {
    let fileList = [...info.fileList];
    // only one file allowed
    fileList = fileList.slice(-1);
    fileList = fileList.map((file) => {
      if (file.response?.data) {
        file.key = file.response.data?.key;
        file.url = file.response.data?.url;
      }
      return file;
    });
    setFileUploadList(fileList);
  };

  return (
    <StickyBreadcrumbPageComponent
      className={getClassList()}
      breadcrumb={breadcrumb}
    >
      <div className="content-inner">
        {extraChildren && (
          <div style={{ marginBottom: "20px", display: "block" }}>
            {extraChildren}
          </div>
        )}
        <div className="table-header">
          <div className="add-btn-search-bar-container">
            {hasAddBtn &&
              (addBtnConfig.url ? (
                <Link to={addBtnConfig.url}>
                  <Button className="btn btn-secondary">
                    {addBtnConfig.text}
                  </Button>
                </Link>
              ) : (
                <Button
                  className="btn btn-secondary"
                  onClick={addBtnConfig.onClick}
                >
                  {addBtnConfig.text}
                </Button>
              ))}
            <SearchBarComponent
              onSearchInputChange={onSearchInputChange}
              value={query.keyword}
              className="search-bar"
              extraBtn={extraBtn}
              hasAdvSearch={hasFilter}
              isAdvSearchShow={isFilterShow}
              onSearch={onSearch}
              onAdvSearchClick={onFilterClick}
              placeholder={searchBarPlaceholder}
              showSearchBar={showSearchBar}
            />
            {isUploadShow && (
              <div>
                <Upload
                  className="ordering-transfer-deposit-uploader"
                  headers={{
                    "x-token": getTokenHeader(),
                    "X-Requested-With": null,
                  }}
                  action={getFileUploadUrl()}
                  fileList={fileUploadList}
                  onChange={onAttachmentChange}
                >
                  <Button className="btn btn-secondary">
                    <Icon type="upload" />
                    上載交易紀錄
                  </Button>
                </Upload>
              </div>
            )}
          </div>
          <CustPaginationComponent
            isMinPage={query.page === 1}
            isMaxPage={isMaxPage}
            total={total}
            page={query.page}
            // pageSize={query.pageSize}
            pageSize={PAGE_SIZE}
            onPageChange={onPageChange}
          />
        </div>
        {hasFilter && props.children}
        <Table
          columns={cols}
          dataSource={tableData}
          rowKey={rowKey}
          loading={isLoading}
          onChange={onSortChange}
          pagination={false}
          rowSelection={rowSelection}
          scroll={{ x: "max-content" }}
        />
      </div>
    </StickyBreadcrumbPageComponent>
  );
}

export default TableView;

TableView.propTypes = {
  addBtnConfig: PropTypes.shape({
    url: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
  }),
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  children: PropTypes.node,
  className: PropTypes.string,
  rowKey: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      dataIndex: PropTypes.string.isRequired,
      sorter: PropTypes.bool,
      render: PropTypes.func,
    })
  ),
  extraBtn: PropTypes.node,
  extraChildren: PropTypes.node,
  hasAddBtn: PropTypes.bool,
  hasFilter: PropTypes.bool,
  isFilterShow: PropTypes.bool,
  isMaxPage: PropTypes.bool,
  total: PropTypes.number,
  isTableLoading: PropTypes.bool,
  setFilterKeyword: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
  onFilterClick: PropTypes.func,
  searchBarPlaceholder: PropTypes.string,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowSelection: PropTypes.object,
  showSearchBar: PropTypes.bool,
};

TableView.defaultProps = {
  addBtnConfig: {
    url: "#",
    text: "新增",
  },
  breadcrumb: undefined,
  children: undefined,
  className: undefined,
  rowKey: "id",
  columns: [],
  extraBtn: null,
  hasAddBtn: false,
  hasFilter: false,
  isFilterShow: false,
  isMaxPage: false,
  total: 0,
  isTableLoading: false,
  onFilterClick: () => {},
  searchBarPlaceholder: undefined,
  rowSelection: undefined,
  showSearchBar: true,
};
