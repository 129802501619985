import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";

// Components
import { Tag } from "antd";

// Constants
import { PAGE, PAGE_NAME, PAGE_SIZE } from "@utils/constants";

// Layouts
import TableView from "@layouts/tableView/tableView";

// Styles
import "./table.scss";

// Utils
import { replaceUrlByParams } from "@utils/api";

import { isEmpty, isEmptyObj } from "@utils/validate";
import Filter from "../../user/filter/filter";

const USER_LIST = gql`
  query userList(
    $size: Int!
    $offset: Int!
    $status: [WorkflowStatus]!
    $sort: SortUserList
    $referralAgencyName: String
    $referrerName: String
    $reverseOrder: Boolean
  ) {
    userList(
      offset: $offset
      size: $size
      status: $status
      sort: $sort
      referralAgencyName: $referralAgencyName
      referrerName: $referrerName
      reverseOrder: $reverseOrder
    ) {
      approvedDate
      approvalStatus
      hkid
      nameEng
      nameChi
      userId
      username
      telephone
      referralInfo {
        referrerName
        referralAgencyName
        referralDate
      }
    }
  }
`;

const USER_SEARCH = gql`
  query userSearch($keyword: String, $size: Int!, $offset: Int!) {
    userSearch(keyword: $keyword, offset: $offset, size: $size) {
      approvedDate
      approvalStatus
      hkid
      nameEng
      nameChi
      userId
      username
      telephone
      referralInfo {
        referrerName
        referralAgencyName
        referralDate
      }
    }
  }
`;

function DemoTablePage(props) {
  let history = useHistory();

  const BREADCRUMB = [
    {
      path: PAGE.OVERVIEW,
      name: PAGE_NAME.OVERVIEW,
    },
    {
      path: "/demo/form",
      name: "Demo Table",
    },
  ];
  const columns = [
    {
      title: "用戶號碼",
      dataIndex: "username",
      sorter: true,
      render: (text, record) => (
        <Link
          className="link"
          to={replaceUrlByParams(PAGE.USER_DETAIL, { id: record.userId })}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "中文姓名",
      dataIndex: "nameChi",
    },
    {
      title: "身份証號碼",
      dataIndex: "hkid",
    },
    {
      title: "電話",
      dataIndex: "telephone",
      render: (text) => <span>{text.filter(Boolean).join(", ") || "-"}</span>,
    },
    {
      title: "轉介日期",
      dataIndex: "referralDate",
      sorter: true,
      render: (text, record) => (
        <span>{record.referralInfo.referralDate || "-"}</span>
      ),
    },
    {
      title: "轉介人",
      dataIndex: "referralInfo.referrerName",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "轉介機構",
      dataIndex: "referralInfo.referralAgencyName",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "審批負責人",
      dataIndex: "personInCharge.displayName",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      dataIndex: "approvalStatus",
      render: (text, record) =>
        ({
          pending: <Tag className="tag">未處理</Tag>,
          processing: <Tag className="tag warning">審批中</Tag>,
        }[record.approvalStatus]),
    },
  ];

  const { location } = props;

  const [
    userList,
    { loading: userListLoading, error: userListError, data: userListData },
  ] = useLazyQuery(USER_LIST, {
    variables: {
      offset:
        +(
          (queryString.parse(location.search).page - 1) *
          queryString.parse(location.search).pageSize
        ) || 0,
      size: +queryString.parse(location.search).pageSize || PAGE_SIZE,
      status: queryString.parse(location.search).status || [
        "pending",
        "processing",
      ],
      sort: queryString.parse(location.search).sortField,
      referrerName: queryString.parse(location.search).referral,
      referralAgencyName: queryString.parse(location.search).referralAgency,
      reverseOrder: queryString.parse(location.search).sortOrder === "descend",
    },
  });
  const [userListNext, { data: userListNextData }] = useLazyQuery(USER_LIST, {
    variables: {
      offset:
        +(
          queryString.parse(location.search).page *
          queryString.parse(location.search).pageSize
        ) || 0,
      size: +queryString.parse(location.search).pageSize || PAGE_SIZE,
      status: queryString.parse(location.search).status || [
        "pending",
        "processing",
      ],
      sort: queryString.parse(location.search).sortField,
      referrerName: queryString.parse(location.search).referral,
      referralAgencyName: queryString.parse(location.search).referralAgency,
      reverseOrder: queryString.parse(location.search).sortOrder === "descend",
    },
  });
  const [
    userSearch,
    {
      loading: userSearchLoading,
      error: userSearchError,
      data: userSearchData,
    },
  ] = useLazyQuery(USER_SEARCH, {
    variables: {
      keyword: queryString.parse(location.search).keyword,
      offset:
        +(
          (queryString.parse(location.search).page - 1) *
          queryString.parse(location.search).pageSize
        ) || 0,
      size: +queryString.parse(location.search).pageSize || PAGE_SIZE,
    },
  });
  const [userSearchNext, { data: userSearchNextData }] = useLazyQuery(
    USER_SEARCH,
    {
      variables: {
        keyword: queryString.parse(location.search).keyword,
        offset:
          +(
            queryString.parse(location.search).page *
            queryString.parse(location.search).pageSize
          ) || 0,
        size: +queryString.parse(location.search).pageSize || PAGE_SIZE,
      },
    }
  );

  const [isFilterShow, setIsFilterShow] = useState(false);
  const [isMaxPage, setIsMaxPage] = useState(true);
  const [query, setQuery] = useState({
    referral: queryString.parse(location.search).referral,
    referralAgency: queryString.parse(location.search).referralAgency,
    status: queryString.parse(location.search).status,
  });
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let query = queryString.parse(location.search);
    history.push({
      search: `?${queryString.stringify(query)}`,
    });

    if (
      Object.prototype.hasOwnProperty.call(query, "referralAgency") ||
      Object.prototype.hasOwnProperty.call(query, "referral") ||
      Object.prototype.hasOwnProperty.call(query, "status")
    ) {
      setIsFilterShow(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (Object.prototype.hasOwnProperty.call(query, "keyword")) {
      setQuery({
        referral: undefined,
        referralAgency: undefined,
        status: undefined,
      });
      setIsFilterShow(false);
    }

    getUserList();

    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (userListData?.userList) {
      setTableData(userListData.userList);

      userListNext();
    }

    // eslint-disable-next-line
  }, [userListLoading, userListError, userListData]);

  useEffect(() => {
    if (userListNextData) {
      setIsMaxPage(!userListNextData.userList.length);
    }

    // eslint-disable-next-line
  }, [userListNextData]);

  useEffect(() => {
    if (userSearchData?.userSearch) {
      setTableData(userSearchData.userSearch);

      userSearchNext();
    }

    // eslint-disable-next-line
  }, [userSearchLoading, userSearchError, userSearchData]);

  useEffect(() => {
    if (userSearchNextData) {
      setIsMaxPage(!userSearchNextData.userSearch.length);
    }

    // eslint-disable-next-line
  }, [userSearchNextData]);

  const getUserList = () => {
    let q = queryString.parse(location.search);

    if (isEmptyObj(q)) {
      q = {
        page: 1,
        pageSize: 20,
        referral: undefined,
        referralAgency: undefined,
        status: undefined,
      };

      history.push({
        search: `?${queryString.stringify(q)}`,
      });
    } else {
      if (Object.prototype.hasOwnProperty.call(q, "keyword")) {
        if (isEmpty(q.keyword)) {
          q = {
            page: 1,
            pageSize: 20,
            referral: undefined,
            referralAgency: undefined,
            status: undefined,
          };

          history.push({
            search: `?${queryString.stringify(q)}`,
          });
        } else {
          userSearch();
        }
      } else {
        userList();
      }
    }
  };

  const onReferralAgencyChange = (val) => {
    setQuery({
      ...query,
      referralAgency: val,
    });
  };

  const onReferralChange = (evt) => {
    setQuery({
      ...query,
      referral: evt.target.value,
    });
  };

  const onStatusChange = (val) => {
    setQuery({
      ...query,
      status: val,
    });
  };

  const onResetFilter = () => {
    setQuery({
      referralAgency: undefined,
      referral: undefined,
      status: undefined,
    });
  };

  const onFilterSearch = () => {
    let q = queryString.parse(location.search);
    q.page = 1;
    delete q.keyword;
    delete q.referralAgency;
    delete q.referral;
    delete q.status;
    history.push({
      search: `?${queryString.stringify(q)}&${queryString.stringify(query)}`,
    });
  };

  const onFilterClick = (state) => {
    setIsFilterShow(state);
  };

  return (
    <TableView
      {...props}
      addBtnConfig={{
        url: PAGE.USER_CREATE,
        text: "新增個案",
      }}
      breadcrumb={BREADCRUMB}
      rowKey="userId"
      columns={columns}
      hasAddBtn
      hasFilter
      isFilterShow={isFilterShow}
      isMaxPage={isMaxPage}
      isTableLoading={userListLoading || userSearchLoading}
      onFilterClick={onFilterClick}
      searchBarPlaceholder="搜尋用戶姓名/電話"
      tableData={tableData}
    >
      <Filter
        filterList={["referralAgency", "referral", "status"]}
        isFilterShow={isFilterShow}
        onFilterSearch={onFilterSearch}
        onReferralAgencyChange={onReferralAgencyChange}
        onReferralChange={onReferralChange}
        onResetFilter={onResetFilter}
        onStatusChange={onStatusChange}
        query={query}
      />
    </TableView>
  );
}

export default DemoTablePage;

DemoTablePage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
