/** @module Utilities - Environment Config */

const appConfig = process.env;

/**
 * Check if under development environment
 * @function
 * @return {boolean}
 */
export const isDev = () =>
  appConfig.NODE_ENV ===
  "development"; /**


/**
 * Get url of the site from configuration
 * @function
 * @return {string}
 */
export const getPublicUrl = () => appConfig.PUBLIC_URL.replace(/\/$/, "");

/**
 * Get API domain from configuration
 * @function
 * @return {string}
 */
export const getApiUrl = () => appConfig.API_URL.replace(/\/$/, "");

/**
 * Get File upload url from configuration
 * @function
 * @return {string}
 */
export const getFileUploadUrl = () =>
  appConfig.FILE_UPLOAD_URL.replace(/\/$/, "");

/**
 * Get Device Inventory excel upload url from configuration
 * @function
 * @return {string}
 */
export const getDeviceInventoryUploadUrl = () =>
  appConfig.INVENTORY_DEVICE_UPLOAD_URL.replace(/\/$/, "");

/**
 * Get Part Inventory excel upload url from configuration
 * @function
 * @return {string}
 */
export const getPartInventoryUploadUrl = () =>
  appConfig.INVENTORY_PART_UPLOAD_URL.replace(/\/$/, "");

export const getBocTransactionImportUrl = () =>
  appConfig.TRANSACTION_BOC_UPLOAD_URL.replace(/\/$/, "");

export const get711TransactionImportUrl = () =>
  appConfig.TRANSACTION_711_UPLOAD_URL.replace(/\/$/, "");

export const getForgetPasswordUrl = () =>
  appConfig.FORGET_PASSWORD_URL.replace(/\/$/, "");

/**
 * Get options.js url from configuration
 * @function
 * @return {string}
 */
export const getOptionJsUrl = () => appConfig.OPTIONS_JS_URL.replace(/\/$/, "");
