import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

// Components
import {Select} from 'antd';

// Style
import './select.scss';

// Utils
import {classList} from '@utils/common';
import {isEmpty} from "@utils/validate";

function AssessmentSelectComponent(props) {
    const {
        singleChoiceAnswer,
        multipleChoiceAnswer,
        children,
        className,
        id,
        inputType,
        label,
        onChange,
        option,
    } = props;

    useEffect(() => {
        if (inputType === 'multipleChoice') {
            onChange(multipleChoiceAnswer)
        } else {
            onChange(singleChoiceAnswer);
        }

        // eslint-disable-next-line
    }, []);

    const getClassList = () => classList({
        'Assessment-Component': true,
        'AssessmentSelect-Component': true,
        [className]: true
    });

    return (
        <div className={getClassList()}>
            {label && (
                <label
                    className='AssessmentSelect-Component-label'
                    htmlFor={id}>
                    {label}
                </label>
            )}
            <Select
                id={id}
                name={id}
                className='AssessmentSelect-Component-select'
                defaultActiveFirstOption
                dropdownMatchSelectWidth={true}
                mode={inputType === 'multipleChoice' ? 'multiple' : 'default'}
                onChange={onChange}
                placeholder='請選擇'
                defaultValue={inputType === 'multipleChoice' ? isEmpty(multipleChoiceAnswer) ? [] : multipleChoiceAnswer : singleChoiceAnswer}
            >
                {option.map(o =>
                    <Select.Option
                        key={o.value}
                        className={[getClassList(), 'AssessmentSelect-Component-option']}>
                        {o.value}{!isNaN(o.score) ? ` (${o.score})` : null}
                    </Select.Option>
                )}
            </Select>
            {children}
        </div>
    );
}

export default AssessmentSelectComponent;

AssessmentSelectComponent.propTypes = {
    singleChoiceAnswer: PropTypes.string,
    multipleChoiceAnswer: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    inputType: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    option: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string
        })
    ).isRequired,
};

AssessmentSelectComponent.defalutProps = {
    singleChoiceAnswer: undefined,
    multipleChoiceAnswer: undefined,
    children: null,
    className: '',
    label: null,
};
