import React from "react";
import PropTypes from "prop-types";

import { Button, notification, Tag, Upload } from "antd";

import { getTokenHeader } from "@utils/api";
import { getFileUploadUrl } from "@utils/env";

import imageCompression from "browser-image-compression";

export const StatusTag = ({ status }) =>
  ({
    inactive: <Tag className="tag danger">{status?.name}</Tag>,
    readyForLease: <Tag className="tag success">{status?.name}</Tag>,
    reserved: <Tag className="tag warning">{status?.name}</Tag>,
    leasing: <Tag className="tag">{status?.name}</Tag>,
    cleaning: <Tag className="tag warning">{status?.name}</Tag>,
    cleaned: <Tag className="tag warning">{status?.name}</Tag>,
    repairing: <Tag className="tag warning">{status?.name}</Tag>,
    inspected: <Tag className="tag warning">{status?.name}</Tag>,
    toBeDisposed: <Tag className="tag warning">{status?.name}</Tag>,
    terminated: <Tag className="tag">{status?.name}</Tag>,
    damaged: <Tag className="tag danger">{status?.name}</Tag>,
  }[status?.id] ?? "-");

StatusTag.propTypes = {
  status: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export const CleaningStatusTag = ({ status }) =>
  ({
    pending: <Tag className="tag">待清洗</Tag>,
    cleaningFailure: <Tag className="tag danger">未能清洗</Tag>,
    completed: <Tag className="tag success">已完成</Tag>,
  }[status] ?? "-");

CleaningStatusTag.propTypes = {
  status: PropTypes.string,
};

export const ImgUploader = ({
  value = [],
  onChange = () => {},
  disabled = false,
  btnText = "上載",
  children,
  ...props
}) => {
  const checkFileType = (file) => {
    if (
      file?.type === undefined || // file is uploaded before
      ["application/pdf"].includes(file?.type) ||
      file?.type?.includes("image/")
    )
      return true;
    return false;
  };

  const handleBeforeUpload = (file) => {
    return checkFileType(file);
  };

  const handleUploadChange = (info) => {
    let fileList = [...info.fileList].reduce((acc, file) => {
      if (checkFileType(file)) {
        if (file.response?.data) {
          file.name = file.response.data.name;
          file.size = file.response.data.size;
          file.key = file.response.data?.key;
          file.url = file.response.data?.url;
        }
        acc.push(file);
      } else {
        notification.error({
          message: `不支援 ${file.name} 的檔案類型, 請上載圖片或PDF`,
        });
      }
      return acc;
    }, []);
    onChange(fileList);
  };

  const compressFile = async (file) => {
    const isImage = file.type.includes("image/");

    if (!isImage) return file;

    const newFile = await imageCompression(file, {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      fileType: "image/jpeg",
      initialQuality: 0.7,
    });

    return newFile;
  };

  return (
    <Upload
      accept="image/*,.pdf"
      headers={{
        "x-token": getTokenHeader(),
        "X-Requested-With": null,
      }}
      action={getFileUploadUrl()}
      fileList={value}
      onChange={handleUploadChange}
      beforeUpload={handleBeforeUpload}
      disabled={disabled}
      {...props}
      name="file"
      transformFile={compressFile}
    >
      {!disabled && (children ?? <Button>{btnText}</Button>)}
    </Upload>
  );
};
ImgUploader.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  btnText: PropTypes.string,
  children: PropTypes.node,
};
