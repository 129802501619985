/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { useMemo } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Link, useHistory, useLocation } from "react-router-dom";

// Constants
import { PAGE, PAGE_NAME, PAGE_SIZE, ACCESS_LEVELS } from "@utils/constants";

// Layouts
import TableView from "@layouts/tableView/tableView";

// Styles
// import "./table.scss";

// Utils
import { replaceUrlByParams } from "@utils/api";
import { Button, Modal, notification, Tag } from "antd";
import Filter from "../../filter/filter";
import { useAccessLevel } from "@utils/hook";

const DEVICE_LIST = gql`
  query deviceList(
    $size: Int!
    $offset: Int!
    $keyword: String
    $categoryId: [String]
    $brand: [String]
    $dimension: [String]
    $characteristicTag: [String]
    $reverseOrder: Boolean
    $sort: SortProductList
  ) {
    deviceList(
      size: $size
      offset: $offset
      keyword: $keyword
      categoryId: $categoryId
      brand: $brand
      dimension: $dimension
      characteristicTag: $characteristicTag
      reverseOrder: $reverseOrder
      sort: $sort
    ) {
      id
      referenceId
      productInfo {
        modelNumber
        nameChi
      }
      quantity
      leasableQuantity
      category {
        id
        name
        mainCategory {
          id
          name
        }
        minInventory
      }
    }
  }
`;

const DEVICE_COUNT = gql`
  query deviceCount(
    $categoryId: [String]
    $brand: [String]
    $dimension: [String]
    $characteristicTag: [String]
    $keyword: String
  ) {
    deviceCount(
      categoryId: $categoryId
      brand: $brand
      dimension: $dimension
      characteristicTag: $characteristicTag
      keyword: $keyword
    )
  }
`;

const DEVICE_DELETE = gql`
  mutation deviceDelete($id: ID) {
    deviceDelete(id: $id)
  }
`;

function DeviceListPage(props) {
  const history = useHistory();
  const { location } = props;

  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.CASE_MANAGEMENT,
  ]);

  const BREADCRUMB = [
    {
      path: PAGE.STOCK_DEVICE,
      name: PAGE_NAME.STOCK_DEVICE,
    },
  ];

  const [deleteDeviceParams, setDeleteDeviceParams] = useState({
    visible: false,
  });

  const columns = [
    {
      title: "款號",
      width: "10%",
      dataIndex: "referenceId",
      sorter: true,
      render: (text, record) => (
        <Link
          className="link"
          to={replaceUrlByParams(PAGE.STOCK_DEVICE_DETAIL, { id: record.id })}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "型號",
      width: "10%",
      dataIndex: "productInfo.modelNumber",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "名稱",
      dataIndex: "productInfo.nameChi",
    },
    {
      title: "分類",
      dataIndex: "category.mainCategory.name",
    },
    {
      title: "子類別",
      width: "15%",
      dataIndex: "category",
      render: (category) => {
        return (
          <Link
            className="link"
            onClick={(e) => {
              e.preventDefault();
              history.push({
                search: queryString.stringify({
                  page: 1,
                  pageSize: PAGE_SIZE,
                  categoryId: category.id,
                }),
              });
            }}
          >
            {category.name}
          </Link>
        );
      },
    },
    {
      title: "存貨數量",
      dataIndex: "leasableQuantity",
    },
    {
      title: "最低存貨量",
      dataIndex: "category.minInventory",
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record) =>
        record.leasableQuantity < record.category.minInventory && (
          <Tag className="tag warning">補貨</Tag>
        ),
    },
    {
      title: "",
      render: (record) => {
        return (
          !isDenied && (
            <Button
              type="danger"
              onClick={() => setDeleteDeviceParams({ visible: true, record })}
            >
              刪除
            </Button>
          )
        );
      },
    },
  ];

  const [
    getDeviceList,
    {
      loading: deviceListLoading,
      error: deviceListError,
      data: deviceListData,
    },
  ] = useLazyQuery(DEVICE_LIST);
  const [
    getDeviceCount,
    { loading: deviceCountLoading, data: deviceCountData },
  ] = useLazyQuery(DEVICE_COUNT);

  const [deviceDelete] = useMutation(DEVICE_DELETE);

  const [isFilterShow, setIsFilterShow] = useState(false);

  // fetch PAGE_SIZE + 1, to check if there is next page.
  const deviceList = useMemo(() => {
    return (deviceListData?.deviceList || []).slice(0, PAGE_SIZE);
  }, [deviceListData]);
  const isMaxPage = useMemo(() => {
    const queryParams = queryString.parse(location.search);
    return queryParams.pageSize >= (deviceListData?.deviceList || []).length;
  }, [deviceListData, location.search]);

  console.log("deviceList", deviceList);

  const [query, setQuery] = useState(queryString.parse(location.search));
  const [filterKeyword, setFilterKeyword] = useState(undefined);

  useEffect(() => {
    setQuery(queryString.parse(location.search));
  }, [location.search]);

  const fetchList = useCallback(() => {
    const {
      page,
      pageSize,
      keyword,
      sortField,
      categoryId,
      brand,
      dimension,
      characteristicTag,
      sortOrder,
    } = queryString.parse(location.search);

    const queryParamToArray = (x) => (x ? (!Array.isArray(x) ? [x] : x) : []);

    getDeviceList({
      variables: {
        offset: +((page - 1) * pageSize) || 0,
        size: +(pageSize || PAGE_SIZE) + 1,
        keyword: keyword,
        sort: sortField,
        categoryId: categoryId,
        brand: queryParamToArray(brand),
        dimension: queryParamToArray(dimension),
        characteristicTag: queryParamToArray(characteristicTag),
        reverseOrder: sortOrder ? sortOrder === "descend" : undefined,
      },
    });

    getDeviceCount({
      variables: {
        keyword: keyword,
        categoryId: categoryId,
        brand: queryParamToArray(brand),
        dimension: queryParamToArray(dimension),
        characteristicTag: queryParamToArray(characteristicTag),
      },
    });
  }, [getDeviceCount, getDeviceList, location.search]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const onFilterSearch = () => {
    history.push({
      search: queryString.stringify(query),
    });
  };

  const onFilterClick = (state) => {
    setIsFilterShow(state);
  };

  const onDeleteDevice = async (record) => {
    try {
      const { data } = await deviceDelete({ variables: { id: record?.id } });
      if (data.deviceDelete) {
        notification.success({
          message: `成功刪除${record?.referenceId}`,
          duration: 5,
        });
        fetchList();
      } else {
        notification.error({
          message: `刪除${record?.referenceId}失敗`,
          duration: 5,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        title={`刪除${BREADCRUMB[0].name}`}
        visible={deleteDeviceParams.visible}
        onOk={() => {
          onDeleteDevice(deleteDeviceParams?.record);
          setDeleteDeviceParams((_) => ({ ..._, visible: false }));
        }}
        onCancel={() =>
          setDeleteDeviceParams((_) => ({ ..._, visible: false }))
        }
        okText="確定"
        cancelText="取消"
        okButtonProps={{ type: "danger" }}
      >
        <div>
          確定{`刪除${BREADCRUMB[0].name}`}{" "}
          {deleteDeviceParams?.record?.referenceId} ?
        </div>
        <div>所有有關存貨亦會被刪除。</div>
      </Modal>
      <TableView
        {...props}
        addBtnConfig={{
          url: PAGE.STOCK_DEVICE_CREATE,
          text: "新增器材",
        }}
        breadcrumb={BREADCRUMB}
        rowKey="id"
        columns={columns}
        {...(!isDenied && { hasAddBtn: true })}
        hasFilter
        isFilterShow={isFilterShow}
        isMaxPage={isMaxPage}
        isTableLoading={deviceListLoading}
        onFilterClick={onFilterClick}
        searchBarPlaceholder="搜尋器材"
        tableData={deviceList}
        total={deviceCountData?.deviceCount}
      >
        <Filter
          filterType={[""]}
          filterValue={query}
          onFilterSearch={onFilterSearch}
          onResetFilter={() => {
            history.push({
              search: queryString.stringify({ page: 1, pageSize: PAGE_SIZE }),
            });
          }}
          isFilterShow={isFilterShow}
          onFilterValueChange={setQuery}
        />
      </TableView>
    </>
  );
}

export default DeviceListPage;

DeviceListPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
