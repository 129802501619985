import React from "react";
import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";

// Components
import { Button, Typography, Select, Row, Col } from "antd";

// Styles
import "./filter.scss";

// Utils

import CategoryFilter from "@components/categoryFilter/categoryFilter";

const API = gql`
  query {
    characteristicTagList(type: ["device", "part", "consumable"])
    brandList(type: ["device", "part", "consumable"])
    dimensionList(type: ["device", "part", "consumable"])
    mainCategoryList {
      id
      name
      atCategory {
        id
        name
      }
      category {
        id
        name
      }
    }
    storeLocationList
    warehouseList {
      id
      name
      storeLocationCode
    }
  }
`;

function Filter({
  filterList,
  onFilterSearch,
  onResetFilter,
  isFilterShow,
  filterValue,
  onFilterValueChange,
}) {
  const { data } = useQuery(API);

  return (
    <div className={`Filter ${isFilterShow ? "show" : "hide"}`}>
      <div className="filter-container">
        <div className="options-container">
          <Typography>篩選條件</Typography>
          <div className="options">
            <Row gutter={[16, 0]} className="container">
              {filterList.includes("categoryId") && (
                <Col className="box">
                  <div className="option">
                    <Typography>分類</Typography>
                    <CategoryFilter
                      value={filterValue.categoryId}
                      onChange={(categoryId) =>
                        onFilterValueChange((filterValue) => {
                          return {
                            ...filterValue,
                            categoryId,
                          };
                        })
                      }
                    />
                  </div>
                </Col>
              )}
              {filterList.includes("brand") && (
                <Col className="box">
                  <div className="option">
                    <Typography>品牌</Typography>
                    <Select
                      showSearch
                      mode="tags"
                      value={filterValue.brand}
                      onChange={(brand) =>
                        onFilterValueChange((filterValue) => {
                          return {
                            ...filterValue,
                            brand,
                          };
                        })
                      }
                    >
                      {(data?.brandList ?? []).map((brand) => (
                        <Select.Option value={brand} key={brand}>
                          {brand}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              )}
              {filterList.includes("dimension") && (
                <Col className="box">
                  <div className="option">
                    <Typography>尺寸</Typography>
                    <Select
                      showSearch
                      mode="tags"
                      value={filterValue.dimension}
                      onChange={(dimension) =>
                        onFilterValueChange((filterValue) => {
                          return {
                            ...filterValue,
                            dimension,
                          };
                        })
                      }
                    >
                      {(data?.dimensionList ?? []).map((dimension) => (
                        <Select.Option value={dimension} key={dimension}>
                          {dimension}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              )}
              {filterList.includes("characteristicTag") && (
                <Col className="box">
                  <div className="option">
                    <Typography>產品特點</Typography>
                    <Select
                      multiple={true}
                      showSearch
                      mode="tags"
                      value={filterValue.characteristicTag}
                      onChange={(characteristicTag) =>
                        onFilterValueChange((filterValue) => {
                          return {
                            ...filterValue,
                            characteristicTag,
                          };
                        })
                      }
                    >
                      {(data?.characteristicTagList ?? []).map(
                        (characteristicTag) => (
                          <Select.Option
                            value={characteristicTag}
                            key={characteristicTag}
                          >
                            {characteristicTag}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </div>
                </Col>
              )}
              {filterList.includes("inventoryStatus") && (
                <Col className="box">
                  <div className="option">
                    <Typography>狀態</Typography>
                    <Select
                      showSearch
                      mode="tags"
                      value={filterValue.inventoryStatus}
                      onChange={(inventoryStatus) =>
                        onFilterValueChange((filterValue) => {
                          return {
                            ...filterValue,
                            inventoryStatus,
                          };
                        })
                      }
                    >
                      <Select.Option value="inactive">尚未上架</Select.Option>
                      <Select.Option value="reserved">已預留</Select.Option>
                      <Select.Option value="readyForLease">
                        可租用
                      </Select.Option>
                      <Select.Option value="leasing">已租用</Select.Option>
                      <Select.Option value="cleaning">清潔中</Select.Option>
                      <Select.Option value="cleaned">已清潔</Select.Option>
                      <Select.Option value="repairing">
                        檢查/維修中
                      </Select.Option>
                      <Select.Option value="inspected">
                        已檢查/維修
                      </Select.Option>
                      <Select.Option value="toBeDisposed">落架中</Select.Option>
                      <Select.Option value="terminated">已落架</Select.Option>
                      <Select.Option value="damaged">已損毀</Select.Option>
                    </Select>
                  </div>
                </Col>
              )}
              {filterList.includes("storeLocation") && (
                <Col className="box">
                  <div className="option">
                    <Typography>工場</Typography>
                    <Select
                      multiple={true}
                      showSearch
                      mode="tags"
                      value={filterValue.storeLocation}
                      onChange={(storeLocation) =>
                        onFilterValueChange((filterValue) => {
                          return {
                            ...filterValue,
                            storeLocation,
                          };
                        })
                      }
                    >
                      {(data?.storeLocationList ?? []).map((storeLocation) => (
                        <Select.Option
                          value={storeLocation}
                          key={storeLocation}
                        >
                          {
                            data.warehouseList.find(
                              (warehouse) =>
                                warehouse.storeLocationCode === storeLocation
                            )?.name
                          }
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </div>
        <div className="btn-container">
          <Button className="btn btn-secondary" onClick={onResetFilter}>
            清除設定
          </Button>
          <Button className="btn" type="primary" onClick={onFilterSearch}>
            搜尋
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Filter;

Filter.propTypes = {
  filterList: PropTypes.arrayOf(PropTypes.string),
  filterTypes: PropTypes.arrayOf(PropTypes.string),
  filterValue: PropTypes.shape({
    categoryId: PropTypes.string,
    brand: PropTypes.arrayOf(PropTypes.string),
    dimension: PropTypes.arrayOf(PropTypes.string),
    characteristicTag: PropTypes.arrayOf(PropTypes.string),
    inventoryStatus: PropTypes.arrayOf(PropTypes.string),
    storeLocation: PropTypes.arrayOf(PropTypes.string),
  }),
  onFilterValueChange: PropTypes.func.isRequired,
  isFilterShow: PropTypes.bool.isRequired,
  onFilterSearch: PropTypes.func.isRequired,
  onResetFilter: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  filterList: [
    "categoryId",
    "brand",
    "dimension",
    "characteristicTag",
    "storeLocation",
  ],
};
