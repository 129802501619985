const initState = {
    isLoading: false,
    isLoggedIn: false,
    user: {
        tbId: '',
        displayName: '',
        username: '',
    }
};

export default (state = initState, action) => {
    switch (action.type) {
    case 'SET_ISLOADING_ACTION':
        return {
            ...state,
            isLoading: action.isLoading
        };
    case 'SET_ISLOGGEDIN_ACTION':
        return {
            ...state,
            isLoggedIn: action.isLoggedIn
        };
    case 'SET_USER_ACTION':
        return {
            ...state,
            user: action.user ? action.user : initState.user
        };
    default:
        return state;
    }
}
