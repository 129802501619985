/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import {
  DatePicker,
  Button,
  Table,
  Modal,
  notification,
  Select,
  Form,
  Drawer,
  Divider,
  Row,
  Col,
} from "antd";
import { gql } from "@apollo/client";
import moment from "moment";
import Axios from "axios";
import PropTypes from "prop-types";

// Constants
import { PAGE, PAGE_NAME } from "@utils/constants";
import REPORT_JSON from "./reportsList.json";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

// Styles
import "./reports.scss";
import { useMutation, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

const downloadFile = async (file) => {
  if (file?.name)
    await Axios({
      url: file.url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", file.name);
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    });
  else window.open(file?.url);
};

const REPORT_DOWNLOAD = gql`
  mutation reportDownload($report: Report, $json: String) {
    reportDownload(report: $report, json: $json) {
      key
      url
      name
      size
    }
  }
`;

const ENUM_ORDERING_STATUS = gql`
  query enumOrderingStatusList {
    enumOrderingStatusList {
      id
      name
    }
  }
`;

const ASSESSMENT_LIST = gql`
  query assessmentList {
    assessmentList {
      id
      title
    }
  }
`;

const CleaningReportDownloadDrawer = ({
  reportDownloadParams,
  onOk,
  onCancel,
  orderingStatusEnum,
  assessmentList,
}) => {
  const downloadQueue = useMemo(
    () => reportDownloadParams?.reports?.map(({ key }) => key) ?? [],
    [reportDownloadParams]
  );

  const downloadQueueGroupByField = useMemo(
    () =>
      reportDownloadParams?.reports?.reduce(
        (acc, report) => {
          const result = { ...acc };
          if (
            report.fields.includes("startDate") &&
            report.fields.includes("endDate")
          )
            result["dateRange"] = [...result["dateRange"], report];

          if (report.fields.includes("assessmentId"))
            result["assessmentId"] = [...result["assessmentId"], report];

          if (report.fields.includes("status"))
            result["status"] = [...result["status"], report];

          if (report.fields.length === 0)
            result["empty"] = [...result["empty"], report];

          return result;
        },
        {
          dateRange: [],
          assessmentId: [],
          status: [],
          empty: [],
        }
      ),
    [reportDownloadParams]
  );

  const { control, handleSubmit } = useForm();

  const [getReportFile, { loading: getReportFileLoading }] = useMutation(
    REPORT_DOWNLOAD,
    {
      onCompleted: (data) => {
        downloadFile(data?.reportDownload);
      },
      onError: (err) => {
        notification.error({
          message: "報告下載失敗",
          description: err?.message,
          duration: 0,
        });
      },
    }
  );

  const onStartDownload = useCallback(
    async (data) => {
      const json = JSON.stringify({
        startDate: data?.["dateRange"]?.[0]?.startOf("date").valueOf(),
        endDate: data?.["dateRange"]?.[1]?.endOf("date").valueOf(),
        status: data?.status || undefined,
        assessmentId: data?.assessmentId || undefined,
      });
      for await (let report of downloadQueue)
        await getReportFile({
          variables: {
            report,
            json,
          },
        });
    },
    [downloadQueue, getReportFile]
  );

  const columnName = {
    title: "報告名稱",
    dataIndex: "name",
    key: "name",
  };

  const columndownloadType = {
    title: "類型",
    dataIndex: "downloadType",
    key: "downloadType",
  };

  const renderParams = () => {
    return Object.entries(downloadQueueGroupByField)?.map(
      ([fieldType, reports]) => {
        if (reports.length === 0) return null;
        switch (fieldType) {
          case "dateRange":
            return (
              <div key="dateRange">
                <Form.Item label="報告日期">
                  <Controller
                    as={
                      <DatePicker.RangePicker
                        allowClear={false}
                        placeholder={["開始日", "結束日"]}
                      />
                    }
                    control={control}
                    defaultValue={[moment().subtract("1", "day"), moment()]}
                    name="dateRange"
                  />
                </Form.Item>
                <Table
                  title={() => `使用於以下 ${reports.length} 份報告`}
                  size="small"
                  columns={[columnName, columndownloadType]}
                  dataSource={reports}
                  pagination={false}
                />
                <Divider />
              </div>
            );
          case "status":
            return (
              <div key="status">
                <Form.Item label="最新訂單狀態">
                  <Controller
                    as={
                      <Select style={{ width: "50%" }}>
                        {orderingStatusEnum?.map(({ id, name }) => (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    control={control}
                    defaultValue={orderingStatusEnum[0].id}
                    name="status"
                  />
                </Form.Item>
                <Table
                  title={() => `使用於以下 ${reports.length} 份報告`}
                  size="small"
                  columns={[columnName]}
                  dataSource={reports}
                  pagination={false}
                />
                <Divider />
              </div>
            );
          case "assessmentId":
            return (
              <div key="assessmentId">
                <Form.Item label="評估名稱">
                  <Controller
                    as={
                      <Select style={{ width: "50%" }}>
                        {assessmentList?.map(({ id, title }) => (
                          <Select.Option key={id} value={id}>
                            {title}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    control={control}
                    defaultValue={assessmentList[0].id}
                    name="assessmentId"
                  />
                </Form.Item>
                <Table
                  title={() => `使用於以下 ${reports.length} 份報告`}
                  size="small"
                  columns={[columnName]}
                  dataSource={reports}
                  pagination={false}
                />
                <Divider />
              </div>
            );
          case "empty":
            return (
              <div key="empty">
                <Form.Item label="無需填寫">
                  <Table
                    title={() => `以下 ${reports.length} 份報告`}
                    size="small"
                    columns={[columnName]}
                    dataSource={reports}
                    pagination={false}
                  />
                </Form.Item>
                <Divider />
              </div>
            );
          default:
            return undefined;
        }
      }
    );
  };

  useEffect(() => {
    if (
      downloadQueue &&
      [
        ...downloadQueueGroupByField.dateRange,
        ...downloadQueueGroupByField.assessmentId,
        ...downloadQueueGroupByField.status,
      ].length === 0
    ) {
      onStartDownload({});
      onOk();
    }
  }, [downloadQueue, downloadQueueGroupByField, onOk, onStartDownload]);

  return (
    <Drawer
      width="50%"
      placement="right"
      title={`下載報告 (${downloadQueue.length})`}
      visible={reportDownloadParams?.visible}
      onClose={() => !getReportFileLoading && onCancel()}
    >
      <Form>{renderParams().map((field) => field)}</Form>
      <div className="btn-container">
        <Row type="flex" gutter={12}>
          <Col>
            <Button
              disabled={getReportFileLoading}
              className="btn btn-secondary"
              onClick={() => !getReportFileLoading && onCancel()}
            >
              返回
            </Button>
          </Col>
          <Col>
            <Button
              loading={getReportFileLoading}
              className="btn"
              type="primary"
              onClick={handleSubmit(onStartDownload)}
            >
              {`下載 (${downloadQueue.length})`}
            </Button>
          </Col>
        </Row>
      </div>
    </Drawer>
  );
};

CleaningReportDownloadDrawer.propTypes = {
  reportDownloadParams: PropTypes.object,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  orderingStatusEnum: PropTypes.array,
  assessmentList: PropTypes.array,
};

function CleaningReportsPage() {
  const BREADCRUMB = [
    {
      path: PAGE.THIRD_PARTY_CLEANING_REPORTS,
      name: PAGE_NAME.THIRD_PARTY_CLEANING_REPORTS,
    },
  ];

  // const reports = useMemo(
  //   () =>
  //     Object.entries(REPORT_JSON).map(([sectionKey, sectionChild]) => ({
  //       key: sectionKey,
  //       name: sectionKey,
  //       isParent: true,
  //       children: Object.entries(sectionChild).map(([reportKey, report]) => ({
  //         key: reportKey,
  //         ...report,
  //       })),
  //     })),
  //   []
  // );

  const noGroupReports = useMemo(
    () =>
      Object.entries(REPORT_JSON).reduce((acc, [sectionKey, sectionChild]) => {
        acc.push(
          ...Object.entries(sectionChild).map(([reportKey, report]) => ({
            key: reportKey,
            ...report,
          }))
        );
        return acc;
      }, []),
    []
  );

  const [selectedReports, setSelectedReports] = useState([]);

  const [reportDownloadParams, setReportDownloadParams] = useState({
    visible: false,
  });

  const {
    data: orderingStatusEnumData,
    loading: orderingStatusEnumLoading,
  } = useQuery(ENUM_ORDERING_STATUS);

  const { data: assessmentListData, loading: assessmentListLoading } = useQuery(
    ASSESSMENT_LIST
  );

  const [getReportURL, { loading: reportDownloadLoading }] = useMutation(
    REPORT_DOWNLOAD,
    {
      onCompleted: (data) => {
        downloadFile(data?.reportDownload);
      },
    }
  );

  const columns = useMemo(
    () => [
      {
        title: "報告名稱",
        dataIndex: "name",
        key: "name",
        width: "70%",
      },
      {
        title: "類型",
        dataIndex: "downloadType",
        key: "downloadType",
        width: "30%",
      },
      {
        title: (
          <Button
            size="small"
            type="primary"
            disabled={selectedReports?.length === 0}
            onClick={() =>
              setReportDownloadParams({
                visible: true,
                reports: [...selectedReports],
              })
            }
          >
            下載所有已選擇報告
          </Button>
        ),
        dataIndex: "action",
        key: "action",
        align: "right",
        render: (_, report) =>
          report?.isParent ? null : (
            <Button
              disabled={report?.disabled}
              onClick={() =>
                report?.fields.length > 0
                  ? setReportDownloadParams({
                      visible: true,
                      reports: [report],
                    })
                  : getReportURL({
                      variables: { report: report.key, json: {} },
                    })
              }
            >
              下載
            </Button>
          ),
      },
    ],
    [getReportURL, selectedReports]
  );

  const isLoading =
    reportDownloadLoading || orderingStatusEnumLoading || assessmentListLoading;

  return (
    <StickyBreadcrumbPageComponent
      className="Reports-page"
      breadcrumb={BREADCRUMB}
    >
      <div className="content-inner">
        <div className="reports-container">
          <Table
            loading={isLoading}
            rowSelection={{
              onChange: (keys, rows) => {
                setSelectedReports(rows);
              },
              getCheckboxProps: (record) => ({
                ...(record?.isParent && {
                  disabled: true,
                  style: { display: "none" },
                }),
              }),
            }}
            dataSource={noGroupReports}
            columns={columns}
            rowKey="name"
            pagination={false}
            defaultExpandAllRows={true}
            scroll={{x: "max-content"}}
          />
          {reportDownloadParams?.visible && (
            <CleaningReportDownloadDrawer
              reportDownloadParams={reportDownloadParams}
              onOk={() => setReportDownloadParams({ visible: false })}
              onCancel={() => setReportDownloadParams({ visible: false })}
              orderingStatusEnum={
                orderingStatusEnumData?.enumOrderingStatusList
              }
              assessmentList={assessmentListData?.assessmentList}
            />
          )}
        </div>
      </div>
    </StickyBreadcrumbPageComponent>
  );
}

export default CleaningReportsPage;
