/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

// Constants
import { ACCESS_LEVELS } from "@utils/constants";

// Layouts
import StockServicePriceCreate from "./servicePriceCreate";

// Styles
import "./servicePriceList.scss";

// Utils
import { Button, Col, message, Row, Table, Tag } from "antd";
import { MOMENT_FORMAT } from "@utils/constants";
import { pricesList, amountDisplay } from "@utils/common";
import moment from "moment";
import { useAccessLevel } from "@utils/hook";

const SERVICE_GET = gql`
  query ServiceGet($id: ID!) {
    serviceGet(id: $id) {
      id
      nameEng
      nameChi
      status {
        id
        name
      }
      itemPrice {
        id
        referenceId
        activeDate
        lastModifiedDate
        purchasePrice
        responsible {
          tbId
          displayName
        }
      }
    }
  }
`;

const PRICE_DELETE_ON_SERVICE = gql`
  mutation PriceDeleteOnService($id: ID, $itemPriceId: ID) {
    priceDeleteOnService(id: $id, itemPriceId: $itemPriceId)
  }
`;

function StockServicePriceList(props) {
  const { id } = useParams();

  const { isMatched: addPriceIsDenied } = useAccessLevel([
    ACCESS_LEVELS.ADMINISTRATION,
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.CASE_MANAGEMENT,
    ACCESS_LEVELS.OPERATION_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.CLERICAL_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.ADMIN_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.SERVICE_TEAM,
  ]);

  const { isMatched: addPriceIsAllowed } = useAccessLevel([
    ACCESS_LEVELS.SUPERADMIN_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.FINANCIAL_MANAGEMENT,
  ]);

  const columns = [
    {
      title: "價格（港幣）",
      dataIndex: "purchasePrice",
      render: (amount) => <div className="text">${amountDisplay(amount)}</div>,
    },
    {
      title: "更新員工",
      dataIndex: "responsible",
      render: (staff) => <div className="text">{staff.displayName}</div>,
    },
    {
      title: "更改日期",
      dataIndex: "lastModifiedDate",
      render: (timestamp) => (
        <div className="text">{moment(timestamp).format(MOMENT_FORMAT)}</div>
      ),
    },
    {
      title: "生效日期",
      dataIndex: "activeDate",
      render: (timestamp) => (
        <div className="text">{moment(timestamp).format(MOMENT_FORMAT)}</div>
      ),
    },
    {
      title: "狀態",
      dataIndex: "status",
      render: (status) => {
        switch (status) {
          case "inactive":
            return <Tag className="tag warning">已失效</Tag>;
          case "active":
            return <Tag className="tag success">生效中</Tag>;
          case "pending":
            return <Tag className="tag info">待生效</Tag>;
          default:
        }
      },
    },
    {
      title: "",
      render: (price) => {
        if (
          (addPriceIsAllowed || !addPriceIsDenied) &&
          price.status === "pending"
        ) {
          return (
            <Button
              onClick={() => deleteItemPrice(price)}
              icon="delete"
            ></Button>
          );
        }
      },
    },
  ];

  const [getService, { loading, error, data }] = useLazyQuery(SERVICE_GET);

  const [
    deletePrice,
    {
      loading: deletePriceLoading,
      error: deletePriceError,
      data: deletePriceData,
    },
  ] = useMutation(PRICE_DELETE_ON_SERVICE);

  const [createPriceParams, setCreatePriceParams] = useState({
    visible: false,
  });

  const prices = useMemo(() => {
    return pricesList(data?.serviceGet?.itemPrice ?? []);
  }, [data]);

  useEffect(() => {
    if (id) {
      getService({ variables: { id } });
    }
  }, [getService, id]);

  const deleteItemPrice = useCallback(
    async (value) => {
      try {
        if (id) {
          await deletePrice({
            variables: {
              id: id,
              itemPriceId: value.id,
            },
          });
          message.success("成功刪除價格");
          getService({ variables: { id } });
        }
      } catch (error) {
        message.error(error.message);
      }
    },
    [id, deletePrice, getService]
  );

  const addButtonClick = () => {
    if (id) {
      setCreatePriceParams({
        visible: true,
        onClose: () => setCreatePriceParams({ visible: false }),
      });
    } else {
      message.error("請先填寫基本資料和儲存");
    }
  };

  const onPriceCreateCallback = (value) => {
    if (value) {
      setCreatePriceParams({
        visible: false,
      });
      getService({ variables: { id } });
    }
  };

  return (
    <div className="StockServicePriceList">
      <Col className="content-inner">
        <Row>
          {(addPriceIsAllowed || !addPriceIsDenied) && (
            <Button className="btn btn-secondary" onClick={addButtonClick}>
              新增
            </Button>
          )}
        </Row>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={prices}
          pagination={false}
          scroll={{x:"max-content"}}
        ></Table>
        {createPriceParams.visible && (
          <StockServicePriceCreate
            {...createPriceParams}
            onPriceCreateCallback={onPriceCreateCallback}
          />
        )}
      </Col>
    </div>
  );
}

export default StockServicePriceList;
