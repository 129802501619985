/** @module Utilities - Common */
import { MOMENT_DATETIME_FORMAT } from "@utils/constants";
import { isNull } from "./validate";
import moment from "moment";
import Axios from "axios";
import { notification } from "antd";

/**
 * Helper function for construct classes in JSX
 * @function
 * @param {Object} classes - Format: { [classname]: [shouldShow] }
 * @return {string} - classes string
 */

export const classList = (classes) => {
  if (isNull(classes)) {
    return "";
  }
  return Object.keys(classes)
    .filter(
      (classKey) =>
        classes[classKey] && classKey !== "undefined" && classKey !== "null"
    )
    .join(" ");
};

/**
 * Helper function to convent number to chinese character (support up to 2 digits only)
 * @function
 * @param {number} number
 * @return {string} - chinese character number
 */
export const toChineseNum = (number) => {
  if (number >= 100 || number <= 0) {
    throw Error("Only support 1 - 99");
  }

  if (isNull(number)) {
    return "";
  }

  const digits = ["", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  const positions = ["", "十", "百", "千"];

  const numberStr = number.toString();
  return numberStr.split("").reduce((acc, curr, index) => {
    if (parseInt(curr)) {
      if (
        parseInt(curr) === 1 &&
        positions.slice(0, numberStr.length).reverse()[index] === "十"
      ) {
        acc += positions.slice(0, numberStr.length).reverse()[index];
      } else {
        acc +=
          digits[parseInt(curr)] +
          positions.slice(0, numberStr.length).reverse()[index];
      }
    }
    return acc;
  }, "");
};

export const getSafe = (fn, defaultVal) => {
  try {
    if (fn() === undefined) return defaultVal;
    return fn();
  } catch (e) {
    return defaultVal;
  }
};

export const hideHkid = (hkid) => {
  if (hkid) {
    return hkid.substring(0, 3) + "****" + hkid.substring(6);
  }
};

export const duration = (fromDate, toDate) => {
  const start = moment(fromDate);
  const end = moment(toDate);
  return moment.duration(end.diff(start));
};

export const getAmount = (amount) => {
  return (amount / 100).toFixed(2);
};

export const amountDisplay = (amount) => {
  return (amount / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const pricesList = (list) => {
  const prices = list
    .sort((a, b) => (a.activeDate > b.activeDate ? -1 : 1))
    .map((price, index, arr) => {
      if (moment().isBefore(moment(price.activeDate, "YYYY-MM-DD"))) {
        return { ...price, status: "pending" };
      } else {
        if (
          index > 0 &&
          moment().isBefore(moment(arr[index - 1].activeDate, "YYYY-MM-DD"))
        ) {
          return { ...price, status: "active" };
        } else {
          return { ...price, status: "inactive" };
        }
      }
    });
  const nPrices = prices.filter((price) => price.status === "active");
  if (nPrices.length > 0) {
    return prices;
  } else {
    let price = prices.find((price) => price.status === "inactive");
    if (price) price.status = "active";
    return prices;
  }
};

export const traceFormattedDate = (date) => {
  const diff = duration(date, new Date());
  if (diff.days() < 1) {
    return moment(date).fromNow();
  } else {
    return moment(date).format(MOMENT_DATETIME_FORMAT);
  }
};

export const _uuid = () => {
  var d = Date.now();
  if (
    typeof performance !== "undefined" &&
    typeof performance.now === "function"
  ) {
    d += performance.now(); //use high-precision timer if available
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const downloadFile = async (file) => {
  if (!file?.url) return;
  if (file?.name)
    try {
      await Axios({
        url: file.url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", file.name);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
      });
    } catch (err) {
      console.error(err);
      notification.error({
        message: "下載檔案失敗，請稍後再試",
        duration: 5,
      });
    }
  else window.open(file?.url);
};
