import React, { useEffect, useState, useCallback } from "react";
import { useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import moment from "moment";

// Constants
import { PAGE, PAGE_NAME, ACCESS_LEVELS } from "@utils/constants";

// Components
import WarehouseEdit from "../edit/warehouseEdit";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

// Styles
import "./warehouseList.scss";

// Utils
import { MOMENT_DATETIME_FORMAT } from "@utils/constants";
import { Button, Table } from "antd";
import { useAccessLevel } from "@utils/hook";

const WAREHOUSE_LIST = gql`
  query warehouseList {
    warehouseList {
      id
      name
      address
      storeLocationCode
      trace {
        createDate
      }
    }
  }
`;

function WarehouseListPage() {
  const BREADCRUMB = [
    {
      path: PAGE.WORKSHOP_WAREHOUSE,
      name: PAGE_NAME.WORKSHOP_WAREHOUSE,
    },
  ];

  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.CLERICAL_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.WORKSHOP_MANAGEMENT,
  ]);

  const [editDrawerParam, setEditDrawerParam] = useState({
    id: undefined,
    name: undefined,
    address: undefined,
    storeLocationCode: undefined,
    visible: false,
  });

  const [
    getWarehouseList,
    { loading: getWarehouseListLoading, data: getWarehouseListData },
  ] = useLazyQuery(WAREHOUSE_LIST);

  const columns = [
    {
      title: "工場名稱",
      dataIndex: "name",
      render: (text, record) =>
        !isDenied ? (
          <Button type="link" onClick={() => openEditDrawer(record)}>
            {text}
          </Button>
        ) : (
          text
        ),
    },
    {
      title: "工場地址",
      dataIndex: "address",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "工場編號",
      dataIndex: "storeLocationCode",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "新增日期",
      dataIndex: "trace.createDate",
      render: (text) => (
        <span>{moment(text).format(MOMENT_DATETIME_FORMAT)}</span>
      ),
    },
  ];

  const refreshWarehouseList = useCallback(() => {
    getWarehouseList();
  }, [getWarehouseList]);

  useEffect(() => {
    refreshWarehouseList();
  }, [refreshWarehouseList]);

  const openEditDrawer = (data = {}) => {
    setEditDrawerParam({ ...data, visible: true });
  };

  const closeEditDrawer = () => {
    setEditDrawerParam({
      id: undefined,
      name: undefined,
      address: undefined,
      storeLocationCode: undefined,
      visible: false,
    });
  };

  const defaultValues = useMemo(() => {
    return {
      id: editDrawerParam?.id,
      name: editDrawerParam?.name,
      address: editDrawerParam?.address,
      storeLocationCode: editDrawerParam?.storeLocationCode,
    };
  }, [editDrawerParam]);

  return (
    <StickyBreadcrumbPageComponent breadcrumb={BREADCRUMB}>
      {editDrawerParam.visible && (
        <WarehouseEdit
          defaultValues={defaultValues}
          onClose={closeEditDrawer}
          refresh={refreshWarehouseList}
        />
      )}
      <div>
        {!isDenied && (
          <Button
            onClick={() => openEditDrawer()}
            style={{ marginBottom: "18px" }}
            className="btn btn-secondary"
          >
            新增
          </Button>
        )}
        <Table
          columns={columns}
          loading={getWarehouseListLoading}
          dataSource={getWarehouseListData?.warehouseList}
          pagination={false}
          rowKey="id"
          scroll={{x: "max-content"}}
        />
      </div>
    </StickyBreadcrumbPageComponent>
  );
}

export default WarehouseListPage;

WarehouseListPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
