/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { setIsLoadingAction, setIsLoggedInAction } from "@actions/storeAction";

// Components
// eslint-disable-next-line no-unused-vars
import {
  Col,
  Row,
  Alert,
  Input,
  Button,
  Form,
  Checkbox,
  Typography,
} from "antd";

// Images
import logo from "@images/logo.png";

// Styles
import "./login.scss";

// Utils
import { setTokenHeader } from "@utils/api";
import { isDev, getForgetPasswordUrl } from "@utils/env";
import { isEmpty } from "@utils/validate";

import queryString from "query-string";

const LOGIN = gql`
  mutation Login($username: String, $password: String, $remember: Boolean) {
    login(username: $username, password: $password, remember: $remember) {
      token
    }
  }
`;

function LoginPage() {
  const dispatch = useDispatch();
  let history = useHistory();
  const fromUrl = queryString.parse(history.location.search)?.fromUrl ?? null;
  const store = useSelector((state) => state.storeReducer);

  const [login, { loading: loginLoading, error: loginError, data: loginData }] =
    useMutation(LOGIN);
  const [isErr, setIsErr] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [credential, setCredential] = useState({
    email: isDev() ? "owner@talkboxapp.com" : undefined,
    pw: isDev() ? "123456" : undefined,
    remember: false,
  });
  const forgetPasswordUrl = getForgetPasswordUrl();

  useEffect(() => {
    const { isLoggedIn } = store;
    if (isLoggedIn) {
      history.replace(fromUrl ?? "/");
    }
  }, [fromUrl, history, store]);

  useEffect(() => {
    if (loginError) {
      dispatch(setIsLoggedInAction(false));
      setIsErr(true);
      setErrMsg("登入電郵地址或密碼不正確。");
      setIsSubmitting(false);
      dispatch(setIsLoadingAction(false));
    } else {
      if (loginData) {
        const { token } = loginData.login;
        setTokenHeader(token);
        dispatch(setIsLoggedInAction(true));
        history.replace(fromUrl ?? "/");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginLoading, loginError, loginData]);

  const onSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    if (validation()) {
      dispatch(setIsLoadingAction(true));
      await login({
        variables: {
          username: credential.email,
          password: credential.pw,
          remember: credential.remember,
        },
      });
    }
  };

  const validation = () => {
    const { email, pw } = credential;

    if (isEmpty(email) || isEmpty(pw)) {
      setIsErr(true);
      setErrMsg("必須輸入登入電郵地址。");
      setIsSubmitting(false);
      return false;
    }

    if (isEmpty(email) || isEmpty(pw)) {
      setIsErr(true);
      setErrMsg("必須輸入密碼。");
      setIsSubmitting(false);
      return false;
    }

    setIsErr(false);
    setErrMsg("");
    return true;
  };

  const onCredentialChange = (val, field) => {
    const { email, pw, remember } = credential;

    switch (field) {
      case "email":
        setCredential({
          email: val,
          pw: pw,
          remember: remember,
        });
        break;
      case "pw":
        setCredential({
          email: email,
          pw: val,
          remember: remember,
        });
        break;
      case "remember":
        setCredential({
          email: email,
          pw: pw,
          remember: val,
        });
        break;
      default:
    }
  };

  return (
    <div className="Login-page">
      <Row className="login-header">
        <Col
          xxl={22}
          lg={20}
          md={18}
          xs={24}
          style={{
            display: "flex",
            alignContent: "center",
            flexDirection: "column",
            marginBottom: "8px",
          }}
        >
          <div>
            <Typography.Text className="login-title">
              招商局「e賃務」樂齡科技租賃系統
            </Typography.Text>
          </div>
          <div>
            <Typography.Text className="login-title">
              CMF eRent - Gerontech Rental System
            </Typography.Text>
          </div>
        </Col>
        <Col
          xxl={2}
          lg={4}
          md={6}
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>捐助機構 Funded by</div>
          <div className="login-logo"></div>
        </Col>
      </Row>
      <Row>
        <Col className="login-background" />
      </Row>
      <Row>
        <Col className="login-form">
          <div className="login-form-container">
            <div className="title">
              <Typography.Title level={3} style={{ color: "#008A99" }}>
                職員登入 Staff Login
              </Typography.Title>
            </div>
            {isErr && (
              <Alert
                className="err-msg"
                message={errMsg}
                type="error"
                showIcon
              />
            )}
            <Form onSubmit={onSubmit}>
              <div>
                <Typography.Text style={{ fontSize: "16px", color: "black" }}>
                  電郵地址 Email
                </Typography.Text>
                <Input
                  style={{ color: "#008A99" }}
                  size="large"
                  placeholder="電郵地址"
                  value={credential.email}
                  onChange={(e) => onCredentialChange(e.target.value, "email")}
                  name="email"
                />
              </div>
              <div>
                <Typography.Text style={{ fontSize: "16px", color: "black" }}>
                  密碼 Password
                </Typography.Text>
                <Input
                  style={{ color: "#008A99" }}
                  size="large"
                  placeholder="密碼"
                  type="password"
                  value={credential.pw}
                  onChange={(e) => onCredentialChange(e.target.value, "pw")}
                  name="password"
                />
              </div>
              <div className="btn-container">
                <Button
                  block
                  className="login-btn"
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitting}
                >
                  登入 Login
                </Button>
              </div>
              <div>
                <Checkbox
                  checked={credential.remember}
                  onChange={(e) =>
                    onCredentialChange(e.target.checked, "remember")
                  }
                >
                  記住我的登入帳戶 Remember me
                </Checkbox>
              </div>
              <div className="forget-password">
                <a href={forgetPasswordUrl}>忘記密碼？</a>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default LoginPage;
