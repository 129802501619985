import React, { useCallback, useEffect, useMemo } from "react";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Icon,
  Input,
  Modal,
  notification,
  Select,
  Upload,
} from "antd";

import QRCode from "qrcode.react";
import { Controller, useForm } from "react-hook-form";

import { gql, useQuery, useMutation } from "@apollo/client";

import PropTypes from "prop-types";
import { useDisclosureParam } from "../../../../utils/hook";
import { ImgUploader } from "../../../../utils/commonComponents";

import { replaceUrlByParams } from "@utils/api";
import { PAGE } from "../../../../utils/constants";
import { Link, useParams } from "react-router-dom";

import { v4 as uuid } from "uuid";

const cleaningJobEquipmentComplete = gql`
  mutation cleaningJobEquipmentComplete($equipmentId: String!) {
    cleaningJobEquipmentComplete(equipmentId: $equipmentId) {
      id
    }
  }
`;

const cleaningJobEquipmentReset = gql`
  mutation cleaningJobEquipmentReset($equipmentId: String!) {
    cleaningJobEquipmentReset(equipmentId: $equipmentId) {
      id
    }
  }
`;

const cleaningJobEquipmentReject = gql`
  mutation cleaningJobEquipmentReject(
    $equipmentId: String!
    $deliveryFee: Boolean
    $remark: String!
    $image: [FileInput]
  ) {
    cleaningJobEquipmentReject(
      equipmentId: $equipmentId
      deliveryFee: $deliveryFee
      remark: $remark
      image: $image
    ) {
      id
    }
  }
`;

const cleaningJobEquipmentCleaningFlowUpdate = gql`
  mutation cleaningJobEquipmentCleaningFlowUpdate(
    $equipmentId: String!
    $cleaningFlowId: String!
  ) {
    cleaningJobEquipmentCleaningFlowUpdate(
      equipmentId: $equipmentId
      cleaningFlowId: $cleaningFlowId
    ) {
      id
    }
  }
`;

const cleaningFlowList = gql`
  query cleaningFlowList {
    cleaningFlowList(size: 9999, offset: 0) {
      id
      referenceId
      name
    }
  }
`;

const RejectDeviceCleaningModal = ({
  visible,
  onOk,
  onCancel,
  equipmentId,
}) => {
  const [rejectDeviceCleaning] = useMutation(cleaningJobEquipmentReject);

  const {
    control,
    reset,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      deliveryFee: false,
      remark: "",
      image: [],
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        await rejectDeviceCleaning({
          variables: {
            equipmentId: equipmentId,
            deliveryFee: data.deliveryFee,
            remark: data.remark,
            image: data.image.map((file) => ({
              key: file?.key,
              name: file?.name,
              size: file?.size,
            })),
          },
        });
        onOk();
        await onCancel();

        notification.success({
          message: "成功更新未能清洗狀態",
          duration: 5,
        });
      } catch (err) {
        console.error(err);
      }
    },
    [equipmentId, onCancel, onOk, rejectDeviceCleaning]
  );

  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [reset, visible]);

  return (
    <Modal
      visible={visible}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title="未能清洗詳情"
      okButtonProps={{ loading: isSubmitting }}
    >
      <Form>
        <Form.Item>
          <Controller
            control={control}
            name="deliveryFee"
            render={({ value, onChange }) => (
              <Checkbox
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              >
                需付運費
              </Checkbox>
            )}
          />
        </Form.Item>
        <Form.Item
          label="未能清洗原因／備註"
          required
          validateStatus={errors?.remark ? "error" : ""}
          help={!!errors?.remark && "請填寫未能清洗原因／備註"}
        >
          <Controller
            control={control}
            name="remark"
            rules={{ required: true }}
            render={(fields) => <Input.TextArea {...fields} />}
          />
        </Form.Item>
        <Form.Item label="上載圖片">
          <Controller
            control={control}
            name="image"
            render={(fields) => (
              <ImgUploader listType="picture-card" {...fields}>
                <div>
                  <Icon type={"plus"} />
                  <div className="ant-upload-text">上載</div>
                </div>
              </ImgUploader>
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

RejectDeviceCleaningModal.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  equipmentId: PropTypes.string,
};

const DeviceCleaningHistory = ({ equipment, isLoading, refetch }) => {
  const { id, deviceId } = useParams();

  const rejectDeviceCleaningModalDisclosure = useDisclosureParam();

  const { data: listCleaningFlowData, loading: listCleaningFlowLoading } =
    useQuery(cleaningFlowList);

  const stageUrl = `${window.location.origin}/console/#${replaceUrlByParams(
    PAGE.THIRD_PARTY_CLEANING_JOB_DEVICE_STAGE,
    {
      id,
      deviceId,
    }
  )}`;

  const [completeDeviceCleaning] = useMutation(cleaningJobEquipmentComplete);
  const [resetDeviceCleaning] = useMutation(cleaningJobEquipmentReset);
  const [updateCleaningFlow] = useMutation(
    cleaningJobEquipmentCleaningFlowUpdate
  );

  const {
    control,
    reset,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      status: undefined,
      cleaningFlowId: undefined,
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      const { status, cleaningFlowId } = data;

      try {
        if (status !== equipment?.status) {
          switch (status) {
            case "pending":
              await resetDeviceCleaning({
                variables: { equipmentId: equipment.id },
              });
              break;
            case "completed":
              await completeDeviceCleaning({
                variables: { equipmentId: equipment.id },
              });
              break;
            default:
              break;
          }
        }

        if (cleaningFlowId !== equipment?.cleanFlow?.cleaningFlow?.id) {
          await updateCleaningFlow({
            variables: {
              equipmentId: equipment.id,
              cleaningFlowId: cleaningFlowId,
            },
          });
        }

        notification.success({
          message: "成功更新器材清洗記錄",
          duration: 5,
        });

        refetch();
      } catch (err) {
        console.log(err);
      }
    },
    [
      completeDeviceCleaning,
      equipment,
      refetch,
      resetDeviceCleaning,
      updateCleaningFlow,
    ]
  );

  useEffect(() => {
    if (equipment && !isLoading) {
      reset({
        status: equipment?.status,
        cleaningFlowId: equipment?.cleanFlow?.cleaningFlow?.id,
      });
    }
  }, [equipment, isLoading, reset]);

  const failureImageList = useMemo(
    () =>
      (equipment?.failureImage || [])?.map((image) => ({
        ...image,
        uid: uuid(),
      })),
    [equipment]
  );

  return (
    <>
      <RejectDeviceCleaningModal
        visible={rejectDeviceCleaningModalDisclosure.isOpen}
        onOk={refetch}
        onCancel={rejectDeviceCleaningModalDisclosure.onClose}
        equipmentId={equipment?.id}
      />
      <Form layout="vertical">
        <div className="button-container">
          <Button
            type="danger"
            onClick={rejectDeviceCleaningModalDisclosure.onOpen}
          >
            未能清洗
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit(onSubmit)}
            loading={isSubmitting}
          >
            儲存
          </Button>
        </div>

        {equipment?.status === "cleaningFailure" && (
          <Card
            title="未能清洗記錄"
            style={{ background: "#eee", marginBottom: 16 }}
          >
            <Form.Item>
              <Checkbox checked={equipment?.deliveryFee}>需付運費</Checkbox>
            </Form.Item>
            <Form.Item label="未能清洗原因／備註" required>
              <Input.TextArea value={equipment?.failureRemark} readOnly />
            </Form.Item>
            <Form.Item label={`上載圖片 (${failureImageList?.length ?? 0})`}>
              <Upload
                disabled
                listType="picture-card"
                fileList={failureImageList}
              ></Upload>
            </Form.Item>
          </Card>
        )}

        <Form.Item
          label="清洗狀態"
          required
          validateStatus={errors?.status ? "error" : ""}
          help={!!errors?.status && "請選擇清洗狀態"}
        >
          <Controller
            control={control}
            name="status"
            rules={{ required: true }}
            render={(fields) => (
              <Select {...fields}>
                <Select.Option value="pending">待清洗</Select.Option>
                <Select.Option value="completed">已完成</Select.Option>
                <Select.Option
                  disabled={true}
                  style={{ display: "none" }}
                  value="cleaningFailure"
                >
                  未能清洗
                </Select.Option>
              </Select>
            )}
          />
        </Form.Item>

        <Form.Item
          label="清潔流程"
          required
          validateStatus={errors?.cleaningFlowId ? "error" : ""}
          help={!!errors?.cleaningFlowId && "請選擇清潔流程"}
        >
          <Controller
            control={control}
            name="cleaningFlowId"
            rules={{ required: true }}
            render={(fields) => (
              <Select {...fields} loading={listCleaningFlowLoading}>
                {listCleaningFlowData?.cleaningFlowList?.map(
                  ({ id, name }, i) => (
                    <Select.Option key={i} value={id}>
                      {name}
                    </Select.Option>
                  )
                )}
              </Select>
            )}
          />
        </Form.Item>

        {equipment?.cleanFlow?.cleaningFlow?.id && (
          <Form.Item label="掃描以下 QR Code 開始清潔流程／查看清潔流程">
            <QRCode value={stageUrl} />
            <div>
              <Link
                to={replaceUrlByParams(
                  PAGE.THIRD_PARTY_CLEANING_JOB_DEVICE_STAGE,
                  {
                    id,
                    deviceId,
                  }
                )}
                target="_blank"
              >
                或點擊這裡
              </Link>
            </div>
          </Form.Item>
        )}
      </Form>
    </>
  );
};

DeviceCleaningHistory.propTypes = {
  equipment: PropTypes.object,
  isLoading: PropTypes.bool,
  refetch: PropTypes.func,
};

export default DeviceCleaningHistory;
