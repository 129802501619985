import React, { useCallback, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// styles
import "./orderingTerminationDrawer.scss";

// Utils
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  notification,
  Select,
  Typography,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import { amountDisplay, classList } from "@utils/common";
import moment from "moment";
import { ImgUploader } from "@utils/commonComponents";

const API = gql`
  query EnumList {
    enumOrderingTerminateReasonList {
      id
      name
    }
  }
`;

const ORDERING_TERMINATE = gql`
  mutation OrderingTerminate($input: OrderingTerminateInput) {
    orderingTerminate(input: $input)
  }
`;

const ORDERING_TERMINATE_PREVIEW = gql`
  mutation OrderingTerminatePreview($input: OrderingTerminateInput) {
    orderingTerminatePreview(input: $input) {
      refundAmount
    }
  }
`;

const CreateOrderingTerminationDrawer = (props) => {
  const {
    className,
    defaultValues,
    onClose,
    onSuccess,
    orderingItemId,
    orderingItemStatusId,
    userId,
    isDisable,
    orderingTermination,
  } = props;

  const isUpdateTermination = useMemo(
    () => orderingItemStatusId === "terminated",
    [orderingItemStatusId]
  );

  const { errors, register, handleSubmit, watch, setValue } = useForm({
    defaultValues,
  });

  useEffect(() => {
    register({ name: "reason" }, { required: true });
    register({ name: "terminateDate" }, { required: true });
    register({ name: "remark" });
    register({ name: "attachment" });
  }, [register]);

  const formValue = watch();

  // const [getEnumList, { data: enumList }] = useLazyQuery(API);
  const { data: enumList } = useQuery(API);

  const [
    orderingTerminate,
    { loading: orderingTerminateLoading },
  ] = useMutation(ORDERING_TERMINATE);

  const [
    orderingTerminatePreview,
    {
      loading: orderingTerminatePreviewLoading,
      // data: orderingTerminatePreviewData,
    },
  ] = useMutation(ORDERING_TERMINATE_PREVIEW);

  useEffect(() => {
    if (orderingTermination) {
      setValue(
        "attachment",
        orderingTermination?.attachment?.map((item) => ({
          ...item,
          uid: item.key,
        }))
      );
      setValue("reason", orderingTermination.reason.id);
      setValue("terminateDate", orderingTermination.terminateDate);
      setValue("remark", orderingTermination.remark);
    }
    // else {
    //   getEnumList();
    // }
  }, [orderingTermination, setValue]);

  const onSubmit = useCallback(
    async (orderingTermination) => {
      const input = {
        ...orderingTermination,
        orderingItemId: orderingItemId,
        userId: userId,
        attachment: (orderingTermination.attachment ?? []).map(
          ({ key, name, size }) => ({
            key,
            name,
            size,
          })
        ),
      };
      const { data } = await orderingTerminatePreview({
        variables: {
          input: input,
        },
      });
      if (data?.orderingTerminatePreview) {
        Modal.confirm({
          title: `確認合約終止指定日期為 ${orderingTermination.terminateDate} ?`,
          content: `系統將自動按比例計算租金餘額並將下列餘額退回用戶的租金戶口(HKD${amountDisplay(
            data?.orderingTerminatePreview?.refundAmount ?? 0
          )})`,
          okText: "確認",
          okType: "danger",
          cancelText: "取消",
          onOk: async () => {
            try {
              await orderingTerminate({
                variables: {
                  input: input,
                },
              });
              message.success("合約終止申請已提交");
              onSuccess();
            } catch (error) {
              message.error(error.message);
            }
          },
        });
      }
      // eslint-disable-next-line
    },
    [
      orderingItemId,
      userId,
      orderingTerminatePreview,
      orderingTerminate,
      onSuccess,
    ]
  );

  const onUpdate = useCallback(
    async (orderingTermination) => {
      try {
        const input = {
          ...orderingTermination,
          orderingItemId: orderingItemId,
          userId: userId,
          attachment: (orderingTermination.attachment ?? []).map(
            ({ key, name, size }) => ({
              key,
              name,
              size,
            })
          ),
        };
        await orderingTerminate({
          variables: {
            input: input,
          },
        });
        notification.success({ message: "已更新終止租借合約", duration: 5 });
      } catch (err) {
        // console.log(err);
      }
    },
    [orderingItemId, userId, orderingTerminate]
  );

  const getClassList = () =>
    classList({
      "CreateOrderingTerminationDrawer-Component": true,
      [className]: true,
    });

  return (
    <Drawer
      className={getClassList()}
      title="終止租借合約"
      onClose={onClose}
      visible={true}
      width="50vw"
    >
      <Form size="small" layout="horizontal">
        <FormItem label="原因">
          <Select
            className={errors?.reason ? "error" : null}
            value={formValue?.reason}
            onChange={(value) => setValue("reason", value)}
            disabled={isDisable}
          >
            {enumList?.enumOrderingTerminateReasonList.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
          {errors?.reason && (
            <Typography.Text type="danger">請填寫原因</Typography.Text>
          )}
        </FormItem>
        <FormItem label="終止日期">
          <DatePicker
            style={{ width: "100%" }}
            className={errors?.terminateDate ? "error" : null}
            value={
              formValue?.terminateDate
                ? moment(formValue?.terminateDate, "YYYY-MM-DD")
                : null
            }
            onChange={(value) =>
              setValue(
                "terminateDate",
                value ? moment(value).format("YYYY-MM-DD") : null
              )
            }
            disabled={isUpdateTermination}
          />
          {errors?.terminateDate && (
            <Typography.Text type="danger">請填寫終止日期</Typography.Text>
          )}
        </FormItem>
        <FormItem label="備註">
          <Input.TextArea
            rows={10}
            value={formValue?.remark}
            onChange={({ target: { value } }) => setValue("remark", value)}
            // disabled={isDisable}
          />
        </FormItem>
        <FormItem label="簽署檔案">
          <ImgUploader
            value={formValue?.attachment}
            onChange={(data) => setValue("attachment", data)}
            btnText="上載簽署檔案"
            // disabled={isDisable}
          />
        </FormItem>
      </Form>
      <div className="btn-container">
        {isUpdateTermination ? (
          <Button
            onClick={handleSubmit(onUpdate)}
            loading={orderingTerminateLoading}
            type="primary"
          >
            更新
          </Button>
        ) : (
          <Button
            onClick={handleSubmit(onSubmit)}
            loading={orderingTerminatePreviewLoading}
            type="primary"
          >
            提交
          </Button>
        )}
      </div>
    </Drawer>
  );
};

CreateOrderingTerminationDrawer.propTypes = {
  className: PropTypes.string,
  defaultValues: PropTypes.object,
  orderingItemId: PropTypes.array,
  orderingItemStatusId: PropTypes.string,
  userId: PropTypes.string,
  onClose: PropTypes.func,
  isDisable: PropTypes.bool,
  onSuccess: PropTypes.func,
  orderingTermination: PropTypes.object,
};

CreateOrderingTerminationDrawer.defaultProps = {
  className: "",
};

export default CreateOrderingTerminationDrawer;
