/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment from "moment";
import { MOMENT_DATETIME_SECOND_FORMAT } from "@utils/constants";

// Components
import { Button, Form, DatePicker, Typography } from "antd";
import AssessmentQuestionComponent from "@components/assessment/question/question";
import AssessmentSelectComponent from "@components/assessment/select/select";
import AssessmentSectionComponent from "@components/assessment/section/section";
import AssessmentTextareaComponent from "@components/assessment/textarea/textarea";
import AssessmentUploadComponent from "@components/assessment/upload/upload";
import AssessmentCheckboxComponent from "@components/assessment/checkbox/checkbox";
import AssessmentNumberComponent from "@components/assessment/number/number";

// Style
import "./form.scss";

// Utils
import { classList, _uuid } from "@utils/common";
import { ImgUploader } from "@utils/commonComponents";
function AssessmentFormComponent({ className, data, onSubmit, onAutoSave }) {
  const { Text } = Typography;
  const { RangePicker } = DatePicker;

  const { handleSubmit, register, setValue, watch, getValues } = useForm({});
  const watchValue = watch();

  const [questions, setQuestions] = useState([]);
  // const [isUserInputted, setIsUserInputted] = useState(false);

  useEffect(() => {
    setQuestions(getAssessmentQuestions(data));
    register({ name: "assessmentStartDate" });
    register({ name: "assessmentEndDate" });
    register({ name: "attachment" });
    if (data.assessmentStartDate || data.assessmentEndDate) {
      setValue("assessmentStartDate", data.assessmentStartDate);
      setValue("assessmentEndDate", data.assessmentEndDate);
    } else {
      setValue("assessmentStartDate", moment().valueOf());
      setValue("assessmentEndDate", moment().valueOf());
    }
    if (data.attachment)
      setValue(
        "attachment",
        data?.attachment?.map(({ key, url, name, size }) => ({
          uid: _uuid(),
          key,
          url,
          name,
          size,
        }))
      );
  }, [data, register, setValue]);

  const getClassList = () =>
    classList({
      "Assessment-Component": true,
      "AssessmentForm-Component": true,
      [className]: true,
    });

  const getAssessmentQuestions = (assessment) => {
    return assessment.section.reduce((acc, s) => {
      let qs = s.question.reduce((acc, q) => {
        let inputs = q.input;
        let extraInputs = q.input.reduce((acc, i) => {
          if (
            i.inputType === "singleChoice" ||
            i.inputType === "multipleChoice"
          ) {
            let optionTextInputs = i.option.map((o) => {
              if (o.extraTextInputId) {
                return {
                  id: o.extraTextInputId,
                  inputType: "text",
                };
              }

              return null;
            });
            optionTextInputs = optionTextInputs.filter((i) => i);
            if (optionTextInputs.length) {
              return [...acc, ...optionTextInputs];
            }
          }

          return [];
        }, []);
        if (extraInputs.length) {
          inputs = [...inputs, ...extraInputs];
        }
        return [...acc, ...inputs];
      }, []);

      return [...acc, ...qs];
    }, []);
  };

  const renderFields = (inputs) => {
    let extraInput;

    return inputs.reduce((acc, curr) => {
      if (!curr.id) return null;
      const name = `input.${curr.id}`;
      register({ name });
      if (curr.answer) {
        setValue(name, curr.answer);
      }

      let field = null;
      switch (curr.inputType) {
        case "singleChoice":
        case "multipleChoice":
          extraInput = curr.option.filter((o) => o.extraTextInputId);

          if (extraInput.length) {
            extraInput.forEach((input) => {
              register({ name: `input.${input.extraTextInputId}` });

              if (input.extraTextInputAns) {
                setValue(
                  `input.${input.extraTextInputId}`,
                  input.extraTextInputAns
                );
              }
            });
          }

          field = (
            <AssessmentSelectComponent
              {...curr}
              key={name}
              onChange={(val) => setValue(name, val)}
            >
              {extraInput.length
                ? extraInput.map((input) => {
                    return (
                      <AssessmentTextareaComponent
                        key={input.extraTextInputId}
                        id={input.extraTextInputId}
                        textAnswer={input.extraTextInputAnswer}
                        onChange={(val) =>
                          setValue(`input.${input.extraTextInputId}`, val)
                        }
                        placeholder={`如選擇 ${input.value}, 請填寫適當的附加資料`}
                      />
                    );
                  })
                : null}
            </AssessmentSelectComponent>
          );

          break;
        case "number":
          field = (
            <AssessmentNumberComponent
              {...curr}
              key={name}
              onChange={(val) => setValue(name, val)}
            />
          );
          break;
        case "text":
          field = (
            <AssessmentTextareaComponent
              {...curr}
              key={name}
              onChange={(val) => setValue(name, val)}
            />
          );
          break;
        case "file":
          field = (
            <AssessmentUploadComponent
              {...curr}
              key={name}
              onChange={(val) => setValue(name, val)}
            />
          );
          break;
        case "checkbox":
          field = (
            <AssessmentCheckboxComponent
              {...curr}
              key={name}
              onChange={(val) => setValue(name, val)}
            />
          );
          break;
        default:
          field = <div key={name}>Input component</div>;
      }
      return [...acc, field];
    }, []);
  };

  const renderQuestions = (questions, sectionNum = null) => {
    return questions.reduce((acc, curr, i) => {
      const { input, title } = curr;
      const questionId =
        sectionNum !== null ? `Q ${sectionNum}-${i + 1}` : `Q ${i + 1}`;
      const currQuestion = (
        <AssessmentQuestionComponent
          className={i % 2 ? "row-odd" : "row-even"}
          key={`${i}-title`}
          questionId={questionId}
          title={title}
        >
          {renderFields(input)}
        </AssessmentQuestionComponent>
      );

      return [...acc, currQuestion];
    }, []);
  };

  const renderSections = (sections) => {
    return sections.reduce((acc, curr, i) => {
      const { question } = curr;
      const currSection = (
        <AssessmentSectionComponent key={i} {...curr}>
          {sections.length > 1
            ? renderQuestions(question, i + 1)
            : renderQuestions(question)}
        </AssessmentSectionComponent>
      );
      return [...acc, currSection];
    }, []);
  };

  const onFormSubmit = ({
    input: inputObject = {},
    assessmentStartDate,
    assessmentEndDate,
    attachment: fileList,
  }) => {
    const inputArray = Object.entries(inputObject).reduce((acc, curr) => {
      const questionMeta = questions.find((q) => q.id === curr[0]);
      if (questionMeta && questionMeta.inputType) {
        if (questionMeta?.inputType === "file") {
          curr[1] = curr[1].map((img) => {
            return { key: img.key, name: img.name, size: img.size };
          });
        }
      }
      const input = {
        inputId: curr[0],
        [questionMeta?.inputType]: curr[1],
      };

      return [...(acc ? acc : []), input];
    }, []);

    const attachment = fileList.map(({ key, name, size }) => ({
      key,
      name,
      size,
    }));

    onSubmit({
      input: inputArray,
      assessmentStartDate,
      assessmentEndDate,
      attachment,
    });
  };

  // const handleInputChange = () => {
  //   setIsUserInputted(true);
  // };

  const autoSaveAssessment = useCallback(
    (formData) => {
      const inputArray = Object.entries({ ...formData?.input }).reduce(
        (acc, curr) => {
          const questionMeta = questions.find((q) => q.id === curr[0]);
          if (questionMeta && questionMeta.inputType) {
            if (questionMeta?.inputType === "file") {
              curr[1] = curr[1].map((img) => {
                return { key: img.key, name: img.name, size: img.size };
              });
            }
          }
          const input = {
            inputId: curr[0],
            [questionMeta?.inputType]: curr[1],
          };

          return [...(acc ? acc : []), input];
        },
        []
      );

      const attachment = formData?.attachment?.map(({ key, name, size }) => ({
        key,
        name,
        size,
      }));

      const assessmentStartDate = formData?.assessmentStartDate;
      const assessmentEndDate = formData?.assessmentEndDate;

      onAutoSave({
        input: inputArray,
        assessmentStartDate,
        assessmentEndDate,
        attachment,
      });
    },
    [onAutoSave, questions]
  );

  // useEffect(() => {
  //   let interval;
  //   if (isUserInputted) {
  //     interval = setInterval(() => {
  //       autoSaveAssessment(watchValue);
  //       setIsUserInputted(false);
  //     }, 1000);
  //   }
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [autoSaveAssessment, isUserInputted, watchValue]);

  useEffect(() => {
    let interval;

    interval = setInterval(() => {
      autoSaveAssessment(getValues());
    }, 1000 * 10);

    return () => {
      clearInterval(interval);
    };
  }, [autoSaveAssessment, getValues]);

  return (
    <div className={getClassList()}>
      <Form
        onSubmit={handleSubmit(onFormSubmit)}
        // onChange={handleInputChange}
      >
        <div className="title-container">
          <div className="v-flex">
            <div>
              {data.title ? <p className="title">{data.title}</p> : null}
            </div>
            <div className="v-flex">
              <Text>實際評估日期:</Text>
              <RangePicker
                value={
                  watchValue?.assessmentStartDate &&
                  watchValue?.assessmentEndDate
                    ? [
                        moment(watchValue?.assessmentStartDate),
                        moment(watchValue?.assessmentEndDate),
                      ]
                    : []
                }
                locale={locale}
                allowClear={false}
                style={{ width: "390px" }}
                placeholder="選擇時間"
                showTime
                onChange={(date) => {
                  setValue("assessmentStartDate", moment(date[0]).valueOf());
                  setValue("assessmentEndDate", moment(date[1]).valueOf());
                }}
              />
            </div>
            <div style={{ marginTop: "10px" }} className="v-flex">
              <Text>簽署檔案:</Text>
              <ImgUploader
                value={watchValue?.attachment}
                onChange={(data) => setValue("attachment", data)}
                btnText="上載簽署檔案"
              />
            </div>
          </div>
          <div>
            {data.totalScore ? (
              <div className="score">
                {data.score} / {data.totalScore}
              </div>
            ) : null}
          </div>
        </div>
        {renderSections(data.section)}
        <Button type="primary" htmlType="submit">
          儲存
        </Button>
      </Form>
    </div>
  );
}

export default AssessmentFormComponent;

AssessmentFormComponent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    score: PropTypes.number,
    section: PropTypes.arrayOf(
      PropTypes.shape({
        displaySubTotalScore: PropTypes.bool.isRequired,
        question: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            input: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string.isRequired,
                inputType: PropTypes.string.isRequired,
                label: PropTypes.string,
                option: PropTypes.arrayOf(
                  PropTypes.shape({
                    value: PropTypes.string.isRequired,
                  })
                ),
                answer: PropTypes.oneOfType([
                  PropTypes.arrayOf(PropTypes.object),
                  PropTypes.arrayOf(PropTypes.string),
                  PropTypes.bool,
                  PropTypes.string,
                ]),
              })
            ).isRequired,
            title: PropTypes.string.isRequired,
          })
        ),
        score: PropTypes.number,
        title: PropTypes.string,
        totalScore: PropTypes.number,
      })
    ),
    totalScore: PropTypes.number,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

AssessmentFormComponent.defalutProps = {
  className: "",
};
