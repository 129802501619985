import axios from 'axios';
import { getApiUrl } from './env';

export const ApiMethod = {
    GET: 'GET',
    POST: 'POST',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
    PUT: 'PUT',
};

let token = 'hash_32_string';

/**
 * Axios instance with default config
 * @inner
 * @member {Object}
 */
const apiCaller = axios.create({
    baseURL: getApiUrl(),
    headers: {
        'Content-Type': 'application/json'
    },
});


/**
 * Set token
 * @function
 * @param {string} userToken - token
 */
export const setTokenHeader = (userToken = '') => {
    token = userToken;
};


/**
 * Get token
 * @function
 * @return {string} userToken
 */
export const getTokenHeader = () => token;


/**
 * Helper function to replace key in api url
 * @function
 * @param {string} url - url to replace
 * @param {Object} params - params to replace
 * @return {string} - Replaced url
 */
export const replaceUrlByParams = (url, params) => {
    let replacedUrl = url;

    Object.keys(params)
        .forEach((paramKey) => {
            replacedUrl = replacedUrl.replace(new RegExp(`{${paramKey}}`, 'g'), params[paramKey]);
        });

    return replacedUrl;
};

/**
 * Helper function for constructing API (replace path param/ append queries / add params in request body)
 * @function
 * @inner
 * @param {'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT'} method - Request method
 * @param {string} url - target url
 * @param {Object} headers - header parameters
 * @param {Object} body - parameters
 * @param {Object} pathParams - path parameters
 * @return {Promise} Promise object without 'data' root name
 */
export const constructFetchFn = (
    {
        method,
        url,
        headers = {},
        body = {},
        pathParams = {},
    }
) => {
    let replacedUrl = url;
    const config = {
        method,
        headers: {
            ...headers,
            'x-tw-token': token
        },
    };

    if (pathParams) {
        replacedUrl = replaceUrlByParams(url, pathParams);
    }

    return apiCaller(
        Object.assign(
            config,
            {
                url: replacedUrl,
            },
            method === ApiMethod.GET
                ? {
                    params: body,
                }
                : {
                    data: body,
                }
        )
    ).then(res => (
        (res.data.error)
            ? { error: res.data.error }
            : res.data.data
    )).catch((err) => Promise.reject(err));
};
