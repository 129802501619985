import React, { useCallback, useEffect, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Components
import AccountRefundTable from "./accountRefundTable";

// Styles
import "./accountRefundDrawer.scss";

// Utils
import { Drawer } from "antd";

const REFUND_REQUEST_LIST = gql`
  query refundRequestList($userId: String) {
    refundRequestList(userId: $userId) {
      id
      refundMethod {
        name
      }
      personInCharge {
        displayName
      }
      requestDate
      depositAmount
      rentalAmount
      status {
        id
        name
      }
    }
  }
`;

function AccountRefundDrawer({ userId, visible, onClose }) {
  const [
    getRefundRequestList,
    { loading: getRefundRequestListLoading, data: getRefundRequestListData },
  ] = useLazyQuery(REFUND_REQUEST_LIST, { variables: { userId } });

  const refreshAccount = useCallback(() => {
    getRefundRequestList();
  }, [getRefundRequestList]);

  useEffect(() => {
    if (userId) {
      refreshAccount();
    }
  }, [refreshAccount, userId]);

  const refundRequestList = useMemo(() => {
    return getRefundRequestListData?.refundRequestList || [];
  }, [getRefundRequestListData]);

  return (
    <div className="AccountRefundDrawer">
      <Drawer
        className="AccountRefundDrawer-drawer"
        width="50vw"
        visible={visible}
        onClose={onClose}
      >
        <div className="AccountRefundDrawer-content">
          <h1>退款紀錄</h1>
          <div>
            <AccountRefundTable
              loading={getRefundRequestListLoading}
              data={refundRequestList}
              title="退款申請詳情"
              refreshAccount={refreshAccount}
              userId={userId}
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
}

AccountRefundDrawer.propTypes = {
  userId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

AccountRefundDrawer.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default AccountRefundDrawer;
