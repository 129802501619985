import {
  Tabs,
  Form,
  Button,
  Card,
  Row,
  Col,
  Input,
  Select,
  Spin,
  notification,
  Icon,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { replaceUrlByParams } from "@utils/api";

import { PAGE, PAGE_NAME } from "../../../../utils/constants";

import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

import "./buyerDetail.scss";
import { gql } from "@apollo/client";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import ServiceAgreementTable from "../../../../components/thirdPartyCleaning/buyerDetail/serviceAgreementTable";
import CleaningJobHistoryTable from "../../../../components/thirdPartyCleaning/buyerDetail/cleaningJobHistoryTable";
import { useTabParam } from "../../../../utils/hook";

const serviceBuyerTagList = gql`
  query serviceBuyerTagList {
    serviceBuyerTagList {
      id
      name
    }
  }
`;

const serviceBuyerGet = gql`
  query serviceBuyerGet($serviceBuyerId: String!) {
    serviceBuyerGet(serviceBuyerId: $serviceBuyerId) {
      id
      referenceId
      name
      isUser
      type
      telephone
      email
      address
      fax
      contactPerson
      serviceBuyerTag {
        id
        name
      }
      cleaningJobCount
      cleaningJob {
        id
        referenceId
        createDate
        reservationDate
      }
      remark
      serviceAgreement {
        id
        file {
          key
          url
          name
          size
        }
        uploadDate
        staffInCharge {
          tbId
          displayName
        }
      }
      billingName
      billingTelephone
      billingAddress
      billingContactPerson
      billingType
      lastUpdateDate
    }
  }
`;

const serviceBuyerCreate = gql`
  mutation serviceBuyerCreate($input: ServiceBuyerCreateInput) {
    serviceBuyerCreate(input: $input) {
      id
      referenceId
    }
  }
`;

const serviceBuyerUpdate = gql`
  mutation serviceBuyerUpdate($input: ServiceBuyerUpdateInput) {
    serviceBuyerUpdate(input: $input) {
      id
      referenceId
    }
  }
`;

const serviceBuyerExisting = gql`
  query serviceBuyerExisting($telephone: String) {
    serviceBuyerExisting(telephone: $telephone)
  }
`;

const { TabPane } = Tabs;

const rowLayout = {
  gutter: 64,
};
const colLayout = {
  span: 12,
};

const BuyerDetail = () => {
  const { id } = useParams();
  const history = useHistory();

  const tabParam = useTabParam();

  const isEdit = !!id;

  const BREADCRUMB = [
    {
      path: isEdit
        ? PAGE.THIRD_PARTY_CLEANING_BUYER_EDIT
        : PAGE.THIRD_PARTY_CLEANING_BUYER_CREATE,
      name: isEdit
        ? PAGE_NAME.THIRD_PARTY_CLEANING_BUYER_EDIT
        : PAGE_NAME.THIRD_PARTY_CLEANING_BUYER_CREATE,
    },
  ];

  const { data: getTagListData, loading: getTagListIsLoading } =
    useQuery(serviceBuyerTagList);

  const [
    getServiceBuyer,
    {
      data: getServiceBuyerData,
      loading: getServiceBuyerLoading,
      refetch: getServiceBuyerRefetch,
    },
  ] = useLazyQuery(serviceBuyerGet, {
    onCompleted: (getServiceBuyerData) => {
      reset({
        ...getServiceBuyerData.serviceBuyerGet,
        serviceBuyerTagId:
          getServiceBuyerData.serviceBuyerGet.serviceBuyerTag.map(
            (tag) => tag.id
          ),
      });
    },
  });

  const [createServiceBuyer, { loading: createServiceBuyerLoading }] =
    useMutation(serviceBuyerCreate);

  const [updateServiceBuyer, { loading: updateServiceBuyerLoading }] =
    useMutation(serviceBuyerUpdate);

  const [getServiceBuyerExisting, { loading: getServiceBuyerExistingLoading }] =
    useLazyQuery(serviceBuyerExisting);

  const isLoading =
    getServiceBuyerLoading ||
    createServiceBuyerLoading ||
    updateServiceBuyerLoading;

  const { control, handleSubmit, errors, reset, setValue } = useForm({
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    try {
      if (!isEdit) {
        const res = await createServiceBuyer({ variables: { input: data } });
        notification.success({
          message: `成功新增機構／用戶檔案`,
          duration: 5,
        });

        if (res?.data?.serviceBuyerCreate?.id) {
          history.replace(
            replaceUrlByParams(PAGE.THIRD_PARTY_CLEANING_BUYER_EDIT, {
              id: res?.data?.serviceBuyerCreate?.id,
            })
          );
        }
      } else {
        await updateServiceBuyer({
          variables: {
            input: { ...data, id: getServiceBuyerData?.serviceBuyerGet?.id },
          },
        });
        notification.success({
          message: `成功更新機構／用戶檔案`,
          duration: 5,
        });
        getServiceBuyerRefetch();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onValidateTelephone = async (value) => {
    if (isEdit && value === getServiceBuyerData?.serviceBuyerGet?.telephone)
      return true;
    const { data } = await getServiceBuyerExisting({
      variables: { telephone: value },
    });
    if (data?.serviceBuyerExisting === true) return "此機構／用戶電話已登記";
    return true;
  };

  useEffect(() => {
    if (id) {
      getServiceBuyer({ variables: { serviceBuyerId: id } });
    }
  }, [getServiceBuyer, id]);

  return (
    <div className="buyer-detail">
      <StickyBreadcrumbPageComponent
        className="buyer-detail-page"
        breadcrumb={BREADCRUMB}
      >
        <div className="content-inner">
          <Tabs {...tabParam("buyerDetail")}>
            <TabPane className="section" tab="檔案資料" key="buyerDetail">
              <Form layout="vertical">
                <Spin spinning={isLoading}>
                  <div className="button-container">
                    <Button
                      onClick={() =>
                        history.push(
                          replaceUrlByParams(
                            PAGE.THIRD_PARTY_CLEANING_BUYER_LIST,
                            {
                              id: id,
                            }
                          )
                        )
                      }
                    >
                      取消
                    </Button>
                    <Button type="primary" onClick={handleSubmit(onSubmit)}>
                      {isEdit ? "儲存" : "新增"}
                    </Button>
                  </div>
                  <Card style={{ marginBottom: 16 }} title="基本資料">
                    <Row {...rowLayout}>
                      <Col {...colLayout}>
                        <Form.Item
                          required
                          label="機構／用戶名稱"
                          validateStatus={errors?.name ? "error" : ""}
                          help={!!errors?.name && "請輸入機構／用戶名稱"}
                          extra={
                            getServiceBuyerData?.serviceBuyerGet?.referenceId
                              ? `(${getServiceBuyerData?.serviceBuyerGet?.referenceId})`
                              : ""
                          }
                        >
                          <Controller
                            control={control}
                            name="name"
                            render={(fields) => (
                              <Input
                                {...fields}
                                onBlur={(event) => {
                                  setValue(
                                    "billingName",
                                    event.target.value.trim()
                                  );
                                  fields.onBlur(event);
                                }}
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </Form.Item>
                      </Col>
                      <Col {...colLayout}>
                        <Form.Item
                          required
                          label="檔案類型"
                          validateStatus={errors?.type ? "error" : ""}
                          help={!!errors?.type && "請選擇檔案類型"}
                        >
                          <Controller
                            control={control}
                            name="type"
                            render={(fields) => (
                              <Select {...fields}>
                                <Select.Option value={"individual"}>
                                  個人
                                </Select.Option>
                                <Select.Option value={"organization"}>
                                  機構
                                </Select.Option>
                              </Select>
                            )}
                            rules={{ required: true }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row {...rowLayout}>
                      <Col {...colLayout}>
                        {isEdit ? (
                          <Form.Item label="機構／用戶電話">
                            <Typography.Text>
                              {getServiceBuyerData?.serviceBuyerGet?.telephone}
                            </Typography.Text>
                          </Form.Item>
                        ) : (
                          <Form.Item
                            required
                            label="機構／用戶電話"
                            validateStatus={errors?.telephone ? "error" : ""}
                            help={errors?.telephone?.message}
                          >
                            {" "}
                            <Controller
                              control={control}
                              name="telephone"
                              render={(fields) => (
                                <Input
                                  type="tel"
                                  maxLength={8}
                                  {...fields}
                                  suffix={
                                    <Icon
                                      type={
                                        getServiceBuyerExistingLoading
                                          ? "loading"
                                          : ""
                                      }
                                    />
                                  }
                                />
                              )}
                              rules={{
                                required: {
                                  value: true,
                                  message: "請輸入機構／用戶電話",
                                },
                                validate: onValidateTelephone,
                              }}
                            />
                          </Form.Item>
                        )}
                      </Col>
                      <Col {...colLayout}>
                        <Form.Item
                          required
                          label="聯絡人姓名"
                          validateStatus={errors?.contactPerson ? "error" : ""}
                          help={!!errors?.contactPerson && "請輸入聯絡人姓名"}
                        >
                          <Controller
                            control={control}
                            name="contactPerson"
                            render={(fields) => <Input {...fields} />}
                            rules={{ required: true }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row {...rowLayout}>
                      <Col {...colLayout}>
                        <Form.Item label="地址">
                          <Controller
                            control={control}
                            name="address"
                            render={(fields) => <Input {...fields} />}
                          />
                        </Form.Item>
                      </Col>
                      <Col {...colLayout}>
                        <Form.Item label="電郵地址">
                          <Controller
                            control={control}
                            name="email"
                            render={(fields) => (
                              <Input type="email" {...fields} />
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row {...rowLayout}>
                      <Col {...colLayout}>
                        <Form.Item label="傳真號碼">
                          <Controller
                            control={control}
                            name="fax"
                            render={(fields) => <Input {...fields} />}
                          />
                        </Form.Item>
                      </Col>
                      <Col {...colLayout}>
                        <Form.Item label="檔案標籤">
                          <Controller
                            control={control}
                            name="serviceBuyerTagId"
                            render={(fields) => (
                              <Select
                                {...fields}
                                mode="multiple"
                                loading={getTagListIsLoading}
                              >
                                {getTagListData &&
                                  getTagListData?.serviceBuyerTagList?.map(
                                    (tag) => (
                                      <Select.Option
                                        key={tag.id}
                                        value={tag.id}
                                      >
                                        {tag.name}
                                      </Select.Option>
                                    )
                                  )}
                              </Select>
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row {...rowLayout}>
                      <Col>
                        <Form.Item label="其他備註">
                          <Controller
                            control={control}
                            name="remark"
                            render={(fields) => <Input.TextArea {...fields} />}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <Card title="發票基本資料">
                    <Row {...rowLayout}>
                      <Col {...colLayout}>
                        <Form.Item
                          required
                          label="機構／用戶名稱"
                          validateStatus={errors?.billingName ? "error" : ""}
                          help={!!errors?.billingName && "請輸入機構／用戶名稱"}
                        >
                          <Controller
                            control={control}
                            name="billingName"
                            render={(fields) => <Input {...fields} />}
                            rules={{ required: true }}
                          />
                        </Form.Item>
                      </Col>
                      <Col {...colLayout}>
                        <Form.Item
                          required
                          label="機構／用戶電話"
                          validateStatus={
                            errors?.billingTelephone ? "error" : ""
                          }
                          help={
                            !!errors?.billingTelephone && "請輸入機構／用戶名稱"
                          }
                        >
                          <Controller
                            control={control}
                            name="billingTelephone"
                            render={(fields) => (
                              <Input type="tel" {...fields} />
                            )}
                            rules={{ required: true }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row {...rowLayout}>
                      <Col {...colLayout}>
                        <Form.Item
                          label="地址"
                          validateStatus={errors?.billingAddress ? "error" : ""}
                          help={!!errors?.billingAddress && "請輸入地址"}
                        >
                          <Controller
                            control={control}
                            name="billingAddress"
                            render={(fields) => <Input {...fields} />}
                          />
                        </Form.Item>
                      </Col>
                      <Col {...colLayout}>
                        <Form.Item
                          required
                          label="聯絡人姓名"
                          validateStatus={
                            errors?.billingContactPerson ? "error" : ""
                          }
                          help={
                            !!errors?.billingContactPerson && "請輸入聯絡人姓名"
                          }
                        >
                          <Controller
                            control={control}
                            name="billingContactPerson"
                            render={(fields) => <Input {...fields} />}
                            rules={{ required: true }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row {...rowLayout}>
                      <Col {...colLayout}>
                        <Form.Item
                          required
                          label="收費類型"
                          validateStatus={errors?.billingType ? "error" : ""}
                          help={!!errors?.billingType && "請選擇收費類型"}
                        >
                          <Controller
                            control={control}
                            name="billingType"
                            render={(fields) => (
                              <Select {...fields}>
                                <Select.Option value={"regular"}>
                                  正價
                                </Select.Option>
                                <Select.Option value={"discount"}>
                                  優惠價
                                </Select.Option>
                              </Select>
                            )}
                            rules={{ required: true }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Spin>
              </Form>
            </TabPane>
            {isEdit && (
              <TabPane
                className="section"
                tab="服務協議"
                key="serviceAgreement"
              >
                <ServiceAgreementTable
                  serviceBuyer={getServiceBuyerData?.serviceBuyerGet}
                  refetch={getServiceBuyerRefetch}
                  isLoading={getServiceBuyerLoading}
                />
              </TabPane>
            )}
            {isEdit && (
              <TabPane
                className="section"
                tab="工作記錄"
                key="cleaningJobHistory"
              >
                <CleaningJobHistoryTable
                  serviceBuyer={getServiceBuyerData?.serviceBuyerGet}
                  refetch={getServiceBuyerRefetch}
                  isLoading={getServiceBuyerLoading}
                />
              </TabPane>
            )}
          </Tabs>
        </div>
      </StickyBreadcrumbPageComponent>
    </div>
  );
};

export default BuyerDetail;
