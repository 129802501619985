import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import React from "react";

import PropTypes from "prop-types";

function DebounceSelectV3({
  fetchOptions = () => Promise.resolve([]),
  debounceTimeout = 800,
  ...props
}) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      if (!value) return;
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      showSearch
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
    >
      {[...options, ...(props?.options ?? [])].map(
        ({ label, value, ...props }, i) => (
          <Select.Option key={i} value={value} {...props}>
            {label}
          </Select.Option>
        )
      )}
    </Select>
  );
}

DebounceSelectV3.propTypes = {
  fetchOptions: PropTypes.func,
  debounceTimeout: PropTypes.number,
  options: PropTypes.array,
  children: undefined,
};

export default DebounceSelectV3;
