/* eslint-disable no-unused-vars */
import {
  Tabs,
  Form,
  Button,
  Card,
  Row,
  Col,
  Input,
  Spin,
  notification,
  DatePicker,
  Typography,
  Table,
  Tag,
  Select,
  Popconfirm,
  Modal,
  InputNumber,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { replaceUrlByParams } from "@utils/api";

import {
  MOMENT_DATETIME_FORMAT,
  MOMENT_FORMAT,
  PAGE,
  PAGE_NAME,
} from "../../../../utils/constants";

import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

import "./jobDetail.scss";
import { gql } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import DebounceSelectV3 from "../../../../components/DebounceSelect";
import moment from "moment";

import PropTypes from "prop-types";
import { useDisclosureParam, useTabParam } from "../../../../utils/hook";

import { downloadFile } from "../../../../utils/common";
import DeviceList from "../../../../components/thirdPartyCleaning/jobDetail/deviceList";
import InvoiceDetail from "../../../../components/thirdPartyCleaning/jobDetail/invoiceDetail";

const DuplicateEquipmentModal = ({ visible, onCancel, onOk }) => {
  const { control, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      amount: null,
    },
  });

  const onSubmit = (data) => {
    onOk(data?.amount);
    onCancel();
  };

  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [reset, visible]);

  return (
    <Modal
      title="複製器材"
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit(onSubmit)}
    >
      <Form.Item
        required
        label="數量"
        validateStatus={errors?.amount ? "error" : ""}
        help={!!errors?.amount && "請輸入有效數量"}
      >
        <Controller
          control={control}
          name="amount"
          render={(fields) => <InputNumber {...fields} min={0} />}
          rules={{ required: true, min: 1 }}
        />
      </Form.Item>
    </Modal>
  );
};

DuplicateEquipmentModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

const equipmentCategoryList = gql`
  query equipmentCategoryList {
    equipmentCategoryList {
      id
      name
      isOther
    }
  }
`;

const serviceBuyerList = gql`
  query serviceBuyerList($keyword: String, $size: Int!, $offset: Int!) {
    serviceBuyerList(keyword: $keyword, size: $size, offset: $offset) {
      id
      name
      telephone
      contactPerson
    }
  }
`;

const cleaningJobGet = gql`
  query cleaningJobGet($cleaningJobId: String!) {
    cleaningJobGet(cleaningJobId: $cleaningJobId) {
      id
      referenceId
      serviceBuyer {
        id
        referenceId
        name
        telephone
        contactPerson
        serviceBuyerTag {
          id
          name
        }
        billingType
      }
      contactPerson
      telephone
      createDate
      reservationDate
      pickupDate
      deliveryDate
      remark
      equipment {
        id
        referenceId
        name
        equipmentCategory {
          id
          name
        }
        otherRemark
        cleanFlow {
          id
          referenceId
          cleaningFlow {
            id
            referenceId
            name
          }
          cleaningFlowStage {
            seq
            startDate
          }
        }
        status
      }
      cleaningJobInvoice {
        isIssued
        item {
          equipmentCategory {
            id
            name
            regularAmount
            discountedAmount
          }
          pendingCount
          cleaningFailureCount
          completedCount
          amount
        }
        otherItem {
          equipmentCategory {
            id
            name
            regularAmount
            discountedAmount
          }
          equipment {
            id
            otherRemark
          }
          pendingCount
          cleaningFailureCount
          completedCount
          amount
        }
        feeAndCharge {
          name
          amount
        }
      }
    }
  }
`;

const cleaningJobCreate = gql`
  mutation cleaningJobCreate($input: CleaningJobCreateInput) {
    cleaningJobCreate(input: $input) {
      id
    }
  }
`;

const cleaningJobUpdate = gql`
  mutation cleaningJobUpdate($input: CleaningJobUpdateInput) {
    cleaningJobUpdate(input: $input) {
      id
    }
  }
`;

const cleaningJobPickupNoteCreate = gql`
  mutation cleaningJobPickupNoteCreate($cleaningJobId: String) {
    cleaningJobPickupNoteCreate(cleaningJobId: $cleaningJobId) {
      key
      url
      name
      size
    }
  }
`;

const cleaningJobDeliveryNoteCreate = gql`
  mutation cleaningJobDeliveryNoteCreate($cleaningJobId: String) {
    cleaningJobDeliveryNoteCreate(cleaningJobId: $cleaningJobId) {
      key
      url
      name
      size
    }
  }
`;
const cleaningJobInvoiceNoteCreate = gql`
  mutation cleaningJobInvoiceNoteCreate($cleaningJobId: String) {
    cleaningJobInvoiceNoteCreate(cleaningJobId: $cleaningJobId) {
      key
      url
      name
      size
    }
  }
`;

const cleaningJobReceiptNoteCreate = gql`
  mutation cleaningJobReceiptNoteCreate($cleaningJobId: String) {
    cleaningJobReceiptNoteCreate(cleaningJobId: $cleaningJobId) {
      key
      url
      name
      size
    }
  }
`;

const cleaningJobInvoiceNoteDownload = gql`
  mutation cleaningJobInvoiceNoteDownload($cleaningJobId: String) {
    cleaningJobInvoiceNoteDownload(cleaningJobId: $cleaningJobId) {
      key
      url
      name
      size
    }
  }
`;

const { TabPane } = Tabs;

const rowLayout = {
  gutter: 64,
};
const colLayout = {
  span: 8,
};

const JobDetail = () => {
  const { id } = useParams();
  const history = useHistory();

  const tabParam = useTabParam();

  const isDetail = !!id;

  const [action, setAction] = useState(undefined);

  const duplicateEquipmentModalDisclosure = useDisclosureParam();

  const [getServiceBuyerList] = useLazyQuery(serviceBuyerList);

  const [
    getCleaningJob,
    {
      data: getCleaningJobData,
      loading: getCleaningJobLoading,
      refetch: getCleaningJobRefetch,
    },
  ] = useLazyQuery(cleaningJobGet, {
    onCompleted: (getCleaningJobData) => {
      const serviceBuyer = getCleaningJobData.cleaningJobGet.serviceBuyer;
      reset({
        ...getCleaningJobData.cleaningJobGet,
        serviceBuyerId: {
          label: `${serviceBuyer.name} (${serviceBuyer.telephone})`,
          value: serviceBuyer.id,
        },
      });
    },
  });

  const BREADCRUMB = [
    {
      path: isDetail
        ? PAGE.THIRD_PARTY_CLEANING_JOB_EDIT
        : PAGE.THIRD_PARTY_CLEANING_JOB_CREATE,
      name: isDetail
        ? `${PAGE_NAME.THIRD_PARTY_CLEANING_JOB_EDIT}${
            getCleaningJobData?.cleaningJobGet?.referenceId
              ? ` (${getCleaningJobData?.cleaningJobGet?.referenceId})`
              : ""
          }`
        : PAGE_NAME.THIRD_PARTY_CLEANING_JOB_CREATE,
    },
  ];

  const [
    createCleaningJob,
    { data: createCleaningJobData, loading: createCleaningJobLoading },
  ] = useMutation(cleaningJobCreate);

  const [updateCleaningJob, { loading: updateCleaningJobLoading }] =
    useMutation(cleaningJobUpdate);

  const [
    listEquipmentCategory,
    { data: listEquipmentCategoryData, loading: listEquipmentCategoryLoading },
  ] = useLazyQuery(equipmentCategoryList);

  const [actionLoading, setActionLoading] = useState(false);

  const [createPickupNote] = useMutation(cleaningJobPickupNoteCreate);
  const [createDeliveryNote] = useMutation(cleaningJobDeliveryNoteCreate);
  const [createInvoiceNote] = useMutation(cleaningJobInvoiceNoteCreate);
  const [createReceiptNote] = useMutation(cleaningJobReceiptNoteCreate);
  const [downloadAllInvoiceNote] = useMutation(cleaningJobInvoiceNoteDownload);

  const onAction = useCallback(async () => {
    setActionLoading(true);
    try {
      switch (action) {
        case "createPickupNote": {
          const {
            data: { cleaningJobPickupNoteCreate: file },
          } = await createPickupNote({ variables: { cleaningJobId: id } });
          await downloadFile(file);
          break;
        }
        case "createDeliveryNote": {
          const {
            data: { cleaningJobDeliveryNoteCreate: file },
          } = await createDeliveryNote({
            variables: { cleaningJobId: id },
          });
          await downloadFile(file);
          break;
        }
        case "createInvoiceNote": {
          const {
            data: { cleaningJobInvoiceNoteCreate: file },
          } = await createInvoiceNote({ variables: { cleaningJobId: id } });
          await downloadFile(file);
          break;
        }
        case "createReceiptNote": {
          const {
            data: { cleaningJobReceiptNoteCreate: file },
          } = await createReceiptNote({ variables: { cleaningJobId: id } });
          await downloadFile(file);
          break;
        }
        case "downloadAllInvoiceNote": {
          const {
            data: { cleaningJobInvoiceNoteDownload: file },
          } = await downloadAllInvoiceNote({ variables: { cleaningJobId: id } });
          await downloadFile(file);
          break;
        }
        default:
          break;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setAction(undefined);
      setActionLoading(false);
    }
  }, [
    action,
    createDeliveryNote,
    createInvoiceNote,
    createPickupNote,
    createReceiptNote,
    id,
  ]);

  const isLoading =
    getCleaningJobLoading ||
    createCleaningJobLoading ||
    updateCleaningJobLoading;

  const { control, handleSubmit, errors, reset, setValue, watch, clearErrors } =
    useForm({
      mode: "onBlur",
      defaultValues: {
        equipment: [
          {
            name: "",
            equipmentCategoryId: "",
          },
        ],
      },
    });

  const { fields, append, remove } = useFieldArray({
    name: "equipment",
    control,
  });

  const onDuplicateEquipment = (equipmentIndex, amount) => {
    const newEquipmentList = [];
    for (let index = 0; index < amount; index++) {
      const newEquipment = watch("equipment")[equipmentIndex];
      newEquipment.name = `${newEquipment?.name || ""} (${index + 1})`;
      newEquipmentList.push(newEquipment);
    }
    append(newEquipmentList);
  };

  const createEquipmentColumns = [
    {
      key: "duplication",
      width: "5%",
      render: (_, record, index) => (
        <Button
          icon="copy"
          onClick={() => duplicateEquipmentModalDisclosure.onOpen(index)}
        />
      ),
    },
    {
      title: (
        <Form.Item
          className="table-form-item-label"
          label="器材名稱"
          required
        />
      ),
      render: (_, record, index) => {
        return (
          <Form.Item
            validateStatus={errors?.equipment?.[index]?.name ? "error" : ""}
            help={!!errors?.equipment?.[index]?.name && "請輸入器材名稱"}
            style={{ marginBottom: 0 }}
          >
            <Controller
              control={control}
              name={`equipment.${index}.name`}
              defaultValue={record?.name}
              render={(fields) => <Input {...fields} />}
              rules={{ required: true }}
            />
          </Form.Item>
        );
      },
    },
    {
      width: "40%",
      title: (
        <Form.Item
          className="table-form-item-label"
          label="器材種類"
          required
        />
      ),
      render: (_, record, index) => {
        return (
          <div>
            <Form.Item
              validateStatus={
                errors?.equipment?.[index]?.equipmentCategoryId ? "error" : ""
              }
              help={
                !!errors?.equipment?.[index]?.equipmentCategoryId &&
                "請選擇器材種類"
              }
              style={{ marginBottom: 0 }}
            >
              <Controller
                control={control}
                name={`equipment.${index}.equipmentCategoryId`}
                defaultValue={record?.equipmentCategoryId}
                render={(fields) => (
                  <Select {...fields} loading={listEquipmentCategoryLoading}>
                    {listEquipmentCategoryData?.equipmentCategoryList?.map(
                      ({ id, name }, i) => (
                        <Select.Option key={i} value={id}>
                          {name}
                        </Select.Option>
                      )
                    )}
                  </Select>
                )}
                rules={{ required: true }}
              />
            </Form.Item>

            {listEquipmentCategoryData?.equipmentCategoryList?.find(
              (item) =>
                item.id === watch(`equipment.${index}.equipmentCategoryId`) &&
                item.isOther === true
            ) && (
              <Form.Item
                validateStatus={
                  errors?.equipment?.[index]?.otherRemark ? "error" : ""
                }
                help={
                  !!errors?.equipment?.[index]?.otherRemark && "請輸入器材種類"
                }
              >
                <Controller
                  control={control}
                  name={`equipment.${index}.otherRemark`}
                  defaultValue={record?.otherRemark}
                  render={(fields) => <Input {...fields} />}
                  rules={{ required: true }}
                />
              </Form.Item>
            )}
          </div>
        );
      },
    },
    {
      key: "action",
      width: "10%",
      render: (_, record, index) => {
        if (index === 0) return null;
        return (
          <Popconfirm
            okButtonProps={{ type: "danger" }}
            title="確認刪除此器材？"
            onConfirm={() => remove(index)}
          >
            <Button type="danger">刪除</Button>
          </Popconfirm>
        );
      },
    },
  ];

  const detailEquipmentColumns = [
    {
      title: "器材名稱",
      dataIndex: "name",
    },
    {
      title: "器材種類",
      render: (_, record) => {
        return record?.otherRemark
          ? `其他：${record?.otherRemark}`
          : record?.equipmentCategory.name;
      },
    },
  ];

  const onSubmit = async (data) => {
    try {
      if (!isDetail) {
        const res = await createCleaningJob({
          variables: {
            input: { ...data, serviceBuyerId: data?.serviceBuyerId?.key },
          },
        });
        notification.success({
          message: `成功新增清潔工作`,
          duration: 5,
        });
        if (res.data.cleaningJobCreate?.id) {
          history.replace(
            replaceUrlByParams(PAGE.THIRD_PARTY_CLEANING_JOB_EDIT, {
              id: res.data.cleaningJobCreate?.id,
            })
          );
        }
      } else {
        await updateCleaningJob({
          variables: {
            input: { ...data, id: getCleaningJobData?.cleaningJobGet?.id },
          },
        });
        notification.success({
          message: `成功更新清潔工作`,
          duration: 5,
        });
        getCleaningJobRefetch();
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (id) {
      getCleaningJob({ variables: { cleaningJobId: id } });
    }
  }, [getCleaningJob, id]);

  useEffect(() => {
    if (!id) {
      listEquipmentCategory();
    }
  }, [id, listEquipmentCategory]);

  return (
    <div className="job-detail">
      <StickyBreadcrumbPageComponent
        className="job-detail-page"
        breadcrumb={BREADCRUMB}
      >
        <DuplicateEquipmentModal
          visible={duplicateEquipmentModalDisclosure.isOpen}
          onCancel={duplicateEquipmentModalDisclosure.onClose}
          onOk={(amount) =>
            onDuplicateEquipment(
              duplicateEquipmentModalDisclosure.param,
              amount
            )
          }
        />
        <div className="content-inner">
          {isDetail && getCleaningJobData?.cleaningJobGet && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="service-buyer-container">
                <Typography.Text
                  style={{ marginRight: "10px" }}
                >{`${getCleaningJobData?.cleaningJobGet?.serviceBuyer?.name} (${getCleaningJobData?.cleaningJobGet?.serviceBuyer?.telephone})`}</Typography.Text>
                {getCleaningJobData?.cleaningJobGet?.serviceBuyer?.serviceBuyerTag?.map(
                  ({ id, name }, i) => (
                    <Tag key={i}>{name}</Tag>
                  )
                )}
              </div>
              <div className="cleaning-job-action-container">
                <Select
                  allowClear
                  style={{ minWidth: "200px" }}
                  placeholder="請選擇動作"
                  value={action}
                  onChange={setAction}
                >
                  <Select.Option value="createPickupNote">
                    生成收貨單
                  </Select.Option>
                  <Select.Option value="createDeliveryNote">
                    生成送貨單
                  </Select.Option>
                  <Select.Option value="createInvoiceNote">
                    生成發票
                  </Select.Option>
                  <Select.Option value="createReceiptNote">
                    生成收據
                  </Select.Option>
                  <Select.Option value="downloadAllInvoiceNote">
                    下載所有已生成發票
                  </Select.Option>
                </Select>
                <Button
                  type="primary"
                  onClick={() => onAction()}
                  loading={actionLoading}
                >
                  確定
                </Button>
              </div>
            </div>
          )}
          <Tabs {...tabParam("jobDetail")}>
            <TabPane className="section" tab="工作資料" key="jobDetail">
              <Form layout="vertical">
                <Spin spinning={isLoading}>
                  <div className="button-container">
                    <Button
                      onClick={() =>
                        history.push(PAGE.THIRD_PARTY_CLEANING_JOB_LIST)
                      }
                    >
                      取消
                    </Button>
                    <Button type="primary" onClick={handleSubmit(onSubmit)}>
                      {isDetail ? "儲存" : "新增"}
                    </Button>
                  </div>
                  <Card
                    style={{ marginBottom: 16 }}
                    title="申請資料（用於生成送貨單及收貨單）"
                  >
                    <Row {...rowLayout}>
                      <Col {...colLayout}>
                        <Form.Item
                          required
                          label="機構／用戶"
                          validateStatus={!!errors?.serviceBuyerId && "error"}
                          help={!!errors?.serviceBuyerId && "請選擇機構／用戶"}
                        >
                          {isDetail ? (
                            <Typography.Text>{`${getCleaningJobData?.cleaningJobGet?.serviceBuyer?.name} (${getCleaningJobData?.cleaningJobGet?.serviceBuyer?.telephone})`}</Typography.Text>
                          ) : (
                            <Controller
                              control={control}
                              name="serviceBuyerId"
                              render={({ onChange, ...fields }) => (
                                <DebounceSelectV3
                                  fetchOptions={(value) =>
                                    getServiceBuyerList({
                                      variables: {
                                        keyword: value,
                                        size: 999,
                                        offset: 0,
                                      },
                                    }).then((res) =>
                                      res.data.serviceBuyerList.map(
                                        (buyer) => ({
                                          label: `${buyer.name} (${buyer.telephone})`,
                                          value: buyer.id,
                                          buyer,
                                        })
                                      )
                                    )
                                  }
                                  {...fields}
                                  onChange={(value, option) => {
                                    onChange(value);
                                    setValue(
                                      "contactPerson",
                                      option?.props?.buyer?.contactPerson
                                    );
                                    setValue(
                                      "telephone",
                                      option?.props?.buyer?.telephone
                                    );
                                    clearErrors("contactPerson");
                                    clearErrors("telephone");
                                  }}
                                  placeholder="搜尋機構／用戶名稱或電話"
                                  allowClear
                                />
                              )}
                              rules={{ required: true }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col {...colLayout}>
                        <Form.Item
                          required
                          label="聯絡人姓名"
                          validateStatus={!!errors?.contactPerson && "error"}
                          help={!!errors?.contactPerson && "請輸入聯絡人姓名"}
                        >
                          <Controller
                            control={control}
                            name="contactPerson"
                            render={(fields) => <Input {...fields} />}
                            rules={{ required: true }}
                          />
                        </Form.Item>
                      </Col>
                      <Col {...colLayout}>
                        <Form.Item
                          required
                          label="聯絡人電話"
                          validateStatus={!!errors?.telephone && "error"}
                          help={errors?.telephone?.message}
                        >
                          <Controller
                            control={control}
                            name="telephone"
                            render={(fields) => (
                              <Input type="tel" maxLength={8} {...fields} />
                            )}
                            rules={{
                              required: {
                                value: true,
                                message: "請輸入聯絡人電話",
                              },
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row {...rowLayout}>
                      <Col {...colLayout}>
                        <Form.Item
                          required
                          label="預計清洗日期"
                          validateStatus={!!errors?.reservationDate && "error"}
                          help={
                            !!errors?.reservationDate && "請選擇預計清洗日期"
                          }
                        >
                          {isDetail ? (
                            <Typography.Text>
                              {moment(
                                getCleaningJobData?.cleaningJobGet
                                  ?.reservationDate
                              ).format(MOMENT_FORMAT)}
                            </Typography.Text>
                          ) : (
                            <Controller
                              control={control}
                              name="reservationDate"
                              render={({ value, onChange, ...fields }) => (
                                <DatePicker
                                  style={{ width: "100%" }}
                                  {...fields}
                                  value={value ? moment(value) : undefined}
                                  onChange={(moment, dateString) => {
                                    onChange(
                                      moment.startOf("minute").valueOf()
                                    );
                                  }}
                                />
                              )}
                              rules={{
                                required: true,
                              }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col {...colLayout}>
                        <Form.Item
                          required
                          label="收貨日期及時間"
                          validateStatus={!!errors?.pickupDate && "error"}
                          help={!!errors?.pickupDate && "請選擇收貨日期及時間"}
                        >
                          <Controller
                            control={control}
                            name="pickupDate"
                            render={({ value, onChange, ...fields }) => (
                              <DatePicker
                                showTime={{
                                  format: "HH:mm",
                                }}
                                format={MOMENT_DATETIME_FORMAT}
                                style={{ width: "100%" }}
                                {...fields}
                                value={value ? moment(value) : undefined}
                                onChange={(moment, dateString) => {
                                  onChange(moment.startOf("minute").valueOf());
                                }}
                              />
                            )}
                            rules={{
                              required: true,
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col {...colLayout}>
                        <Form.Item
                          required
                          label="送貨日期及時間"
                          validateStatus={!!errors?.deliveryDate && "error"}
                          help={
                            !!errors?.deliveryDate && "請選擇送貨日期及時間"
                          }
                        >
                          <Controller
                            control={control}
                            name="deliveryDate"
                            render={({ value, onChange, ...fields }) => (
                              <DatePicker
                                showTime={{
                                  format: "HH:mm",
                                }}
                                format={MOMENT_DATETIME_FORMAT}
                                style={{ width: "100%" }}
                                {...fields}
                                value={value ? moment(value) : undefined}
                                onChange={(moment, dateString) => {
                                  onChange(moment.startOf("minute").valueOf());
                                }}
                              />
                            )}
                            rules={{
                              required: true,
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row {...rowLayout}>
                      <Col>
                        <Form.Item label="備註">
                          <Controller
                            control={control}
                            name="remark"
                            render={(fields) => <Input.TextArea {...fields} />}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>

                  <Card title="器材資料">
                    {isDetail ? (
                      <Table
                        columns={detailEquipmentColumns}
                        dataSource={
                          getCleaningJobData?.cleaningJobGet?.equipment
                        }
                        loading={getCleaningJobLoading}
                        scroll={{x:"max-content"}}
                      />
                    ) : (
                      <div>
                        <Table
                          columns={createEquipmentColumns}
                          dataSource={fields}
                          rowKey="id"
                          pagination={false}
                          scroll={{x:"max-content"}}
                        />
                        <Button
                          style={{ marginTop: 16 }}
                          type="primary"
                          onClick={() =>
                            append({
                              name: "",
                              equipmentCategoryId: "",
                            })
                          }
                        >
                          新增器材
                        </Button>
                      </div>
                    )}
                  </Card>
                </Spin>
              </Form>
            </TabPane>
            {isDetail && (
              <TabPane
                className="section"
                tab="器材詳細資料"
                key="equipmentDetail"
              >
                <DeviceList
                  cleaningJob={getCleaningJobData?.cleaningJobGet}
                  refetch={getCleaningJobRefetch}
                  isLoading={getCleaningJobLoading}
                />
              </TabPane>
            )}
            {isDetail && (
              <TabPane className="section" tab="發票資料" key="invoiceDetail">
                <InvoiceDetail
                  cleaningJob={getCleaningJobData?.cleaningJobGet}
                  refetch={getCleaningJobRefetch}
                  isLoading={getCleaningJobLoading}
                />
              </TabPane>
            )}
          </Tabs>
        </div>
      </StickyBreadcrumbPageComponent>
    </div>
  );
};

export default JobDetail;
