import React, { useCallback, useEffect } from "react";
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";
import { PAGE, PAGE_NAME } from "../../../utils/constants";
import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  notification,
  Popconfirm,
  Table,
} from "antd";

import "./categoryList.scss";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";

import PropTypes from "prop-types";
import { useDisclosureParam } from "../../../utils/hook";

const equipmentCategoryList = gql`
  query equipmentCategoryList {
    equipmentCategoryList {
      id
      name
      regularAmount
      discountedAmount
      isOther
    }
  }
`;

const equipmentCategoryCreate = gql`
  mutation equipmentCategoryCreate($input: EquipmentCategoryCreateInput) {
    equipmentCategoryCreate(input: $input) {
      id
    }
  }
`;

const equipmentCategoryDelete = gql`
  mutation equipmentCategoryDelete($EquipmentCategoryId: String!) {
    equipmentCategoryDelete(EquipmentCategoryId: $EquipmentCategoryId) {
      id
    }
  }
`;

const equipmentCategoryUpdate = gql`
  mutation equipmentCategoryUpdate($input: [EquipmentCategoryUpdateInput]!) {
    equipmentCategoryUpdate(input: $input) {
      id
    }
  }
`;

const BREADCRUMB = [
  {
    path: PAGE.THIRD_PARTY_CLEANING_CATEGORY_LIST,
    name: PAGE_NAME.THIRD_PARTY_CLEANING_CATEGORY_LIST,
  },
];

const DeviceCategoryDrawer = ({
  visible,
  onClose = () => undefined,
  onSave = () => undefined,
}) => {
  const [createCategory, { loading }] = useMutation(equipmentCategoryCreate);

  const { control, handleSubmit, reset, errors } = useForm({
    defaultValues: {
      name: "",
      regularAmount: 0,
      discountedAmount: 0,
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      await createCategory({ variables: { input: data } });
      notification.success({
        message: "成功新增器材種類",
        duration: 5,
      });
      onClose();
      await onSave();
    },
    [createCategory, onClose, onSave]
  );

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [reset, visible]);

  return (
    <Drawer
      width="30%"
      title="新增器材種類"
      visible={visible}
      onClose={onClose}
    >
      <Form>
        <Form.Item
          label="器材種類"
          validateStatus={!!errors?.name && "error"}
          {...(errors?.name && { help: "請輸入器材種類" })}
        >
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={(fields) => <Input {...fields} />}
          />
        </Form.Item>
        <Form.Item
          label="正價（$）"
          validateStatus={!!errors?.regularAmount && "error"}
          {...(errors?.regularAmount && { help: "請輸入正價（$）" })}
        >
          <Controller
            control={control}
            name="regularAmount"
            rules={{ required: true }}
            render={(fields) => (
              <InputNumber
                min={0}
                {...fields}
                value={fields.value / 100}
                onChange={(value) => fields.onChange(Math.round(value * 100))}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="優惠價（$）"
          validateStatus={!!errors?.discountedAmount && "error"}
          {...(errors?.discountedAmount && { help: "請輸入優惠價（$）" })}
        >
          <Controller
            control={control}
            name="discountedAmount"
            rules={{ required: true }}
            render={(fields) => (
              <InputNumber
                min={0}
                {...fields}
                value={fields.value / 100}
                onChange={(value) => fields.onChange(Math.round(value * 100))}
              />
            )}
          />
        </Form.Item>
      </Form>
      <div className="btn-container">
        <Button
          className="btn btn-secondary"
          onClick={onClose}
          disabled={loading}
        >
          取消
        </Button>
        <Button
          className="btn"
          type="primary"
          onClick={handleSubmit(onSubmit)}
          loading={loading}
        >
          建立
        </Button>
      </div>
    </Drawer>
  );
};

DeviceCategoryDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};

const DeviceCategoryList = () => {
  const createDrawerDisclosure = useDisclosureParam();

  const {
    data: cateogryListData,
    loading: categoryListIsLoading,
    refetch,
  } = useQuery(equipmentCategoryList);

  const [deleteCategory, { loading: deleteCategoryLoading }] = useMutation(
    equipmentCategoryDelete
  );

  const [updateCategory, { loading: updateCategoryLoading }] = useMutation(
    equipmentCategoryUpdate
  );

  const isLoading =
    categoryListIsLoading || deleteCategoryLoading || updateCategoryLoading;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      category: {},
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      if (Object.entries(data?.category || {}).length === 0) return;
      const category = Object.entries(data.category).map(([id, values]) => ({
        id,
        ...values,
      }));
      await updateCategory({ variables: { input: category } });
      notification.success({
        message: "成功更新器材種類",
        duration: 5,
      });
      await refetch();
    },
    [refetch, updateCategory]
  );

  const onDelete = useCallback(
    async (id) => {
      await deleteCategory({
        variables: { EquipmentCategoryId: id },
      });
      await refetch();
    },
    [deleteCategory, refetch]
  );

  const columns = [
    {
      title: "器材種類",
      dataIndex: "name",
      width: "50%",
      render: (_, record) => {
        return (
          <Controller
            control={control}
            defaultValue={record.name}
            name={`category.[${record.id}].name`}
            rules={{ required: true }}
            render={(fields, { invalid }) => (
              <Form.Item
                className="table-form-item-label"
                validateStatus={invalid ? "error" : ""}
                help={invalid && "請輸入器材種類"}
              >
                <Input {...fields} />
              </Form.Item>
            )}
          />
        );
      },
    },
    {
      title: "正價（$）",
      dataIndex: "regularAmount",
      render: (_, record) => {
        return (
          <Controller
            control={control}
            defaultValue={record.regularAmount}
            name={`category.[${record.id}].regularAmount`}
            rules={{ required: true }}
            render={(fields, { invalid }) => (
              <Form.Item
                className="table-form-item-label"
                validateStatus={invalid ? "error" : ""}
                help={invalid && "請輸入正價（$）"}
              >
                <InputNumber
                  min={0}
                  {...fields}
                  value={fields.value / 100}
                  onChange={(value) => fields.onChange(Math.round(value * 100))}
                />
              </Form.Item>
            )}
          />
        );
      },
    },
    {
      title: "優惠價（$）",
      dataIndex: "discountedAmount",
      render: (_, record) => {
        return (
          <Controller
            control={control}
            defaultValue={record.discountedAmount}
            name={`category.[${record.id}].discountedAmount`}
            rules={{ required: true }}
            render={(fields, { invalid }) => (
              <Form.Item
                className="table-form-item-label"
                validateStatus={invalid ? "error" : ""}
                help={invalid && "請輸入優惠價（$）"}
              >
                <InputNumber
                  min={0}
                  {...fields}
                  value={fields.value / 100}
                  onChange={(value) => fields.onChange(Math.round(value * 100))}
                />
              </Form.Item>
            )}
          />
        );
      },
    },
    {
      render: (_, record) => {
        return (
          <Popconfirm
            title={`確定刪除 ${record?.name}？`}
            onConfirm={() => onDelete(record.id)}
            placement="left"
          >
            <Button className="btn btn-danger">刪除</Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <StickyBreadcrumbPageComponent
      className="device-category"
      breadcrumb={BREADCRUMB}
    >
      <DeviceCategoryDrawer
        visible={createDrawerDisclosure.isOpen}
        onClose={createDrawerDisclosure.onClose}
        onSave={() => refetch()}
      />
      <div className="content-inner">
        <div className="btn-container">
          <Button
            className="btn btn-secondary"
            onClick={createDrawerDisclosure.onOpen}
          >
            新增
          </Button>
          <Button
            className="btn"
            type="primary"
            htmlType="submit"
            onClick={handleSubmit(onSubmit)}
          >
            儲存
          </Button>
        </div>
        <Table
          loading={isLoading}
          rowKey="id"
          columns={columns}
          dataSource={
            cateogryListData?.equipmentCategoryList?.filter(
              (item) => item.isOther === false
            ) || []
          }
          pagination={false}
          scroll={{x: "max-content"}}
        />
      </div>
    </StickyBreadcrumbPageComponent>
  );
};

export default DeviceCategoryList;
