import React, { useCallback, useEffect } from "react";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Icon,
  Input,
  notification,
  Popconfirm,
  Select,
} from "antd";

import { Controller, useFieldArray, useForm } from "react-hook-form";

import { gql, useMutation } from "@apollo/client";

import PropTypes from "prop-types";
import { ImgUploader } from "../../../../utils/commonComponents";

import { v4 as uuid } from "uuid";

const cleaningJobEquipmentUpdate = gql`
  mutation cleaningJobEquipmentUpdate($input: EquipmentUpdateInput) {
    cleaningJobEquipmentUpdate(input: $input) {
      id
    }
  }
`;

const DeviceServiceHistory = ({ equipment, isLoading, refetch }) => {
  const [updateCleaningJobEquipment] = useMutation(cleaningJobEquipmentUpdate);

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      friendlyReminder: "",
      equipmentServiceItem: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "equipmentServiceItem",
  });

  const onSubmit = useCallback(
    async (data) => {
      const equipmentServiceItem = (data?.equipmentServiceItem || [])?.reduce(
        (acc, cur) => {
          const item = {
            ...cur,
            stage: cur.stage || null,
            image: cur.image.map((image) => ({
              key: image.key,
              size: image.size,
              name: image.name,
            })),
          };

          if (
            item?.displayToServiceRecord ||
            item?.remark ||
            item?.stage ||
            item?.image?.length > 0
          )
            acc.push(item);

          return acc;
        },
        []
      );

      try {
        await updateCleaningJobEquipment({
          variables: {
            input: {
              id: equipment?.id,
              ...data,
              equipmentServiceItem,
            },
          },
        });
        notification.success({
          message: "成功更新器材服務記錄",
          duration: 5,
        });

        refetch();
      } catch (err) {
        console.log(err);
      }
    },
    [equipment, refetch, updateCleaningJobEquipment]
  );

  useEffect(() => {
    if (equipment && !isLoading) {
      reset({
        friendlyReminder: equipment.friendlyReminder,
        equipmentServiceItem: equipment.equipmentServiceItem.map((item) => ({
          ...item,
          image: item.image.map((image) => ({
            ...image,
            uid: uuid(),
          })),
        })),
      });
    }
  }, [equipment, isLoading, reset]);

  return (
    <div>
      <Form layout="vertical">
        <div className="button-container">
          <Button
            type="primary"
            onClick={handleSubmit(onSubmit)}
            loading={isSubmitting}
          >
            儲存
          </Button>
        </div>

        <Form.Item label="溫馨提示">
          <Controller
            control={control}
            name="friendlyReminder"
            render={(fields) => <Input.TextArea {...fields} />}
          />
        </Form.Item>

        {fields.map((field, index) => {
          const displayToServiceRecord = watch(
            `equipmentServiceItem[${index}].displayToServiceRecord`
          );
          return (
            <Card
              key={field.id}
              title={`服務事項 (${index + 1})`}
              style={{ marginBottom: 32 }}
              extra={
                <Popconfirm
                  title="確定刪除此服務事項？"
                  onConfirm={() => remove(index)}
                  okType="danger"
                  placement="left"
                >
                  <Button type="danger">刪除</Button>
                </Popconfirm>
              }
            >
              <Form.Item label="圖片">
                <Controller
                  control={control}
                  name={`equipmentServiceItem[${index}].image`}
                  defaultValue={field.image}
                  render={(fields) => (
                    <ImgUploader listType="picture-card" {...fields}>
                      <div>
                        <Icon type={"plus"} />
                        <div className="ant-upload-text">上載</div>
                      </div>
                    </ImgUploader>
                  )}
                />
              </Form.Item>

              <Controller
                control={control}
                name={`equipmentServiceItem[${index}].stage`}
                defaultValue={field.stage}
                rules={{ required: !!displayToServiceRecord }}
                render={(fields, { invalid }) => (
                  <Form.Item
                    label="清洗階段"
                    required={!!displayToServiceRecord}
                    validateStatus={invalid ? "error" : undefined}
                    help={invalid && "請選擇清洗階段"}
                  >
                    <Select {...fields} allowClear>
                      <Select.Option value="beforeCleaning">
                        清洗前
                      </Select.Option>
                      <Select.Option value="duringCleaning">
                        清洗中
                      </Select.Option>
                      <Select.Option value="afterCleaning">
                        清洗後
                      </Select.Option>
                    </Select>
                  </Form.Item>
                )}
              />

              <Form.Item>
                <Controller
                  control={control}
                  name={`equipmentServiceItem[${index}].displayToServiceRecord`}
                  defaultValue={field.displayToServiceRecord}
                  render={(fields) => (
                    <Checkbox
                      checked={fields.value}
                      onChange={(e) => fields.onChange(e.target.checked)}
                    >
                      顯示於服務記錄
                    </Checkbox>
                  )}
                />
              </Form.Item>

              <Form.Item label="描述">
                <Controller
                  control={control}
                  name={`equipmentServiceItem[${index}].remark`}
                  defaultValue={field.remark}
                  render={(fields) => <Input.TextArea {...fields} />}
                />
              </Form.Item>
            </Card>
          );
        })}

        <Button
          onClick={() =>
            append({
              image: [],
              stage: undefined,
              displayToServiceRecord: false,
              remark: "",
            })
          }
        >
          新增服務事項
        </Button>
      </Form>
    </div>
  );
};

DeviceServiceHistory.propTypes = {
  equipment: PropTypes.object,
  isLoading: PropTypes.bool,
  refetch: PropTypes.func,
};

export default DeviceServiceHistory;
