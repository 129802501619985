/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import moment from "moment";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";

// Actions
import { setIsLoadingAction } from "@actions/storeAction";

// Components
import { Button, Card, Col, Row, Select, Spin, Tag } from "antd";
import Filter from "./filter/filter";

// Constants
import { MOMENT_FORMAT, PAGE, PAGE_NAME, PAGE_SIZE } from "@utils/constants";

// Layouts
import TableView from "@layouts/tableView/tableView";

// Styles
import "./dashboard.scss";

// Utils
import { replaceUrlByParams } from "@utils/api";
import { _uuid } from "@utils/common";

import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";

const WAREHOUSE_LIST = gql`
  query warehouseList {
    warehouseList {
      id
      name
      storeLocationCode
    }
  }
`;

const INVENTORY_DASHBOARD = gql`
  query inventoryRecordDashboardGet($warehouseId: ID) {
    inventoryRecordDashboardGet(warehouseId: $warehouseId) {
      cleaning
      cleaned
      repairing
      inspected
      readyForLease
    }
  }
`;

const INVENTORY_RECORD_LIST = gql`
  query InventoryRecordList(
    $categoryId: [ID]
    $brand: [String]
    $dimension: [String]
    $characteristicTag: [String]
    $inventoryStatus: [InventoryStatus]
    $deliveryStatus: [DeliveryStatus]
    $inspectionResult: [InspectionResult]
    $keyword: String
    $size: Int!
    $offset: Int!
    $reverseOrder: Boolean
    $sort: SortInventoryRecordList
    $storeLocationList: [String]
  ) {
    inventoryRecordList(
      categoryId: $categoryId
      brand: $brand
      dimension: $dimension
      characteristicTag: $characteristicTag
      inventoryStatus: $inventoryStatus
      deliveryStatus: $deliveryStatus
      inspectionResult: $inspectionResult
      keyword: $keyword
      size: $size
      offset: $offset
      reverseOrder: $reverseOrder
      sort: $sort
      storeLocationList: $storeLocationList
    ) {
      id
      referenceId
      status {
        id
      }
      inventoryInspection {
        inspectionResult {
          id
        }
      }
      activeDelivery {
        deliveryStatus {
          id
        }
      }
      product {
        __typename
        id
        referenceId
        ... on Device {
          productInfo {
            modelNumber
            nameChi
          }
        }
        ... on Part {
          productInfo {
            modelNumber
            nameChi
          }
        }
        ... on Consumable {
          productInfo {
            modelNumber
            nameChi
          }
        }
      }
    }
  }
`;

const INVENTORY_RECORD_COUNT = gql`
  query InventoryRecordCount(
    $categoryId: [ID]
    $brand: [String]
    $dimension: [String]
    $characteristicTag: [String]
    $inventoryStatus: [InventoryStatus]
    $deliveryStatus: [DeliveryStatus]
    $inspectionResult: [InspectionResult]
    $keyword: String
    $storeLocationList: [String]
  ) {
    inventoryRecordCount(
      categoryId: $categoryId
      brand: $brand
      dimension: $dimension
      characteristicTag: $characteristicTag
      inventoryStatus: $inventoryStatus
      deliveryStatus: $deliveryStatus
      inspectionResult: $inspectionResult
      keyword: $keyword
      storeLocationList: $storeLocationList
    )
  }
`;

function Dashboard(props) {
  const cacheSessionUUID = useRef(_uuid()).current;
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = props;

  const [query, setQuery] = useState(queryString.parse(location.search));

  useEffect(() => {
    setQuery(queryString.parse(location.search));
  }, [location.search]);

  const BREADCRUMB = [
    {
      path: PAGE.WORKSHOP_DASHBOARD,
      name: PAGE_NAME.WORKSHOP_DASHBOARD,
    },
  ];

  const columns = [
    {
      title: "器材編號",
      dataIndex: "referenceId",
      sorter: true,
      render: (text, record) => {
        /* TODO Update page constant */
        const redPoint = Object.values(record?.redDot ?? {}).reduce(
          (acc, flag) => {
            if (typeof flag === "boolean") return acc || flag;
            return acc;
          },
          false
        );
        return (
          <>
            {redPoint && <span className="redPoint"></span>}
            <Link
              className="link"
              to={replaceUrlByParams(PAGE.STOCK_INVENTORY_DETAIL, {
                inventoryId: record.id,
              })}
            >
              {text}
            </Link>
          </>
        );
      },
    },
    {
      title: "款號",
      dataIndex: "model",
      render: (text, record) =>
        /* TODO Update page constant */
        record.product.referenceId,
    },
    {
      title: "型號",
      dataIndex: "product.productInfo.modelNumber",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "名稱",
      dataIndex: "product.productInfo.nameChi",
    },
    {
      title: "器材狀態",
      dataIndex: "status.id",
      render: (text) =>
        ({
          inactive: <Tag className="tag">尚未上架</Tag>,
          reserved: <Tag className="tag warning">已預留</Tag>,
          readyForLease: <Tag className="tag warning">可租用</Tag>,
          leasing: <Tag className="tag success">已租用</Tag>,
          cleaning: <Tag className="tag warning">清潔中</Tag>,
          cleaned: <Tag className="tag warning">已清潔</Tag>,
          repairing: <Tag className="tag warning">檢查/維修中</Tag>,
          inspected: <Tag className="tag warning">已檢查/維修</Tag>,
          toBeDisposed: <Tag className="tag warning">落架中</Tag>,
          terminated: <Tag className="tag danger">已落架</Tag>,
          damaged: <Tag className="tag danger">已損毀</Tag>,
        }[text]),
    },
    {
      title: "檢查/維修記錄狀態",
      dataIndex: "inventoryInspection[0].inspectionResult.id",
      render: (text) =>
        text
          ? {
              packed: <Tag className="tag success">已包裝</Tag>,
              pending: <Tag className="tag warning">待維修</Tag>,
              repairing: <Tag className="tag warning">維修中</Tag>,
              normal: <Tag className="tag success">正常</Tag>,
              repaired: <Tag className="tag success">已維修</Tag>,
              unableToRepair: <Tag className="tag danger">未能維修</Tag>,
            }[text]
          : "-",
    },
    {
      title: "運送工作狀態",
      dataIndex: "activeDelivery.deliveryStatus.id",
      render: (text) =>
        text
          ? {
              pending: <Tag className="tag">待命</Tag>,
              confirmedDelivery: <Tag className="tag warning">已確認</Tag>,
              inventoryReady: <Tag className="tag warning">已備貨</Tag>,
              checkedOut: <Tag className="tag warning">已發貨</Tag>,
              completed: <Tag className="tag success">已完成</Tag>,
              cancelled: <Tag className="tag danger">已取消</Tag>,
            }[text]
          : "-",
    },
  ];

  const [
    getInventoryList,
    { loading: inventoryListLoading, data: inventoryListData },
  ] = useLazyQuery(INVENTORY_RECORD_LIST, {
    context: {
      headers: {
        "x-cache-session": cacheSessionUUID,
      },
    },
  });

  const [
    getInventoryCount,
    { loading: inventoryCountLoading, data: inventoryCountData },
  ] = useLazyQuery(INVENTORY_RECORD_COUNT, {
    context: {
      headers: {
        "x-cache-session": cacheSessionUUID,
      },
    },
  });

  const { loading: getWarehouseListLoading, data: getWarehouseListData } =
    useQuery(WAREHOUSE_LIST);

  const [
    getInventoryDashboard,
    { loading: getInventoryDashboardLoading, data: getInventoryDashboardData },
  ] = useLazyQuery(INVENTORY_DASHBOARD);

  const { control, watch, reset, register } = useForm({
    defaultValues: {
      storeLocationList: query.storeLocationList
        ? query.storeLocationList
        : undefined,
    },
  });

  const FormValue = watch();

  useEffect(() => {
    if (getWarehouseListData) {
      const warehouseId = getWarehouseListData.warehouseList.find(
        (item) => item.storeLocationCode === FormValue.storeLocationList
      )?.id;
      getInventoryDashboard({ variables: { warehouseId: warehouseId } });
    }
  }, [
    FormValue.storeLocationList,
    getWarehouseListData,
    getInventoryDashboard,
  ]);

  useEffect(() => {
    if (FormValue.storeLocationList !== query.storeLocationList)
      history.replace({
        search: queryString.stringify({
          ...query,
          page: 1,
          pageSize: PAGE_SIZE,
          storeLocationList: FormValue.storeLocationList
            ? [FormValue.storeLocationList]
            : undefined,
        }),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FormValue.storeLocationList]);

  // fetch PAGE_SIZE + 1, to check if there is next page.
  const inventoryList = useMemo(() => {
    return (inventoryListData?.inventoryRecordList || []).slice(0, PAGE_SIZE);
  }, [inventoryListData]);

  // const isMaxPage = useMemo(() => {
  //   const queryParams = queryString.parse(location.search);
  //   return (
  //     queryParams.pageSize >= (inventoryCountData?.inventoryRecordCount || 0)
  //   );
  // }, [inventoryCountData?.inventoryRecordCount, location.search]);

  const [isFilterShow, setIsFilterShow] = useState(false);

  useEffect(() => {
    const {
      page,
      pageSize,
      keyword,
      sortField,
      sortOrder,
      inventoryStatus,
      deliveryStatus,
      inspectionResult,
      storeLocationList,
    } = queryString.parse(location.search);

    const queryParamToArray = (x) => (x ? (!Array.isArray(x) ? [x] : x) : []);
    reset({ storeLocationList: storeLocationList });
    getInventoryList({
      variables: {
        offset: +((page - 1) * pageSize) || 0,
        size: +(pageSize || PAGE_SIZE) + 1,
        keyword: keyword,
        sort: sortField,
        inventoryStatus: queryParamToArray(inventoryStatus),
        deliveryStatus: queryParamToArray(deliveryStatus),
        inspectionResult: queryParamToArray(inspectionResult),
        reverseOrder: sortOrder ? sortOrder === "descend" : undefined,
        storeLocationList: storeLocationList,
      },
    });
    getInventoryCount({
      variables: {
        keyword: keyword,
        inventoryStatus: queryParamToArray(inventoryStatus),
        deliveryStatus: queryParamToArray(deliveryStatus),
        inspectionResult: queryParamToArray(inspectionResult),
        reverseOrder: sortOrder ? sortOrder === "descend" : undefined,
        storeLocationList: storeLocationList,
      },
    });
  }, [getInventoryCount, getInventoryList, location.search, reset]);

  const onFilterSearch = () => {
    history.push({
      search: queryString.stringify(query),
    });
  };

  const onFilterClick = (state) => {
    setIsFilterShow(state);
  };

  const onClickFilterStatus = (inventoryStatus) => {
    history.push({
      search: queryString.stringify({
        ...query,
        inventoryStatus: [inventoryStatus],
      }),
    });
  };

  const renderDashboard = (
    <div>
      <Row gutter={[16, 32]}>
        <Col>
          <Controller
            name="storeLocationList"
            control={control}
            as={
              <Select
                loading={getWarehouseListLoading}
                style={{ width: "160px" }}
                placeholder="請選擇工場名稱"
                allowClear
              >
                {getWarehouseListData &&
                  getWarehouseListData?.warehouseList?.map((item) => (
                    <Select.Option key={item.id} value={item.storeLocationCode}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            }
          />
        </Col>
      </Row>
      <Row gutter={[16, 32]}>
        <Spin spinning={getInventoryDashboardLoading}>
          <div className="dashboard-container">
            <Col span={4}>
              <div className="dashboard-item">
                <div className="dashboard-item-title">清潔中</div>
                <div className="dashboard-item-description">
                  <Button
                    type="link"
                    onClick={() => onClickFilterStatus("cleaning")}
                  >
                    {
                      getInventoryDashboardData?.inventoryRecordDashboardGet
                        ?.cleaning
                    }
                  </Button>
                </div>
              </div>
            </Col>
            <Col span={4}>
              <div className="dashboard-item">
                <div className="dashboard-item-title">已清潔</div>
                <div className="dashboard-item-description">
                  <Button
                    type="link"
                    onClick={() => onClickFilterStatus("cleaned")}
                  >
                    {
                      getInventoryDashboardData?.inventoryRecordDashboardGet
                        ?.cleaned
                    }
                  </Button>
                </div>
              </div>
            </Col>
            <Col span={4}>
              <div className="dashboard-item">
                <div className="dashboard-item-title">檢查/維修中</div>
                <div className="dashboard-item-description">
                  <Button
                    type="link"
                    onClick={() => onClickFilterStatus("repairing")}
                  >
                    {
                      getInventoryDashboardData?.inventoryRecordDashboardGet
                        ?.repairing
                    }
                  </Button>
                </div>
              </div>
            </Col>
            <Col span={4}>
              <div className="dashboard-item">
                <div className="dashboard-item-title">已檢查/維修</div>
                <div className="dashboard-item-description">
                  <Button
                    type="link"
                    onClick={() => onClickFilterStatus("inspected")}
                  >
                    {
                      getInventoryDashboardData?.inventoryRecordDashboardGet
                        ?.inspected
                    }
                  </Button>
                </div>
              </div>
            </Col>
            <Col span={4}>
              <div className="dashboard-item">
                <div className="dashboard-item-title">存倉中(可租用)</div>
                <div className="dashboard-item-description">
                  <Button
                    type="link"
                    onClick={() => onClickFilterStatus("readyForLease")}
                  >
                    {
                      getInventoryDashboardData?.inventoryRecordDashboardGet
                        ?.readyForLease
                    }
                  </Button>
                </div>
              </div>
            </Col>
          </div>
        </Spin>
      </Row>
    </div>
  );

  return (
    <TableView
      {...props}
      breadcrumb={BREADCRUMB}
      className="Dashboard-page"
      rowKey="id"
      columns={columns}
      hasFilter
      isFilterShow={isFilterShow}
      // isMaxPage={isMaxPage}
      total={inventoryCountData?.inventoryRecordCount || 0}
      isTableLoading={inventoryListLoading}
      onFilterClick={onFilterClick}
      searchBarPlaceholder="器材編號/型號搜尋"
      tableData={inventoryList}
      extraChildren={renderDashboard}
    >
      <Filter
        // filterList={[
        //   "status",
        // ]}
        filterType={[""]}
        filterValue={query}
        onFilterSearch={onFilterSearch}
        onResetFilter={() => {
          history.push({
            search: queryString.stringify({
              page: 1,
              pageSize: PAGE_SIZE,
              storeLocationList: [FormValue?.storeLocationList],
            }),
          });
        }}
        isFilterShow={isFilterShow}
        onFilterValueChange={setQuery}
      />
    </TableView>
  );
}

export default Dashboard;

Dashboard.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
