import React, { useEffect, useCallback } from "react";
import { useMemo } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

// Constants
import { PAGE, PAGE_NAME, PAGE_SIZE, ACCESS_LEVELS } from "@utils/constants";

// Layouts
import TableView from "@layouts/tableView/tableView";

// Styles
import "./InspectionChecklist.scss";

// Utils
import { MOMENT_DATETIME_FORMAT } from "@utils/constants";
import { replaceUrlByParams } from "@utils/api";
import { Button, Dropdown, Icon, Menu, Modal, notification } from "antd";
import { useAccessLevel } from "@utils/hook";

const INSPECTION_LIST = gql`
  query inspectionList($keyword: String, $size: Int, $offset: Int) {
    inspectionList(keyword: $keyword, size: $size, offset: $offset) {
      id
      name
      remark
      trace {
        createDate
        lastModifiedDate
      }
    }
  }
`;

const INSPECTION_DELETE = gql`
  mutation inspectionDelete($id: ID) {
    inspectionDelete(id: $id)
  }
`;

const INSPECTION_COUNT = gql`
  query inspectionCount($keyword: String) {
    inspectionCount(keyword: $keyword)
  }
`;

function WorkshopInspectionChecklist(props) {
  const history = useHistory();
  const { location } = props;

  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.WORKSHOP_MANAGEMENT,
  ]);

  const BREADCRUMB = [
    {
      path: PAGE.WORKSHOP_INSPECTION_CHECKLIST,
      name: PAGE_NAME.WORKSHOP_INSPECTION_CHECKLIST,
    },
  ];

  const [
    getInspectionList,
    { loading: inspectionListLoading, data: inspectionListData },
  ] = useLazyQuery(INSPECTION_LIST);

  const [inspectionDelete, { data: inspectionDeleteData }] =
    useMutation(INSPECTION_DELETE);

  const [
    getInspectionCount,
    {
      // loading: inspectionCountLoading,
      data: inspectionCountData,
    },
  ] = useLazyQuery(INSPECTION_COUNT);

  const columns = [
    {
      title: "記錄名稱",
      width: "25%",
      dataIndex: "name",
      sorter: false,
      render: (text, record) => (
        <Link
          className="link"
          to={replaceUrlByParams(PAGE.WORKSHOP_INSPECTION_EDIT, {
            id: record.id,
          })}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "備註",
      width: "30%",
      dataIndex: "remark",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "新增日期",
      dataIndex: "trace.createDate",
      render: (text) => (
        <span>{moment(text).format(MOMENT_DATETIME_FORMAT)}</span>
      ),
    },
    {
      title: "最後更新日期",
      dataIndex: "trace.lastModifiedDate",
      render: (text) => (
        <span>{moment(text).format(MOMENT_DATETIME_FORMAT)}</span>
      ),
    },
    {
      dataIndex: "action",
      render: (text, inspection) => (
        <Dropdown overlay={() => menu(inspection)}>
          <Button className="btn btn-secondary">
            <Icon type="ellipsis" />
          </Button>
        </Dropdown>
      ),
    },
  ];

  // fetch PAGE_SIZE + 1, to check if there is next page.
  const inspectionList = useMemo(() => {
    return (inspectionListData?.inspectionList || []).slice(0, PAGE_SIZE);
  }, [inspectionListData]);

  const isMaxPage = useMemo(() => {
    const queryParams = queryString.parse(location.search);
    return (
      queryParams.pageSize >= (inspectionListData?.inspectionList || []).length
    );
  }, [inspectionListData, location.search]);

  const refreshInspectionList = useCallback(() => {
    const { page, pageSize, keyword } = queryString.parse(location.search);

    getInspectionList({
      variables: {
        keyword: keyword,
        size: +(pageSize || PAGE_SIZE) + 1,
        offset: +((page - 1) * pageSize) || 0,
        // sort: sortField,
        // reverseOrder: sortOrder,
      },
    });

    getInspectionCount({
      variables: {
        keyword: keyword,
      },
    });
  }, [getInspectionCount, getInspectionList, location.search]);

  useEffect(() => {
    refreshInspectionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const onInspectionDelete = useCallback(
    (inspection) => {
      try {
        inspectionDelete({ variables: { id: inspection.id } });
      } catch (error) {
        notification.error(error.message);
      }
    },
    [inspectionDelete]
  );

  useEffect(() => {
    if (inspectionDeleteData?.inspectionDelete) {
      notification.success({ message: "刪除成功", duration: 2 });
      refreshInspectionList();
    }
  }, [inspectionDeleteData, refreshInspectionList]);

  const onMenuItemClick = ({ key, inspection }) => {
    switch (key) {
      case "edit": {
        history.push(
          replaceUrlByParams(PAGE.WORKSHOP_INSPECTION_EDIT, {
            id: inspection.id,
          })
        );
        break;
      }
      case "remove": {
        Modal.confirm({
          title: `刪除檢查貨物記錄表`,
          content: `確定刪除檢查貨物記錄表 ${inspection?.name} ?`,
          okText: "確定",
          cancelText: "取消",
          onOk() {
            onInspectionDelete(inspection);
          },
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  const menu = (inspection) => (
    <Menu
      onClick={({ key }) => {
        onMenuItemClick({ key, inspection: inspection });
      }}
    >
      <Menu.Item key="edit">編輯記錄表</Menu.Item>
      {!isDenied && <Menu.Item key="remove">刪除記錄表</Menu.Item>}
    </Menu>
  );

  return (
    <>
      <TableView
        {...props}
        addBtnConfig={{
          url: PAGE.WORKSHOP_INSPECTION_CREATE,
          text: "新增",
        }}
        breadcrumb={BREADCRUMB}
        rowKey="id"
        columns={columns}
        {...(!isDenied && { hasAddBtn: true })}
        isMaxPage={isMaxPage}
        isTableLoading={inspectionListLoading}
        searchBarPlaceholder="搜尋檢查貨物記錄表"
        tableData={inspectionList}
        total={inspectionCountData?.inspectionCount}
      ></TableView>
    </>
  );
}

export default WorkshopInspectionChecklist;

WorkshopInspectionChecklist.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
