import React from "react";
import PropTypes from "prop-types";

// Styles
import "./_categoryFilter.scss";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { useMemo } from "react";

import { TreeSelect } from "antd";

const API = gql`
  query {
    enumATCategoryList {
      id
      name
    }
    mainCategoryList {
      id
      name
      atCategory {
        id
        name
      }
      category {
        id
        name
      }
    }
  }
`;

const CategoryFilter = ({ value: categoryId, onChange, disabled }) => {
  const { data } = useQuery(API);

  const categoryTreeData = useMemo(() => {
    const mainCategories = data?.mainCategoryList || [];
    const enumATCategoryList = data?.enumATCategoryList || [];
    if (mainCategories) {
      return enumATCategoryList.map(({ id: value, name: title }) => ({
        value,
        title,
        key: value,
        selectable: false,
        children: mainCategories
          .filter((mainCategory) => mainCategory.atCategory.id === value)
          .map(
            ({ category: categories = [], name, ...restOfMainCategory }) => ({
              ...restOfMainCategory,
              title: name,
              value: restOfMainCategory.id,
              key: restOfMainCategory.id,
              selectable: false,
              children: categories.map(({ name, ...restOfCategory }) => ({
                ...restOfCategory,
                title: name,
                value: restOfCategory.id,
                key: restOfCategory.id,
                selectable: true,
              })),
            })
          ),
      }));
    }
    return [];
  }, [data]);

  return (
    <div>
      <TreeSelect
        disabled={disabled}
        showSearch
        treeData={categoryTreeData}
        treeNodeFilterProp="title"
        value={categoryId}
        onChange={onChange}
        // style={{ width: 300 }}
      />
    </div>
  );
};

CategoryFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CategoryFilter.defaultProps = {
  disabled: false,
};

export default CategoryFilter;
