/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import { useParams, useHistory, Link } from "react-router-dom";
import axios from "axios";
import {
  Card,
  Tabs,
  Form,
  Descriptions,
  Tag,
  Typography,
  Tooltip,
  Col,
  Row,
  Input,
  Button,
  Divider,
  Table,
  Icon,
  Select,
  Upload,
  notification,
  Modal,
} from "antd";
import moment from "moment";
import { gql } from "@apollo/client";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

// Constants
import { PAGE, PAGE_NAME, ACCESS_LEVELS } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

// Utils
import { StatusTag } from "@utils/commonComponents";
import { getTokenHeader, replaceUrlByParams } from "@utils/api";
import { getDeviceInventoryUploadUrl } from "@utils/env";
import { amountDisplay } from "@utils/common";
import { pricesList } from "@utils/common";
import { _uuid } from "@utils/common";
import { useAccessLevel } from "@utils/hook";

// Components
import CreateInventoryDrawer from "@components/createInventoryDrawer/createInventoryDrawer";
import CreatePurchaseInfoDrawer from "@components/createPurchaseInfoDrawer/createPurchaseInfoDrawer";
import ActivityHistoryComponent from "@components/activityHistory/activityHistory";
import CreatePriceDrawer from "@components/createPriceDrawer/createPriceDrawer";
import CustPaginationComponent from "@components/custPagination/custPagination";

//Styles
import "./deviceDetail.scss";
import FormItem from "antd/lib/form/FormItem";

const { TabPane } = Tabs;

const API = gql`
  query EnumList {
    warehouseList {
      id
      name
      storeLocationCode
    }
    enumInventoryStatusList {
      id
      name
    }
  }
`;

const INVENTORY_RECORD_LIST = gql`
  query InventoryRecordList(
    $id: String!
    $size: Int!
    $offset: Int!
    $status: [InventoryStatus]
    $keyword: String
    $storeLocationList: [String]
    $sort: SortInventoryRecordList
  ) {
    inventoryRecordListOnDevice(
      deviceId: $id
      size: $size
      offset: $offset
      status: $status
      keyword: $keyword
      storeLocationList: $storeLocationList
      sort: $sort
    ) {
      id
      referenceId
      serialNumber
      status {
        id
        name
      }
      activeDate
      firstStorageDate
      warehouse {
        id
        name
        storeLocationCode
      }
      purchaseInfo {
        id
        referenceId
      }
      createDate
      displayItem
    }
  }
`;

const PURCHASE_INFO_LIST = gql`
  query PurcahseInfoList($id: ID!, $size: Int!, $offset: Int!) {
    deviceGet(id: $id) {
      purchaseInfo(size: $size, offset: $offset) {
        id
        referenceId
        quantity
        distributor
        distributorContact
        warranty
        funder
        purchaseDate
        purchasePrice
        document {
          key
          url
          size
          name
        }
      }
    }
  }
`;

const PRICE_LIST = gql`
  query PriceList($id: ID!) {
    deviceGet(id: $id) {
      itemPrice {
        id
        referenceId
        monthlyRentalPrice
        depositPrice
        serviceFee
        activeDate
        lastModifiedDate
        responsible {
          tbId
        }
      }
    }
  }
`;

const DEVICE_GET = gql`
  query DeviceGet($id: ID!) {
    deviceGet(id: $id) {
      id
      referenceId
      productInfo {
        nameEng
        nameChi
        modelNumber
        thumbnail {
          key
          url
        }
        dimension
        brand
        netWeight
        totalWeight
        colour
        specification
        characteristicTag
        manufacturer
      }
      quantity
      leasableQuantity
      category {
        id
        name
      }
      defaultPart {
        part {
          id
          referenceId
          productInfo {
            nameEng
            nameChi
          }
        }
        quantity
      }
      optionalPart {
        part {
          id
          referenceId
          productInfo {
            nameEng
            nameChi
          }
        }
        quantity
      }
      defaultConsumable {
        consumable {
          id
          referenceId
          productInfo {
            nameEng
            nameChi
          }
        }
        quantity
      }
    }
  }
`;

const INVENTORY_RECORD_TEMPLATE_GET = gql`
  query inventoryRecordTemplateFileDownload {
    inventoryRecordTemplateFileDownload {
      key
      url
      name
      size
    }
  }
`;

const INVENTORY_RECORD_DELETE_ON_DEVICE = gql`
  mutation inventoryRecordDeleteOnDevice($id: ID) {
    inventoryRecordDeleteOnDevice(id: $id)
  }
`;

const PAGE_SIZE = 10;

const DeviceDetail = () => {
  const cacheSessionUUID = useRef(_uuid()).current;

  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [selectedInventory, setSelectedInventory] = useState([]);
  const [keyword, setKeyword] = useState("");
  const { isMatched: addPriceIsDenied } = useAccessLevel([
    ACCESS_LEVELS.ADMINISTRATION,
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.CASE_MANAGEMENT,
    ACCESS_LEVELS.OPERATION_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.CLERICAL_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.FINANCIAL_MANAGEMENT,
    ACCESS_LEVELS.SERVICE_TEAM,
    ACCESS_LEVELS.ADMIN_EQUIPMENT_MANAGEMENT,
  ]);

  const { isMatched: addPriceIsAllowed } = useAccessLevel([
    ACCESS_LEVELS.SUPERADMIN_EQUIPMENT_MANAGEMENT,
  ]);

  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.CASE_MANAGEMENT,
    ACCESS_LEVELS.FINANCIAL_MANAGEMENT,
    ACCESS_LEVELS.SERVICE_TEAM,
  ]);

  const { data: enumList } = useQuery(API);

  console.log("enumList", enumList);

  const query = useMemo(() => {
    const { page, pageSize } = queryString.parse(location.search);
    return {
      ...queryString.parse(location.search),
      page: page ? Number(page) : undefined,
      // pageSize: pageSize ? Number(pageSize) : PAGE_SIZE,
      pageSize: PAGE_SIZE,
    };
  }, [location.search]);

  const isQueryReady = !!query?.page && !!query?.pageSize;

  const [localQuery, setLocalQuery] = useState(query);

  useEffect(() => {
    setLocalQuery(query);
  }, [query]);

  const onSearch = useCallback(() => {
    const query = {
      tabKey: localQuery.tabKey,
      page: localQuery.page,
      pageSize: localQuery.pageSize,
    };

    localQuery.storeLocationList &&
      (query.storeLocationList = localQuery.storeLocationList);
    localQuery.status && (query.status = localQuery.status);

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(query),
    });
  }, [history, localQuery, location.pathname]);

  const [inventoryDrawerVisible, setInventoryDrawerVisible] = useState(false);
  const [inventoryDrawerParams, setInventoryDrawerParams] = useState(undefined);

  const [purchaseInfoDrawerVisible, setPurchaseInfoDrawerVisible] = useState(
    false
  );
  const [purchaseInfoDrawerParams, setPurchaseInfoDrawerParams] = useState(
    undefined
  );

  const [priceDrawerVisible, setPriceDrawerVisible] = useState(false);
  const [priceDrawerParams, setPriceDrawerParams] = useState(undefined);

  const [filterVisible, setFilterVisible] = useState(false);

  const [isUploading, setIsUploading] = useState(false);

  const [deleteInventoryParams, setDeleteInventoryParams] = useState({
    visible: false,
  });

  const [
    getDevice,
    { data: getDeviceData, loading: getDeviceLoading, error: getDeviceError },
  ] = useLazyQuery(DEVICE_GET);
  const [
    getInventoryRecord,
    {
      data: getInventoryRecordData,
      loading: getInventoryRecordLoading,
      error: getInventoryRecordError,
    },
  ] = useLazyQuery(INVENTORY_RECORD_LIST, {
    context: {
      headers: {
        "x-cache-session": cacheSessionUUID,
      },
    },
  });
  const [
    getPurchaseInfoList,
    {
      data: getPurchaseInfoListData,
      loading: getPurchaseInfoListLoading,
      error: getPurchaseInfoListError,
    },
  ] = useLazyQuery(PURCHASE_INFO_LIST);
  const [
    getPriceList,
    {
      data: getPriceListData,
      loading: getPriceListLoading,
      error: getPriceListError,
    },
  ] = useLazyQuery(PRICE_LIST);

  const [inventoryDelete] = useMutation(INVENTORY_RECORD_DELETE_ON_DEVICE);

  console.log("getInventoryRecordData", getInventoryRecordData);

  const device = useMemo(() => {
    return getDeviceData?.deviceGet;
  }, [getDeviceData]);

  const purchaseInfo = useMemo(() => {
    return (getPurchaseInfoListData?.deviceGet?.purchaseInfo ?? []).slice(
      0,
      query.pageSize
    );
  }, [getPurchaseInfoListData, query.pageSize]);

  const inventoryRecords = useMemo(() => {
    return (getInventoryRecordData?.inventoryRecordListOnDevice ?? []).slice(
      0,
      query.pageSize
    );
  }, [getInventoryRecordData, query.pageSize]);

  const activityHistory = useMemo(() => {
    return device?.trace?.activityHistory ?? [];
  }, [device]);

  const prices = useMemo(() => {
    return pricesList(getPriceListData?.deviceGet?.itemPrice ?? []);
  }, [getPriceListData]);

  const isMaxPage = useMemo(() => {
    switch (query.tabKey) {
      case "purchaseInfo":
        return purchaseInfo.length < query.pageSize;
      case "quantityInfo":
        return inventoryRecords.length < query.pageSize;
      case "priceList":
        return prices.length < query.pageSize;
      default:
        return false;
    }
  }, [
    inventoryRecords.length,
    purchaseInfo.length,
    prices.length,
    query.pageSize,
    query.tabKey,
  ]);

  const refreshInventoryRecordList = useCallback(() => {
    getInventoryRecord({
      variables: {
        id,
        size: query.pageSize + 1 || 11,
        offset: (query.page - 1) * query.pageSize || 0,
        status: query.status,
        storeLocationList: query.storeLocationList,
        sort: "referenceId",
      },
    });
  }, [
    getInventoryRecord,
    id,
    query.page,
    query.pageSize,
    query.status,
    query.storeLocationList,
  ]);

  const refreshPurchaseInfoList = useCallback(() => {
    getPurchaseInfoList({
      variables: {
        id,
        size: query.pageSize + 1,
        offset: (query.page - 1) * query.pageSize,
      },
    });
  }, [getPurchaseInfoList, id, query.page, query.pageSize]);

  const refreshPriceList = useCallback(() => {
    getPriceList({
      variables: {
        id,
      },
    });
  }, [getPriceList, id]);

  useEffect(() => {
    if (id && isQueryReady) {
      switch (query.tabKey) {
        case "purchaseInfo":
          getPurchaseInfoList({
            variables: {
              id,
              size: query.pageSize + 1,
              offset: (query.page - 1) * query.pageSize,
            },
          });
          break;
        case "quantityInfo":
          getInventoryRecord({
            variables: {
              id,
              size: query.pageSize + 1,
              offset: (query.page - 1) * query.pageSize,
              status: query.status,
              keyword: keyword,
              storeLocationList: query.storeLocationList,
            },
          });
          break;
        case "priceList":
          getPriceList({
            variables: {
              id,
            },
          });
          break;
        default:
      }
    }
  }, [
    getInventoryRecord,
    getPriceList,
    getPurchaseInfoList,
    id,
    isQueryReady,
    keyword,
    query.page,
    query.pageSize,
    query.status,
    query.storeLocationList,
    query.tabKey,
  ]);

  useEffect(() => {
    if (id) {
      getDevice({
        variables: {
          id,
        },
      });
    }
  }, [getDevice, id]);

  const unfilledText = <Typography.Text disabled>(未填寫)</Typography.Text>;

  const inventoryInfoColumns = [
    {
      title: "器材編號",
      dataIndex: "referenceId",
      render: (referenceId, { id }) => {
        return (
          <Link
            className="link"
            to={replaceUrlByParams(PAGE.STOCK_INVENTORY_DETAIL, {
              inventoryId: id,
            })}
          >
            {referenceId}
          </Link>
        );
      },
    },
    {
      title: "序列號",
      dataIndex: "serialNumber",
    },
    {
      title: "工場",
      dataIndex: "warehouse.name",
      render: (text) => text || "-",
    },
    {
      title: "狀態",
      dataIndex: "status",
      render: (status) => <StatusTag status={status} />,
    },
    // {
    // 	title: "訂單編號",
    // 	dataIndex: "orderNo",
    // },
    {
      title: "貨物新增日期",
      dataIndex: "createDate",
      render: (createDate) =>
        createDate ? moment(createDate).format("YYYY-MM-DD") : "-",
    },
    {
      title: "首次入倉日期",
      dataIndex: "firstStorageDate",
      render: (firstStorageDate) =>
        firstStorageDate ? moment(firstStorageDate).format("YYYY-MM-DD") : "-",
    },
    {
      title: "上架日期",
      dataIndex: "activeDate",
      render: (activeDate) =>
        activeDate ? moment(activeDate).format("YYYY-MM-DD") : "-",
    },
    {
      title: "採購單號",
      dataIndex: "purchaseInfo.referenceId",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "",
      render: (record) => {
        return (
          !isDenied && (
            <Button
              onClick={() => {
                setInventoryDrawerVisible(true);
                setInventoryDrawerParams({
                  productId: id,
                  ...record,
                });
              }}
              icon="edit"
            ></Button>
          )
        );
      },
    },
    {
      title: "",
      render: (record) => {
        return !isDenied && record?.status?.id === "inactive" ? (
          <Button
            type="danger"
            onClick={() => setDeleteInventoryParams({ visible: true, record })}
          >
            刪除
          </Button>
        ) : null;
      },
    },
  ];

  const purchaseInfoColumns = [
    {
      title: "採購單號",
      dataIndex: "referenceId",
    },
    {
      title: "保養年期",
      dataIndex: "warranty",
    },
    {
      title: "分銷商",
      dataIndex: "distributor",
    },
    {
      title: "分銷商電話",
      dataIndex: "distributorContact",
    },
    {
      title: "出資者",
      dataIndex: "funder",
    },
    {
      title: "購買日期",
      dataIndex: "purchaseDate",
      render: (purchaseDate) =>
        purchaseDate ? moment(purchaseDate).format("YYYY-MM-DD") : "-",
    },
    {
      title: "價格",
      dataIndex: "purchasePrice",
    },
    {
      title: "數量",
      dataIndex: "quantity",
    },
    {
      title: "收據",
      dataIndex: "document",
      render: (doc) =>
        doc &&
        doc[0]?.url && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={doc[0]?.url}
            onClick={(e) => e.stopPropagation()}
          >
            查看
          </a>
        ),
    },
  ];

  const priceInfoColumns = [
    {
      title: "生效日期",
      dataIndex: "activeDate",
      render: (activeDate) => (activeDate ? activeDate : "-"),
    },
    {
      title: "月租價格",
      dataIndex: "monthlyRentalPrice",
      render: (amount) => <span>{amountDisplay(amount)}</span>,
    },
    {
      title: "服務成本",
      dataIndex: "serviceFee",
      render: (amount) => <span>{amountDisplay(amount)}</span>,
    },
    {
      title: "按金價格",
      dataIndex: "depositPrice",
      render: (amount) => <span>{amountDisplay(amount)}</span>,
    },
    {
      title: "更新日期",
      dataIndex: "lastModifiedDate",
      render: (lastModifiedDate) =>
        lastModifiedDate ? moment(lastModifiedDate).format("YYYY-MM-DD") : "-",
    },
    {
      title: "狀態",
      dataIndex: "status",
      render: (status) => {
        switch (status) {
          case "inactive":
            return <Tag className="tag warning">已失效</Tag>;
          case "active":
            return <Tag className="tag success">生效中</Tag>;
          case "pending":
            return <Tag className="tag info">待生效</Tag>;
          default:
        }
      },
    },
    {
      title: "",
      render: (record) => {
        if (record.status === "pending") {
          return (
            (addPriceIsAllowed || !addPriceIsDenied) && (
              <Button
                onClick={() => {
                  setPriceDrawerVisible(true);
                  setPriceDrawerParams({
                    productId: id,
                    type: "Device",
                    ...record,
                  });
                }}
                icon="edit"
              ></Button>
            )
          );
        }
      },
    },
  ];

  if (!isQueryReady) {
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({
        tabKey: query?.tabKey ?? "quantityInfo",
        page: query?.page ?? 1,
        pageSize: query?.pageSize ?? PAGE_SIZE,
      }),
    });
  }

  const defaultPartColumns = useMemo(() => {
    return [
      {
        title: "型號",
        dataIndex: "part.referenceId",
        render: (text, { part }) => {
          return (
            <Link
              to={`${replaceUrlByParams(PAGE.STOCK_PART_DETAIL, {
                id: part?.id,
              })}`}
              target="_blank"
            >
              {text}
            </Link>
          );
        },
      },
      {
        title: "名稱",
        dataIndex: "part.productInfo.nameChi",
      },
      {
        title: "數量",
        dataIndex: "quantity",
        render: (quantity) => <span>{quantity}</span>,
      },
    ];
  }, []);

  const optionalPartColumns = useMemo(() => {
    return [
      {
        title: "型號",
        dataIndex: "part.referenceId",
        render: (text, { part }) => {
          return (
            <Link
              to={`${replaceUrlByParams(PAGE.STOCK_PART_DETAIL, {
                id: part?.id,
              })}`}
              target="_blank"
            >
              {text}
            </Link>
          );
        },
      },
      {
        title: "名稱",
        dataIndex: "part.productInfo.nameChi",
      },
      {
        title: "數量",
        dataIndex: "quantity",
        render: (quantity) => <span>{quantity}</span>,
      },
    ];
  }, []);

  const defaultConsumableColumns = useMemo(() => {
    return [
      {
        title: "型號",
        dataIndex: "consumable.referenceId",
        render: (text, { consumable }) => {
          return (
            <Link
              to={`${replaceUrlByParams(PAGE.STOCK_CONSUMABLE_DETAIL, {
                id: consumable?.id,
              })}`}
              target="_blank"
            >
              {text}
            </Link>
          );
        },
      },
      {
        title: "名稱",
        dataIndex: "consumable.productInfo.nameChi",
      },
      {
        title: "數量",
        dataIndex: "quantity",
        render: (quantity) => <span>{quantity}</span>,
      },
    ];
  }, []);

  //qrcode stuffs
  const [isSearching, setIsSearching] = useState(false);
  useEffect(() => {
    if (query.storeLocationList || query.status || keyword) {
      setIsSearching(true);
    }
  }, [keyword, localQuery.storeLocationList, query]);
  const INVENTORY_RECORD_QRCODE_DOWNLOAD_ON_DEVICE = gql`
    query InventoryRecordQrcodeDownloadOnDevice(
      $id: String!
      $status: [InventoryStatus]
      $keyword: String
      $storeLocationList: [String]
    ) {
      inventoryRecordQrcodeDownloadOnDevice(
        deviceId: $id
        keyword: $keyword
        status: $status
        storeLocationList: $storeLocationList
      ) {
        key
        url
        name
        size
      }
    }
  `;
  const [
    getInventoryRecordQrCode,
    {
      data: getInventoryRecordQrCodeData,
      loading: getInventoryRecordQrCodeLoading,
      error: getInventoryRecordQrCodeError,
    },
  ] = useLazyQuery(INVENTORY_RECORD_QRCODE_DOWNLOAD_ON_DEVICE, {
    context: { headers: { "Content-Disposition": "attachment" } },
  });

  const refreshInventoryRecordQrcode = useCallback(async () => {
    getInventoryRecordQrCode({
      variables: {
        id,
        keyword,
        status: query.status,
        storeLocationList: query.storeLocationList,
      },
    });
  }, [
    getInventoryRecordQrCode,
    id,
    keyword,
    query.status,
    query.storeLocationList,
  ]);

  useEffect(() => {
    if (getInventoryRecordQrCodeData) {
      axios({
        url:
          getInventoryRecordQrCodeData?.inventoryRecordQrcodeDownloadOnDevice
            .url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "qrcode.zip");
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
      });
    }
  }, [getInventoryRecordQrCodeData]);

  // record template download
  const [
    getInventoryRecordTemplateFile,
    {
      data: getInventoryRecordTemplateFileData,
      loading: getInventoryRecordTemplateFileLoading,
      error: getInventoryRecordTemplateFileError,
    },
  ] = useLazyQuery(INVENTORY_RECORD_TEMPLATE_GET);

  const handleTemplateDownload = useCallback(() => {
    getInventoryRecordTemplateFile();
  }, [getInventoryRecordTemplateFile]);

  useEffect(() => {
    if (getInventoryRecordTemplateFileData) {
      axios({
        url:
          getInventoryRecordTemplateFileData
            ?.inventoryRecordTemplateFileDownload.url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          getInventoryRecordTemplateFileData
            ?.inventoryRecordTemplateFileDownload.name
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
      });
    }
  }, [getInventoryRecordTemplateFileData]);

  const onDeleteInventory = async (record) => {
    try {
      const { data } = await inventoryDelete({ variables: { id: record?.id } });
      if (data.inventoryRecordDeleteOnDevice) {
        notification.success({
          message: `成功刪除${record?.referenceId}`,
          duration: 5,
        });
        refreshInventoryRecordList();
      } else {
        notification.error({
          message: `刪除${record?.referenceId}失敗`,
          duration: 5,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const BREADCRUMB = [
    {
      path: PAGE.STOCK_DEVICE_DETAIL,
      name: `${PAGE_NAME.STOCK_DEVICE_DETAIL} - ${device?.referenceId ?? ""}`,
    },
  ];

  return (
    <StickyBreadcrumbPageComponent
      className="device-detail-page"
      breadcrumb={BREADCRUMB}
    >
      <Modal
        title="刪除存貨"
        visible={deleteInventoryParams.visible}
        onOk={() => {
          onDeleteInventory(deleteInventoryParams?.record);
          setDeleteInventoryParams((_) => ({ ..._, visible: false }));
        }}
        onCancel={() =>
          setDeleteInventoryParams((_) => ({ ..._, visible: false }))
        }
        okText="確定"
        cancelText="取消"
        okButtonProps={{ type: "danger" }}
      >
        確定刪除存貨 {deleteInventoryParams?.record?.referenceId} ?
      </Modal>
      <div className="status-container">
        {!isDenied && (
          <Button
            type="primary"
            onClick={() =>
              history.push(
                replaceUrlByParams(PAGE.STOCK_DEVICE_EDIT, {
                  id: id,
                })
              )
            }
          >
            編輯
          </Button>
        )}
        {/* <Button
					type="primary"
					onClick={() =>
						history.push(PAGE.STOCK_DEVICE)
					}
				>
					返回
				</Button> */}
      </div>
      <Row gutter={[16, 16]} style={{ marginTop: "8px" }}>
        <Col span={6}>
          <img
            width="100%"
            alt="device"
            src={device?.productInfo?.thumbnail?.url ?? ""}
          />
        </Col>
        <Col span={18}>
          <Descriptions layout="vertical">
            <Descriptions.Item label="子類別">
              {device?.category?.name ?? unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="器材英文名稱">
              {device?.productInfo?.nameEng ?? unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="器材中文名稱">
              {device?.productInfo?.nameChi ?? unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="器材型號">
              {device?.productInfo.modelNumber ?? unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="淨重（公斤）">
              {device?.productInfo.netWeight ?? unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="總重量（公斤）">
              {device?.productInfo.totalWeight ?? unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="尺寸">
              {device?.productInfo?.dimension?.length > 0
                ? (device?.productInfo?.dimension).map((dimension) => (
                    <Tag key={dimension}>{dimension}</Tag>
                  ))
                : unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="顏色">
              {device?.productInfo?.colour?.length > 0
                ? (device?.productInfo?.colour).map((colour) => (
                    <Tag key={colour}>{colour}</Tag>
                  ))
                : unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="品牌">
              {device?.productInfo?.brand ?? unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="生產廠家">
              {device?.productInfo?.manufacturer ?? unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="產品特點">
              {device?.productInfo?.characteristicTag?.length > 0
                ? (device?.productInfo?.characteristicTag).map((tag) => (
                    <Tag key={tag}>{tag}</Tag>
                  ))
                : unfilledText}
            </Descriptions.Item>
            <Descriptions.Item label="存量">
              {device?.leasableQuantity ?? 0}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Divider />
      <Tabs
        activeKey={query.tabKey}
        onChange={(tabKey) => {
          history.replace({
            pathname: location.pathname,
            search: queryString.stringify({
              tabKey,
            }),
          });
        }}
      >
        <TabPane className="section" tab="生產及採購資料" key="purchaseInfo">
          <div className="toolbar">
            <div className="left">
              {!isDenied && (
                <Button
                  className="add-btn"
                  type="primary"
                  onClick={() => {
                    setPurchaseInfoDrawerVisible(true);
                    setPurchaseInfoDrawerParams({
                      productId: id,
                    });
                  }}
                >
                  增加採購單
                </Button>
              )}
            </div>
            <div className="right">
              <CustPaginationComponent
                isMinPage={query.page === 1}
                isMaxPage={isMaxPage}
                page={query.page}
                // pageSize={query.pageSize || PAGE_SIZE}
                pageSize={PAGE_SIZE}
                onPageChange={(page) => {
                  history.push({
                    pathname: location.pathname,
                    search: queryString.stringify({
                      ...query,
                      page,
                    }),
                  });
                }}
              />
            </div>
          </div>
          <Table
            loading={getPurchaseInfoListLoading}
            pagination={false}
            rowKey="id"
            columns={purchaseInfoColumns}
            dataSource={purchaseInfo}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (isDenied) return;
                  setPurchaseInfoDrawerVisible(true);
                  setPurchaseInfoDrawerParams({
                    productId: id,
                    ...record,
                  });
                },
              };
            }}
            scroll={{x:"max-content"}}
          />
        </TabPane>
        <TabPane
          className="section"
          tab={`數量及存倉資料（${device?.leasableQuantity ?? 0})`}
          key="quantityInfo"
        >
          <div className="toolbar">
            <div className="left">
              {!isDenied && (
                <Button
                  className="add-btn"
                  type="primary"
                  onClick={() => {
                    setInventoryDrawerVisible(true);
                    setInventoryDrawerParams({
                      productId: id,
                    });
                  }}
                >
                  增加存貨
                </Button>
              )}
              <Input.Search
                className="search"
                placeholder="搜尋"
                value={keyword}
                onChange={({ target: { value } }) => setKeyword(value)}
                onSearch={() =>
                  history.push({
                    pathname: location.pathname,
                    search: queryString.stringify({
                      ...query,
                      keyword,
                    }),
                  })
                }
              />
              <Button
                className="filter-btn"
                type="primary"
                onClick={() => setFilterVisible((v) => !v)}
              >
                <Icon type="filter" theme="filled" />
                {!filterVisible ? "篩選" : "取消篩選"}
              </Button>
              {!isDenied && (
                <>
                  <Upload
                    className="import-xls-btn"
                    type="priamry"
                    headers={{
                      "x-token": getTokenHeader(),
                      "X-Requested-With": null,
                    }}
                    data={{
                      productId: id,
                    }}
                    onChange={(info) => {
                      if (info.file.status === "uploading") {
                        setIsUploading(true);
                        return;
                      }
                      if (info.file.status === "done") {
                        refreshInventoryRecordList();
                      }
                      if (info.file.status === "error") {
                        if (info.file.response?.error?.message)
                          notification.error({
                            message: info.file.response?.error?.message,
                          });
                        else
                          notification.error({
                            message: "上載失敗，請檢查列表內容並重試",
                          });
                      }
                      setIsUploading(false);
                    }}
                    action={getDeviceInventoryUploadUrl()}
                    showUploadList={false}
                  >
                    <Tooltip title="上載存貨列表">
                      <Button icon="upload" loading={isUploading} />
                    </Tooltip>
                  </Upload>
                  <Tooltip title="下載存貨列表範本">
                    <Button
                      icon="download"
                      className="export-template-btn"
                      onClick={handleTemplateDownload}
                    ></Button>
                  </Tooltip>
                  <Tooltip title="批量下載 QR Code">
                    <Button
                      disabled={!isSearching}
                      icon="qrcode"
                      className="import-xls-btn"
                      type="priamry"
                      onClick={refreshInventoryRecordQrcode}
                    ></Button>
                  </Tooltip>
                </>
              )}
            </div>
            <div className="right">
              <CustPaginationComponent
                isMinPage={query.page === 1}
                isMaxPage={isMaxPage}
                page={query.page}
                // pageSize={query.pageSize || PAGE_SIZE}
                pageSize={PAGE_SIZE}
                onPageChange={(page) => {
                  history.push({
                    pathname: location.pathname,
                    search: queryString.stringify({
                      ...query,
                      page,
                    }),
                  });
                }}
              />
            </div>
          </div>
          {filterVisible && (
            <div className="filter">
              <div className="filter-container">
                <FormItem label="存倉位置">
                  {/* <Input
										style={{ width: 200 }}
										value={localQuery?.storeLocation}
										onChange={({ target: { value } }) =>
											setLocalQuery((localQuery) => ({
												...localQuery,
												storeLocation: value,
											}))
										}
									/> */}
                  <Select
                    mode="multiple"
                    style={{ width: 200 }}
                    value={localQuery?.storeLocationList}
                    onChange={(value) =>
                      setLocalQuery((localQuery) => ({
                        ...localQuery,
                        storeLocationList: value,
                      }))
                    }
                  >
                    {(enumList?.warehouseList ?? []).map((location) => (
                      <Select.Option
                        value={location.storeLocationCode}
                        key={location.id}
                      >
                        {location.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem label="狀態">
                  <Select
                    mode="multiple"
                    style={{ width: 200 }}
                    value={localQuery?.status}
                    onChange={(value) =>
                      setLocalQuery((localQuery) => ({
                        ...localQuery,
                        status: value,
                      }))
                    }
                  >
                    {enumList?.enumInventoryStatusList.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </div>
              <div className="footer-container">
                <Button
                  type="secondary"
                  onClick={() => {
                    setLocalQuery((localQuery) => ({
                      ...localQuery,
                      status: undefined,
                      storeLocationList: undefined,
                    }));
                  }}
                >
                  重設
                </Button>
                <Button type="primary" onClick={onSearch}>
                  搜尋
                </Button>
              </div>
            </div>
          )}
          <Table
            loading={getInventoryRecordLoading}
            pagination={false}
            rowKey="id"
            columns={inventoryInfoColumns}
            dataSource={inventoryRecords}
            scroll={{x:"max-content"}}
            // rowSelection={{
            // 	type: "checkbox",
            // 	selectedRowKeys: selectedInventory.map((x) => x.id),
            // 	onChange: (selectedRowKeys, selected) => {
            // 		setSelectedInventory(selected);
            // 	},
            // }}
          />
        </TabPane>
        <TabPane className="section" tab="價格資料" key="priceList">
          <div className="toolbar">
            <div className="left">
              {(addPriceIsAllowed || !addPriceIsDenied) && (
                <Button
                  className="add-btn"
                  type="primary"
                  onClick={() => {
                    setPriceDrawerVisible(true);
                    setPriceDrawerParams({
                      productId: id,
                      type: "Device",
                    });
                  }}
                >
                  增加價格
                </Button>
              )}
            </div>
            <div className="right">
              <CustPaginationComponent
                isMinPage={query.page === 1}
                isMaxPage={isMaxPage}
                page={query.page}
                // pageSize={query.pageSize || PAGE_SIZE}
                pageSize={PAGE_SIZE}
                onPageChange={(page) => {
                  history.push({
                    pathname: location.pathname,
                    search: queryString.stringify({
                      ...query,
                      page,
                    }),
                  });
                }}
              />
            </div>
          </div>
          <Table
            loading={getPriceListLoading}
            pagination={false}
            rowKey="id"
            columns={priceInfoColumns}
            dataSource={prices}
            scroll={{x:"max-content"}}
          />
        </TabPane>
        <TabPane className="section" tab="其他適用零件／消耗品" key="parts">
          {/* <Descriptions layout="vertical">
						<Descriptions.Item label="預設零件">
							{(device?.defaultPart ?? []).map(
								({ part, quantity }, index) => (
									<Typography.Text key={index}>
										<Link
											to={`${replaceUrlByParams(PAGE.STOCK_PART_DETAIL, {
												id: part?.id
											})}`}
											target='_blank'
										>
											{part?.productInfo?.nameChi}
										</Link>
										(數量：
										{quantity})<br />
									</Typography.Text>
								)
							)}
						</Descriptions.Item>
						<Descriptions.Item label="額外零件">
							{(device?.optionalPart ?? []).map(
								({ part, quantity }, index) => (
									<Typography.Text key={index}>
										<Link
											to={`${replaceUrlByParams(PAGE.STOCK_PART_DETAIL, {
												id: part?.id
											})}`}
											target='_blank'
										>
											{part?.productInfo?.nameChi}
										</Link>
										(數量：
										{quantity})<br />
									</Typography.Text>
								)
							)}
						</Descriptions.Item>
						<Descriptions.Item label="預設消耗品">
							{(device?.defaultConsumable ?? []).map(
								({ consumable, quantity }, index) => (
									<Typography.Text key={index}>
										<Link
											to={`${replaceUrlByParams(PAGE.STOCK_PART_DETAIL, {
												id: consumable?.id
											})}`}
											target='_blank'
										>
											{consumable?.productInfo?.nameChi}
										</Link>
										(數量：
										{quantity})<br />
									</Typography.Text>
								)
							)}
						</Descriptions.Item>
					</Descriptions> */}
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card title="預設零件">
                <Table
                  rowKey={(row) => row.part.id}
                  pagination={false}
                  dataSource={device?.defaultPart}
                  columns={defaultPartColumns}
                  scroll={{x:"max-content"}}
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card title="額外零件">
                <Table
                  rowKey={(row) => row.part.id}
                  pagination={false}
                  dataSource={device?.optionalPart}
                  columns={optionalPartColumns}
                  scroll={{x:"max-content"}}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card title="預設消耗品">
                <Table
                  rowKey={(row) => row.consumable.id}
                  pagination={false}
                  dataSource={device?.defaultConsumable}
                  columns={defaultConsumableColumns}
                  scroll={{x:"max-content"}}
                />
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane className="section" tab="活動記錄" key="activityLog">
          <ActivityHistoryComponent id={id} type={"Device"} />
        </TabPane>
      </Tabs>
      {inventoryDrawerVisible && (
        <CreateInventoryDrawer
          productType="device"
          onClose={() => {
            setInventoryDrawerVisible(false);
            refreshInventoryRecordList();
          }}
          defaultValues={inventoryDrawerParams}
        />
      )}
      {purchaseInfoDrawerVisible && (
        <CreatePurchaseInfoDrawer
          productType="device"
          onClose={() => {
            setPurchaseInfoDrawerVisible(false);
            refreshPurchaseInfoList();
          }}
          defaultValues={purchaseInfoDrawerParams}
        />
      )}
      {priceDrawerVisible && (
        <CreatePriceDrawer
          onClose={() => {
            setPriceDrawerVisible(false);
            refreshPriceList();
          }}
          defaultValues={priceDrawerParams}
        />
      )}
    </StickyBreadcrumbPageComponent>
  );
};

export default DeviceDetail;
