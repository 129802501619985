import React from "react";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Components
import CategoryFilter from "@components/categoryFilter/categoryFilter";

// Styles
import "./serviceFilter.scss";

// Utils
import { Button, Col, Row, Select, Typography, Input } from "antd";

const API = gql`
  query {
    characteristicTagList(type: ["service"])
  }
`;

function ServiceFilter(props) {
  const {
    onFilterSearch,
    onResetFilter,
    isFilterShow,
    filterValue,
    onFilterValueChange,
  } = props;

  const { data } = useQuery(API);

  return (
    <div className={`Filter ${isFilterShow ? "show" : "hide"}`}>
      <div className="filter-container">
        <div className="options-container">
          <Typography>篩選條件</Typography>
          <div className="options">
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <div className="option">
                  <Typography>選擇分類 及 子類別</Typography>
                  <CategoryFilter
                    value={filterValue.categoryId}
                    onChange={(categoryId) =>
                      onFilterValueChange((filterValue) => {
                        return {
                          ...filterValue,
                          categoryId,
                        };
                      })
                    }
                  />
                </div>
              </Col>
              <Col span={6}>
                <div className="option">
                  <Typography>產品特點</Typography>
                  <Select
                    multiple={true}
                    showSearch
                    mode="tags"
                    value={filterValue.characteristicTag}
                    onChange={(characteristicTag) =>
                      onFilterValueChange((filterValue) => {
                        return {
                          ...filterValue,
                          characteristicTag,
                        };
                      })
                    }
                  >
                    {(data?.characteristicTagList ?? []).map(
                      (characteristicTag) => (
                        <Select.Option
                          value={characteristicTag}
                          key={characteristicTag}
                        >
                          {characteristicTag}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </div>
              </Col>
              <Col span={12}>
                  <div className="option">
                    <Typography>搜尋服務編號</Typography>
                    <Input 
                      value={filterValue.referenceId} 
                      onChange={(e) => {
                        const referenceId = e.target.value;
                        onFilterValueChange((filterValue) => {
                          return {
                            ...filterValue,
                            referenceId,
                          };
                        })
                      }} 
                    />
                  </div>
                </Col>
            </Row>
          </div>
        </div>
        <div className="btn-container">
          <Button className="btn btn-secondary" onClick={onResetFilter}>
            清除設定
          </Button>
          <Button className="btn" type="primary" onClick={onFilterSearch}>
            搜尋
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ServiceFilter;

ServiceFilter.propTypes = {
  filterTypes: PropTypes.arrayOf(PropTypes.string),
  filterValue: PropTypes.shape({
    categoryId: PropTypes.string,
    characteristicTag: PropTypes.arrayOf(PropTypes.string),
    referenceId: PropTypes.string,
  }),
  onFilterValueChange: PropTypes.func,
  isFilterShow: PropTypes.bool.isRequired,
  onFilterSearch: PropTypes.func,
  onResetFilter: PropTypes.func,
};
