/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  Button,
  Typography,
  Upload,
  Icon,
  Modal,
  notification,
} from "antd";
import axios from "axios";

// Constants
import { PAGE, PAGE_NAME } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

// Styles
import "./tools.scss";

// Utils
import { getTokenHeader } from "@utils/api";
import {
  getBocTransactionImportUrl,
  get711TransactionImportUrl,
} from "@utils/env";

const TransactionUpload = ({ action, confirmModalTitle }) => {
  const [ModalParams, setModalParams] = useState({
    visible: false,
    file: undefined,
  });

  const uploadFile = () => {
    const formData = new FormData();
    formData.append("file", ModalParams.file);
    axios({
      method: "POST",
      url: action,
      headers: {
        "x-token": getTokenHeader(),
      },
      data: formData,
    })
      .then((res) => {
        if (res?.error) {
          notification.error({
            message: res.error?.message,
            duration: 5,
          });
        } else {
          notification.success({
            message: "成功匯入交易記錄",
            duration: 5,
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: "匯入交易記錄失敗，請檢查檔案內容",
          duration: 5,
        });
      });
  };

  return (
    <>
      <Modal
        visible={ModalParams.visible}
        title={confirmModalTitle}
        onCancel={() => setModalParams({ visible: false, file: undefined })}
        onOk={() => {
          uploadFile();
          setModalParams({ visible: false, file: undefined });
        }}
        cancelText="取消"
        okText="確定"
      >
        是否確定上載 {ModalParams.file?.name}?
      </Modal>
      <Upload
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        multiple={false}
        showUploadList={false}
        beforeUpload={(file) => {
          setModalParams({
            visible: true,
            file,
          });
          return false;
        }}
      >
        <Button>
          <Icon type="upload" /> 上載
        </Button>
      </Upload>
    </>
  );
};

TransactionUpload.propTypes = {
  action: PropTypes.string,
  confirmModalTitle: PropTypes.string,
};

function ToolsPage() {
  const BREADCRUMB = [
    {
      path: PAGE.SYSTEM_TOOLS,
      name: PAGE_NAME.SYSTEM_TOOLS,
    },
  ];

  return (
    <StickyBreadcrumbPageComponent
      className="Tools-page"
      breadcrumb={BREADCRUMB}
    >
      <div className="content-inner">
        <Row gutter={[16, 16]}>
          <Col>
            <Typography.Title level={3}>匯入交易記錄:</Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col span={2}>
            <div className="description">7-11</div>
          </Col>
          <Col span={4}>
            <TransactionUpload
              action={get711TransactionImportUrl()}
              confirmModalTitle="匯入 7-11 交易記錄"
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "16px" }}>
          <Col span={2}>
            <div className="description">FPS</div>
          </Col>
          <Col span={4}>
            <TransactionUpload
              action={getBocTransactionImportUrl()}
              confirmModalTitle="匯入 FPS 交易記錄"
            />
          </Col>
        </Row>
      </div>
    </StickyBreadcrumbPageComponent>
  );
}

export default ToolsPage;
