import {
  Tabs,
  Form,
  Spin,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  notification,
  Icon,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useHistory, Link } from "react-router-dom";
import { gql } from "@apollo/client";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

// Components

import QRCodeScanner from "@components/qrcodeScanner/qrcodeScanner";

// Constants
import { PAGE, PAGE_NAME } from "../../../../../utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

//Styles
import "./deviceStage.scss";

// Utils
import { replaceUrlByParams } from "@utils/api";
import TextArea from "antd/lib/input/TextArea";
import { CleaningStatusTag } from "@utils/commonComponents";
import { isDev } from "@utils/env";
import CleaningRecords from "./cleaningRecords/cleaningRecords";

const { TabPane } = Tabs;

const API = gql`
  query cleaningStageList {
    cleaningStageList {
      id
      name
      remark
    }
  }
`;

const INVENTORY_CLEANING_FRAGMENT = gql`
  fragment Fields on InventoryCleaning {
    id
    cleaningFlow {
      name
    }
    cleaningType {
      id
      name
    }
    cleaningFlowStage {
      seq
      cleaningStage {
        id
      }
      startDate
      endDate
      nextCleaningStage
    }
    date
  }
`;

const inventoryCleaningGet = gql`
  ${INVENTORY_CLEANING_FRAGMENT}
  query inventoryCleaningGet($id: ID!) {
    inventoryCleaningGet(id: $id) {
      ...Fields
      equipment {
        id
        referenceId
        name
        status
      }
    }
  }
`;

const cleaningJobGet = gql`
  query cleaningJobGet($cleaningJobId: String!) {
    cleaningJobGet(cleaningJobId: $cleaningJobId) {
      id
      referenceId
      equipment {
        id
        referenceId
        name
        cleanFlow {
          id
          referenceId
          cleaningFlow {
            id
            referenceId
            name
          }
          date
        }
        status
      }
    }
  }
`;

const INVENTORY_CLEANING_UPDATE = gql`
  mutation inventoryCleaningUpdate($input: InventoryCleaningUpdateInput) {
    inventoryCleaningUpdate(input: $input) {
      id
    }
  }
`;

const { Text } = Typography;

const DeviceStage = () => {
  const { id, deviceId } = useParams();
  const history = useHistory();

  const { data: APIData, loading: APILoading } = useQuery(API);

  const {
    data: getCleaningJobData,
    loading: getCleaningJobLoading,
    error: getCleaningJobError,
  } = useQuery(cleaningJobGet, {
    variables: {
      cleaningJobId: id,
    },
  });

  const equipment = useMemo(
    () =>
      getCleaningJobData?.cleaningJobGet?.equipment?.find(
        (e) => e.id === deviceId
      ),
    [deviceId, getCleaningJobData]
  );

  const [
    getInventoryCleaning,
    {
      data: getInventoryCleaningData,
      loading: getInventoryCleaningLoading,
      error: getInventoryCleaningError,
      refetch: getInventoryCleaningRefetch,
    },
  ] = useLazyQuery(inventoryCleaningGet, {
    variables: {
      id: equipment?.cleanFlow?.id,
    },
  });

  useEffect(() => {
    if (equipment?.cleanFlow?.id) {
      getInventoryCleaning();
    }
  }, [equipment, getInventoryCleaning]);

  const [
    updateInventoryCleaning,
    { loading: updateInventoryCleaningLoading },
  ] = useMutation(INVENTORY_CLEANING_UPDATE);

  const isLoading =
    APILoading || getCleaningJobLoading || getInventoryCleaningLoading;

  const [updateCleaningModalVisible, setUpdateCleaingModalVisible] = useState(
    false
  );

  const [scannerModalVisible, setScannerModalVisible] = useState(false);

  const [scannerErrMsg, setScannerErrMsg] = useState(undefined);

  const inventoryCleaning = getInventoryCleaningData?.inventoryCleaningGet;

  const BREADCRUMB = [
    {
      path: PAGE.THIRD_PARTY_CLEANING_JOB_DEVICE_STAGE,
      name: PAGE_NAME.THIRD_PARTY_CLEANING_JOB_DEVICE_STAGE,
    },
  ];

  const { register, setValue, watch, reset, handleSubmit, errors } = useForm();

  const watchValue = watch();

  useEffect(() => {
    if (inventoryCleaning) setValue("id", inventoryCleaning?.id);
  }, [inventoryCleaning, setValue]);

  useEffect(() => {
    register({ name: "id" }, { required: updateCleaningModalVisible });
    register(
      { name: "cleaningStage" },
      { required: updateCleaningModalVisible }
    );
    register(
      { name: "remark" },
      {
        required: updateCleaningModalVisible,
      }
    );
  }, [register, updateCleaningModalVisible, watchValue]);

  const resetUpdate = useCallback(() => {
    reset({
      id: inventoryCleaning?.id,
    });
  }, [inventoryCleaning, reset]);

  const onScannerClick = () => {
    setScannerModalVisible(true);
  };

  const onUpdateCleaningOk = useCallback(
    async (data) => {
      try {
        await updateInventoryCleaning({
          variables: {
            input: {
              id: data.id,
              cleaningStageId: data.cleaningStage,
              remark: data.remark,
            },
          },
        });
        notification.success({ message: "成功更新狀態", duration: 5 });
        getInventoryCleaningRefetch();
      } catch (err) {
        notification.error({ message: err.message });
      }
      resetUpdate();
      setUpdateCleaingModalVisible(false);
    },
    [getInventoryCleaningRefetch, resetUpdate, updateInventoryCleaning]
  );

  const onUpdateCleaningCancel = () => {
    resetUpdate();
    setUpdateCleaingModalVisible(false);
    setScannerModalVisible(true);
  };

  const renderUpdateCleaningModal = () => (
    <Modal
      title="更新清潔流程"
      visible={updateCleaningModalVisible}
      onOk={handleSubmit(onUpdateCleaningOk)}
      onCancel={onUpdateCleaningCancel}
      okText="開始"
      cancelText="取消"
      okButtonProps={{ loading: updateInventoryCleaningLoading }}
      cancelButtonProps={{ loading: updateInventoryCleaningLoading }}
    >
      <Form>
        <Form.Item>
          <div style={{ textAlign: "center" }}>
            <Icon style={{ fontSize: "100px", width: "100%" }} type="warning" />
            <Text type="warning" style={{ fontSize: 24, fontWeight: "bold" }}>
              錯誤清潔流程
            </Text>
          </div>
        </Form.Item>
        <Form.Item>
          <Text>確認進入此清潔流程嗎?</Text>
          <TextArea
            placeholder="必須填寫原因"
            value={watchValue?.remark}
            onChange={(e) => setValue("remark", e.target.value)}
          />
          {errors?.remark && (
            <Typography.Text type="danger">必須填寫原因</Typography.Text>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );

  const onScanSuccess = (data) => {
    try {
      const cleaningStage = JSON.parse(data).cleaningStage;
      if (
        APIData?.cleaningStageList?.find((item) => item.id === cleaningStage)
      ) {
        const currentStage = inventoryCleaning?.cleaningFlowStage?.find(
          (stage) => stage.nextCleaningStage
        );
        if (currentStage.cleaningStage.id === cleaningStage) {
          onUpdateCleaningOk({ ...watchValue, cleaningStage });
          setValue("cleaningStage", cleaningStage);
          setScannerModalVisible(false);
        } else if (currentStage.startDate && !currentStage.endDate) {
          notification.error({
            message: "錯誤清潔流程",
            description: "請先完成本流程",
          });
        } else {
          setValue("cleaningStage", cleaningStage);
          setUpdateCleaingModalVisible(true);
          setScannerModalVisible(false);
        }
      } else {
        notification.error({ message: "不正確的清潔流程QR Code", duration: 3 });
      }
    } catch (err) {
      notification.error({ message: "不正確的QR Code", duration: 3 });
    }
  };

  const renderScannerModal = () => (
    <Modal
      title="掃描清潔流程QR Code"
      visible={scannerModalVisible}
      onCancel={() => setScannerModalVisible(false)}
      footer={null}
    >
      {scannerErrMsg && <Text type="danger">{scannerErrMsg}</Text>}
      <QRCodeScanner
        onScanSuccess={onScanSuccess}
        onError={() => setScannerErrMsg("找不到鏡頭，請使用其他設備")}
      />

      {isDev() &&
        APIData?.cleaningStageList?.map((item) => (
          <Button
            key={item.id}
            onClick={() => onScanSuccess(`{"cleaningStage": "${item.id}"}`)}
          >
            {item.name}
          </Button>
        ))}
    </Modal>
  );

  return (
    <Spin spinning={isLoading}>
      <div className="device-stage">
        {scannerModalVisible && renderScannerModal()}
        {renderUpdateCleaningModal()}
        <StickyBreadcrumbPageComponent
          className="device-stage-page"
          breadcrumb={BREADCRUMB}
        >
          {getCleaningJobError || getInventoryCleaningError ? (
            <div className="errorRecordNotFound">
              <Text>找不到貨物記錄</Text>
              <Text>請返回並確認 QR Code 後重新掃描</Text>
              <Button
                onClick={() =>
                  history.push(
                    replaceUrlByParams(
                      PAGE.THIRD_PARTY_CLEANING_JOB_DEVICE_EDIT,
                      {
                        id: id,
                        deviceId: deviceId,
                      }
                    )
                  )
                }
              >
                返回
              </Button>
            </div>
          ) : (
            <div className="content-inner">
              <div className="inventoryInfo">
                <Row>
                  <Col span={24}>
                    <Typography.Title className="inventoryReferenceId">
                      {`${equipment?.name} (${equipment?.referenceId})`}
                    </Typography.Title>
                  </Col>
                  <Col sm={14} xs={0}>
                    <Col span={12}>
                      <div className="inventory-description">清潔流程</div>
                      <Text>
                        {inventoryCleaning?.cleaningFlow?.name ??
                          "未有進行中清潔流程"}
                      </Text>
                    </Col>

                    <Col span={12}>
                      <div className="inventory-description">狀態</div>
                      <CleaningStatusTag status={equipment?.status} />
                    </Col>

                    <Col span={24}>
                      <Link
                        to={replaceUrlByParams(
                          PAGE.THIRD_PARTY_CLEANING_JOB_DEVICE_EDIT,
                          {
                            id: id,
                            deviceId: deviceId,
                          }
                        )}
                      >
                        檢視器材詳情
                      </Link>
                    </Col>
                  </Col>
                </Row>
              </div>

              <div className="records">
                <Tabs>
                  <TabPane
                    className="section"
                    tab="清潔流程/記錄"
                    key="cleaningRecords"
                  >
                    {inventoryCleaning ? (
                      <div>
                        <CleaningRecords
                          loading={getInventoryCleaningLoading}
                          selectedCleaningId={equipment?.cleanFlow?.id}
                        />
                      </div>
                    ) : (
                      <div>未有清潔流程記錄</div>
                    )}
                  </TabPane>
                </Tabs>
                {["pending"].includes(equipment?.status) && (
                  <Button
                    className="bottomScanBtn"
                    block={true}
                    type="primary"
                    onClick={onScannerClick}
                  >
                    掃描清潔流程QR Code
                  </Button>
                )}
              </div>
            </div>
          )}
        </StickyBreadcrumbPageComponent>
      </div>
    </Spin>
  );
};

export default DeviceStage;
