import {
  Tabs,
  Form,
  Spin,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Radio,
  Select,
  notification,
  Icon,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import { gql } from "@apollo/client";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import queryString from "query-string";
import { usePopup } from "../../../utils/hook";

// Components
import CleaningRecords from "./cleaningRecords/cleaningRecords";
import InspectionRecords from "./inspectionRecords/inspectionRecords";
import QRCodeScanner from "@components/qrcodeScanner/qrcodeScanner";
import InventoryInspectionCreateModal from "@components/inventoryInspectionCreateModal/InventoryInspectionCreateModal";

// Constants
import { PAGE, PAGE_NAME } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

//Styles
import "./inventoryDetail.scss";

// Utils
import { replaceUrlByParams } from "@utils/api";
import TextArea from "antd/lib/input/TextArea";
import { StatusTag } from "@utils/commonComponents";
import { isDev } from "@utils/env";

const { TabPane } = Tabs;

const API = gql`
  query cleaningStageList {
    cleaningStageList {
      id
      name
      remark
    }
  }
`;

const INVENTORY_CLEANING_FRAGMENT = gql`
  fragment Fields on InventoryCleaning {
    id
    cleaningFlow {
      name
    }
    cleaningType {
      id
      name
    }
    cleaningFlowStage {
      seq
      cleaningStage {
        id
      }
      startDate
      endDate
      nextCleaningStage
    }
    date
  }
`;
const INVENTORY_RECORD_GET = gql`
  query inventoryRecordGet($id: ID!) {
    inventoryRecordGet(id: $id) {
      inventoryRecordOrderHistory {
        id
      }
      id
      referenceId
      status {
        id
        name
      }
      activeInventoryInspection {
        referenceId
      }
      product {
        id
        ... on Device {
          inspection {
            id
            name
          }
          productInfo {
            nameEng
            nameChi
            modelNumber
            thumbnail {
              key
              name
              size
              url
            }
          }
          cleaningFlow {
            id
            name
          }
        }

        ... on Part {
          inspection {
            id
            name
          }
          productInfo {
            nameEng
            nameChi
            modelNumber
            thumbnail {
              key
              name
              size
              url
            }
          }
          cleaningFlow {
            id
            name
          }
        }
      }
      activeInventoryCleaning {
        ...Fields
      }
      inventoryCleaningHistory {
        ...Fields
      }
      defaultPart {
        referenceId
      }
      inventoryInspection {
        date
        orderingItem {
          user {
            username
            nameChi
            deleted
            referralInfo {
              id
            }
          }
        }
        id
        referenceId
        inspectionResult {
          name
          id
        }
        inspection {
          id
          name
          device {
            id
            inspection {
              id
              name
            }
          }
          part {
            id
          }
          remark
          trace {
            createDate
            lastModifiedDate
            creator {
              displayName
            }
          }
        }
        trace {
          creator {
            displayName
          }
          lastModifiedDate
        }
        manMadeDamage
        manMadeMissingParts
        suggestedClaimAmount
        reviewingStatus
      }
    }
  }
  ${INVENTORY_CLEANING_FRAGMENT}
`;

const INVENTORY_CLEANING_CREATE = gql`
  mutation inventoryCleaningCreate($input: InventoryCleaningCreateInput) {
    inventoryCleaningCreate(input: $input) {
      id
    }
  }
`;

const INVENTORY_CLEANING_UPDATE = gql`
  mutation inventoryCleaningUpdate($input: InventoryCleaningUpdateInput) {
    inventoryCleaningUpdate(input: $input) {
      id
    }
  }
`;

const INVENTORY_PACKING = gql`
  mutation InventoryPacking($inventoryRecordId: ID!) {
    inventoryPacking(inventoryRecordId: $inventoryRecordId) {
      id
    }
  }
`;

const InventoryDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => {
    const { tabKey } = queryString.parse(location.search);
    return {
      ...queryString.parse(location.search),
      tabKey,
    };
  }, [location.search]);

  useEffect(() => {
    if (!query.tabKey)
      history.replace({
        search: queryString.stringify({
          tabKey: "cleaningRecords",
        }),
      });
  }, [history, query.tabKey]);

  const { data: APIData, loading: APILoading } = useQuery(API);

  const { Text } = Typography;
  const { Option } = Select;

  const [startCleaningModalVisible, setStartCleaingModalVisible] = useState(
    false
  );
  const [updateCleaningModalVisible, setUpdateCleaingModalVisible] = useState(
    false
  );
  const [scannerModalVisible, setScannerModalVisible] = useState(false);

  const [selectedCleaningId, setSelectedCleaningId] = useState(undefined);

  const [scannerErrMsg, setScannerErrMsg] = useState(undefined);

  const [
    getInventory,
    {
      data: getInventoryData,
      loading: getInventoryLoading,
      error: getInventoryError,
    },
  ] = useLazyQuery(INVENTORY_RECORD_GET);

  const [
    createInventoryCleaning,
    { loading: createInventoryCleaningLoading },
  ] = useMutation(INVENTORY_CLEANING_CREATE);

  const [
    updateInventoryCleaning,
    { loading: updateInventoryCleaningLoading },
  ] = useMutation(INVENTORY_CLEANING_UPDATE);

  const [packInventory, { loading: packInventoryLoading }] = useMutation(
    INVENTORY_PACKING
  );

  const isLoading =
    getInventoryLoading ||
    APILoading ||
    createInventoryCleaningLoading ||
    updateInventoryCleaningLoading;

  const inventoryRecord = useMemo(() => {
    return getInventoryData?.inventoryRecordGet;
  }, [getInventoryData]);

  const inventoryCleaning = useMemo(() => {
    const array = [];
    if (inventoryRecord?.activeInventoryCleaning)
      array.push(inventoryRecord?.activeInventoryCleaning);
    if (inventoryRecord?.inventoryCleaningHistory?.length)
      array.push(...inventoryRecord?.inventoryCleaningHistory);
    return array;
  }, [inventoryRecord]);

  useEffect(() => {
    if (inventoryCleaning) {
      setSelectedCleaningId(inventoryCleaning[0]?.id);
    }
  }, [inventoryCleaning]);

  const BREADCRUMB = [
    {
      path: PAGE.MOBILE_INVENTORY_DETAIL,
      name: PAGE_NAME.MOBILE_INVENTORY_DETAIL,
    },
  ];

  const { register, setValue, watch, reset, handleSubmit, errors } = useForm({
    defaultValues: {
      inventoryRecordId: id || undefined,
      cleaningType: undefined,
      cleaningFlowID: undefined,
      id: undefined,
      cleaningStage: undefined,
      remark: undefined,
    },
  });

  useEffect(() => {
    if (inventoryRecord)
      setValue("id", inventoryRecord?.activeInventoryCleaning?.id);
  }, [inventoryRecord, setValue]);

  const watchValue = watch();

  const refreshInventory = useCallback(() => {
    if (id) {
      getInventory({ variables: { id } });
    }
  }, [getInventory, id]);

  useEffect(() => {
    refreshInventory();
  }, [refreshInventory]);

  useEffect(() => {
    register(
      { name: "inventoryRecordId" },
      { required: startCleaningModalVisible }
    );
    register({ name: "cleaningType" }, { required: startCleaningModalVisible });
    register(
      { name: "cleaningFlowID" },
      { required: startCleaningModalVisible }
    );
    register({ name: "id" }, { required: updateCleaningModalVisible });
    register(
      { name: "cleaningStage" },
      { required: updateCleaningModalVisible }
    );
    register(
      { name: "remark" },
      {
        required: updateCleaningModalVisible,
      }
    );
  }, [
    register,
    startCleaningModalVisible,
    updateCleaningModalVisible,
    watchValue,
  ]);

  const onScannerClick = () => {
    if (inventoryRecord?.activeInventoryCleaning) {
      setScannerModalVisible(true);
    } else {
      setStartCleaingModalVisible(true);
    }
  };

  const [inspectionCreateProps, openPopup] = usePopup({
    visible: false,
    params: {
      inventoryRecordId: inventoryRecord?.id,
      orderingItemId: null,
      inspectionType: null,
    },
  });

  const onStartCleaningOk = async (data) => {
    try {
      await createInventoryCleaning({
        variables: {
          input: {
            inventoryRecordId: data.inventoryRecordId,
            cleaningFlowID: data.cleaningFlowID,
            cleaningType: data.cleaningType,
          },
        },
      });
      notification.success({ message: "成功開始清潔流程", duration: 5 });
      refreshInventory();
    } catch (err) {
      notification.error({ message: err.message });
    }
    reset();
    setStartCleaingModalVisible(false);
  };

  const onStartCleaningCancel = () => {
    reset();
    setStartCleaingModalVisible(false);
  };

  const renderStartCleaningModal = () => (
    <Modal
      title="開始清潔流程"
      visible={startCleaningModalVisible}
      onOk={handleSubmit(onStartCleaningOk)}
      onCancel={onStartCleaningCancel}
      okText="開始"
      cancelText="取消"
      okButtonProps={{ loading: createInventoryCleaningLoading }}
      cancelButtonProps={{ loading: createInventoryCleaningLoading }}
    >
      <Form>
        <Form.Item>
          <Text>清潔類型</Text>
          <Radio.Group
            onChange={(e) => setValue("cleaningType", e.target.value)}
            value={watchValue?.cleaningType}
            style={{ display: "block" }}
          >
            <Radio
              style={{ display: "block", lineHeight: "30px" }}
              value={"cleaningRegularly"}
            >
              定期清洗
            </Radio>
            <Radio
              style={{ display: "block", lineHeight: "30px" }}
              value={"afterTermination"}
            >
              結束服務清洗
            </Radio>
          </Radio.Group>
          {errors?.cleaningType && (
            <Typography.Text type="danger">請選擇清潔類型</Typography.Text>
          )}
        </Form.Item>
        <Form.Item>
          <Text>清潔流程</Text>
          <Select
            onChange={(value) => setValue("cleaningFlowID", value)}
            value={watchValue?.cleaningFlowID}
          >
            {inventoryRecord?.product?.cleaningFlow?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
          {errors?.cleaningFlowID && (
            <Typography.Text type="danger">請選擇清潔流程</Typography.Text>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );

  const onUpdateCleaningOk = async (data) => {
    try {
      await updateInventoryCleaning({
        variables: {
          input: {
            id: data.id,
            cleaningStageId: data.cleaningStage,
            remark: data.remark,
          },
        },
      });
      notification.success({ message: "成功更新狀態", duration: 5 });
      refreshInventory();
    } catch (err) {
      notification.error({ message: err.message });
    }
    resetUpdate();
    setUpdateCleaingModalVisible(false);
  };

  const onUpdateCleaningCancel = () => {
    resetUpdate();
    setUpdateCleaingModalVisible(false);
    setScannerModalVisible(true);
  };

  const resetUpdate = () => {
    reset({
      inventoryRecordId: id,
      id: inventoryRecord?.activeInventoryCleaning?.id,
    });
  };

  const renderUpdateCleaningModal = () => (
    <Modal
      title="更新清潔流程"
      visible={updateCleaningModalVisible}
      onOk={handleSubmit(onUpdateCleaningOk)}
      onCancel={onUpdateCleaningCancel}
      okText="開始"
      cancelText="取消"
      okButtonProps={{ loading: updateInventoryCleaningLoading }}
      cancelButtonProps={{ loading: updateInventoryCleaningLoading }}
    >
      <Form>
        <Form.Item>
          <div style={{ textAlign: "center" }}>
            <Icon style={{ fontSize: "100px", width: "100%" }} type="warning" />
            <Text type="warning" style={{ fontSize: 24, fontWeight: "bold" }}>
              錯誤清潔流程
            </Text>
          </div>
        </Form.Item>
        <Form.Item>
          <Text>確認進入此清潔流程嗎?</Text>
          <TextArea
            placeholder="必須填寫原因"
            value={watchValue?.remark}
            onChange={(e) => setValue("remark", e.target.value)}
          />
          {errors?.remark && (
            <Typography.Text type="danger">必須填寫原因</Typography.Text>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );

  const onScanSuccess = (data) => {
    try {
      const cleaningStage = JSON.parse(data).cleaningStage;
      if (
        APIData?.cleaningStageList?.find((item) => item.id === cleaningStage)
      ) {
        const currentStage = inventoryRecord?.activeInventoryCleaning?.cleaningFlowStage?.find(
          (stage) => stage.nextCleaningStage
        );
        if (currentStage.cleaningStage.id === cleaningStage) {
          onUpdateCleaningOk({ ...watchValue, cleaningStage });
          setValue("cleaningStage", cleaningStage);
          setScannerModalVisible(false);
        } else if (currentStage.startDate && !currentStage.endDate) {
          notification.error({
            message: "錯誤清潔流程",
            description: "請先完成本流程",
          });
        } else {
          setValue("cleaningStage", cleaningStage);
          setUpdateCleaingModalVisible(true);
          setScannerModalVisible(false);
        }
      } else {
        notification.error({ message: "不正確的清潔流程QR Code", duration: 3 });
      }
    } catch (err) {
      notification.error({ message: "不正確的QR Code", duration: 3 });
    }
  };

  const renderScannerModal = () => (
    <Modal
      title="掃描清潔流程QR Code"
      visible={scannerModalVisible}
      onCancel={() => setScannerModalVisible(false)}
      footer={null}
    >
      {scannerErrMsg && <Text type="danger">{scannerErrMsg}</Text>}
      <QRCodeScanner
        onScanSuccess={onScanSuccess}
        onError={() => setScannerErrMsg("找不到鏡頭，請使用其他設備")}
      />

      {isDev() &&
        APIData?.cleaningStageList?.map((item) => (
          <Button
            key={item.id}
            onClick={() => onScanSuccess(`{"cleaningStage": "${item.id}"}`)}
          >
            {item.name}
          </Button>
        ))}
    </Modal>
  );

  return (
    <Spin spinning={isLoading}>
      <div className="inventory-detail">
        {renderStartCleaningModal()}
        {scannerModalVisible && renderScannerModal()}
        {renderUpdateCleaningModal()}
        <StickyBreadcrumbPageComponent
          className="inventory-detail-page"
          breadcrumb={BREADCRUMB}
        >
          {getInventoryError ? (
            <div className="errorRecordNotFound">
              <Text>找不到貨物記錄</Text>
              <Text>請返回並確認 QR Code 後重新掃描</Text>
              <Button onClick={() => history.push(PAGE.MOBILE_SCANNER)}>
                返回
              </Button>
            </div>
          ) : (
            <div className="content-inner">
              <div className="inventoryInfo">
                <Row>
                  <Col span={24}>
                    <Text>
                      <span className="inventoryReferenceId">
                        {inventoryRecord?.referenceId}
                      </span>
                    </Text>
                  </Col>
                  <Col sm={0} xs={24}>
                    <Text>
                      {inventoryRecord?.product?.productInfo?.modelNumber}
                    </Text>
                  </Col>
                  <Col sm={0} xs={24}>
                    <Text>
                      {inventoryRecord?.product?.productInfo?.nameChi}
                    </Text>
                  </Col>
                  <Col sm={0} xs={24}>
                    <StatusTag status={inventoryRecord?.status} />
                  </Col>
                  {inventoryRecord?.product?.productInfo?.thumbnail?.url && (
                    <Col className="inventoryThumbnail" sm={10} xs={24}>
                      <img
                        alt="inventory"
                        src={
                          inventoryRecord?.product?.productInfo?.thumbnail
                            ?.url ?? ""
                        }
                      />
                    </Col>
                  )}
                  <Col sm={14} xs={0}>
                    <Col span={24}>
                      <div className="inventory-description">型號</div>
                      <Text>
                        {inventoryRecord?.product?.productInfo?.modelNumber}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <div className="inventory-description">型號名稱</div>
                      <Text>
                        {inventoryRecord?.product?.productInfo?.nameChi}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <div className="inventory-description">狀態</div>
                      <StatusTag status={inventoryRecord?.status} />
                    </Col>
                    <Col span={24}>
                      <div className="inventory-description">預設配件編號</div>
                      <Text>
                        {inventoryRecord?.defaultPart?.map((item, i) =>
                          i === 0 ? item?.referenceId : `, ${item?.referenceId}`
                        )}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <div className="inventory-description">清潔流程</div>
                      <Text>
                        {inventoryRecord?.activeInventoryCleaning?.cleaningFlow
                          ?.name ?? "未有進行中清潔流程"}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <div className="inventory-description">檢查記錄表</div>
                      <Text>
                        {inventoryRecord?.activeInventoryInspection
                          ?.referenceId ?? "未有進行中檢查記錄表"}
                      </Text>
                    </Col>

                    <Col span={24}>
                      <Link
                        to={replaceUrlByParams(PAGE.STOCK_INVENTORY_DETAIL, {
                          inventoryId: id,
                        })}
                      >
                        檢視貨物詳情
                      </Link>
                    </Col>
                  </Col>

                  <Col>
                    {["inspected", "normal", "cleaned"].includes(
                      inventoryRecord?.status?.id
                    ) && (
                      <Col span={12}>
                        <Button
                          size="sm"
                          loading={packInventoryLoading}
                          onClick={async () => {
                            try {
                              await packInventory({
                                variables: {
                                  inventoryRecordId: inventoryRecord?.id,
                                },
                              });

                              notification.success({
                                message: "成功包裝",
                                duration: 5,
                              });
                            } catch (error) {
                              notification.error({
                                message: "無法操作",
                                duration: 5,
                              });
                              console.error(error);
                            }
                            refreshInventory();
                          }}
                        >
                          包裝
                        </Button>
                      </Col>
                    )}
                  </Col>
                </Row>
              </div>

              <div className="records">
                <Tabs
                  activeKey={query?.tabKey}
                  onChange={(tabKey) =>
                    history.replace({
                      search: queryString.stringify({
                        tabKey,
                      }),
                    })
                  }
                >
                  <TabPane
                    className="section"
                    tab="清潔流程/記錄"
                    key="cleaningRecords"
                  >
                    {inventoryCleaning.length > 0 ? (
                      <div>
                        <Select
                          style={{ width: "100%", paddingBottom: "10px" }}
                          value={selectedCleaningId}
                          onChange={(data) => setSelectedCleaningId(data)}
                        >
                          {inventoryCleaning?.map((item) => (
                            <Option key={item?.id} value={item?.id}>{`${
                              item?.date
                            } | ${item?.cleaningType?.name} ${
                              inventoryRecord?.activeInventoryCleaning?.id ===
                              item.id
                                ? "(進行中)"
                                : ""
                            }`}</Option>
                          ))}
                        </Select>
                        <CleaningRecords
                          loading={getInventoryLoading}
                          selectedCleaningId={selectedCleaningId}
                        />
                      </div>
                    ) : (
                      <div>未有清潔流程記錄</div>
                    )}
                  </TabPane>

                  <TabPane
                    className="section"
                    tab="檢查/維修記錄"
                    key="inspectionRecords"
                  >
                    {![
                      "leasing",
                      "repairing",
                      "toBeDisposed",
                      "reserved",
                      "cleaning",
                      "terminated",
                    ].includes(inventoryRecord?.status.id) && (
                      <Button
                        onClick={() =>
                          openPopup({
                            inventoryRecordId: id,
                            orderingItemId: null,
                            inspectionType: "centerInspection",
                          })
                        }
                      >
                        新增中心檢查/維修紀錄表
                      </Button>
                    )}
                    <InventoryInspectionCreateModal
                      {...inspectionCreateProps}
                    />
                    <InspectionRecords
                      loading={getInventoryLoading}
                      inventoryRecord={inventoryRecord}
                      inventoryId={id}
                    />
                  </TabPane>
                </Tabs>
                {query?.tabKey === "cleaningRecords" &&
                  [
                    "readyForLease",
                    "inspected",
                    "cleaned",
                    "cleaning",
                  ].includes(inventoryRecord?.status.id) && (
                    <Button
                      className="bottomScanBtn"
                      block={true}
                      type="primary"
                      onClick={onScannerClick}
                    >
                      {inventoryRecord?.activeInventoryCleaning
                        ? "掃描清潔流程QR Code"
                        : "開始清潔流程"}
                    </Button>
                  )}
              </div>
            </div>
          )}
        </StickyBreadcrumbPageComponent>
      </div>
    </Spin>
  );
};

export default InventoryDetail;
