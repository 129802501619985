import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import moment from "moment";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";

// Actions
import { setIsLoadingAction } from "@actions/storeAction";

// Components
import { Button } from "antd";
import Filter from "../filter/filter";

// Constants
import { MOMENT_FORMAT, PAGE, PAGE_NAME, PAGE_SIZE } from "@utils/constants";

// Layouts
import TableView from "@layouts/tableView/tableView";

// Styles
import "./inventory.scss";

// Utils
import { replaceUrlByParams } from "@utils/api";
import { StatusTag } from "@utils/commonComponents";
import { _uuid } from "@utils/common";

import { useDispatch } from "react-redux";

const cacheSessionUUID = _uuid();

const INVENTORY_RECORD_LIST = gql`
  query InventoryRecordList(
    $categoryId: [ID]
    $brand: [String]
    $dimension: [String]
    $characteristicTag: [String]
    $inventoryStatus: [InventoryStatus]
    $storeLocationList: [String]
    $keyword: String
    $size: Int!
    $offset: Int!
    $reverseOrder: Boolean
    $sort: SortInventoryRecordList
  ) {
    inventoryRecordList(
      categoryId: $categoryId
      brand: $brand
      dimension: $dimension
      characteristicTag: $characteristicTag
      inventoryStatus: $inventoryStatus
      storeLocationList: $storeLocationList
      keyword: $keyword
      size: $size
      offset: $offset
      reverseOrder: $reverseOrder
      sort: $sort
    ) {
      id
      referenceId
      status {
        id
        name
      }
      product {
        __typename
        id
        referenceId
        ... on Device {
          productInfo {
            modelNumber
            nameChi
          }
        }
        ... on Part {
          productInfo {
            modelNumber
            nameChi
          }
        }
        ... on Consumable {
          productInfo {
            modelNumber
            nameChi
          }
        }
        category {
          id
          name
          mainCategory {
            name
          }
        }
      }
      warehouse {
        id
        name
      }
      trace {
        lastModifiedDate
      }
    }
  }
`;

const INVENTORY_RECORD_DOWNLOAD = gql`
  query InventoryRecordDownload(
    $categoryId: [ID]
    $brand: [String]
    $dimension: [String]
    $characteristicTag: [String]
    $storeLocationList: [String]
    $status: [InventoryStatus]
    $keyword: String
  ) {
    inventoryRecordDownload(
      categoryId: $categoryId
      brand: $brand
      dimension: $dimension
      characteristicTag: $characteristicTag
      storeLocationList: $storeLocationList
      status: $status
      keyword: $keyword
    ) {
      key
      url
    }
  }
`;

const INVENTORY_RECORD_COUNT = gql`
  query InventoryRecordCount(
    $categoryId: [ID]
    $brand: [String]
    $dimension: [String]
    $characteristicTag: [String]
    $inventoryStatus: [InventoryStatus]
    $storeLocationList: [String]
    $keyword: String
  ) {
    inventoryRecordCount(
      categoryId: $categoryId
      brand: $brand
      dimension: $dimension
      characteristicTag: $characteristicTag
      inventoryStatus: $inventoryStatus
      storeLocationList: $storeLocationList
      keyword: $keyword
    )
  }
`;

function InventoryListPage(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = props;

  const BREADCRUMB = [
    {
      path: PAGE.STOCK_INVENTORY,
      name: PAGE_NAME.STOCK_INVENTORY,
    },
  ];

  const columns = [
    {
      title: "工場",
      dataIndex: "warehouse.name",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "器材編號",
      width: "12%",
      dataIndex: "referenceId",
      sorter: true,
      render: (text, record) => {
        /* TODO Update page constant */
        const redPoint = Object.values(record?.redDot ?? {}).reduce(
          (acc, flag) => {
            if (typeof flag === "boolean") return acc || flag;
            return acc;
          },
          false
        );
        return (
          <>
            {redPoint && <span className="redPoint"></span>}
            <Link
              className="link"
              to={replaceUrlByParams(PAGE.STOCK_INVENTORY_DETAIL, {
                inventoryId: record.id,
              })}
            >
              {text}
            </Link>
          </>
        );
      },
    },
    {
      title: "款號",
      width: "10%",
      dataIndex: "model",
      sorter: true,
      render: (text, record) =>
        /* TODO Update page constant */
        record.product.referenceId,
    },
    {
      title: "型號",
      width: "10%",
      dataIndex: "product.productInfo.modelNumber",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "名稱",
      dataIndex: "product.productInfo.nameChi",
    },
    {
      title: "分類",
      dataIndex: "product.category.mainCategory.name",
    },
    {
      title: "子分類",
      width: "15%",
      dataIndex: "product.category",
      render: (category) => {
        return (
          <Link
            className="link"
            onClick={(e) => {
              e.preventDefault();
              history.push({
                search: queryString.stringify({
                  page: 1,
                  pageSize: PAGE_SIZE,
                  categoryId: category.id,
                }),
              });
            }}
          >
            {category.name}
          </Link>
        );
      },
    },
    {
      title: "狀態",
      dataIndex: "status",
      render: (status) => <StatusTag status={status} />,
    },
    {
      title: "最後更新日期",
      dataIndex: "lastModifiedDate",
      render: (text, record) => (
        <span>
          {moment(+record.trace.lastModifiedDate).format(MOMENT_FORMAT)}
        </span>
      ),
      sorter: true,
    },
  ];

  const [
    getInventoryList,
    { loading: inventoryListLoading, data: inventoryListData },
  ] = useLazyQuery(INVENTORY_RECORD_LIST, {
    context: {
      headers: {
        "x-cache-session": cacheSessionUUID,
      },
    },
  });
  const [
    inventoryRecordDownload,
    {
      loading: inventoryRecordDownloadLoading,
      data: inventoryRecordDownloadData,
    },
  ] = useLazyQuery(INVENTORY_RECORD_DOWNLOAD);
  const [
    inventoryRecordCount,
    {
      // loading: inventoryRecordCountLoading,
      data: inventoryRecordCountData,
    },
  ] = useLazyQuery(INVENTORY_RECORD_COUNT);

  // fetch PAGE_SIZE + 1, to check if there is next page.
  const inventoryList = useMemo(() => {
    return (inventoryListData?.inventoryRecordList || []).slice(0, PAGE_SIZE);
  }, [inventoryListData]);

  const isMaxPage = useMemo(() => {
    const queryParams = queryString.parse(location.search);
    return (
      queryParams.pageSize >=
      (inventoryListData?.inventoryRecordList || []).length
    );
  }, [inventoryListData, location.search]);

  const [isFilterShow, setIsFilterShow] = useState(false);
  const [query, setQuery] = useState(queryString.parse(location.search));

  useEffect(() => {
    setQuery(queryString.parse(location.search));
  }, [location.search]);

  useEffect(() => {
    const {
      page,
      pageSize,
      keyword,
      sortField,
      categoryId,
      brand,
      dimension,
      characteristicTag,
      storeLocation,
      sortOrder,
      inventoryStatus,
    } = queryString.parse(location.search);

    console.log(
      "queryString.parse(location.search);",
      queryString.parse(location.search)
    );
    console.log("location.search", location.search);

    const queryParamToArray = (x) => (x ? (!Array.isArray(x) ? [x] : x) : []);

    getInventoryList({
      variables: {
        offset: +((page - 1) * pageSize) || 0,
        size: +(pageSize || PAGE_SIZE) + 1,
        keyword: keyword,
        sort: sortField,
        categoryId: categoryId,
        inventoryStatus: queryParamToArray(inventoryStatus),
        brand: queryParamToArray(brand),
        dimension: queryParamToArray(dimension),
        characteristicTag: queryParamToArray(characteristicTag),
        storeLocationList: queryParamToArray(storeLocation),
        reverseOrder: sortOrder ? sortOrder === "descend" : undefined,
      },
    });

    inventoryRecordCount({
      variables: {
        keyword: keyword,
        categoryId: categoryId,
        inventoryStatus: queryParamToArray(inventoryStatus),
        brand: queryParamToArray(brand),
        dimension: queryParamToArray(dimension),
        characteristicTag: queryParamToArray(characteristicTag),
        storeLocationList: queryParamToArray(storeLocation),
      },
    });
  }, [getInventoryList, inventoryRecordCount, location.search]);

  useEffect(() => {
    dispatch(setIsLoadingAction(inventoryRecordDownloadLoading));

    if (inventoryRecordDownloadData) {
      window.open(inventoryRecordDownloadData.inventoryRecordDownload.url);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [inventoryRecordDownloadLoading, inventoryRecordDownloadData]);

  const onDownloadClick = () => {
    const {
      keyword,
      categoryId,
      brand,
      dimension,
      characteristicTag,
      storeLocation,
      status,
    } = queryString.parse(location.search);

    const queryParamToArray = (x) => (x ? (!Array.isArray(x) ? [x] : x) : []);

    inventoryRecordDownload({
      variables: {
        categoryId: categoryId,
        brand: queryParamToArray(brand),
        dimension: queryParamToArray(dimension),
        characteristicTag: queryParamToArray(characteristicTag),
        storeLocationList: queryParamToArray(storeLocation),
        status: queryParamToArray(status),
        keyword: keyword,
      },
    });
  };

  const onFilterSearch = () => {
    history.push({
      search: queryString.stringify(query),
    });
  };

  const onFilterClick = (state) => {
    setIsFilterShow(state);
  };

  return (
    <TableView
      {...props}
      breadcrumb={BREADCRUMB}
      className="Stock-inventory-page"
      rowKey="id"
      columns={columns}
      hasFilter
      isFilterShow={isFilterShow}
      isMaxPage={isMaxPage}
      total={inventoryRecordCountData?.inventoryRecordCount}
      isTableLoading={inventoryListLoading}
      onFilterClick={onFilterClick}
      searchBarPlaceholder="關鍵字/型號/分類/子類別搜尋"
      tableData={inventoryList}
      extraBtn={
        <Button className="btn btn-secondary" onClick={onDownloadClick}>
          <span>下載此搜尋 / 篩選結果列表.xlsx</span>
        </Button>
      }
    >
      <Filter
        filterList={[
          "categoryId",
          "brand",
          "dimension",
          "characteristicTag",
          "inventoryStatus",
          "storeLocation",
        ]}
        filterType={[""]}
        filterValue={query}
        onFilterSearch={onFilterSearch}
        onResetFilter={() => {
          history.push({
            search: queryString.stringify({ page: 1, pageSize: PAGE_SIZE }),
          });
        }}
        isFilterShow={isFilterShow}
        onFilterValueChange={setQuery}
      />
    </TableView>
  );
}

export default InventoryListPage;

InventoryListPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
