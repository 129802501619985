import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

// Components
import { Layout } from "antd";
import CustBreadcrumbComponent from "@components/custBreadcrumb/custBreadcrumb";
import MobileNavbar from "@layouts/mobile/navbar/navbar";

// Style
import "./stickyBreadcrumbPage.scss";

// Utils
import { classList } from "@utils/common";

function StickyBreadcrumbPageComponent(props) {
  const { children, className, breadcrumb } = props;
  const location = useLocation();

  const isMobile = useMemo(() => (location.pathname.match(/^\/mobile/g) ? true : false), [location.pathname]);
  if (breadcrumb) {
    return (
      <Layout
        className={classList({
          "Sticky-breadcrumb-page-component": true,
          [className]: true,
        })}
      >
        <Layout.Header>
          {isMobile && <MobileNavbar />}
          <CustBreadcrumbComponent paths={breadcrumb} />
          {children.length > 1 ? children[0] : null}
        </Layout.Header>
        <Layout.Content className="content">{children.length > 1 ? children.filter((child, i) => i !== 0) : children}</Layout.Content>
      </Layout>
    );
  } else {
    return (
      <Layout
        className={classList({
          "Sticky-breadcrumb-page-component": true,
          [className]: true,
        })}
      >
        <Layout.Content className="content">{children.length > 1 ? children.filter((child, i) => i !== 0) : children}</Layout.Content>
      </Layout>
    );
  }
}

export default StickyBreadcrumbPageComponent;

StickyBreadcrumbPageComponent.propTypes = {
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
};

StickyBreadcrumbPageComponent.defaultProps = {
  children: null,
  className: "",
};
