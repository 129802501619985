/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

import queryString from "query-string";

// Styles
import "./searchTable.scss";
import { Button, Icon, Input, Table } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PAGE_SIZE } from "../../../utils/constants";
import CustPaginationComponent from "@components/custPagination/custPagination";

// Utils

function SearchTable(props) {
  const {
    breadcrumb,
    header,
    filter,
    showFilterButton = true,
    filterVisible = false,
    searchInputProps,
    createButton,
    searchBarExtra,
    tableProps,
    total,
    isMaxPage,
  } = props;

  const history = useHistory();
  const location = useLocation();

  const query = useMemo(
    () => queryString.parse(location.search),
    [location.search]
  );

  const replaceDefaultQueryParams = useCallback(() => {
    if (
      query?.page &&
      (tableProps?.pagination?.total
        ? query?.page <= Math.ceil(tableProps?.pagination?.total / PAGE_SIZE)
        : true)
    )
      return;
    history.replace({
      search: queryString.stringify({
        ...query,
        page: 1,
      }),
    });
  }, [history, query, tableProps]);

  useEffect(() => {
    replaceDefaultQueryParams();
  }, [replaceDefaultQueryParams]);

  const onSearch = useCallback(
    (keyword) => {
      history.push({
        search: queryString.stringify({
          ...query,
          keyword: keyword ? keyword : undefined,
          page: 1,
        }),
      });
    },
    [history, query]
  );

  const [isFilterVisible, setIsFilterVisible] = useState(filterVisible);

  const onTableChange = useCallback(
    (pagination, filters, sorter) => {
      history.push({
        search: queryString.stringify({
          ...query,
          sortField: sorter.order ? sorter.field : undefined,
          sortOrder: sorter.order,
          page: query.page,
        }),
      });
    },
    [history, query]
  );

  const onPageChange = (page) => {
    history.replace({
      search: queryString.stringify({
        ...query,
        page: page,
      }),
    });
  };

  return (
    <StickyBreadcrumbPageComponent
      className="Table-View"
      breadcrumb={breadcrumb}
    >
      <div className="content-inner">
        {header && <div className="header">{header}</div>}
        <div className="search-bar-container">
          <div className="buttons-container">
            {createButton && (
              <Link to={createButton?.url}>
                <Button className="btn btn-secondary">
                  {createButton.text}
                </Button>
              </Link>
            )}
            <div className="search-bar">
              <Input.Search
                onSearch={onSearch}
                allowClear
                defaultValue={query?.keyword}
                {...searchInputProps}
              />
            </div>
            {filter && showFilterButton && (
              <Button
                className="btn"
                type={"primary"}
                onClick={() => setIsFilterVisible((isVisible) => !isVisible)}
              >
                <Icon type="filter" theme="filled" />
                {isFilterVisible ? "取消篩選" : "篩選"}
              </Button>
            )}
            {searchBarExtra}
          </div>
        </div>
        {isFilterVisible && filter}
        <Table
          className="search-table"
          placeholder="沒有資料"
          onChange={onTableChange}
          {...tableProps}
          pagination={false}
          // pagination={{
          //   current: parseInt(query.page),
          //   pageSize: PAGE_SIZE,
          //   ...tableProps?.pagination,
          // }}
          scroll={{ x: "max-content" }}
        />
        <div className="pagination">
          <CustPaginationComponent
            isMinPage={query.page === 1}
            isMaxPage={isMaxPage}
            total={total}
            page={parseInt(query.page)}
            pageSize={PAGE_SIZE}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </StickyBreadcrumbPageComponent>
  );
}

export default SearchTable;

SearchTable.propTypes = {
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  header: PropTypes.node,
  filter: PropTypes.node,
  showFilterButton: PropTypes.bool,
  filterVisible: PropTypes.bool,
  createButton: PropTypes.shape({
    url: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
  }),
  searchInputProps: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  searchBarExtra: PropTypes.node,
  tableProps: PropTypes.object,
  total: PropTypes.number,
  isMaxPage: PropTypes.bool,
};
