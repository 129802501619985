import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form'

// Components
import {
    Button,
    DatePicker,
    notification,
    Form,
    Input,
    Checkbox,
    Select,
    InputNumber
} from 'antd';

// Constants
import {NOTIFICATION_CONFIG, PAGE, PAGE_NAME} from '@utils/constants';

// Layouts
import StickyBreadcrumbPageComponent from '@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage';

// Styles
import './form.scss';

// Utils

function DemoFormPage() {
    const BREADCRUMB = [
        {
            path: PAGE.OVERVIEW,
            name: PAGE_NAME.OVERVIEW
        },
        {
            path: '/demo/form',
            name: 'Demo Form'
        }
];

    notification.config(NOTIFICATION_CONFIG);

    const {register, handleSubmit, errors, setValue, watch} = useForm();

    useEffect(() => {
        register({name: 'input'}, {required: true});
        // setValue('input', 1)
        register({name: 'textarea'}, {required: true, minLength: 2});
        register({name: 'select'}, {required: true});
        register({name: 'number'}, {required: true});
        register({name: 'datePicker'}, {required: true});
        register({name: 'checkbox'}, {required: true});
        register({name: 'checkboxGroup'}, {validate});

        // eslint-disable-next-line
    }, []);

    const onSubmit = data => {
        console.log(data);
    };

    const validate = () => {
        const test1 = watch('checkboxGroup[0]');
        const test2 = watch('checkboxGroup[1]');
        const test3 = watch('checkboxGroup[2]');
        return !!(test1 || test2 || test3);
    };

    return (
        <StickyBreadcrumbPageComponent className='Demo-form-page' breadcrumb={BREADCRUMB}>
            <div className='content-inner'>
                <Form onSubmit={handleSubmit(onSubmit)} className="login-form">
                    <Form.Item>
                        <Input
                            className={errors.input ? 'error' : null}
                            placeholder="Input"
                            name={'input'}
                            // defaultValue={1}
                            onChange={(e) => setValue('input', e.target.value)}
                        />
                        {errors.input && <span>input is required</span>}
                    </Form.Item>
                    <Form.Item>
                        <Input.TextArea
                            className={errors.textarea ? 'error' : null}
                            rows={4}
                            placeholder="Textarea"
                            name="textarea"
                            onChange={(e) => setValue('textarea', e.target.value)}
                        />
                        {errors.textarea && errors.textarea.type === 'required' && <span>textarea is required</span>}
                        {errors.textarea && errors.textarea.type === 'minLength' &&
                        <span>textarea min length is 2 charaters</span>}
                    </Form.Item>
                    <Form.Item>
                        <Select
                            className={errors.select ? 'error' : null}
                            name="select"
                            // defaultValue="lucy"
                            onChange={(value) => setValue('select', value)}
                        >
                            <Select.Option value="jack">Jack</Select.Option>
                            <Select.Option value="lucy">Lucy</Select.Option>
                            <Select.Option value="disabled" disabled>
                                Disabled
                            </Select.Option>
                            <Select.Option value="Yiminghe">yiminghe</Select.Option>
                        </Select>
                        {errors.select && <span>select is required</span>}
                    </Form.Item>

                    <Form.Item>
                        <InputNumber
                            className={errors.number ? 'error' : null}
                            name='number'
                            min={1}
                            max={10}
                            onChange={(value) => setValue('number', value)}/>
                        {errors.number && <span>number is required</span>}
                    </Form.Item>
                    <Form.Item>
                        <DatePicker
                            className={errors.datePicker ? 'error' : null}
                            name='datePicker'
                            onChange={(date) => setValue('datePicker', date)}
                        />
                        {errors.datePicker && <span>datePicker is required</span>}
                    </Form.Item>
                    <Form.Item>
                        <Checkbox
                            className={errors.checkbox ? 'error' : null}
                            name='checkbox'
                            onChange={(e) => setValue('checkbox', e.target.checked)}>
                            Checkbox
                        </Checkbox>
                        {errors.checkbox && <span>checkbox is required</span>}
                    </Form.Item>
                    <Form.Item>
                        <Checkbox.Group
                            className={errors.checkboxGroup ? 'error' : null}
                            name='checkboxGroup'
                            options={['a', 'b', 'c']}
                            onChange={(checkedValues) => setValue('checkboxGroup', checkedValues)}
                        />
                        {errors.checkboxGroup && <span>checkboxGroup is required</span>}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Form.Item>
                </Form>
            </div>
        </StickyBreadcrumbPageComponent>
    );
}

export default DemoFormPage;
