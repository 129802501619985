import { Button, notification, Table, Tag } from "antd";
import React, { useState } from "react";

import PropTypes from "prop-types";

import "./deviceList.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import { MOMENT_FORMAT, PAGE } from "../../../utils/constants";
import { replaceUrlByParams } from "@utils/api";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { downloadFile } from "../../../utils/common";

const cleaningJobQrcodeDownload = gql`
  query cleaningJobQrcodeDownload($equipmentId: [String]) {
    cleaningJobQrcodeDownload(equipmentId: $equipmentId) {
      key
      url
      name
      size
    }
  }
`;

const cleaningJobEquipmentServiceItemDownload = gql`
  query cleaningJobEquipmentServiceItemDownload($equipmentId: [String]) {
    cleaningJobEquipmentServiceItemDownload(equipmentId: $equipmentId) {
      key
      url
      name
      size
    }
  }
`;

const cleaningJobEquipmentComplete = gql`
  mutation cleaningJobEquipmentComplete($equipmentId: [String]) {
    cleaningJobEquipmentComplete(equipmentId: $equipmentId) {
      id
    }
  }
`;

const DeviceList = ({
  cleaningJob,
  refetch,
  isLoading: cleaningJobIsLoading,
}) => {
  const [selectMode, setSelectMode] = useState(null);
  const [selectedRowKey, setSelectedRowKey] = useState([]);

  const [downloadCleaningJobQRCode, { loading: qrcodeLoading }] = useLazyQuery(
    cleaningJobQrcodeDownload,
    {
      onCompleted: (data) => {
        downloadFile(data.cleaningJobQrcodeDownload);
      },
    }
  );

  const [
    downloadCleaningJobEquipmentServiceItem,
    { loading: serviceItemLoading },
  ] = useLazyQuery(cleaningJobEquipmentServiceItemDownload, {
    onCompleted: (data) => {
      downloadFile(data.cleaningJobEquipmentServiceItemDownload);
    },
  });

  const [completeCleaningJob, { loading: completeCleaningJobLoading }] =
    useMutation(cleaningJobEquipmentComplete, {
      onCompleted: (data) => {
        setSelectedRowKey([]);
        notification.success({ message: "成功更新洗狀態", duration: 5 });
        refetch();
      },
    });

  const selectLoading =
    qrcodeLoading || serviceItemLoading || completeCleaningJobLoading;

  const columns = [
    {
      title: "器材編號",
      dataIndex: "referenceId",
      render: (text, record) => (
        <Link
          to={replaceUrlByParams(PAGE.THIRD_PARTY_CLEANING_JOB_DEVICE_EDIT, {
            id: cleaningJob?.id,
            deviceId: record?.id,
          })}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "器材名稱",
      dataIndex: "name",
    },
    {
      title: "器材種類",
      render: (_, record) => {
        return record?.otherRemark
          ? `其他：${record?.otherRemark}`
          : record?.equipmentCategory.name;
      },
    },
    {
      title: "新增日期",
      render: () =>
        cleaningJob?.createDate
          ? moment(cleaningJob?.createDate).format(MOMENT_FORMAT)
          : "-",
    },
    {
      title: "清洗日期",
      dataIndex: "cleanFlow.cleaningFlowStage.0.startDate",
      render: (date) => (date ? moment(date).format(MOMENT_FORMAT) : "-"),
    },
    {
      title: "清潔流程",
      dataIndex: "cleanFlow.cleaningFlow.name",
      render: (text) => text ?? "-",
    },
    {
      title: "清洗狀態",
      dataIndex: "status",
      render: (text) =>
        ({
          pending: <Tag className="tag">待清洗</Tag>,
          cleaningFailure: <Tag className="tag danger">未能清洗</Tag>,
          completed: <Tag className="tag success">已完成</Tag>,
        }[text]),
    },
  ];

  const onConfirmSelect = () => {
    switch (selectMode) {
      case "batchComplete":
        completeCleaningJob({
          variables: {
            equipmentId: selectedRowKey?.filter(
              (selectedId) =>
                cleaningJob?.equipment?.find(({ id }) => id === selectedId)
                  ?.status === "pending"
            ),
          },
        });
        break;
      case "qrCode":
        downloadCleaningJobQRCode({
          variables: {
            equipmentId: selectedRowKey,
          },
        });
        break;
      case "serviceReport":
        downloadCleaningJobEquipmentServiceItem({
          variables: {
            equipmentId: selectedRowKey,
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="device-list">
      <div className="space-between-container">
        <div className="button-container">
          <Button
            onClick={() => {
              setSelectMode((prev) =>
                prev === "batchComplete" ? null : "batchComplete"
              );
              setSelectedRowKey([]);
            }}
            type={selectMode === "batchComplete" ? "primary" : "default"}
          >
            (批量) 完成清洗器材
          </Button>
          <Button
            onClick={() => {
              setSelectMode((prev) => (prev === "qrCode" ? null : "qrCode"));
              setSelectedRowKey([]);
            }}
            type={selectMode === "qrCode" ? "primary" : "default"}
          >
            (批量) 下載器材 QR Code
          </Button>
          <Button
            onClick={() => {
              setSelectMode((prev) =>
                prev === "serviceReport" ? null : "serviceReport"
              );
              setSelectedRowKey([]);
            }}
            type={selectMode === "serviceReport" ? "primary" : "default"}
          >
            (批量) 生成服務記錄
          </Button>
        </div>
        <div>
          <Button
            disabled={selectMode === null || selectedRowKey?.length === 0}
            type="primary"
            onClick={onConfirmSelect}
            loading={selectLoading}
          >
            確定
          </Button>
        </div>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={cleaningJob?.equipment}
        loading={cleaningJobIsLoading}
        rowSelection={
          selectMode
            ? {
                selectedRowKeys: selectedRowKey,
                onChange: setSelectedRowKey,
                getCheckboxProps: (record) => {
                  if (
                    selectMode === "batchComplete" &&
                    record?.status !== "pending"
                  )
                    return { disabled: true };
                },
              }
            : undefined
        }
        scroll={{x:"max-content"}}
      />
    </div>
  );
};

DeviceList.propTypes = {
  cleaningJob: PropTypes.object,
  refetch: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default DeviceList;
