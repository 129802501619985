import React, { useCallback, useEffect, useMemo } from "react";
import {
  Drawer,
  Form,
  Input,
  DatePicker,
  Button,
  Typography,
  message,
  InputNumber,
  Icon,
  Upload,
  notification,
} from "antd";
import { useForm } from "react-hook-form";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { getFileUploadUrl } from "../../utils/env";
import { getTokenHeader } from "@utils/api";
import { useState } from "react";

const PURCHASE_INFO_CREATE_DEVICE = gql`
  mutation purchaseInfoCreateOnDevice($input: PurchaseInfoCreateInput) {
    purchaseInfoCreateOnDevice(input: $input) {
      id
    }
  }
`;

const PURCHASE_INFO_UPDATE_DEVICE = gql`
  mutation purchaseInfoUpdateOnDevice($input: PurchaseInfoUpdateInput) {
    purchaseInfoUpdateOnDevice(input: $input) {
      id
    }
  }
`;

const PURCHASE_INFO_CREATE_PART = gql`
  mutation purchaseInfoCreateOnPart($input: PurchaseInfoCreateInput) {
    purchaseInfoCreateOnPart(input: $input) {
      id
    }
  }
`;

const PURCHASE_INFO_UPDATE_PART = gql`
  mutation purchaseInfoUpdateOnPart($input: PurchaseInfoUpdateInput) {
    purchaseInfoUpdateOnPart(input: $input) {
      id
    }
  }
`;

const PURCHASE_INFO_CREATE_CONSUMABLE = gql`
  mutation purchaseInfoCreateOnConsumable($input: PurchaseInfoCreateInput) {
    purchaseInfoCreateOnConsumable(input: $input) {
      id
    }
  }
`;

const PURCHASE_INFO_UPDATE_CONSUMABLE = gql`
  mutation purchaseInfoUpdateOnConsumable($input: PurchaseInfoUpdateInput) {
    purchaseInfoUpdateOnConsumable(input: $input) {
      id
    }
  }
`;

const CreatePurchaseInfoDrawer = (props) => {
  const { defaultValues, onClose, productType = "" } = props;
  const [isUploading, setIsUploading] = useState(false);
  const { errors, register, handleSubmit, watch, setValue } = useForm({
    defaultValues,
  });

  const isConsumable = productType === "consumable" ? true : false;
  const isEdit = defaultValues?.id ? true : false;

  useEffect(() => {
    register({ name: "id" });
    register({ name: "referenceId" }, { required: true });
    register({ name: "productId" }, { required: true });
    register({ name: "distributor" }, { required: true });
    register({ name: "distributorContact" }, { required: true });
    register({ name: "funder" }, { required: true });
    register({ name: "purchaseDate" }, { required: true });
    register({ name: "purchasePrice" }, { required: true });
    register(
      { name: "quantity" },
      { required: !isConsumable || (isConsumable && !isEdit) }
    );
    register({ name: "warranty" }, { required: true });
    register({ name: "document" });
  }, [isConsumable, isEdit, register]);

  useEffect(() => {
    if (defaultValues?.document) {
      const list = defaultValues.document?.map((file) => {
        return {
          ...file,
          uid: file.key,
        };
      });
      setValue("document", list);
    }
  }, [defaultValues, setValue]);

  const formValue = watch();

  const CREATE_MUTATION = useMemo(() => {
    switch (productType) {
      case "device":
        return PURCHASE_INFO_CREATE_DEVICE;
      case "part":
        return PURCHASE_INFO_CREATE_PART;
      case "consumable":
        return PURCHASE_INFO_CREATE_CONSUMABLE;
      default:
        return undefined;
    }
  }, [productType]);

  const UPDATE_MUTATION = useMemo(() => {
    switch (productType) {
      case "device":
        return PURCHASE_INFO_UPDATE_DEVICE;
      case "part":
        return PURCHASE_INFO_UPDATE_PART;
      case "consumable":
        return PURCHASE_INFO_UPDATE_CONSUMABLE;
      default:
        return undefined;
    }
  }, [productType]);

  const [
    createPurchaseInfo,
    { loading: createPurchaseInfoLoading },
  ] = useMutation(CREATE_MUTATION);
  const [
    updatePurchaseInfo,
    { loading: updatePurchaseInfoLoading },
  ] = useMutation(UPDATE_MUTATION);

  const onSubmit = useCallback(
    async (purchaseInfo) => {
      if (isConsumable && isEdit) purchaseInfo.quantity = undefined;

      purchaseInfo.document = (
        purchaseInfo.document ?? []
      ).map(({ key, name, size }) => ({ key, name, size }));

      if (purchaseInfo.id) {
        try {
          await updatePurchaseInfo({
            variables: { input: purchaseInfo },
          });
          onClose();
        } catch (error) {
          message.error(error.message);
        }
      } else {
        try {
          await createPurchaseInfo({
            variables: { input: purchaseInfo },
          });
          onClose();
        } catch (error) {
          message.error(error.message);
        }
      }
    },
    [isConsumable, isEdit, updatePurchaseInfo, onClose, createPurchaseInfo]
  );

  const onFileChange = ({ fileList }) => {
    let fl = [...fileList];
    // console.log(fl);

    // fl = fl.slice(-1);

    fl = fl.map((file) => {
      if (file.error) {
        notification.error({
          message: "上載失敗",
        });
        setIsUploading(false);
      } else if (file?.status === "uploading") {
        setIsUploading(true);
      } else if (file.response) {
        file.name = file.response.data.name;
        file.size = file.response.data.size;
        file.key = file.response.data.key;
        file.url = file.response.data.url;
        setIsUploading(false);
      }
      return file;
    });
    fl = fl.filter((file) => !file.error);
    setValue("document", fl);
  };
  return (
    <Drawer width="50%" visible={true} title="新增採購單" onClose={onClose}>
      <Form layout="horizontal">
        <FormItem label="採購單號">
          <Input
            className={errors?.referenceId ? "error" : null}
            value={formValue?.referenceId}
            onChange={({ target: { value } }) => setValue("referenceId", value)}
          />
          {errors?.referenceId && (
            <Typography.Text type="danger">請填寫採購單號</Typography.Text>
          )}
        </FormItem>
        <FormItem label="分銷商">
          <Input
            className={errors?.distributor ? "error" : null}
            value={formValue?.distributor}
            onChange={({ target: { value } }) => setValue("distributor", value)}
          />
          {errors?.distributor && (
            <Typography.Text type="danger">請填寫分銷商</Typography.Text>
          )}
        </FormItem>
        <FormItem label="分銷商電話">
          <Input
            className={errors?.distributorContact ? "error" : null}
            value={formValue?.distributorContact}
            onChange={({ target: { value } }) =>
              setValue("distributorContact", value)
            }
          />
          {errors?.distributorContact && (
            <Typography.Text type="danger">請填寫分銷商電話</Typography.Text>
          )}
        </FormItem>
        <FormItem label="出資者">
          <Input
            className={errors?.funder ? "error" : null}
            value={formValue?.funder}
            onChange={({ target: { value } }) => setValue("funder", value)}
          />
          {errors?.funder && (
            <Typography.Text type="danger">請填寫出資者</Typography.Text>
          )}
        </FormItem>

        <FormItem label="保養年期">
          <Input
            className={errors?.warranty ? "error" : null}
            value={formValue?.warranty}
            onChange={({ target: { value } }) => setValue("warranty", value)}
          />
          {errors?.warranty && (
            <Typography.Text type="danger">請填寫保養年期</Typography.Text>
          )}
        </FormItem>
        <FormItem label="購買日期">
          <DatePicker
            style={{ width: "100%" }}
            className={errors?.purchaseDate ? "error" : null}
            value={
              formValue?.purchaseDate ? moment(formValue?.purchaseDate) : null
            }
            onChange={(value) =>
              setValue("purchaseDate", moment(value).valueOf())
            }
          />
          {errors?.firstStorageDate && (
            <Typography.Text type="danger">請填寫購買日期</Typography.Text>
          )}
        </FormItem>
        <FormItem label="價格">
          <InputNumber
            className={errors?.purchasePrice ? "error" : null}
            min={0}
            value={formValue?.purchasePrice}
            onChange={(value) => setValue("purchasePrice", value)}
          />
          {errors?.purchasePrice && (
            <Typography.Text type="danger">請填寫價格</Typography.Text>
          )}
        </FormItem>
        {(!isConsumable || (isConsumable && !isEdit)) && (
          <FormItem label="數量">
            <InputNumber
              className={errors?.quantity ? "error" : null}
              min={0}
              value={formValue?.quantity}
              onChange={(value) => setValue("quantity", value)}
            />
            {errors?.quantity && (
              <Typography.Text type="danger">請填寫數量</Typography.Text>
            )}
          </FormItem>
        )}
        <Form.Item label="收據">
          <Upload
            name="file"
            className="thumbnail-uploader"
            action={getFileUploadUrl()}
            disabled={isUploading}
            fileList={formValue.document}
            headers={{
              "x-token": getTokenHeader(),
              "X-Requested-With": null,
            }}
            onChange={onFileChange}
            // onPreview={onFilePreview}
            // onRemove={
            // 	isUploading || IS_READ_ONLY ? null : onFileRemove
            // }
            // showUploadList={{ showDownloadIcon: false }}
            listType="picture-card"
          >
            <Button disabled={isUploading}>
              <Icon type="upload" /> 上載
            </Button>
          </Upload>
          {/* <Modal
						visible={isPreviewShow}
						footer={null}
						onCancel={onFilePreviewCancel}
					>
						<img
							alt="轉介 / 登記表"
							style={{ width: "100%" }}
							src={previewImage}
						/>
					</Modal> */}
        </Form.Item>
      </Form>
      <Button
        onClick={handleSubmit(onSubmit)}
        loading={createPurchaseInfoLoading || updatePurchaseInfoLoading}
        type="primary"
      >
        提交
      </Button>
    </Drawer>
  );
};

CreatePurchaseInfoDrawer.propTypes = {
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
  productType: PropTypes.string,
};

export default CreatePurchaseInfoDrawer;
