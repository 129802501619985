/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Dropdown,
  Icon,
  Menu,
  Modal,
  notification,
  Row,
  Table,
} from "antd";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

// Constants
import { PAGE, PAGE_NAME, PAGE_SIZE, ACCESS_LEVELS } from "@utils/constants";

// Layouts
import TableView from "@layouts/tableView/tableView"; // Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

// Styles
import "./cleaningStage.scss";

// Utils
import { useAccessLevel } from "@utils/hook";
import { downloadFile } from "@utils/common";
import { MOMENT_DATETIME_FORMAT } from "@utils/constants";
import { replaceUrlByParams } from "@utils/api";

import CleaningStageEdit from "./cleaningStageEdit";

const CLEANINGSTAGE_LIST = gql`
  query cleaningStageList($size: Int, $offset: Int) {
    cleaningStageList(size: $size, offset: $offset) {
      id
      name
      remark
      trace {
        createDate
        lastModifiedDate
      }
    }
  }
`;

const DOWNLOAD_CLEANINGSTAGE_QRCODE = gql`
  query cleaningStageQrcodeDownload($cleaningStageId: [ID]) {
    cleaningStageQrcodeDownload(cleaningStageId: $cleaningStageId) {
      key
      url
      name
    }
  }
`;

const CLEANINGSTAGE_DELETE = gql`
  mutation cleaningStageDelete($id: ID) {
    cleaningStageDelete(id: $id)
  }
`;

const CLEANINGSTAGE_COUNT = gql`
  query cleaningStageCount {
    cleaningStageCount
  }
`;

function CleaningStage(props) {
  const history = useHistory();
  const { location } = props;
  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.WORKSHOP_MANAGEMENT,
  ]);

  const [
    getCleaningStageList,
    { data: cleaningStageListData, loading: cleaningStageListLoading },
  ] = useLazyQuery(CLEANINGSTAGE_LIST);

  const [
    getCleaningStageCount,
    { loading: cleaningStageCountLoading, data: cleaningStageCountData },
  ] = useLazyQuery(CLEANINGSTAGE_COUNT);

  const [selectedCleaningStage, setSelectedCleaningStage] = useState([]);
  const [cleaningStageDrawerParams, setCleaningStageDrawerParams] = useState({
    visible: false,
  });

  const BREADCRUMB = [
    {
      path: PAGE.WORKSHOP_CLEANING_STAGE,
      name: PAGE_NAME.WORKSHOP_CLEANING_STAGE,
    },
  ];

  const [
    getCleaningStageQRCode,
    { data: cleaningStageQRCodeData, loading: cleaningStageQRCodeLoading },
  ] = useLazyQuery(DOWNLOAD_CLEANINGSTAGE_QRCODE);

  const [cleaningStageDelete, { data: cleaningStageDeleteData }] =
    useMutation(CLEANINGSTAGE_DELETE);

  const columns = [
    {
      title: "步驟名稱",
      dataIndex: "name",
      width: "15%",
      render: (text, stage) => (
        <Link
          className="link"
          to=""
          onClick={(e) => {
            e.preventDefault();
            setCleaningStageDrawerParams({ visible: true, stage });
          }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "備註",
      dataIndex: "remark",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "新增日期",
      width: "15%",
      dataIndex: "trace.createDate",
      render: (text) => (
        <span>{moment(text).format(MOMENT_DATETIME_FORMAT)}</span>
      ),
    },
    {
      title: "最後更新日期",
      width: "15%",
      dataIndex: "trace.lastModifiedDate",
      render: (text) => (
        <span>{moment(text).format(MOMENT_DATETIME_FORMAT)}</span>
      ),
    },
    {
      dataIndex: "action",
      render: (text, cleaningFlow) => (
        <Dropdown overlay={() => menu(cleaningFlow)}>
          <Button className="btn btn-secondary">
            <Icon type="ellipsis" />
          </Button>
        </Dropdown>
      ),
    },
  ];

  // fetch PAGE_SIZE + 1, to check if there is next page.
  const cleaningStageList = useMemo(() => {
    return (cleaningStageListData?.cleaningStageList || []).slice(0, PAGE_SIZE);
  }, [cleaningStageListData]);

  const isMaxPage = useMemo(() => {
    const queryParams = queryString.parse(location.search);
    return (
      queryParams.pageSize >=
      (cleaningStageListData?.cleaningStageList || []).length
    );
  }, [cleaningStageListData, location.search]);

  const refreshCleaningStageList = useCallback(() => {
    const { keyword, page, pageSize } = queryString.parse(location.search);

    getCleaningStageList({
      variables: {
        keyword,
        offset: +((page - 1) * pageSize) || 0,
        size: +(pageSize || PAGE_SIZE) + 1,
      },
    });

    getCleaningStageCount();
  }, [getCleaningStageCount, getCleaningStageList, location.search]);

  useEffect(() => {
    refreshCleaningStageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const onCleaningStageDelete = useCallback(
    async (stage) => {
      try {
        const { data } = await cleaningStageDelete({
          variables: { id: stage.id },
        });
        if (data) {
          data?.cleaningStageDelete
            ? notification.success({ message: "刪除成功", duration: 5 })
            : notification.error({ message: "刪除失敗", duration: 5 });
        }
      } catch (error) {
        notification.error(error.message);
      } finally {
        refreshCleaningStageList();
      }
    },
    [cleaningStageDelete, refreshCleaningStageList]
  );

  const onMenuItemClick = ({ key, stage }) => {
    switch (key) {
      case "edit": {
        setCleaningStageDrawerParams({ visible: true, stage });
        break;
      }
      case "remove": {
        Modal.confirm({
          title: `刪除清潔步驟`,
          content: `確定刪除清潔步驟 ${stage?.name} ?`,
          okText: "確定",
          cancelText: "取消",
          onOk() {
            onCleaningStageDelete(stage);
          },
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  const menu = (stage) => (
    <Menu
      onClick={({ key }) => {
        onMenuItemClick({ key, stage });
      }}
    >
      <Menu.Item key="edit">編輯步驟</Menu.Item>
      {!isDenied && <Menu.Item key="remove">刪除步驟</Menu.Item>}
    </Menu>
  );

  useEffect(() => {
    if (cleaningStageQRCodeData)
      downloadFile(cleaningStageQRCodeData?.cleaningStageQrcodeDownload);
  }, [cleaningStageQRCodeData]);

  const onDownloadCleaningStage = () => {
    getCleaningStageQRCode({
      variables: { cleaningStageId: selectedCleaningStage },
    });
  };

  return (
    <>
      {cleaningStageDrawerParams.visible && (
        <CleaningStageEdit
          cleaningStageDrawerParams={cleaningStageDrawerParams}
          onClose={() => {
            setCleaningStageDrawerParams({ visible: false });
          }}
          onSuccess={refreshCleaningStageList}
        />
      )}
      <TableView
        {...props}
        addBtnConfig={{
          text: "新增",
          onClick: () => setCleaningStageDrawerParams({ visible: true }),
        }}
        breadcrumb={BREADCRUMB}
        rowKey="id"
        columns={columns}
        {...(!isDenied && { hasAddBtn: true })}
        searchBarPlaceholder="搜尋清潔步驟"
        isMaxPage={isMaxPage}
        isTableLoading={cleaningStageListLoading}
        tableData={cleaningStageList}
        extraBtn={
          !isDenied && (
            <>
              <Button
                className="btn ant-btn-primary"
                onClick={onDownloadCleaningStage}
                disabled={selectedCleaningStage.length === 0}
                loading={cleaningStageQRCodeLoading}
              >
                下載已選擇清潔步驟 QR Code
              </Button>
            </>
          )
        }
        rowSelection={
          !isDenied && {
            onChange: (selectedRowKeys, selectedRows) =>
              setSelectedCleaningStage(selectedRowKeys),
          }
        }
        showSearchBar={false}
        total={cleaningStageCountData?.cleaningStageCount}
      ></TableView>
    </>
  );
}

export default CleaningStage;

CleaningStage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
