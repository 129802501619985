/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";

// Components
import Filter from "@components/filter/orderingFilter/orderingFilter";

// Constants
import { PAGE, PAGE_NAME, PAGE_SIZE } from "@utils/constants";

// Layouts
import TableView from "@layouts/tableView/tableView";

// Styles
import "./orderingList.scss";

// Utils
import { MOMENT_FORMAT, MOMENT_DATETIME_FORMAT } from "@utils/constants";
import { replaceUrlByParams } from "@utils/api";
import moment from "moment";
import { Tag } from "antd";

const ORDERING_LIST = gql`
  query orderingList(
    $orderingStatus: [OrderingStatus]
    $orderingDeliveryStatus: [OrderingDeliveryStatus]
    $paymentStatus: [PaymentStatus]
    $keyword: String
    $redPointOnly: Boolean
    $sort: SortOrderingList
    $reverseOrder: Boolean
    $size: Int!
    $offset: Int!
    $nextAssessmentDateStart: Timestamp
    $nextAssessmentDateEnd: Timestamp
  ) {
    orderingList(
      orderingStatus: $orderingStatus
      orderingDeliveryStatus: $orderingDeliveryStatus
      paymentStatus: $paymentStatus
      keyword: $keyword
      redPointOnly: $redPointOnly
      sort: $sort
      reverseOrder: $reverseOrder
      size: $size
      offset: $offset
      nextAssessmentDateStart: $nextAssessmentDateStart
      nextAssessmentDateEnd: $nextAssessmentDateEnd
    ) {
      user {
        userId
        username
        nameChi
        deleted
      }
      orderingStatus {
        id
        name
      }
      orderingDeliveryStatus {
        id
        name
      }
      paymentStatus {
        id
        name
      }

      redDot {
        missingActiveServiceContract
        missingServiceStartDate
        expiryAssessment
        expiryService
        unissuingPaymentStatement
      }
      nextAssessmentDate
      lastUpdateDate
    }
  }
`;

const ORDERING_COUNT = gql`
  query orderingCount (
    $orderingStatus: [OrderingStatus]
    $orderingDeliveryStatus: [OrderingDeliveryStatus]
    $paymentStatus: [PaymentStatus]
    $keyword: String
    $nextAssessmentDateStart: Timestamp
    $nextAssessmentDateEnd: Timestamp
    $redPointOnly: Boolean
  ) {
    orderingCount(
      orderingStatus: $orderingStatus
      orderingDeliveryStatus: $orderingDeliveryStatus
      paymentStatus: $paymentStatus
      keyword: $keyword
      nextAssessmentDateStart: $nextAssessmentDateStart
      nextAssessmentDateEnd: $nextAssessmentDateEnd
      redPointOnly: $redPointOnly
    )
  }
`;

function OrderingList(props) {
  const { location, breadcrumb, availableOrderingStatus } = props;

  let history = useHistory();

  const columns = [
    {
      title: "用戶編號",
      dataIndex: "user.username",
      render: (text, record) => {
        const redPoint = Object.values(record?.redDot ?? {}).reduce(
          (acc, flag) => {
            if (typeof flag === "boolean") return acc || flag;
            return acc;
          },
          false
        );
        return (
          <>
            {redPoint && <span className="redPoint"></span>}
            {
              <Link
                style={{ textDecoration: "underline" }}
                className="link"
                to={replaceUrlByParams(PAGE.ORDERING_DETAIL, {
                  id: record.user.userId,
                })}
              >
                {text}
              </Link>
            }
          </>
        );
      },
    },
    {
      title: "用戶名稱",
      dataIndex: "user.nameChi",
      render: (text) => <>{text || "-"}</>,
    },
    {
      title: "訂單狀態",
      dataIndex: "orderingStatus",
      render: (text, record) => {
        const tagStatus = {
          waiting: "",
          reserved: "warning",
          unpaidInFirstPayment: "warning",
          toBeTerminate: "danger",
          terminated: "danger",
          inService: "success",
        }[record.orderingStatus?.id];

        return (
          <Tag className={`tag ${tagStatus}`}>
            {record.orderingStatus?.name}
          </Tag>
        );
      },
    },
    {
      title: "繳費狀態",
      dataIndex: "paymentStatus",
      render: (text, record) => {
        const tagStatus = {
          pending: "warining",
          paidManually: "",
          paid: "success",
          overdue: "danger",
          confirmed: "",
          toBeRefunded: "",
          refunded: "",
        }[record.paymentStatus?.id];

        return record.paymentStatus ? (
          <Tag className={`tag ${tagStatus}`}>
            {record.paymentStatus?.name || "-"}
          </Tag>
        ) : (
          <>-</>
        );
      },
    },
    {
      title: "放貨狀態",
      dataIndex: "orderingDeliveryStatus",
      render: (text, record) => {
        const tagStatus = {
          pending: "warning",
          confirmedDelivery: "success",
          inventoryReady: "",
          checkedOut: "",
          delivered: "",
          cancelled: "danger",
          confirmedReclaim: "",
          reclaimed: "",
        }[record.orderingDeliveryStatus?.id];

        return record.orderingDeliveryStatus ? (
          <Tag className={`tag ${tagStatus}`}>
            {record.orderingDeliveryStatus?.name || "-"}
          </Tag>
        ) : (
          <>-</>
        );
      },
    },
    {
      title: "下一個預計評估日期",
      dataIndex: "nextAssessmentDate",
      render: (text) => <>{text ? moment(text).format(MOMENT_FORMAT) : "-"}</>,
    },
    {
      title: "最後更新日期",
      dataIndex: "lastUpdateDate",
      sorter: true,
      render: (text) => (
        <>{text ? moment(text).format(MOMENT_DATETIME_FORMAT) : "-"}</>
      ),
    },
  ];

  const [
    getOrderingList,
    { loading: getOrderingListLoading, data: getOrderingListData },
  ] = useLazyQuery(ORDERING_LIST);

  const [
    getOrderingCount, {
      loading: getOrderingCountLoading,
      data: getOrderingCountData
    },
  ] = useLazyQuery(ORDERING_COUNT);

  const [query, setQuery] = useState(queryString.parse(location.search));
  const [isFilterShow, setIsFilterShow] = useState(false);

  const orderingList = useMemo(() => {
    return (
      getOrderingListData?.orderingList?.filter(
        (order) => order?.user?.deleted !== true
      ) || []
    ).slice(0, PAGE_SIZE);
  }, [getOrderingListData]);

  const isMaxPage = useMemo(() => {
    return getOrderingListData?.orderingList?.length <= PAGE_SIZE;
  }, [getOrderingListData]);

  useEffect(() => {
    setQuery(queryString.parse(location.search));
  }, [location.search]);

  useEffect(() => {
    const {
      page,
      pageSize,
      keyword,
      orderingStatus,
      paymentStatus,
      orderingDeliveryStatus,
      redPointOnly,
      sortField,
      sortOrder,
      nextAssessmentDateStart,
      nextAssessmentDateEnd,
    } = queryString.parse(location.search);

    if (Object.prototype.hasOwnProperty.call(query, "keyword")) {
      setIsFilterShow(false);
    }

    let nOrderingStatus = [];
    if (!orderingStatus) {
      nOrderingStatus = [...availableOrderingStatus];
    } else {
      nOrderingStatus = orderingStatus;
    }

    getOrderingList({
      variables: {
        offset: +((page - 1) * pageSize) || 0,
        size: +(pageSize || PAGE_SIZE) + 1,
        keyword: keyword,
        orderingStatus: nOrderingStatus,
        paymentStatus: paymentStatus,
        orderingDeliveryStatus: orderingDeliveryStatus,
        redPointOnly: redPointOnly === "true",
        nextAssessmentDateStart: parseInt(nextAssessmentDateStart),
        nextAssessmentDateEnd: parseInt(nextAssessmentDateEnd),
        sort: sortField,
        reverseOrder: sortOrder ? sortOrder === "descend" : undefined,
      },
    });

    getOrderingCount({
      variables: {
        keyword: keyword,
        orderingStatus: nOrderingStatus,
        paymentStatus: paymentStatus,
        orderingDeliveryStatus: orderingDeliveryStatus,
        redPointOnly: redPointOnly === "true",
        nextAssessmentDateStart: parseInt(nextAssessmentDateStart),
        nextAssessmentDateEnd: parseInt(nextAssessmentDateEnd),
      },
    });

    // eslint-disable-next-line
  }, [location]);

  const onOrderingStatusChange = (val) => {
    setQuery({
      ...query,
      orderingStatus: val,
    });
  };

  const onOrderingDeliveryStatusChange = (val) => {
    setQuery({
      ...query,
      orderingDeliveryStatus: val,
    });
  };

  const onPaymentStatusChange = (val) => {
    setQuery({
      ...query,
      paymentStatus: val,
    });
  };

  const onRedPointOnly = (e) => {
    setQuery({
      ...query,
      redPointOnly: e.target.checked,
    });
  };

  const onAssessmentDateChange = (dates) => {
    setQuery({
      ...query,
      nextAssessmentDateStart: dates.length
        ? dates[0].valueOf().toString()
        : undefined,
      nextAssessmentDateEnd: dates.length
        ? dates[1].valueOf().toString()
        : undefined,
    });
  };

  const onResetFilter = () => {
    history.push({
      search: queryString.stringify({ page: 1, pageSize: PAGE_SIZE }),
    });
    setQuery(queryString.parse(location.search));
  };

  const onFilterSearch = () => {
    history.push({
      search: queryString.stringify(query),
    });
  };

  const onFilterClick = (state) => {
    setIsFilterShow(state);
  };

  return (
    <TableView
      className="ordering-list"
      {...props}
      breadcrumb={breadcrumb}
      rowKey="contract"
      columns={columns}
      hasFilter
      isFilterShow={isFilterShow}
      isMaxPage={isMaxPage}
      isTableLoading={getOrderingListLoading}
      onFilterClick={onFilterClick}
      searchBarPlaceholder="輸入用戶名稱名稱/聯絡號碼"
      tableData={orderingList}
      total={getOrderingCountData?.orderingCount}
    >
      <Filter
        filterList={[
          "orderingStatus",
          "orderingDeliveryStatus",
          "paymentStatus",
          "assessmentStartDate",
          "redPointOnly",
        ]}
        isFilterShow={isFilterShow}
        onOrderingStatusChange={onOrderingStatusChange}
        onOrderingDeliveryStatusChange={onOrderingDeliveryStatusChange}
        onPaymentStatusChange={onPaymentStatusChange}
        onRedPointOnly={onRedPointOnly}
        onAssessmentDateChange={onAssessmentDateChange}
        onResetFilter={onResetFilter}
        onFilterSearch={onFilterSearch}
        query={query}
        availableOrderingStatus={availableOrderingStatus}
      />
    </TableView>
  );
}

export default OrderingList;

OrderingList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  availableOrderingStatus: PropTypes.array,
};
