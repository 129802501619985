import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

// Components
import {Checkbox} from 'antd'

// Style
import './checkbox.scss';

// Utils
import {classList} from '@utils/common';

function AssessmentCheckboxComponent(props) {
    const {
        id,
        className,
        checkboxAnswer,
        label,
        onChange,
    } = props;

    useEffect(() => {
        onChange(checkboxAnswer);

        // eslint-disable-next-line
    }, []);

    const getClassList = () => classList({
        'Assessment-Component': true,
        'AssessmentCheckbox-Component': true,
        [className]: true
    });

    return (
        <div className={getClassList()}>
            <Checkbox
                id={id}
                name={id}
                className='AssessmentCheckbox-Component-checkbox'
                defaultChecked={checkboxAnswer}
                onChange={(event) => onChange(event.target.checked)}>
            </Checkbox>
            {label && (
                <label
                    className='AssessmentCheckbox-Component-label'
                    htmlFor={id}>
                    {label}
                </label>
            )}
        </div>
    );
}

export default AssessmentCheckboxComponent;

AssessmentCheckboxComponent.propTypes = {
    id: PropTypes.string.isRequired,
    checkboxAnswer: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

AssessmentCheckboxComponent.defalutProps = {
    checkboxAnswer: false,
    className: '',
    label: null,
};
