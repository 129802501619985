/* eslint-disable no-unused-vars */
import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";

// Constant
import { PAGE } from "@utils/constants";

// Pages
import MobileScannerPage from "@pages/workshop/scanner/workshopScanner";
import MobileInventoryDetailPage from "@pages/workshop/inventoryDetail/inventoryDetail";
import InspectionForm from "@pages/workshop/inspectionForm/inspectionForm";

// Utils
import { replaceUrlByParams } from "@utils/api";
export default withRouter(AppMainRouter);

function AppMainRouter(props) {
  return (
    <>
      <Switch>
        <Route exact path={PAGE.MOBILE_SCANNER} component={MobileScannerPage} />
        <Route
          exact
          path={`${replaceUrlByParams(PAGE.MOBILE_INVENTORY_DETAIL, {
            id: ":id",
          })}`}
          component={MobileInventoryDetailPage}
        />
        <Route
          exact
          path={`${replaceUrlByParams(PAGE.MOBILE_INSPECTION_FORM, {
            id: ":id",
          })}`}
          component={InspectionForm}
        />

        <Redirect to={PAGE.MOBILE_SCANNER} />
      </Switch>
    </>
  );
}
