import React from "react";
import PropTypes from "prop-types";

// Components
import { Button, Typography } from "antd";

// Styles
import "./tableFilter.scss";

// Utils

function TableFilter({
  onSearch = () => undefined,
  onReset = () => undefined,
  children,
}) {
  return (
    <div className="table-filter">
      <div className="filter-container">
        <div className="options-container">
          <Typography>篩選條件</Typography>
          <div className="options">{children}</div>
        </div>
        <div className="btn-container">
          <Button className="btn btn-secondary" onClick={onReset}>
            清除篩選條件
          </Button>
          <Button className="btn" type="primary" onClick={onSearch}>
            篩選
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TableFilter;

TableFilter.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  children: PropTypes.node,
};
