/** @module Utilities - Validation */

/**
 * Check value is null or undefined
 * @function
 * @param {string} value - Input value
 * @return {boolean}
 */
export const isNull = (value) => (
    value === null || value === undefined
);

/**
 * Check value is empty or null or undefined
 * @function
 * @param {string | array } value - Input value
 * @return {boolean} - Value is empty
 */
export const isEmpty = (value) => (
    isNull(value) || value.toString().length === 0
);

/**
 * Check object is empty
 * @function
 * @param {object } obj - Input object
 * @return {boolean} - Object is empty
 */
export const isEmptyObj = (obj) => (
    Object.entries(obj).length === 0
);

/**
 * Check string is space only string
 * @function
 * @param {string} value - Input value
 * @return {boolean} - Value is space only
 */
export const isSpaceString = (value) => {
    if (isNull(value)) {
        return false
    }

    return !value.replace(/\s/g, '').length;
};

/**
 * Check value by regex
 * @function
 * @param {string} value - Input value
 * @param {string} reg - Regular expression for checking value
 * @return {boolean} - Value match with the regex pattern, null or undefined value should return false
 */
export const isValidInputPattern = (value, reg) => {
    // undefined and null will return true for pattern checking
    if (isEmpty(value) || isNull(reg)) {
        return false;
    }
    return reg.test(value);
};

/**
 * Check value is within the range, i.e. min <= value.length <= max
 * @function
 * @param {string} value - Input value
 * @param {string} min - Min length
 * @param {string} max - Max length
 * @return {boolean} - Value is within the range
 */
export const isValidLength = (value, min = 0, max = Number.MAX_SAFE_INTEGER) => {
    if (isNull(value)) {
        return false;
    }

    const inputValue = value.toString();
    return inputValue.length >= min && (!max || inputValue.length <= max);
};

/**
 * Check value is matched password format
 * @function
 * @param {string} value - Input password
 * @return {passwordErrorCodes} - Error codes object
 */
export const isValidPassword = (value) => ({
    isValidLength: isValidLength(value, 8, 36),
    isValidWord: isValidInputPattern(value, /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/),
    containsSpecialChar: isValidInputPattern(value, /[@"|!#$%&/()=?»«£§€{}\\.\-;'<>_,]/),
});


/**
 * Check email pattern
 * @function
 * @param {string} value - Input email
 * @return {boolean} - Email is valid
 */
export const isValidEmail = (value) => (
    isValidInputPattern(value, /^[\w-.]{2,}@([\w-]{2,}\.)+[\w-]{2,}$/) && isValidLength(value, 0, 200)
);

/**
 * Check value isn't including a garbled
 * @function
 * @param {string} value - Input value
 * @return {boolean} - Valid value
 */
export const isValidGarbled = (value) => (
    isValidInputPattern(value, /^[\u4e00-\u9fa5A-Za-z0-9\s`~!@#$%^&*()_+=\-[\]\\{}|;':",./<>?]*$/)
);

/**
 * Check value is empty or null or undefined
 * @function
 * @param {string} value - Input value
 * @return {boolean} - Value is a number
 */
export const isNumberOnly = (value) => isValidInputPattern(value, /^(\s{0}|\d+)$/);

/**
 * Check value contains english letters and numbers only
 * @function
 * @param {string} value - Input value
 * @return {boolean} - Value is a number
 */
export const isAlphaNumeric = (value) => isValidInputPattern(value, /^[A-Za-z0-9]+$/);
