/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// Actions
import {
  setIsLoggedInAction,
  setUserAction,
  setIsLoadingAction,
} from "@actions/storeAction";

// Components
import {
  Menu,
  Typography,
  Layout,
  Avatar,
  Dropdown,
  Card,
  Icon,
  notification,
  Button,
} from "antd";

import AlertPanelDrawer from "@components/alertPanelDrawer/alertPanelDrawer";

// Constants
import { PAGE, PAGE_NAME, KEY } from "@utils/constants";

// Style
import "./navbar.scss";

// Utils
import { setTokenHeader } from "@utils/api";
import { isDev } from "@utils/env";

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

function NavbarComponent() {
  const dispatch = useDispatch();
  let history = useHistory();
  const store = useSelector((state) => state.storeReducer);

  const { user } = store;

  const [
    logout,
    { loading: logoutLoading, error: logoutError, data: logoutData },
  ] = useMutation(LOGOUT);

  const windowInnerWidth = useRef(window.innerWidth);

  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(
    windowInnerWidth.current <= 768 ? true : false
  );
  const [alertPanelVisible, setAlertPanelVisible] = useState(false);

  useEffect(() => {
    if (!isCollapsed) {
      const currPath = `/${history.location.pathname.split("/")[1]}`;
      // console.log(currPath);
      const key = Object.keys(PAGE).find((key) => PAGE[key] === currPath);

      if (key) {
        const keyArray = key.split("_");
        // console.log("keyArray", key, keyArray);

        setOpenKeys([
          keyArray[0] === "STOCK" && keyArray[1] === "DELIVERY"
            ? KEY["STOCK_DELIVERY"]
            : KEY[keyArray[0]],
        ]);
        // setOpenKeys((prev) => {
        // const newArray = [...prev];
        // const toPush =
        //   keyArray[0] === "STOCK" && keyArray[1] === "DELIVERY"
        //     ? KEY["STOCK_DELIVERY"]
        //     : KEY[keyArray[0]];
        // if (!newArray.find( item => item === toPush)) newArray.push(toPush);
        // return newArray;
        // });
        setSelectedKeys([KEY[key]]);
      }
    }
  }, [history.location.pathname, isCollapsed]);

  useEffect(() => {
    if (logoutData) {
      if (logoutData.logout) {
        setTokenHeader();
        dispatch(setIsLoggedInAction(false));
        dispatch(setUserAction());
        history.push("/login");
      } else {
        notification.error({
          message: "請重試",
        });
      }

      dispatch(setIsLoadingAction(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutLoading, logoutError, logoutData]);

  const onLogoutClick = async () => {
    dispatch(setIsLoadingAction(true));
    await logout();
  };

  const onSubMenuChange = (e) => {
    const keyIndex = openKeys.indexOf(e.key);
    // console.log("openKeys", openKeys, e.key, keyIndex)
    setOpenKeys((prevState) => {
      const prevStateArray = [];
      if (keyIndex === -1) {
        prevStateArray.push(e.key);
      } else {
        prevStateArray.splice(keyIndex, 1);
      }
      return prevStateArray;
    });
  };

  const onSectionChange = (e) => {
    // console.log("selectedKeys",selectedKeys, e.key)
    setSelectedKeys([e.key]);
    if (isCollapsed) setOpenKeys([]);
  };

  const userCard = (
    <div>
      <Card id="navbar-user-card" selectable="true">
        {isCollapsed && (
          <div>
            <div className="username">{user.displayName}</div>
          </div>
        )}
        <Typography.Text className="logout-btn" onClick={onLogoutClick}>
          登出
        </Typography.Text>
      </Card>
    </div>
  );

  const onCollaspe = (collapsed) => {
    setIsCollapsed(collapsed);
    setOpenKeys([]);
  };

  return (
    <Layout.Sider
      id="Navbar"
      className="Navbar-component"
      collapsible
      collapsed={isCollapsed}
      theme={"light"}
      onCollapse={onCollaspe}
      width="220"
      breakpoint="sm"
    >
      <AlertPanelDrawer
        visible={alertPanelVisible}
        onClose={() => setAlertPanelVisible(false)}
      />
      <div className="logo-container">
        <Button
          className="alert-btn"
          type="link"
          onClick={() => setAlertPanelVisible(true)}
        >
          <Icon className="alert-icon" type="bell" theme="filled" />
        </Button>
        {/*TODO TBC*/}
        {/*<div className='logo'/>
         */}
        <Dropdown overlay={userCard}>
          <div>
            {!isCollapsed ? (
              <>
                <Avatar>{user.displayName[0]}</Avatar>
                <div className="username">{user.displayName}</div>
              </>
            ) : (
              <Avatar>{user.displayName[0]}</Avatar>
            )}
          </div>
        </Dropdown>
      </div>
      <Menu
        className="menu"
        onClick={onSectionChange}
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        mode="inline"
      >
        <Menu.Item key={KEY.OVERVIEW}>
          <Link to={PAGE.OVERVIEW}>
            <Icon type="dashboard" />
            <span>{PAGE_NAME.OVERVIEW}</span>
          </Link>
        </Menu.Item>
        <Menu.SubMenu
          key={KEY.USER}
          title={
            <span>
              <Icon type="user" />
              <span>{PAGE_NAME.USER}</span>
            </span>
          }
          onTitleClick={onSubMenuChange}
        >
          <Menu.Item key={KEY.USER_SEARCH}>
            <Link to={PAGE.USER_SEARCH}>
              <div>
                <Typography.Text>{PAGE_NAME.USER_SEARCH}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.USER_PENDING}>
            <Link to={PAGE.USER_PENDING}>
              <div>
                <Typography.Text>{PAGE_NAME.USER_PENDING}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.USER_REJECTED}>
            <Link to={PAGE.USER_REJECTED}>
              <div>
                <Typography.Text>{PAGE_NAME.USER_REJECTED}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.USER_REGISTERED}>
            <Link to={PAGE.USER_REGISTERED}>
              <div>
                <Typography.Text>{PAGE_NAME.USER_REGISTERED}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key={KEY.ORDER}
          title={
            <span>
              <Icon type="file" />
              <span>{PAGE_NAME.ORDER}</span>
            </span>
          }
          onTitleClick={onSubMenuChange}
        >
          <Menu.Item key={KEY.ORDER_RESERVE}>
            <Link to={PAGE.ORDER_RESERVE}>
              <div>
                <Typography.Text>{PAGE_NAME.ORDER_RESERVE}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.ORDER_IN_SERVICE}>
            <Link to={PAGE.ORDER_IN_SERVICE}>
              <div>
                <Typography.Text>{PAGE_NAME.ORDER_IN_SERVICE}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.ORDER_TO_BE_TERMINATE}>
            <Link to={PAGE.ORDER_TO_BE_TERMINATE}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.ORDER_TO_BE_TERMINATE}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.ORDER_TERMINATED}>
            <Link to={PAGE.ORDER_TERMINATED}>
              <div>
                <Typography.Text>{PAGE_NAME.ORDER_TERMINATED}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key={KEY.STOCK_DELIVERY}
          title={
            <span>
              <Icon type="folder" />
              <span>{PAGE_NAME.STOCK_DELIVERY}</span>
            </span>
          }
          onTitleClick={onSubMenuChange}
        >
          <Menu.Item key={KEY.STOCK_DELIVERY_ALL}>
            <Link to={PAGE.STOCK_DELIVERY_ALL}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.STOCK_DELIVERY_ALL}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="stock-delivery-pending">
            <Link to={PAGE.STOCK_DELIVERY_PENDING}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.STOCK_DELIVERY_PENDING}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.STOCK_DELIVERY_CONFIRMED}>
            <Link to={PAGE.STOCK_DELIVERY_CONFIRMED}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.STOCK_DELIVERY_CONFIRMED}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.STOCK_DELIVERY_COMPLETED}>
            <Link to={PAGE.STOCK_DELIVERY_COMPLETED}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.STOCK_DELIVERY_COMPLETED}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key={KEY.STOCK}
          title={
            <span>
              <Icon type="folder" />
              <span>{PAGE_NAME.STOCK}</span>
            </span>
          }
          onTitleClick={onSubMenuChange}
        >
          <Menu.Item key={KEY.STOCK_INVENTORY}>
            <Link to={PAGE.STOCK_INVENTORY}>
              <div>
                <Typography.Text>{PAGE_NAME.STOCK_INVENTORY}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.STOCK_DEVICE}>
            <Link to={PAGE.STOCK_DEVICE}>
              <div>
                <Typography.Text>{PAGE_NAME.STOCK_DEVICE}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.STOCK_PART}>
            <Link to={PAGE.STOCK_PART}>
              <div>
                <Typography.Text>{PAGE_NAME.STOCK_PART}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.STOCK_CONSUMABLE}>
            <Link to={PAGE.STOCK_CONSUMABLE}>
              <div>
                <Typography.Text>{PAGE_NAME.STOCK_CONSUMABLE}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.STOCK_SERVICE}>
            <Link to={PAGE.STOCK_SERVICE}>
              <div>
                <Typography.Text>{PAGE_NAME.STOCK_SERVICE}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.STOCK_CATEGORY}>
            <Link to={PAGE.STOCK_CATEGORY}>
              <div>
                <Typography.Text>{PAGE_NAME.STOCK_CATEGORY}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.STOCK_USER_DISCOUNT}>
            <Link to={PAGE.STOCK_USER_DISCOUNT}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.STOCK_USER_DISCOUNT}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          {/* <Menu.Item key={KEY.STOCK_CHECKING_RECORD}>
                        <Link to={PAGE.STOCK_CHECKING_RECORD}>
                            <div>
                                <Typography.Text>
                                    {PAGE_NAME.STOCK_CHECKING_RECORD}
                                </Typography.Text>
                            </div>
                        </Link>
                    </Menu.Item> */}
        </Menu.SubMenu>
        <Menu.SubMenu
          key={KEY.WORKSHOP}
          title={
            <span>
              <Icon type="home" />
              <span>{PAGE_NAME.WORKSHOP}</span>
            </span>
          }
          onTitleClick={onSubMenuChange}
        >
          <Menu.Item key={KEY.WORKSHOP_DASHBOARD}>
            <Link to={PAGE.WORKSHOP_DASHBOARD}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.WORKSHOP_DASHBOARD}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.WORKSHOP_INSPECTION_CHECKLIST}>
            <Link to={PAGE.WORKSHOP_INSPECTION_CHECKLIST}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.WORKSHOP_INSPECTION_CHECKLIST}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.WORKSHOP_CLEANING_FLOW}>
            <Link to={PAGE.WORKSHOP_CLEANING_FLOW}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.WORKSHOP_CLEANING_FLOW}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.WORKSHOP_CLEANING_STAGE}>
            <Link to={PAGE.WORKSHOP_CLEANING_STAGE}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.WORKSHOP_CLEANING_STAGE}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.WORKSHOP_WAREHOUSE}>
            <Link to={PAGE.WORKSHOP_WAREHOUSE}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.WORKSHOP_WAREHOUSE}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.WORKSHOP_SCANNER}>
            <Link to={PAGE.WORKSHOP_SCANNER}>
              <div>
                <span>{PAGE_NAME.WORKSHOP_SCANNER}</span>
              </div>
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key={KEY.THIRD_PARTY_CLEANING}
          title={
            <span>
              <Icon type="coffee" />
              <span>{PAGE_NAME.THIRD_PARTY_CLEANING}</span>
            </span>
          }
          onTitleClick={onSubMenuChange}
        >
          <Menu.Item key={KEY.THIRD_PARTY_CLEANING_BUYER_LIST}>
            <Link to={PAGE.THIRD_PARTY_CLEANING_BUYER_LIST}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.THIRD_PARTY_CLEANING_BUYER_LIST}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.THIRD_PARTY_CLEANING_JOB_LIST}>
            <Link to={PAGE.THIRD_PARTY_CLEANING_JOB_LIST}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.THIRD_PARTY_CLEANING_JOB_LIST}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.THIRD_PARTY_CLEANING_CATEGORY_LIST}>
            <Link to={PAGE.THIRD_PARTY_CLEANING_CATEGORY_LIST}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.THIRD_PARTY_CLEANING_CATEGORY_LIST}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.THIRD_PARTY_CLEANING_REPORTS}>
            <Link to={PAGE.THIRD_PARTY_CLEANING_REPORTS}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.THIRD_PARTY_CLEANING_REPORTS}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.THIRD_PARTY_CLEANING_TAG_LIST}>
            <Link to={PAGE.THIRD_PARTY_CLEANING_TAG_LIST}>
              <div>
                <Typography.Text>
                  {PAGE_NAME.THIRD_PARTY_CLEANING_TAG_LIST}
                </Typography.Text>
              </div>
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key={KEY.SYSTEM}
          title={
            <span>
              <Icon type="tool" />
              <span>{PAGE_NAME.SYSTEM}</span>
            </span>
          }
          onTitleClick={onSubMenuChange}
        >
          <Menu.Item key={KEY.SYSTEM_REPORTS}>
            <Link to={PAGE.SYSTEM_REPORTS}>
              <div>
                <Typography.Text>{PAGE_NAME.SYSTEM_REPORTS}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key={KEY.SYSTEM_TOOLS}>
            <Link to={PAGE.SYSTEM_TOOLS}>
              <div>
                <Typography.Text>{PAGE_NAME.SYSTEM_TOOLS}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
        {isDev() && (
          <Menu.Item key={KEY.DEBUG}>
            <Link to={PAGE.DEBUG}>
              <div>
                <Icon type="bug" />
                <Typography.Text>{PAGE_NAME.DEBUG}</Typography.Text>
              </div>
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </Layout.Sider>
  );
}

export default NavbarComponent;
