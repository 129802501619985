import React from "react";
import { Table } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import {
  MOMENT_DATETIME_FORMAT,
  MOMENT_FORMAT,
  PAGE,
} from "../../../utils/constants";
import { Link } from "react-router-dom";

import { replaceUrlByParams } from "@utils/api";

const CleaningJobHistoryTable = ({
  serviceBuyer,
  refetch,
  isLoading: buyerIsLoading,
}) => {
  const isLoading = buyerIsLoading;

  const columns = [
    {
      title: "工作編號",
      dataIndex: "referenceId",
      render: (referenceId, record) => (
        <Link
          to={replaceUrlByParams(PAGE.THIRD_PARTY_CLEANING_JOB_EDIT, {
            id: record?.id,
          })}
        >
          {referenceId}
        </Link>
      ),
    },
    {
      title: "新增日期",
      dataIndex: "createDate",
      sorter: (a, b) =>
        moment(a.uploadDate).valueOf() > moment(b.uploadDate).valueOf()
          ? 1
          : -1,
      sortDirection: ["descend", "ascend"],
      render: (timestamp) => moment(timestamp).format(MOMENT_DATETIME_FORMAT),
    },
    {
      title: "預計清洗日期",
      dataIndex: "reservationDate",
      sorter: (a, b) =>
        moment(a.reservationDate).valueOf() >
        moment(b.reservationDate).valueOf()
          ? 1
          : -1,
      sortDirection: ["descend", "ascend"],
      render: (timestamp) => moment(timestamp).format(MOMENT_FORMAT),
    },
  ];

  return (
    <div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={serviceBuyer?.cleaningJob}
        scroll={{x:"max-content"}}
      />
    </div>
  );
};

CleaningJobHistoryTable.propTypes = {
  serviceBuyer: PropTypes.object,
  refetch: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default CleaningJobHistoryTable;
