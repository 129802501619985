/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Styles
import "./additionalInfo.scss";

// Utils
import { Button, Col, Form, Icon, Input, Modal, Row, Upload } from "antd";
import { getTokenHeader } from "@utils/api";
import { getFileUploadUrl } from "@utils/env";

const PRODUCT_INFO_FRAGMENT = gql`
  fragment Fields on ProductInfo {
    photoList {
      key
      url
      name
      size
    }
    brochureList {
      key
      url
      name
      size
    }
    userManual {
      key
      url
      name
      size
    }
    videoUrl
    productUrl
  }
`;

const DEVICE_GET = gql`
  query DeviceGet($id: ID!) {
    deviceGet(id: $id) {
      id
      remark
      productInfo {
        ...Fields
      }
    }
  }
  ${PRODUCT_INFO_FRAGMENT}
`;

const PART_GET = gql`
  query PartGet($id: ID!) {
    partGet(id: $id) {
      id
      remark
      productInfo {
        ...Fields
      }
    }
  }
  ${PRODUCT_INFO_FRAGMENT}
`;

const CONSUMABLE_GET = gql`
  query ConsumableGet($id: ID!) {
    consumableGet(id: $id) {
      id
      remark
      productInfo {
        ...Fields
      }
    }
  }
  ${PRODUCT_INFO_FRAGMENT}
`;

const AdditionalInfoComponent = ({ id, type, onChange }) => {
  const [
    getDevice,
    { loading: deviceLoading, data: deviceData },
  ] = useLazyQuery(DEVICE_GET);

  const [getPart, { loading: partLoading, data: partData }] = useLazyQuery(
    PART_GET
  );

  const [
    getConsumable,
    { loading: consumableLoading, data: consumableData },
  ] = useLazyQuery(CONSUMABLE_GET);

  const productInfo = useMemo(() => {
    switch (type) {
      case "Device": {
        return deviceData?.deviceGet?.productInfo;
      }
      case "Part": {
        return partData?.partGet?.productInfo;
      }
      case "Consumable": {
        return consumableData?.consumableGet?.productInfo;
      }
      default:
        return undefined;
    }

    // eslint-disable-next-line
  }, [deviceData, partData, consumableData]);

  const setList = (fileList) => {
    let list = [];
    fileList.forEach((file) => {
      list.push({
        uid: file.key,
        name: file.name,
        size: file.size,
        status: "done",
        key: file.key,
        url: file.url,
      });
    });
    return list;
  };

  const setUploadList = (fileList) => {
    let list = [];
    fileList.forEach((file) => {
      list.push({
        uid: file.uid,
        name: file.name,
        size: file.size,
        status: file.status,
        key: file.response?.data?.key ?? file?.key,
        url: file.response?.data?.url ?? file.url,
      });
    });
    return list;
  };

  const setChangeList = (fileList) => {
    let list = [];
    fileList.forEach((file) => {
      list.push({
        key: file.key,
        name: file.name,
        size: file.size,
        url: file.url,
      });
    });
    return list;
  };

  useEffect(() => {
    switch (type) {
      case "Device": {
        setRemark(deviceData?.deviceGet?.remark);
        break;
      }
      case "Part": {
        setRemark(partData?.partGet?.remark);
        break;
      }
      case "Consumable": {
        setRemark(consumableData?.consumableGet?.remark);
        break;
      }
      default:
        break;
    }

    // eslint-disable-next-line
  }, [deviceData, partData, consumableData]);

  useEffect(() => {
    if (productInfo) {
      setVideoUrl(productInfo.videoUrl);
      setProductUrl(productInfo.productUrl);
      if (productInfo.photoList) {
        setPhotoList(setList(productInfo.photoList));
      }
      if (productInfo.brochureList) {
        setBrochureList(setList(productInfo.brochureList));
      }
      if (productInfo.userManual) {
        setUserManualList(setList(productInfo.userManual));
      }
    }
  }, [productInfo]);

  useEffect(() => {
    if (id) {
      const variables = {
        id,
      };
      switch (type) {
        case "Device": {
          getDevice({
            variables: variables,
          });
          break;
        }
        case "Part": {
          getPart({
            variables: variables,
          });
          break;
        }
        case "Consumable": {
          getConsumable({
            variables: variables,
          });
          break;
        }
        default:
          break;
      }
    }

    // eslint-disable-next-line
  }, [id]);

  const [previewParams, setPreviewParams] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
  });
  const [photoList, setPhotoList] = useState([]);
  const [brochureList, setBrochureList] = useState([]);
  const [userManualList, setUserManualList] = useState([]);
  const [videoUrl, setVideoUrl] = useState(undefined);
  const [productUrl, setProductUrl] = useState(undefined);
  const [remark, setRemark] = useState(undefined);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleCancel = () => setPreviewParams({ previewVisible: false });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewParams({
      previewType: file.name.substring(file.name.lastIndexOf(".") + 1),
      previewImage: file.url || file.preview,
      previewUrl: file.url,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const handlePhotoChange = ({ fileList }) => {
    setPhotoList(setUploadList(fileList));
  };

  const handleBrochureChange = ({ fileList }) => {
    setBrochureList(setUploadList(fileList));
  };

  const handleUserManualChange = ({ fileList }) => {
    setUserManualList(setUploadList(fileList));
  };

  useEffect(() => {
    onChange({
      info: {
        photoList: setChangeList(photoList),
        brochureList: setChangeList(brochureList),
        userManual: setChangeList(userManualList),
        videoUrl: videoUrl,
        productUrl: productUrl,
      },
      remark: remark,
    });
  }, [
    photoList,
    brochureList,
    userManualList,
    videoUrl,
    productUrl,
    remark,
    onChange,
  ]);

  const photoUploadButton = (
    <div className="file-upload-btn">
      <Icon type="camera" theme="filled" />
      <div className="ant-upload-text">拖拉以上載相片</div>
    </div>
  );

  const fileUploadButton = (
    <div className="file-upload-btn">
      <Icon type="paper-clip" />
      <div className="ant-upload-text">拖拉以上載相片</div>
    </div>
  );

  return (
    <div className="AdditionalInfoComponent">
      <Row type="flex">
        <Col xs={24}>
          <Form.Item>
            <span>圖片</span>
            <Upload
              className="file-uploader"
              headers={{
                "x-token": getTokenHeader(),
                "X-Requested-With": null,
              }}
              listType="picture-card"
              action={getFileUploadUrl()}
              fileList={photoList}
              onPreview={handlePreview}
              onChange={handlePhotoChange}
            >
              {photoUploadButton}
            </Upload>
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item style={{ width: "45%" }}>
            <span>影片連結</span>
            <Input
              name="videoUrl"
              onChange={({ target: { value } }) => setVideoUrl(value)}
              value={videoUrl}
            ></Input>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item>
            <span>宣傳冊</span>
            <Upload
              className="file-uploader"
              headers={{
                "x-token": getTokenHeader(),
                "X-Requested-With": null,
              }}
              listType="picture-card"
              action={getFileUploadUrl()}
              fileList={brochureList}
              onPreview={handlePreview}
              onChange={handleBrochureChange}
            >
              {fileUploadButton}
            </Upload>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item>
            <span>用戶手冊</span>
            <Upload
              className="file-uploader"
              headers={{
                "x-token": getTokenHeader(),
                "X-Requested-With": null,
              }}
              listType="picture-card"
              action={getFileUploadUrl()}
              fileList={userManualList}
              onPreview={handlePreview}
              onChange={handleUserManualChange}
            >
              {fileUploadButton}
            </Upload>
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item style={{ width: "45%" }}>
            <span>產品/公司網站</span>
            <Input
              name="productUrl"
              onChange={({ target: { value } }) => setProductUrl(value)}
              value={productUrl}
            ></Input>
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item style={{ width: "45%" }}>
            <span>備註</span>
            <Input.TextArea
              rows={6}
              name="remark"
              onChange={({ target: { value } }) => setRemark(value)}
              value={remark}
            ></Input.TextArea>
          </Form.Item>
        </Col>
      </Row>
      <Modal
        visible={previewParams?.previewVisible}
        title={previewParams?.previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        {previewParams.previewType !== "pdf" ? (
          <img
            alt="previewImage"
            style={{ width: "100%" }}
            src={previewParams?.previewImage}
          />
        ) : (
          <span>無法預覽</span>
        )}
        <div style={{ paddingTop: 10 }}>
          <Button onClick={() => window.open(previewParams.previewUrl)}>
            開啟
          </Button>
        </div>
      </Modal>
    </div>
  );
};

AdditionalInfoComponent.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
};

AdditionalInfoComponent.defaultProps = {
  id: "",
  type: "",
};

export default AdditionalInfoComponent;
