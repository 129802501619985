/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import {
  Spin,
  Row,
  Col,
  Timeline,
  Modal,
  Input,
  Button,
  notification,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { gql } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import moment from "moment";

// Components

// Constants

// Layouts

//Styles
import "./cleaningRecords.scss";

// Utils

const INVENTORY_CLEANING_GET = gql`
  query inventoryCleaningGet($id: ID) {
    inventoryCleaningGet(id: $id) {
      id
      cleaningFlow {
        name
      }
      cleaningType {
        id
        name
      }
      cleaningFlowStage {
        seq
        cleaningStage {
          id
          name
        }
        startDate
        endDate
        personInCharge {
          displayName
        }
        remark
        override
        nextCleaningStage
      }
      date
    }
  }
`;

const INVENTORY_CLEANING_REMARK_UPDATE = gql`
  mutation inventoryCleaningRemark($id: ID, $seq: Int, $remark: String) {
    inventoryCleaningRemark(id: $id, seq: $seq, remark: $remark) {
      id
    }
  }
`;

const CleaningRecords = ({ loading, selectedCleaningId }) => {
  const [
    getInventoryCleaning,
    { data: getInventoryCleaningData, loading: getInventoryCleaningLoading },
  ] = useLazyQuery(INVENTORY_CLEANING_GET);

  const [
    updateInventoryCleaningRemark,
    { loading: updateInventoryCleaningRemarkLoading },
  ] = useMutation(INVENTORY_CLEANING_REMARK_UPDATE);

  const [updateRemarkParam, setUpdateRemarkParam] = useState({
    visible: false,
  });
  const resetUpdateRemark = () => setUpdateRemarkParam({ visible: false });

  const refreshInventoryCleaning = useCallback(() => {
    if (selectedCleaningId || loading)
      getInventoryCleaning({ variables: { id: selectedCleaningId } });
  }, [getInventoryCleaning, loading, selectedCleaningId]);

  useEffect(() => {
    refreshInventoryCleaning();
  }, [refreshInventoryCleaning]);

  const cleaningData = useMemo(() => {
    if (getInventoryCleaningData)
      return getInventoryCleaningData?.inventoryCleaningGet;
  }, [getInventoryCleaningData]);

  const renderTimelineItem = () => {
    // eslint-disable-next-line react/prop-types
    const Dot = ({ index, dotClass }) => (
      <div className={`timeline-dot ${dotClass}`}>{index + 1}</div>
    );

    return cleaningData?.cleaningFlowStage?.map((stage, i) => {
      const isOverride = stage.override;
      const isNextStage = stage.nextCleaningStage;
      const isCompleted = stage.startDate && stage.endDate;

      const stageClass =
        (isOverride && "isOverride") ||
        (isNextStage && "isNextStage") ||
        (isCompleted && "isCompleted");

      return (
        <Timeline.Item
          key={i}
          dot={<Dot index={i} dotClass={`${stageClass}`} />}
        >
          <Row>
            <Col>
              <div style={{ paddingLeft: "20px" }}>
                <Col xs={24} sm={12} md={12}>
                  <span className={`cleaningName ${stageClass}`}>
                    {stage.cleaningStage.name}
                  </span>
                </Col>
                <Col xs={24} sm={10} md={10}>
                  <Col>
                    <span className="cleaningRemark">{stage?.remark}</span>
                  </Col>
                  <Col>
                    {stage.startDate && (
                      <span className="cleaningTime">{`${
                        stage.personInCharge.displayName
                      } | ${moment(stage.startDate).format(
                        "YYYY-MM-DD HH:mm"
                      )}`}</span>
                    )}
                  </Col>
                  {stage.endDate && (
                    <Col>
                      <span className="cleaningTime">{`${
                        stage.personInCharge.displayName
                      } | ${moment(stage.endDate).format(
                        "YYYY-MM-DD HH:mm"
                      )}`}</span>
                    </Col>
                  )}
                  {stage.startDate && (
                    <Col>
                      <span className="cleaningRemarkUpdate">
                        <Button
                          onClick={() =>
                            setUpdateRemarkParam({
                              id: selectedCleaningId,
                              seq: stage.seq,
                              remark: stage.remark,
                              cleaningStage: stage.cleaningStage,
                              visible: true,
                            })
                          }
                        >
                          填寫備註
                        </Button>
                      </span>
                    </Col>
                  )}
                </Col>
              </div>
            </Col>
          </Row>
        </Timeline.Item>
      );
    });
  };

  const renderCleaningRemarkUpdateModal = () => {
    const onOk = async () => {
      try {
        await updateInventoryCleaningRemark({
          variables: {
            id: updateRemarkParam.id,
            seq: updateRemarkParam.seq,
            remark: updateRemarkParam.remark,
          },
        });
        resetUpdateRemark();
        refreshInventoryCleaning();
      } catch (err) {
        notification.error({ message: err.message });
      }
    };
    return (
      <Modal
        title="填寫備註"
        visible={updateRemarkParam.visible}
        onCancel={resetUpdateRemark}
        onOk={onOk}
        cancelText="取消"
        okText="確定"
      >
        <Input.TextArea
          value={updateRemarkParam.remark}
          onChange={({ target: { value } }) => {
            console.log(value);
            setUpdateRemarkParam((prev) => ({ ...prev, remark: value }));
          }}
        />
      </Modal>
    );
  };

  return (
    <div className="cleaning-records">
      {updateRemarkParam.visible && renderCleaningRemarkUpdateModal()}
      <Spin
        spinning={
          loading ||
          getInventoryCleaningLoading ||
          updateInventoryCleaningRemarkLoading
        }
      >
        <div className="cleaning-timeline">
          <Timeline>{renderTimelineItem()}</Timeline>
        </div>
      </Spin>
    </div>
  );
};

export default CleaningRecords;

CleaningRecords.propTypes = {
  loading: PropTypes.bool,
  selectedCleaningId: PropTypes.string,
};
