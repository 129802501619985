/* eslint-disable no-unused-vars */

import React, { useMemo } from "react";
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

import "./deviceDetail.scss";
import { Link, useHistory, useParams } from "react-router-dom";
import { PAGE } from "../../../../../utils/constants";
import { Button, Form, Icon, Spin, Tabs, Typography } from "antd";
import { replaceUrlByParams } from "@utils/api";
import { gql, useQuery } from "@apollo/client";

import DeviceCleaningHistory from "../../../../../components/thirdPartyCleaning/jobDetail/deviceDetail/deviceCleaningHistory";
import DeviceServiceHistory from "../../../../../components/thirdPartyCleaning/jobDetail/deviceDetail/serviceHistory";
import { useTabParam } from "../../../../../utils/hook";

const { TabPane } = Tabs;

const cleaningJobGet = gql`
  query cleaningJobGet($cleaningJobId: String!) {
    cleaningJobGet(cleaningJobId: $cleaningJobId) {
      id
      referenceId
      equipment {
        id
        referenceId
        name
        equipmentCategory {
          id
          name
        }
        otherRemark
        cleanFlow {
          id
          referenceId
          cleaningFlow {
            id
            referenceId
            name
          }
          date
        }
        status
        friendlyReminder
        equipmentServiceItem {
          image {
            key
            size
            url
            name
          }
          stage
          displayToServiceRecord
          remark
        }
        failureRemark
        failureImage {
          key
          url
          name
          size
        }
        deliveryFee
      }
    }
  }
`;

const CleaningJobDeviceDetail = () => {
  const { id, deviceId } = useParams();
  const history = useHistory();

  const tabParam = useTabParam();

  const {
    data: getCleaningJobData,
    loading: getCleaningJobLoading,
    refetch: getCleaningJobRefetch,
  } = useQuery(cleaningJobGet, {
    variables: { cleaningJobId: id },
  });

  const equipment = useMemo(
    () =>
      getCleaningJobData?.cleaningJobGet?.equipment?.find(
        (equipment) => equipment.id === deviceId
      ),
    [deviceId, getCleaningJobData]
  );

  const BREADCRUMB = [
    {
      path: PAGE.THIRD_PARTY_CLEANING_JOB_DEVICE_EDIT,
      name: equipment ? `${equipment?.name} (${equipment?.referenceId})` : "",
    },
  ];

  const isLoading = getCleaningJobLoading;

  return (
    <div className="device-detail">
      <div className="go-back">
        <Link
          to={{
            pathname: replaceUrlByParams(PAGE.THIRD_PARTY_CLEANING_JOB_EDIT, {
              id: id,
            }),
            search: "tabKey=equipmentDetail",
          }}
        >
          <Icon type="left" />
          返回器材詳細資料
        </Link>
      </div>
      <StickyBreadcrumbPageComponent
        className="device-detail-page"
        breadcrumb={BREADCRUMB}
      >
        <Spin spinning={isLoading}>
          <div className="content-inner">
            <div className="equipment-category-container">
              <Typography.Text>
                {equipment?.otherRemark
                  ? `其他：${equipment?.otherRemark}`
                  : equipment?.equipmentCategory?.name}
              </Typography.Text>
            </div>
            <Tabs {...tabParam("cleaningHistory")}>
              <TabPane className="section" tab="清洗記錄" key="cleaningHistory">
                <DeviceCleaningHistory
                  equipment={equipment}
                  isLoading={getCleaningJobLoading}
                  refetch={getCleaningJobRefetch}
                />
              </TabPane>

              <TabPane className="section" tab="服務記錄" key="serviceHistory">
                <DeviceServiceHistory
                  equipment={equipment}
                  isLoading={getCleaningJobLoading}
                  refetch={getCleaningJobRefetch}
                />
              </TabPane>
            </Tabs>
          </div>
        </Spin>
      </StickyBreadcrumbPageComponent>
    </div>
  );
};

export default CleaningJobDeviceDetail;
