import React from "react";
import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
// Components
import { Button, Col, Row, Select, Typography, Input } from "antd";
// Styles
import "./filter.scss";
import CategoryFilter from "../../../components/categoryFilter/categoryFilter";

// Utils

const API = gql`
  query {
    characteristicTagList(type: ["device", "part", "consumable"])
    brandList(type: ["device", "part", "consumable"])
    dimensionList(type: ["device", "part", "consumable"])
    mainCategoryList {
      id
      name
      atCategory {
        id
        name
      }
      category {
        id
        name
      }
    }
  }
`;
function Filter({
  filterList,
  onResetFilter,
  onFilterSearch,
  filterValue,
  onFilterValueChange,
}) {
  const { data } = useQuery(API);

  return (
    <div className="Filter">
      <div className="filter-container">
        <div className="options-container">
          <Typography>篩選條件</Typography>
          <div className="options">
            <Row gutter={[16, 16]}>
              {filterList.includes("categoryId") && (
                <Col span={6}>
                  <div className="option">
                    <Typography>分類</Typography>
                    <CategoryFilter
                      value={filterValue.categoryId}
                      onChange={(categoryId) =>
                        onFilterValueChange((filterValue) => {
                          return {
                            ...filterValue,
                            categoryId,
                          };
                        })
                      }
                    />
                  </div>
                </Col>
              )}
              {filterList.includes("brand") && (
                <Col span={6}>
                  <div className="option">
                    <Typography>品牌</Typography>
                    <Select
                      showSearch
                      mode="tags"
                      value={filterValue.brand}
                      onChange={(brand) =>
                        onFilterValueChange((filterValue) => {
                          return {
                            ...filterValue,
                            brand,
                          };
                        })
                      }
                    >
                      {(data?.brandList ?? []).map((brand) => (
                        <Select.Option value={brand} key={brand}>
                          {brand}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              )}
              {filterList.includes("dimension") && (
                <Col span={6}>
                  <div className="option">
                    <Typography>尺寸</Typography>
                    <Select
                      showSearch
                      mode="tags"
                      value={filterValue.dimension}
                      onChange={(dimension) =>
                        onFilterValueChange((filterValue) => {
                          return {
                            ...filterValue,
                            dimension,
                          };
                        })
                      }
                    >
                      {(data?.dimensionList ?? []).map((dimension) => (
                        <Select.Option value={dimension} key={dimension}>
                          {dimension}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              )}
              {filterList.includes("characteristicTag") && (
                <Col span={6}>
                  <div className="option">
                    <Typography>產品特點</Typography>
                    <Select
                      multiple={true}
                      showSearch
                      mode="tags"
                      value={filterValue.characteristicTag}
                      onChange={(characteristicTag) =>
                        onFilterValueChange((filterValue) => {
                          return {
                            ...filterValue,
                            characteristicTag,
                          };
                        })
                      }
                    >
                      {(data?.characteristicTagList ?? []).map(
                        (characteristicTag) => (
                          <Select.Option
                            value={characteristicTag}
                            key={characteristicTag}
                          >
                            {characteristicTag}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </div>
                </Col>
              )}
              {filterList.includes("referenceId") && (
                <Col span={24}>
                  <div className="option">
                    <Typography>搜尋型號</Typography>
                    <Input 
                      value={filterValue.referenceId} 
                      onChange={(e) => {
                        const referenceId = e.target.value;
                        onFilterValueChange((filterValue) => {
                          return {
                            ...filterValue,
                            referenceId,
                          };
                        })
                      }} 
                    />
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </div>
        <div className="btn-container">
          <Button className="btn btn-secondary" onClick={onResetFilter}>
            清除設定
          </Button>
          <Button className="btn" type="primary" onClick={onFilterSearch}>
            搜尋
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Filter;

Filter.propTypes = {
  filterList: PropTypes.arrayOf(PropTypes.string),
  filterTypes: PropTypes.arrayOf(PropTypes.string),
  filterValue: PropTypes.shape({
    categoryId: PropTypes.string,
    brand: PropTypes.arrayOf(PropTypes.string),
    dimension: PropTypes.arrayOf(PropTypes.string),
    characteristicTag: PropTypes.arrayOf(PropTypes.string),
    referenceId: PropTypes.string,
  }),
  onFilterValueChange: PropTypes.func.isRequired,
  onFilterSearch: PropTypes.func.isRequired,
  onResetFilter: PropTypes.func,
};

Filter.defaultProps = {
  filterList: ["categoryId", "brand", "dimension", "characteristicTag","referenceId"],
};
