/* eslint-disable */
import React, { useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import Contract from "@pages/demo/contract/contract";

import moment from "moment";
import { gql } from "@apollo/client";

import { Drawer, Table, Tag, Upload, Button } from "antd";

import "./drawer.scss";

function DemoContractDrawer(prop) {
  const inventoryContractColumns = [
    {
      key: "id",
      title: "合約編號",
      render: (sortedinventoryContractList) => {
        return (
          <a href={sortedinventoryContractList.id}>
            {sortedinventoryContractList.id}
          </a>
        );
      },
    },
    {
      key: "createDate",
      title: "更新日期",
      render: (text, sortedinventoryContractList) => {
        return (
          <span>
            {moment.unix(sortedinventoryContractList.createDate).format("L") ||
              "-"}
          </span>
        );
      },
    },
    {
      key: "lastModifiedDate",
      title: "上載日期",
      render: (text, sortedinventoryContractList) => {
        return (
          <span>
            {moment
              .unix(sortedinventoryContractList.lastModifiedDate)
              .format("L") || "-"}
          </span>
        );
      },
    },
    {
      key: "status",
      title: "狀態",
      render: (sortedinventoryContractList) => {
        switch (sortedinventoryContractList.status.id) {
          case "pending":
            return (
              <React.Fragment>
                <Tag className="tag warning">
                  {sortedinventoryContractList.status.id}
                </Tag>
                <Upload>
                  <Button type="button" className="ant-btn">
                    <span>上載簽署合約</span>
                  </Button>
                </Upload>
              </React.Fragment>
            );
          case "cancelled":
            return (
              <React.Fragment>
                <Tag className="tag">
                  {sortedinventoryContractList.status.id}
                </Tag>
              </React.Fragment>
            );
          case "comfirmed":
            return (
              <React.Fragment>
                <Tag className="tag success">
                  {sortedinventoryContractList.status.id}
                </Tag>
              </React.Fragment>
            );
          default:
            return (
              <React.Fragment>
                <Tag className="tag success">
                  {sortedinventoryContractList.status.id}
                </Tag>
              </React.Fragment>
            );
        }
      },
    },
  ];
  //
  const CONTRACT_LIST = gql`
    query inventoryContractList($userId: ID) {
      inventoryContractList(userId: $userId) {
        id
        status {
          id
        }
        file {
          key
          url
        }
        createDate
        lastModifiedDate
      }
    }
  `;
  const CONTRACT_CREATE = gql`
    mutation inventoryContractCreate($userId: ID) {
      inventoryContractCreate(userId: $userId) {
        id
        status {
          id
        }
        file {
          key
          url
        }
        createDate
        lastModifiedDate
      }
    }
  `;

  const [
    inventoryContractList,
    { loading: inventoryContractListLoading, data: inventoryContractListData },
  ] = useLazyQuery(CONTRACT_LIST, { variables: { userid: prop.userId } });
  useEffect(() => {
    inventoryContractList();
  }, [prop.shouldDrawerTrigger]);

  const [
    inventoryContractCreate,
    {
      loading: inventoryContractCreateLoading,
      data: inventoryContractCreateData,
    },
  ] = useMutation(CONTRACT_CREATE);

  const onClickedCreateContract = async (userId) => {
    try {
      await inventoryContractCreate({ variables: { userId } });
      inventoryContractList();
    } catch (error) {
      console.log("erorr :" + { error });
    }
  };

  let sortedinventoryContractList = [];
  if (inventoryContractListData) {
    sortedinventoryContractList = inventoryContractListData.inventoryContractList.sort(
      (serviceUsage1, serviceUsage2) =>
        serviceUsage1.lastModifiedDate > serviceUsage2.lastModifiedDate ? -1 : 1
    );
  }

  return (
    <React.Fragment>
      <Drawer
        onClose={prop.showDrawer}
        placement="right"
        closable={true}
        visible={prop.shouldDrawerTrigger}
        width={"800px"}
      >
        <h1>服務協議資料</h1>
        <div className="contract">
          <h3 className="contract title">使用服務合約</h3>
          <Button
            loading={inventoryContractCreateLoading}
            className="contract button"
            onClick={() => {
              onClickedCreateContract(prop.userId);
            }}
          >
            產生合約
          </Button>
        </div>
        <Table
          rowKey="id"
          loading={inventoryContractListLoading}
          dataSource={sortedinventoryContractList}
          columns={inventoryContractColumns}
          pagination={false}
          scroll={{x:"max-content"}}
        />
      </Drawer>
    </React.Fragment>
  );
}

export default DemoContractDrawer;
