import React from 'react';

// Components
import DeliveryList from "./deliveryList";

// Constants
import { PAGE, PAGE_NAME } from '@utils/constants';

function DeliveryAll(props) {

    const BREADCRUMB = [
        {
            path: PAGE.STOCK_DELIVERY_COMPLETED,
            name: PAGE_NAME.STOCK_DELIVERY_COMPLETED
        }
    ];

    const availableDeliveryStatus = ['completed', 'cancelled'];

    return (
        <DeliveryList {...props} breadcrumb={BREADCRUMB} availableDeliveryStatus={availableDeliveryStatus}/>
    );
}

export default DeliveryAll;