/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import moment from "moment";

// Style
import "./createDamageReportDrawer.scss";

// Utils
import {
  Button,
  Drawer,
  message,
  Typography,
  DatePicker,
  TimePicker,
  Input,
  Select,
  Upload,
  notification,
  Spin,
  Row,
  Col,
} from "antd";
import {
  PAGE,
  PAGE_SIZE,
  MOMENT_FORMAT,
  TIME_FORMAT,
  MOMENT_TIME_FORMAT,
} from "@utils/constants";
import { replaceUrlByParams, getTokenHeader } from "@utils/api";
import { classList } from "@utils/common";
import { getFileUploadUrl } from "@utils/env";

const DAMAGE_REPORT_CREATE = gql`
  mutation DamageReportCreate($input: DamageReportCreateInput) {
    damageReportCreate(input: $input) {
      order {
        id
      }
      damageDatetime
      address
      damageReason
      damageType {
        id
      }
      damageTypeOther
      damageDetail
      damageImage {
        key
        name
        size
      }
      userStatementAndSignature {
        key
        name
        size
      }
      repairResult {
        id
        name
      }
      repairRemark
      trace {
        creator {
          displayName
        }
        lastModifier {
          displayName
        }
        lastModifiedDate
      }
    }
  }
`;
const DAMAGE_REPORT_UPDATE = gql`
  mutation DamageReportUpdate($input: DamageReportUpdateInput) {
    damageReportUpdate(input: $input) {
      id
      damageDatetime
      address
      damageReason
      damageType {
        id
      }
      damageTypeOther
      damageDetail
      damageImage {
        key
        name
        size
      }
      userStatementAndSignature {
        key
        name
        size
      }
      repairResult {
        id
        name
      }
      repairRemark
      trace {
        creator {
          displayName
        }
        lastModifier {
          displayName
        }
        lastModifiedDate
      }
    }
  }
`;

const INVENTORY_RECORD_GET = gql`
  query inventoryRecordGet(
    $id: ID
    $referenceId: String
    $size: Int
    $offset: Int
  ) {
    inventoryRecordGet(id: $id, referenceId: $referenceId) {
      id
      referenceId
      inventoryRecordOrderHistory(size: $size, offset: $offset) {
        id
        referenceId
        user {
          deleted
          userId
          username
          nameChi
          referralInfo {
            id
          }
          area {
          name
          }
          region {
            name
          }
          buildingName
          estateName
          floor
          flat
        }
        leasedDate
        returnedDate
        damageReportLastUpdateDate
        damageReportLastModifier {
          displayName
        }
        damageReport {
            id
            order {
              id
            }
            damageDatetime
            address
            damageReason
            damageType {
              id
              name
            }
            damageTypeOther
            damageDetail
            damageImage {
              key
              url
              name
              size
            }
            userStatementAndSignature {
              key
              url
              name
              size
            }
            repairResult {
              id
              name
            }
            repairRemark
            trace {
              creator {
                displayName
              }
              lastModifier {
                displayName
              }
              lastModifiedDate
            }
        }
      }
    }
  }
`;

function CreateDamageReportDrawer({
  visible,
  onClose,
  orderingItemId,
  isCreate,
  reportId,
  userId,
  inventoryRecordId,
}) {
  //api
  const [
    createDamageReport,
    {
      loading: createDamageReportLoading,
      error: createDamageReportError,
      data: createDamageReportData,
    },
  ] = useMutation(DAMAGE_REPORT_CREATE);
  const [
    updateDamageReport,
    {
      loading: updateDamageReportLoading,
      error: updateDamageReportError,
      data: updateDamageReportData,
    },
  ] = useMutation(DAMAGE_REPORT_UPDATE);

  const {
    loading: inventoryRecordLoading,
    data: inventoryRecordData,
    inventoryRecordRefetch,
  } = useQuery(INVENTORY_RECORD_GET, {
    variables: {
      id: inventoryRecordId
    },
  });

  const isLoading =
    createDamageReportLoading || updateDamageReportLoading || inventoryRecordLoading;

  // console.log("orderingData", orderingData);
  console.log("createDamageReportData", createDamageReportData);
  console.log("updateDamageReportData", updateDamageReportData);

  //api vars
  const orderingItem = useMemo(
    () =>
    inventoryRecordData?.inventoryRecordGet?.inventoryRecordOrderHistory?.find(
        (item) => item.id === orderingItemId
      ),
    [inventoryRecordData, orderingItemId]
  );

  const referenceId = useMemo(() => inventoryRecordData?.inventoryRecordGet?.referenceId, [inventoryRecordData?.inventoryRecordGet?.referenceId]);

  const userAddress = useMemo(() => {
    if (isCreate && orderingItem) {
      return `${orderingItem.user?.area?.name || ""} ${orderingItem.user?.region?.name || ""} ${orderingItem.user
        ?.buildingName || ""} ${orderingItem.user?.estateName ||
        ""} ${orderingItem.user?.floor || ""} ${orderingItem.user?.flat || ""}`;
    } else {
      return "";
    }
  }, [orderingItem]);

  const selectedReport = useMemo(() => {
    return orderingItem?.damageReport?.find((report) => report.id === reportId);
  }, [orderingItem?.damageReport]);

  const {
    clearError,
    errors,
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      id: reportId,
      orderId: orderingItemId,
      damageDate: null,
      damageTime: null,
      address: userAddress,
      damageReason: "",
      damageType: "scratchedSurface",
      damageTypeOther: "",
      damageDetail: "",
      damageImage: [],
      userStatementAndSignature: [],
      repairResult: "",
      repairRemark: "",
    },
  });

  console.log("userId", userId);

  useEffect(() => {
    register({ name: "id" });
    register({ name: "orderId" });
    register({ name: "damageDate" }, { required: true });
    register({ name: "damageTime" }, { required: true });
    register({ name: "address" });
    register({ name: "damageReason" });
    register({ name: "damageType" });
    register({ name: "damageTypeOther" });
    register({ name: "damageDetail" });
    register({ name: "damageImage" });
    register({ name: "userStatementAndSignature" });
    register({ name: "repairResult" });
    register({ name: "repairRemark" });
  }, [register]);

  const watchValue = watch();
  const { TextArea } = Input;
  const InputGroup = Input.Group;
  const { Option } = Select;
  let location = useLocation();
  let history = useHistory();
  const { Text, Link } = Typography;

  //conditions
  useEffect(() => {
    if (watchValue?.damageType !== "other") {
      setValue("damageTypeOther", "");
    }
  }, [watchValue?.damageType]);

  //prefill
  useEffect(() => {
    if (selectedReport) {
      console.log("selectedReport", selectedReport);
      setValue("damageDate", selectedReport.damageDatetime);
      setValue("damageTime", selectedReport.damageDatetime);
      setValue("damageReason", selectedReport.damageReason);
      setValue("damageType", selectedReport.damageType.id);
      setValue("damageTypeOther", selectedReport.damageTypeOther);
      setValue("damageDetail", selectedReport.damageDetail);
      setValue("address", selectedReport.address);
      setValue("damageImage", selectedReport.damageImage ?? []);
      setValue(
        "userStatementAndSignature",
        selectedReport.userStatementAndSignature ?? []
      );
      setValue("repairResult", selectedReport.repairResult.id);
      setValue("repairRemark", selectedReport.repairRemark);
    }
    if (isCreate) {
      setValue("address", userAddress);
    }
  }, [selectedReport, userAddress]);

  //upload
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  function beforeUpload(file) {
    // size checking
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      notification.error("Must be smaller than 5MB!");
    }
    return isLt5M;
  }

  const checkFileType = (file) => {
    if (file.type.includes("image/") || file.type.includes("application/pdf"))
      return true;
    return false;
  };

  const handleChange = (info, uploadType) => {
    let fileList = [...info.fileList].reduce((acc, file) => {
      if (checkFileType(file)) {
        if (file.response?.data) {
          file.key = file.response.data?.key;
          file.url = file.response.data?.url;
        }
        acc.push(file);
      } else {
        notification.error({
          message: `不支援 ${file.name} 的檔案類型, 請上載圖片或PDF`,
        });
      }
      return acc;
    }, []);
    if (uploadType === "damageImage") setValue("damageImage", fileList);
    if (uploadType === "userStatementAndSignature")
      setValue("userStatementAndSignature", fileList);
  };

  const onRemoveUpload = (file, uploadType) => {
    let index = {};
    let newDamageImageList = [];
    let newUserStatementAndSignatureList = [];
    switch (uploadType) {
      case "damageImage":
        index = watchValue?.damageImage.indexOf(file);
        newDamageImageList = [...watchValue?.damageImage];
        newDamageImageList.splice(index, 1);
        setValue("damageImage", newDamageImageList);
        break;
      case "userStatementAndSignature":
        index = watchValue?.userStatementAndSignature.indexOf(file);
        newUserStatementAndSignatureList = [
          ...watchValue?.userStatementAndSignature,
        ];
        newUserStatementAndSignatureList.splice(index, 1);
        setValue("userStatementAndSignature", newUserStatementAndSignatureList);
        break;
    }
  };

  const [isDamageImageUploading, setIsDamageImageUploading] = useState(false);
  const [isUserSignUploading, setIsUserSignUploading] = useState(false);

  //on drawer submit
  const onDrawerSubmit = async (value) => {
    const newDamageImage = value.damageImage.reduce((fin, acu) => {
      fin.push({ key: acu.key, name: acu.name ?? "", size: acu.size });
      return fin;
    }, []);
    const newUserStatementAndSignature = value.userStatementAndSignature.reduce(
      (fin, acu) => {
        fin.push({ key: acu.key, name: acu.name ?? "", size: acu.size });
        return fin;
      },
      []
    );
    const damageDatetime = moment(value.damageDate).set({
      hour: moment(value.damageTime).hour(),
      minute: moment(value.damageTime).minute(),
    });
    if (isCreate) {
      await createDamageReport({
        variables: {
          input: {
            orderId: value.orderId,
            damageDatetime: damageDatetime.valueOf(),
            address: value.address,
            damageReason: value.damageReason,
            damageType: value.damageType,
            damageTypeOther: value.damageTypeOther,
            damageDetail: value.damageDetail,
            damageImage: newDamageImage,
            userStatementAndSignature: newUserStatementAndSignature,
          },
        },
      });
    } else {
      await updateDamageReport({
        variables: {
          input: {
            id: value.id,
            damageDatetime: damageDatetime.valueOf(),
            address: value.address,
            damageReason: value.damageReason,
            damageType: value.damageType,
            damageTypeOther: value.damageTypeOther,
            damageDetail: value.damageDetail,
            damageImage: newDamageImage,
            userStatementAndSignature: newUserStatementAndSignature,
            repairResult: value.repairResult,
            repairRemark: value.repairRemark,
          },
        },
      });
    }
    onDrawerClose();
  };

  //pop up submit message
  useEffect(() => {
    if (createDamageReportData || updateDamageReportData) {
      console.log("createDamageReportData", createDamageReportData);
      notification.success({
        message: `成功${isCreate ? "新增" : "編輯"}器材損毀報告`,
      });
      onDrawerClose();
    }
  }, [createDamageReportData, updateDamageReportData]);

  const onDrawerClose = () => {
    onClose();
  };
  console.log("watchValue", watchValue);
  return (
    <Drawer
      className="CreateDamageReportDrawer-Component"
      title={`${isCreate ? "新增" : "編輯"}器材損毀報告`}
      placement="right"
      onClose={onDrawerClose}
      visible={visible}
      width="50vw"
    >
      <Spin spinning={isLoading}>
        <div className="vertical">
          <Text strong className="black">
            貨物編號
          </Text>
          <Text className="black">
            {referenceId}
          </Text>
        </div>
        <div className="horizontal">
          <div className="vertical" style={{ paddingRight: "50px" }}>
            <Text strong className="black">
              損毀發生日期
            </Text>
            <DatePicker
              placeholder="損毀發生日期"
              disabled={!isCreate}
              allowClear={false}
              format={MOMENT_FORMAT}
              value={
                watchValue?.damageDate ? moment(watchValue?.damageDate) : null
              }
              onChange={(e) => {
                setValue(
                  "damageDate",
                  moment(e, MOMENT_FORMAT).startOf("hour")
                );
              }}
            />
          </div>
          <div className="vertical">
            <Text strong className="black">
              損毀發生時間(時:分)
            </Text>
            <TimePicker
              allowClear={false}
              placeholder="損毀發生時間"
              value={
                watchValue?.damageTime ? moment(watchValue?.damageTime) : null
              }
              onChange={(e) => {
                setValue("damageTime", moment(e, MOMENT_TIME_FORMAT));
              }}
              format={MOMENT_TIME_FORMAT}
              disabled={!isCreate}
            />
          </div>
        </div>
        {(errors?.damageDate || errors?.damageTime) && (
          <div className="vertical">
            <Text type="danger">請填寫損毀發生日期及時間</Text>
          </div>
        )}
        <div className="vertical">
          <Text strong className="black">
            發生地點
          </Text>
          <Input
            placeholder={isCreate ? "填寫若與登記用戶住址不附" : ""}
            disabled={!isCreate}
            value={watchValue?.address}
            onChange={({ target: { value } }) => {
              setValue("address", value);
            }}
          />
        </div>
        <div className="vertical">
          <Text strong className="black">
            發生經過
          </Text>
          <TextArea
            placeholder={isCreate ? "描述器材的損毀發生經過" : ""}
            disabled={!isCreate}
            value={watchValue?.damageReason}
            onChange={({ target: { value } }) => {
              setValue("damageReason", value);
            }}
          ></TextArea>
        </div>
        <div className="vertical">
          <Text strong className="black">
            損毀情況
          </Text>
          <InputGroup compact disabled={!isCreate}>
            <Select
              style={{ minWidth: 150 }}
              disabled={!isCreate}
              value={watchValue?.damageType}
              onChange={(e) => {
                setValue("damageType", e);
              }}
            >
              <Option value="scratchedSurface">外層刮損</Option>
              <Option value="missingParts">零件遺失</Option>
              <Option value="structuralDamage">結構性毀壞</Option>
              <Option value="other">其他</Option>
            </Select>
            {watchValue?.damageType === "other" ? (
              <Input
                placeholder="器材的損毀類型"
                value={watchValue?.damageTypeOther}
                onChange={({ target: { value } }) => {
                  setValue("damageTypeOther", value);
                }}
              ></Input>
            ) : (
              false
            )}
            <TextArea
              placeholder={isCreate ? "描述器材的損毀情況" : ""}
              disabled={!isCreate}
              value={watchValue?.damageDetail}
              onChange={({ target: { value } }) => {
                setValue("damageDetail", value);
              }}
            />
          </InputGroup>
        </div>
        {isCreate ? (
          <>
            <div className="horizontal">
              <div className="vertical" style={{ paddingRight: "65px" }}>
                <div className="vertical">
                  <Upload
                    headers={{
                      "x-token": getTokenHeader(),
                      "X-Requested-With": null,
                    }}
                    action={getFileUploadUrl()}
                    beforeUpload={beforeUpload}
                    onChange={(info) => handleChange(info, "damageImage")}
                    listType="text"
                    onRemove={(file) => onRemoveUpload(file, "damageImage")}
                    fileList={watchValue?.damageImage?.map((item) => ({
                      uid: item.key,
                      ...item,
                    }))}
                    multiple={true}
                  >
                    <Button
                      type="primary"
                      disabled={isDamageImageUploading}
                      loading={isDamageImageUploading}
                    >
                      {isDamageImageUploading
                        ? "正在上載用戶聲明及簽署"
                        : `上載損毀情況照片[${watchValue?.damageImage.length}]`}
                    </Button>
                  </Upload>
                </div>
                <div className="vertical">
                  <Upload
                    headers={{
                      "x-token": getTokenHeader(),
                      "X-Requested-With": null,
                    }}
                    action={getFileUploadUrl()}
                    beforeUpload={beforeUpload}
                    onChange={(info) =>
                      handleChange(info, "userStatementAndSignature")
                    }
                    listType="text"
                    onRemove={(file) =>
                      onRemoveUpload(file, "userStatementAndSignature")
                    }
                    fileList={watchValue?.userStatementAndSignature?.map(
                      (item) => ({ uid: item.key, ...item })
                    )}
                    multiple={true}
                  >
                    <Button
                      type="primary"
                      disabled={isUserSignUploading}
                      loading={isUserSignUploading}
                    >
                      {isUserSignUploading
                        ? "正在上載用戶聲明及簽署"
                        : `上載用戶聲明及簽署[${watchValue?.userStatementAndSignature.length}]`}
                    </Button>
                  </Upload>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Row style={{ paddingTop: "20px" }}>
              <Col span="12">
                {watchValue?.damageImage.length > 0 ? (
                  <Text strong className="black">
                    下載損毀情況照片
                  </Text>
                ) : (
                  false
                )}
                <Upload
                  disabled={true}
                  listType="text"
                  fileList={watchValue?.damageImage.map((item) => ({
                    uid: item.key,
                    ...item,
                  }))}
                />
              </Col>
              <Col span="12">
                {watchValue?.userStatementAndSignature.length > 0 ? (
                  <Text strong className="black">
                    下載用戶聲明及簽署
                  </Text>
                ) : (
                  false
                )}
                <Upload
                  disabled={true}
                  listType="text"
                  fileList={watchValue?.userStatementAndSignature.map(
                    (item) => ({ uid: item.key, ...item })
                  )}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: "20px" }}>
              <Col>
                <Text strong className="black">
                  現場檢查/維修結果
                </Text>
                <InputGroup compact disabled={!isCreate}>
                  <Select
                    style={{ minWidth: 150 }}
                    value={watchValue.repairResult}
                    onChange={(e) => {
                      setValue("repairResult", e);
                    }}
                  >
                    <Option value="pending">有待維修</Option>
                    <Option value="repairedImmediately">已即時維修</Option>
                    <Option value="notRepairedImmediately">未能即時維修</Option>
                    <Option value="cancel">已取消</Option>
                  </Select>
                  <TextArea
                    placeholder="檢查/維修備註"
                    value={watchValue.repairRemark}
                    onChange={({ target: { value } }) => {
                      setValue("repairRemark", value);
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
          </>
        )}
        <div className="btn-container">
          <Button className="btn btn-secondary" onClick={onClose}>
            取消
          </Button>
          {/* <Button className="btn" type="primary" onClick={tamp}> */}
          <Button
            className="btn"
            type="primary"
            onClick={handleSubmit(onDrawerSubmit)}
          >
            儲存
          </Button>
        </div>
      </Spin>
    </Drawer>
  );
}

CreateDamageReportDrawer.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  // currentItems: PropTypes.array.isRequired,
  isCreate: PropTypes.bool.isRequired,
  orderingItemId: PropTypes.string,
  userAddress: PropTypes.string,
  reportId: PropTypes.string,
  // reports: PropTypes.array,
  inventoryRecordId: PropTypes.string.isRequired,
};

CreateDamageReportDrawer.defalutProps = {
  className: "",
};

export default CreateDamageReportDrawer;
