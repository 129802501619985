/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

// Components
import { Typography, Button, Pagination } from "antd";

// Style
import "./custPagination.scss";
import { classList } from "@utils/common";

// Utils
import { PAGE_SIZE } from "@utils/constants";

function CustPaginationComponent(props) {
  const {
    className,
    isMaxPage,
    isMinPage,
    total,
    onPageChange = () => undefined,
    page = 1,
    pageSize = PAGE_SIZE,
  } = props;
  const [pageLoading, setPageLoading] = useState(false);

  const getClassList = () =>
    classList({
      "CustPagination-Component": true,
      [className]: true,
    });

  const handlePageChange = useCallback(
    async (page) => {
      try {
        setPageLoading(true);
        if (page >= 1) {
          await new Promise((resolve) => {
            onPageChange(page);
            setTimeout(() => resolve(), 500);
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        setPageLoading(false);
      }
    },
    [onPageChange]
  );

  const isMobile = window.innerWidth < 576;

  return (
    <div className={getClassList()}>
      {total >= 0 ? (
        <>
          {isMobile && total > 0 && (
            <Typography.Text>
              顯示 {total} 項中的 {(page - 1) * pageSize + 1} -{" "}
              {Math.min(pageSize * page, total)}
            </Typography.Text>
          )}
          <Pagination
            showQuickJumper
            pageSize={pageSize}
            total={total}
            current={page}
            showTotal={(total, range) =>
              `顯示 ${total} 項中的 ${range[0]} - ${range[1]}`
            }
            onChange={handlePageChange}
            size="small"
            showLessItems
            simple={isMobile && total > 0}
          />
        </>
      ) : (
        <>
          <Typography.Text>
            顯示 {pageSize * (page - 1) + 1} - {pageSize * page}
          </Typography.Text>

          <Button
            disabled={pageLoading || isMinPage}
            // className={isMinPage ? "hide" : "show"}
            type="primary"
            icon="caret-left"
            onClick={() => handlePageChange(page - 1)}
          />
          <Typography.Text className="current-page">{page}</Typography.Text>
          <Button
            disabled={pageLoading || isMaxPage}
            // className={isMaxPage ? "hide" : "show"}
            type="primary"
            icon="caret-right"
            onClick={() => handlePageChange(page + 1)}
          />
        </>
      )}
    </div>
  );
}

export default CustPaginationComponent;

CustPaginationComponent.propTypes = {
  className: PropTypes.string,
  isMinPage: PropTypes.bool.isRequired,
  isMaxPage: PropTypes.bool.isRequired,
  total: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

CustPaginationComponent.defalutProps = {
  className: "",
};
