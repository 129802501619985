import React, { useCallback, useEffect } from "react";
import {
  Drawer,
  Form,
  DatePicker,
  Button,
  Typography,
  message,
  InputNumber,
} from "antd";
import { useForm } from "react-hook-form";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import Text from "antd/lib/typography/Text";

const PRICE_CREATE_ON_DEVICE = gql`
  mutation priceCreateOnDevice($id: ID, $input: ItemPriceInput) {
    priceCreateOnDevice(id: $id, input: $input) {
      id
    }
  }
`;

const PRICE_CREATE_ON_PART = gql`
  mutation priceCreateOnPart($id: ID, $input: ItemPriceInput) {
    priceCreateOnPart(id: $id, input: $input) {
      id
    }
  }
`;

const PRICE_CREATE_ON_CONSUMABLE = gql`
  mutation priceCreateOnConsumable($id: ID, $input: ItemPriceInput) {
    priceCreateOnConsumable(id: $id, input: $input) {
      id
    }
  }
`;

const PRICE_UPDATE_ON_DEVICE = gql`
  mutation priceCreateOnDevice(
    $id: ID
    $itemPriceId: ID
    $input: ItemPriceInput
  ) {
    priceUpdateOnDevice(id: $id, itemPriceId: $itemPriceId, input: $input) {
      id
    }
  }
`;

const PRICE_UPDATE_ON_PART = gql`
  mutation priceCreateOnPart(
    $id: ID
    $itemPriceId: ID
    $input: ItemPriceInput
  ) {
    priceUpdateOnPart(id: $id, itemPriceId: $itemPriceId, input: $input) {
      id
    }
  }
`;

const PRICE_UPDATE_ON_CONSUMABLE = gql`
  mutation priceCreateOnConsumable(
    $id: ID
    $itemPriceId: ID
    $input: ItemPriceInput
  ) {
    priceUpdateOnConsumable(id: $id, itemPriceId: $itemPriceId, input: $input) {
      id
    }
  }
`;

const CreatePriceDrawer = (props) => {
  const { defaultValues, onClose } = props;
  const { type } = defaultValues;
  const { errors, register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      ...defaultValues,
      monthlyRentalPrice:
        type !== "Consumable"
          ? defaultValues?.monthlyRentalPrice ?? 0
          : undefined,
      depositPrice:
        type !== "Consumable" ? defaultValues?.depositPrice ?? 0 : undefined,
      serviceFee:
        type !== "Consumable" ? defaultValues?.serviceFee ?? 0 : undefined,
      purchasePrice:
        type === "Consumable" ? defaultValues?.purchasePrice ?? 0 : undefined,
    },
  });

  useEffect(() => {
    register({ name: "id" });
    register({ name: "productId" }, { required: true });
    register({ name: "activeDate" }, { required: true });
    register(
      { name: "monthlyRentalPrice" },
      { required: !(type === "Consumable"), min: 0 }
    );
    register(
      { name: "depositPrice" },
      { required: !(type === "Consumable"), min: 0 }
    );
    register(
      { name: "serviceFee" },
      { required: !(type === "Consumable"), min: 0 }
    );
    register(
      { name: "purchasePrice" },
      { required: type === "Consumable", min: 0 }
    );
  }, [register, type]);

  const formValue = watch();

  let priceCreateGraphQL;
  let priceUpdateGraphQL;
  switch (type) {
    case "Device": {
      priceCreateGraphQL = PRICE_CREATE_ON_DEVICE;
      priceUpdateGraphQL = PRICE_UPDATE_ON_DEVICE;
      break;
    }
    case "Part": {
      priceCreateGraphQL = PRICE_CREATE_ON_PART;
      priceUpdateGraphQL = PRICE_UPDATE_ON_PART;
      break;
    }
    case "Consumable": {
      priceCreateGraphQL = PRICE_CREATE_ON_CONSUMABLE;
      priceUpdateGraphQL = PRICE_UPDATE_ON_CONSUMABLE;
      break;
    }
    default: {
      priceCreateGraphQL = PRICE_CREATE_ON_DEVICE;
      priceUpdateGraphQL = PRICE_UPDATE_ON_DEVICE;
      break;
    }
  }

  const [createPrice, { loading: createPriceLoading }] = useMutation(
    priceCreateGraphQL
  );
  const [updatePrice, { loading: updatePriceLoading }] = useMutation(
    priceUpdateGraphQL
  );

  const onSubmit = useCallback(
    async ({ productId, id: itemPriceId, ...price }) => {
      if (itemPriceId) {
        try {
          let variables;
          switch (type) {
            case "Device": {
              variables = {
                id: productId,
                itemPriceId,
                input: {
                  ...price,
                  purchasePrice: 0,
                },
              };
              break;
            }
            case "Part": {
              variables = {
                id: productId,
                itemPriceId,
                input: {
                  ...price,
                  purchasePrice: 0,
                },
              };
              break;
            }
            case "Consumable": {
              variables = {
                id: productId,
                itemPriceId,
                input: {
                  ...price,
                  monthlyRentalPrice: 0,
                  depositPrice: 0,
                },
              };
              break;
            }
            default:
              break;
          }
          await updatePrice({
            variables: variables,
          });
          onClose();
          message.success("成功更改價格");
        } catch (error) {
          message.error(error.message);
        }
      } else {
        try {
          let variables;
          switch (type) {
            case "Device": {
              variables = {
                id: productId,
                input: {
                  ...price,
                  purchasePrice: 0,
                },
              };
              break;
            }
            case "Part": {
              variables = {
                id: productId,
                input: {
                  ...price,
                  purchasePrice: 0,
                },
              };
              break;
            }
            case "Consumable": {
              variables = {
                id: productId,
                input: {
                  ...price,
                  monthlyRentalPrice: 0,
                  depositPrice: 0,
                },
              };
              break;
            }
            default:
              break;
          }
          await createPrice({
            variables: variables,
          });
          onClose();
          message.success(`成功${defaultValues?.id ? "更改" : "新增"}價格`);
        } catch (error) {
          message.error(error.message);
        }
      }
    },

    // eslint-disable-next-line
    [updatePrice, onClose, createPrice]
  );

  return (
    <Drawer
      width="50%"
      visible={true}
      title={`${defaultValues?.id ? "更改" : "新增"}價格`}
      onClose={onClose}
    >
      <Form layout="horizontal">
        {type !== "Consumable" && (
          <>
            <Text>*租金價格 = 月租價格 + 服務成本</Text>
            <FormItem label="月租價格">
              <InputNumber
                className={errors?.monthlyRentalPrice ? "error" : null}
                min={0}
                precision={2}
                value={
                  formValue?.monthlyRentalPrice >= 0
                    ? formValue?.monthlyRentalPrice / 100
                    : undefined
                }
                onChange={(value) =>
                  setValue("monthlyRentalPrice", Math.round(value * 100))
                }
              />
              {errors?.monthlyRentalPrice && (
                <Typography.Text type="danger">請填寫月租價格</Typography.Text>
              )}
            </FormItem>
            <FormItem label="服務成本">
              <InputNumber
                className={errors?.serviceFee ? "error" : null}
                min={0}
                precision={2}
                value={
                  formValue?.serviceFee >= 0
                    ? formValue?.serviceFee / 100
                    : undefined
                }
                onChange={(value) =>
                  setValue("serviceFee", Math.round(value * 100))
                }
              />
              {errors?.serviceFee && (
                <Typography.Text type="danger">請填寫服務成本</Typography.Text>
              )}
            </FormItem>
            <FormItem label="按金價格">
              <InputNumber
                className={errors?.depositPrice ? "error" : null}
                min={0}
                precision={2}
                value={
                  formValue?.depositPrice >= 0
                    ? formValue?.depositPrice / 100
                    : undefined
                }
                onChange={(value) =>
                  setValue("depositPrice", Math.round(value * 100))
                }
              />
              {errors?.depositPrice && (
                <Typography.Text type="danger">請填寫按金價格</Typography.Text>
              )}
            </FormItem>
          </>
        )}
        {type === "Consumable" && (
          <FormItem label="價格">
            <InputNumber
              className={errors?.purchasePrice ? "error" : null}
              min={0}
              precision={2}
              value={
                formValue?.purchasePrice >= 0
                  ? formValue?.purchasePrice / 100
                  : undefined
              }
              onChange={(value) =>
                setValue("purchasePrice", Math.round(value * 100))
              }
            />
            {errors?.purchasePrice && (
              <Typography.Text type="danger">請填寫價格</Typography.Text>
            )}
          </FormItem>
        )}
        <FormItem label="生效日期">
          <DatePicker
            style={{ width: "100%" }}
            className={errors?.activeDate ? "error" : null}
            value={
              formValue?.activeDate
                ? moment(formValue?.activeDate, "YYYY-MM-DD")
                : null
            }
            onChange={(value) =>
              setValue("activeDate", moment(value).format("YYYY-MM-DD"))
            }
          />
          {errors?.activeDate && (
            <Typography.Text type="danger">請填寫生效日期</Typography.Text>
          )}
        </FormItem>
      </Form>
      <Button
        onClick={handleSubmit(onSubmit)}
        loading={createPriceLoading || updatePriceLoading}
        type="primary"
      >
        提交
      </Button>
    </Drawer>
  );
};

CreatePriceDrawer.propTypes = {
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
};

export default CreatePriceDrawer;
