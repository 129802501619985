import React from "react";
import {
  Drawer,
  Form,
  Input,
  Button,
  Typography,
  notification,
  Spin,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import FormItem from "antd/lib/form/FormItem";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";

// Styles
import "./warehouseEdit.scss";

const WAREHOUSE_LIST = gql`
  query warehouseList {
    warehouseList {
      id
      storeLocationCode
    }
  }
`;

const WAREHOUSE_CREATE = gql`
  mutation warehouseCreate($input: WarehouseCreateInput) {
    warehouseCreate(input: $input) {
      id
    }
  }
`;

const WAREHOUSE_UPDATE = gql`
  mutation warehouseUpdate($input: WarehouseUpdateInput) {
    warehouseUpdate(input: $input) {
      id
    }
  }
`;

const WarehouseEditDrawer = (props) => {
  const { defaultValues, onClose, refresh } = props;
  const { errors, control, handleSubmit } = useForm({
    defaultValues,
  });

  const isEdit = defaultValues?.id ? true : false;

  const [createWarehouse, { loading: createWarehouseLoading }] = useMutation(
    WAREHOUSE_CREATE
  );
  const [updateWarehouse, { loading: updateWarehouseLoading }] = useMutation(
    WAREHOUSE_UPDATE
  );

  const {
    data: getWarehouseListData,
    loading: getWarehouseListLoading,
  } = useQuery(WAREHOUSE_LIST);

  const isLoading =
    createWarehouseLoading || updateWarehouseLoading || getWarehouseListLoading;

  const onSubmit = async (data) => {
    try {
      if (defaultValues?.id) {
        await updateWarehouse({
          variables: { input: { id: defaultValues?.id, ...data } },
        });
        notification.success({ message: "成功更新工場地點" });
      } else {
        await createWarehouse({ variables: { input: data } });
        notification.success({ message: "成功新增工場地點" });
      }
      refresh();
      onClose();
    } catch (err) {
      notification.error({ message: err.message });
    }
  };

  return (
    <Drawer
      width="50%"
      visible={true}
      title={`${isEdit ? "編輯" : "新增"}工場地點`}
      onClose={onClose}
    >
      <Spin spinning={isLoading}>
        <Form layout="horizontal">
          <FormItem label="工場名稱">
            <Controller
              as={<Input />}
              name="name"
              control={control}
              rules={{ required: true }}
              defaultValue=""
            />
            {errors?.name && (
              <Typography.Text type="danger">工場名稱</Typography.Text>
            )}
          </FormItem>
          <FormItem label="工場地址">
            <Controller
              as={<Input />}
              name="address"
              control={control}
              rules={{ required: true }}
              defaultValue=""
            />
            {errors?.address && (
              <Typography.Text type="danger">請填寫工場地址</Typography.Text>
            )}
          </FormItem>
          <FormItem label="工場編號">
            <Controller
              as={<Input />}
              name="storeLocationCode"
              control={control}
              rules={{
                validate: {
                  required: (value) => !!value || "請填寫工場編號",
                  duplicated: (value) =>
                    !getWarehouseListData?.warehouseList?.find(
                      (item) =>
                        item.storeLocationCode === value &&
                        item.id !== defaultValues?.id
                    ) || "工場編號重覆，請更改工場編號",
                },
              }}
              defaultValue=""
            />
            {errors?.storeLocationCode && (
              <Typography.Text type="danger">
                {errors?.storeLocationCode?.message}
              </Typography.Text>
            )}
          </FormItem>
        </Form>
        <div className="btn-container">
          <Button
            className="btn btn-secondary"
            onClick={onClose}
            loading={isLoading}
          >
            取消
          </Button>
          <Button
            className="btn"
            type="primary"
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            儲存
          </Button>
        </div>
      </Spin>
    </Drawer>
  );
};

WarehouseEditDrawer.propTypes = {
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
  refresh: PropTypes.func,
};

export default WarehouseEditDrawer;
