import React, { useState, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

import AccountRefundDetail from "./accountRefundDetail";

// Styles
import "./accountRefundTable.scss";

// Utils
import { Button, Table, Tag } from "antd";
import { amountDisplay } from "@utils/common";
// import Axios from "axios";

function AccountRefundTable({ data, loading, refreshAccount, userId }) {
  const history = useHistory();
  const location = useLocation();
  const query = useMemo(() => {
    const { refundDetail } = queryString.parse(location.search);
    return {
      ...queryString.parse(location.search),
      refundDetail,
    };
  }, [location.search]);

  const [accountRefundDetailParams, setAccountRefundDetailParams] = useState({
    visible: false,
  });

  const onClose = useCallback(() => {
    setAccountRefundDetailParams({ visible: false });
    history.push({
      search: queryString.stringify({
        ...query,
        refundDetail: undefined,
      }),
    });
  }, [history, query]);

  const onSuccess = useCallback(() => {
    setAccountRefundDetailParams({ visible: false });
    refreshAccount();
    history.push({
      search: queryString.stringify({
        ...query,
        refundDetail: undefined,
      }),
    });
  }, [history, query, refreshAccount]);

  const createRefundDetail = useCallback(() => {
    setAccountRefundDetailParams({
      mode: "create",
      visible: true,
      onClose: onClose,
      onSuccessEdit: onSuccess,
      userId,
    });
  }, [onClose, onSuccess, userId]);

  const editRefundDetail = useCallback(() => {
    setAccountRefundDetailParams({
      mode: "edit",
      refundId: query.refundDetail,
      visible: true,
      onClose: onClose,
      onSuccessEdit: onSuccess,
      userId,
    });
  }, [onClose, onSuccess, query.refundDetail, userId]);

  const viewRefundDetail = useCallback(() => {
    setAccountRefundDetailParams({
      mode: "view",
      refundId: query.refundDetail,
      visible: true,
      onClose: onClose,
      onSuccessEdit: onSuccess,
      userId,
    });
  }, [onClose, onSuccess, query.refundDetail, userId]);

  useEffect(() => {
    if (query.refundDetail && data.find((item) => item.id === query.refundDetail)) {
      switch (query.formMode) {
        case "edit":
          editRefundDetail();
          break;
        case "view":
          viewRefundDetail();
          break;
        default:
          console.error("unexpected formMode");
      }
    } else setAccountRefundDetailParams((prev) => ({ ...prev, visible: false }));
  }, [data, editRefundDetail, query.formMode, query.refundDetail, viewRefundDetail]);

  const cols = [
    {
      title: "按金金額",
      dataIndex: "depositAmount",
      render: (text) => <span>{text === null ? "-" : `HKD $${amountDisplay(text)}`}</span>,
    },
    {
      title: "租金金額",
      dataIndex: "rentalAmount",
      render: (text) => <span>{text === null ? "-" : `HKD $${amountDisplay(text)}`}</span>,
    },
    {
      title: "申請日期",
      dataIndex: "requestDate",
      render: (text) => <span>{text ? text : "-"}</span>,
    },
    {
      title: "收取方式",
      dataIndex: "refundMethod.name",
    },
    // {
    //   title: "支票編號",
    //   dataIndex: "chequeNumber",
    //   render: (text) => <span>{text ? text : "-"}</span>,
    // },
    // {
    //   title: "支票抬頭",
    //   dataIndex: "payableTo",
    //   render: (text) => <span>{text ? text : "-"}</span>,
    // },
    // {
    //   title: "寄送日期",
    //   dataIndex: "mailDate",
    //   render: (text) => <span>{text ? text : "-"}</span>,
    // },
    // {
    //   title: "寄送地址",
    //   width: "200px",
    //   dataIndex: "address",
    //   render: (text) => <span>{text ? text : "-"}</span>,
    // },
    // {
    //   title: "賬目編號",
    //   dataIndex: "accountNumber",
    //   render: (text) => <span>{text ? text : "-"}</span>,
    // },
    // {
    //   title: "備註",
    //   dataIndex: "remark",
    //   render: (text) => (
    //     <span style={{ wordBreak: "break-word" }}>{text ? text : "-"}</span>
    //   ),
    // },
    {
      title: "負責人",
      dataIndex: "personInCharge",
      render: (staff) => {
        console.log("staff", staff);
        return <span>{staff?.displayName}</span>;
      },
    },
    {
      title: "狀態",
      dataIndex: "status",
      render: (status, item) => {
        let statusTag = <>-</>;
        switch (status?.id) {
          case "approving":
            statusTag = (
              <Tag key={item?.id} className="tag">
                {status?.name}
              </Tag>
            );
            break;
          case "approved":
            statusTag = (
              <Tag key={item?.id} className="tag success">
                {status?.name}
              </Tag>
            );
            break;
          case "rejected":
            statusTag = (
              <Tag key={item?.id} className="tag danger">
                {status?.name}
              </Tag>
            );
            break;
          default:
            statusTag = <>-</>;
            break;
        }
        return statusTag;
      },
    },
    {
      dataIndex: "action",
      render: (text, record) => {
        const { status } = record;
        return (
          <Button
            className="btn btn-secondary"
            onClick={() => {
              history.push({
                search: queryString.stringify({
                  ...query,
                  refundDetail: record.id,
                  formMode: status?.id === "approving" ? `edit` : `view`,
                }),
              });
            }}
          >
            {status?.id === "approving" ? `編輯` : `查看`}
          </Button>
        );
      },
    },
  ];

  const isActiveRefundExist = (data ?? []).reduce(
    (result, record) => result || record.status.id === "approving",
    false
  );

  return (
    <div className="AccountRefundTable">
      <div className="table-header">
        {!isActiveRefundExist && (
          <Button className="btn btn-secondary" onClick={createRefundDetail}>
            新增退款
          </Button>
        )}
      </div>
      <div>
        <Table
          rowKey="id"
          loading={loading}
          dataSource={data}
          columns={cols}
          pagination={false}
          scroll={{ x: "max-content" }}
        />
      </div>
      {accountRefundDetailParams.visible && <AccountRefundDetail {...accountRefundDetailParams} />}
    </div>
  );
}

AccountRefundTable.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  refreshAccount: PropTypes.func,
  userId: PropTypes.string,
};

AccountRefundTable.defaultProps = {
  loading: false,
  title: "",
};

export default AccountRefundTable;
