import React from 'react';
import PropTypes from 'prop-types';

// Style
import './section.scss';

// Utils
import {classList} from '@utils/common';

function AssessmentSectionComponent(props) {
    const {
        children,
        className,
        displaySubTotalScore,
        score,
        title,
        totalScore
    } = props;

    const getClassList = () => classList({
        'Assessment-Component': true,
        'AssessmentSection-Component': true,
        [className]: true
    });

    return (
        <div className={getClassList()}>
            <div className='title-container'>
                <div>
                    {title ? <h2 className='title'>{title}</h2> : null}
                </div>
                <div>
                    {displaySubTotalScore ? <div className='score'>{score} / {totalScore}</div> : null}
                </div>
            </div>
            <div className='questions-container'>
                {children}
            </div>
        </div>
    );
}

export default AssessmentSectionComponent;

AssessmentSectionComponent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    displaySubTotalScore: PropTypes.bool.isRequired,
    question: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            input: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    inputType: PropTypes.string.isRequired,
                    label: PropTypes.string,
                    option: PropTypes.arrayOf(
                        PropTypes.shape({
                            value: PropTypes.string.isRequired,
                        })
                    ),
                    answer: PropTypes.oneOfType([
                        PropTypes.arrayOf(PropTypes.object),
                        PropTypes.arrayOf(PropTypes.string),
                        PropTypes.bool,
                        PropTypes.string
                    ]),
                })
            ).isRequired,
            title: PropTypes.string.isRequired,
        })
    ),
    score: PropTypes.number,
    title: PropTypes.string,
    totalScore: PropTypes.number,
};

AssessmentSectionComponent.defalutProps = {
    children: null,
    className: '',
    score: undefined,
    title: undefined,
    totalScore: undefined
};
