/* eslint-disable no-unused-vars */
import {
  Tabs,
  Form,
  Row,
  Col,
  Select,
  Input,
  Upload,
  Button,
  notification,
  Icon,
  Modal,
  InputNumber,
  Avatar,
  Tooltip,
  Comment,
  message,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { gql } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";

// Components
import ActivityHistoryComponent from "@components/activityHistory/activityHistory";
import AdditionalInfoComponent from "@components/additionalInfo/additionalInfo";

// Constants
import { PAGE, PAGE_NAME } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

//Styles
import "./_consumableForm.scss";

// Utils

import { getTokenHeader, replaceUrlByParams } from "@utils/api";
import CategoryFilter from "../../../../components/categoryFilter/categoryFilter";
import Picker from "../../picker/picker";
import { getFileUploadUrl } from "../../../../utils/env";

const { TabPane } = Tabs;

const SELECTION_CHOICES = gql`
  query {
    characteristicTagList(type: ["consumable", "part", "consumable"])
    brandList(type: ["consumable", "part", "consumable"])
    dimensionList(type: ["consumable", "part", "consumable"])
  }
`;

const CONSUMABLE_GET = gql`
  query ConsumableGet($id: ID!) {
    consumableGet(id: $id) {
      id
      productInfo {
        nameEng
        nameChi
        modelNumber
        thumbnail {
          key
          name
          size
          url
        }
        dimension
        netWeight
        totalWeight
        colour
        specification
        characteristicTag
        manufacturer
      }
      category {
        id
      }
      trace {
        activityHistory {
          staff {
            displayName
          }
          content
          loggedDate
        }
      }
    }
  }
`;

const CONSUMABLE_CREATE = gql`
  mutation ConsumableCreate($input: ConsumableCreateInput) {
    consumableCreate(input: $input) {
      id
      productInfo {
        nameEng
        nameChi
        modelNumber
        thumbnail {
          key
          name
          size
        }
        dimension
        netWeight
        totalWeight
        colour
        specification
        characteristicTag
        manufacturer
      }
      category {
        id
      }
    }
  }
`;

const CONSUMABLE_UPDATE = gql`
  mutation ConsumableUpdate($input: ConsumableUpdateInput) {
    consumableUpdate(input: $input) {
      id
      productInfo {
        nameEng
        nameChi
        modelNumber
        thumbnail {
          key
          name
          size
        }
        dimension
        netWeight
        totalWeight
        colour
        specification
        characteristicTag
        manufacturer
      }
      category {
        id
      }
    }
  }
`;

const ConsumableForm = () => {
  const { id } = useParams();
  const history = useHistory();

  const BREADCRUMB = [
    {
      path: id ? PAGE.STOCK_CONSUMABLE_EDIT : PAGE.STOCK_CONSUMABLE_CREATE,
      name: id
        ? PAGE_NAME.STOCK_CONSUMABLE_EDIT
        : PAGE_NAME.STOCK_CONSUMABLE_CREATE,
    },
  ];

  const [activeTabKey, setActiveTabKey] = useState("productInfo");
  const [isUploading, setIsUploading] = useState(false);
  const [isPreviewShow, setIsPreviewShow] = useState(false);
  const [previewImage, setPreviewImage] = useState(undefined);

  const [pickerParams] = useState({
    visible: false,
  });

  const [additionalInfo, setAdditionalInfo] = useState(undefined);
  const [remark, setRemark] = useState(undefined);

  const IS_READ_ONLY = false;

  const [getConsumable, { data: getConsumableData }] = useLazyQuery(
    CONSUMABLE_GET
  );
  const { data: selectionChoices } = useQuery(SELECTION_CHOICES);

  const [
    _createConsumable,
    { loading: createConsumableLoading, data: createConsumableData },
  ] = useMutation(CONSUMABLE_CREATE);
  const [_updateConsumable] = useMutation(CONSUMABLE_UPDATE);

  const {
    errors,
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      id: undefined,
      categoryId: undefined,
      productInfo: {
        nameEng: "",
        nameChi: "",
        modelNumber: "",
        thumbnail: undefined,
        dimension: [],
        netWeight: 0,
        totalWeight: 0,
        colour: [],
        specification: "",
        characteristicTag: [],
      },
    },
  });

  const watchValue = watch();

  useEffect(() => {
    if (id) {
      getConsumable({ variables: { id } });
    }
  }, [getConsumable, id]);

  useEffect(() => {
    if (getConsumableData?.consumableGet) {
      const data = getConsumableData?.consumableGet;
      setValue("id", data.id);
      setValue("categoryId", data?.category?.id ?? undefined);
      setValue("productInfo.nameEng", data?.productInfo?.nameEng ?? "");
      setValue("productInfo.nameChi", data?.productInfo?.nameChi ?? "");
      setValue("productInfo.modelNumber", data?.productInfo?.modelNumber ?? "");
      setValue(
        "productInfo.thumbnail",
        data?.productInfo?.thumbnail ?? undefined
      );
      setValue(
        "productInfo.manufacturer",
        data?.productInfo?.manufacturer ?? undefined
      );
      setValue("productInfo.dimension", data?.productInfo?.dimension ?? []);
      setValue("productInfo.netWeight", data?.productInfo?.netWeight ?? 0);
      setValue("productInfo.totalWeight", data.productInfo.totalWeight ?? 0);
      setValue("productInfo.colour", data?.productInfo?.colour ?? []);
      setValue(
        "productInfo.specification",
        data?.productInfo?.specification ?? ""
      );
      setValue(
        "productInfo.characteristicTag",
        data?.productInfo.characteristicTag ?? []
      );
    }
  }, [getConsumableData, setValue]);

  useEffect(() => {
    const id = createConsumableData?.consumableCreate?.id;
    if (id) {
      history.push(replaceUrlByParams(PAGE.STOCK_CONSUMABLE_EDIT, { id: id }));
    }
  }, [createConsumableData, history]);

  useEffect(() => {
    register({ name: "id" });
    register({ name: "categoryId" }, { required: true });
    register({ name: "productInfo" });
    register({ name: "productInfo.nameEng" });
    register({ name: "productInfo.nameChi" }, { required: true });
    register({ name: "productInfo.modelNumber" }, { required: true });
    register({ name: "productInfo.thumbnail" });
    register({ name: "productInfo.manufacturer" });
    register({ name: "productInfo.dimension" });
    register({ name: "productInfo.netWeight" });
    register({ name: "productInfo.totalWeight" });
    register({ name: "productInfo.colour" });
    register({ name: "productInfo.specification" });
    register({ name: "productInfo.characteristicTag" });
  }, [register]);

  const onFileChange = ({ fileList }) => {
    let fl = [...fileList];

    fl = fl.slice(-1);

    fl = fl.map((file) => {
      if (file.error) {
        notification.error({
          message: "上載失敗",
        });
        setIsUploading(false);
      } else if (file?.status === "uploading") {
        setIsUploading(true);
      } else if (file.response) {
        file.name = file.response.data.name;
        file.size = file.response.data.size;
        file.key = file.response.data.key;
        file.url = file.response.data.url;
        setIsUploading(false);
      }
      return file;
    });

    fl = fl.filter((file) => !file.error);
    if (fl[0]) {
      const { key, url, name, size } = fl[0];
      if (url && key && name && size) {
        fl[0] = { key, url, name, size };
      }
    }
    setValue("productInfo.thumbnail", fl[0] ?? undefined);
  };

  const onFileRemove = () => {
    setValue("productInfo.thumbnail", undefined);
  };

  const onFilePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      })(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setIsPreviewShow(true);
  };

  const onFilePreviewCancel = () => setIsPreviewShow(false);

  const activityHistory = useMemo(() => {
    return getConsumableData?.consumableGet?.trace?.activityHistory ?? [];
  }, [getConsumableData]);

  const onSaveButtonClick = useCallback(
    async (value) => {
      try {
        const consumableFormValue = JSON.parse(JSON.stringify(value));
        if (consumableFormValue?.productInfo?.thumbnail) {
          const {
            key,
            name,
            size,
          } = consumableFormValue?.productInfo?.thumbnail;
          consumableFormValue.productInfo.thumbnail = { key, name, size };
        }

        if (additionalInfo) {
          additionalInfo.brochureList = additionalInfo?.brochureList?.map(
            ({ key, name, size }) => ({ key, name, size })
          );
          additionalInfo.photoList = additionalInfo?.photoList?.map(
            ({ key, name, size }) => ({ key, name, size })
          );
          additionalInfo.userManual = additionalInfo?.userManual?.map(
            ({ key, name, size }) => ({ key, name, size })
          );
          consumableFormValue.productInfo = {
            ...consumableFormValue?.productInfo,
            ...additionalInfo,
          };
        }

        if (remark) {
          consumableFormValue.remark = remark;
        }

        if (id) {
          await _updateConsumable({
            variables: {
              input: consumableFormValue,
            },
          });
          message.success("成功更新型號資料");
        } else {
          await _createConsumable({
            variables: {
              input: consumableFormValue,
            },
          });
          message.success("成功新增型號資料");
        }
      } catch (error) {
        message.error(error.message);
      }
    },
    [additionalInfo, remark, id, _updateConsumable, _createConsumable]
  );

  const onAdditionalInfoChange = useCallback(({ info, remark }) => {
    setAdditionalInfo(info);
    setRemark(remark);
  }, []);

  return (
    <div className="consumable-form">
      <StickyBreadcrumbPageComponent
        className="consumable-create-page"
        breadcrumb={BREADCRUMB}
      >
        <div className="status-container">
          <Button
            loading={createConsumableLoading}
            type="secondary"
            onClick={() =>
              history.push(
                replaceUrlByParams(PAGE.STOCK_CONSUMABLE_DETAIL, {
                  id: id,
                })
              )
            }
          >
            取消
          </Button>
          <Button
            loading={createConsumableLoading}
            type="primary"
            onClick={handleSubmit(onSaveButtonClick)}
          >
            儲存
          </Button>
        </div>

        <div className="content-inner">
          <Form layout="vertical">
            <Tabs
              activeKey={activeTabKey}
              onChange={(key) => setActiveTabKey(key)}
            >
              <TabPane className="section" tab="基本資料" key="productInfo">
                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>分類</span>
                      <CategoryFilter
                        disabled={IS_READ_ONLY}
                        name="categoryId"
                        value={watchValue.categoryId}
                        onChange={(categoryId) =>
                          setValue("categoryId", categoryId)
                        }
                      />
                      {errors?.categoryId && (
                        <Typography.Text type="danger">
                          請選擇分類
                        </Typography.Text>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>消耗品英文名稱</span>
                      <Input
                        className={
                          errors?.productInfo?.nameEng ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.nameEng"
                        onChange={({ target: { value } }) =>
                          setValue("productInfo.nameEng", value)
                        }
                        value={watchValue.productInfo.nameEng}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>消耗品中文名稱</span>
                      <Input
                        className={
                          errors?.productInfo?.nameChi ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.nameChi"
                        onChange={({ target: { value } }) =>
                          setValue("productInfo.nameChi", value)
                        }
                        value={watchValue.productInfo.nameChi}
                      ></Input>
                      {errors?.categoryId && (
                        <Typography.Text type="danger">
                          請填寫消耗品中文名稱
                        </Typography.Text>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>消耗品型號</span>
                      <Input
                        className={
                          errors?.productInfo?.modelNumber ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.modelNumber"
                        onChange={({ target: { value } }) =>
                          setValue("productInfo.modelNumber", value)
                        }
                        value={watchValue.productInfo.modelNumber}
                      ></Input>
                      {errors?.categoryId && (
                        <Typography.Text type="danger">
                          請填寫消耗品型號
                        </Typography.Text>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>型號縮略圖</span>
                      <Upload
                        name="file"
                        className="thumbnail-uploader"
                        action={getFileUploadUrl()}
                        disabled={isUploading || IS_READ_ONLY}
                        fileList={
                          watchValue.productInfo.thumbnail
                            ? [{ uid: 1, ...watchValue.productInfo.thumbnail }]
                            : undefined
                        }
                        headers={{
                          "x-token": getTokenHeader(),
                          "X-Requested-With": null,
                        }}
                        onChange={onFileChange}
                        onPreview={onFilePreview}
                        onRemove={
                          isUploading || IS_READ_ONLY ? null : onFileRemove
                        }
                        showUploadList={{ showDownloadIcon: false }}
                        listType="picture-card"
                      >
                        <Button disabled={isUploading || IS_READ_ONLY}>
                          <Icon type="upload" /> 上載
                        </Button>
                      </Upload>
                      <Modal
                        visible={isPreviewShow}
                        footer={null}
                        onCancel={onFilePreviewCancel}
                      >
                        <img
                          alt="型號縮略圖"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>生產廠家</span>
                      <Input
                        className={
                          errors?.productInfo?.manufacturer ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.manufacturer"
                        onChange={({ target: { value } }) =>
                          setValue("productInfo.manufacturer", value)
                        }
                        value={watchValue.productInfo.manufacturer}
                      ></Input>
                      <Modal
                        visible={isPreviewShow}
                        footer={null}
                        onCancel={onFilePreviewCancel}
                      >
                        <img
                          alt="型號縮略圖"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>尺寸</span>
                      <Select
                        mode="tags"
                        multiple={true}
                        options={selectionChoices?.dimensionList}
                        className={
                          errors?.productInfo?.dimension ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.dimension"
                        onChange={(value) =>
                          setValue("productInfo.dimension", value)
                        }
                        value={watchValue.productInfo.dimension}
                      >
                        {(selectionChoices?.dimensionList || []).map((tag) => {
                          return (
                            <Select.Option key={tag} value={tag}>
                              {tag}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>顏色</span>
                      <Select
                        mode="tags"
                        multiple={true}
                        className={errors?.productInfo?.colour ? "error" : null}
                        disabled={IS_READ_ONLY}
                        name="productInfo.colour"
                        onChange={(value) =>
                          setValue("productInfo.colour", value)
                        }
                        value={watchValue.productInfo.colour}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>

                {/* <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>總重量(公斤）</span>
                      <InputNumber
                        className={
                          errors?.productInfo?.totalWeight ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        min={0}
                        name="productInfo.totalWeight"
                        onChange={(value) =>
                          setValue("productInfo.totalWeight", Number(value))
                        }
                        value={watchValue.productInfo.totalWeight}
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>淨重(公斤）</span>
                      <InputNumber
                        className={
                          errors?.productInfo?.netWeight ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        min={0}
                        name="productInfo.netWeight"
                        onChange={(value) =>
                          setValue("productInfo.netWeight", Number(value))
                        }
                        value={watchValue.productInfo.netWeight}
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                </Row> */}

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>功能和規格（不超過200個字）</span>
                      <Input.TextArea
                        row={5}
                        className={
                          errors?.productInfo?.specification ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.specification"
                        onChange={({ target: { value } }) =>
                          setValue("productInfo.specification", value)
                        }
                        value={watchValue.productInfo.specification}
                      ></Input.TextArea>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>產品特點</span>
                      <Select
                        mode="tags"
                        multiple={true}
                        className={
                          errors?.productInfo?.characteristicTag
                            ? "error"
                            : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.characteristicTag"
                        onChange={(value) =>
                          setValue("productInfo.characteristicTag", value)
                        }
                        value={watchValue.productInfo.characteristicTag}
                      >
                        {(selectionChoices?.characteristicTagList || []).map(
                          (tag) => {
                            return (
                              <Select.Option key={tag} value={tag}>
                                {tag}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane className="section" tab="附加資料" key="additionalInfo">
                <AdditionalInfoComponent
                  id={id}
                  type={"Consumable"}
                  onChange={onAdditionalInfoChange}
                />
              </TabPane>
              <TabPane className="section" tab="活動記錄" key="activityLog">
                <ActivityHistoryComponent id={id} type={"Consumable"} />
              </TabPane>
            </Tabs>
          </Form>
        </div>
        {pickerParams.visible && <Picker {...pickerParams} />}
      </StickyBreadcrumbPageComponent>
    </div>
  );
};

export default ConsumableForm;
