import React, { useCallback, useState } from "react";
import { Button, notification, Popconfirm, Table, Upload } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import { MOMENT_DATETIME_SECOND_FORMAT } from "../../../utils/constants";

import { gql, useMutation } from "@apollo/client";
import { getFileUploadUrl } from "../../../utils/env";
import { getTokenHeader } from "@utils/api";

const serviceAgreementCreateInServiceBuyer = gql`
  mutation serviceAgreementCreateInServiceBuyer(
    $serviceBuyerId: String!
    $file: FileInput!
  ) {
    serviceAgreementCreateInServiceBuyer(
      serviceBuyerId: $serviceBuyerId
      file: $file
    )
  }
`;

const serviceAgreementDeleteInServiceBuyer = gql`
  mutation serviceAgreementDeleteInServiceBuyer(
    $serviceBuyerId: String!
    $serviceAgreementId: String!
  ) {
    serviceAgreementDeleteInServiceBuyer(
      serviceBuyerId: $serviceBuyerId
      serviceAgreementId: $serviceAgreementId
    )
  }
`;

const ServiceAgreementTable = ({
  serviceBuyer,
  refetch,
  isLoading: buyerIsLoading,
}) => {
  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [
    createServiceAgreement,
    { loading: createServiceAgreementLoading },
  ] = useMutation(serviceAgreementCreateInServiceBuyer);

  const [
    deleteServiceAgreement,
    { loading: deleteServiceAgreementLoading },
  ] = useMutation(serviceAgreementDeleteInServiceBuyer);

  const isLoading =
    buyerIsLoading ||
    createServiceAgreementLoading ||
    deleteServiceAgreementLoading;

  const onServiceAgreementDelete = useCallback(
    async (serviceAgreement) => {
      try {
        const { data } = await deleteServiceAgreement({
          variables: {
            serviceBuyerId: serviceBuyer?.id,
            serviceAgreementId: serviceAgreement?.id,
          },
        });
        if (data?.serviceAgreementDeleteInServiceBuyer) {
          notification.success({
            message: "成功刪除服務協議",
            duration: 5,
          });
          await refetch();
        }
      } catch (err) {
        console.log(err);
      }
    },
    [deleteServiceAgreement, refetch, serviceBuyer]
  );

  const onFileUpload = useCallback(
    async (event) => {
      setFileList(event.fileList);
      const file = event.file;
      const uploadFile = {};
      if (file.error) {
        notification.error({
          message: "上載失敗",
        });
        setIsUploading(false);
        setFileList([]);
      } else if (file?.status === "uploading") {
        setIsUploading(true);
      } else if (file.response) {
        uploadFile.name = file.response.data.name;
        uploadFile.size = file.response.data.size;
        uploadFile.key = file.response.data.key;
        setIsUploading(false);

        try {
          const { data } = await createServiceAgreement({
            variables: { serviceBuyerId: serviceBuyer?.id, file: uploadFile },
          });

          if (data?.serviceAgreementCreateInServiceBuyer) {
            notification.success({
              message: "成功上傳服務協議",
              duration: 5,
            });
            await refetch();
          }
        } catch (err) {
          console.error(err);
        } finally {
          setFileList([]);
        }
      }
    },
    [createServiceAgreement, refetch, serviceBuyer]
  );

  const columns = [
    {
      title: "上傳日期",
      dataIndex: "uploadDate",
      sorter: (a, b) =>
        moment(a.uploadDate).valueOf() > moment(b.uploadDate).valueOf()
          ? 1
          : -1,
      sortDirection: ["descend", "ascend"],
      render: (timestamp) =>
        moment(timestamp).format(MOMENT_DATETIME_SECOND_FORMAT),
    },
    {
      title: "檔案名稱",
      dataIndex: ["file", "name"],
      render: (name, record) => (
        <a href={record?.file?.url} target="__blank">
          {name}
        </a>
      ),
    },
    {
      title: "上傳員工",
      dataIndex: ["staffInCharge", "displayName"],
    },
    {
      render: (_, record) => (
        <Popconfirm
          title="確定要刪除此服務協議嗎？"
          okType="danger"
          onConfirm={() => onServiceAgreementDelete(record)}
        >
          <Button type="danger">刪除</Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <Upload
        accept="image/*, .pdf"
        action={getFileUploadUrl()}
        headers={{
          "x-token": getTokenHeader(),
          "X-Requested-With": null,
        }}
        showUploadList={false}
        fileList={fileList}
        onChange={onFileUpload}
        disabled={isUploading}
      >
        <Button
          loading={createServiceAgreementLoading || isUploading}
          disabled={isLoading}
          style={{ marginBottom: 16 }}
          type="primary"
        >
          新增服務協議
        </Button>
      </Upload>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={serviceBuyer?.serviceAgreement}
        scroll={{x:"max-content"}}
      />
    </div>
  );
};

ServiceAgreementTable.propTypes = {
  serviceBuyer: PropTypes.object,
  refetch: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ServiceAgreementTable;
