/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import {
  Tabs,
  Form,
  Row,
  Col,
  Select,
  Input,
  Upload,
  Button,
  notification,
  Icon,
  Modal,
  InputNumber,
  Avatar,
  Tooltip,
  Comment,
  Card,
  message,
  Table,
  Typography,
  AutoComplete,
} from "antd";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useParams, useHistory, Link } from "react-router-dom";
import moment from "moment";

// Components
import ActivityHistoryComponent from "@components/activityHistory/activityHistory";
import AdditionalInfoComponent from "@components/additionalInfo/additionalInfo";

// Constants
import { PAGE, PAGE_NAME } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";
import { gql } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";

//Styles
import "./_partForm.scss";

// Utils

import { getTokenHeader, replaceUrlByParams } from "@utils/api";
import CategoryFilter from "../../../../components/categoryFilter/categoryFilter";
import Picker from "../../picker/picker";
import { getFileUploadUrl } from "../../../../utils/env";

const { TabPane } = Tabs;

const SELECTION_CHOICES = gql`
  query {
    characteristicTagList(type: ["device", "part", "consumable"])
    brandList(type: ["device", "part", "consumable"])
    dimensionList(type: ["device", "part", "consumable"])
  }
`;

const PART_GET = gql`
  query PartGet($id: ID!) {
    partGet(id: $id) {
      id
      productInfo {
        nameEng
        nameChi
        modelNumber
        thumbnail {
          key
          name
          size
          url
        }
        dimension
        brand
        netWeight
        totalWeight
        colour
        specification
        characteristicTag
        manufacturer
      }
      category {
        id
      }
      defaultDevice {
        id
        referenceId
        productInfo {
          nameEng
          nameChi
        }
        defaultPart {
          quantity
          part {
            id
            productInfo {
              nameEng
              nameChi
            }
          }
        }
      }
      optionalDevice {
        id
        referenceId
        productInfo {
          nameEng
          nameChi
        }
        optionalPart {
          quantity
          part {
            id
            productInfo {
              nameEng
              nameChi
            }
          }
        }
      }
      trace {
        activityHistory {
          staff {
            displayName
          }
          content
          loggedDate
        }
      }
    }
  }
`;

const DEVICE_UPDATE = gql`
  mutation DeviceUpdate($input: DeviceUpdateInput) {
    deviceUpdate(input: $input) {
      id
    }
  }
`;

const PART_CREATE = gql`
  mutation PartCreate($input: PartCreateInput) {
    partCreate(input: $input) {
      id
      productInfo {
        nameEng
        nameChi
        modelNumber
        thumbnail {
          key
          name
          size
        }
        dimension
        brand
        netWeight
        totalWeight
        colour
        specification
        characteristicTag
        manufacturer
      }
      category {
        id
      }
    }
  }
`;

const PART_UPDATE = gql`
  mutation PartUpdate($input: PartUpdateInput) {
    partUpdate(input: $input) {
      id
      productInfo {
        nameEng
        nameChi
        modelNumber
        thumbnail {
          key
          name
          size
        }
        dimension
        brand
        netWeight
        totalWeight
        colour
        specification
        characteristicTag
        manufacturer
      }
      category {
        id
      }
    }
  }
`;

const PartForm = () => {
  const { id } = useParams();
  const history = useHistory();

  const BREADCRUMB = [
    {
      path: id ? PAGE.STOCK_PART_EDIT : PAGE.STOCK_PART_CREATE,
      name: id ? PAGE_NAME.STOCK_PART_EDIT : PAGE_NAME.STOCK_PART_CREATE,
    },
  ];

  const [activeTabKey, setActiveTabKey] = useState("productInfo");
  const [isUploading, setIsUploading] = useState(false);
  const [isPreviewShow, setIsPreviewShow] = useState(false);
  const [previewImage, setPreviewImage] = useState(undefined);

  const [pickerParams, setPickerParams] = useState({
    visible: false,
  });

  const [additionalInfo, setAdditionalInfo] = useState(undefined);
  const [remark, setRemark] = useState(undefined);

  const IS_READ_ONLY = false;

  const [
    getPart,
    { data: getPartData, loading: getPartLoading, error: getPartError },
  ] = useLazyQuery(PART_GET);
  const {
    data: selectionChoices,
    loading: selectionChoiceLoading,
    error: selectionChoiceError,
  } = useQuery(SELECTION_CHOICES);

  const [
    _createPart,
    {
      loading: createPartLoading,
      error: createPartError,
      data: createPartData,
    },
  ] = useMutation(PART_CREATE);
  const [
    _updatePart,
    {
      loading: updatePartLoading,
      error: updatePartError,
      data: updatePartData,
    },
  ] = useMutation(PART_UPDATE);

  const [
    _updateDevice,
    {
      loading: updateDeviceLoading,
      error: updateDeviceError,
      data: updateDeviceData,
    },
  ] = useMutation(DEVICE_UPDATE);

  const {
    clearError,
    errors,
    handleSubmit,
    register,
    setValue,
    triggerValidation,
    watch,
    getValues,
    validate,
  } = useForm({
    defaultValues: {
      id: undefined,
      categoryId: undefined,
      productInfo: {
        nameEng: "",
        nameChi: "",
        modelNumber: "",
        thumbnail: undefined,
        dimension: [],
        brand: undefined,
        netWeight: 0,
        totalWeight: 0,
        colour: [],
        specification: "",
        characteristicTag: [],
      },

      defaultDevice: [],
      optionalDevice: [],
    },
  });

  const watchValue = watch();

  useEffect(() => {
    if (id) {
      getPart({ variables: { id } });
    }
  }, [getPart, id]);

  useEffect(() => {
    if (getPartData?.partGet) {
      const data = getPartData?.partGet;
      setValue("id", data.id);
      setValue("categoryId", data?.category?.id ?? undefined);
      setValue("productInfo.nameEng", data?.productInfo?.nameEng ?? "");
      setValue("productInfo.nameChi", data?.productInfo?.nameChi ?? "");
      setValue("productInfo.modelNumber", data?.productInfo?.modelNumber ?? "");
      setValue(
        "productInfo.thumbnail",
        data?.productInfo?.thumbnail ?? undefined
      );
      setValue("productInfo.dimension", data?.productInfo?.dimension ?? []);
      setValue("productInfo.brand", data?.productInfo?.brand ?? undefined);
      setValue(
        "productInfo.manufacturer",
        data?.productInfo?.manufacturer ?? undefined
      );
      setValue("productInfo.netWeight", data?.productInfo?.netWeight ?? 0);
      setValue("productInfo.totalWeight", data.productInfo.totalWeight ?? 0);
      setValue("productInfo.colour", data?.productInfo?.colour ?? []);
      setValue(
        "productInfo.specification",
        data?.productInfo?.specification ?? ""
      );
      setValue(
        "productInfo.characteristicTag",
        data?.productInfo.characteristicTag ?? []
      );
    }
  }, [getPartData, setValue]);

  useEffect(() => {
    const id = createPartData?.partCreate?.id;
    if (id) {
      history.push(replaceUrlByParams(PAGE.STOCK_PART_EDIT, { id: id }));
    }
  }, [createPartData, history]);

  useEffect(() => {
    register({ name: "id" });
    register({ name: "categoryId" }, { required: true });
    register({ name: "productInfo" });
    register({ name: "productInfo.nameEng" });
    register({ name: "productInfo.nameChi" }, { required: true });
    register({ name: "productInfo.modelNumber" }, { required: true });
    register({ name: "productInfo.thumbnail" });
    register({ name: "productInfo.dimension" });
    register({ name: "productInfo.brand" });
    register({ name: "productInfo.manufacturer" });
    register({ name: "productInfo.netWeight" });
    register({ name: "productInfo.totalWeight" });
    register({ name: "productInfo.colour" });
    register({ name: "productInfo.specification" });
    register({ name: "productInfo.characteristicTag" });

    register({ name: "defaultDevice" });
    register({ name: "optionalDevice" });
  }, [register]);

  const onFileChange = ({ fileList }) => {
    let fl = [...fileList];

    fl = fl.slice(-1);

    fl = fl.map((file) => {
      if (file.error) {
        notification.error({
          message: "上載失敗",
        });
        setIsUploading(false);
      } else if (file?.status === "uploading") {
        setIsUploading(true);
      } else if (file.response) {
        file.name = file.response.data.name;
        file.size = file.response.data.size;
        file.key = file.response.data.key;
        file.url = file.response.data.url;
        setIsUploading(false);
      }
      return file;
    });

    fl = fl.filter((file) => !file.error);
    if (fl[0]) {
      const { key, url, name, size } = fl[0];
      if (url && key && name && size) {
        fl[0] = { key, url, name, size };
      }
    }
    setValue("productInfo.thumbnail", fl[0] ?? undefined);
  };

  const onFileRemove = (file) => {
    setValue("productInfo.thumbnail", undefined);
  };

  const onFilePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      })(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setIsPreviewShow(true);
  };

  const onFilePreviewCancel = () => setIsPreviewShow(false);

  const activityHistory = useMemo(() => {
    return getPartData?.partGet?.trace?.activityHistory ?? [];
  }, [getPartData]);

  const defaultDevice = useMemo(() => {
    return getPartData?.partGet?.defaultDevice.map((device) => {
      const quantity =
        device?.defaultPart?.find(({ part }) => {
          return part.id === id;
        })?.quantity ?? 0;
      return {
        quantity,
        device,
      };
    });
  }, [getPartData, id]);

  useEffect(() => {
    setValue("defaultDevice", defaultDevice);
  }, [setValue, defaultDevice]);

  const onDefaultDeviceAdd = useCallback(
    (device) => {
      const newDefaultDevices = [
        ...(getValues().defaultDevice ?? []),
        {
          device,
          quantity: 0,
        },
      ];
      setValue(`defaultDevice`, newDefaultDevices);
    },
    [getValues, setValue]
  );

  const onDefaultDeviceRemove = useCallback(
    (index) => {
      Modal.confirm({
        title: "確認刪除此預設貨物嗎？",
        okText: "確定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          const newDefaultParts = getValues()?.defaultDevice;
          newDefaultParts.splice(index, 1);
          setValue(`defaultDevice`, newDefaultParts);
        },
      });
    },
    [getValues, setValue]
  );

  const optionalDevice = useMemo(() => {
    return getPartData?.partGet?.optionalDevice.map((device) => {
      const quantity =
        device?.optionalPart?.find(({ part }) => {
          return part.id === id;
        })?.quantity ?? 0;
      return {
        quantity,
        device,
      };
    });
  }, [getPartData, id]);
  useEffect(() => {
    setValue("optionalDevice", optionalDevice);
  }, [setValue, optionalDevice]);

  const onOptionalDeviceAdd = useCallback(
    (device) => {
      const newOptionalDevices = [];
      device.forEach((d) => {
        newOptionalDevices.push(...(getValues().optionalDevice ?? []), {
          device: d,
          quantity: 0,
        });
      });
      setValue(`optionalDevice`, newOptionalDevices);
    },
    [getValues, setValue]
  );

  const onOptionalDeviceRemove = useCallback(
    (index) => {
      Modal.confirm({
        title: "確認刪除此額外貨物嗎？",
        okText: "確定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          const newOptionalDevices = getValues().optionalDevice;
          newOptionalDevices.splice(index, 1);
          setValue(`optionalDevice`, newOptionalDevices);
        },
      });
    },
    [getValues, setValue]
  );

  const onQuantityChange = useCallback(
    (type, index, quantity) => {
      const list = getValues()[type];
      list.splice(index, 1, { ...list[index], quantity });
      setValue(type, list);
    },
    [getValues, setValue]
  );

  const defaultDeviceColumns = useMemo(() => {
    return [
      {
        title: "型號",
        dataIndex: "device.referenceId",
        render: (text, { device }, index) => {
          return (
            <Link
              to={`${replaceUrlByParams(PAGE.STOCK_DEVICE_DETAIL, {
                id: device?.id ?? device?.[index]?.id,
              })}`}
              target="_blank"
            >
              {device?.referenceId ?? device?.[index]?.referenceId}
            </Link>
          );
        },
      },
      {
        title: "名稱",
        dataIndex: "device.productInfo.nameChi",
        render: (text, { device }, index) => {
          return (
            <Typography>
              {device?.productInfo?.nameChi ??
                device?.[index]?.productInfo?.nameChi}
            </Typography>
          );
        },
      },
      {
        title: "數量",
        dataIndex: "quantity",
        render: (quantity, record, index) => {
          return (
            <InputNumber
              value={Number(quantity)}
              onChange={(qty) => onQuantityChange("defaultDevice", index, qty)}
            />
          );
        },
      },
      {
        title: "",
        dataIndex: "device",
        render: (device, record, index) => {
          // TODO remove item
          return (
            <Button
              size="small"
              shape="circle"
              type="danger"
              icon="minus-circle"
              onClick={() => onDefaultDeviceRemove(index)}
            />
          );
        },
      },
    ];
  }, [onDefaultDeviceRemove, onQuantityChange]);

  const optionalDeviceColumns = useMemo(() => {
    return [
      {
        title: "型號",
        dataIndex: "device.referenceId",
        render: (text, { device }, index) => {
          return (
            <Link
              to={`${replaceUrlByParams(PAGE.STOCK_DEVICE_DETAIL, {
                id: device?.[index]?.id,
              })}`}
              target="_blank"
            >
              {text}
            </Link>
          );
        },
      },
      {
        title: "名稱",
        dataIndex: "device.productInfo.nameChi",
        render: (text, { device }, index) => {
          return (
            <Typography>
              {device?.productInfo?.nameChi ??
                device?.[index]?.productInfo?.nameChi}
            </Typography>
          );
        },
      },
      {
        title: "數量",
        dataIndex: "quantity",
        render: (quantity, record, index) => {
          return (
            <InputNumber
              value={Number(quantity)}
              onChange={(qty) => onQuantityChange("optionalDevice", index, qty)}
            />
          );
        },
      },
      {
        title: "",
        dataIndex: "device",
        render: (device, record, index) => {
          // TODO remove item
          return (
            <Button
              size="small"
              shape="circle"
              type="danger"
              icon="minus-circle"
              onClick={() => onOptionalDeviceRemove(index)}
            />
          );
        },
      },
    ];
  }, [onOptionalDeviceRemove, onQuantityChange]);

  const onSaveButtonClick = useCallback(
    async (value) => {
      try {
        const {
          defaultDevice: updatedDefaultDevices = [],
          optionalDevice: updatedOptionalDevices = [],
          ...rest
        } = value;

        const partFormValue = JSON.parse(JSON.stringify(rest));

        if (partFormValue?.productInfo?.thumbnail) {
          const { key, name, size } = partFormValue?.productInfo?.thumbnail;
          partFormValue.productInfo.thumbnail = { key, name, size };
        }

        const _defaultEquippedDevices =
          getPartData?.partGet?.defaultDevice ?? [];
        const defaultDeviceToBeRemoved = _defaultEquippedDevices.filter(
          (_device) => {
            return !updatedDefaultDevices
              .map(({ device }) => device.id)
              .includes(_device.id);
          }
        );

        const _optionalEquippedDevices =
          getPartData?.partGet?.optionalDevice ?? [];
        const optionalDeviceToBeRemoved = _optionalEquippedDevices.filter(
          (_device) => {
            return !updatedDefaultDevices
              .map(({ device }) => device.id)
              .includes(_device.id);
          }
        );

        if (additionalInfo) {
          additionalInfo.brochureList = additionalInfo?.brochureList?.map(
            ({ key, name, size }) => ({
              key,
              name,
              size,
            })
          );
          additionalInfo.photoList = additionalInfo?.photoList?.map(
            ({ key, name, size }) => ({ key, name, size })
          );
          additionalInfo.userManual = additionalInfo?.userManual?.map(
            ({ key, name, size }) => ({ key, name, size })
          );
          partFormValue.productInfo = {
            ...partFormValue?.productInfo,
            ...additionalInfo,
          };
        }

        if (remark) {
          partFormValue.remark = remark;
        }

        if (id) {
          await _updatePart({
            variables: {
              input: partFormValue,
            },
          });
        } else {
          await _createPart({
            variables: {
              input: partFormValue,
            },
          });
        }
        for (let removedDevice of defaultDeviceToBeRemoved) {
          const newDefaultParts = [...removedDevice?.defaultPart];
          const index = newDefaultParts.findIndex(({ part }) => part.id === id);
          if (index >= 0) {
            newDefaultParts.splice(index, 1);
            await _updateDevice({
              variables: {
                input: {
                  id: removedDevice.id,
                  defaultPart: newDefaultParts.map(
                    ({ part: { id: partId }, quantity }) => ({
                      partId,
                      quantity,
                    })
                  ),
                },
              },
            });
          }
        }

        for (let removedDevice of optionalDeviceToBeRemoved) {
          const newOptionalParts = [...removedDevice?.optionalPart];
          const index = newOptionalParts.findIndex(
            ({ part }) => part.id === id
          );
          if (index >= 0) {
            newOptionalParts.splice(index, 1);
            await _updateDevice({
              variables: {
                input: {
                  id: removedDevice.id,
                  optionalPart: newOptionalParts.map(
                    ({ part: { id: partId }, quantity }) => ({
                      partId,
                      quantity,
                    })
                  ),
                },
              },
            });
          }
        }

        for (const [
          i,
          { device, quantity },
        ] of updatedDefaultDevices.entries()) {
          const defaultPartQuantityPairs = (device?.defaultPart ?? []).map(
            ({ part: { id: partId }, quantity }) => ({
              partId,
              quantity,
            })
          );
          const index = defaultPartQuantityPairs.findIndex(
            ({ partId }) => partId === id
          );
          if (index >= 0) {
            defaultPartQuantityPairs.splice(index, 1, {
              quantity,
              partId: id,
            });
          } else {
            defaultPartQuantityPairs.push({
              quantity,
              partId: id,
            });
          }

          await _updateDevice({
            variables: {
              input: {
                id: device?.id,
                defaultPart: defaultPartQuantityPairs,
              },
            },
          });
        }

        for (const { device, quantity } of updatedOptionalDevices) {
          const optionalPartQuantityPairs = (device?.optionalPart ?? []).map(
            ({ part: { id: partId }, quantity }) => ({
              partId,
              quantity,
            })
          );
          const index = optionalPartQuantityPairs.findIndex(
            ({ partId }) => partId === id
          );
          if (index >= 0) {
            optionalPartQuantityPairs.splice(index, 1, {
              quantity,
              partId: id,
            });
          } else {
            optionalPartQuantityPairs.push({
              quantity,
              partId: id,
            });
          }
          await _updateDevice({
            variables: {
              input: {
                id: device.id,
                optionalPart: optionalPartQuantityPairs,
              },
            },
          });
        }

        message.success("成功更新型號資料");
      } catch (error) {
        message.error(error.message);
      }
    },
    [
      getPartData,
      additionalInfo,
      remark,
      id,
      _updatePart,
      _createPart,
      _updateDevice,
    ]
  );

  const onAdditionalInfoChange = useCallback(({ info, remark }) => {
    setAdditionalInfo(info);
    setRemark(remark);
  }, []);

  return (
    <div className="pPart-form">
      <StickyBreadcrumbPageComponent
        className="part-create-page"
        breadcrumb={BREADCRUMB}
      >
        <div className="status-container">
          <Button
            loading={createPartLoading}
            type="secondary"
            onClick={() =>
              history.push(
                replaceUrlByParams(PAGE.STOCK_PART_DETAIL, {
                  id: id,
                })
              )
            }
          >
            取消
          </Button>
          <Button
            loading={createPartLoading}
            type="primary"
            onClick={handleSubmit(onSaveButtonClick)}
          >
            儲存
          </Button>
        </div>

        <div className="content-inner">
          <Form layout="vertical">
            <Tabs
              activeKey={activeTabKey}
              onChange={(key) => setActiveTabKey(key)}
            >
              <TabPane className="section" tab="基本資料" key="productInfo">
                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>分類</span>
                      <CategoryFilter
                        name="categoryId"
                        value={watchValue.categoryId}
                        onChange={(categoryId) =>
                          setValue("categoryId", categoryId)
                        }
                      />
                      {errors?.categoryId && (
                        <Typography.Text type="danger">
                          請選擇分類
                        </Typography.Text>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>零件英文名稱</span>
                      <Input
                        className={
                          errors?.productInfo?.nameEng ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.nameEng"
                        onChange={({ target: { value } }) =>
                          setValue("productInfo.nameEng", value)
                        }
                        value={watchValue.productInfo.nameEng}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>零件中文名稱</span>
                      <Input
                        className={
                          errors?.productInfo?.nameChi ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.nameChi"
                        onChange={({ target: { value } }) =>
                          setValue("productInfo.nameChi", value)
                        }
                        value={watchValue.productInfo.nameChi}
                      ></Input>
                      {errors?.categoryId && (
                        <Typography.Text type="danger">
                          請填寫零件中文名稱
                        </Typography.Text>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>零件型號</span>
                      <Input
                        className={
                          errors?.productInfo?.modelNumber ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.modelNumber"
                        onChange={({ target: { value } }) =>
                          setValue("productInfo.modelNumber", value)
                        }
                        value={watchValue.productInfo.modelNumber}
                      ></Input>
                      {errors?.categoryId && (
                        <Typography.Text type="danger">
                          請填寫零件型號
                        </Typography.Text>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>型號縮略圖</span>
                      <Upload
                        name="file"
                        className="thumbnail-uploader"
                        action={getFileUploadUrl()}
                        disabled={isUploading || IS_READ_ONLY}
                        fileList={
                          watchValue.productInfo.thumbnail
                            ? [
                                {
                                  uid: 1,
                                  ...watchValue.productInfo.thumbnail,
                                },
                              ]
                            : undefined
                        }
                        headers={{
                          "x-token": getTokenHeader(),
                        }}
                        onChange={onFileChange}
                        onPreview={onFilePreview}
                        onRemove={
                          isUploading || IS_READ_ONLY ? null : onFileRemove
                        }
                        showUploadList={{
                          showDownloadIcon: false,
                        }}
                        listType="picture-card"
                      >
                        <Button disabled={isUploading || IS_READ_ONLY}>
                          <Icon type="upload" /> 上載
                        </Button>
                      </Upload>
                      <Modal
                        visible={isPreviewShow}
                        footer={null}
                        onCancel={onFilePreviewCancel}
                      >
                        <img
                          alt="轉介 / 登記表"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>尺寸</span>
                      <Select
                        mode="tags"
                        multiple={true}
                        options={selectionChoices?.dimensionList}
                        className={
                          errors?.productInfo?.dimension ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.dimension"
                        onChange={(value) =>
                          setValue("productInfo.dimension", value)
                        }
                        value={watchValue.productInfo.dimension}
                      >
                        {(selectionChoices?.dimensionList || []).map((tag) => {
                          return (
                            <Select.Option key={tag} value={tag}>
                              {tag}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>顏色</span>
                      <Select
                        mode="tags"
                        multiple={true}
                        className={errors?.productInfo?.colour ? "error" : null}
                        disabled={IS_READ_ONLY}
                        name="productInfo.colour"
                        onChange={(value) =>
                          setValue("productInfo.colour", value)
                        }
                        value={watchValue.productInfo.colour}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>品牌</span>
                      <AutoComplete
                        dataSource={selectionChoices?.brandList ?? []}
                        filterOption={(inputValue, option) => {
                          return (
                            option.props.children
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          );
                        }}
                        className={errors?.productInfo?.brand ? "error" : null}
                        disabled={IS_READ_ONLY}
                        name="productInfo.brand"
                        onChange={(value) =>
                          setValue("productInfo.brand", value)
                        }
                        value={watchValue.productInfo.brand}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>生產廠家</span>
                      <Input
                        className={
                          errors?.productInfo?.manufacturer ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.manufacturer"
                        onChange={({ target: { value } }) =>
                          setValue("productInfo.manufacturer", value)
                        }
                        value={watchValue.productInfo.manufacturer}
                      ></Input>
                    </Form.Item>
                  </Col>
                </Row>

                {/* <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>總重量(公斤）</span>
                      <InputNumber className={errors?.productInfo?.totalWeight ? "error" : null} disabled={IS_READ_ONLY} name="productInfo.totalWeight" onChange={(value) => setValue("productInfo.totalWeight", Number(value))} value={watchValue.productInfo.totalWeight}></InputNumber>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>淨重(公斤）</span>
                      <InputNumber className={errors?.productInfo?.netWeight ? "error" : null} disabled={IS_READ_ONLY} name="productInfo.netWeight" onChange={(value) => setValue("productInfo.netWeight", Number(value))} value={watchValue.productInfo.netWeight}></InputNumber>
                    </Form.Item>
                  </Col>
                </Row> */}

                <Row gutter={[16, 16]} type="flex">
                  <Col xs={12}>
                    <Form.Item>
                      <span>功能和規格（不超過200個字）</span>
                      <Input.TextArea
                        row={5}
                        className={
                          errors?.productInfo?.specification ? "error" : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.specification"
                        onChange={({ target: { value } }) =>
                          setValue("productInfo.specification", value)
                        }
                        value={watchValue.productInfo.specification}
                      ></Input.TextArea>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <span>產品特點</span>
                      <Select
                        mode="tags"
                        multiple={true}
                        className={
                          errors?.productInfo?.characteristicTag
                            ? "error"
                            : null
                        }
                        disabled={IS_READ_ONLY}
                        name="productInfo.characteristicTag"
                        onChange={(value) =>
                          setValue("productInfo.characteristicTag", value)
                        }
                        value={watchValue.productInfo.characteristicTag}
                      >
                        {(selectionChoices?.characteristicTagList || []).map(
                          (tag) => {
                            return (
                              <Select.Option key={tag} value={tag}>
                                {tag}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>

              {/* <TabPane tab="清潔/檢查流程" key="clean"></TabPane> */}
              <TabPane tab="其他適用貨物／消耗品" key="devices">
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Card
                      title="預設貨物"
                      extra={
                        <Button
                          onClick={() =>
                            setPickerParams({
                              pickerType: "device",
                              visible: true,
                              multiple: false,
                              onChange: onDefaultDeviceAdd,
                              onClose: () =>
                                setPickerParams({
                                  visible: false,
                                }),
                            })
                          }
                        >
                          新增貨物型號
                        </Button>
                      }
                    >
                      <Table
                        rowKey={(row) => row.device.id}
                        pagination={false}
                        dataSource={watchValue?.defaultDevice}
                        columns={defaultDeviceColumns}
                        scroll={{x:"max-content"}}
                      />
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card
                      title="額外貨物"
                      extra={
                        <Button
                          onClick={() =>
                            setPickerParams({
                              pickerType: "device",
                              visible: true,
                              multiple: false,
                              onChange: onOptionalDeviceAdd,
                              onClose: () =>
                                setPickerParams({
                                  visible: false,
                                }),
                            })
                          }
                        >
                          新增貨物型號
                        </Button>
                      }
                    >
                      <Table
                        rowKey={(row) => row.device.id}
                        pagination={false}
                        dataSource={watchValue.optionalDevice}
                        columns={optionalDeviceColumns}
                        scroll={{x:"max-content"}}
                      />
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane className="section" tab="附加資料" key="additionalInfo">
                <AdditionalInfoComponent
                  id={id}
                  type={"Part"}
                  onChange={onAdditionalInfoChange}
                />
              </TabPane>
              <TabPane className="section" tab="活動記錄" key="activityLog">
                <ActivityHistoryComponent id={id} type={"Part"} />
              </TabPane>
            </Tabs>
          </Form>
        </div>
        {pickerParams.visible && <Picker {...pickerParams} />}
      </StickyBreadcrumbPageComponent>
    </div>
  );
};

export default PartForm;
