import React, { useCallback, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Styles
import "./servicePriceCreate.scss";

// Utils

import { MOMENT_FORMAT } from "@utils/constants";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Typography,
} from "antd";
import moment from "moment";

const PRICE_CREATE_ON_SERVICE = gql`
  mutation PriceCreateOnService($id: ID!, $input: ItemPriceInput) {
    priceCreateOnService(id: $id, input: $input) {
      id
    }
  }
`;

const StockServicePriceCreate = ({
  visible,
  onClose,
  onPriceCreateCallback,
}) => {
  const { id } = useParams();

  const { errors, handleSubmit, register, setValue, watch } = useForm({
    defaultValues: {
      depositPrice: 0,
      monthlyRentalPrice: 0,
      purchasePrice: 0,
      activeDate: moment(new Date(), MOMENT_FORMAT),
    },
  });

  const [createServicePrice, { loading, error, data }] = useMutation(
    PRICE_CREATE_ON_SERVICE
  );

  const watchValue = watch();

  useEffect(() => {
    register({ name: "purchasePrice" }, { required: true });
    register({ name: "activeDate" }, { required: true });
  }, [register]);

  useEffect(() => {
    if (data) {
      onPriceCreateCallback(true);
    }
  }, [loading, error, data, onPriceCreateCallback]);

  const onPriceChange = (value) => {
    setValue("purchasePrice", value);
  };

  const onDateChange = (date) => {
    setValue("activeDate", date);
  };

  const onSaveButtonClick = useCallback(
    async (value) => {
      if (value.purchasePrice < 0) {
        message.error("請輸入有效金額");
        return;
      }
      try {
        if (id) {
          const nValue = {
            depositPrice: 0,
            monthlyRentalPrice: 0,
            purchasePrice: value.purchasePrice * 100,
            activeDate: value.activeDate,
          };
          const priceFormValue = JSON.parse(JSON.stringify(nValue));
          await createServicePrice({
            variables: {
              id: id,
              input: priceFormValue,
            },
          });
          message.success("成功新增價格");
        }
      } catch (error) {
        message.error(error.message);
      }
    },
    [id, createServicePrice]
  );

  return (
    <div className="PriceCreate">
      <Drawer
        className="PriceCreate-drawer"
        width="50vw"
        visible={visible}
        onClose={onClose}
      >
        <div className="PriceCreate-content">
          <h1>新增價格</h1>
          <div className="PriceCreate-form">
            <Form layout="vertical">
              <Form.Item>
                <div className="header">
                  <span>價格（港幣）</span>
                </div>
                <InputNumber
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  min={0}
                  value={watchValue.purchasePrice}
                  onChange={onPriceChange}
                />
              </Form.Item>
              <Form.Item>
                <div className="header">
                  <span>生效日期（${MOMENT_FORMAT}）</span>
                </div>
                <div type="block">
                  <DatePicker
                    dateFormat={MOMENT_FORMAT}
                    value={watchValue.activeDate}
                    onChange={onDateChange}
                  ></DatePicker>
                </div>
                {errors?.activeDate && (
                  <Typography.Text type="danger">請選擇日期</Typography.Text>
                )}
              </Form.Item>
            </Form>
          </div>
          <div className="btn-container">
            <Button
              className="btn btn-secondary"
              onClick={handleSubmit(onSaveButtonClick)}
            >
              儲存
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

StockServicePriceCreate.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPriceCreateCallback: PropTypes.func,
};

StockServicePriceCreate.defaultProps = {
  visible: false,
};

export default StockServicePriceCreate;
