export const setIsLoadingAction = (isLoading) => dispatch => {
    dispatch({
        type: 'SET_ISLOADING_ACTION',
        isLoading: isLoading
    });
};

export const setIsLoggedInAction = (isLoggedIn) => dispatch => {
    dispatch({
        type: 'SET_ISLOGGEDIN_ACTION',
        isLoggedIn: isLoggedIn
    });
};

export const setUserAction = (user) => dispatch => {
    dispatch({
        type: 'SET_USER_ACTION',
        user: user
    });
};
