// TODO Should be removed after mock login api
import { isDev } from "./env";

export const API = {
  // User
  ME: "/user/me",
  LOGIN: "/user/login",
  LOGOUT: "/user/logout",
};

export const KEY = {
  OVERVIEW: "overview",

  USER: "user",
  USER_SEARCH: "user-search",
  USER_PENDING: "user-pending",
  USER_REJECTED: "user-rejected",
  USER_REGISTERED: "user-registered",

  ORDER: "order",
  ORDER_RESERVE: "order-reserve",
  ORDER_IN_SERVICE: "order-in-service",
  ORDER_TO_BE_TERMINATE: "order-to-be-terminate",
  ORDER_TERMINATED: "order-terminated",

  STOCK_DELIVERY: "stock-delivery",
  STOCK_DELIVERY_ALL: "stock-delivery-all",
  STOCK_DELIVERY_PENDING: "stock-delivery-pending",
  STOCK_DELIVERY_CONFIRMED: "stock-delivery-confirmed",
  STOCK_DELIVERY_COMPLETED: "stock-delivery-completed",

  STOCK: "stock",
  STOCK_INVENTORY: "stock-stock",
  STOCK_DEVICE: "stock-device",
  STOCK_PART: "stock-part",
  STOCK_CONSUMABLE: "stock-consumable",
  STOCK_SERVICE: "stock-service",
  STOCK_CATEGORY: "stock-category",
  STOCK_USER_DISCOUNT: "stock-user-discount",
  STOCK_CHECKING_RECORD: "stock-checking-record",

  WORKSHOP: "workshop",
  WORKSHOP_CLEANING_FLOW: "workshop-cleaning-flow",
  WORKSHOP_CLEANING_STAGE: "workshop-cleaning-stage",
  WORKSHOP_SCANNER: "workshop-scanner",
  WORKSHOP_INSPECTION_CHECKLIST: "workshop-stock-examination-record-list",

  WORKSHOP_WAREHOUSE: "workshop-warehouse",
  WORKSHOP_DASHBOARD: "workshop-dashboard",

  THIRD_PARTY_CLEANING: "third-party-cleaning",
  THIRD_PARTY_CLEANING_BUYER_LIST: "third-party-cleaning-buyer-list",
  THIRD_PARTY_CLEANING_JOB_LIST: "third-party-cleaning-job-list",
  THIRD_PARTY_CLEANING_CATEGORY_LIST: "third-party-cleaning-category-list",
  THIRD_PARTY_CLEANING_REPORTS: "third-party-cleaning-reports",
  THIRD_PARTY_CLEANING_TAG_LIST: "third-party-cleaning-tag-list",

  MOBILE_SCANNER: "mobile-scanner",
  MOBILE_INVENTORY_DETAIL: "mobile-inventory-detail",
  DESKTOP_INVENTORY_DETAIL: "desktop-inventory-detail",
  MOBILE_INSPECTION_FORM: "mobile-inspection-checkllist",
  DESKTOP_INSPECTION_FORM: "desktop-inspection-checkllist",

  SYSTEM: "system",
  SYSTEM_REPORTS: "reports",
  SYSTEM_TOOLS: "tools",

  DEBUG: "debug",
};

export const PAGE = {
  INDEX: "/",
  LOGIN: "/login",
  OVERVIEW: "/overview",
  USER_ASSESSMENT: "/user/{id}/assessment/{assessmentId}",
  USER_CREATE: "/user/create",
  USER_DETAIL: "/user/{id}",
  USER_PENDING: "/user_pending",
  USER_PRESCRIPTION: "/user/{id}/prescription/{prescriptionId}",
  USER_REGISTERED: "/user_registered",
  USER_REJECTED: "/user_rejected",
  USER_SEARCH: "/user/search",
  ORDER: "/order",

  ORDER_RESERVE: "/order_reserve",
  ORDER_IN_SERVICE: "/order_in_service",
  ORDER_TO_BE_TERMINATE: "/order_to_be_terminate",
  ORDER_TERMINATED: "/order_terminated",

  STOCK_DELIVERY_ALL: "/stock_delivery_all",
  STOCK_DELIVERY_PENDING: "/stock_delivery_pending",
  STOCK_DELIVERY_CONFIRMED: "/stock_delivery_confirmed",
  STOCK_DELIVERY_COMPLETED: "/stock_delivery_completed",

  STOCK_INVENTORY: "/stock_inventory",
  STOCK_INVENTORY_DETAIL: "/stock_inventory/{inventoryId}",

  STOCK_DEVICE: "/stock_device",
  STOCK_DEVICE_EDIT: "/stock_device/{id}/edit",
  STOCK_DEVICE_DETAIL: "/stock_device/{id}",
  STOCK_DEVICE_CREATE: "/stock_device/create",

  STOCK_PART: "/stock_part",
  STOCK_PART_EDIT: "/stock_part/{id}/edit",
  STOCK_PART_DETAIL: "/stock_part/{id}",
  STOCK_PART_CREATE: "/stock_part/create",

  STOCK_CONSUMABLE: "/stock_consumable",
  STOCK_CONSUMABLE_EDIT: "/stock_consumable/{id}/edit",
  STOCK_CONSUMABLE_DETAIL: "/stock_consumable/{id}",
  STOCK_CONSUMABLE_CREATE: "/stock_consumable/create",

  STOCK_SERVICE: "/stock_service",
  STOCK_SERVICE_CREATE: "/stock_service/create",
  STOCK_SERVICE_DETAIL: "/stock_service/{id}/{mode}",
  STOCK_CATEGORY: "/stock_category",
  STOCK_CATEGORY_CREATE: "/stock_category/{categoryType}/create",
  STOCK_CATEGORY_RECORD: "/stock_category/{categoryType}/{id}",
  STOCK_CHECKING_RECORD: "/stock_checking_record",

  STOCK_USER_DISCOUNT: "/stock_user_discount",
  ORDERING_DETAIL: "/ordering/{id}",

  WORKSHOP: "/workshop",
  WORKSHOP_CLEANING_FLOW: "/workshop_cleaning_flow",
  WORKSHOP_CLEANING_FLOW_CREATE: "/workshop_cleaning_flow/create",
  WORKSHOP_CLEANING_FLOW_EDIT: "/workshop_cleaning_flow/{id}/edit/",
  WORKSHOP_CLEANING_STAGE: "/workshop_cleaning_stage",
  WORKSHOP_SCANNER: "/workshop_scanner",

  WORKSHOP_INSPECTION_CHECKLIST: "/workshop_inspection_checklist",
  WORKSHOP_INSPECTION_CREATE: "/workshop_inspection_checklist/create",
  WORKSHOP_INSPECTION_EDIT: "/workshop_inspection_checklist/{id}/edit/",

  WORKSHOP_WAREHOUSE: "/workshop_warehouse",

  WORKSHOP_DASHBOARD: "/workshop_dashboard",

  THIRD_PARTY_CLEANING_BUYER_LIST: "/cleaning/buyer/list",
  THIRD_PARTY_CLEANING_BUYER_CREATE: "/cleaning/buyer/create",
  THIRD_PARTY_CLEANING_BUYER_EDIT: "/cleaning/buyer/{id}/edit",

  THIRD_PARTY_CLEANING_JOB_LIST: "/cleaning/job/list",
  THIRD_PARTY_CLEANING_JOB_CREATE: "/cleaning/job/create",
  THIRD_PARTY_CLEANING_JOB_EDIT: "/cleaning/job/{id}/edit",
  THIRD_PARTY_CLEANING_JOB_DEVICE_EDIT: "/cleaning/job/{id}/{deviceId}/edit",
  THIRD_PARTY_CLEANING_JOB_DEVICE_STAGE: "/cleaning/job/{id}/{deviceId}/stage",

  THIRD_PARTY_CLEANING_CATEGORY_LIST: "/cleaning/category/list",

  THIRD_PARTY_CLEANING_REPORTS: "/cleaning/reports",

  THIRD_PARTY_CLEANING_TAG_LIST: "/cleaning/tag/list",

  MOBILE_SCANNER: "/mobile",
  MOBILE_INVENTORY_DETAIL: "/mobile/inventory-detail/{id}",
  MOBILE_INSPECTION_FORM: "/mobile/inspection/{id}",
  DESKTOP_INSPECTION_FORM: "/desktop/inspection/{id}",
  DESKTOP_INVENTORY_DETAIL: "/desktop/inventory-detail/{id}",

  UNAUTHORIZED: "/401",

  SYSTEM: "/system",
  SYSTEM_REPORTS: "/reports",
  SYSTEM_TOOLS: "/tools",

  DEBUG: "/debug",
};

export const PAGE_NAME = {
  OVERVIEW: "總覽",
  USER: "用戶管理",
  USER_ASSESSMENT: "評估",
  USER_PENDING: "審批中轉介個案",
  USER_REJECTED: "拒絕轉介個案",
  USER_REGISTERED: "登記用戶",
  USER_SEARCH: "搜尋個案/用戶",
  USER_CREATE: "個案來源",
  USER_DETAIL: "個案來源",

  ORDER: "訂單管理",
  ORDER_RESERVE: "已預留",
  ORDER_IN_SERVICE: "服務中",
  ORDER_TO_BE_TERMINATE: "即將取消",
  ORDER_TERMINATED: "已完結/取消",

  STOCK_DELIVERY: "運送及裝拆安排",
  STOCK_DELIVERY_ALL: "全部安排",
  STOCK_DELIVERY_AND_DISASSEMBLY_ALL: "全部運送及裝拆安排",
  STOCK_DELIVERY_PENDING: "待確認安排",
  STOCK_DELIVERY_CONFIRMED: "已確認安排",
  STOCK_DELIVERY_COMPLETED: "已完成/取消安排",
  STOCK: "貨物管理",
  STOCK_INVENTORY: "所有存貨",

  STOCK_DEVICE: "器材",
  STOCK_DEVICE_EDIT: "器材",
  STOCK_DEVICE_DETAIL: "器材",
  STOCK_DEVICE_CREATE: "新增器材",

  STOCK_PART: "零件",
  STOCK_PART_EDIT: "零件",
  STOCK_PART_DETAIL: "零件",
  STOCK_PART_CREATE: "新增零件",

  STOCK_CONSUMABLE_EDIT: "消耗品",
  STOCK_CONSUMABLE: "消耗品",
  STOCK_CONSUMABLE_DETAIL: "消耗品",
  STOCK_CONSUMABLE_CREATE: "新增消耗品",

  STOCK_SERVICE: "服務",
  STOCK_SERVICE_DETAIL: "服務資料",
  STOCK_SERVICE_CREATE: "新增服務",
  STOCK_CATEGORY: "貨物分類",
  STOCK_CHECKING_RECORD: "檢查 / 維修貨物記錄表",
  STOCK_USER_DISCOUNT: "用戶折扣",

  ORDERING_DETAIL: "訂單資料",

  WORKSHOP: "工場管理",
  WORKSHOP_CLEANING_FLOW: "清潔流程",
  WORKSHOP_CLEANING_FLOW_CREATE: "新增清潔流程",
  WORKSHOP_CLEANING_FLOW_EDIT: "編輯清潔流程",
  WORKSHOP_CLEANING_STAGE: "清潔步驟",
  WORKSHOP_CLEANING_STAGE_CREATE: "新增清潔步驟",
  WORKSHOP_CLEANING_STAGE_EDIT: "編輯清潔步驟",
  WORKSHOP_SCANNER: "掃瞄 QR Code",

  WORKSHOP_INSPECTION_CHECKLIST: "檢查貨物記錄表",
  WORKSHOP_INSPECTION_CREATE: "新增檢查貨物記錄表",
  WORKSHOP_INSPECTION_EDIT: "編輯檢查貨物記錄表",

  WORKSHOP_WAREHOUSE: "工場地點",
  WORKSHOP_DASHBOARD: "工場存貨",

  THIRD_PARTY_CLEANING: "輔助器材清潔管理",
  THIRD_PARTY_CLEANING_BUYER_LIST: "機構／用戶",
  THIRD_PARTY_CLEANING_BUYER_CREATE: "新增機構／用戶檔案",
  THIRD_PARTY_CLEANING_BUYER_EDIT: "編輯機構／用戶檔案",

  THIRD_PARTY_CLEANING_JOB_LIST: "清潔工作",
  THIRD_PARTY_CLEANING_JOB_CREATE: "新增清潔工作",
  THIRD_PARTY_CLEANING_JOB_EDIT: "清潔工作詳請",
  THIRD_PARTY_CLEANING_JOB_DEVICE_EDIT: "器材詳細資料",
  THIRD_PARTY_CLEANING_JOB_DEVICE_STAGE: "器材清潔流程",

  THIRD_PARTY_CLEANING_CATEGORY_LIST: "器材分類及收費",
  THIRD_PARTY_CLEANING_REPORTS: "服務報告",
  THIRD_PARTY_CLEANING_TAG_LIST: "檔案標籤管理",

  MOBILE_SCANNER: "掃瞄 QR Code",
  MOBILE_INVENTORY_DETAIL: "貨物詳情",
  DESKTOP_INVENTORY_DETAIL: "貨物詳情",
  MOBILE_INSPECTION_FORM: "檢查貨物記錄表",
  DESKTOP_INSPECTION_FORM: "檢查貨物記錄表",

  SYSTEM: "系統工具",
  SYSTEM_REPORTS: "報告",
  SYSTEM_TOOLS: "內部功能",

  DEBUG: "測試工具",
};

export const DEMO_PAGE = [
  {
    path: "/demo/assessment",
    name: "Demo Assessment",
  },
  {
    path: "/demo/form",
    name: "Demo Form",
  },
  {
    path: "/demo/table",
    name: "Demo Table",
  },
  {
    path: "/demo/largeForm",
    name: "Demo Large Form",
  },
  {
    path: "/demo/contract",
    name: "Demo Contract",
  },
];

export const DEFAULT_MSG = {
  error: "網絡出現問題，請重試",
};

export const VALIDATION_STATUS_DEFAULT = {
  validateStatus: "success",
  help: null,
};

export const MOMENT_FORMAT = "YYYY-MM-DD";

export const MOMENT_DATETIME_FORMAT = "YYYY-MM-DD HH:mm";

export const MOMENT_DATETIME_SECOND_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const MOMENT_TIME_FORMAT = "HH:mm";

export const NOTIFICATION_CONFIG = {
  duration: isDev() ? 0 : 3,
  placement: "bottomLeft",
};

export const PAGE_SIZE = 20;

export const PRODUCT_TYPE = {
  device: "Device",
  part: "Part",
  consumable: "Consumable",
  service: "Service",
  misc: "Misc",
};

export const ACCESS_LEVELS = {
  ADMINISTRATION: "administration",
  REFERRALS: "referrals",
  CASE_MANAGEMENT: "caseManagement",
  OPERATION_EQUIPMENT_MANAGEMENT: "operationEquipmentManagement",
  CLERICAL_EQUIPMENT_MANAGEMENT: "clericalEquipmentManagement",
  FINANCIAL_MANAGEMENT: "financialManagement",
  WORKSHOP_MANAGEMENT: "workshopManagement",
  SERVICE_TEAM: "serviceTeam",
  ADMIN_EQUIPMENT_MANAGEMENT: "adminEquipmentManagement",
  SUPERADMIN_EQUIPMENT_MANAGEMENT: "superAdminEquipmentManagement",
};
