import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

// Components
import {InputNumber} from 'antd';

// Style
import './number.scss';

// Utils
import {classList} from '@utils/common';

function AssessmentNumberComponent(props) {
    const {
        numberAnswer,
        className,
        id,
        label,
        onChange,
        placeholder
    } = props;

    useEffect(() => {
        onChange(numberAnswer);

        // eslint-disable-next-line
    }, []);

    const getClassList = () => classList({
        'Assessment-Component': true,
        'AssessmentNumber-Component': true,
        [className]: true
    });

    return (
        <div className={getClassList()}>
            {label && (
                <label
                    className='AssessmentNumber-Component-label'
                    htmlFor={id}>
                    {label}
                </label>
            )}
            <InputNumber
                id={id}
                className='AssessmentNumber-Component-number'
                name={id}
                defaultValue={numberAnswer}
                onChange={(value) => onChange(value)}
                placeholder={placeholder}
            />
        </div>
    );
}

export default AssessmentNumberComponent;

AssessmentNumberComponent.propTypes = {
    numberAnswer: PropTypes.number,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string
};

AssessmentNumberComponent.defalutProps = {
    numberAnswer: undefined,
    className: '',
    label: null,
    placeholder: undefined,
    value: undefined
};
