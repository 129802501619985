/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import { Spin, Typography, Table, Tag } from "antd";
import React, { useMemo } from "react";
import { useHistory, Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";

// Components

// Constants
import { PAGE, PAGE_NAME, ACCESS_LEVELS } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

//Styles
import "./inspectionRecords.scss";

// Utils
import { classList } from "@utils/common";
import { useAccessLevel } from "@utils/hook";

import { replaceUrlByParams } from "@utils/api";

const InspectionRecords = ({ loading, inventoryRecord }) => {
  const history = useHistory();

  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.CASE_MANAGEMENT,
  ]);

  const { isMatched: userIdLinkDisabled } = useAccessLevel([
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.OPERATION_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.CLERICAL_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.WORKSHOP_MANAGEMENT,
    ACCESS_LEVELS.ADMIN_EQUIPMENT_MANAGEMENT,
  ]);

  const columns = [
    {
      title: "記錄編號",
      dataIndex: "referenceId",
      render: (text, record) => {
        const {
          id,
          manMadeDamage,
          manMadeMissingParts,
          reviewingStatus,
          suggestedClaimAmount,
        } = record;
        const redPoint = () => {
          if (
            (manMadeDamage || manMadeMissingParts) &&
            reviewingStatus === "reviewed" &&
            !suggestedClaimAmount
          ) {
            return true;
          } else {
            return false;
          }
        };
        return (
          <>
            {redPoint() && <span className="redPoint"></span>}
            {!isDenied ? (
              <Link
                to={replaceUrlByParams(PAGE.MOBILE_INSPECTION_FORM, { id })}
              >
                {text ?? "N/A"}
              </Link>
            ) : (
              text ?? "N/A"
            )}
          </>
        );
      },
    },
    {
      title: "檢查/維修日期",
      dataIndex: "date",
      sorter: (a, b) => moment(a.date).isAfter(moment(b.date)),
      sortOrder: "descend",
      render: (text) => (
        <Typography>
          {text ? moment(text).format("YYYY-MM-DD") : "-"}
        </Typography>
      ),
    },
    {
      title: "處理員工",
      dataIndex: "trace.creator.displayName",
    },
    {
      title: "結果/狀態",
      dataIndex: "inspectionResult",
      render: (result) => {
        let resultColor = "";
        switch (result.id) {
          case "packed":
            resultColor = "tag sucess";
            break;
          case "pending":
            resultColor = "tag warning";
            break;

          case "repairing":
            resultColor = "tag sucess";
            break;

          case "normal":
            resultColor = "tag sucess";
            break;

          case "repaired":
            resultColor = "tag sucess";
            break;

          case "unableToRepair":
            resultColor = "tag danger";
            break;

          default:
            resultColor = "tag sucess";
            break;
        }
        return <Tag className={resultColor}>{result.name}</Tag>;
      },
    },

    {
      title: "最後更新日期",
      dataIndex: "trace.lastModifiedDate",
      render: (text) => (
        <Typography>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</Typography>
      ),
    },
    {
      title: "用戶號碼",
      dataIndex: "orderingItem.user.username",
      render: (text, record) =>
        !userIdLinkDisabled && !record?.orderingItem?.user?.deleted ? (
          <Link
            className="link"
            to={replaceUrlByParams(PAGE.USER_DETAIL, {
              id: record?.orderingItem?.user?.referralInfo[0]?.id,
            })}
          >
            {text || "-"}
          </Link>
        ) : (
          `${text} ${
            record?.orderingItem?.user?.deleted ? "(Deleted User)" : ""
          }`
        ),
    },
    {
      title: "用戶名稱",
      dataIndex: "orderingItem.user.nameChi",
      render: (text) => text ?? "-",
    },
  ];

  return (
    <Spin spinning={loading || !inventoryRecord}>
      {inventoryRecord ? (
        <StickyBreadcrumbPageComponent>
          <Table
            columns={columns}
            dataSource={inventoryRecord?.inventoryInspection}
            rowKey="id"
            scroll={{x: "max-content"}}
          />
        </StickyBreadcrumbPageComponent>
      ) : (
        <div>未有檢查/維修記錄</div>
      )}
    </Spin>
  );
};

export default InspectionRecords;

InspectionRecords.propTypes = {
  loading: PropTypes.bool,
  inventoryRecord: PropTypes.object,
};
