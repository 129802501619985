/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo } from "react";
import {
  Drawer,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Button,
  Typography,
  message,
  Select,
  Checkbox,
} from "antd";
import { useForm } from "react-hook-form";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";

const STATUS_ENUM_GET = gql`
  query enumInventoryStatusList {
    enumInventoryStatusList {
      id
      name
    }
  }
`;

const WAREHOUSE_LIST_GET = gql`
  query warehouseList {
    warehouseList {
      id
      name
    }
  }
`;

const DEVICE_PURCHASE_INFO_LIST = gql`
  query PurcahseInfoList($id: ID!) {
    deviceGet(id: $id) {
      purchaseInfo {
        referenceId
      }
    }
  }
`;

const PART_PURCHASE_INFO_LIST = gql`
  query PurcahseInfoList($id: ID!) {
    partGet(id: $id) {
      purchaseInfo {
        referenceId
      }
    }
  }
`;

const CONSUMABLE_PURCHASE_INFO_LIST = gql`
  query PurcahseInfoList($id: ID!) {
    consumableGet(id: $id) {
      purchaseInfo {
        referenceId
      }
    }
  }
`;

const DEVICE_INVENTORY_CREATE = gql`
  mutation inventoryRecordCreateOnDevice($input: InventoryRecordCreateInput) {
    inventoryRecordCreateOnDevice(input: $input) {
      id
    }
  }
`;
const DEVICE_INVENTORY_UPDATE = gql`
  mutation inventoryRecordUpdateOnDevice($input: InventoryRecordUpdateInput) {
    inventoryRecordUpdateOnDevice(input: $input) {
      id
    }
  }
`;

const PART_INVENTORY_CREATE = gql`
  mutation inventoryRecordCreateOnPart($input: InventoryRecordCreateInput) {
    inventoryRecordCreateOnPart(input: $input) {
      id
    }
  }
`;
const PART_INVENTORY_UPDATE = gql`
  mutation inventoryRecordUpdateOnPart($input: InventoryRecordUpdateInput) {
    inventoryRecordUpdateOnPart(input: $input) {
      id
    }
  }
`;

const CONSUMABLE_INVENTORY_CREATE = gql`
  mutation inventoryRecordCreateOnConsumable(
    $input: InventoryRecordCreateInput
  ) {
    inventoryRecordCreateOnConsumable(input: $input) {
      id
    }
  }
`;
const CONSUMABLE_INVENTORY_UPDATE = gql`
  mutation inventoryRecordUpdateOnConsumable(
    $input: InventoryRecordUpdateInput
  ) {
    inventoryRecordUpdateOnConsumable(input: $input) {
      id
    }
  }
`;

const CreateInventoryDrawer = (props) => {
  const { defaultValues, onClose, productType } = props;
  const { errors, register, handleSubmit, watch, setValue } = useForm({
    defaultValues,
  });

  console.log(defaultValues);

  const { data: statusEnum } = useQuery(STATUS_ENUM_GET);

  const getProductTypeQuery = () => {
    switch (productType) {
      case "device":
        return DEVICE_PURCHASE_INFO_LIST;
      case "part":
        return PART_PURCHASE_INFO_LIST;
      case "consumable":
        return CONSUMABLE_PURCHASE_INFO_LIST;
      default:
        return undefined;
    }
  };

  const { data: purchaseInfoData } = useQuery(getProductTypeQuery(), {
    variables: {
      id: defaultValues.productId,
    },
  });

  const { data: warehouseListData, loading: warehouseListLoading } = useQuery(
    WAREHOUSE_LIST_GET
  );

  const purchaseInfoList = useMemo(() => {
    switch (productType) {
      case "device":
        return purchaseInfoData?.deviceGet.purchaseInfo;
      case "part":
        return purchaseInfoData?.partGet.purchaseInfo;
      case "consumable":
        return purchaseInfoData?.consumableGet.purchaseInfo;
      default:
        return undefined;
    }
  }, [productType, purchaseInfoData]);

  const formValue = watch();

  useEffect(() => {
    register({ name: "id" });
    register({ name: "productId" }, { required: true });
    register({ name: "serialNumber" });
    register({ name: "firstStorageDate" }, { required: true });
    // register({ name: "storeLocation" });
    register({ name: "warehouseId" }, { required: true });
    register({ name: "activeDate" });
    register({ name: "purchaseInfoReferenceId" });
    // if (productType === 'consumable') {
    // 	register({ name: "quantity" })
    // }
    register({ name: "status" });
    register({ name: "displayItem" });
  }, [formValue.displayItem, productType, register]);

  useEffect(() => {
    defaultValues?.warehouse?.id &&
      setValue("warehouseId", defaultValues?.warehouse?.id);
    defaultValues?.productId && setValue("productId", defaultValues?.productId);
    defaultValues?.purchaseInfo?.referenceId &&
      setValue(
        "purchaseInfoReferenceId",
        defaultValues?.purchaseInfo?.referenceId
      );
    defaultValues?.status && setValue("status", defaultValues?.status.id);
  }, [defaultValues, setValue]);

  const statusToDisplay = ["readyForLease", "cleaning", "pendingToBeRepaired"];
  const statusChangeVisible = useMemo(
    () => statusToDisplay.includes(formValue?.status),
    [formValue, statusToDisplay]
  );

  const [
    createDeviceInventory,
    { loading: createDeviceInventoryLoading },
  ] = useMutation(DEVICE_INVENTORY_CREATE);
  const [
    updateDeviceInventory,
    { loading: updateDeviceInventoryLoading },
  ] = useMutation(DEVICE_INVENTORY_UPDATE);

  const [
    createPartInventory,
    { loading: createPartInventoryLoading },
  ] = useMutation(PART_INVENTORY_CREATE);
  const [
    updatePartInventory,
    { loading: updatePartInventoryLoading },
  ] = useMutation(PART_INVENTORY_UPDATE);

  const [
    createConsumableInventory,
    { loading: createConsumableInventoryLoading },
  ] = useMutation(CONSUMABLE_INVENTORY_CREATE);
  const [
    updateConsumableInventory,
    { loading: updateConsumableInventoryLoading },
  ] = useMutation(CONSUMABLE_INVENTORY_UPDATE);

  const onSubmit = useCallback(
    async (inventory) => {
      let input = inventory;
      if (input.displayItem) {
        input.activeDate = undefined;
      }
      switch (productType) {
        case "device": {
          if (inventory.id) {
            try {
              await updateDeviceInventory({
                variables: { input },
              });
              onClose();
              message.success("存貨已更新");
            } catch (error) {
              message.error(error.message);
            }
          } else {
            try {
              await createDeviceInventory({
                variables: { input },
              });
              onClose();
              message.success("存貨已新增");
            } catch (error) {
              message.error(error.message);
            }
          }
          break;
        }
        case "part": {
          if (inventory.id) {
            try {
              await updatePartInventory({
                variables: { input },
              });
              onClose();
              message.success("存貨已更新");
            } catch (error) {
              message.error(error.message);
            }
          } else {
            try {
              await createPartInventory({
                variables: { input },
              });
              onClose();
              message.success("存貨已新增");
            } catch (error) {
              message.error(error.message);
            }
          }
          break;
        }
        case "consumable": {
          if (inventory.id) {
            try {
              await updateConsumableInventory({
                variables: { input },
              });
              onClose();
              message.success("存貨已更新");
            } catch (error) {
              message.error(error.message);
            }
          } else {
            try {
              await createConsumableInventory({
                variables: { input },
              });
              onClose();
              message.success("存貨已新增");
            } catch (error) {
              message.error(error.message);
            }
          }
          break;
        }
        default:
      }
    },
    [
      onClose,
      productType,
      createDeviceInventory,
      updateDeviceInventory,
      createPartInventory,
      updatePartInventory,
      createConsumableInventory,
      updateConsumableInventory,
    ]
  );

  const statusOptions = useMemo(() => {
    const { Option } = Select;
    const filteredStatus = statusEnum?.enumInventoryStatusList.filter(
      ({ id }) => statusToDisplay.includes(id)
    );
    return (
      filteredStatus &&
      filteredStatus.map((status) => (
        <Option value={status.id} key={status.id}>
          {status.name}
        </Option>
      ))
    );
  }, [statusEnum, statusToDisplay]);

  const purchaseInfoReferenceIdOptions = useMemo(() => {
    const { Option } = Select;
    return (
      purchaseInfoList &&
      purchaseInfoList.map((purchaseInfo) => (
        <Option value={purchaseInfo.referenceId} key={purchaseInfo.referenceId}>
          {purchaseInfo.referenceId}
        </Option>
      ))
    );
  }, [purchaseInfoList]);

  return (
    <Drawer
      width="50%"
      visible={true}
      title="新增數量及存倉資料"
      onClose={onClose}
    >
      <Form layout="horizontal">
        {productType !== "consumable" && (
          <FormItem label="產品序列號">
            <Input
              className={errors?.serialNumber ? "error" : null}
              value={formValue?.serialNumber}
              onChange={({ target: { value } }) =>
                setValue("serialNumber", value)
              }
            />
            {errors?.serialNumber && (
              <Typography.Text type="danger">請填寫產品序列號</Typography.Text>
            )}
          </FormItem>
        )}
        <FormItem label="工場">
          <Select
            loading={warehouseListLoading}
            value={formValue?.warehouseId}
            onChange={(data) => setValue("warehouseId", data)}
          >
            {warehouseListData?.warehouseList?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
          {errors?.warehouseId && (
            <Typography.Text type="danger">請選擇工場</Typography.Text>
          )}
        </FormItem>
        {/* <FormItem label="存倉位置">
          <Input
            className={errors?.storeLocation ? "error" : null}
            value={formValue?.storeLocation}
            onChange={({ target: { value } }) =>
              setValue("storeLocation", value)
            }
          />
          {errors?.storeLocation && (
            <Typography.Text type="danger">請填寫存倉位置</Typography.Text>
          )}
        </FormItem> */}
        <FormItem label="首次入倉日期">
          <DatePicker
            style={{ width: "100%" }}
            className={errors?.firstStorageDate ? "error" : null}
            value={
              formValue?.firstStorageDate
                ? moment(formValue?.firstStorageDate)
                : null
            }
            onChange={(value) =>
              setValue(
                "firstStorageDate",
                value ? moment(value).valueOf() : undefined
              )
            }
          />
          {errors?.firstStorageDate && (
            <Typography.Text type="danger">請填寫首次入倉日期</Typography.Text>
          )}
        </FormItem>
        {(formValue.status === "inactive" ||
          formValue.status === "readyForLease" ||
          !formValue.id) && (
          <FormItem>
            <Checkbox
              className={errors?.displayItem ? "error" : null}
              checked={formValue?.displayItem}
              onChange={({ target: { checked } }) =>
                setValue("displayItem", checked)
              }
            >
              陳列品
            </Checkbox>
          </FormItem>
        )}
        {formValue.displayItem !== true && (
          <FormItem label="上架日期">
            <DatePicker
              style={{ width: "100%" }}
              className={errors?.activeDate ? "error" : null}
              value={
                formValue?.activeDate
                  ? moment(formValue?.activeDate)
                  : undefined
              }
              onChange={(value) =>
                setValue(
                  "activeDate",
                  value ? moment(value).valueOf() : undefined
                )
              }
            />
            {errors?.activeDate && (
              <Typography.Text type="danger">請填寫上架日期</Typography.Text>
            )}
          </FormItem>
        )}
        {/* {productType === 'consumable' &&
					<Form.Item label="數量">
						<InputNumber
							className={errors?.quantity ? "error" : null}
							min={0}
							name="quantity"
							onChange={(value) =>
								setValue("quantity", Number(value))
							}
							value={formValue?.quantity}
						></InputNumber>
					</Form.Item>
				} */}
        {productType !== "consumable" && (
          <FormItem label="採購單號">
            <Select
              showSearch
              className={errors?.purchaseInfoReferenceId ? "error" : null}
              value={formValue?.purchaseInfoReferenceId}
              onChange={(value) => setValue("purchaseInfoReferenceId", value)}
            >
              {purchaseInfoReferenceIdOptions}
            </Select>
            {errors?.purchaseInfoReferenceId && (
              <Typography.Text type="danger">請填寫採購單號</Typography.Text>
            )}
          </FormItem>
        )}
        {/* {productType !== 'consumable' && statusChangeVisible &&
					<FormItem label="更改狀態">
						<Select
							value={statusEnum?.enumInventoryStatusList?.find(({ id }) => id === formValue?.status)?.id}
							onChange={(value) =>
								setValue("status", value)
							}
						>
							{statusOptions}
						</Select>
					</FormItem>
				} */}
      </Form>
      <Button
        onClick={handleSubmit(onSubmit)}
        loading={
          createDeviceInventoryLoading ||
          updateDeviceInventoryLoading ||
          createPartInventoryLoading ||
          updatePartInventoryLoading ||
          createConsumableInventoryLoading ||
          updateConsumableInventoryLoading
        }
        type="primary"
      >
        提交
      </Button>
    </Drawer>
  );
};

CreateInventoryDrawer.propTypes = {
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
  productType: PropTypes.string,
  quantity: PropTypes.number,
};

export default CreateInventoryDrawer;
