/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Styles
import "./balanceTransfer.scss";

// Utils
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Input,
  InputNumber,
  message,
  Row,
  Typography,
  Upload,
} from "antd";
import { amountDisplay } from "@utils/common";
import { getFileUploadUrl } from "@utils/env";
import { getTokenHeader } from "@utils/api";

const TRANSFER_DEPOSIT = gql`
  mutation TransferDeposit($input: TransferDepositInput!) {
    transferDeposit(input: $input)
  }
`;

const BalanceTransfer = ({
  userId,
  depositDifferent,
  visible,
  onClose,
  onSuccessTransfer,
}) => {
  const [transferDeposit, { loading, error, data }] = useMutation(
    TRANSFER_DEPOSIT
  );

  const [transferAmount, setTransferAmount] = useState(undefined);
  const [isTransferAll, setIsTransferAll] = useState(undefined);
  const [fileUploadList, setFileUploadList] = useState(undefined);
  const [remark, setRemark] = useState(undefined);

  const onTransferAmountChange = (value) => {
    setTransferAmount(value);
  };

  const onTransferAllChange = (e) => {
    const checked = e.target.checked;
    setIsTransferAll(checked);
    if (checked) {
      setTransferAmount((depositDifferent / 100).toFixed(2));
    }
  };

  const onAttachmentChange = (info) => {
    let fileList = [...info.fileList];
    // only one file allowed
    fileList = fileList.slice(-1);
    fileList = fileList.map((file) => {
      if (file.response?.data) {
        file.key = file.response.data?.key;
        file.url = file.response.data?.url;
      }
      return file;
    });
    setFileUploadList(fileList);
  };

  const onRemarkChange = (e) => {
    setRemark(e.target.value);
  };

  const onConfirmClick = useCallback(async () => {
    if (transferAmount == null || transferAmount <= 0) {
      message.warning("請填寫金額");
      return;
    }
    try {
      const input = {
        userId: userId,
        amount: transferAmount * 100,
        attachment: fileUploadList
          ? {
              key: fileUploadList[0].key,
              name: fileUploadList[0].name,
              size: fileUploadList[0].size,
            }
          : undefined,
        remark: remark,
      };
      await transferDeposit({
        variables: {
          input: input,
        },
      });
      message.success("成功餘額過戶...");
      onSuccessTransfer();
    } catch (error) {
      message.error(error.message);
    }
  }, [
    userId,
    transferDeposit,
    fileUploadList,
    transferAmount,
    remark,
    onSuccessTransfer,
  ]);

  return (
    <div className="BalanceTransfer">
      <Drawer
        className="BalanceTransfer-drawer"
        width="50vw"
        visible={visible}
        onClose={onClose}
      >
        <div className="BalanceTransfer-content">
          <h1>餘額過戶</h1>
          <div>
            <Row type="flex">
              <Col xs={4}>
                <div className="header">
                  <span>轉帳數目從</span>
                </div>
                <div>
                  <Typography.Title level={3}>按金帳戶</Typography.Title>
                </div>
              </Col>
              <Col xs={2}>
                <Button
                  className="btn btn-secondary"
                  icon="swap"
                  disabled
                  style={{ marginTop: "32px" }}
                />
              </Col>
              <Col xs={4}>
                <div className="header">
                  <span>存入至</span>
                </div>
                <div>
                  <Typography.Title level={3}>租金帳戶</Typography.Title>
                </div>
              </Col>
            </Row>
            <Row type="flex" style={{ marginTop: "20px" }}>
              <Col xs={8}>
                <div className="header">
                  <span>金額數目（港幣）</span>
                </div>
                <div>
                  <InputNumber
                    min={0}
                    max={(depositDifferent / 100).toFixed(2)}
                    placeholder="請填寫金額"
                    disabled={isTransferAll}
                    value={transferAmount}
                    onChange={onTransferAmountChange}
                    style={{ width: "80%" }}
                  />
                </div>
              </Col>
              <Col xs={4}>
                <Checkbox
                  checked={isTransferAll}
                  onChange={onTransferAllChange}
                  style={{ marginTop: "36px" }}
                >
                  全數過戶
                </Checkbox>
              </Col>
            </Row>
            <Row type="flex" style={{ marginTop: "20px" }}>
              <Col xs={24}>
                <div className="header">
                  <span>過戶確認書</span>
                </div>
                <div>
                  <Upload
                    className="ordering-transfer-deposit-uploader"
                    headers={{
                      "x-token": getTokenHeader(),
                      "X-Requested-With": null,
                    }}
                    action={getFileUploadUrl()}
                    fileList={fileUploadList}
                    onChange={onAttachmentChange}
                  >
                    <Button className="btn btn-secondary">上載確認書</Button>
                  </Upload>
                </div>
              </Col>
            </Row>
            <Row type="flex" style={{ marginTop: "20px" }}>
              <Col xs={24}>
                <div className="header">
                  <span>備註</span>
                </div>
                <div>
                  <Input.TextArea
                    rows={6}
                    placeholder="填寫備註"
                    value={remark}
                    onChange={onRemarkChange}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="btn-container">
            <Row type="flex" gutter={12}>
              <Col>
                <Button className="btn btn-secondary" onClick={onClose}>
                  取消
                </Button>
              </Col>
              <Col>
                <Button className="btn" type="primary" onClick={onConfirmClick}>
                  確認
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

BalanceTransfer.propTypes = {
  userId: PropTypes.string.isRequired,
  depositDifferent: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccessTransfer: PropTypes.func,
};

BalanceTransfer.defaultProps = {
  visible: false,
  depositDifferent: 0,
};

export default BalanceTransfer;
