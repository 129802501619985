import React from "react";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import moment from "moment";

// Styles
import "./orderingFilter.scss";

// Utils
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Row,
  Select,
  Typography,
} from "antd";

const { RangePicker } = DatePicker;

const API = gql`
  {
    enumOrderingStatusList {
      id
      name
    }
    enumOrderingDeliveryStatusList {
      id
      name
    }
    enumPaymentStatusList {
      id
      name
    }
  }
`;

function Filter(props) {
  const {
    filterList,
    isFilterShow,
    onOrderingStatusChange,
    onOrderingDeliveryStatusChange,
    onPaymentStatusChange,
    onAssessmentDateChange,
    onRedPointOnly,
    onResetFilter,
    onFilterSearch,
    query,
    availableOrderingStatus,
  } = props;

  const { data } = useQuery(API);

  return (
    <>
      <div className={`Filter ${isFilterShow ? "show" : "hide"}`}>
        <div className="filter-container">
          <div className="options-container">
            <Typography>篩選條件</Typography>
            <div className="options">
              <Row gutter={[{ xs: 8, sm: 16}, 16]}>
                <Col sm={6} xs={20}>
                  {filterList.includes("orderingStatus") && (
                    <div className="option">
                      <Typography>訂單狀態</Typography>
                      <Select
                        value={query.orderingStatus}
                        placeholder="請選擇"
                        onChange={onOrderingStatusChange}
                        mode="multiple"
                      >
                        {data?.enumOrderingStatusList?.map((option) => (
                          <Select.Option
                            key={option.id}
                            value={option.id}
                            disabled={
                              !availableOrderingStatus?.includes(option.id)
                            }
                          >
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  )}
                </Col>
                <Col sm={6} xs={20}>
                  {filterList.includes("paymentStatus") && (
                    <div className="option">
                      <Typography>繳費狀態</Typography>
                      <Select
                        value={query.paymentStatus}
                        placeholder="請選擇"
                        onChange={onPaymentStatusChange}
                        mode="multiple"
                      >
                        {data?.enumPaymentStatusList.map((item) => (
                          <Select.Option
                            key={item.id}
                            label={item.name}
                            value={item.id}
                          >
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  )}
                </Col>
                <Col sm={6} xs={20}>
                  {filterList.includes("orderingDeliveryStatus") && (
                    <div className="option">
                      <Typography>放貨狀態</Typography>
                      <Select
                        value={query.orderingDeliveryStatus}
                        placeholder="請選擇"
                        onChange={onOrderingDeliveryStatusChange}
                        mode="multiple"
                        optionLabelProp="label"
                      >
                        {data?.enumOrderingDeliveryStatusList.map((item) => (
                          <Select.Option
                            key={item.id}
                            label={item.name}
                            value={item.id}
                          >
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  )}
                </Col>
                <Col sm={6} xs={20}>
                  {filterList.includes("assessmentStartDate") && (
                    <div className="option">
                      <Typography>預計評估日期</Typography>
                      <RangePicker
                        value={[
                          query.nextAssessmentDateStart
                            ? moment(Number(query.nextAssessmentDateStart))
                            : undefined,
                          query.nextAssessmentDateEnd
                            ? moment(Number(query.nextAssessmentDateEnd))
                            : undefined,
                        ]}
                        onChange={onAssessmentDateChange}
                        placeholder={["開始日", "結束日"]}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <div className="action-container">
            <Row>
              <Col sm={12} xs={24}>
                <div className="action-checkbox-container">
                  {filterList.includes("redPointOnly") && (
                    <Checkbox
                      onChange={onRedPointOnly}
                      checked={query.redPointOnly}
                    >
                      只顯示紅點項目
                    </Checkbox>
                  )}
                </div>
              </Col>
              <Col sm={12} xs={24}>
                <div className="action-btn-container">
                  <Button className="btn btn-secondary" onClick={onResetFilter}>
                    清除設定
                  </Button>
                  <Button
                    className="btn"
                    type="primary"
                    onClick={onFilterSearch}
                  >
                    搜尋
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default Filter;

Filter.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
  filterList: PropTypes.arrayOf(PropTypes.string),
  isFilterShow: PropTypes.bool.isRequired,
  query: PropTypes.shape({
    orderingStatus: PropTypes.array,
    paymentStatus: PropTypes.array,
    orderingDeliveryStatus: PropTypes.array,
    nextAssessmentDateStart: PropTypes.string,
    nextAssessmentDateEnd: PropTypes.string,
    redPointOnly: PropTypes.bool,
  }),
  onOrderingStatusChange: PropTypes.func,
  onOrderingDeliveryStatusChange: PropTypes.func,
  onPaymentStatusChange: PropTypes.func,
  onAssessmentDateChange: PropTypes.func,
  onRedPointOnly: PropTypes.func,
  onResetFilter: PropTypes.func.isRequired,
  onFilterSearch: PropTypes.func.isRequired,
  availableOrderingStatus: PropTypes.array,
};

Filter.defaultProps = {
  filterList: ["referralAgency", "referral"],
};
