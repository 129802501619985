import React from "react";
import PropTypes from "prop-types";
// import QrReader from "react-qr-scanner";
import QrReader from "react-qr-reader";

// Styles
import "./qrcodeScanner.scss";
function QRCodeScanner({ onScanSuccess, onError }) {
  const onScan = (data) => {
    if (onScanSuccess && data !== null) onScanSuccess(data);
  };

  const onLocalError = (err) => {
    onError && onError(err);
  };

  return (
    <div className="QRCodeScanner">
      <QrReader
        className="scanner"
        delay={200}
        onError={onLocalError}
        onScan={onScan}
        facingMode="environment"
        resolution={1000}
        showViewFinder
        // chooseDeviceId={(filteredDevices, videoDevices) => {
        //   // console.log("chooseDeviceId", filteredDevices, videoDevices);
        //   return filteredDevices.length > 0
        //     ? filteredDevices[0].deviceId // No device found with the pattern thus use another video device
        //     : videoDevices[1]
        //     ? videoDevices[1].deviceId
        //     : videoDevices[0].deviceId; // use second device, if no then use first device
        // }}
      />
    </div>
  );
}

QRCodeScanner.propTypes = {
  onScanSuccess: PropTypes.func,
  onError: PropTypes.func,
};

QRCodeScanner.defaultProps = {};

export default QRCodeScanner;
