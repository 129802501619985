/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Link, useHistory } from "react-router-dom";

// Components
import { Button, Form, Icon, Input, Table, Tag, Typography } from "antd";

// Constants
import { PAGE, PAGE_NAME } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

// Styles
import "./userSearch.scss";

// Utils
import { replaceUrlByParams } from "@utils/api";
import { isEmpty } from "@utils/validate";
import { hideHkid } from "@utils/common";

import { Controller, useForm } from "react-hook-form";

import { ErrorMessage } from "@hookform/error-message";

const REFERRAL_INFO_SEARCH = gql`
  query ReferralInfoSearch($hkid: String) {
    referralInfoSearch(hkid: $hkid) {
      __typename
      userId
      referralInfo {
        id
        referenceId
        referralAgencyName
        referrerName
        referralDate
        personInCharge {
          displayName
        }
        approvalStatus
        user {
          userId
          username
          nameChi
          hkid
          telephone
          editable
        }
      }
      allowCreateReferralInfo
    }
  }
`;

function UserSearchPage(props) {
  let history = useHistory();

  const BREADCRUMB = [
    {
      path: PAGE.USER_SEARCH,
      name: PAGE_NAME.USER_SEARCH,
    },
  ];

  const columns = [
    {
      title: "個案號碼 / 用戶編號",
      dataIndex: "id",
      render: (text, record) =>
        record?.user?.editable ? (
          <Link
            className="link"
            to={replaceUrlByParams(PAGE.USER_DETAIL, { id: text })}
          >
            {record.approvalStatus === "approved"
              ? record.user.username
              : record.referenceId}
          </Link>
        ) : record.approvalStatus === "approved" ? (
          record.user.username
        ) : (
          record.referenceId
        ),
    },
    {
      title: "中文姓名",
      dataIndex: "user.nameChi",
    },
    {
      title: "身份証號碼",
      dataIndex: "user.hkid",
      render: (text) => <span>{hideHkid(text)}</span>,
    },
    {
      title: "電話",
      dataIndex: "user.telephone",
      render: (text) => <span>{text.filter(Boolean).join(", ") || "-"}</span>,
    },
    {
      title: "轉介日期",
      dataIndex: "referralDate",
    },
    {
      title: "轉介人",
      dataIndex: "referrerName",
    },
    {
      title: "轉介機構",
      dataIndex: "referralAgencyName",
    },
    {
      title: "審批負責人",
      dataIndex: "personInCharge.displayName",
    },
    {
      dataIndex: "approvalStatus",
      render: (text) =>
        ({
          pending: <Tag className="tag">未處理</Tag>,
          processing: <Tag className="tag warning">審批中</Tag>,
          rejected: <Tag className="tag danger">已拒絕</Tag>,
          completed: <Tag className="tag">訂單已完結</Tag>,
        }[text]),
    },
  ];

  const { location } = props;

  const {
    clearError,
    control,
    errors,
    handleSubmit,
    triggerValidation,
    watch,
  } = useForm();

  const [
    referralInfoSearch,
    { loading: referralInfoSearchLoading, data: referralInfoSearchData },
  ] = useLazyQuery(REFERRAL_INFO_SEARCH);

  const [disableCreate, setDisableCreate] = useState(true);

  const onSearch = (data) => {
    referralInfoSearch({ variables: { hkid: data.keyword } });
    history.push({
      search: queryString.stringify({ hkid: data.keyword }),
    });
  };

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query.hkid) {
      referralInfoSearch({ variables: { hkid: query.hkid } });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errors?.keyword) {
      setDisableCreate(true);
    } else if (!watch("keyword")) {
      setDisableCreate(true);
    } else {
      setDisableCreate(
        !(
          referralInfoSearchData &&
          referralInfoSearchData.referralInfoSearch?.allowCreateReferralInfo
        )
      );
    }
  }, [errors, watch, referralInfoSearchData]);

  return (
    <StickyBreadcrumbPageComponent
      className="User-search-page"
      breadcrumb={BREADCRUMB}
    >
      <div className="content-inner">
        <div className="search-bar">
          <Form onSubmit={handleSubmit(onSearch)}>
            <Form.Item label="搜尋身份證號碼">
              {/* <Controller
                                as={<Input/>}
                                control={control}
                                name={'keyword'}
                                prefix={<Icon type="search"/>}
                                defaultValue={queryString.parse(location.search).hkid}
                            /> */}
              <div>
                <Controller
                  as={<Input />}
                  className={`${errors.hkid ? "error" : ""}`}
                  control={control}
                  name="keyword"
                  rules={{
                    required: "請輸入身份証號碼",
                    pattern: {
                      value: /^[A-Z]{1,2}[0-9]{6}[0-9,A]$/i,
                      message: "如身份証號碼為A123456(7)，請輸入A1234567",
                    },
                  }}
                  prefix={<Icon type="search" />}
                  defaultValue={queryString.parse(location.search).hkid}
                />
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!watch("keyword")}
                >
                  <Icon type="search" />
                  搜尋
                </Button>
              </div>
              <ErrorMessage
                as={<Typography.Text type="danger" />}
                errors={errors}
                name="keyword"
              />
            </Form.Item>
          </Form>
        </div>
        <div>
          <Typography.Text strong>搜尋結果</Typography.Text>
          <Table
            columns={columns}
            dataSource={
              referralInfoSearchData
                ? referralInfoSearchData.referralInfoSearch?.referralInfo
                : []
            }
            pagination={false}
            loading={referralInfoSearchLoading}
            rowKey="id"
            scroll={{x: "max-content"}}
          />
          <Link
            className="link"
            to={`${PAGE.USER_CREATE}?${queryString.stringify({
              userId: referralInfoSearchData?.referralInfoSearch?.userId,
              hkid: queryString.parse(location.search).hkid,
            })}`}
          >
            <Button
              className="btn btn-secondary"
              // disabled={!(referralInfoSearchData && referralInfoSearchData.referralInfoSearch?.allowCreateReferralInfo)}
              disabled={disableCreate}
            >
              新增個案
            </Button>
          </Link>
        </div>
      </div>
    </StickyBreadcrumbPageComponent>
  );
}

export default UserSearchPage;

UserSearchPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
