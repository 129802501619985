import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

// Components
import {Input} from 'antd';

// Style
import './textarea.scss';

// Utils
import {classList} from '@utils/common';

function AssessmentTextareaComponent(props) {
    const {
        textAnswer,
        className,
        id,
        label,
        onChange,
        placeholder
    } = props;

    useEffect(() => {
        onChange(textAnswer);

        // eslint-disable-next-line
    }, []);

    const getClassList = () => classList({
        'Assessment-Component': true,
        'AssessmentTextarea-Component': true,
        [className]: true
    });

    return (
        <div className={getClassList()}>
            {label && (
                <label
                    className='AssessmentTextarea-Component-label'
                    htmlFor={id}>
                    {label}
                </label>
            )}
            <Input.TextArea
                id={id}
                className='AssessmentTextarea-Component-textarea'
                name={id}
                defaultValue={textAnswer}
                onChange={(evt) => onChange(evt.target.value)}
                placeholder={placeholder}
            />
        </div>
    );
}

export default AssessmentTextareaComponent;

AssessmentTextareaComponent.propTypes = {
    textAnswer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string
};

AssessmentTextareaComponent.defalutProps = {
    textAnswer: undefined,
    className: '',
    label: null,
    placeholder: undefined,
    value: undefined
};
