import React, { useEffect, useCallback } from "react";
import { useMemo } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

// Constants
import { PAGE, PAGE_NAME, PAGE_SIZE, ACCESS_LEVELS } from "@utils/constants";

// Layouts
import TableView from "@layouts/tableView/tableView";

// Styles
import "./cleaningFlowList.scss";

// Utils
import { MOMENT_DATETIME_FORMAT } from "@utils/constants";
import { replaceUrlByParams } from "@utils/api";
import { Button, Dropdown, Icon, Menu, Modal, notification } from "antd";
import { useAccessLevel } from "@utils/hook";

const CLEANINGFLOW_LIST = gql`
  query cleaningFlowList($keyword: String, $size: Int, $offset: Int) {
    cleaningFlowList(keyword: $keyword, size: $size, offset: $offset) {
      id
      name
      remark
      trace {
        createDate
        lastModifiedDate
      }
    }
  }
`;

const CLEANINGFLOW_DELETE = gql`
  mutation cleaningFlowDelete($id: ID) {
    cleaningFlowDelete(id: $id)
  }
`;

const CLEANINGFLOW_COUNT = gql`
  query cleaningFlowCount($keyword: String) {
    cleaningFlowCount(keyword: $keyword)
  }
`;

function CleaingFlowListPage(props) {
  const history = useHistory();
  const { location } = props;
  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.WORKSHOP_MANAGEMENT,
  ]);

  const BREADCRUMB = [
    {
      path: PAGE.WORKSHOP_CLEANING_FLOW,
      name: PAGE_NAME.WORKSHOP_CLEANING_FLOW,
    },
  ];

  const [
    getCleaingFlowList,
    { loading: cleaningFlowListLoading, data: cleaningFlowListData },
  ] = useLazyQuery(CLEANINGFLOW_LIST);

  const [cleaningFlowDelete, { data: cleaningFlowDeleteData }] =
    useMutation(CLEANINGFLOW_DELETE);

  const [
    getCleaningFlowCount,
    {
      // loading: cleaningFlowCountLoading,
      data: cleaningFlowCountData,
    },
  ] = useLazyQuery(CLEANINGFLOW_COUNT);

  const columns = [
    {
      title: "流程名稱",
      width: "15%",
      dataIndex: "name",
      render: (text, record) => (
        <Link
          className="link"
          to={replaceUrlByParams(PAGE.WORKSHOP_CLEANING_FLOW_EDIT, {
            id: record.id,
          })}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "備註",
      dataIndex: "remark",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "新增日期",
      width: "15%",
      dataIndex: "trace.createDate",
      render: (text) => (
        <span>{moment(text).format(MOMENT_DATETIME_FORMAT)}</span>
      ),
    },
    {
      title: "最後更新日期",
      width: "15%",
      dataIndex: "trace.lastModifiedDate",
      render: (text) => (
        <span>{moment(text).format(MOMENT_DATETIME_FORMAT)}</span>
      ),
    },
    {
      dataIndex: "action",
      render: (text, cleaningFlow) => (
        <Dropdown overlay={() => menu(cleaningFlow)}>
          <Button className="btn btn-secondary">
            <Icon type="ellipsis" />
          </Button>
        </Dropdown>
      ),
    },
  ];

  // fetch PAGE_SIZE + 1, to check if there is next page.
  const cleaningFlowList = useMemo(() => {
    return (cleaningFlowListData?.cleaningFlowList || []).slice(0, PAGE_SIZE);
  }, [cleaningFlowListData]);

  const isMaxPage = useMemo(() => {
    const queryParams = queryString.parse(location.search);
    return (
      queryParams.pageSize >=
      (cleaningFlowListData?.cleaningFlowList || []).length
    );
  }, [cleaningFlowListData, location.search]);

  const refreshCleaningFlowList = useCallback(() => {
    const { keyword, page, pageSize } = queryString.parse(location.search);

    getCleaingFlowList({
      variables: {
        keyword,
        offset: +((page - 1) * pageSize) || 0,
        size: +(pageSize || PAGE_SIZE) + 1,
        // sort: sortField,
        // reverseOrder: sortOrder ? sortOrder === "descend" : undefined,
      },
    });

    getCleaningFlowCount({
      variables: {
        keyword,
      },
    });
  }, [getCleaingFlowList, getCleaningFlowCount, location.search]);

  useEffect(() => {
    refreshCleaningFlowList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const onCleaningFlowDelete = useCallback(
    (cleaningFlow) => {
      try {
        cleaningFlowDelete({ variables: { id: cleaningFlow.id } });
      } catch (error) {
        notification.error(error.message);
      }
    },
    [cleaningFlowDelete]
  );

  useEffect(() => {
    if (cleaningFlowDeleteData) {
      cleaningFlowDeleteData?.cleaningFlowDelete
        ? notification.success({ message: "刪除成功" })
        : notification.error({ message: "刪除失敗" });
      refreshCleaningFlowList();
    }
  }, [cleaningFlowDeleteData, refreshCleaningFlowList]);

  const onMenuItemClick = ({ key, cleaningFlow }) => {
    switch (key) {
      case "edit": {
        history.push(
          replaceUrlByParams(PAGE.WORKSHOP_CLEANING_FLOW_EDIT, {
            id: cleaningFlow.id,
          })
        );
        break;
      }
      case "remove": {
        Modal.confirm({
          title: `刪除清潔流程`,
          content: `確定刪除清潔流程 ${cleaningFlow?.name} ?`,
          okText: "確定",
          cancelText: "取消",
          onOk() {
            onCleaningFlowDelete(cleaningFlow);
          },
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  const menu = (cleaningFlow) => (
    <Menu
      onClick={({ key }) => {
        onMenuItemClick({ key, cleaningFlow: cleaningFlow });
      }}
    >
      <Menu.Item key="edit">編輯流程</Menu.Item>
      {!isDenied && <Menu.Item key="remove">刪除流程</Menu.Item>}
    </Menu>
  );

  return (
    <>
      <TableView
        {...props}
        addBtnConfig={{
          url: PAGE.WORKSHOP_CLEANING_FLOW_CREATE,
          text: "新增",
        }}
        breadcrumb={BREADCRUMB}
        rowKey="id"
        columns={columns}
        {...(!isDenied && { hasAddBtn: true })}
        isMaxPage={isMaxPage}
        isTableLoading={cleaningFlowListLoading}
        searchBarPlaceholder="搜尋清潔流程"
        tableData={cleaningFlowList}
        total={cleaningFlowCountData?.cleaningFlowCount}
      ></TableView>
    </>
  );
}

export default CleaingFlowListPage;

CleaingFlowListPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
