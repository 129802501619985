import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Style
import './custBreadcrumb.scss';

// Utils
import { Breadcrumb, Typography, Icon } from 'antd';

function CustBreadcrumbComponent(props) {
    const { paths, children } = props;

    return (
        <Breadcrumb className='CustBreadcrumb-Component' separator={<Icon type="right" />}>
            {
                paths.map((path, i) => {
                    return (
                        <Breadcrumb.Item key={path.path}>
                            {i === (paths.length - 1)
                                ? (
                                    <Typography.Text className="text-item"
                                                     to={path.path}>{path.name}</Typography.Text>
                                )
                                : <Link className="link-item" to={path.path}>{path.name}</Link>
                            }
                        </Breadcrumb.Item>
                    );
                })
            }
            {children}
        </Breadcrumb>
    );
}

export default CustBreadcrumbComponent;

CustBreadcrumbComponent.propTypes = {
    paths: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            path: PropTypes.string
        })
    ).isRequired,
    children: PropTypes.node
};

CustBreadcrumbComponent.defaultProps = {
    children: null
};
