/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from "react";
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";
import { PAGE, PAGE_NAME } from "../../../../utils/constants";
import {
  Button,
  Drawer,
  Form,
  Input,
  notification,
  Popconfirm,
  Table,
} from "antd";

import "./tagList.scss";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";

import PropTypes from "prop-types";
import { useDisclosureParam } from "../../../../utils/hook";

const serviceBuyerTagList = gql`
  query serviceBuyerTagList {
    serviceBuyerTagList {
      id
      name
    }
  }
`;

const serviceBuyerTagCreate = gql`
  mutation serviceBuyerTagCreate($input: ServiceBuyerTagCreateInput!) {
    serviceBuyerTagCreate(input: $input) {
      id
      name
    }
  }
`;

const serviceBuyerTagDelete = gql`
  mutation serviceBuyerTagDelete($serviceBuyerTagId: String!) {
    serviceBuyerTagDelete(serviceBuyerTagId: $serviceBuyerTagId)
  }
`;

const serviceBuyerTagUpdate = gql`
  mutation serviceBuyerTagUpdate($input: [ServiceBuyerTagUpdateInput]!) {
    serviceBuyerTagUpdate(input: $input) {
      id
      name
    }
  }
`;

const BREADCRUMB = [
  {
    path: PAGE.THIRD_PARTY_CLEANING_TAG_LIST,
    name: PAGE_NAME.THIRD_PARTY_CLEANING_TAG_LIST,
  },
];

const BuyerTagDrawer = ({
  visible,
  onClose = () => undefined,
  onSave = () => undefined,
}) => {
  const [createServiceTag, { loading }] = useMutation(serviceBuyerTagCreate);

  const { control, handleSubmit, reset, errors } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      await createServiceTag({ variables: { input: data } });
      notification.success({
        message: "成功新增標籤",
        duration: 5,
      });
      onClose();
      await onSave();
    },
    [createServiceTag, onClose, onSave]
  );

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [reset, visible]);

  return (
    <Drawer width="30%" title="新增標籤" visible={visible} onClose={onClose}>
      <Form>
        <Form.Item
          label="標籤名稱"
          validateStatus={!!errors?.name && "error"}
          {...(errors?.name && { help: "請輸入標籤名稱" })}
        >
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={(fields) => <Input {...fields} />}
          />
        </Form.Item>
      </Form>
      <div className="btn-container">
        <Button
          className="btn btn-secondary"
          onClick={onClose}
          disabled={loading}
        >
          取消
        </Button>
        <Button
          className="btn"
          type="primary"
          onClick={handleSubmit(onSubmit)}
          loading={loading}
        >
          建立
        </Button>
      </div>
    </Drawer>
  );
};

BuyerTagDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};

const BuyerTagList = () => {
  const createDrawerDisclosure = useDisclosureParam();

  const {
    data: getTagListData,
    loading: getTagListIsLoading,
    refetch,
  } = useQuery(serviceBuyerTagList);

  const [
    deleteServiceBuyerTag,
    { loading: deleteServiceBuyerTagLoading },
  ] = useMutation(serviceBuyerTagDelete);

  const [
    updateServiceBuyerTag,
    { loading: updateServiceBuyerTagLoading },
  ] = useMutation(serviceBuyerTagUpdate);

  const isLoading =
    getTagListIsLoading ||
    deleteServiceBuyerTagLoading ||
    updateServiceBuyerTagLoading;

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      tags: {},
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      if (Object.entries(data?.tags || {}).length === 0) return;
      const tags = Object.entries(data.tags).map(([id, name]) => ({
        id,
        name,
      }));
      await updateServiceBuyerTag({ variables: { input: tags } });
      notification.success({
        message: "成功更新標籤",
        duration: 5,
      });
      await refetch();
    },
    [refetch, updateServiceBuyerTag]
  );

  const onDelete = useCallback(
    async (id) => {
      await deleteServiceBuyerTag({
        variables: { serviceBuyerTagId: id },
      });
      await refetch();
    },
    [deleteServiceBuyerTag, refetch]
  );

  const columns = [
    {
      title: "標籤",
      dataIndex: "name",
      width: "100%",
      render: (_, record) => {
        return (
          <Form.Item
            className="table-form-item-label"
            validateStatus={errors?.tags?.[record.id] ? "error" : ""}
            help={errors?.tags?.[record.id] && "請填寫標籤"}
          >
            <Controller
              control={control}
              defaultValue={record.name}
              name={`tags.${record.id}`}
              rules={{ required: true }}
              render={(fields) => <Input {...fields} />}
            />
          </Form.Item>
        );
      },
    },
    {
      render: (_, record) => {
        return (
          <Popconfirm
            title={`確定刪除 ${record?.name}？`}
            onConfirm={() => onDelete(record.id)}
            placement="left"
          >
            <Button className="btn btn-danger">刪除</Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <StickyBreadcrumbPageComponent className="tag-list" breadcrumb={BREADCRUMB}>
      <BuyerTagDrawer
        visible={createDrawerDisclosure.isOpen}
        onClose={createDrawerDisclosure.onClose}
        onSave={() => refetch()}
      />
      <div className="content-inner">
        <div className="btn-container">
          <Button
            className="btn btn-secondary"
            onClick={createDrawerDisclosure.onOpen}
          >
            新增
          </Button>
          <Button
            className="btn"
            type="primary"
            htmlType="submit"
            onClick={handleSubmit(onSubmit)}
          >
            儲存
          </Button>
        </div>
        <Table
          loading={isLoading}
          rowKey="id"
          columns={columns}
          dataSource={getTagListData?.serviceBuyerTagList || []}
          pagination={false}
          scroll={{x:"max-content"}}
        />
      </div>
    </StickyBreadcrumbPageComponent>
  );
};

export default BuyerTagList;
