/* eslint-disable no-unused-vars */
import React from "react";
import { Col, notification, Row, Select } from "antd";

// Constants
import { PAGE, PAGE_NAME, ACCESS_LEVELS } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";
import FormItem from "antd/lib/form/FormItem";
import { useDispatch, useSelector } from "react-redux";
import { setUserAction } from "../../actions/storeAction";

// Styles

// Utils

function DebugPage() {
  const dispatch = useDispatch();

  const store = useSelector((state) => state.storeReducer);
  const { user } = store;

  const BREADCRUMB = [
    {
      path: PAGE.DEBUG,
      name: PAGE_NAME.DEBUG,
    },
  ];

  return (
    <StickyBreadcrumbPageComponent
      className="Debug-page"
      breadcrumb={BREADCRUMB}
    >
      <div className="content-inner">
        <Row>
          <Col>
            <FormItem label="User Access Level">
              <Select
                maxTagCount={3}
                value={user?.accessLevel ?? []}
                onChange={(levels) => {
                  try {
                    dispatch(setUserAction({ ...user, accessLevel: levels }));
                    notification.success({
                      message: "(Debug) Set User Access Level",
                      duration: 3,
                    });
                  } catch (err) {
                    console.log(err);
                  }
                }}
                style={{ minWidth: "100%" }}
                mode="multiple"
                allowClear
              >
                {Object.entries(ACCESS_LEVELS).map(([key, value]) => (
                  <Select.Option key={key} value={value}>
                    {key}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
      </div>
    </StickyBreadcrumbPageComponent>
  );
}

export default DebugPage;
