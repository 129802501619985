import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Link, useHistory } from "react-router-dom";

// Components
import { Tag } from "antd";
import Filter from "../filter/filter";

// Constants
import { PAGE, PAGE_NAME, PAGE_SIZE } from "@utils/constants";

// Layouts
import TableView from "@layouts/tableView/tableView";

// Styles
import "./userPending.scss";

// Utils
import { replaceUrlByParams } from "@utils/api";
import { hideHkid } from "@utils/common";

import { isEmpty, isEmptyObj } from "@utils/validate";

const REFERRAL_INFO_LIST = gql`
  query ReferralInfoList(
    $keyword: String
    $size: Int!
    $offset: Int!
    $status: [WorkflowStatus]!
    $sort: SortReferralInfoList
    $referralAgencyName: String
    $referrerName: String
    $reverseOrder: Boolean
  ) {
    referralInfoList(
      keyword: $keyword
      offset: $offset
      size: $size
      status: $status
      sort: $sort
      referralAgencyName: $referralAgencyName
      referrerName: $referrerName
      reverseOrder: $reverseOrder
    ) {
      id
      referralAgencyName
      referenceId
      referrerName
      referralDate
      personInCharge {
        displayName
      }
      approvedDate
      approvalStatus
      user {
        username
        userId
        nameChi
        hkid
        telephone
        deleted
      }
    }
  }
`;

const REFERRAL_INFO__COUNT = gql`
  query referralInfoCount(
    $keyword: String
    $status: [WorkflowStatus]!
    $referralAgencyName: String
    $referrerName: String
  ) {
    referralInfoCount(
      keyword: $keyword
      status: $status
      referralAgencyName: $referralAgencyName
      referrerName: $referrerName
    )
  }
`;

function UserPendingPage(props) {
  let history = useHistory();

  const BREADCRUMB = [
    {
      path: PAGE.USER_PENDING,
      name: PAGE_NAME.USER_PENDING,
    },
  ];

  const columns = [
    {
      title: "個案號碼",
      dataIndex: "referenceId",
      sorter: true,
      render: (text, record) => (
        <Link
          className="link"
          to={replaceUrlByParams(PAGE.USER_DETAIL, { id: record.id })}
        >
          {record.referenceId}
        </Link>
      ),
    },
    {
      title: "中文姓名",
      dataIndex: "user.nameChi",
    },
    {
      title: "身份証號碼",
      dataIndex: "user.hkid",
      render: (text) => <span>{hideHkid(text)}</span>,
    },
    {
      title: "電話",
      dataIndex: "user.telephone",
      render: (text) => <span>{text?.filter(Boolean)?.join(", ") || "-"}</span>,
    },
    {
      title: "轉介日期",
      dataIndex: "referralDate",
      sorter: true,
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "轉介人",
      dataIndex: "referrerName",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "轉介機構",
      dataIndex: "referralAgencyName",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "審批負責人",
      dataIndex: "personInCharge.displayName",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      dataIndex: "approvalStatus",
      render: (text, record) =>
        ({
          pending: <Tag className="tag">未處理</Tag>,
          processing: <Tag className="tag warning">審批中</Tag>,
        }[record.approvalStatus]),
    },
  ];

  const { location } = props;

  const [
    referralInfoList,
    {
      loading: referralInfoListLoading,
      error: referralInfoListError,
      data: referralInfoListData,
    },
  ] = useLazyQuery(REFERRAL_INFO_LIST, {
    variables: {
      keyword: queryString.parse(location.search).keyword,
      size: +queryString.parse(location.search).pageSize + 1 || PAGE_SIZE + 1,
      offset:
        +(
          (queryString.parse(location.search).page - 1) *
          queryString.parse(location.search).pageSize
        ) || 0,
      status: queryString.parse(location.search).status || [
        "pending",
        "processing",
      ],
      sort: queryString.parse(location.search).sortField,
      referrerName: queryString.parse(location.search).referral,
      referralAgencyName: queryString.parse(location.search).referralAgency,
      reverseOrder: queryString.parse(location.search).sortOrder === "descend",
    },
  });
  const [
    referralInfoCount, {
      // loading: referralInfoCountLoading,
      data: referralInfoCountData
    },
  ] = useLazyQuery(REFERRAL_INFO__COUNT, {
    variables: {
      keyword: queryString.parse(location.search).keyword,
      status: queryString.parse(location.search).status || [
        "pending",
        "processing",
      ],
      referrerName: queryString.parse(location.search).referral,
      referralAgencyName: queryString.parse(location.search).referralAgency,
    },
  });

  const [isFilterShow, setIsFilterShow] = useState(false);
  const [isMaxPage, setIsMaxPage] = useState(true);
  const [query, setQuery] = useState({
    referral: queryString.parse(location.search).referral,
    referralAgency: queryString.parse(location.search).referralAgency,
    status: queryString.parse(location.search).status,
  });
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const query = queryString.parse(location.search);

    history.push({
      search: `?${queryString.stringify(query)}`,
    });

    // if (Object.prototype.hasOwnProperty.call(query, 'referralAgency') || Object.prototype.hasOwnProperty.call(query, 'referral') || Object.prototype.hasOwnProperty.call(query, 'status')) {
    //     setIsFilterShow(true);
    // }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // const query = queryString.parse(location.search);
    // if (Object.prototype.hasOwnProperty.call(query, 'keyword')) {
    //     setQuery({
    //         referral: undefined,
    //         referralAgency: undefined,
    //         status: undefined
    //     });
    //     setIsFilterShow(false);
    // }

    getReferralInfoList();

    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (referralInfoListData?.referralInfoList) {
      const size = +queryString.parse(location.search).pageSize || PAGE_SIZE;
      setTableData(referralInfoListData.referralInfoList.slice(0, size));
      setIsMaxPage(referralInfoListData.referralInfoList.length !== size + 1);
    }

    // eslint-disable-next-line
  }, [referralInfoListLoading, referralInfoListError, referralInfoListData]);

  const getReferralInfoList = () => {
    let q = queryString.parse(location.search);
    if (isEmptyObj(q)) {
      q = {
        page: 1,
        pageSize: PAGE_SIZE,
        referral: undefined,
        referralAgency: undefined,
        status: undefined,
      };

      history.push({
        search: `?${queryString.stringify(q)}`,
      });
    } else {
      if (
        Object.prototype.hasOwnProperty.call(q, "keyword") &&
        isEmpty(q.keyword)
      ) {
        q = {
          page: 1,
          pageSize: PAGE_SIZE,
          referral: undefined,
          referralAgency: undefined,
          status: undefined,
        };

        history.push({
          search: `?${queryString.stringify(q)}`,
        });
      } else {
        referralInfoList();
        referralInfoCount();
      }
    }
  };

  const onFilterClick = (state) => {
    setIsFilterShow(state);
  };

  const onFilterSearch = () => {
    let q = queryString.parse(location.search);
    q.page = 1;
    // delete q.keyword;
    delete q.referralAgency;
    delete q.referral;
    delete q.status;

    history.push({
      search: `?${queryString.stringify(q)}&${queryString.stringify(query)}`,
    });
  };

  const onReferralAgencyChange = (val) => {
    setQuery({
      ...query,
      referralAgency: val,
    });
  };

  const onReferralChange = (evt) => {
    setQuery({
      ...query,
      referral: evt.target.value,
    });
  };

  const onResetFilter = () => {
    setQuery({
      referralAgency: undefined,
      referral: undefined,
      status: undefined,
    });
    history.push({
      search: queryString.stringify({
        page: 1,
        pageSize: PAGE_SIZE,
      }),
    });
  };

  const onStatusChange = (val) => {
    setQuery({
      ...query,
      status: val,
    });
  };

  return (
    <TableView
      {...props}
      className="User-pending-page"
      addBtnConfig={{
        url: PAGE.USER_CREATE,
        text: "新增個案",
      }}
      breadcrumb={BREADCRUMB}
      rowKey="id"
      columns={columns}
      hasFilter
      isFilterShow={isFilterShow}
      isMaxPage={isMaxPage}
      isTableLoading={referralInfoListLoading}
      onFilterClick={onFilterClick}
      searchBarPlaceholder="搜尋用戶姓名/電話"
      tableData={tableData}
      total={referralInfoCountData?.referralInfoCount}
    >
      <Filter
        filterList={["referralAgency", "referral", "status"]}
        isFilterShow={isFilterShow}
        onFilterSearch={onFilterSearch}
        onReferralAgencyChange={onReferralAgencyChange}
        onReferralChange={onReferralChange}
        onResetFilter={onResetFilter}
        onStatusChange={onStatusChange}
        query={query}
      />
    </TableView>
  );
}

export default UserPendingPage;

UserPendingPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
