import React, { useMemo } from "react";
import PropTypes from "prop-types";

// Components
import { Button, Drawer, Form, message, Spin, Table, Typography } from "antd";

// Style
import "./orderingItemsCartDrawer.scss";

// Utils
import { classList } from "@utils/common";

function OrderingItemsCartDrawerComponent(props) {
  const { Text } = Typography;
  const {
    className,
    visible,
    onClose,
    onSubmit,
    items,
    setItems,
    isLoading,
  } = props;

  const dataSource = useMemo(() => {
    return Object.values(items)
      .map((item) => {
        return {
          ...item,
        };
      })
      .filter((it) => it.qty > 0);
  }, [items]);

  const cols = useMemo(
    () => [
      {
        title: "型號",
        dataIndex: "referenceId",
      },
      {
        title: "分類",
        dataIndex: "category.mainCategory",
        render: (mainCategory) => (mainCategory ? mainCategory.name : false),
      },
      {
        title: "子類別",
        dataIndex: "category",
        render: (category) => (category ? category.name : false),
      },
      {
        title: "名稱",
        dataIndex: "productInfo.nameChi",
      },
      {
        title: "可選擇數量",
        dataIndex: "max",
      },
      {
        title: "已選擇數量",
        dataIndex: "action",
        render: (text, record) => (
          <div className="counter">
            <Button
              type="primary"
              shape="circle"
              icon="plus"
              size="small"
              onClick={() => {
                const num = items ? items[record.referenceId]?.qty || 0 : 0;
                const max = items ? items[record.referenceId]?.max || 0 : 0;
                if (num === max) {
                  message.warning("已選取上限數量");
                  return;
                }
                setItems((prev) => ({
                  ...prev,
                  [record.referenceId]: {
                    ...record,
                    qty: (prev[record.referenceId].qty || 0) + 1,
                  },
                }));
              }}
            />
            <Text>{record.qty}</Text>
            <Button
              type="primary"
              shape="circle"
              icon="minus"
              size="small"
              onClick={() =>
                setItems((prev) => ({
                  ...prev,
                  [record.referenceId]: {
                    ...record,
                    qty: prev[record.referenceId].qty - 1,
                  },
                }))
              }
            />
          </div>
        ),
      },

      // eslint-disable-next-line
    ],
    [dataSource]
  );

  // console.log("dataSource",dataSource)

  const onDrawerClose = () => {
    onClose();
  };

  const getClassList = () =>
    classList({
      "OrderingItemsCartDrawer-Component": true,
      [className]: true,
    });

  return (
    <Drawer
      className={getClassList()}
      title="選取清單"
      placement="right"
      closable={true}
      onClose={onDrawerClose}
      visible={visible}
      width="48vw"
    >
      <Spin spinning={isLoading}>
        <Form onSubmit={onSubmit}>
          <Table
            columns={cols}
            dataSource={dataSource}
            rowKey="referenceId"
            pagination={false}
            scroll={{x:"max-content"}}
          />
          <div className="form-btn-container">
            <Button
              className="btn"
              type="primary"
              htmlType="submit"
              disabled={dataSource.length === 0}
            >
              提交
            </Button>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default OrderingItemsCartDrawerComponent;

OrderingItemsCartDrawerComponent.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  items: PropTypes.object.isRequired,
  setItems: PropTypes.func.isRequired,
  isCreate: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

OrderingItemsCartDrawerComponent.defalutProps = {
  className: "",
};
