import React, { useMemo } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

// Style
import './serviceOrderingItemsCartDrawer.scss';

// Utils
import { Button, Drawer, Form, Table, Tag, Typography } from 'antd';
import { replaceUrlByParams } from "@utils/api";
import { classList } from '@utils/common';
import { PAGE } from "@utils/constants";

function ServiceOrderingItemsCartDrawer(props) {
    const { className, visible, onClose, onSubmit, items, setItems } = props;
    const { Text } = Typography

    const dataSource = useMemo(() => {
        return Object.values(items).map((item) => {
            return {
                ...item
            }
        }).filter(it => it.qty > 0)
    }, [items])

    const columns = [
        {
            title: "服務編號",
            dataIndex: "referenceId",
            render: (text, service) => (
                <Link
                    className="link"
                    to={replaceUrlByParams(PAGE.STOCK_SERVICE_DETAIL, { id: service.id, mode: "view" })}
                    style={{ textDecoration: "underline" }}
                    target='_blank'
                >
                    {text}
                </Link>
            )
        },
        {
            title: "名稱",
            dataIndex: "nameChi"
        },
        {
            title: "分類",
            dataIndex: "category",
            render: (category) => (
                <div className="text">
                    {category.name}
                </div>
            )
        },
        {
            title: "子類別",
            dataIndex: "category.mainCategory",
            render: (mainCategory) => (
                <div className="text">
                    {mainCategory.name}
                </div>
            )
        },
        {
            title: "狀態",
            dataIndex: "status",
            render: (status) => (
                { 
                    'inactive': <Tag className="tag warning" >{status.name}</Tag>,
                    'readyForLease': <Tag className="tag success" >{status.name}</Tag>,
                }[status.id]
            )
        },
        {
            dataIndex: 'action',
            render: (text, service) => (
                // <Button
                //     type="primary"
                //     shape="circle"
                //     icon="minus"
                //     size='small'
                //     onClick={() => setItems(items.filter((item) => item.referenceId !== service.referenceId))}
                // />
                <div className='counter'>
                    <Button
                        type="primary"
                        shape="circle"
                        icon="plus"
                        size='small'
                        onClick={() => setItems(prev => ({ ...prev, [service.id]: { ...service, qty: (prev[service.id].qty || 0) + 1 } }))}
                    />
                    <Text>{service.qty}</Text>
                    <Button
                        type="primary"
                        shape="circle"
                        icon="minus"
                        size='small'
                        onClick={() => setItems(prev => ({ ...prev, [service.id]: { ...service, qty: prev[service.id].qty - 1 } }))}
                    />
                </div>
            ),
        },
    ];

    const getClassList = () => classList({
        'ServiceOrderingItemsCartDrawer-Component': true,
        [className]: true
    });

    return (
        <Drawer className={getClassList()}
            title="選取清單"
            placement="right"
            closable={true}
            onClose={onClose}
            visible={visible}
            width="48vw"
        >
            <Form onSubmit={onSubmit}>
                <Table
                    rowKey="referenceId"
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    scroll={{x:"max-content"}}
                />
                <div className='form-btn-container'>
                    <Button className='btn'
                        type='primary'
                        htmlType='submit'
                        disabled={dataSource.length === 0}
                    >
                        提交
                    </Button>
                </div>
            </Form>
        </Drawer>
    );
}

export default ServiceOrderingItemsCartDrawer;

ServiceOrderingItemsCartDrawer.propTypes = {
    className: PropTypes.string,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    items: PropTypes.object.isRequired,
    setItems: PropTypes.func.isRequired
};

ServiceOrderingItemsCartDrawer.defalutProps = {
    className: '',
    items: [],
};
