import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import { Icon, Input, Button } from "antd";

// Style
import "./searchBar.scss";

// Utils
import { classList } from "@utils/common";

function SearchBarComponent(props) {
  const {
    className,
    extraBtn,
    hasAdvSearch,
    isAdvSearchShow,
    value,
    onSearch,
    onAdvSearchClick,
    placeholder,
    onSearchInputChange,
    showSearchBar,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(undefined);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    onSearchInputChange(inputValue);
  }, [inputValue, onSearchInputChange]);

  useEffect(() => {
    setIsOpen(isAdvSearchShow);
  }, [isAdvSearchShow]);

  const getClassList = () =>
    classList({
      "SearchBar-Component": true,
      [className]: true,
    });

  const onClick = () => {
    const state = !isOpen;
    setIsOpen(state);
    onAdvSearchClick(state);
  };

  return (
    <div className={getClassList()}>
      {showSearchBar && (
        <>
          <Input.Search
            value={inputValue}
            placeholder={placeholder}
            onSearch={onSearch}
            onPressEnter={(e) => onSearch(e.target.value)}
            onChange={(evt) => setInputValue(evt.target.value)}
          />
          {hasAdvSearch && (
            <Button className="btn" type={"primary"} onClick={onClick}>
              <Icon type="filter" theme="filled" />
              {isOpen ? "取消篩選" : "篩選"}
            </Button>
          )}
        </>
      )}
      {extraBtn}
    </div>
  );
}

export default SearchBarComponent;

SearchBarComponent.propTypes = {
  className: PropTypes.string,
  extraBtn: PropTypes.node,
  hasAdvSearch: PropTypes.bool,
  isAdvSearchShow: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  onAdvSearchClick: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onSearchInputChange: PropTypes.func,
  showSearchBar: PropTypes.bool,
};

SearchBarComponent.defaultProps = {
  className: "",
  extraBtn: null,
  hasAdvSearch: false,
  isAdvSearchShow: false,
  onAdvSearchClick: () => {},
  value: undefined,
  placeholder: "搜尋",
  showSearchBar: true,
};
