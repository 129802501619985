import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popconfirm,
  Select,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useMemo } from "react";

import PropTypes from "prop-types";

import "./invoiceDetail.scss";
import { gql, useMutation } from "@apollo/client";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { getAmount } from "../../../utils/common";

const cleaningJobInvoiceIssue = gql`
  mutation cleaningJobInvoiceIssue($cleaningJobId: String) {
    cleaningJobInvoiceIssue(cleaningJobId: $cleaningJobId)
  }
`;

const cleaningJobInvoiceUpdate = gql`
  mutation cleaningJobInvoiceUpdate($input: CleaningJobInvoiceUpdateInput) {
    cleaningJobInvoiceUpdate(input: $input) {
      id
    }
  }
`;

const InvoiceDetail = ({
  cleaningJob,
  refetch,
  isLoading: cleaningJobIsLoading,
}) => {
  const isDiscountedAmount =
    cleaningJob?.serviceBuyer?.billingType === "discount";

  const isIssuedInvoice = cleaningJob?.cleaningJobInvoice?.isIssued

  const [
    updateCleaningJobInvoiceIssue,
    { loading: updateCleaningJobInvoiceIssueLoading },
  ] = useMutation(cleaningJobInvoiceIssue, {
    onCompleted: (data) => {
      notification.success({
        message: "成功更改已發出收據及付款狀態",
        duration: 5,
      });
      refetch();
    },
  });

  const [updateCleaningJobInvoice] = useMutation(cleaningJobInvoiceUpdate, {
    onCompleted: (data) => {
      notification.success({
        message: "成功更新收費資料",
        duration: 5,
      });
      refetch();
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        await updateCleaningJobInvoice({
          variables: {
            input: {
              cleaningJobId: cleaningJob?.id,
              equipmentInvoiceFeeAndCharge: (data?.feeAndCharge || []).map(
                (item) => ({
                  name: item.name,
                  amount:
                    item.type === "discount" ? item.amount * -1 : item.amount,
                })
              ),
              cleaningJobEquipmentInvoiceOtherItem: Object.entries(
                data?.otherItem || {}
              ).map(([id, { amount }]) => ({ equipmentId: id, amount })),
            },
          },
        });

        refetch();
      } catch (err) {
        console.error(err);
      }
    },
    [cleaningJob, refetch, updateCleaningJobInvoice]
  );

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    watch,
    errors,
  } = useForm({
    defaultValues: {
      feeAndCharge: [],
      otherItem: {},
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "feeAndCharge",
    control,
  });

  const columns = [
    {
      title: "收費種類",
      render: (_, record) => {
        // other item
        if (record?.equipment?.otherRemark)
          return `其他：${record?.equipment?.otherRemark}`;

        // normal item
        if (record?.equipmentCategory) return record.equipmentCategory.name;

        // fee and charge
        if ("index" in record)
          return (
            <Form.Item
              className="table-form-item-label"
              validateStatus={
                errors?.feeAndCharge?.[record.index]?.name ? "error" : ""
              }
              help={
                !!errors?.feeAndCharge?.[record.index]?.name && "請填寫收費種類"
              }
            >
              <Controller
                control={control}
                name={`feeAndCharge[${record.index}].name`}
                defaultValue={record.name}
                rules={{ required: true }}
                render={(fields) => <Input {...fields} disabled={isIssuedInvoice} />}
              />
            </Form.Item>
          );
      },
    },
    {
      title: "未收費數量（未能清洗）",
      render: (_, record) => {
        if ("cleaningFailureCount" in record)
          return record.cleaningFailureCount;
        return "-";
      },
    },
    {
      title: "收費數量（已完成／待清洗）",
      render: (_, record) => {
        if ("completedCount" in record || "pendingCount" in record)
          return `${record.completedCount ?? 0}/${record.pendingCount ?? 0}`;

        return 1;
      },
    },
    {
      title: `單價${isDiscountedAmount ? "（優惠價）" : ""}`,
      render: (_, record) => {
        // other item
        if (record?.equipment?.otherRemark && record?.equipment?.id) {
          return (
            <Form.Item
              className="table-form-item-label"
              validateStatus={
                errors?.otherItem?.[record.equipment?.id]?.amount ? "error" : ""
              }
              help={
                !!errors?.otherItem?.[record.equipment?.id]?.amount &&
                "請填寫單價"
              }
            >
              <Controller
                control={control}
                name={`otherItem.${record.equipment?.id}.amount`}
                defaultValue={record.amount}
                rules={{ required: true }}
                render={(fields) => (
                  <InputNumber
                    {...fields}
                    value={fields.value / 100}
                    onChange={(value) =>
                      fields.onChange(Math.round(value * 100))
                    }
                    min={0}
                    disabled={isIssuedInvoice}
                  />
                )}
              />
            </Form.Item>
          );
        }

        // fee and charge
        if ("index" in record) {
          return (
            <>
              <Form.Item
                className="table-form-item-label"
                validateStatus={
                  errors?.feeAndCharge?.[record.index]?.amount ? "error" : ""
                }
                help={
                  !!errors?.feeAndCharge?.[record.index]?.amount && "請填寫單價"
                }
              >
                <Controller
                  control={control}
                  name={`feeAndCharge.${record.index}.amount`}
                  defaultValue={record.amount}
                  rules={{ required: true }}
                  render={(fields) => (
                    <InputNumber
                      {...fields}
                      value={fields.value / 100}
                      onChange={(value) =>
                        fields.onChange(Math.round(value * 100))
                      }
                      min={0}
                      disabled={isIssuedInvoice}
                    />
                  )}
                />
              </Form.Item>
              <Controller
                control={control}
                name={`feeAndCharge.${record.index}.type`}
                defaultValue={record.type}
              />
            </>
          );
        }

        // normal item
        return getAmount(
          isDiscountedAmount
            ? record?.equipmentCategory?.discountedAmount
            : record?.equipmentCategory?.regularAmount
        );
      },
    },
    {
      title: "合共（$）",
      render: (_, record) => {
        // other item
        if (record?.equipment?.otherRemark && record?.equipment?.id) {
          const amount = watch(`otherItem.${record?.equipment?.id}.amount`);
          if (!amount || isNaN(amount)) return 0;
          return getAmount(amount);
        }

        // fee and charge
        if ("index" in record) {
          const amount = watch(`feeAndCharge.${record.index}.amount`);
          if (!amount || isNaN(amount)) return 0;
          if (record.type === "charge") return getAmount(amount);
          return `(${getAmount(Math.abs(amount))})`;
        }

        // normal item
        return getAmount(record?.amount);
      },
    },
    {
      render: (_, record) => {
        if ("index" in record)
          return (
            <Popconfirm
              title="確定刪除此項目？"
              okType="danger"
              onConfirm={() => remove(record.index)}
              disabled={isIssuedInvoice}
            >
              <Button type="danger" disabled={isIssuedInvoice}>刪除</Button>
            </Popconfirm>
          );
      },
    },
  ];

  const onAddFeeAndCharge = useCallback(
    (type) => {
      append({
        name: "",
        amount: 0,
        type,
      });
    },
    [append]
  );

  const otherItem = useWatch({
    control,
    name: "otherItem",
    defaultValue: {},
  });

  const feeAndCharge = useWatch({
    control,
    name: "feeAndCharge",
    defaultValue: [],
  });

  const totalAmount = useMemo(() => {
    let normalItemAmount = 0;
    let otherItemAmount = 0;
    let feeAndChargeAmount = 0;
    for (const item of cleaningJob?.cleaningJobInvoice?.item || []) {
      normalItemAmount += item.amount;
    }
    Object.entries(otherItem || {}).forEach(([id, { amount }]) => {
      otherItemAmount += amount;
    });

    feeAndCharge.forEach(({ amount, type }) => {
      type === "discount"
        ? (feeAndChargeAmount -= amount)
        : (feeAndChargeAmount += amount);
    });

    return getAmount(normalItemAmount + otherItemAmount + feeAndChargeAmount);
  }, [cleaningJob, feeAndCharge, otherItem]);

  useEffect(() => {
    if (cleaningJob && !cleaningJobIsLoading) {
      reset({
        feeAndCharge: cleaningJob?.cleaningJobInvoice?.feeAndCharge.map(
          (feeAndCharge) => ({
            name: feeAndCharge.name,
            amount: Math.abs(feeAndCharge.amount),
            type: feeAndCharge?.amount >= 0 ? "charge" : "discount",
          })
        ),
      });
    }
  }, [cleaningJob, cleaningJobIsLoading, reset]);

  const dataSource = cleaningJob
    ? [
        ...cleaningJob?.cleaningJobInvoice?.item,
        ...cleaningJob?.cleaningJobInvoice?.otherItem,
        ...fields.map((field, index) => ({ ...field, index })),
      ]
    : [];

  return (
    <div className="invoice-detail">
      <div className="button-container">
        <div className="invoice-action-container">
          <Select
            value={undefined}
            placeholder="新增項目"
            style={{ width: 140 }}
            onSelect={onAddFeeAndCharge}
            disabled={isIssuedInvoice}
          >
            <Select.Option value="charge">新增收費項目</Select.Option>
            <Select.Option value="discount">新增折扣項目</Select.Option>
          </Select>
          <Checkbox checked={isIssuedInvoice}>
            已發出收據及付款
          </Checkbox>
          {isIssuedInvoice === false && (
            <Button
              loading={updateCleaningJobInvoiceIssueLoading}
              onClick={() =>
                Modal.confirm({
                  title: "更改狀態",
                  content: (
                    <>
                      <div>確定更改已發出收據及付款狀態？</div>
                      <div>所有已新增的收費項目將不能被更改。</div>
                    </>
                  ),
                  onOk: () =>
                    updateCleaningJobInvoiceIssue({
                      variables: {
                        cleaningJobId: cleaningJob.id,
                      },
                    }),
                })
              }
            >
              更改狀態
            </Button>
          )}
        </div>
        <Button
          loading={isSubmitting}
          type="primary"
          onClick={handleSubmit(onSubmit)}
        >
          儲存
        </Button>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        loading={cleaningJobIsLoading}
        pagination={false}
        footer={() => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>收費合共：($)</Typography>
            <Typography>{totalAmount >= 0 ? totalAmount : 0}</Typography>
          </div>
        )}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

InvoiceDetail.propTypes = {
  cleaningJob: PropTypes.object,
  refetch: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default InvoiceDetail;
