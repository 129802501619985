/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import queryString from "query-string";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { gql } from "@apollo/client";

// Components
import DeliveryEditDrawer from "@components/deliveryEditDrawer/deliveryEditDrawer";
import DeliveryFilter from "@components/filter/deliveryFilter/deliveryFilter";

// Constants
import { PAGE, PAGE_SIZE } from "@utils/constants";

// Layouts
import TableView from "@layouts/tableView/tableView";

// Styles
import "./deliveryList.scss";

// Utils
import {
  Button,
  Col,
  Dropdown,
  Icon,
  List,
  Menu,
  Modal,
  message,
  Radio,
  Row,
  Tag,
  Typography,
  Upload,
  notification,
} from "antd";
import { getTokenHeader, replaceUrlByParams } from "@utils/api";
import { _uuid } from "@utils/common";
import { getFileUploadUrl } from "@utils/env";
import { isEmpty } from "@utils/validate";

const API = gql`
  query EnumList {
    enumDeliveryStatusList {
      id
      name
    }
  }
`;

const DELIVERY_LIST = gql`
  query DeliveryList(
    $size: Int!
    $offset: Int!
    $userId: [ID]
    $keyword: String
    $selfPick: Boolean
    $zone: [Zone]
    $type: [DeliveryType]
    $deliveryStartDate: LocalDate
    $deliveryEndDate: LocalDate
    $deliveryStatus: [DeliveryStatus]
    $paymentStatus: [PaymentStatus]
    $sort: SortDeliveryList
    $reverseOrder: Boolean
  ) {
    deliveryList(
      size: $size
      offset: $offset
      userId: $userId
      keyword: $keyword
      selfPick: $selfPick
      zone: $zone
      type: $type
      deliveryStartDate: $deliveryStartDate
      deliveryEndDate: $deliveryEndDate
      deliveryStatus: $deliveryStatus
      paymentStatus: $paymentStatus
      sort: $sort
      reverseOrder: $reverseOrder
    ) {
      id
      referenceId
      type {
        id
        name
      }
      user {
        nameChi
        userId
        referralInfo {
          id
        }
        deleted
      }
      zone {
        id
        name
      }
      paymentStatus {
        id
        name
      }
      deliveryStatus {
        id
        name
      }
      deliveryDate
      createDate
      selfPick
      confirmation {
        key
        url
        name
        size
      }
    }
  }
`;

const DELIVERY_UPDATE = gql`
  mutation DeliveryUpdate($input: DeliveryUpdateInput) {
    deliveryUpdate(input: $input) {
      id
    }
  }
`;

const DELIVERY_BULK_UPDATE = gql`
  mutation DeliveryBulkUpdate($input: [DeliveryUpdateInput]) {
    deliveryBulkUpdate(input: $input) {
      id
    }
  }
`;

const DELIVERY_NOTE_GET = gql`
  query DeliveryNoteGet($id: ID) {
    deliveryNoteGet(id: $id) {
      url
    }
  }
`;

const DELIVERY_COUNT =gql`
  query DeliveryCount(
    $userId: [ID]
    $keyword: String
    $selfPick: Boolean
    $zone: [Zone]
    $type: [DeliveryType]
    $deliveryStartDate: LocalDate
    $deliveryEndDate: LocalDate
    $deliveryStatus: [DeliveryStatus]
    $paymentStatus: [PaymentStatus]
  ) {
    deliveryCount (
      userId: $userId
      keyword: $keyword
      selfPick: $selfPick
      zone: $zone
      type: $type
      deliveryStartDate: $deliveryStartDate
      deliveryEndDate: $deliveryEndDate
      deliveryStatus: $deliveryStatus
      paymentStatus: $paymentStatus
    )
  }
`;

function DeliveryList(props) {
  const {
    location,
    breadcrumb,
    availableDeliveryStatus,
    extraChildren,
  } = props;
  const cacheSessionUUID = useRef(_uuid()).current;

  const history = useHistory();

  const { Text } = Typography;

  const columns = [
    {
      title: "工作編號",
      dataIndex: "referenceId",
      render: (text, delivery, index) => {
        return (
          <Button
            type="link"
            onClick={() =>
              setUpdateDeliveryParams({
                deliveryId: delivery.id,
                userId: delivery.user.nameChi,
                visible: true,
                mode: "edit",
                // workMode: delivery?.type?.name,
                onClose: () => setUpdateDeliveryParams({ visible: false }),
              })
            }
            style={{ paddingLeft: "0px" }}
          >
            <span style={{ textDecoration: "underline" }}>{text}</span>
          </Button>
        );
      },
    },
    {
      title: "用戶名稱",
      dataIndex: "user",
      render: (user) => <div className="text">{user.nameChi}</div>,
    },
    {
      title: "分區",
      dataIndex: "zone",
      render: (zone, delivery) => (
        <div className="text">
          {delivery?.selfPick ? "自提" : !isEmpty(zone.name) ? zone.name : "-"}
        </div>
      ),
    },
    {
      title: "訂單最新繳費狀態",
      dataIndex: "paymentStatus",
      render: (paymentStatus) =>
        paymentStatus ? (
          {
            pending: <Tag className="tag warning">{paymentStatus.name}</Tag>,
            paid: <Tag className="tag success">{paymentStatus.name}</Tag>,
          }[paymentStatus.id]
        ) : (
          <span>-</span>
        ),
    },
    {
      title: "工作類型",
      dataIndex: "type",
      render: (type) => (
        <Tag
          className={`${(type.id === "delivery" || type.id === "reclaim") &&
            "deliveryOrReclaim"}`}
          style={{
            paddingTop: "2px",
            paddingBottom: "2px",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          {type.name}
        </Tag>
      ),
    },
    {
      title: "工作狀態",
      dataIndex: "deliveryStatus",
      render: (deliveryStatus) => renderDeliveryStatus(deliveryStatus),
    },
    {
      title: "預計日期",
      dataIndex: "deliveryDate",
      sorter: true,
      render: (deliveryDate) => <div className="text">{deliveryDate}</div>,
    },
    {
      title: "新增日期",
      dataIndex: "createDate",
      render: (createDate) => <div className="text">{createDate}</div>,
    },
    {
      dataIndex: "action",
      render: (text, delivery) => (
        <Dropdown overlay={() => menu(delivery)}>
          <Button className="btn btn-secondary">
            <Icon type="ellipsis" />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const [
    updateDelivery,
    {
      loading: updateDeliveryLoading,
      error: updateDeliveryError,
      data: updateDeliveryData,
    },
  ] = useMutation(DELIVERY_UPDATE);

  const [
    bulkUpdateDelivery,
    {
      loading: bulkUpdateDeliveryLoading,
      error: bulkUpdateDeliveryError,
      data: bulkUpdateDeliveryData,
    },
  ] = useMutation(DELIVERY_BULK_UPDATE);

  const [
    getDeliveryList,
    { loading: getListLoading, error: getListError, data: getListData },
  ] = useLazyQuery(DELIVERY_LIST, {
    context: {
      headers: {
        "x-cache-session": cacheSessionUUID,
      },
    },
  });

  const [
    getDeliveryNote,
    { called: getDeliveryNoteCalled, data: getDeliveryNoteData },
  ] = useLazyQuery(DELIVERY_NOTE_GET);

  const [
    getDeliveryCount, {
      loading: getDeliveryCountLoading,
      data: getDeliveryCountData
    },
  ] = useLazyQuery(DELIVERY_COUNT);

  const { data: enumList } = useQuery(API);

  const [query, setQuery] = useState(queryString.parse(location.search));
  const queryRef = useRef(query);
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [isShowUploadModal, setIsShowUploadModal] = useState(false);
  const [isShowBatchModal, setIsShowBatchModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileUploadList, setFileUploadList] = useState([]);
  const [selectedDelivery, setSelectedDelivery] = useState(undefined);
  const [uploadConfirmationFiles, setUploadConfirmationFiles] = useState(
    undefined
  );
  const [isBatchEdit, setIsBatchEdit] = useState(false);
  const [isBatchUpdating, setIsBatchUpdating] = useState(false);
  const [batchSelectedRows, setBatchSelectedRows] = useState([]);
  const [batchRadioOption, setBatchRadioOption] = useState();

  const deliveryList = useMemo(() => {
    return (
      getListData?.deliveryList?.filter(
        (delivery) => delivery?.user?.deleted !== true
      ) || []
    ).slice(0, PAGE_SIZE);
  }, [getListData]);

  const isMaxPage = useMemo(() => {
    return getListData?.deliveryList?.length <= PAGE_SIZE;
  }, [getListData]);

  const [updateDeliveryParams, setUpdateDeliveryParams] = useState({
    visible: false,
  });

  useEffect(() => {
    setQuery(queryString.parse(location.search));
  }, [location.search]);

  useEffect(() => {
    refreshList();
    // eslint-disable-next-line
  }, [location.search]);

  const renderDeliveryStatus = (deliveryStatus) => {
    switch (deliveryStatus.id) {
      case "cancelled":
        return <Tag className="tag error">{deliveryStatus.name}</Tag>;
      case "completed":
      case "confirmedDelivery":
        return <Tag className="tag success">{deliveryStatus.name}</Tag>;
      default:
        return <Tag className="tag warning">{deliveryStatus.name}</Tag>;
    }
  };

  const refreshList = () => {
    const {
      page,
      pageSize,
      keyword,
      paymentStatus,
      deliveryStatus,
      zone,
      deliveryStartDate,
      deliveryEndDate,
      selfPick,
      sortField,
      sortOrder,
      type,
      userId,
    } = queryString.parse(location.search);

    let nDeliveryStatus = [];
    if (!deliveryStatus) {
      nDeliveryStatus = [...availableDeliveryStatus];
    } else {
      nDeliveryStatus = deliveryStatus;
    }

    getDeliveryList({
      variables: {
        offset: +((page - 1) * pageSize) || 0,
        size: +(pageSize || PAGE_SIZE) + 1,
        keyword: keyword,
        paymentStatus: paymentStatus,
        deliveryStatus: nDeliveryStatus,
        zone: zone,
        deliveryStartDate: deliveryStartDate,
        deliveryEndDate: deliveryEndDate,
        selfPick: selfPick ? selfPick === "true" : undefined,
        sort: sortField,
        type: type,
        reverseOrder: sortOrder ? sortOrder === "descend" : undefined,
        userId: userId,
      },
    });

    getDeliveryCount({
      variables: {
        keyword: keyword,
        paymentStatus: paymentStatus,
        deliveryStatus: nDeliveryStatus,
        zone: zone,
        deliveryStartDate: deliveryStartDate,
        deliveryEndDate: deliveryEndDate,
        selfPick: selfPick ? selfPick === "true" : undefined,
        type: type,
        userId: userId,
      },
    });
  };

  const onFilterSearch = () => {
    history.push({
      search: queryString.stringify(query),
    });
  };

  const onFilterClick = (state) => {
    setIsFilterShow(state);
  };

  const onEditSuccess = (value) => {
    if (value) {
      setUpdateDeliveryParams({
        visible: false,
      });
      refreshList();
    }
  };

  const downloadDeliveryNote = (delivery) => {
    getDeliveryNote({ variables: { id: delivery.id } });
  };

  useEffect(() => {
    // console.log(getDeliveryNoteData)
    if (getDeliveryNoteData?.deliveryNoteGet.url) {
      window.open(getDeliveryNoteData?.deliveryNoteGet.url);
    } else if (getDeliveryNoteData !== undefined) {
      message.warn("未有提貨單");
    }
  }, [getDeliveryNoteData]);

  useEffect(() => {
    if (queryRef.current.defaultDeliveryDetail)
      setUpdateDeliveryParams({
        deliveryId: queryRef.current.defaultDeliveryDetail,
        visible: true,
        mode: "edit",
        onClose: () => setUpdateDeliveryParams({ visible: false }),
      });
  }, []);

  const onMenuItemClick = ({ key, delivery }) => {
    switch (key) {
      case "edit": {
        setUpdateDeliveryParams({
          deliveryId: delivery.id,
          visible: true,
          mode: "edit",
          // workMode: delivery?.type?.name,
          onClose: () => setUpdateDeliveryParams({ visible: false }),
        });
        break;
      }
      case "view_confirmation": {
        setIsShowUploadModal(true);
        setSelectedDelivery(delivery);
        setUploadConfirmationFiles(delivery.confirmation);
        break;
      }
      case "download_bill": {
        downloadDeliveryNote(delivery);
        break;
      }
      case "view_order": {
        history.push(
          replaceUrlByParams(PAGE.ORDERING_DETAIL, {
            id: delivery.user.userId,
          })
        );
        break;
      }
      default: {
        break;
      }
    }
  };

  const menu = (delivery) => (
    <Menu
      onClick={({ key }) => {
        onMenuItemClick({ key, delivery: delivery });
      }}
    >
      <Menu.Item key="edit">編輯</Menu.Item>
      <Menu.Item key="view_confirmation">查看收貨確認書</Menu.Item>
      <Menu.Item key="download_bill">下載提貨單</Menu.Item>
      <Menu.Item key="view_order">查看訂單</Menu.Item>
    </Menu>
  );

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `onChange selectedRowKeys: ${selectedRowKeys}`,
        "onChange selectedRows: ",
        selectedRows
      );
      setBatchSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(
        `onSelect record: ${record}`,
        `onSelect record: ${selected}`,
        `onSelect record: ${selectedRows}`
      );
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(
        `onSelectAll record: ${selected}`,
        `onSelectAll record: ${selectedRows}`,
        `onSelectAll record: ${changeRows}`
      );
    },
  };

  const resetUploadParams = () => {
    setIsUploading(false);
    setIsShowUploadModal(false);
    setFileUploadList([]);
    setSelectedDelivery(undefined);
  };

  const handleUploadModalOk = useCallback(async () => {
    setIsUploading(true);
    try {
      const originalFileSize = selectedDelivery?.confirmation?.length ?? 0;
      const newFileSize = uploadConfirmationFiles?.length ?? 0;
      if (fileUploadList.length > 0 || originalFileSize !== newFileSize) {
        const confirmation = [];
        fileUploadList.forEach((file) => {
          confirmation.push({
            key: file.key,
            name: file.name,
            size: file.size,
          });
        });
        if (uploadConfirmationFiles) {
          uploadConfirmationFiles.forEach((file) => {
            confirmation.push({
              key: file.key,
              name: file.name,
              size: file.size,
            });
          });
        }
        const input = {
          id: selectedDelivery?.id,
          confirmation: confirmation,
        };
        await updateDelivery({
          variables: {
            input: input,
          },
        });
        message.success("成功上載收貨確認書");
        resetUploadParams();
        refreshList();
      } else {
        resetUploadParams();
      }
    } catch (error) {
      message.error(error.message);
      resetUploadParams();
    }
    // eslint-disable-next-line
  }, [
    fileUploadList,
    updateDelivery,
    selectedDelivery,
    uploadConfirmationFiles,
  ]);

  const handleUploadModalCancel = () => {
    setIsShowUploadModal(false);
    setFileUploadList([]);
    setSelectedDelivery(undefined);
  };

  const checkFileType = (file) => {
    if (file.type.includes("image/") || file.type.includes("application/pdf"))
      return true;
    return false;
  };

  const handleUploadChange = (info) => {
    if (checkFileType(info.file)) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-10);
      fileList = fileList.map((file) => {
        if (file.response?.data) {
          file.key = file.response.data?.key;
          file.url = file.response.data?.url;
        }
        return file;
      });
      setFileUploadList(fileList);
    } else {
      notification.error({
        message: `不支援 ${info.file.name} 的檔案類型, 請上載圖片或PDF`,
      });
    }
  };

  const handleBeforeUpload = (file) => {
    return checkFileType(file);
  };

  const resetBatchParams = () => {
    setIsShowBatchModal(false);
    setIsBatchUpdating(false);
    setBatchRadioOption(undefined);
  };

  const handleBatchModalOk = useCallback(async () => {
    setIsBatchUpdating(true);
    try {
      if (batchSelectedRows.length > 0) {
        const input = [];
        batchSelectedRows.forEach((delivery) => {
          input.push({
            id: delivery.id,
            deliveryStatus: batchRadioOption,
          });
        });
        await bulkUpdateDelivery({
          variables: {
            input: input,
          },
        });
        if (bulkUpdateDeliveryData) {
          message.success("成功修改");
        }
        resetBatchParams();
        setBatchSelectedRows([]);
        setIsBatchEdit(!isBatchEdit);
        refreshList();
      } else {
        resetBatchParams();
      }
    } catch (error) {
      message.error(error.message);
      resetBatchParams();
    }
    // eslint-disable-next-line
  }, [batchSelectedRows, batchRadioOption, bulkUpdateDelivery]);

  const handleBatchModalCancel = () => {
    resetBatchParams();
  };

  return (
    <div className="DeliveryList">
      <TableView
        {...props}
        addBtnConfig={{
          onClick: () => setIsBatchEdit((prev) => !prev),
          text: "批選修改工作狀態",
        }}
        breadcrumb={breadcrumb}
        rowKey="id"
        columns={columns}
        hasFilter
        hasAddBtn
        isMaxPage={isMaxPage}
        isFilterShow={isFilterShow}
        isTableLoading={getListLoading}
        onFilterClick={onFilterClick}
        searchBarPlaceholder="搜尋工作編號"
        tableData={deliveryList}
        rowSelection={isBatchEdit ? rowSelection : undefined}
        extraChildren={extraChildren}
        total={getDeliveryCountData?.deliveryCount}
      >
        <DeliveryFilter
          filterType={[""]}
          filterValue={query}
          onFilterSearch={onFilterSearch}
          onResetFilter={() => {
            history.push({
              search: queryString.stringify({ page: 1, pageSize: PAGE_SIZE }),
            });
          }}
          isFilterShow={isFilterShow}
          onFilterValueChange={setQuery}
          availableDeliveryStatus={availableDeliveryStatus}
        />
      </TableView>
      {updateDeliveryParams.visible && (
        <DeliveryEditDrawer
          {...updateDeliveryParams}
          onEditSuccess={onEditSuccess}
        />
      )}
      {isShowUploadModal && (
        <Modal
          title="查看收貨確認書"
          visible={isShowUploadModal}
          onOk={handleUploadModalOk}
          onCancel={handleUploadModalCancel}
          footer={[
            <Button key="back" onClick={handleUploadModalCancel}>
              返回
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={isUploading}
              onClick={handleUploadModalOk}
            >
              提交
            </Button>,
          ]}
        >
          <div>
            {uploadConfirmationFiles && (
              <List
                itemLayout="vertical"
                dataSource={uploadConfirmationFiles}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        key="list-download"
                        type="link"
                        icon="download"
                        onClick={() => window.open(item.url)}
                      />,
                      <Button
                        key="list-delete"
                        type="link"
                        icon="delete"
                        onClick={() => {
                          var list = [...uploadConfirmationFiles];
                          var index = list.findIndex(
                            (file) => file.key === item.key
                          );
                          if (index !== -1) {
                            list.splice(index, 1);
                            setUploadConfirmationFiles(list);
                          }
                        }}
                      />,
                    ]}
                  >
                    {item.name}
                  </List.Item>
                )}
              ></List>
            )}
          </div>
          <Upload
            className="delivery-confirmation-uploader"
            headers={{
              "x-token": getTokenHeader(),
              "X-Requested-With": null,
            }}
            action={getFileUploadUrl()}
            fileList={fileUploadList}
            onChange={handleUploadChange}
            beforeUpload={handleBeforeUpload}
          >
            <Button>上載收貨確認書</Button>
          </Upload>
        </Modal>
      )}
      {isBatchEdit && (
        <div className="selected-layout">
          <Row type="flex" style={{ marginTop: "16px" }}>
            <Col xs={12}>
              <Text className="text">
                已選擇
                <Text style={{ fontSize: "24px", fontWeight: "bold" }}>
                  {" "}
                  {batchSelectedRows?.length}{" "}
                </Text>
                項送貨項目
              </Text>
            </Col>
            <Col xs={12}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    setIsBatchEdit(false);
                    setBatchSelectedRows([]);
                  }}
                  style={{ marginRight: "16px" }}
                >
                  取消
                </Button>
                <Button
                  type="primary"
                  onClick={() => setIsShowBatchModal(true)}
                >
                  確認
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      )}
      {isShowBatchModal && (
        <Modal
          title="調整工作狀態至"
          visible={isShowBatchModal}
          onOk={handleBatchModalOk}
          onCancel={handleBatchModalCancel}
          footer={[
            <Button key="back" onClick={handleBatchModalCancel}>
              取消
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={isBatchUpdating}
              onClick={handleBatchModalOk}
            >
              確定
            </Button>,
          ]}
        >
          <Radio.Group
            onChange={(e) => setBatchRadioOption(e.target.value)}
            value={batchRadioOption}
          >
            {enumList?.enumDeliveryStatusList.map((option) => (
              <Radio
                key={option.id}
                value={option.id}
                style={{ display: "block", height: "30px" }}
              >
                {option.name}
              </Radio>
            ))}
          </Radio.Group>
        </Modal>
      )}
    </div>
  );
}

export default DeliveryList;

DeliveryList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  availableDeliveryStatus: PropTypes.array,
  extraChildren: PropTypes.node,
};
