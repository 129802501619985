/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";

// Components
import { Drawer, Table, Button } from "antd";
import CustPaginationComponent from "@components/custPagination/custPagination";
import Filter from "../filter/filter";

// Constants
import { PAGE_SIZE } from "@utils/constants";

// Utils

import { useMemoRef } from "@utils/hook";

const DEVICES_GET = gql`
  query deviceBulkGet($id: [ID]) {
    deviceBulkGet(id: $id) {
      id
      referenceId
      productInfo {
        nameChi
      }
      quantity
      category {
        id
        name
        mainCategory {
          name
        }
        minInventory
      }
    }
  }
`;

const DEVICE_LIST = gql`
  query deviceList(
    $size: Int!
    $offset: Int!
    $keyword: String
    $categoryId: [String]
  ) {
    deviceList(
      size: $size
      offset: $offset
      keyword: $keyword
      categoryId: $categoryId
    ) {
      id
      referenceId
      productInfo {
        nameChi
      }
      quantity
      washingQuantity
      leasableQuantity
      category {
        id
        name
        mainCategory {
          name
        }
        minInventory
      }
    }
  }
`;

const PART_LIST = gql`
  query partList(
    $size: Int!
    $offset: Int!
    $keyword: String
    $categoryId: [String]
    $dimension: [String]
    $characteristicTag: [String]
  ) {
    partList(
      size: $size
      offset: $offset
      keyword: $keyword
      categoryId: $categoryId
      dimension: $dimension
      characteristicTag: $characteristicTag
    ) {
      id
      referenceId
      productInfo {
        nameChi
      }
      quantity
      washingQuantity
      leasableQuantity
      category {
        id
        name
        mainCategory {
          id
          name
        }
        minInventory
      }
    }
  }
`;

const CONSUMABLE_LIST = gql`
  query consumableList(
    $size: Int!
    $offset: Int!
    $keyword: String
    $categoryId: [String]
    $dimension: [String]
    $characteristicTag: [String]
  ) {
    consumableList(
      size: $size
      offset: $offset
      keyword: $keyword
      categoryId: $categoryId
      dimension: $dimension
      characteristicTag: $characteristicTag
    ) {
      id
      referenceId
      productInfo {
        nameChi
      }
      quantity
      category {
        id
        name
        mainCategory {
          id
          name
        }
        minInventory
      }
    }
  }
`;

const Picker = ({
  visible,
  visibleColumns,
  onClose,
  pickerType,
  value,
  onChange,
  multiple,
  validate,
  metaComponent,
}) => {
  const [selected, setSelected] = useState([]);

  const [query, setQuery] = useState({
    page: 1,
    pageSize: PAGE_SIZE,
    keyword: "",
    categoryId: undefined,
    brand: [],
    dimension: [],
    characteristicTag: [],
  });

  const queryToUse = useMemo(() => {
    switch (pickerType) {
      case "device":
        return DEVICE_LIST;
      case "part":
        return PART_LIST;
      case "consumable":
        return CONSUMABLE_LIST;
      default:
        return DEVICE_LIST;
    }
  }, [pickerType]);

  const [
    getProductList,
    {
      loading: productListLoading,
      error: productListError,
      data: productListData,
    },
  ] = useLazyQuery(queryToUse);

  const [
    getSelectedProducts,
    {
      loading: selectedProductsLoading,
      error: selectedProductsError,
      data: selectedProductsData,
    },
  ] = useLazyQuery(DEVICES_GET);

  useEffect(() => {
    const ids = [];
    if (ids.length > 0) {
      getSelectedProducts({
        variables: {
          id: ids,
        },
      });
    }
  }, [getSelectedProducts, multiple, value, selected]);

  const queryRef = useMemoRef(query);

  console.log("selected", selected);

  // fetch PAGE_SIZE + 1, to check if there is next page.
  const products = useMemo(() => {
    switch (pickerType) {
      case "device":
        return productListData?.deviceList || [];
      case "part":
        return productListData?.partList || [];
      case "consumable":
        return productListData?.consumableList || [];
      default:
        return [];
    }
  }, [pickerType, productListData]);

  const displayProducts = useMemo(() => {
    return products.slice(0, query.pageSize);
  }, [products, query]);

  const isMaxPage = useMemo(() => {
    return query.pageSize >= products.length;
  }, [products, query.pageSize]);

  const onSearch = useCallback(() => {
    const {
      page,
      pageSize,
      keyword,
      sortField,
      categoryId,
      brand,
      dimension,
      characteristicTag,
      sortOrder,
    } = query;

    const queryParamToArray = (x) => (x ? (!Array.isArray(x) ? [x] : x) : []);

    getProductList({
      variables: {
        offset: +((page - 1) * pageSize) || 0,
        size: +(pageSize || PAGE_SIZE) + 1,
        keyword: keyword,
        sort: sortField,
        categoryId: categoryId,
        brand: queryParamToArray(brand),
        dimension: queryParamToArray(dimension),
        characteristicTag: queryParamToArray(characteristicTag),
        reverseOrder: sortOrder === "descend",
      },
    });
  }, [getProductList, query]);

  useEffect(() => {
    onSearch();
  }, [onSearch]);

  const columns = useMemo(() => {
    const result = [];
    const column = {
      referenceId: {
        title: "款號",
        dataIndex: "referenceId",
        sorter: true,
      },
      name: {
        title: "名稱",
        dataIndex: "productInfo.nameChi",
      },
      leasableQuantity: {
        title: "可租用",
        dataIndex: "leasableQuantity",
      },
      washingQuantity: {
        title: "清洗中",
        dataIndex: "washingQuantity",
      },
      quantity: {
        title: "輪候中",
        dataIndex: "quantity",
      },
    };

    for (const item of visibleColumns) {
      if (column[item]) result.push(column[item]);
    }

    return result;
  }, [visibleColumns]);

  return (
    <Drawer visible={visible} className="picker" width="50vw" onClose={onClose}>
      <h1>選擇型號</h1>

      <Filter
        // filterType={pickerTypes}
        filterValue={query}
        onFilterSearch={onSearch}
        onResetFilter={() => {
          setQuery(({ page, pageSize }) => ({ page, pageSize }));
          onSearch();
        }}
        isFilterShow={true}
        onFilterValueChange={setQuery}
      />

      <CustPaginationComponent
        isMinPage={query.page === 1}
        isMaxPage={isMaxPage}
        page={query.page}
        // pageSize={query.pageSize}
        pageSize={PAGE_SIZE}
        onPageChange={(page) => {
          setQuery((query) => ({
            ...query,
            page,
          }));
        }}
      />
      <Table
        style={{ paddingBottom: "40px" }}
        rowKey="id"
        pagination={false}
        rowSelection={{
          type: `${multiple ? "checkbox" : "radio"}`,
          selectedRowKeys: selected.map((x) => x.id),
          onChange: (selectedRowKeys, selected) => {
            setSelected(selected);
          },
          getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
          }),
        }}
        loading={productListLoading}
        columns={columns}
        dataSource={displayProducts}
        scroll={{x:"max-content"}}
      />

      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          width: "100%",
          borderTop: "1px solid #e9e9e9",
          padding: "10px 16px",
          background: "#fff",
          textAlign: "right",
        }}
      >
        <Button
          type="primary"
          disabled={selected.length === 0}
          onClick={() => {
            onChange(selected);
            onClose();
          }}
        >
          選取 {selected.length} 個項目
        </Button>
      </div>
    </Drawer>
  );
};

Picker.propTypes = {
  visible: PropTypes.bool.isRequired,
  visibleColumns: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func.isRequired,
  pickerType: PropTypes.oneOf(["device", "consumable", "part"]),
  value: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.string.isRequired,
  ]),
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
  metaComponent: PropTypes.element,
};

Picker.defaultProps = {
  visible: false,
  visibleColumns: [
    "referenceId",
    "name",
    "leasableQuantity",
    "washingQuantity",
    "quantity",
  ],
  value: undefined,
  multiple: false,
  validate: () => true,
};

export default Picker;
