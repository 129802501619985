import React, {useState} from 'react';

// Components
import {Col, Collapse, notification, Row,} from 'antd';
import AssessmentCheckboxComponent from "@components/assessment/checkbox/checkbox";
import AssessmentFormComponent from "@components/assessment/form/form";
import AssessmentNumberComponent from "@components/assessment/number/number";
import AssessmentSelectComponent from "@components/assessment/select/select";
import AssessmentSectionComponent from "@components/assessment/section/section";
import AssessmentTextareaComponent from "@components/assessment/textarea/textarea";
import AssessmentUploadComponent from "@components/assessment/upload/upload";

// Constants
import {NOTIFICATION_CONFIG, PAGE, PAGE_NAME} from '@utils/constants';

// Layouts
import StickyBreadcrumbPageComponent from '@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage';

// Styles
import './assessment.scss';

import ASSESSMENTS from '../../../assessments';

function DemoAssessmentPage() {
    const BREADCRUMB = [
        {
            path: PAGE.OVERVIEW,
            name: PAGE_NAME.OVERVIEW
        },
        {
            path: '/demo/assessment',
            name: 'Demo Assessment'
        }
    ];

    notification.config(NOTIFICATION_CONFIG);

    const SELECT1 = {
        "id": "s1",
        "inputType": "singleChoice",
        "required": false,
        "label": "extra description of the input (nullable)",
        "option": [
            {"value": "I - 卧床者"},
            {"value": "II - 能座者"},
            {"value": "II - 能座者II - 能座者II - 能座者II - 能座者II - 能座者"}
        ],
        "singleChoiceAnswer": "II - 能座者"
    };
    const SELECT2 = {
        "id": "s2",
        "inputType": "singleChoice",
        "required": false,
        "label": "extra description of the input (nullable)",
        "option": [
            {"value": "I - 卧床者"},
            {"value": "II - 能座者"},
            {"value": "II - 能座者II - 能座者II - 能座者II - 能座者II - 能座者"}
        ]
    };
    const SELECT3 = {
        "id": "s3",
        "inputType": "multipleChoice",
        "required": false,
        "label": "extra description of the input (nullable)",
        "option": [
            {"value": "I - 卧床者"},
            {"value": "II - 能座者"},
            {"value": "II - 能座者II - 能座者II - 能座者II - 能座者II - 能座者"}
        ],
        "multipleChoiceAnswer": [
            "I - 卧床者",
            "II - 能座者",
        ]
    };
    const SELECT4 = {
        "id": "s4",
        "inputType": "multipleChoice",
        "required": false,
        "label": "extra description of the input (nullable)",
        "option": [
            {"value": "I - 卧床者"},
            {"value": "II - 能座者"},
            {"value": "II - 能座者II - 能座者II - 能座者II - 能座者II - 能座者"}
        ]
    };

    const TEXTAREA1 = {
        "id": "t1",
        "inputType": "text",
        "required": false,
        "label": "extra description of the input (nullable)",
        "textAnswer": "textarea1"
    };
    const TEXTAREA2 = {
        "id": "t2",
        "inputType": "text",
        "required": false,
        "label": "extra description of the input (nullable)",
    };

    const NUMBER1 = {
        "id": "N1",
        "inputType": "number",
        "required": false,
        "label": "extra description of the input (nullable)",
        "numberAnswer": 1
    };

    const NUMBER2 = {
        "id": "N2",
        "inputType": "number",
        "required": false,
        "label": "extra description of the input (nullable)",
    };

    const CHECKBOX1 = {
        "id": "cb1",
        "inputType": "checkbox",
        "label": "extra description of the input (nullable)",
        "required": false,
        "checkboxAnswer": true,
    };
    const CHECKBOX2 = {
        "id": "cb2",
        "inputType": "checkbox",
        "label": "extra description of the input (nullable)",
        "required": false,
    };

    const UPLOAD1 = {
        "id": "u1",
        "inputType": "file",
        "label": "extra description of the input (nullable)",
        "required": false,
    };
    const UPLOAD2 = {
        "id": "u2",
        "inputType": "file",
        "label": "extra description of the input (nullable)",
        "required": false,
        "fileAnswer": [
            {
                key: "57a7bfc8-79af-4c41-8189-0306c0a08171",
                url: "https://jcgers.talkbox.io/a/assets/module/file/57a7bfc8-79af-4c41-8189-0306c0a08171"
            },
            {
                key: "ebe2aa6d-49a7-4f14-b21e-b3d78d52ccf7",
                url: "https://jcgers.talkbox.io/a/assets/module/file/ebe2aa6d-49a7-4f14-b21e-b3d78d52ccf7"
            },
        ]
    };

    const SECTION1 = {
        "title": "Section Title",
        "displaySubTotalScore": false,
        "question": [
            {
                'id': 'section1-q1',
                'input': [
                    {
                        "id": "t1",
                        "inputType": "text",
                        "required": false,
                        "label": "extra description of the input (nullable)",
                        "answer": "textarea1"
                    }
                ],
                'title': 'Question 1'
            }
        ]
    };
    const SECTION2 = {
        "title": "Section Title",
        "displaySubTotalScore": true,
        "question": [
            {
                'id': 'section2-q1',
                'title': 'Question 1',
                'input': [
                    {
                        "id": "t1",
                        "inputType": "text",
                        "required": false,
                        "label": "extra description of the input (nullable)",
                        "answer": "textarea1"
                    }
                ]
            }
        ],
        "score": 99,
        "totalScore": 100
    };

    const [singleSelect1, setSingleSelect1] = useState(undefined);
    const [singleSelect2, setSingleSelect2] = useState(undefined);
    const [multipleSelect1, setMultipleSelect1] = useState(undefined);
    const [multipleSelect2, setMultipleSelect2] = useState(undefined);
    const [textarea1, setTextarea1] = useState(undefined);
    const [textarea2, setTextarea2] = useState(undefined);
    const [number1, setNumber1] = useState(undefined);
    const [number2, setNumber2] = useState(undefined);
    const [upload1, setUpload1] = useState([]);
    const [upload2, setUpload2] = useState([]);
    const [checkbox1, setCheckbox1] = useState(undefined);
    const [checkbox2, setCheckbox2] = useState(undefined);

    const json = {
        "id": "ModifiedFunctionalAmbulatoryCategory",
        "title": "功能性行走分類評量表 Modified Functional Ambulatory Category",
        "section": [
            {
                "title": "Section Title",
                "displaySubTotalScore": false,
                "question": [
                    {
                        "id": "",
                        "title": "MFAC: CATEGORY",
                        "input": [
                            {
                                "id": "x1-1",
                                "inputType": "singleChoice",
                                "required": false,
                                "label": "Single Choice",
                                "option": [
                                    {"value": "I - 卧床者"},
                                    {"value": "II - 能座者"}
                                ],
                                "singleChoiceAnswer": "I - 卧床者",
                            },
                            {
                                "id": "x1-2",
                                "inputType": "multipleChoice",
                                "required": false,
                                "label": "Multiple Choice",
                                "option": [
                                    {"value": "I - 卧床者"},
                                    {"value": "II - 能座者"}
                                ],
                                "multipleChoiceAnswer": [
                                    "I - 卧床者",
                                    "II - 能座者"
                                ]
                            },
                            {
                                "id": "x2",
                                "inputType": "text",
                                "required": false,
                                "label": "Text",
                                "textAnswer": "answer"
                            },
                            {
                                "id": "x3",
                                "inputType": "file",
                                "label": "File Upload",
                                "required": false,
                                "fileAnswer": [
                                    {
                                        key: "57a7bfc8-79af-4c41-8189-0306c0a08171",
                                        url: "https://jcgers.talkbox.io/a/assets/module/file/57a7bfc8-79af-4c41-8189-0306c0a08171"
                                    },
                                    {
                                        key: "ebe2aa6d-49a7-4f14-b21e-b3d78d52ccf7",
                                        url: "https://jcgers.talkbox.io/a/assets/module/file/ebe2aa6d-49a7-4f14-b21e-b3d78d52ccf7"
                                    },
                                ]
                            },
                            {
                                "id": "x4",
                                "inputType": "checkbox",
                                "required": false,
                                "label": "Checkbox",
                                "checkboxAnswer": true,
                            },
                            {
                                "id": "x5",
                                "inputType": "number",
                                "required": false,
                                "label": "Number",
                                "numberAnswer": 1
                            }
                        ]
                    }
                ]
            }
        ]
    };


    const onSubmit = (data) => {
        console.log('Demo assessment - onSubmit()', data);

        notification.success({
            message: "Form data logged in console"
        });
    };

    return (
        <StickyBreadcrumbPageComponent className='Demo-assessment-page' breadcrumb={BREADCRUMB}>
            <div className='content-inner'>
                <div>
                    <h2>Components</h2>
                    <Row>
                        <Col xs={12}>
                            <h3>Dropdown - single with answer</h3>
                            <span>Value: {singleSelect1}</span>
                        </Col>
                        <Col xs={12}>
                            <AssessmentSelectComponent
                                {...SELECT1}
                                onChange={setSingleSelect1}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h3>Dropdown - single without answer</h3>
                            <span>Value: {singleSelect2}</span>
                        </Col>
                        <Col xs={12}>
                            <AssessmentSelectComponent
                                {...SELECT2}
                                onChange={setSingleSelect2}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h3>Dropdown - multiple with answer</h3>
                            <span>Value: {multipleSelect1}</span>
                        </Col>
                        <Col xs={12}>
                            <AssessmentSelectComponent
                                {...SELECT3}
                                onChange={setMultipleSelect1}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h3>Dropdown - multiple without answer</h3>
                            <span>Value: {multipleSelect2}</span>
                        </Col>
                        <Col xs={12}>
                            <AssessmentSelectComponent
                                {...SELECT4}
                                onChange={setMultipleSelect2}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <h3>Textarea with answer</h3>
                            <span>Value: {textarea1}</span>
                        </Col>
                        <Col xs={12}>
                            <AssessmentTextareaComponent
                                {...TEXTAREA1}
                                onChange={setTextarea1}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h3>Textarea without answer</h3>
                            <span>Value: {textarea2}</span>
                        </Col>
                        <Col xs={12}>
                            <AssessmentTextareaComponent
                                {...TEXTAREA2}
                                onChange={setTextarea2}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <h3>Number with answer</h3>
                            <span>Value: {number1}</span>
                        </Col>
                        <Col xs={12}>
                            <AssessmentNumberComponent
                                {...NUMBER1}
                                onChange={setNumber1}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h3>Number without answer</h3>
                            <span>Value: {number2}</span>
                        </Col>
                        <Col xs={12}>
                            <AssessmentNumberComponent
                                {...NUMBER2}
                                onChange={setNumber2}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <h3>Checkbox with answer</h3>
                            <span>Value: {String(checkbox1)}</span>
                        </Col>
                        <Col xs={12}>
                            <AssessmentCheckboxComponent
                                {...CHECKBOX1}
                                onChange={setCheckbox1}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h3>Checkbox without answer</h3>
                            <span>Value: {String(checkbox2)}</span>
                        </Col>
                        <Col xs={12}>
                            <AssessmentCheckboxComponent
                                {...CHECKBOX2}
                                onChange={setCheckbox2}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <h3>Upload without answer</h3>
                            <span>Value: </span>
                            <ol>
                                {upload1.map((u, i) => (
                                        <li key={u.key}>
                                            <div> {u.key}</div>
                                            <img
                                                alt={'Upload-' + i}
                                                src={u.url}
                                                style={{width: '200px'}}
                                            />
                                        </li>
                                    )
                                )}
                            </ol>
                        </Col>
                        <Col xs={12}>
                            <AssessmentUploadComponent
                                {...UPLOAD1}
                                onChange={setUpload1}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h3>Upload with answer</h3>
                            <span>Value: </span>
                            <ol>
                                {upload2.map((u, i) => (
                                        <li key={u.key}>
                                            <div> {u.key}</div>
                                            <img
                                                alt={'Upload-' + i}
                                                src={u.url}
                                                style={{width: '200px'}}
                                            />
                                        </li>
                                    )
                                )}
                            </ol>
                        </Col>
                        <Col xs={12}>
                            <AssessmentUploadComponent
                                {...UPLOAD2}
                                onChange={setUpload2}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h3>Section without score</h3>
                        </Col>
                        <Col xs={12}>
                            <AssessmentSectionComponent
                                {...SECTION1}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h3>Section with score</h3>
                        </Col>
                        <Col xs={12}>
                            <AssessmentSectionComponent
                                {...SECTION2}
                            />
                        </Col>
                    </Row>
                </div>

                <br/><hr/>

                <div>
                    <h2>Grouped in Form</h2>
                    <AssessmentFormComponent data={json} onSubmit={onSubmit}/>
                </div>

                <br/><hr/>

                <div>
                    <Collapse>
                        {ASSESSMENTS.map(assessment => (
                            <Collapse.Panel header={`${assessment.id} - ${assessment.title}`} key={assessment.id}>
                                <AssessmentFormComponent data={assessment} onSubmit={onSubmit}/>
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </div>

            </div>
        </StickyBreadcrumbPageComponent>
    );
}

export default DemoAssessmentPage;
