import React, { useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Styles
import "./accountRefundDetail.scss";

// Utils
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Input,
  Row,
  Upload,
  Select,
  notification,
  Spin,
  Form,
  Typography,
  InputNumber,
} from "antd";
import { MOMENT_FORMAT } from "@utils/constants";
import { getFileUploadUrl } from "@utils/env";
import { getTokenHeader } from "@utils/api";
import moment from "moment";
import { useMemo } from "react";
import { amountDisplay } from "@utils/common";

const USER_GET = gql`
  query userGet($id: ID) {
    userGet(id: $id) {
      nameChi
      flat
      floor
      buildingName
      estateName
      zone {
        id
        name
      }
      area {
        id
        name
      }
      region {
        id
        name
      }
      contactPerson {
        name
      }
    }
  }
`;

const REFUND_DETAIL_GET = gql`
  query refundRequestGet($id: ID) {
    refundRequestGet(id: $id) {
      id
      refundMethod {
        id
      }
      fpsId
      payableTo
      address
      addressType {
        id
      }
      depositAmount
      rentalAmount
      chequeNumber
      chequeDate
      mailDate
      accountNumber
      remark
      attachment {
        url
        key
        name
      }
    }
  }
`;

const REFUND_REQUEST_CREATE = gql`
  mutation refundRequestCreate($input: RefundRequestCreateInput) {
    refundRequestCreate(input: $input) {
      id
    }
  }
`;

const REFUND_REQUEST_UPDATE = gql`
  mutation refundRequestUpdate($input: RefundRequestUpdateInput) {
    refundRequestUpdate(input: $input) {
      id
    }
  }
`;

const ACCOUNT_GET = gql`
  query accountGet($userId: ID) {
    accountGet(userId: $userId) {
      depositAmount
      depositDifferent
      rentalAmount
    }
  }
`;

const AccountRefundDetail = ({
  visible,
  onClose,
  refundId,
  mode,
  onSuccessEdit,
  userId,
}) => {
  const { Option } = Select;

  const [getUserInfo, { data: getUserInfoData }] = useLazyQuery(USER_GET, {
    variables: { id: userId },
  });

  const {
    data: getAccountData,
    loading: getAccountLoading,
  } = useQuery(ACCOUNT_GET, { variables: { userId } });

  const [
    getRefundDetail,
    { data: getRefundDetailData, loading: getRefundDetailLoading },
  ] = useLazyQuery(REFUND_DETAIL_GET, { variables: { id: refundId } });

  const [
    createRefundRequest,
    { loading: createRefundRequestLoading },
  ] = useMutation(REFUND_REQUEST_CREATE);

  const [
    updateRefundRequest,
    { loading: updateRefundRequestLoading },
  ] = useMutation(REFUND_REQUEST_UPDATE);

  const isLoading =
    getRefundDetailLoading ||
    createRefundRequestLoading ||
    updateRefundRequestLoading ||
    getAccountLoading;

  const { errors, handleSubmit, register, setValue, watch } = useForm({
    defaultValues: {
      depositAmount: undefined,
      rentalAmount: undefined,
      chequeNumber: "",
      chequeDate: undefined,
      mailDate: undefined,
      accountNumber: "",
      address: "",
      payableTo: undefined,
      remark: "",
      attachment: [],
    },
  });

  const watchValue = watch();

  const userInfo = useMemo(() => {
    if (getUserInfoData) {
      return getUserInfoData?.userGet;
    }
  }, [getUserInfoData]);

  const refundDetail = useMemo(() => {
    if (getRefundDetailData) {
      return getRefundDetailData?.refundRequestGet;
    }
  }, [getRefundDetailData]);

  console.log("watchValue", watchValue);

  const userAddress = useMemo(
    () =>
      `${userInfo?.region?.name}${userInfo?.area?.name}${userInfo?.zone?.name}${userInfo?.estateName}${userInfo?.buildingName}${userInfo?.floor}${userInfo?.flat}`,
    [userInfo]
  );

  useEffect(() => {
    //create
    register({ name: "refundMethod" }, { required: mode === "create" });
    register(
      { name: "fpsId" },
      { required: mode === "create" && watchValue?.refundMethod === "fps" }
    );
    register(
      { name: "addressType" },
      {
        required:
          mode === "create" && watchValue?.refundMethod === "crossedCheque",
      }
    );
    register(
      { name: "address" },
      {
        required:
          mode === "create" &&
          watchValue?.refundMethod === "crossedCheque" &&
          (watchValue?.addressType === "userAddress" ||
            watchValue?.addressType === "other"),
      }
    );
    register(
      { name: "payableTo" },
      {
        required:
          mode === "create" && watchValue?.refundMethod === "crossedCheque",
      }
    );
    // update
    register({ name: "depositAmount" }, { required: true });
    register({ name: "rentalAmount" }, { required: true });
    register({ name: "chequeNumber" });
    register({ name: "chequeDate" });
    register({ name: "mailDate" });
    register({ name: "accountNumber" });
    // share
    register({ name: "remark" });
    register({ name: "attachment" });
  }, [mode, register, watchValue]);

  useEffect(() => {
    if (userInfo) {
      setValue("address", userAddress);
    }

    if (refundDetail) {
      //create
      setValue("refundMethod", refundDetail?.refundMethod?.id);
      setValue("fpsId", refundDetail?.fpsId);
      setValue("payableTo", refundDetail?.payableTo);
      setValue("address", refundDetail?.address);
      setValue("addressType", refundDetail?.addressType?.id);
      //update
      setValue(
        "depositAmount",
        refundDetail.depositAmount ? refundDetail.depositAmount / 100 : 0
      );
      setValue(
        "rentalAmount",
        refundDetail.rentalAmount ? refundDetail.rentalAmount / 100 : 0
      );
      setValue("chequeNumber", refundDetail.chequeNumber);
      setValue("chequeDate", refundDetail.chequeDate);
      setValue("mailDate", refundDetail?.mailDate);
      setValue("accountNumber", refundDetail?.accountNumber);
      //share
      setValue("remark", refundDetail?.remark);
    }
  }, [userInfo, refundDetail, setValue, userAddress]);

  useEffect(() => {
    if (mode === "create" && userId) {
      getUserInfo();
    } else if (["edit", "view"].includes(mode) && refundId) {
      getRefundDetail();
    }
  }, [getRefundDetail, getUserInfo, mode, refundId, userId]);

  const checkFileType = (file) => {
    if (file.type.includes("image/") || file.type.includes("application/pdf"))
      return true;
    return false;
  };

  const handleUploadChange = (info) => {
    if (checkFileType(info.file)) {
      let fileList = [...info.fileList];
      // fileList = fileList.slice(-1);
      fileList = fileList.map((file) => {
        if (file.response?.data) {
          file.key = file.response.data?.key;
          file.url = file.response.data?.url;
        }
        return file;
      });
      setValue("attachment", fileList);
    } else {
      notification.error({
        message: `不支援 ${info.file.name} 的檔案類型, 請上載圖片或PDF`,
      });
    }
  };

  const handleBeforeUpload = (file) => {
    return checkFileType(file);
  };

  const onSubmit = async ({ data, approvalType }) => {
    try {
      const fileList = data?.attachment?.map(({ key, size, name }) => ({
        key,
        size,
        name,
      }));
      let methodVar = {};
      switch (data.refundMethod) {
        case "crossedCheque":
          methodVar = {
            refundMethod: data.refundMethod,
            payableTo: data.payableTo ?? "",
            addressType: data.addressType,
            address: data.address ?? "",
          };
          break;

        case "fps":
          methodVar = {
            refundMethod: data.refundMethod,
            fpsId: data.fpsId ?? "",
          };
          break;

        default:
          console.error("unexpected data.refundMethod case");
          break;
      }

      if (mode === "create") {
        const baseVar = {
          userId: userId,
          remark: data?.remark ?? "",
          attachment: fileList,
        };
        await createRefundRequest({
          variables: {
            input: {
              depositAmount: data.depositAmount * 100,
              rentalAmount: data.rentalAmount * 100,
              ...baseVar,
              ...methodVar,
            },
          },
        });
      }
      if (mode === "edit") {
        await updateRefundRequest({
          variables: {
            input: {
              remark: data?.remark ?? "",
              attachment: fileList ?? [],
              ...methodVar,
              id: refundId ?? "",
              depositAmount: data.depositAmount * 100,
              rentalAmount: data.rentalAmount * 100,
              chequeNumber: data.chequeNumber ?? "",
              chequeDate: data.chequeDate ?? "",
              mailDate: data.mailDate ?? "",
              accountNumber: data.accountNumber ?? "",
              status: approvalType,
            },
          },
        });
      }
    } catch (error) {
      console.log("error", error);
    }
    onSuccessEdit();
  };

  const remark = (
    <Form.Item label="備註">
      <Input.TextArea
        rows={6}
        {...(mode === "view" ? {} : { placeholder: "填寫備註" })}
        disabled={mode === "view"}
        value={watchValue?.remark}
        onChange={({ target: { value } }) => setValue("remark", value)}
        style={{ width: "85%" }}
      />
    </Form.Item>
  );

  const payableToOptions = () => {
    return userInfo?.contactPerson.map((contactPerson) => {
      if (contactPerson.name)
        return (
          <Option key={contactPerson.name} value={contactPerson.name}>
            {contactPerson.name}
          </Option>
        );
    });
  };

  return (
    <div className="AccountRefundDetail">
      <Drawer
        className="AccountRefundDetail-drawer"
        width="50vw"
        visible={visible}
        onClose={onClose}
      >
        <Spin spinning={isLoading}>
          <div className="AccountRefundDetail-content">
            <h1>退款申請詳情</h1>
            <Form>
              <Form.Item
                label="收取方式"
                validateStatus={errors?.refundMethod && "error"}
                help={errors?.refundMethod && "請選擇收取方式"}
                required={true}
              >
                <Select
                  disabled={["edit", "view"].includes(mode)}
                  {...(mode === "view"
                    ? {}
                    : { placeholder: "請選擇收取方式" })}
                  value={watchValue?.refundMethod}
                  onChange={(value) => setValue("refundMethod", value)}
                  style={{ width: "40%" }}
                >
                  <Option value={"crossedCheque"}>以劃線支票方式收取</Option>
                  <Option value={"fps"}>以轉數快收取</Option>
                </Select>
              </Form.Item>
              {watchValue?.refundMethod === "crossedCheque" && (
                <>
                  <Form.Item
                    label="劃線支票抬頭"
                    required={true}
                    validateStatus={errors?.payableTo && "error"}
                    help={errors?.payableTo && "請選擇劃線支票抬頭"}
                  >
                    <Select
                      disabled={["edit", "view"].includes(mode)}
                      {...(mode === "view"
                        ? {}
                        : { placeholder: "請選擇劃線支票抬頭" })}
                      value={watchValue?.payableTo}
                      onChange={(value) => setValue("payableTo", value)}
                      style={{ width: "40%" }}
                    >
                      <Option value={userInfo?.nameChi}>
                        {userInfo?.nameChi}
                      </Option>
                      {payableToOptions()}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="支票收取地點"
                    required={true}
                    validateStatus={errors?.addressType && "error"}
                    help={errors?.addressType && "請選擇支票收取地點"}
                  >
                    <Select
                      disabled={["edit", "view"].includes(mode)}
                      {...(mode === "view"
                        ? {}
                        : { placeholder: "請選擇支票收取地點" })}
                      value={watchValue?.addressType}
                      onChange={(value) => {
                        setValue("addressType", value);
                        if (value === "userAddress")
                          setValue("address", userAddress);
                        if (value === "other") setValue("address", "");
                      }}
                      style={{ width: "70%" }}
                    >
                      <Option value={"serviceCenter"}>
                        賽馬會「ɑ家」樂齡科技教育及租賃服務中心
                      </Option>
                      <Option value={"userAddress"}>用户登記地址</Option>
                      <Option value={"other"}>其他地址</Option>
                    </Select>
                  </Form.Item>
                  {(watchValue?.addressType === "userAddress" ||
                    watchValue?.addressType === "other") && (
                    <Form.Item
                      label="支票寄送地址"
                      required={true}
                      validateStatus={errors?.address && "error"}
                      help={errors?.address && "請填寫支票寄送地址"}
                    >
                      {["edit", "view"].includes(mode) ? (
                        <Typography.Text
                          copyable
                          style={{ padding: "4px 11px" }}
                        >
                          {watchValue?.address}
                        </Typography.Text>
                      ) : (
                        <Input
                          disabled={
                            mode === "edit" ||
                            watchValue?.addressType === "userAddress"
                          }
                          {...(mode === "view"
                            ? {}
                            : { placeholder: "請填寫支票寄送地址" })}
                          value={watchValue?.address}
                          onChange={({ target: { value } }) =>
                            setValue("address", value)
                          }
                          style={{ width: "70%" }}
                        />
                      )}
                    </Form.Item>
                  )}
                </>
              )}
              {watchValue?.refundMethod === "fps" && (
                <Form.Item
                  label="已於轉數快登記的電話號碼/轉數快識別碼"
                  required={true}
                  validateStatus={errors?.fpsId && "error"}
                  help={errors?.fpsId && "請填寫電話號碼/轉數快識別碼"}
                >
                  {mode === "edit" ? (
                    <Typography.Text copyable style={{ padding: "4px 11px" }}>
                      {watchValue?.fpsId}
                    </Typography.Text>
                  ) : (
                    <Input
                      disabled={["edit", "view"].includes(mode)}
                      {...(mode === "view"
                        ? {}
                        : { placeholder: "請填寫電話號碼/轉數快識別碼" })}
                      value={watchValue?.fpsId}
                      onChange={({ target: { value } }) =>
                        setValue("fpsId", value)
                      }
                      style={{ width: "70%" }}
                    />
                  )}
                </Form.Item>
              )}
              {mode === "create" && remark}
            </Form>
            {["edit", "view", "create"].includes(mode) && (
              <div>
                <div className="item">
                  <Row>
                    <Col xs={12}>
                      <Form.Item
                        label="按金金額（港幣)"
                        validateStatus={errors?.depositAmount && "error"}
                        help={errors?.depositAmount && "請選擇按金金額"}
                        required={true}
                      >
                        {mode === "create" ? (
                          <Select
                            onChange={(value) => {
                              switch (value) {
                                case "NA":
                                  setValue("depositAmount", 0);
                                  break;
                                case "depositDifferent":
                                  setValue(
                                    "depositAmount",
                                    getAccountData?.accountGet
                                      ?.depositDifferent / 100
                                  );
                                  break;
                                case "currentAmount":
                                  setValue(
                                    "depositAmount",
                                    getAccountData?.accountGet?.depositAmount /
                                      100
                                  );
                                  break;
                                default:
                                  break;
                              }
                            }}
                            disabled={mode === "view" || mode === "edit"}
                            placeholder="請選擇金額"
                            style={{ width: "70%" }}
                          >
                            {getAccountData?.accountGet?.depositAmount === 0 &&
                              getAccountData?.accountGet?.depositDifferent <=
                                0 && (
                                <Select.Option key="NA">
                                  不適用 ($0)
                                </Select.Option>
                              )}
                            {getAccountData?.accountGet?.depositDifferent ===
                              0 &&
                              getAccountData?.accountGet?.depositAmount && (
                                <Select.Option
                                  disabled={
                                    getAccountData?.accountGet
                                      ?.depositAmount === 0
                                  }
                                  key="currentAmount"
                                >
                                  {`
                            現時按金餘額 ($${amountDisplay(
                              getAccountData?.accountGet?.depositAmount
                            )})`}
                                </Select.Option>
                              )}
                            {getAccountData?.accountGet?.depositDifferent >=
                              0 && (
                              <Select.Option
                                disabled={
                                  getAccountData?.accountGet
                                    ?.depositDifferent <= 0
                                }
                                key="depositDifferent"
                              >{`可退還按金 ($${amountDisplay(
                                getAccountData?.accountGet?.depositDifferent
                              )})`}</Select.Option>
                            )}
                          </Select>
                        ) : (
                          <InputNumber
                            disabled={true}
                            value={watchValue?.depositAmount}
                            // onChange={(value) => setValue("depositAmount", value)}
                            style={{ width: "70%" }}
                            precision={2}
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        label="租金金額（港幣)"
                        validateStatus={errors?.rentalAmount && "error"}
                        help={errors?.rentalAmount && "請選擇租金金額"}
                        required={true}
                      >
                        {mode === "create" ? (
                          <Select
                            onChange={(value) => {
                              switch (value) {
                                case "NA":
                                  setValue("rentalAmount", 0);
                                  break;
                                case "currentAmount":
                                  setValue(
                                    "rentalAmount",
                                    getAccountData?.accountGet?.rentalAmount /
                                      100
                                  );
                                  break;
                                default:
                                  break;
                              }
                            }}
                            disabled={mode === "view" || mode === "edit"}
                            style={{ width: "70%" }}
                            placeholder="請選擇金額"
                          >
                            <Select.Option key="NA">不適用 ($0)</Select.Option>
                            <Select.Option
                              disabled={
                                getAccountData?.accountGet?.rentalAmount === 0
                              }
                              key="currentAmount"
                            >
                              {`
                            現時租金餘額 ($${amountDisplay(
                              getAccountData?.accountGet?.rentalAmount
                            )})`}
                            </Select.Option>
                          </Select>
                        ) : (
                          <InputNumber
                            min={0}
                            disabled={true}
                            value={watchValue?.rentalAmount}
                            // onChange={(value) => setValue("rentalAmount", value)}
                            style={{ width: "70%" }}
                            precision={2}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    {watchValue?.refundMethod === "crossedCheque" &&
                      mode !== "create" && (
                        <Col xs={12}>
                          <Form.Item label="退款支票編號">
                            <Input
                              {...(mode === "view"
                                ? {}
                                : { placeholder: "請填寫編號" })}
                              disabled={mode === "view"}
                              value={watchValue?.chequeNumber}
                              onChange={({ target: { value } }) =>
                                setValue("chequeNumber", value)
                              }
                              style={{ width: "70%" }}
                            />
                          </Form.Item>
                        </Col>
                      )}
                  </Row>
                </div>
                {watchValue?.refundMethod === "crossedCheque" &&
                  mode !== "create" && (
                    <div className="item">
                      <Row>
                        <Col xs={12}>
                          <Form.Item label="退款支票收妥日期">
                            <DatePicker
                              {...(mode === "view"
                                ? {}
                                : { placeholder: "請選擇日期" })}
                              disabled={mode === "view"}
                              value={
                                watchValue?.chequeDate
                                  ? moment(
                                      watchValue?.chequeDate,
                                      MOMENT_FORMAT
                                    )
                                  : undefined
                              }
                              onChange={(date, dateString) =>
                                setValue("chequeDate", dateString)
                              }
                              style={{ width: "70%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12}>
                          <Form.Item label="支票寄出日期">
                            <DatePicker
                              {...(mode === "view"
                                ? {}
                                : { placeholder: "請選擇日期" })}
                              disabled={mode === "view"}
                              value={
                                watchValue?.mailDate
                                  ? moment(watchValue?.mailDate, MOMENT_FORMAT)
                                  : undefined
                              }
                              onChange={(date, dateString) =>
                                setValue("mailDate", dateString)
                              }
                              style={{ width: "70%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  )}
                {mode !== "create" && (
                  <div className="item">
                    <Row>
                      <Col xs={12}>
                        <Form.Item label="賬目編號">
                          <Input
                            {...(mode === "view"
                              ? {}
                              : { placeholder: "請填寫賬目編號" })}
                            disabled={mode === "view"}
                            value={watchValue?.accountNumber}
                            onChange={({ target: { value } }) =>
                              setValue("accountNumber", value)
                            }
                            style={{ width: "70%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                )}
                {mode !== "create" && remark}
                {mode !== "create" && (
                  <Form.Item label="已上載的簽署檔案">
                    {refundDetail?.attachment?.length > 0 ? (
                      <Upload
                        defaultFileList={refundDetail.attachment.map(
                          (item) => ({
                            ...item,
                            uid: item.key,
                          })
                        )}
                        disabled={["edit", "view"].includes(mode)}
                      />
                    ) : (
                      <>沒有已上載的簽署檔案</>
                    )}
                  </Form.Item>
                )}
              </div>
            )}
            {mode === "create" && (
              <div className="item">
                <Form.Item label="上載簽署檔案">
                  <Upload
                    headers={{
                      "x-token": getTokenHeader(),
                      "X-Requested-With": null,
                    }}
                    action={getFileUploadUrl()}
                    fileList={watchValue?.attachment}
                    onChange={handleUploadChange}
                    beforeUpload={handleBeforeUpload}
                    multiple={true}
                  >
                    <Button className="btn btn-secondary">上載檔案</Button>
                  </Upload>
                </Form.Item>
              </div>
            )}

            <div className="btn-container">
              {!(mode === "view") && (
                <Row type="flex" gutter={12}>
                  {!(mode === "create") && (
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={handleSubmit((data) =>
                          onSubmit({ data, approvalType: "rejected" })
                        )}
                      >
                        拒絕
                      </Button>
                    </Col>
                  )}
                  <Col>
                    <Button
                      className="btn"
                      type="primary"
                      onClick={handleSubmit((data) =>
                        onSubmit({ data, approvalType: "approved" })
                      )}
                    >
                      {mode === "create" ? "創建" : "批准"}
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </Spin>
      </Drawer>
    </div>
  );
};

AccountRefundDetail.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refundId: PropTypes.string,
  mode: PropTypes.string,
  onSuccessEdit: PropTypes.func,
  userId: PropTypes.string,
};

AccountRefundDetail.defaultProps = {
  visible: false,
};

export default AccountRefundDetail;
