/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import queryString from "query-string";
import { Link, useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Layouts
import CustPaginationComponent from "@components/custPagination/custPagination";

// Styles
import "./transactionRecord.scss";

// Utils
import { MOMENT_DATETIME_FORMAT } from "@utils/constants";
import { amountDisplay } from "@utils/common";
import { isEmpty } from "@utils/validate";
import { Button, Drawer, Modal, Table, Upload } from "antd";
import moment from "moment";

const ACCOUNT_GET = gql`
  query AccountGet($userId: ID, $size: Int!, $offset: Int!) {
    accountGet(userId: $userId) {
      user {
        userId
      }
      transaction(size: $size, offset: $offset) {
        id
        referenceId
        # amount
        title
        remark
        paymentDatetime
        attachment {
          key
          url
          name
          size
        }
        rentalAmount
        depositAmount
        rentalInOut
        depositInOut
      }
    }
  }
`;

const PAGE_SIZE = 10;

const TransactionRecord = ({ userId, visible, onClose }) => {
  const history = useHistory();
  const location = useLocation();

  const [downloadModalParam, setDownloadModalParam] = useState({
    visible: false,
  });

  const columns = [
    {
      title: "交易編號",
      dataIndex: "referenceId",
      //   render: (text) => (
      //     <Link style={{ textDecoration: "underline" }} className="link" to={"/"}>
      //       {text}
      //     </Link>
      //   ),
    },
    {
      title: "名稱",
      dataIndex: "title",
    },
    {
      title: "租金餘額（港幣）",
      dataIndex: "rentalAmount",
      render: (amount) => <span>{`$${amountDisplay(amount ?? 0)}`}</span>,
    },
    {
      title: "租金出入金額（港幣）",
      dataIndex: "rentalInOut",
      render: (amount) => <span>{`$${amountDisplay(amount ?? 0)}`}</span>,
    },
    {
      title: "按金餘額（港幣）",
      dataIndex: "depositAmount",
      render: (amount) => <span>{`$${amountDisplay(amount ?? 0)}`}</span>,
    },
    {
      title: "按金出入金額（港幣）",
      dataIndex: "depositInOut",
      render: (amount) => <span>{`$${amountDisplay(amount ?? 0)}`}</span>,
    },
    // {
    //   title: "金額（港幣）",
    //   dataIndex: "amount",
    //   render: (amount) => (
    //     <span>
    //       {amount >= 0
    //         ? `$${amountDisplay(amount)}`
    //         : `($${amountDisplay(Math.abs(amount))})`}
    //     </span>
    //   ),
    // },
    {
      title: "交易日期及時間",
      dataIndex: "paymentDatetime",
      render: (date) => (
        <span>{moment(date).format(MOMENT_DATETIME_FORMAT)}</span>
      ),
    },
    // {
    //   title: "備註",
    //   dataIndex: "remark",
    //   render: (text) => <span>{!isEmpty(text) ? text : "-"}</span>,
    // },
    {
      dataIndex: "action",
      render: (text, transaction) => {
        return transaction?.attachment?.length > 0 ? (
          <Button
            className="btn btn-secondary"
            onClick={() =>
              setDownloadModalParam({
                attachment: transaction.attachment.map((item) => ({
                  ...item,
                  uid: item.key,
                })),
                visible: true,
              })
            }
          >
            下載確認書
          </Button>
        ) : null;
      },
    },
  ];

  const [
    getAccount,
    { loading: getAccountLoading, data: getAccountData },
  ] = useLazyQuery(ACCOUNT_GET);

  const query = useMemo(() => {
    const { transactionPage, transactionPageSize } = queryString.parse(
      location.search
    );
    return {
      ...queryString.parse(location.search),
      transactionPage: transactionPage ? Number(transactionPage) : 1,
      transactionPageSize: transactionPageSize
        ? Number(transactionPageSize)
        : PAGE_SIZE,
    };
  }, [location.search]);

  const transactions = useMemo(() => {
    return (getAccountData?.accountGet?.transaction ?? []).slice(
      0,
      query.transactionPageSize
    );
  }, [getAccountData, query.transactionPageSize]);

  const isMaxPage = useMemo(() => {
    return transactions.length < query.transactionPageSize;
  }, [transactions.length, query.transactionPageSize]);

  useEffect(() => {
    if (userId) {
      history.push({
        search: queryString.stringify(query),
      });
      getAccount({
        variables: {
          userId: userId,
          size: query.transactionPageSize + 1,
          offset: (query.transactionPage - 1) * query.transactionPageSize,
        },
      });
    }
  }, [getAccount, userId, query, history]);

  const renderDownloadModal = () => (
    <Modal
      visible={downloadModalParam.visible}
      onCancel={() => setDownloadModalParam({ visible: false })}
      footer={null}
      title="下載確認書"
    >
      <Upload disabled={true} defaultFileList={downloadModalParam.attachment} />
    </Modal>
  );

  return (
    <div className="TransactionRecord">
      <Drawer
        className="TransactionRecord-drawer"
        width="60vw"
        visible={visible}
        onClose={onClose}
      >
        {downloadModalParam.visible && renderDownloadModal()}
        <div>
          <h1>交易紀錄</h1>
          <div className="paging-component">
            <CustPaginationComponent
              isMinPage={query.transactionPage === 1}
              isMaxPage={isMaxPage}
              page={query.transactionPage}
              // pageSize={query.transactionPageSize || PAGE_SIZE}
              pageSize={PAGE_SIZE}
              onPageChange={(page) => {
                history.push({
                  pathname: location.pathname,
                  search: queryString.stringify({
                    ...query,
                    transactionPage: page,
                  }),
                });
              }}
            />
          </div>
          <Table
            scroll={{
              x: "max-content",
            }}
            className="transaction-table"
            rowKey="transactionId"
            columns={columns}
            dataSource={transactions}
            loading={getAccountLoading}
            pagination={false}
          />
        </div>
      </Drawer>
    </div>
  );
};

TransactionRecord.propTypes = {
  userId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

TransactionRecord.defaultProps = {
  visible: false,
};

export default TransactionRecord;
