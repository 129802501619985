import React from "react";
import PropTypes from "prop-types";

// Components
import { InputNumber } from "antd";

// Style
import "./inputDiscount.scss";

// Utils
import { classList } from "@utils/common";

function InputDiscountComponent(props) {
  const { tier, className, discountPercentage, onChange } = props;

  const getClassList = () =>
    classList({
      "Store-Component": true,
      "StoreUserDiscount-Component": true,
      [className]: true,
    });

  return (
    <div className={getClassList()}>
      <InputNumber
        id={tier}
        name={discountPercentage}
        className="StoreUserDiscount-Component-inputDiscount"
        min={0}
        max={100}
        onChange={(value) => onChange(value)}
        defaultValue={Math.round(discountPercentage * 100) / 100}
      />
      <label className="StoreUserDiscount-Component-label">%</label>
    </div>
  );
}

export default InputDiscountComponent;

InputDiscountComponent.propTypes = {
  tier: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  discountPercentage: PropTypes.number,
};

InputDiscountComponent.defaultProps = {
  discountPercentage: 0,
  className: "",
};
