/* eslint-disable no-unused-vars */
import React from "react";
// import { gql } from "@apollo/client";
import PropTypes from "prop-types";

// Components
import { Button, Typography, Select, Row, Col } from "antd";

// Styles
import "./filter.scss";

// Utils

function Filter({
  filterList,
  onFilterSearch,
  onResetFilter,
  isFilterShow,
  filterValue,
  onFilterValueChange,
}) {
  // const { data } = useQuery(API);

  return (
    <div className={`Filter ${isFilterShow ? "show" : "hide"}`}>
      <div className="filter-container">
        <div className="options-container">
          <Typography>篩選條件</Typography>
          <div className="options">
            <Row gutter={[16, 0]} className="container">
              <Col className="box">
                <div className="option">
                  <Typography>器材狀態</Typography>
                  <Select
                    showSearch
                    mode="tags"
                    value={filterValue.inventoryStatus}
                    onChange={(inventoryStatus) =>
                      onFilterValueChange((filterValue) => {
                        return {
                          ...filterValue,
                          inventoryStatus,
                        };
                      })
                    }
                  >
                    <Select.Option value="inactive">尚未上架</Select.Option>
                    <Select.Option value="reserved">已預留</Select.Option>
                    <Select.Option value="readyForLease">可租用</Select.Option>
                    <Select.Option value="leasing">已租用</Select.Option>
                    <Select.Option value="cleaning">清潔中</Select.Option>
                    <Select.Option value="cleaned">已清潔</Select.Option>
                    <Select.Option value="repairing">檢查/維修中</Select.Option>
                    <Select.Option value="inspected">已檢查/維修</Select.Option>
                    <Select.Option value="toBeDisposed">落架中</Select.Option>
                    <Select.Option value="terminated">已落架</Select.Option>
                    <Select.Option value="damaged">已損毀</Select.Option>
                  </Select>
                </div>
              </Col>
              <Col className="box">
                <div className="option">
                  <Typography>檢查/維修記錄狀態</Typography>
                  <Select
                    showSearch
                    mode="tags"
                    value={filterValue.inspectionResult}
                    onChange={(inspectionResult) =>
                      onFilterValueChange((filterValue) => {
                        return {
                          ...filterValue,
                          inspectionResult,
                        };
                      })
                    }
                  >
                    <Select.Option value="packed">已包裝</Select.Option>
                    <Select.Option value="pending">待維修</Select.Option>
                    <Select.Option value="repairing">維修中</Select.Option>
                    <Select.Option value="normal">正常</Select.Option>
                    <Select.Option value="repaired">已維修</Select.Option>
                    <Select.Option value="unableToRepair">
                      未能維修
                    </Select.Option>
                  </Select>
                </div>
              </Col>
              <Col className="box">
                <div className="option">
                  <Typography>運送工作狀態</Typography>
                  <Select
                    showSearch
                    mode="tags"
                    value={filterValue.deliveryStatus}
                    onChange={(deliveryStatus) =>
                      onFilterValueChange((filterValue) => {
                        return {
                          ...filterValue,
                          deliveryStatus,
                        };
                      })
                    }
                  >
                    <Select.Option value="pending">待命</Select.Option>
                    <Select.Option value="confirmedDelivery">
                      已確認
                    </Select.Option>
                    <Select.Option value="inventoryReady">已備貨</Select.Option>
                    <Select.Option value="checkedOut">已發貨</Select.Option>
                    <Select.Option value="completed">已完成</Select.Option>
                    <Select.Option value="cancelled">已取消</Select.Option>
                  </Select>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="btn-container">
          <Button className="btn btn-secondary" onClick={onResetFilter}>
            清除設定
          </Button>
          <Button className="btn" type="primary" onClick={onFilterSearch}>
            搜尋
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Filter;

Filter.propTypes = {
  filterList: PropTypes.arrayOf(PropTypes.string),
  filterTypes: PropTypes.arrayOf(PropTypes.string),
  filterValue: PropTypes.shape({
    inventoryStatus: PropTypes.arrayOf(PropTypes.string),
    inspectionResult: PropTypes.arrayOf(PropTypes.string),
    deliveryStatus: PropTypes.arrayOf(PropTypes.string),
  }),
  onFilterValueChange: PropTypes.func.isRequired,
  isFilterShow: PropTypes.bool.isRequired,
  onFilterSearch: PropTypes.func.isRequired,
  onResetFilter: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  filterList: ["categoryId", "brand", "dimension", "characteristicTag"],
};
