/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { gql } from "@apollo/client";

// Components
import ServiceFilter from "@components/filter/serviceFilter/serviceFilter";

// Constants
import { PAGE, PAGE_NAME, PAGE_SIZE, ACCESS_LEVELS } from "@utils/constants";

// Layouts
import TableView from "@layouts/tableView/tableView";

// Utils
import { StatusTag } from "@utils/commonComponents";
import { Button, Modal, notification, Tag } from "antd";
import { replaceUrlByParams } from "@utils/api";
import { useAccessLevel } from "@utils/hook";

const SERVICE_LIST = gql`
  query ServiceList(
    $categoryId: [String]
    $characteristicTag: [String]
    $size: Int!
    $offset: Int!
    $keyword: String
    $reverseOrder: Boolean
  ) {
    serviceList(
      categoryId: $categoryId
      characteristicTag: $characteristicTag
      size: $size
      offset: $offset
      keyword: $keyword
      reverseOrder: $reverseOrder
    ) {
      id
      referenceId
      nameEng
      nameChi
      category {
        id
        name
        mainCategory {
          id
          name
        }
      }
      status {
        id
        name
      }
    }
  }
`;

const SERVICE_DELETE = gql`
  mutation serviceDelete($id: ID) {
    serviceDelete(id: $id)
  }
`;

const SERVICE_COUNT = gql`
  query serviceCount(
    $categoryId: [String]
    $characteristicTag: [String]
    $keyword: String
  ) {
    serviceCount(
      categoryId: $categoryId
      characteristicTag: $characteristicTag
      keyword: $keyword
    )
  }
`;

function StockServicePage(props) {
  const { location } = props;
  const history = useHistory();

  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.CASE_MANAGEMENT,
  ]);

  const BREADCRUMB = [
    {
      path: PAGE.STOCK_SERVICE,
      name: PAGE_NAME.STOCK_SERVICE,
    },
  ];

  const [deleteInventoryParams, setDeleteInventoryParams] = useState({
    visible: false,
  });

  const columns = [
    {
      title: "款號",
      dataIndex: "referenceId",
      sorter: true,
      render: (text, service) => (
        <Link
          className="link"
          to={replaceUrlByParams(PAGE.STOCK_SERVICE_DETAIL, {
            id: service.id,
            mode: "view",
          })}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "名稱",
      dataIndex: "nameChi",
    },
    {
      title: "分類",
      dataIndex: "category.mainCategory",
      render: (mainCategory) => <div className="text">{mainCategory.name}</div>,
    },
    {
      title: "子類別",
      dataIndex: "category",
      sorter: true,
      render: (category) => <div className="text">{category.name}</div>,
    },
    {
      title: "狀態",
      dataIndex: "status",
      render: (status) => <StatusTag status={status} />,
    },
    {
      title: "",
      render: (record) => {
        return record?.status?.id === "inactive"
          ? !isDenied && (
              <Button
                type="danger"
                onClick={() =>
                  setDeleteInventoryParams({ visible: true, record })
                }
              >
                刪除
              </Button>
            )
          : null;
      },
    },
  ];

  const [getServiceList, { loading, error, data }] = useLazyQuery(SERVICE_LIST);
  const [getServiceCount, {loading: serviceCountLoading, data: serviceCountData}] = useLazyQuery(SERVICE_COUNT);

  const [query, setQuery] = useState(queryString.parse(location.search));

  const [isFilterShow, setIsFilterShow] = useState(false);

  const serviceList = useMemo(() => {
    return (data?.serviceList || []).slice(0, PAGE_SIZE);
  }, [data]);

  const isMaxPage = useMemo(() => {
    return data?.serviceList ? data?.serviceList.length <= PAGE_SIZE : true;
  }, [data]);

  useEffect(() => {
    setQuery(queryString.parse(location.search));
  }, [location.search]);

  const refershServiceList = useCallback(() => {
    const {
      page,
      pageSize,
      keyword,
      categoryId,
      sortOrder,
      characteristicTag,
    } = queryString.parse(location.search);

    getServiceList({
      variables: {
        offset: +((page - 1) * pageSize) || 0,
        size: +(pageSize || PAGE_SIZE) + 1,
        keyword: keyword,
        categoryId: categoryId,
        characteristicTag: queryParamToArray(characteristicTag),
        reverseOrder: sortOrder ? sortOrder === "descend" : undefined,
      },
    });

    getServiceCount({
      variables: {
        keyword: keyword,
        categoryId: categoryId,
        characteristicTag: queryParamToArray(characteristicTag),
      },
    });
  }, [getServiceCount, getServiceList, location.search]);

  useEffect(() => {
    refershServiceList();
  }, [refershServiceList]);

  const queryParamToArray = (x) => (x ? (!Array.isArray(x) ? [x] : x) : []);

  const onFilterSearch = () => {
    history.push({
      search: queryString.stringify(query),
    });
  };

  const onFilterClick = (state) => {
    setIsFilterShow(state);
  };

  const [serviceDelete, { loading: serviceDeleteLoading }] = useMutation(
    SERVICE_DELETE
  );

  const onDeleteService = async (record) => {
    try {
      const { data } = await serviceDelete({ variables: { id: record?.id } });
      if (data.serviceDelete) {
        notification.success({
          message: `成功刪除${record?.referenceId}`,
          duration: 5,
        });
        refershServiceList();
      } else {
        notification.error({
          message: `刪除${record?.referenceId}失敗`,
          duration: 5,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        title={`刪除${BREADCRUMB[0].name}`}
        visible={deleteInventoryParams.visible}
        onOk={() => {
          onDeleteService(deleteInventoryParams?.record);
          setDeleteInventoryParams((_) => ({ ..._, visible: false }));
        }}
        onCancel={() =>
          setDeleteInventoryParams((_) => ({ ..._, visible: false }))
        }
        okText="確定"
        cancelText="取消"
        okButtonProps={{ type: "danger", loading: serviceDeleteLoading }}
      >
        <div>
          確定{`刪除${BREADCRUMB[0].name}`}{" "}
          {deleteInventoryParams?.record?.referenceId} ?
        </div>
        <div>所有有關存貨亦會被刪除。</div>
      </Modal>
      <TableView
        {...props}
        addBtnConfig={{
          url: PAGE.STOCK_SERVICE_CREATE,
          text: "新增服務",
        }}
        breadcrumb={BREADCRUMB}
        rowKey="id"
        columns={columns}
        {...(!isDenied && { hasAddBtn: true })}
        hasFilter
        isMaxPage={isMaxPage}
        isFilterShow={isFilterShow}
        onFilterClick={onFilterClick}
        searchBarPlaceholder="搜尋服務"
        tableData={serviceList}
        total={serviceCountData?.serviceCount}
      >
        <ServiceFilter
          filterType={[""]}
          filterValue={query}
          onFilterSearch={onFilterSearch}
          onResetFilter={() => {
            history.push({
              search: queryString.stringify({ page: 1, pageSize: PAGE_SIZE }),
            });
          }}
          isFilterShow={isFilterShow}
          onFilterValueChange={setQuery}
        />
      </TableView>
    </>
  );
}

export default StockServicePage;

StockServicePage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
