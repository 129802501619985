/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useEffect, useState } from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import queryString from "query-string";
import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";

//style
import "./orderingItem.scss";

// Components
import CreateOrderingItemsDrawer from "@components/createOrderingItemsDrawer/createOrderingItemsDrawer";
import CreateServiceOrderingItemsDrawer from "@components/createServiceOrderingItemsDrawer/createServiceOrderingItemsDrawer";
import PrescriptionRecordExpander from "@components/prescriptionRecordExpander/prescriptionRecordExpander";
import CreateOrderingTerminationDrawer from "@components/orderingTerminationDrawer/orderingTerminationDrawer";
import AvailablePartDrawer from "@components/availablePartDrawer/availablePartDrawer";
import DeliveryEditDrawer from "@components/deliveryEditDrawer/deliveryEditDrawer";
import CustPaginationComponent from "@components/custPagination/custPagination";
import ContractDrawer from "@components/contractDrawer/contractDrawer";
// import PaymentPreview from "@components/paymentPreview/paymentPreview";
import OrderingItemEdit from "./orderingItemEdit";
// import CreateDamageReportDrawer from "@components/createDamageReportDrawer/createDamageReportDrawer";
import ViewDamageReportsDrawer from "@components/viewDamageReportsDrawer/viewDamageReportsDrawer";

import ChangeEquipmentDrawer from "@components/changeEquipmentDrawer/changeEquipmentDrawer";

// Contants
import { PAGE } from "@utils/constants";

// Utils
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Icon,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
  Popconfirm,
  notification,
  Spin,
} from "antd";
import {
  MOMENT_FORMAT,
  PAGE_SIZE,
  PRODUCT_TYPE,
  ACCESS_LEVELS,
} from "@utils/constants";
import { replaceUrlByParams } from "@utils/api";
import { amountDisplay } from "@utils/common";
import moment from "moment";
import { useAccessLevel } from "@utils/hook";

import icon_truck_delivery_cancelled from "@assets/images/icon-truck-delivery-cancelled.png";
import icon_truck_delivery_completed from "@assets/images/icon-truck-delivery-completed.png";
import icon_truck_delivery_requested from "@assets/images/icon-truck-delivery-requested.png";
import icon_truck_reclaim_cancelled from "@assets/images/icon-truck-reclaim-cancelled.png";
import icon_truck_reclaim_completed from "@assets/images/icon-truck-reclaim-completed.png";
import icon_truck_reclaim_requested from "@assets/images/icon-truck-reclaim-requested.png";
import Text from "antd/lib/typography/Text";
import ChangeEquipmentUpdateDrawer from "../../../components/changeEquipmentUpdateDrawer/changeEquipmentUpdateDrawer";

const ORDERING_ITEM_FRAGMENT = gql`
  fragment Fields on OrderingItem {
    id
    inventoryRecord {
      id
      referenceId
      parentInventoryRecord {
        id
        referenceId
      }
      defaultPart {
        product {
          id
          ... on Device {
            productInfo {
              nameChi
            }
          }
          ... on Part {
            productInfo {
              nameChi
            }
          }
          ... on Consumable {
            productInfo {
              nameChi
            }
          }
          ... on Misc {
            nameChi
          }
          referenceId
          status {
            id
          }
          category {
            name
          }
        }
      }
      extraPart {
        product {
          id
          referenceId
          status {
            id
          }
          category {
            name
          }
        }
      }
      product {
        __typename
        id
        referenceId
        ... on Device {
          productInfo {
            nameChi
          }
        }
        ... on Part {
          productInfo {
            nameChi
          }
        }
        ... on Consumable {
          productInfo {
            nameChi
          }
        }
        ... on Misc {
          nameChi
        }
      }
    }
    product {
      __typename
      id
      referenceId
      ... on Device {
        productInfo {
          nameChi
        }
        quantity
        leasableQuantity
        washingQuantity
        waitingQuantity
      }
      ... on Part {
        productInfo {
          nameChi
        }
        quantity
        leasableQuantity
        washingQuantity
        waitingQuantity
      }
      ... on Consumable {
        productInfo {
          nameChi
        }
      }
      ... on Service {
        nameChi
      }
      ... on Misc {
        nameChi
      }
    }
    serviceEstimatedStartDate
    rentalMonth
    serviceStartDate
    serviceEndDate
    status {
      id
      name
    }
    depositAmount
    rentalAmount
    purchaseAmount
    quantity
    subtotal
    remark
    delivery {
      type {
        id
      }
      deliveryStatus {
        id
        name
      }
    }
    orderingTermination {
      reason {
        id
        name
      }
      terminateDate
      remark
      attachment {
        key
        url
        name
        size
      }
    }
    paymentStatus {
      id
      name
    }
    deletable
    replacementDate
    replacementRemark
    freeReplacement
    replaceFrom {
      id
      inventoryRecord {
        id
        referenceId
        product {
          __typename
          id
          referenceId
          ... on Device {
            productInfo {
              nameChi
            }
          }
          ... on Part {
            productInfo {
              nameChi
            }
          }
        }
      }
      product {
        __typename
        id
        referenceId
        ... on Device {
          productInfo {
            nameChi
          }
          quantity
          leasableQuantity
          washingQuantity
          waitingQuantity
        }
        ... on Part {
          productInfo {
            nameChi
          }
          quantity
          leasableQuantity
          washingQuantity
          waitingQuantity
        }
      }
    }
  }
`;

const TERMINATED_ORDERING_ITEM_LIST = gql`
  query OrderingGet($userId: ID, $size: Int, $offset: Int) {
    orderingGet(userId: $userId) {
      terminatedOrderingItem(size: $size, offset: $offset) {
        ...Fields
      }
    }
  }
  ${ORDERING_ITEM_FRAGMENT}
`;

const ORDERING_ITEM_LIST = gql`
  query OrderingGet($userId: ID) {
    orderingGet(userId: $userId) {
      orderingItem {
        ...Fields
        partOrderingItem {
          ...Fields
          replaceFrom {
            id
          }
        }
        replaceFrom {
          id
        }
      }
      otherOrderingItem {
        ...Fields
        replaceFrom {
          id
        }
      }
    }
  }
  ${ORDERING_ITEM_FRAGMENT}
`;

const ORDERING_RESERVATION_DUE_DATE = gql`
  query OrderingGet($userId: ID) {
    orderingGet(userId: $userId) {
      reservationDueDate
    }
  }
`;

const ORDERING_ITEM_DELETE = gql`
  mutation OrderingItemDelete($orderingItemId: ID!) {
    orderingItemDelete(orderingItemId: $orderingItemId)
  }
`;

const FIRST_PAYMENT_CREATE = gql`
  mutation FirstPaymentCreate($userId: ID!) {
    firstPaymentCreate(userId: $userId)
  }
`;

const ORDERING_ITEM_WAIVE_LIMIT = gql`
  query orderingItemWaiveLimitGet($orderingItemId: ID) {
    orderingItemWaiveLimitGet(orderingItemId: $orderingItemId) {
      amount
      depositAmount
    }
  }
`;

const ORDERING_ITEM_WAIVE = gql`
  mutation OrderingItemWaive(
    $orderingItemId: ID!
    $amount: Int!
    $depositAmount: Int
    $remark: String
  ) {
    orderingItemWaive(
      orderingItemId: $orderingItemId
      amount: $amount
      depositAmount: $depositAmount
      remark: $remark
    )
  }
`;

// const ACCOUNT_WAIVE_LIMIT = gql`
//   query accountWaiveLimitGet($userId: ID) {
//     accountWaiveLimitGet(userId: $userId) {
//       amount
//       depositAmount
//     }
//   }
// `;

// const ACCOUNT_WAIVE = gql`
//   mutation accountWaive(
//     $userId: ID!
//     $amount: Int!
//     $depositAmount: Int
//     $remark: String
//   ) {
//     accountWaive(
//       userId: $userId
//       amount: $amount
//       depositAmount: $depositAmount
//       remark: $remark
//     )
//   }
// `;

const UPDATING_RESERVATIOON_DUE_DATE = gql`
  mutation reservationDueDateUpdate(
    $userId: ID!
    $reservationDueDate: LocalDate!
  ) {
    reservationDueDateUpdate(
      userId: $userId
      reservationDueDate: $reservationDueDate
    )
  }
`;

const CREATE_ORDERINGITEM_PENALTY = gql`
  mutation orderingItemPenalty(
    $orderingItemId: ID!
    $amount: Int!
    $remark: String
  ) {
    orderingItemPenalty(
      orderingItemId: $orderingItemId
      amount: $amount
      remark: $remark
    )
  }
`;

const CREATE_LASTPAYMENT = gql`
  mutation lastPaymentCreate($userId: ID!) {
    lastPaymentCreate(userId: $userId)
  }
`;

const PAYMENT_PREVIEW_GET = gql`
  query paymentStatementPreviewGet($userId: ID!) {
    paymentStatementPreviewGet(userId: $userId) {
      url
      name
    }
  }
`;

const ORDERING_ITEM_LIST_TYPE = {
  current: "current",
  other: "other",
  terminated: "terminated",
  toBeTerminate: "toBeTerminate",
};

const ORDERING_ITEM_CHANGE = gql`
  mutation orderingItemRefund($orderingItemId: ID!) {
    orderingItemRefund(orderingItemId: $orderingItemId)
  }
`;

const IsItemPenaltyOrWaive = (record) => {
  return (
    record.product?.__typename === PRODUCT_TYPE.misc &&
    ["F01", "F02"].includes(record.product?.referenceId)
  );
};

export const BottomBar = ({
  totalOrderingItemAmount,
  totalOtherOrderingItemAmount,
  batchEdit,
  isBatchEdit,
  batchEditText,
  batchEditMode,
  selectedOrderingItems,
  selectedOtherOrderingItems,
  setPaymentPreviewVisible,
}) => {
  const { Text } = Typography;

  return isBatchEdit ? (
    <Row className="selected-layout">
      {/* <Row>
        <Col>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Tooltip title={"預覽帳單"}>
              <Button
                type="link"
                size="small"
                icon="info-circle"
                onClick={() => setPaymentPreviewVisible(true)}
              ></Button>
            </Tooltip>
            <Text className="text">
              租借項目
              <Text style={{ fontSize: "24px", fontWeight: "bold" }}>
                {" "}
                ${amountDisplay(totalOrderingItemAmount)} +{" "}
              </Text>
              服務及其他項目
              <Text style={{ fontSize: "24px", fontWeight: "bold" }}>
                {" "}
                ${amountDisplay(totalOtherOrderingItemAmount)} ={" "}
              </Text>
              合計港幣
              <Text style={{ fontSize: "24px", fontWeight: "bold" }}>
                {" "}
                $
                {amountDisplay(
                  totalOrderingItemAmount + totalOtherOrderingItemAmount
                )}
              </Text>
            </Text>
          </div>
        </Col>
      </Row> */}
      {isBatchEdit && (
        <Row type="flex" style={{ marginTop: "16px" }}>
          <Col xs={12}>
            <Text className="text">
              已選擇
              <Text style={{ fontSize: "24px", fontWeight: "bold" }}>
                {" "}
                {selectedOrderingItems?.length +
                  selectedOtherOrderingItems?.length}{" "}
              </Text>
              項{batchEditText}
            </Text>
          </Col>
          <Col xs={12}>
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                className="btn btn-secondary"
                onClick={() => batchEdit(false)}
                style={{ marginRight: "16px" }}
              >
                取消
              </Button>
              <Button type="primary" onClick={() => batchEdit(true)}>
                {batchEditMode === "changeEquipment" ? "查看存貨" : "確認"}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </Row>
  ) : null;
};

BottomBar.propTypes = {
  totalOrderingItemAmount: PropTypes.func,
  totalOtherOrderingItemAmount: PropTypes.func,
  batchEdit: PropTypes.func,
  isBatchEdit: PropTypes.bool,
  batchEditMode: PropTypes.string,
  batchEditText: PropTypes.string,
  selectedOrderingItems: PropTypes.array,
  selectedOtherOrderingItems: PropTypes.array,
  setPaymentPreviewVisible: PropTypes.func,
};

const ExemptAmountModal = ({
  visible,
  onOk = () => {},
  onCancel = () => {},
  params,
  listType,
}) => {
  const { control, errors, handleSubmit } = useForm();

  const maxAmount = Number(params?.maxAmount).toFixed(2);

  const maxDepositAmount = Number(params?.maxDepositAmount).toFixed(2);

  return (
    <Modal
      title="豁免金額"
      visible={visible}
      onOk={handleSubmit(onOk)}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={params?.isExempting}
          onClick={handleSubmit(onOk)}
        >
          提交
        </Button>,
      ]}
    >
      <Row gutter={[4, 4]}>
        <Col span={10}>
          {listType === "other" && params.orderingItemId
            ? "購買金額 (港幣)"
            : "租金及服務及其他項目 (港幣)"}
        </Col>
        <Col span={14}>
          <Controller
            as={
              <InputNumber
                min={0}
                placeholder={`請填寫租金，上限為 $${maxAmount}`}
                precision={2}
                style={{ width: "100%" }}
              />
            }
            control={control}
            name="amount"
            rules={{
              required: {
                value: true,
                message: "請填寫租金",
              },
              max: {
                value: maxAmount,
                message: `可豁免租金上限為 $${maxAmount}`,
              },
            }}
          />
          <Col span={24}>
            {errors?.amount && (
              <Text type="danger">{errors?.amount?.message}</Text>
            )}
          </Col>
        </Col>
      </Row>
      {(listType === "current" || params.userId) && (
        <Row gutter={[4, 4]}>
          <Col span={10}>按金 (港幣)</Col>
          <Col span={14}>
            <Controller
              as={
                <InputNumber
                  min={0}
                  placeholder={`請填寫按金，上限為 $${maxDepositAmount}`}
                  precision={2}
                  style={{ width: "100%" }}
                />
              }
              control={control}
              name="depositAmount"
              rules={{
                required: {
                  value: true,
                  message: "請填寫按金",
                },
                max: {
                  value: maxDepositAmount,
                  message: `可豁免按金上限為 $${maxDepositAmount}`,
                },
              }}
            />
            <Col span={24}>
              {errors?.depositAmount && (
                <Text type="danger">{errors?.depositAmount?.message}</Text>
              )}
            </Col>
          </Col>
        </Row>
      )}
      <Row gutter={[4, 4]}>
        <Col span={10}>備註</Col>
        <Col span={14}>
          <Controller
            as={
              <Input
                maxLength={20}
                placeholder="請填寫少於20字"
                style={{ width: "100%" }}
              />
            }
            control={control}
            name="remark"
          />
        </Col>
      </Row>
    </Modal>
  );
};

ExemptAmountModal.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  params: PropTypes.object,
  listType: PropTypes.string,
};

const CreatePenaltyModal = ({
  visible,
  onOk = () => {},
  onCancel = () => {},
  params = {},
}) => {
  const { control, handleSubmit, errors } = useForm();

  const [
    createOrderingItemPenalty,
    { loading: createOrderingItemPenaltyLoading },
  ] = useMutation(CREATE_ORDERINGITEM_PENALTY);

  const submit = async (input) => {
    try {
      await createOrderingItemPenalty({
        variables: {
          orderingItemId: params.item.id,
          amount: input.amount * 100,
          remark: input.remark,
        },
      });
      notification.success({
        message: "成功新增其他收費",
      });
      onOk();
    } catch (err) {
      console.log(err);
      notification.error({
        message: "新增其他收費失敗",
        description: err?.message,
        duration: 5,
      });
    }
  };

  return (
    <Modal
      visible={visible}
      title="新增其他收費"
      confirmLoading={createOrderingItemPenaltyLoading}
      onOk={handleSubmit(submit)}
      okText="確定"
      onCancel={onCancel}
      cancelText="取消"
    >
      <Row gutter={[8, 8]}>
        <Col span={6}>項目: </Col>
        <Col span={18}>{params.item?.product?.customReferenceId}</Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={6}>備註: </Col>
        <Col span={18}>
          <Controller
            as={<Input />}
            name="remark"
            control={control}
            rules={{ required: true }}
            defaultValue=""
          />
          <Col span={24}>
            {errors?.remark && <Text type="danger">請填寫備註</Text>}
          </Col>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={6}>金額 (港幣): </Col>
        <Col span={18}>
          <Controller
            as={<InputNumber min={0} precision={2} />}
            name="amount"
            control={control}
            rules={{ min: 0, required: true }}
            defaultValue={null}
          />
          <Col span={24}>
            {errors?.amount && <Text type="danger">請填寫金額</Text>}
          </Col>
        </Col>
      </Row>
    </Modal>
  );
};
CreatePenaltyModal.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  params: PropTypes.object,
};

const OrderingItem = ({
  batchEdit,
  isBatchEdit,
  setIsBatchEdit,
  batchEditText,
  setBatchEditText,
  selectedOrderingItems,
  setSelectedOrderingItems,
  selectedOtherOrderingItems,
  setSelectedOtherOrderingItems,
  orderingTerminationDrawerParams,
  setOrderingTerminationDrawerParams,
  changeEquipmentDrawerParams,
  setChangeEquipmentDrawerParams,
  batchDelivery,
  batchReclaim,
  batchChangeEquipment,
  batchEditMode,
  updateDeliveryParams,
  batchTerminateOrderingItem,
  paymentPreviewVisible,
  setPaymentPreviewVisible,
  userAddress,
  denyCreatingOrder,
  canChangeEquipment,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();

  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.OPERATION_EQUIPMENT_MANAGEMENT,
  ]);

  const [
    paymentPreviewGet,
    { loading: paymentPreviewGetLoading, data: paymentPreviewGetData },
  ] = useLazyQuery(PAYMENT_PREVIEW_GET, { variables: { userId: id } });

  const refreshPaymentPreview = useCallback(() => {
    paymentPreviewGet();
  }, [paymentPreviewGet]);

  const paymentPreviewData = useMemo(
    () => paymentPreviewGetData?.paymentStatementPreviewGet,
    [paymentPreviewGetData]
  );

  useEffect(() => {
    if (paymentPreviewData) {
      axios({
        url: paymentPreviewData.url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", paymentPreviewData?.name);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
      });
    } else if (paymentPreviewData === null) {
      message.info("沒有帳單");
    }
  }, [paymentPreviewData]);

  const { Text } = Typography;

  const accountFreezed = denyCreatingOrder;

  const query = useMemo(() => {
    const { page, pageSize } = queryString.parse(location.search);
    return {
      ...queryString.parse(location.search),
      page: page ? Number(page) : 1,
      // pageSize: pageSize ? Number(pageSize) : PAGE_SIZE,
      pageSize: PAGE_SIZE,
    };
  }, [location.search]);

  const [viewDamageReportsDrawerParams, setViewDamageReportsDrawerParams] =
    useState({ visible: false });
  const [deleteOrderingItemParams, setDeleteOrderingItemParams] = useState({
    isShowDeleteModal: false,
    itemId: null,
  });
  const [changeOrderingItemParams, setChangeOrderingItemParams] = useState({
    visible: false,
    itemId: null,
  });

  const [inventoryRecordId, setInventoryRecordId] = useState("");

  const [
    changeEquipmentUpdateDrawerParams,
    setChangeEquipmentUpdateDrawerParams,
  ] = useState({
    visible: false,
    orderingItem: null,
  });

  const indexCustomReferenceId = (title = "器材") => ({
    title,
    width: "8%",
    render: (item) => {
      let link = "/";
      let customReferenceId = "";
      switch (item?.product?.__typename) {
        case PRODUCT_TYPE.device:
        case PRODUCT_TYPE.part: {
          link = replaceUrlByParams(PAGE.STOCK_INVENTORY_DETAIL, {
            inventoryId: item?.inventoryRecord[0]?.id,
          });
          customReferenceId = item?.inventoryRecord[0]?.referenceId;
          break;
        }
        case PRODUCT_TYPE.consumable: {
          link = replaceUrlByParams(PAGE.STOCK_DEVICE_DETAIL, {
            id: item?.product?.id,
          });
          customReferenceId = item?.inventoryRecord[0]?.referenceId;
          break;
        }
        case PRODUCT_TYPE.misc:
        case PRODUCT_TYPE.service: {
          link = replaceUrlByParams(PAGE.STOCK_SERVICE_DETAIL, {
            id: item.product.id,
            mode: "view",
          });
          customReferenceId = item?.product?.referenceId;
          break;
        }
        default:
          break;
      }
      return (
        <Link
          key={item.id}
          style={{ textDecoration: "underline" }}
          className={`link`}
          to={link}
        >
          {customReferenceId}
        </Link>
      );
    },
  });

  const indexCustomModelId = (title = "器材款號") => ({
    title,
    width: "0%",
    // dataIndex: "product.customModelId",
    render: (item) => {
      if (item.product?.referenceId) {
        return (
          <Link
            style={{ textDecoration: "underline" }}
            key={item.id}
            className={`link`}
            to={replaceUrlByParams(PAGE.STOCK_DEVICE_DETAIL, {
              id: item.product.id,
            })}
          >
            {item.product?.referenceId}
          </Link>
        );
      } else {
        return <>-</>;
      }
    },
  });

  const indexServiceStartDate = {
    title: "實際租借開始日",
    width: "0%",
    dataIndex: "serviceStartDate",
    render: (date, item) => {
      let returnDate = null;
      switch (item.product?.__typename) {
        case PRODUCT_TYPE.device:
        case PRODUCT_TYPE.part:
          returnDate = date;
          break;
        default:
          break;
      }
      return (
        <span key={item.id}>
          {returnDate ? moment(returnDate).format(MOMENT_FORMAT) : "-"}
        </span>
      );
    },
  };

  const indexServiceEndDate = {
    title: "實際租借結束日",
    width: "0%",
    dataIndex: "serviceEndDate",
    render: (date, item) => {
      let returnDate = null;
      switch (item.product?.__typename) {
        case PRODUCT_TYPE.device:
        case PRODUCT_TYPE.part:
          returnDate = date;
          break;
        default:
          break;
      }
      return (
        <div key={item.id}>
          <div>
            <span>
              {returnDate ? moment(returnDate).format(MOMENT_FORMAT) : "-"}
            </span>
          </div>
          <div>
            <span style={{ color: "grey" }}>
              {!!item?.replacementDate &&
                (item?.freeReplacement ? "免費更換" : "收費更換")}
            </span>
          </div>
        </div>
      );
    },
  };

  const indexReplacementDate = {
    title: "更換日期",
    width: "0%",
    dataIndex: "replacementDate",
    render: (date, item) => {
      return (
        <span key={item.id}>
          {date ? moment(date).format(MOMENT_FORMAT) : "-"}
        </span>
      );
    },
  };

  const indexServiceEstimatedStartDate = {
    title: "預計服務日期",
    dataIndex: "serviceEstimatedStartDate",
    render: (date, item) => (
      <span key={item.id}>
        {date ? moment(date).format(MOMENT_FORMAT) : "-"}
      </span>
    ),
  };

  const indexServiceStartDate_ServiceOthers = {
    title: "實際服務日期",
    dataIndex: "serviceStartDate",
    key: "serviceStartDate_ServiceOthers",
    render: (date, item) => {
      let returnDate = date;
      switch (item.product?.__typename) {
        case PRODUCT_TYPE.device:
        case PRODUCT_TYPE.part:
          returnDate = null;
          break;
        default:
          break;
      }
      return (
        <span key={item.id}>
          {returnDate ? moment(returnDate).format(MOMENT_FORMAT) : "-"}
        </span>
      );
    },
  };

  const indexDepositAmount = {
    title: "按金價格 (港幣)",
    dataIndex: "depositAmount",
    render: (amount, item) => (
      <span key={item.id}>{amount ? amountDisplay(amount) : "-"}</span>
    ),
  };

  const indexRentalMonths = {
    title: "租借月",
    dataIndex: "rentalMonth",
    render: (month, item) => {
      return <span key={item.id}>{`第${month}月` ?? "-"}</span>;
    },
  };

  const indexRentalAmount = {
    title: "租金價格 (港幣)",
    dataIndex: "rentalAmount",
    render: (amount, item) => (
      <span key={item.id}>{amount ? amountDisplay(amount) : "-"}</span>
    ),
  };

  const indexSubtotal = {
    title: "小計",
    dataIndex: "subtotal",
    render: (amount, item) => (
      <span key={item.id}>{amountDisplay(amount)}</span>
    ),
  };

  const indexPurchaseAmount = {
    title: "購買價格（港幣）",
    dataIndex: "purchaseAmount",
    render: (amount, item) =>
      IsItemPenaltyOrWaive(item) ? (
        "-"
      ) : (
        <span key={item.id}>{amount ? amountDisplay(amount) : "-"}</span>
      ),
  };

  const indexQuantity = {
    title: "數量",
    dataIndex: "quantity",
    render: (text, item) => {
      let link = "/";
      switch (item.product.__typename) {
        case PRODUCT_TYPE.misc:
          return <div>-</div>;
        default:
      }
      return (
        <div key={item.id} className="link" to={link}>
          {text}
        </div>
      );
    },
  };

  const indexStatus = {
    title: "狀態",
    dataIndex: "status",
    render: (status, item) => {
      let statusTag = <>-</>;
      switch (status.id) {
        case "waiting":
          statusTag = (
            <Tag key={item.id} className="tag">
              {status.name}
            </Tag>
          );
          break;
        case "reserved":
          statusTag = (
            <Tag key={item.id} className="tag warning">
              {status.name}
            </Tag>
          );
          break;
        case "toBeTerminate":
          statusTag = (
            <Tag key={item.id} className="tag danger">
              {status.name}
            </Tag>
          );
          break;
        case "terminated":
          statusTag = (
            <Tag key={item.id} className="tag danger">
              {status.name}
            </Tag>
          );
          break;
        case "inService":
          statusTag = (
            <Tag key={item.id} className="tag success">
              {status.name}
            </Tag>
          );
          break;
        case "unpaidInFirstPayment":
          statusTag = (
            <Tag key={item.id} className="tag warning">
              {status.name}
            </Tag>
          );
          break;
        case "prepareForChange":
          statusTag = (
            <Tag key={item.id} className="tag warning">
              {status.name}
            </Tag>
          );
          break;
        case "waitingForChange":
          statusTag = (
            <Tag key={item.id} className="tag">
              {status.name}
            </Tag>
          );
          break;
        case "reservedForChange":
          statusTag = (
            <Tag key={item.id} className="tag warning">
              {status.name}
            </Tag>
          );
          break;
        default:
          statusTag = <>-</>;
          break;
      }

      if (item.delivery?.deliveryStatus) {
        let deliveryStatusIcon;
        switch (item.delivery.deliveryStatus.id) {
          case "pending":
          case "confirmedDelivery":
          case "inventoryReady":
          case "checkedOut": {
            if (item.delivery.type.id === "delivery") {
              deliveryStatusIcon = (
                <Tooltip
                  key={item.id}
                  title={`送貨${item.delivery.deliveryStatus.name}`}
                >
                  <img
                    src={icon_truck_delivery_requested}
                    alt="icon_truck_delivery_requested"
                    style={{
                      width: "36px",
                      height: "36px",
                      marginLeft: "16px",
                    }}
                  />
                </Tooltip>
              );
            } else {
              deliveryStatusIcon = (
                <Tooltip
                  key={item.id}
                  title={`收回${item.delivery.deliveryStatus.name}`}
                >
                  <img
                    src={icon_truck_reclaim_requested}
                    alt="icon_truck_reclaim_requested"
                    style={{
                      width: "36px",
                      height: "36px",
                      marginLeft: "16px",
                    }}
                  />
                </Tooltip>
              );
            }
            break;
          }
          case "completed": {
            if (item.delivery.type.id === "delivery") {
              deliveryStatusIcon = (
                <Tooltip
                  key={item.id}
                  title={`送貨${item.delivery.deliveryStatus.name}`}
                >
                  <img
                    src={icon_truck_delivery_completed}
                    alt="icon_truck_delivery_completed"
                    style={{
                      width: "36px",
                      height: "36px",
                      marginLeft: "16px",
                    }}
                  />
                </Tooltip>
              );
            } else {
              deliveryStatusIcon = (
                <Tooltip
                  key={item.id}
                  title={`收回${item.delivery.deliveryStatus.name}`}
                >
                  <img
                    src={icon_truck_reclaim_completed}
                    alt="icon_truck_reclaim_completed"
                    style={{
                      width: "36px",
                      height: "36px",
                      marginLeft: "16px",
                    }}
                  />
                </Tooltip>
              );
            }
            break;
          }
          case "cancelled": {
            if (item.delivery.type.id === "delivery") {
              deliveryStatusIcon = (
                <Tooltip
                  key={item.id}
                  title={`送貨${item.delivery.deliveryStatus.name}`}
                >
                  <img
                    src={icon_truck_delivery_cancelled}
                    alt="icon_truck_delivery_cancelled"
                    style={{
                      width: "36px",
                      height: "36px",
                      marginLeft: "16px",
                    }}
                  />
                </Tooltip>
              );
            } else {
              deliveryStatusIcon = (
                <Tooltip
                  key={item.id}
                  title={`收回${item.delivery.deliveryStatus.name}`}
                >
                  <img
                    src={icon_truck_reclaim_cancelled}
                    alt="icon_truck_reclaim_cancelled"
                    style={{
                      width: "36px",
                      height: "36px",
                      marginLeft: "16px",
                    }}
                  />
                </Tooltip>
              );
            }
            break;
          }
          default: {
            deliveryStatusIcon = <></>;
            break;
          }
        }
        return (
          <div key={item.id}>
            {statusTag}
            {deliveryStatusIcon}
          </div>
        );
      } else {
        return statusTag;
      }
    },
  };

  useEffect(() => {
    if (query.damageReportList)
      setViewDamageReportsDrawerParams({
        visible: true,
        orderingItemId: query.damageReportList,
      });
  }, [query.damageReportList]);

  const indexPaymentStatus = {
    title: "付款狀態",
    dataIndex: "paymentStatus",
    render: (paymentStatus, record) => {
      if (paymentStatus?.id) {
        const tags = {
          pending: <Tag className="tag warning">{paymentStatus.name}</Tag>,
          paid: <Tag className="tag success">{paymentStatus.name}</Tag>,
          paidManually: <Tag className="tag success">{paymentStatus.name}</Tag>,
          overdue: <Tag className="tag error">{paymentStatus.name}</Tag>,
          confirmed: <Tag className="tag success">{paymentStatus.name}</Tag>,
          toBeRefunded: <Tag className="tag warning">{paymentStatus.name}</Tag>,
          refunded: <Tag className="tag warning">{paymentStatus.name}</Tag>,
        }[paymentStatus.id];

        return <div>{tags}</div>;
      } else return <span> - </span>;
    },
  };

  const indexAction = {
    dataIndex: "action",
    render: (text, item) => {
      const onMenuItemClick = ({ key, item }) => {
        switch (key) {
          case "edit": {
            editOrderingItem(item);
            break;
          }
          case "check_item": {
            setAvailablePartDrawerParams({
              userId: id,
              id: item.product.id,
              orderId: item.id,
              type: item.product.__typename,
              visible: true,
              onClose: () => setAvailablePartDrawerParams({ visible: false }),
              onSubmit: () => getItems(),
            });
            break;
          }
          case "check_TerminateContract": {
            setOrderingTerminationDrawerParams({
              type: item.product.__typename,
              orderingTermination: item.orderingTermination,
              orderingItemId: item.id,
              orderingItemStatusId: item.status.id,
              visible: true,
              isDisable: true,
              onClose: () =>
                setOrderingTerminationDrawerParams((prevState) => ({
                  ...prevState,
                  visible: false,
                })),
            });
            break;
          }
          case "exempt_amount": {
            setIsShowExemptAmountModal(true);
            orderingItemWaiveLimit({ variables: { orderingItemId: item.id } });
            setExemptParams((prevState) => ({
              ...prevState,
              orderingItemId: item.id,
            }));
            break;
          }
          case "delete_item": {
            setDeleteOrderingItemParams({
              isShowDeleteModal: true,
              itemId: item.id,
            });
            break;
          }
          case "change_item": {
            setChangeOrderingItemParams({
              visible: true,
              itemId: item.id,
            });
            break;
          }
          case "replace_item": {
            setCreateOrderingItemsDrawerParams({
              visible: true,
              isCreate: false,
              mode: item.product.__typename,
              orderingItemId: item.id,
            });
            break;
          }
          case "create_item_damage_report": {
            history.replace({
              search: queryString.stringify({
                ...query,
                damageReportList: item.id,
              }),
            });
            setInventoryRecordId(item.inventoryRecord[0].id);
            break;
          }
          case "create_penalty": {
            setIsShowCreatePenaltyModal(true);
            setCreatePenaltyParams({ item });
            break;
          }
          case "update_change_equipment": {
            setChangeEquipmentUpdateDrawerParams({
              visible: true,
              orderingItem: item,
            });
            break;
          }
          case "view_change_equipment": {
            setChangeEquipmentUpdateDrawerParams({
              visible: true,
              orderingItem: item,
              isReadOnly: true,
            });
            break;
          }
          default: {
            break;
          }
        }
      };
      const checkItemDisabled = (item) => {
        switch (item.product?.__typename) {
          case "Consumable":
          case "Service": {
            return true;
          }
          default:
            return false;
        }
      };
      const checkExemptAmountDisabled = (item) => {
        if (
          query?.listType === "terminated" ||
          item.status.id === "waiting" ||
          item.subtotal <= 0
        ) {
          return true;
        }
        return false;
      };

      const checkDeleteItemDisabled = (item) => !item?.deletable;
      const menu = (item) => {
        const deleteItem = (
          <Menu.Item key="delete_item" disabled={checkDeleteItemDisabled(item)}>
            刪除項目
          </Menu.Item>
        );
        const changeItem = (
          <Menu.Item
            key="change_item"
            disabled={
              !(
                item?.status?.id === "reserved" &&
                item?.paymentStatus?.id === "paid"
              )
            }
          >
            更換項目
          </Menu.Item>
        );
        const edit = <Menu.Item key="edit">編輯</Menu.Item>;
        const checkItem = (
          <Menu.Item key="check_item" disabled={checkItemDisabled(item)}>
            查看適用貨物/零件/消耗品
          </Menu.Item>
        );
        const checkTerminateContract = (
          <Menu.Item
            key="check_TerminateContract"
            disabled={
              !(
                item?.status?.id === ORDERING_ITEM_LIST_TYPE.terminated ||
                item?.status?.id === ORDERING_ITEM_LIST_TYPE.toBeTerminate
              ) ||
              checkItemDisabled(item) ||
              !item.orderingTermination
            }
          >
            查看終止租借合約内容
          </Menu.Item>
        );
        const exemptAmount = (
          <Menu.Item
            key="exempt_amount"
            disabled={checkExemptAmountDisabled(item)}
          >
            豁免項目收費
          </Menu.Item>
        );
        const createItemDamageReport = (
          <Menu.Item key="create_item_damage_report">
            查看器材損毀報告
          </Menu.Item>
        );

        const createPenalty =
          item?.product?.__typename === PRODUCT_TYPE.device ? (
            <Menu.Item key="create_penalty">新增其他收費</Menu.Item>
          ) : null;

        const isOldItem =
          orderingItemReplaceIdPairs?.headItems?.find(
            ({ oldItemId }) => oldItemId === item.id
          ) ||
          orderingItemReplaceIdPairs?.partItems?.find(
            ({ oldItemId }) => oldItemId === item.id
          );
        const updateChangeEquipment = (
          <Menu.Item
            key="update_change_equipment"
            disabled={
              !["reservedForChange", "prepareForChange"].includes(
                item?.status?.id
              ) || isOldItem
            }
          >
            更新更換器材紀錄
          </Menu.Item>
        );

        const viewChangeEquipment = (
          <Menu.Item
            key="view_change_equipment"
            disabled={!item?.replacementDate}
          >
            查看額外更換內容
          </Menu.Item>
        );

        let list = [];

        switch (query?.listType) {
          case ORDERING_ITEM_LIST_TYPE.current:
            list = [
              edit,
              changeItem,
              deleteItem,
              createItemDamageReport,
              checkItem,
              checkTerminateContract,
              exemptAmount,
              createPenalty,
              updateChangeEquipment,
            ];
            break;
          case ORDERING_ITEM_LIST_TYPE.other:
            list = [
              edit,
              changeItem,
              deleteItem,
              createItemDamageReport,
              checkItem,
              exemptAmount,
            ];
            break;
          case ORDERING_ITEM_LIST_TYPE.terminated:
            list = [checkTerminateContract, createPenalty, viewChangeEquipment];
            break;
          default:
            console.error("unsxpected listType");
            break;
        }
        if (IsItemPenaltyOrWaive(item)) {
          list = [deleteItem];
        }

        return (
          <Menu
            onClick={({ key }) => {
              onMenuItemClick({ key, item: item });
            }}
          >
            {list}
          </Menu>
        );
      };

      return (
        !isDenied && (
          <Dropdown
            overlay={menu(item)}
            placement="bottomRight"
            trigger={["click"]}
          >
            <Button className="btn btn-secondary">
              <Icon type="ellipsis" />
            </Button>
          </Dropdown>
        )
      );
    },
  };

  const [
    getOrderingItems,
    {
      data: {
        orderingGet: { orderingItem = [], otherOrderingItem = [] } = {},
      } = {},
      loading: orderingItemLoading,
    },
  ] = useLazyQuery(ORDERING_ITEM_LIST);

  const [
    getTerminatedOrderingItems,
    {
      data: { orderingGet: { terminatedOrderingItem = [] } = {} } = {},
      loading: terminatedOrderingItemLoading,
    },
  ] = useLazyQuery(TERMINATED_ORDERING_ITEM_LIST);

  const [
    deleteOrderingItem,
    { loading: deleteOrderingItemLoading, data: deleteOrderingItemData },
  ] = useMutation(ORDERING_ITEM_DELETE);

  const [
    firstPaymentCreate,
    { loading: firstPaymentCreateLoading, data: firstPaymentCreateData },
  ] = useMutation(FIRST_PAYMENT_CREATE);

  const [
    orderingItemWaiveLimit,
    {
      called: orderingItemWaiveLimitCalled,
      loading: orderingItemWaiveLimitLoading,
      data: orderingItemWaiveLimitData,
    },
  ] = useLazyQuery(ORDERING_ITEM_WAIVE_LIMIT);
  const [
    orderingItemWaive,
    { loading: orderingItemWaiveLoading, data: orderingItemWaiveData },
  ] = useMutation(ORDERING_ITEM_WAIVE);

  // const [
  //   accountWaiveLimit,
  //   {
  //     called: accountWaiveLimitCalled,
  //     loading: accountWaiveLimitLoading,
  //     data: accountWaiveLimitData,
  //   },
  // ] = useLazyQuery(ACCOUNT_WAIVE_LIMIT);
  // const [
  //   accountWaive,
  //   { loading: accountWaiveLoading, data: accountWaiveData },
  // ] = useMutation(ACCOUNT_WAIVE);

  const [
    reservationDueDateUpdate,
    { loading: reservationDueDateLoading, data: reservationDueDateData },
  ] = useMutation(UPDATING_RESERVATIOON_DUE_DATE);

  const [getReservationDueDate, { data: getReservationDueDateData }] =
    useLazyQuery(ORDERING_RESERVATION_DUE_DATE, {
      variables: {
        userId: id,
      },
    });

  const [createLastPayment, { loading: createLastPaymentLoading }] =
    useMutation(CREATE_LASTPAYMENT, {
      onCompleted: (data) => {
        if (data) {
          history.replace({
            pathname: location.pathname,
            search: queryString.stringify({
              tabKey: "payment",
            }),
          });
          message.success("成功產生最後付款通知");
        }
      },
    });

  const [orderingItemChange, { loading: orderingItemChangeLoading }] =
    useMutation(ORDERING_ITEM_CHANGE);

  const getOrderingItemReplaceIdPairs = useCallback((data) => {
    const replaceIdsPairs = [];
    const replacePartIdsPairs = [];

    const sortPairListByNewItems = (pairList) => {
      for (let i = 0; i < pairList.length; i++) {
        for (let j = 0; j < pairList.length; j++) {
          if (pairList[i].oldItemId === pairList[j].newItemId) {
            const temp = pairList[j];
            pairList[j] = pairList[i];
            pairList[i] = temp;
          }
        }
      }
    };

    data.forEach((item) => {
      if (item.replaceFrom) {
        let pair = {
          oldItemId: item.replaceFrom.id,
          newItemId: item.id,
        };
        replaceIdsPairs.push(pair);
      }
      if (item.partOrderingItem) {
        for (const part of item.partOrderingItem) {
          if (part.replaceFrom) {
            const partPair = {
              parentId: item.id,
              oldItemId: part.replaceFrom.id,
              newItemId: part.id,
            };
            replacePartIdsPairs.push(partPair);
          }
        }
      }
    });

    sortPairListByNewItems(replaceIdsPairs);
    sortPairListByNewItems(replacePartIdsPairs);

    return { headItems: replaceIdsPairs, partItems: replacePartIdsPairs };
  }, []);

  const orderingItemReplaceIdPairs = useMemo(() => {
    switch (query?.listType) {
      case ORDERING_ITEM_LIST_TYPE.current:
        return getOrderingItemReplaceIdPairs(orderingItem);
      case ORDERING_ITEM_LIST_TYPE.other:
        return getOrderingItemReplaceIdPairs(otherOrderingItem);
      case ORDERING_ITEM_LIST_TYPE.terminated:
        return getOrderingItemReplaceIdPairs(terminatedOrderingItem);
      default:
        return [];
    }
  }, [
    query,
    getOrderingItemReplaceIdPairs,
    orderingItem,
    otherOrderingItem,
    terminatedOrderingItem,
  ]);

  const customOrderingItem = useCallback(
    (orderingItems, listType) => {
      orderingItems.forEach((item) => {
        switch (item.product?.__typename) {
          case PRODUCT_TYPE.device: {
            item.product.customReferenceId =
              item.inventoryRecord[0]?.referenceId;
            item.product.customModelId = item.product?.referenceId;
            item.product.customName = item.product.productInfo?.nameChi;
            item.key = item.id;
            break;
          }
          case PRODUCT_TYPE.part: {
            item.product.customReferenceId =
              item.inventoryRecord[0]?.referenceId;
            item.product.customModelId = item.product?.referenceId;
            item.product.customName = item.product.productInfo?.nameChi;
            item.key = item.id;
            break;
          }
          case PRODUCT_TYPE.consumable: {
            item.product.customReferenceId =
              item.inventoryRecord[0]?.referenceId;
            item.product.customModelId = item.product?.referenceId;
            item.product.customName = item.product.productInfo?.nameChi;
            item.key = item.id;
            break;
          }
          case PRODUCT_TYPE.misc:
          case PRODUCT_TYPE.service: {
            item.product.customReferenceId = item.product?.referenceId;
            item.product.customName = item.product?.nameChi;
            item.key = item.id;
            break;
          }
          default:
            break;
        }
        if (item.partOrderingItem) {
          if (item.partOrderingItem.length === 0) item.partOrderingItem = null;
          else
            item.partOrderingItem.forEach((part) => {
              part.key = part.id;
              part.parentId = item.id;
            });
          item.children = item.partOrderingItem;
        }
      });

      //sort orderingItem by replaceFrom & replacer
      let sortedData = [];

      if (listType !== ORDERING_ITEM_LIST_TYPE.terminated) {
        const sortByPairs = (itemList, pairList) => {
          const pairItems = [];
          for (const pair of pairList) {
            const newItem = itemList.find(
              (record) => record.id === pair.newItemId
            );
            const oldItem = itemList.find(
              (record) => record.id === pair.oldItemId
            );
            if (newItem && !pairItems.includes(newItem))
              pairItems.push(newItem);
            if (oldItem && !pairItems.includes(oldItem))
              pairItems.push(oldItem);
          }

          const normalItems = itemList.filter(
            (record) => !pairItems.includes(record)
          );

          return [...pairItems, ...normalItems];
        };

        sortedData = sortByPairs(
          [...orderingItems],
          orderingItemReplaceIdPairs.headItems
        );
        sortedData.forEach((item) => {
          if (item.partOrderingItem) {
            item.partOrderingItem = sortByPairs(
              item.partOrderingItem,
              orderingItemReplaceIdPairs.partItems
            );
          }
        });
      } else {
        sortedData = [...orderingItems];

        sortedData.sort((a, b) => {
          if (a.serviceStartDate === null) return 1;
          if (b.serviceStartDate === null) return -1;

          if (a.serviceStartDate > b.serviceStartDate) return -1;
          if (a.serviceStartDate < b.serviceStartDate) return 1;
          if (a.serviceStartDate === b.serviceStartDate)
            if (a.product.customReferenceId > b.product.customReferenceId)
              return 1;
            else return -1;

          return 0;
        });
      }

      return sortedData;
    },
    [orderingItemReplaceIdPairs]
  );

  const orderingItemData = useMemo(() => {
    switch (query?.listType) {
      case ORDERING_ITEM_LIST_TYPE.current:
        return customOrderingItem(orderingItem, query?.listType);
      case ORDERING_ITEM_LIST_TYPE.other:
        return customOrderingItem(otherOrderingItem, query?.listType);
      case ORDERING_ITEM_LIST_TYPE.terminated:
        return customOrderingItem(terminatedOrderingItem, query?.listType);
      default:
        return [];
    }
  }, [
    query,
    customOrderingItem,
    orderingItem,
    otherOrderingItem,
    terminatedOrderingItem,
  ]);

  const indexCustomName = (title = "器材名稱") => ({
    title,
    width: "18%",
    render: (item) => {
      let customName = "";
      switch (item?.product?.__typename) {
        case PRODUCT_TYPE.device:
        case PRODUCT_TYPE.part:
        case PRODUCT_TYPE.consumable: {
          customName = item.product.productInfo?.nameChi;
          break;
        }
        case PRODUCT_TYPE.misc:
        case PRODUCT_TYPE.service: {
          customName = item.product?.nameChi;
          break;
        }
        default:
          break;
      }
      return <span style={{ whiteSpace: "pre" }}>{customName}</span>;
    },
  });

  const orderingItemCols = [
    indexCustomReferenceId("器材"),
    indexCustomModelId("器材款號"),
    indexCustomName("器材名稱"),
    indexRentalMonths,
    indexServiceStartDate,
    indexReplacementDate,
    indexRentalAmount,
    indexDepositAmount,
    indexStatus,
    indexPaymentStatus,
    indexAction,
  ];

  const otherOrderingItemCols = [
    indexCustomReferenceId("服務"),
    indexCustomModelId("服務款號"),
    indexCustomName("服務名稱"),
    indexPurchaseAmount,
    indexServiceEstimatedStartDate,
    indexServiceStartDate_ServiceOthers,
    indexQuantity,
    indexSubtotal,
    indexPaymentStatus,
    indexAction,
  ];

  const terminatedOrderingItemCols = [
    indexCustomReferenceId("器材/服務"),
    indexCustomModelId("器材/服務款號"),
    indexCustomName("器材/服務名稱"),
    indexRentalMonths,
    indexServiceStartDate,
    indexServiceEndDate,
    indexServiceEstimatedStartDate,
    indexServiceStartDate_ServiceOthers,
    indexQuantity,
    indexAction,
  ];

  const orderingItemColumns = useMemo(() => {
    switch (query?.listType) {
      case ORDERING_ITEM_LIST_TYPE.current:
        return orderingItemCols;
      case ORDERING_ITEM_LIST_TYPE.other:
        return otherOrderingItemCols;
      case ORDERING_ITEM_LIST_TYPE.terminated:
        return terminatedOrderingItemCols;
      default:
        return [];
    }
  }, [
    orderingItemCols,
    otherOrderingItemCols,
    terminatedOrderingItemCols,
    query,
  ]);

  const isMaxPage = useMemo(() => {
    return orderingItemData.length <= PAGE_SIZE;
  }, [orderingItemData]);

  const returnTotalAmount = (itemList) => {
    const totalAmount = itemList.reduce((acc, cur) => {
      let amount = acc + cur.subtotal;
      if (cur.partOrderingItem) {
        amount += cur.partOrderingItem.reduce(
          (acc, cur) => acc + cur.subtotal,
          0
        );
      }
      return amount;
    }, 0);
    return totalAmount;
  };

  const totalOrderingItemAmount = useMemo(() => {
    return returnTotalAmount(orderingItem);
  }, [orderingItem]);

  const totalOtherOrderingItemAmount = useMemo(() => {
    return returnTotalAmount(otherOrderingItem);
  }, [otherOrderingItem]);

  const [availablePartDrawerParams, setAvailablePartDrawerParams] = useState({
    visible: false,
  });

  const [expiryDate, setExpiryDate] = useState(null);
  const [newExpiryDate, setNewExpiryDate] = useState(null);
  const [createOrderingItemsDrawerParams, setCreateOrderingItemsDrawerParams] =
    useState({
      visible: false,
      mode: "Device",
    });
  const [
    isCreateServiceOrderingItemsDrawerShow,
    setIsCreateServiceOrderingItemsDrawerShow,
  ] = useState(false);

  const [contractDrawerVisible, setContractDrawerVisible] = useState(false);

  const [orderingItemEditParams, setOrderingItemEditParams] = useState({
    visible: false,
  });

  const [isShowExemptAmountModal, setIsShowExemptAmountModal] = useState(false);
  const [exemptParams, setExemptParams] = useState({
    orderingItemId: undefined,
    userId: undefined,
    isExempting: false,
    amount: undefined,
    depositAmount: undefined,
    remark: undefined,
    maxAmount: undefined,
    maxDepositAmount: undefined,
  });

  const [isShowCreatePenaltyModal, setIsShowCreatePenaltyModal] =
    useState(false);
  const [createPenaltyParams, setCreatePenaltyParams] = useState({
    item: undefined,
  });

  useEffect(() => {
    if (query?.listType) {
      getItems();
    }

    // eslint-disable-next-line
  }, [id, query]);

  useEffect(() => {
    getReservationDueDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getReservationDueDateData?.orderingGet?.reservationDueDate) {
      const reservationDueDate =
        getReservationDueDateData.orderingGet.reservationDueDate;
      setExpiryDate(reservationDueDate);
    }
  }, [getReservationDueDateData]);

  const getItems = useCallback(() => {
    switch (query?.listType) {
      case ORDERING_ITEM_LIST_TYPE.current:
      case ORDERING_ITEM_LIST_TYPE.other:
        getOrderingItems({
          variables: {
            userId: id,
          },
        });
        break;
      case ORDERING_ITEM_LIST_TYPE.terminated:
        getTerminatedOrderingItems({
          variables: {
            userId: id,
            size: query.pageSize + 1,
            offset: (query.page - 1) * query.pageSize,
          },
        });
        break;
      default:
    }
  }, [getOrderingItems, getTerminatedOrderingItems, id, query]);

  const onDeleteItem = useCallback(
    async (id) => {
      try {
        await deleteOrderingItem({
          variables: {
            orderingItemId: id,
          },
        });
        message.success("成功刪除項目");
        getItems();
      } catch (error) {
        message.error(error.message);
      }
    },
    [deleteOrderingItem, getItems]
  );

  const onChangeItem = useCallback(
    async (id) => {
      try {
        await orderingItemChange({
          variables: {
            orderingItemId: id,
          },
        });
        message.success("成功更換項目");
        getItems();
      } catch (error) {
        message.error(error.message);
      }
    },
    [getItems, orderingItemChange]
  );

  const onViewDamageReportsDrawerClose = () => {
    setViewDamageReportsDrawerParams({ visible: false });
    history.replace({
      search: queryString.stringify({
        ...query,
        damageReportList: undefined,
      }),
    });
    getItems();
  };

  const onCreateDamageReportDrawerSubmit = () => {
    onViewDamageReportsDrawerClose();
    getItems();
  };

  const onCreateOrderingItemsDrawerClose = () => {
    setCreateOrderingItemsDrawerParams({ visible: false });
    getItems();
  };

  const onCreateOrderingItemsDrawerSubmit = () => {
    onCreateOrderingItemsDrawerClose();
    getReservationDueDate();
    getItems();
  };

  const onCreateServiceOrderingItemsDrawerClose = () => {
    setIsCreateServiceOrderingItemsDrawerShow(false);
    getItems();
  };

  const onCreateServiceOrderingItemsDrawerSubmit = () => {
    onCreateServiceOrderingItemsDrawerClose();
    getItems();
  };

  const editOrderingItem = (orderingItem) => {
    setOrderingItemEditParams({
      orderingItem: orderingItem,
      visible: true,
      onClose: () => {
        setOrderingItemEditParams({ visible: false });
      },
      onSuccess: onSuccess,
    });
  };

  const onSuccess = () => {
    setOrderingItemEditParams({ visible: false });
    getItems();
  };

  const resetExemptAmountParams = () => {
    setExemptParams({
      orderingItemId: undefined,
      userId: undefined,
      isExempting: false,
      amount: undefined,
      depositAmount: undefined,
      remark: undefined,
      maxAmount: undefined,
      maxDepositAmount: undefined,
    });
    setIsShowExemptAmountModal(false);
  };

  // useEffect(() => {
  //   if (
  //     accountWaiveLimitCalled &&
  //     !accountWaiveLimitLoading &&
  //     accountWaiveLimitData
  //   ) {
  //     setExemptParams((prevState) => ({
  //       ...prevState,
  //       maxAmount: accountWaiveLimitData?.accountWaiveLimitGet?.amount / 100,
  //       maxDepositAmount:
  //         accountWaiveLimitData?.accountWaiveLimitGet?.depositAmount / 100,
  //     }));
  //   }
  // }, [
  //   accountWaiveLimitCalled,
  //   accountWaiveLimitData,
  //   accountWaiveLimitLoading,
  // ]);

  useEffect(() => {
    if (
      orderingItemWaiveLimitCalled &&
      !orderingItemWaiveLimitLoading &&
      orderingItemWaiveLimitData
    ) {
      setExemptParams((prevState) => ({
        ...prevState,
        maxAmount:
          orderingItemWaiveLimitData?.orderingItemWaiveLimitGet?.amount / 100,
        maxDepositAmount:
          orderingItemWaiveLimitData?.orderingItemWaiveLimitGet?.depositAmount /
          100,
      }));
    }
  }, [
    orderingItemWaiveLimitCalled,
    orderingItemWaiveLimitData,
    orderingItemWaiveLimitLoading,
  ]);

  const handleExemptAmountModalOk = useCallback(
    async (data) => {
      setExemptParams((prevState) => ({ ...prevState, isExempting: true }));
      try {
        if (exemptParams.orderingItemId) {
          await orderingItemWaive({
            variables: {
              orderingItemId: exemptParams?.orderingItemId,
              amount: data.amount * 100,
              depositAmount: data?.depositAmount * 100,
              remark: data?.remark,
            },
          });
        } else if (exemptParams.userId) {
          // await accountWaive({
          //   variables: {
          //     userId: exemptParams?.userId,
          //     amount: data.amount * 100,
          //     depositAmount: data?.depositAmount * 100,
          //     remark: data?.remark,
          //   },
          // });
        }
        message.success("成功調整金額");
        getItems();
      } catch (error) {
        message.error(error.message);
      }
      resetExemptAmountParams();
    },
    [exemptParams, getItems, orderingItemWaive]
  );

  const handleReservationDueDate = useCallback(async () => {
    if (newExpiryDate && id) {
      try {
        await reservationDueDateUpdate({
          variables: {
            userId: id,
            reservationDueDate: newExpiryDate,
          },
        });
        setExpiryDate(newExpiryDate);
      } catch (error) {
        message.error(error.message);
      }
    }
  }, [reservationDueDateUpdate, newExpiryDate, id]);

  useEffect(() => {
    handleReservationDueDate();
  }, [handleReservationDueDate, newExpiryDate]);

  const handleExemptAmountModalCancel = () => {
    resetExemptAmountParams();
  };

  const createFirstPayment = useCallback(async () => {
    try {
      await firstPaymentCreate({
        variables: {
          userId: id,
        },
      });
      message.success("成功產生預繳通知");
      history.replace({
        pathname: location.pathname,
        search: queryString.stringify({
          tabKey: "payment",
        }),
      });
    } catch (error) {
      message.error(error.message);
    }

    // eslint-disable-next-line
  }, [id, firstPaymentCreate]);

  const onToolbarMenuClick = ({ key }) => {
    switch (key) {
      // case "exempt_amount": {
      //   setIsShowExemptAmountModal(true);
      //   accountWaiveLimit({ variables: { userId: id } });
      //   setExemptParams((prevState) => ({ ...prevState, userId: id }));
      //   break;
      // }
      case "preview_invoice":
        refreshPaymentPreview();
        break;
      case "create_first_payment": {
        createFirstPayment();
        break;
      }
      case "add_delivery": {
        batchDelivery();
        break;
      }
      case "add_reclaim": {
        batchReclaim();
        break;
      }
      case "view_delivery": {
        history.push({
          pathname: PAGE.STOCK_DELIVERY_ALL,
          search: queryString.stringify({
            userId: id,
          }),
        });
        break;
      }
      case "create_last_payment_notice": {
        (async () => {
          try {
            createLastPayment({ variables: { userId: id } });
          } catch (err) {
            // console.log(err);
          }
        })();
        break;
      }
      case "change_equipment": {
        batchChangeEquipment();
        break;
      }
      default: {
        break;
      }
    }
  };

  const toolbarMenu = (
    <Menu
      onClick={({ key }) => {
        onToolbarMenuClick({ key });
      }}
    >
      {/* <Menu.Item
        key="exempt_amount"
        disabled={query?.listType === "terminated" ? true : false}
      >
        豁免訂單收費
      </Menu.Item> */}
      {!isDenied && <Menu.Item key="preview_invoice">預覽帳單</Menu.Item>}
      {!isDenied && (
        <Menu.Item key="create_first_payment" style={{ minWidth: "120px" }}>
          產生預繳通知
        </Menu.Item>
      )}
      {!isDenied && <Menu.Item key="add_delivery">新增送貨預約</Menu.Item>}
      {!isDenied && <Menu.Item key="add_reclaim">新增收回預約</Menu.Item>}
      <Menu.Item key="view_delivery">查看送貨安排</Menu.Item>
      {!isDenied && (
        <Menu.Item key="create_last_payment_notice">產生最後付款通知</Menu.Item>
      )}
      {!isDenied && (
        <Menu.Item disabled={!canChangeEquipment} key="change_equipment">
          額外更換器材
        </Menu.Item>
      )}
    </Menu>
  );

  const onCreateOrderingItemMenuClick = ({ key }) => {
    switch (key) {
      case "create_device": {
        setCreateOrderingItemsDrawerParams({
          visible: true,
          isCreate: true,
          mode: "Device",
        });
        break;
      }
      case "create_part": {
        setCreateOrderingItemsDrawerParams({
          visible: true,
          isCreate: true,
          mode: "Part",
        });
        break;
      }
      case "create_consumable": {
        setCreateOrderingItemsDrawerParams({
          visible: true,
          isCreate: true,
          mode: "Consumable",
        });
        break;
      }
      case "create_service": {
        setIsCreateServiceOrderingItemsDrawerShow(true);
        break;
      }
      default: {
        break;
      }
    }
  };

  const createOrderingItemMenu = (
    <Menu
      onClick={({ key }) => {
        onCreateOrderingItemMenuClick({ key });
      }}
    >
      <Menu.Item key="create_device" style={{ minWidth: "120px" }}>
        器材
      </Menu.Item>
      <Menu.Item key="create_part">零件</Menu.Item>
      <Menu.Item key="create_consumable">消耗品</Menu.Item>
      <Menu.Item key="create_service">服務</Menu.Item>
    </Menu>
  );

  const checkRowSelectionDisabled = (item) => {
    switch (batchEditMode) {
      case "delivery": {
        if (
          item.product?.__typename === "Service" ||
          !["reserved", "reservedForChange"].includes(item.status.id)
        ) {
          return true;
        }
        return false;
      }
      case "terminate": {
        if (item.status.id === "toBeTerminate" && !item.orderingTermination) {
          return false;
        }
        return true;
      }
      case "reclaim": {
        if (item.status.id === "reservedForChange") return true;
        if (
          (item.product?.__typename !== "Service" &&
            item.product?.__typename !== "Consumable") ||
          item.status.id === "prepareForChange"
        ) {
          return false;
        }
        return true;
      }
      case "changeEquipment": {
        if (item?.inventoryRecord?.[0]?.parentInventoryRecord?.id) return true;
        if (
          item?.status?.id === "inService" &&
          item?.paymentStatus?.id === "paid"
        )
          return false;
        return true;
      }
      default: {
        return false;
      }
    }
  };

  const selectedRowKeys = () => {
    switch (query?.listType) {
      case ORDERING_ITEM_LIST_TYPE.current: {
        return selectedOrderingItems.map((x) => x.id);
      }
      case ORDERING_ITEM_LIST_TYPE.other: {
        return selectedOtherOrderingItems.map((x) => x.id);
      }
      default:
        return [];
    }
  };

  const rowSelectionOnChange = (selected) => {
    switch (query?.listType) {
      case ORDERING_ITEM_LIST_TYPE.current: {
        setSelectedOrderingItems(selected);
        break;
      }
      case ORDERING_ITEM_LIST_TYPE.other: {
        setSelectedOtherOrderingItems(selected);
        break;
      }
      default:
        break;
    }
  };

  return (
    <Spin spinning={createLastPaymentLoading}>
      <div className="ordering-items">
        <div className="subtitle">診斷紀錄</div>
        <PrescriptionRecordExpander
          userId={id}
          isDisplay={true}
          currentItems={orderingItemData.map(({ product }) => product?.id)}
        />
        <div className="subtitle">訂單項目</div>
        <div className="toolbar">
          <div className="left">
            {!isDenied && (
              <Dropdown
                overlay={createOrderingItemMenu}
                placement="bottomRight"
                trigger={["click"]}
                disabled={accountFreezed}
              >
                <Button className={!accountFreezed && "add-btn"} size="small">
                  新增項目
                </Button>
              </Dropdown>
            )}
            {viewDamageReportsDrawerParams?.visible && (
              <ViewDamageReportsDrawer
                userId={id}
                visible={viewDamageReportsDrawerParams?.visible}
                // mode={viewDamageReportsDrawerParams?.mode}
                onClose={onViewDamageReportsDrawerClose}
                onCreateDamageReportDrawerSubmit={
                  onCreateDamageReportDrawerSubmit
                }
                onViewDamageReportsDrawerClose={onViewDamageReportsDrawerClose}
                orderingItem={orderingItem}
                currentItems={orderingItem.map(({ product }) => product?.id)}
                isCreate={viewDamageReportsDrawerParams?.isCreate}
                orderingItemId={viewDamageReportsDrawerParams?.orderingItemId}
                viewDamageReportsDrawerParams={viewDamageReportsDrawerParams}
                userAddress={userAddress}
                canCreate={true}
                inventoryRecordId={inventoryRecordId}
              ></ViewDamageReportsDrawer>
            )}
            {createOrderingItemsDrawerParams?.visible && (
              <CreateOrderingItemsDrawer
                userId={id}
                visible={createOrderingItemsDrawerParams?.visible}
                mode={createOrderingItemsDrawerParams?.mode}
                onClose={onCreateOrderingItemsDrawerClose}
                onSubmit={onCreateOrderingItemsDrawerSubmit}
                currentItems={orderingItem.map(({ product }) => product?.id)}
                isCreate={createOrderingItemsDrawerParams?.isCreate}
                orderingItemId={createOrderingItemsDrawerParams?.orderingItemId}
              ></CreateOrderingItemsDrawer>
            )}
            <CreateServiceOrderingItemsDrawer
              userId={id}
              visible={isCreateServiceOrderingItemsDrawerShow}
              onClose={onCreateServiceOrderingItemsDrawerClose}
              onSubmit={onCreateServiceOrderingItemsDrawerSubmit}
              currentItems={orderingItem.map(({ product }) => product?.id)}
            ></CreateServiceOrderingItemsDrawer>
            <Select
              size="small"
              value={query?.listType}
              onChange={(listType) => {
                history.push({
                  pathname: location.pathname,
                  search: queryString.stringify({
                    tabKey: "orderingItem",
                    listType,
                  }),
                });
              }}
            >
              <Select.Option value="current" label="租借項目">
                租借項目
              </Select.Option>
              <Select.Option value="other" label="服務及其他項目">
                服務及其他項目
              </Select.Option>
              <Select.Option value="terminated" label="過往訂單項目">
                過往訂單項目
              </Select.Option>
            </Select>
            <Form.Item
              size="small"
              label="保留預訂項目到期日"
              style={{ width: 130 }}
              className="expiryDate"
            >
              <DatePicker
                size="small"
                placeholder="請選擇日期"
                value={
                  expiryDate ? moment(expiryDate, MOMENT_FORMAT) : undefined
                }
                allowClear={false}
                onChange={(m, datestring) => {
                  if (m) {
                    setNewExpiryDate(m.format("YYYY-MM-DD"));
                  }
                }}
                disabled={
                  isDenied ||
                  !(orderingItem.length > 0 || otherOrderingItem.length > 0)
                }
              ></DatePicker>
            </Form.Item>
            {!isDenied && (
              <>
                <Button
                  size="small"
                  type="danger"
                  onClick={batchTerminateOrderingItem}
                  disabled={accountFreezed}
                >
                  終止訂單項目
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => setContractDrawerVisible(true)}
                >
                  查詢服務協議
                </Button>
              </>
            )}
            <Dropdown
              overlay={toolbarMenu}
              trigger={["click"]}
              disabled={accountFreezed}
            >
              <Button
                className={!accountFreezed && "more-btn"}
                size="small"
                icon="ellipsis"
              ></Button>
            </Dropdown>
          </div>

          <div className="paging-component">
            {query?.listType === ORDERING_ITEM_LIST_TYPE.terminated && (
              <CustPaginationComponent
                isMinPage={query.page === 1}
                isMaxPage={isMaxPage}
                page={query.page}
                // pageSize={query.pageSize || PAGE_SIZE}
                pageSize={PAGE_SIZE}
                onPageChange={(page) => {
                  history.push({
                    pathname: location.pathname,
                    search: queryString.stringify({
                      ...query,
                      page,
                    }),
                  });
                }}
              />
            )}
          </div>
        </div>
        <Table
          tableLayout="auto"
          scroll={{ x: "max-content" }}
          columns={orderingItemColumns}
          dataSource={orderingItemData}
          childrenColumnName="children"
          rowKey={(row) => row.id}
          pagination={false}
          loading={orderingItemLoading || terminatedOrderingItemLoading}
          rowClassName={(record) => {
            let className = "";
            let isParentInPair = { oldItem: false, newItem: false };
            let isRecordInPair = { oldItem: false, newItem: false };

            const getClassName = (pairList, parentFlag, recordFlag) => {
              for (const pair of pairList) {
                if (record.id === pair.oldItemId) recordFlag.oldItem = true;
                if (record.id === pair.newItemId) recordFlag.newItem = true;
                if (record?.parentId === pair.oldItemId)
                  parentFlag.oldItem = true;
                if (record?.parentId === pair.newItemId)
                  parentFlag.newItem = true;
              }

              if (
                (record.parentId && parentFlag.oldItem) ||
                recordFlag.oldItem
              ) {
                className = "oldItem";
              } else if (recordFlag.newItem) {
                className = "newItem";
              }
            };

            getClassName(
              orderingItemReplaceIdPairs.headItems,
              isParentInPair,
              isRecordInPair
            );

            if (record.parentId && !isParentInPair.oldItem) {
              isRecordInPair = { oldItem: false, newItem: false };
              getClassName(
                orderingItemReplaceIdPairs.partItems,
                isParentInPair,
                isRecordInPair
              );
            }

            return className;
          }}
          rowSelection={
            isBatchEdit
              ? {
                  type: "checkbox",
                  selectedRowKeys: selectedRowKeys(),
                  onChange: (selectedRowKeys, selected) => {
                    rowSelectionOnChange(selected);
                  },
                  getCheckboxProps: (item) => ({
                    disabled: checkRowSelectionDisabled(item),
                  }),
                }
              : undefined
          }
        ></Table>
        {isShowExemptAmountModal && (
          <ExemptAmountModal
            visible={isShowExemptAmountModal}
            onOk={handleExemptAmountModalOk}
            onCancel={handleExemptAmountModalCancel}
            params={exemptParams}
            listType={query?.listType}
          />
        )}
        {isShowCreatePenaltyModal && (
          <CreatePenaltyModal
            visible={isShowCreatePenaltyModal}
            onOk={() => {
              setIsShowCreatePenaltyModal(false);
              // history.replace({
              //   search: queryString.stringify({
              //     ...query,
              //     listType: "other",
              //   }),
              // });
            }}
            onCancel={() => setIsShowCreatePenaltyModal(false)}
            params={createPenaltyParams}
          />
        )}

        <Modal
          title="更換訂單項目"
          visible={changeOrderingItemParams?.visible}
          okText="確定"
          cancelText="取消"
          onOk={() => {
            onChangeItem(changeOrderingItemParams.itemId);
            setChangeOrderingItemParams({
              visible: false,
              itemId: null,
            });
          }}
          onCancel={() =>
            setChangeOrderingItemParams({
              visible: false,
              itemId: null,
            })
          }
        >
          <p>確定更換訂單項目?</p>
        </Modal>

        <Modal
          title="刪除訂單項目"
          visible={deleteOrderingItemParams?.isShowDeleteModal}
          okText="確定"
          cancelText="取消"
          onOk={() => {
            onDeleteItem(deleteOrderingItemParams.itemId);
            setDeleteOrderingItemParams({
              isShowDeleteModal: false,
              itemId: null,
            });
          }}
          onCancel={() =>
            setDeleteOrderingItemParams({
              isShowDeleteModal: false,
              itemId: null,
            })
          }
          okButtonProps={{ type: "danger" }}
        >
          <p>確定刪除訂單項目?</p>
        </Modal>
        {contractDrawerVisible && (
          <ContractDrawer
            userId={id}
            visible={contractDrawerVisible}
            onClose={() => {
              setContractDrawerVisible(false);
              getItems();
            }}
          />
        )}
        {/* {paymentPreviewVisible && (
        <PaymentPreview
          userId={id}
          visible={paymentPreviewVisible}
          onClose={() => {
            setPaymentPreviewVisible(false);
            getItems();
          }}
        />
      )} */}
        {orderingTerminationDrawerParams.visible && (
          <CreateOrderingTerminationDrawer
            {...orderingTerminationDrawerParams}
            defaultValues={{}}
            userId={id}
            onClose={() => {
              setOrderingTerminationDrawerParams({
                visible: false,
                orderingItemId: [],
              });
              getItems();
            }}
            onSuccess={() => {
              setOrderingTerminationDrawerParams({
                visible: false,
                orderingItemId: [],
              });
              getItems();
            }}
          />
        )}
        {orderingItemEditParams.visible && (
          <OrderingItemEdit {...orderingItemEditParams} />
        )}
        {availablePartDrawerParams.visible && (
          <AvailablePartDrawer {...availablePartDrawerParams} />
        )}
        {updateDeliveryParams.visible && (
          <DeliveryEditDrawer {...updateDeliveryParams} />
        )}
        {changeEquipmentDrawerParams.visible && (
          <ChangeEquipmentDrawer
            {...changeEquipmentDrawerParams}
            onClose={() => {
              setChangeEquipmentDrawerParams({
                visible: false,
                selectedOrderingItem: [],
              });
              getItems();
            }}
          />
        )}
        {changeEquipmentUpdateDrawerParams.visible && (
          <ChangeEquipmentUpdateDrawer
            {...changeEquipmentUpdateDrawerParams}
            onClose={() => {
              setChangeEquipmentUpdateDrawerParams({
                visible: false,
                orderingItem: null,
              });
              getItems();
            }}
          />
        )}
      </div>
    </Spin>
  );
};

OrderingItem.propTypes = {
  setTotalOrderingItemAmount: PropTypes.func,
  setTotalOtherOrderingItemAmount: PropTypes.func,
  batchEdit: PropTypes.func,
  isBatchEdit: PropTypes.bool,
  setIsBatchEdit: PropTypes.func,
  batchEditText: PropTypes.string,
  setBatchEditText: PropTypes.func,
  selectedOrderingItems: PropTypes.array,
  setSelectedOrderingItems: PropTypes.func,
  selectedOtherOrderingItems: PropTypes.array,
  setSelectedOtherOrderingItems: PropTypes.func,
  orderingTerminationDrawerParams: PropTypes.object,
  setOrderingTerminationDrawerParams: PropTypes.func,
  changeEquipmentDrawerParams: PropTypes.object,
  setChangeEquipmentDrawerParams: PropTypes.func,
  batchDelivery: PropTypes.func,
  batchReclaim: PropTypes.func,
  batchChangeEquipment: PropTypes.func,
  batchEditMode: PropTypes.string,
  updateDeliveryParams: PropTypes.object,
  batchTerminateOrderingItem: PropTypes.func,
  paymentPreviewVisible: PropTypes.bool,
  setPaymentPreviewVisible: PropTypes.func,
  userAddress: PropTypes.string,
  denyCreatingOrder: PropTypes.bool,
  canChangeEquipment: PropTypes.bool,
};

export default OrderingItem;
