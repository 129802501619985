/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Components
import ContractTable from "./contractTable";

// Styles
import "./contractDrawer.scss";

// Utils
import { Drawer, message } from "antd";

const CONTRACT_FRAGMENT = gql`
  fragment Fields on Contract {
    id
    referenceId
    status {
      id
      name
    }
    file {
      key
      url
      name
    }
    createDate
    lastModifiedDate
    pic {
      displayName
    }
  }
`;

const CONTRACT_LIST = gql`
  query InventoryContractList($userId: ID) {
    inventoryContractList(userId: $userId) {
      ...Fields
    }
  }
  ${CONTRACT_FRAGMENT}
`;

const AGREEMENT_LIST = gql`
  query MasterAgreementList($userId: ID) {
    masterAgreementList(userId: $userId) {
      ...Fields
    }
  }
  ${CONTRACT_FRAGMENT}
`;

const CONTRACT_CREATE = gql`
  mutation InventoryContractCreate($userId: ID) {
    inventoryContractCreate(userId: $userId) {
      id
    }
  }
`;

const AGREEMENT_CREATE = gql`
  mutation MasterAgreementCreate($userId: ID) {
    masterAgreementCreate(userId: $userId) {
      id
    }
  }
`;

const CONTRACT_UPLOAD = gql`
  mutation InventoryContractUpload($input: ContractUploadInput) {
    inventoryContractUpload(input: $input) {
      id
    }
  }
`;

const AGREEMENT_UPLOAD = gql`
  mutation MasterAgreementUpload($input: ContractUploadInput) {
    masterAgreementUpload(input: $input) {
      id
    }
  }
`;
function compare(a, b) {
  const itemA = a.id;
  const itemB = b.id;
  let comparison = 0;
  if (itemA < itemB) {
    comparison = 1;
  } else if (itemA > itemB) {
    comparison = -1;
  }
  return comparison;
}

function ContractDrawer({ userId, visible, onClose }) {
  const [
    getInventoryContractList,
    {
      loading: getInventoryContractListLoading,
      data: getInventoryContractListData,
    },
  ] = useLazyQuery(CONTRACT_LIST, { variables: { userId } });
  const [
    getMasterAgreementList,
    {
      loading: getMasterAgreementListLoading,
      data: getMasterAgreementListData,
    },
  ] = useLazyQuery(AGREEMENT_LIST, { variables: { userId } });

  const [
    inventoryContractCreate,
    {
      loading: inventoryContractCreateLoading,
      data: inventoryContractCreateData,
    },
  ] = useMutation(CONTRACT_CREATE);
  const [
    masterAgreementCreate,
    { loading: masterAgreementCreateLoading, data: masterAgreementCreateData },
  ] = useMutation(AGREEMENT_CREATE);

  const [
    inventoryContractUpload,
    {
      loading: inventoryContractUploadLoading,
      data: inventoryContractUploadData,
    },
  ] = useMutation(CONTRACT_UPLOAD);
  const [
    masterAgreementUpload,
    { loading: masterAgreementUploadLoading, data: masterAgreementUploadData },
  ] = useMutation(AGREEMENT_UPLOAD);

  useEffect(() => {
    if (userId) {
      getInventoryContractList();
      getMasterAgreementList();
    }
  }, [userId, getInventoryContractList, getMasterAgreementList]);

  const contractList = useMemo(() => {
    return getInventoryContractListData?.inventoryContractList || [];
  }, [getInventoryContractListData]);

  const agreementList = useMemo(() => {
    return getMasterAgreementListData?.masterAgreementList || [];
  }, [getMasterAgreementListData]);

  const onCreateContract = useCallback(async () => {
    try {
      await inventoryContractCreate({
        variables: {
          userId,
        },
      });
      getInventoryContractList();
    } catch (error) {
      message.error(error, message);
    }
  }, [userId, inventoryContractCreate, getInventoryContractList]);

  const onCreateAgreement = useCallback(async () => {
    try {
      await masterAgreementCreate({
        variables: {
          userId,
        },
      });
      getMasterAgreementList();
    } catch (error) {
      message.error(error, message);
    }
  }, [userId, masterAgreementCreate, getMasterAgreementList]);

  const onContractFileUpload = useCallback(
    async (file, contract) => {
      try {
        const input = {
          id: contract.id,
          file: {
            key: file.key,
            name: file.name,
            size: file.size,
          },
        };
        await inventoryContractUpload({
          variables: {
            input: input,
          },
        });
        getInventoryContractList();
      } catch (error) {
        message.error(error, message);
      }
    },
    [inventoryContractUpload, getInventoryContractList]
  );

  const onAgreementFileUpload = useCallback(
    async (file, contract) => {
      try {
        const input = {
          id: contract.id,
          file: {
            key: file.key,
            name: file.name,
            size: file.size,
          },
        };
        await masterAgreementUpload({
          variables: {
            input: input,
          },
        });
        getMasterAgreementList();
      } catch (error) {
        message.error(error, message);
      }
    },
    [masterAgreementUpload, getMasterAgreementList]
  );

  useEffect(() => {
    console.log("agreementList", agreementList);
    console.log("contractList", contractList);
  }, [agreementList, contractList]);

  return (
    <div className="ContractDrawer">
      <Drawer
        className="ContractDrawer-drawer"
        visible={visible}
        onClose={onClose}
      >
        <div className="ContractDrawer-content">
          <h1>服務協議資料</h1>
          <div>
            <ContractTable
              loading={getMasterAgreementListLoading}
              data={agreementList}
              title="使用服務合約"
              onCreate={onCreateAgreement}
              onCreateLoading={masterAgreementCreateLoading}
              onUpload={onAgreementFileUpload}
            />
          </div>
          {/* <div style={{ marginTop: "24px" }}>
            <ContractTable loading={getInventoryContractListLoading} data={contractList} title="使用貨物訂單合約" onCreate={onCreateContract} onCreateLoading={inventoryContractCreateLoading} onUpload={onContractFileUpload} />
          </div> */}
        </div>
      </Drawer>
    </div>
  );
}

ContractDrawer.propTypes = {
  userId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

ContractDrawer.defaultProps = {
  visible: false,
};

export default ContractDrawer;
