/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Components
import PrescriptionRecordExpander from "../prescriptionRecordExpander/prescriptionRecordExpander";
import OrderingItemsCartDrawer from "./orderingItemsCartDrawer";
import CustPaginationComponent from "../custPagination/custPagination";
import Filter from "./filter/filter";

// Style
import "./createOrderingItemsDrawer.scss";

// Utils
import { Button, Drawer, message, notification, Table, Typography } from "antd";
import { PAGE, PAGE_SIZE } from "@utils/constants";
import { replaceUrlByParams } from "@utils/api";
import { classList } from "@utils/common";

const DEVICE_LIST = gql`
  query DeviceList(
    $size: Int!
    $offset: Int!
    $keyword: String
    $categoryId: [String]
    $referenceId: String
    $brand: [String]
    $dimension: [String]
    $characteristicTag: [String]
    $sort: SortProductList
    $reverseOrder: Boolean
  ) {
    deviceList(
      size: $size
      offset: $offset
      keyword: $keyword
      categoryId: $categoryId
      referenceId: $referenceId
      brand: $brand
      dimension: $dimension
      characteristicTag: $characteristicTag
      sort: $sort
      reverseOrder: $reverseOrder
    ) {
      id
      referenceId
      productInfo {
        nameChi
      }
      quantity
      category {
        id
        name
        mainCategory {
          id
          name
        }
      }
      leasableQuantity
      washingQuantity
      waitingQuantity
    }
  }
`;

const PART_LIST = gql`
  query PartList(
    $size: Int!
    $offset: Int!
    $keyword: String
    $categoryId: [String]
    $referenceId: String
    $brand: [String]
    $dimension: [String]
    $characteristicTag: [String]
    $sort: SortProductList
    $reverseOrder: Boolean
  ) {
    partList(
      size: $size
      offset: $offset
      keyword: $keyword
      categoryId: $categoryId
      referenceId: $referenceId
      brand: $brand
      dimension: $dimension
      characteristicTag: $characteristicTag
      sort: $sort
      reverseOrder: $reverseOrder
    ) {
      id
      referenceId
      productInfo {
        nameChi
      }
      quantity
      category {
        id
        name
        mainCategory {
          id
          name
        }
      }
      leasableQuantity
      washingQuantity
      waitingQuantity
    }
  }
`;

const CONSUMABLE_LIST = gql`
  query ConsumableList(
    $size: Int!
    $offset: Int!
    $keyword: String
    $categoryId: [String]
    $referenceId: String
    $brand: [String]
    $dimension: [String]
    $characteristicTag: [String]
    $sort: SortProductList
    $reverseOrder: Boolean
  ) {
    consumableList(
      size: $size
      offset: $offset
      keyword: $keyword
      categoryId: $categoryId
      referenceId: $referenceId
      brand: $brand
      dimension: $dimension
      characteristicTag: $characteristicTag
      sort: $sort
      reverseOrder: $reverseOrder
    ) {
      id
      referenceId
      productInfo {
        nameChi
      }
      quantity
      category {
        id
        name
        mainCategory {
          id
          name
        }
      }
    }
  }
`;

const ORDERING_CREATE = gql`
  mutation OrderingItemBulkCreate($input: [OrderingItemCreateInput]) {
    orderingItemBulkCreate(inupt: $input) {
      id
    }
  }
`;

const ORDERING_REPLACE = gql`
  mutation OrderingItemReplace(
    $orderingItemId: ID!
    $productId: ID!
    $quantity: Int!
  ) {
    orderingItemReplace(
      orderingItemId: $orderingItemId
      productId: $productId
      quantity: $quantity
    ) {
      id
    }
  }
`;

function CreateOrderingItemsDrawer(props) {
  let location = useLocation();
  let history = useHistory();

  const {
    className,
    userId,
    visible,
    mode,
    onClose,
    onSubmit,
    currentItems,
    isCreate,
    orderingItemId,
  } = props;

  const indexReferenceId = {
    title: "型號",
    dataIndex: "referenceId",
    width: "15%",
    render: (text, record) => (
      <Link
        className="link"
        to={replaceUrlByParams(PAGE.STOCK_DEVICE_DETAIL, { id: record.id })}
        target="_blank"
      >
        {text}
      </Link>
    ),
  };

  const indexMainCategory = {
    title: "分類",
    dataIndex: "category",
    width: "25%",
    render: (category) => {
      return (
        <>
          <div>
            <Link
              className="link"
              to={replaceUrlByParams(PAGE.STOCK_CATEGORY_RECORD, {
                id: category.mainCategory.id,
                categoryType: "main-category",
              })}
              target="_blank"
            >
              {category.mainCategory.name}
            </Link>
          </div>
          <div>
          <Typography.Text>子分類：</Typography.Text>
            <Link
              className="link"
              to={replaceUrlByParams(PAGE.STOCK_CATEGORY_RECORD, {
                id: category.id,
                categoryType: "category",
              })}
              target="_blank"
            >
              {category.name}
            </Link>
          </div>
        </>
      );
    },
  };

  // const indexCategory = {
  //   title: "子類別",
  //   dataIndex: "category",
  //   render: (category) => {
  //     return (
  //       <Link
  //         className="link"
  //         to={replaceUrlByParams(PAGE.STOCK_CATEGORY_RECORD, {
  //           id: category.id,
  //           categoryType: "category",
  //         })}
  //         target="_blank"
  //       >
  //         {category.name}
  //       </Link>
  //     );
  //   },
  // };

  const indexNameChi = {
    title: "名稱",
    dataIndex: "productInfo.nameChi",
    width: "20%",
  };

  const indexQuantity = {
    title: "存貨數量",
    width: "8%",
    dataIndex: "quantity",
  };

  const indexLeasableQuantity = {
    title: "可租用數量",
    width: "8%",
    dataIndex: "leasableQuantity",
  };

  const indexWashingQuantity = {
    title: "正在清洗數量",
    width: "8%",
    dataIndex: "washingQuantity",
  };

  const indexWaitingQuantity = {
    title: "輪候人數",
    width: "8%",
    dataIndex: "waitingQuantity",
  };

  const indexAction = {
    dataIndex: "action",
    width: "8%",
    render: (text, record) => {
      let max = 0;
      console.log(record);
      if (record.__typename === "Device" || record.__typename === "Part") {
        max = record.leasableQuantity + record.washingQuantity;
      } else {
        max = record.quantity;
      }
      return max > 0 ? (
        <Button
          type="primary"
          shape="circle"
          icon="plus"
          size="small"
          onClick={() => {
            if (isCreate) {
              const num = items[record.referenceId]?.qty || 0;
              if (num === max) {
                message.warning("已選取上限數量");
                return;
              }
              setItems((prev) => ({
                ...prev,
                [record.referenceId]: {
                  ...record,
                  qty: (prev[record.referenceId]?.qty || 0) + 1,
                  max: max,
                },
              }));
              message.success("成功加到選取清單");
            } else {
              if (Object.keys(items).length > 0) {
                message.error(`只可以更換一項${modeName()}`);
              } else {
                setItems((prev) => ({
                  ...prev,
                  [record.referenceId]: {
                    ...record,
                    qty: (prev[record.referenceId]?.qty || 0) + 1,
                    max: max,
                  },
                }));
                message.success("成功加到選取清單");
              }
            }
          }}
        />
      ) : (
        undefined
      );
    },
  };

  const deviceCols = [
    indexReferenceId,
    indexMainCategory,
    // indexCategory,
    indexNameChi,
    indexLeasableQuantity,
    indexWashingQuantity,
    indexWaitingQuantity,
    indexAction,
  ];

  const partCols = deviceCols;

  const consumableCols = [
    indexReferenceId,
    indexMainCategory,
    // indexCategory,
    indexNameChi,
    indexQuantity,
    indexAction,
  ];

  const [
    getDeviceList,
    { loading: deviceListLoading, data: deviceListData },
  ] = useLazyQuery(DEVICE_LIST);

  const [
    getPartList,
    { loading: partListLoading, data: partListData },
  ] = useLazyQuery(PART_LIST);

  const [
    getConsumableList,
    { loading: consumableListLoading, data: consumableListData },
  ] = useLazyQuery(CONSUMABLE_LIST);

  const [
    orderingItemsCreate,
    { data: orderingItemsCreateData, loading: orderingItemsCreateLoading },
  ] = useMutation(ORDERING_CREATE);
  const [
    orderingItemsReplace,
    { data: orderingItemsReplaceData, loading: orderingItemsReplaceLoading },
  ] = useMutation(ORDERING_REPLACE);

  const isLoading =
    deviceListLoading ||
    partListLoading ||
    consumableListLoading ||
    orderingItemsCreateLoading ||
    orderingItemsReplaceLoading;

  const [query, setQuery] = useState({
    page: 1,
    pageSize: PAGE_SIZE,
  });

  const queryParamToArray = (x) => (x ? (!Array.isArray(x) ? [x] : x) : []);

  const [items, setItems] = useState({});

  const [isCartDrawerShow, setIsCartDrawerShow] = useState(false);

  const listDataColumns = useMemo(() => {
    switch (mode) {
      case "Device":
        return deviceCols;
      case "Part":
        return partCols;
      case "Consumable":
        return consumableCols;
      default:
        return [];
    }
  }, [deviceCols, partCols, consumableCols, mode]);

  const onCartDrawerClose = () => {
    setIsCartDrawerShow(false);
  };

  // //logg
  // useEffect(() => {
  //     console.log("items from createOrderingItemsDrawer",items)
  // }, [items])

  const onCartDrawerSubmit = async () => {
    const submit = Object.values(items).map((item) => {
      return {
        productId: item.id,
        quantity: item.qty,
        userId: userId,
      };
    });
    if (isCreate) {
      await orderingItemsCreate({
        variables: {
          input: submit,
        },
      });
    } else {
      await orderingItemsReplace({
        variables: {
          orderingItemId: orderingItemId,
          productId: submit[0].productId,
          quantity: submit[0].quantity,
        },
      });
    }

    onCartDrawerClose();
  };

  const getList = useCallback(() => {
    const {
      page,
      pageSize,
      keyword,
      categoryId,
      referenceId,
      brand,
      dimension,
      characteristicTag,
      sortField,
      sortOrder,
    } = query;
    const variables = {
      offset: +((page - 1) * pageSize) || 0,
      size: +(pageSize || PAGE_SIZE) + 1,
      keyword: keyword,
      categoryId: categoryId,
      referenceId: referenceId,
      brand: queryParamToArray(brand),
      dimension: queryParamToArray(dimension),
      characteristicTag: queryParamToArray(characteristicTag),
      sort: sortField,
      reverseOrder: sortOrder ? sortOrder === "descend" : undefined,
    };
    switch (mode) {
      case "Device":
        getDeviceList({
          variables,
        });
        break;
      case "Part":
        getPartList({
          variables,
        });
        break;
      case "Consumable":
        getConsumableList({
          variables,
        });
        break;
      default:
    }

    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    if (visible) {
      getList();
    }

    // eslint-disable-next-line
  }, [visible, getList]);

  const listData = useMemo(() => {
    switch (mode) {
      case "Device":
        return (deviceListData?.deviceList || []).slice(0, PAGE_SIZE);
      case "Part":
        return (partListData?.partList || []).slice(0, PAGE_SIZE);
      case "Consumable":
        return (consumableListData?.consumableList || []).slice(0, PAGE_SIZE);
      default:
        return [];
    }
  }, [deviceListData, partListData, consumableListData, mode]);

  const isMaxPage = useMemo(() => {
    switch (mode) {
      case "Device":
        return deviceListData?.deviceList?.length <= PAGE_SIZE;
      case "Part":
        return partListData?.partList?.length <= PAGE_SIZE;
      case "Consumable":
        return consumableListData?.consumableList?.length <= PAGE_SIZE;
      default:
        return true;
    }
  }, [consumableListData, deviceListData, mode, partListData]);

  console.log("orderingItemsReplaceData", orderingItemsReplaceData);

  useEffect(() => {
    if (orderingItemsCreateData || orderingItemsReplaceData) {
      notification.success({
        message: `成功${isCreate ? "新增" : "更換"}訂單項目`,
      });
      setItems({});

      if (onSubmit) {
        onSubmit();
      }
    }

    // eslint-disable-next-line
  }, [orderingItemsCreateData, orderingItemsReplaceData]);

  const resetQuery = () => {
    setQuery({ page: 1, pageSize: PAGE_SIZE });
  };

  const onPageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  const modeName = () => {
    switch (mode) {
      case "Device": {
        return "器材";
      }
      case "Part": {
        return "零件";
      }
      case "Consumable": {
        return "消耗品";
      }
      default:
        return "";
    }
  };

  const onDrawerClose = () => {
    setItems({});
    onClose();
  };

  const getClassList = () =>
    classList({
      "CreateOrderingItemsDrawer-Component": true,
      [className]: true,
    });

  return (
    <Drawer
      className={getClassList()}
      title={`${isCreate ? "新增" : "更換"}訂單項目資料（${modeName(mode)})`}
      placement="right"
      onClose={onDrawerClose}
      visible={visible}
      width="90vw"
    >
      <PrescriptionRecordExpander
        userId={userId}
        isDisplay={mode !== "Device"}
        items={items}
        setItems={setItems}
        currentItems={currentItems}
      />
      <Filter
        filterList={["categoryId", "brand", "dimension", "characteristicTag", "referenceId"]}
        filterType={[""]}
        filterValue={query}
        onFilterSearch={() => getList()}
        onResetFilter={() => resetQuery()}
        onFilterValueChange={setQuery}
      />
      <div className="paginate">
        <Typography.Text>附合篩選條件貨物</Typography.Text>
        <CustPaginationComponent
          isMinPage={query.page === 1}
          isMaxPage={isMaxPage}
          page={query.page}
          // pageSize={query.pageSize || PAGE_SIZE}
          pageSize={PAGE_SIZE}
          onPageChange={onPageChange}
        />
      </div>
      <Table
        tableLayout="fixed"
        // scroll={{ x: "max-content" }}
        columns={listDataColumns}
        dataSource={listData}
        rowKey="id"
        pagination={false}
        loading={deviceListLoading || partListLoading || consumableListLoading}
      />
      <div className="btn-container" style={{ marginTop: "40px" }}>
        <Button
          className="btn bottom-pinned"
          type="primary"
          onClick={() => setIsCartDrawerShow(true)}
        >
          查看選取清單
        </Button>
      </div>
      <OrderingItemsCartDrawer
        isLoading={isLoading}
        visible={isCartDrawerShow}
        onClose={onCartDrawerClose}
        onSubmit={onCartDrawerSubmit}
        items={items}
        setItems={setItems}
        isCreate={isCreate}
      ></OrderingItemsCartDrawer>
    </Drawer>
  );
}

CreateOrderingItemsDrawer.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  currentItems: PropTypes.array.isRequired,
  isCreate: PropTypes.bool.isRequired,
  orderingItemId: PropTypes.string,
};

CreateOrderingItemsDrawer.defalutProps = {
  className: "",
};

export default CreateOrderingItemsDrawer;
