/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { Link, useHistory, useLocation } from "react-router-dom";
import { gql } from "@apollo/client";
import queryString from "query-string";

// Layouts
import TransactionRecord from "./transactionRecord";
import BalanceTransfer from "./balanceTransfer";
import PaymentStatementEdit from "./paymentStatementEdit";
// import AccountRefundDetail from "./accountRefundDetail";
import CustPaginationComponent from "@components/custPagination/custPagination";
import AccountRefundDrawer from "@components/accountRefundDrawer/accountRefundDrawer";

// Styles
import "./orderingPayment.scss";

// Utils
import { getFileUploadUrl } from "@utils/env";
import { MOMENT_FORMAT } from "@utils/constants";
import { amountDisplay } from "@utils/common";
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Icon,
  List,
  Menu,
  Modal,
  message,
  Row,
  Table,
  Tag,
  Typography,
  Upload,
  Popconfirm,
  notification,
} from "antd";
import { isEmpty } from "@utils/validate";
import moment from "moment";
import { getTokenHeader } from "@utils/api";
import Axios from "axios";

const ACCOUNT_GET = gql`
  query AccountGet($userId: ID, $size: Int!, $offset: Int!) {
    accountGet(userId: $userId) {
      user {
        userId
      }
      whatsappOptIn
      depositAmount
      depositDifferent
      rentalAmount
      frozenDepositAmount
      frozenRentalAmount
      paymentStatement(size: $size, offset: $offset) {
        id
        referenceId
        issueDate
        paymentDueDate
        amount
        status {
          id
          name
        }
        paymentMethod {
          id
          name
        }
        paymentDate
        latePayment
        lastPayment
        attachment {
          key
          url
          name
          size
        }
        invoiceNumber
        personInCharge {
          tbId
          displayName
        }
        download {
          key
          url
          name
          size
        }
        receipt {
          key
          url
          name
          size
        }
        sendPaymentNotification
        sendPaymentReceipt
        lastSendPaymentNotificationDatetime
        lastSendPaymentReceiptDatetime
        remark
      }
    }
  }
`;

const PAYMENT_STATEMENT_UPDATE = gql`
  mutation PaymentStatementUpdate($input: PaymentStatementUpdateInput!) {
    paymentStatementUpdate(input: $input) {
      id
    }
  }
`;

const PAYMENT_STATEMENT_REMINDER_SEND = gql`
  mutation PaymentStatementReminderSend($paymentStatementId: ID!) {
    paymentStatementReminderSend(paymentStatementId: $paymentStatementId)
  }
`;

const PAYMENT_RECEIPT_REMINDER_SEND = gql`
  mutation paymentReceiptReminderSend($paymentStatementId: ID!) {
    paymentReceiptReminderSend(paymentStatementId: $paymentStatementId)
  }
`;

const PAYMENT_STATEMENT_SEND_TO_WHATSAPP = gql`
  mutation paymentStatementSendToWhatsapp($paymentStatementId: ID!) {
    paymentStatementSendToWhatsapp(paymentStatementId: $paymentStatementId)
  }
`;

const PAYMENT_RECEIPT_SEND_TO_WHATSAPP = gql`
  mutation paymentReceiptSendToWhatsapp($paymentStatementId: ID!) {
    paymentReceiptSendToWhatsapp(paymentStatementId: $paymentStatementId)
  }
`;

const PAYMENT_REFUND = gql`
  mutation PaymentRefund($userId: ID!) {
    paymentRefund(userId: $userId)
  }
`;

const BALANCE_CLEAR = gql`
  mutation balanceClear($userId: ID, $attachment: [FileInput]) {
    balanceClear(userId: $userId, attachment: $attachment) {
      user {
        userId
      }
      depositAmount
      rentalAmount
    }
  }
`;

const PAGE_SIZE = 10;

const OrderingPayment = ({ userId }) => {
  // console.log("OrderingPayment userId", userId)
  const history = useHistory();
  const location = useLocation();

  const columns = [
    {
      title: "帳單編號",
      dataIndex: "referenceId",
      render: (text, payment) => (
        <Link
          style={{ textDecoration: "underline" }}
          className="link"
          onClick={(e) => {
            e.preventDefault();
            handlePaymentDownload(payment?.download);
          }}
        >
          {text}
        </Link>
      ),
    },
    {
      dataIndex: "lastPayment",
      render: (bool) => (
        <span>{bool ? <Tag className="tag error">{"最後"}</Tag> : ""}</span>
      ),
    },
    {
      title: "發出日期",
      dataIndex: "issueDate",
      render: (date) => (
        <span>{date ? moment(date).format(MOMENT_FORMAT) : "-"}</span>
      ),
    },
    {
      title: "應繳日期",
      dataIndex: "paymentDueDate",
      render: (date) => (
        <span>{date ? moment(date).format(MOMENT_FORMAT) : "-"}</span>
      ),
    },
    {
      title: "應繳款項（港幣）",
      dataIndex: "amount",
      render: (amount) => <span>{amountDisplay(amount)}</span>,
    },
    {
      title: "付款狀態",
      dataIndex: "status",
      render: (paymentStatus, record) => {
        const latePaymentTag = <Tag className="tag error">{"過期"}</Tag>;
        const tags = {
          pending: <Tag className="tag warning">{paymentStatus.name}</Tag>,
          paid: <Tag className="tag success">{paymentStatus.name}</Tag>,
          paidManually: <Tag className="tag success">{paymentStatus.name}</Tag>,
          overdue: <Tag className="tag error">{paymentStatus.name}</Tag>,
          confirmed: <Tag className="tag success">{paymentStatus.name}</Tag>,
          toBeRefunded: <Tag className="tag warning">{paymentStatus.name}</Tag>,
          refunded: <Tag className="tag warning">{paymentStatus.name}</Tag>,
          cancelled: (
            <Tag
              className="tag"
              style={{ backgroundColor: "#eeeeee", color: "#888888" }}
            >
              {paymentStatus.name}
            </Tag>
          ),
        }[paymentStatus.id];

        return (
          <div>
            {record.latePayment &&
              record.status.id !== "overdue" &&
              latePaymentTag}
            {tags}
          </div>
        );
      },
    },
    // {
    //   title: "付款方式",
    //   dataIndex: "paymentMethod",
    //   render: (paymentMethod) => <span>{paymentMethod?.name || "-"}</span>,
    // },
    {
      title: "付款日期",
      dataIndex: "paymentDate",
      render: (date, record) => (
        <span className={record.latePayment ? "latePayment" : ""}>
          {date ? moment(date).format(MOMENT_FORMAT) : "-"}
        </span>
      ),
    },
    // {
    //   title: "負責人",
    //   dataIndex: "personInCharge",
    //   render: (personInCharge) => (
    //     <span>{personInCharge ? personInCharge.displayName : "-"}</span>
    //   ),
    // },
    {
      title: "收據編號",
      dataIndex: "invoiceNumber",
      render: (text, payment) => (
        <span>
          {!isEmpty(text) ? (
            <Link
              style={{ textDecoration: "underline" }}
              className="link"
              onClick={(e) => {
                e.preventDefault();
                handlePaymentDownload(payment?.receipt);
              }}
            >
              {text}
            </Link>
          ) : (
            "-"
          )}
        </span>
      ),
    },
    {
      title: "付款通知",
      dataIndex: "sendPaymentNotification",
      render: (text) => (
        <span>
          {text ? (
            <Tag className="tag success">{"已發出"}</Tag>
          ) : (
            <Tag className="tag warning">{"待發出"}</Tag>
          )}
        </span>
      ),
    },
    {
      title: "付款通知發出日期",
      dataIndex: "lastSendPaymentNotificationDatetime",
      render: (text) => (
        <span>{text ? moment(text).format(MOMENT_FORMAT) : "-"}</span>
      ),
    },
    {
      title: "收據通知",
      dataIndex: "sendPaymentReceipt",
      render: (text) => (
        <span>
          {text ? (
            <Tag className="tag success">{"已發出"}</Tag>
          ) : (
            <Tag className="tag warning">{"待發出"}</Tag>
          )}
        </span>
      ),
    },
    {
      title: "收據通知發出日期",
      dataIndex: "lastSendPaymentReceiptDatetime",
      render: (text) => (
        <span>{text ? moment(text).format(MOMENT_FORMAT) : "-"}</span>
      ),
    },
    {
      dataIndex: "action",
      render: (text, payment) => (
        <Dropdown overlay={() => paymentMenu(payment)}>
          <Button className="btn btn-secondary">
            <Icon type="ellipsis" />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const [
    getAccount,
    { loading: getAccountLoading, data: getAccountData },
  ] = useLazyQuery(ACCOUNT_GET);
  console.log("getAccountData", getAccountData);

  const [
    updatePaymentStatement,
    {
      loading: updatePaymentStatementLoading,
      data: updatePaymentStatementData,
    },
  ] = useMutation(PAYMENT_STATEMENT_UPDATE);
  const [sendPaymentReminder, { data: sendPaymentReminderData }] = useMutation(
    PAYMENT_STATEMENT_REMINDER_SEND
  );
  const [sendReceiptReminder, { data: sendReceiptReminderData }] = useMutation(
    PAYMENT_RECEIPT_REMINDER_SEND
  );
  const [
    sendStatementToWhatsapp,
    { data: sendStatementToWhatsappData },
  ] = useMutation(PAYMENT_STATEMENT_SEND_TO_WHATSAPP);
  const [
    sendReceiptToWhatsapp,
    { data: sendReceiptToWhatsappData },
  ] = useMutation(PAYMENT_RECEIPT_SEND_TO_WHATSAPP);

  const [refundPayment, { data: refundPaymentData }] = useMutation(
    PAYMENT_REFUND
  );
  const [balanceClear, { data: balanceClearData }] = useMutation(BALANCE_CLEAR);

  const [transactionRecordParams, setTransactionRecordParams] = useState({
    visible: false,
  });
  const [transferDepositParams, setTransferDepositParams] = useState({
    visible: false,
  });
  const [paymentStatementEditParams, setPaymentStatementEditParams] = useState({
    visible: false,
  });
  const [accountRefundDetailParams, setAccountRefundDetailParams] = useState({
    visible: false,
  });
  const [uploadAttachment, setUploadAttachment] = useState(undefined);
  const [selectedPayment, setSelectedPayment] = useState(undefined);
  const [isShowUploadModal, setIsShowUploadModal] = useState(false);
  const [fileUploadList, setFileUploadList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [depositDifferent, setDepositDifferent] = useState(undefined);
  const [balanceClearParam, setbalanceClearParam] = useState({
    visible: false,
    fileList: [],
  });

  const query = useMemo(() => {
    const { page, pageSize, refundList } = queryString.parse(location.search);
    return {
      ...queryString.parse(location.search),
      page: page ? Number(page) : 1,
      // pageSize: pageSize ? Number(pageSize) : PAGE_SIZE,
      pageSize: PAGE_SIZE,
      refundList: refundList === "visible",
    };
  }, [location.search]);

  const paymentStatements = useMemo(() => {
    return (getAccountData?.accountGet?.paymentStatement ?? []).slice(
      0,
      query.pageSize
    );
  }, [getAccountData, query.pageSize]);

  const isMaxPage = useMemo(() => {
    return paymentStatements.length < query.pageSize;
  }, [paymentStatements.length, query.pageSize]);

  useEffect(() => {
    if (userId) {
      refreshAccount();
    }
    // eslint-disable-next-line
  }, [userId, query.pageSize, query.page, query.tabKey]);

  useEffect(() => {
    setDepositDifferent(getAccountData?.accountGet?.depositDifferent);
  }, [getAccountData]);

  // useEffect(() => {
  //   const file = sendPaymentReminderData?.paymentStatementReminderSend;
  //   if (file) {
  //     window.open(file?.url);
  //   }
  // }, [sendPaymentReminderData]);

  const refreshAccount = useCallback(() => {
    console.log(userId);
    getAccount({
      variables: {
        userId: userId,
        size: query.pageSize + 1,
        offset: (query.page - 1) * query.pageSize,
      },
    });
  }, [getAccount, query.page, query.pageSize, userId]);

  const editPaymentStatement = (payment) => {
    setPaymentStatementEditParams({
      paymentStatement: payment,
      visible: true,
      onClose: () => setPaymentStatementEditParams({ visible: false }),
      onSuccessEdit: onSuccessEdit,
    });
  };

  const onSuccessEdit = () => {
    setPaymentStatementEditParams({ visible: false });
    refreshAccount();
  };

  const viewTransactionRecord = () => {
    setTransactionRecordParams({
      userId: userId,
      visible: true,
      onClose: () => {
        setTransactionRecordParams({ visible: false });
        delete query.transactionPage;
        delete query.transactionPageSize;
        history.push({
          search: queryString.stringify(query),
        });
        refreshAccount();
      },
    });
  };

  const onSuccessTransferDeposit = () => {
    setTransferDepositParams({ visible: false });
    refreshAccount();
  };

  const viewPaymentRefund = useCallback(async () => {
    setAccountRefundDetailParams({
      userId: userId,
      visible: true,
      onClose: () => {
        setAccountRefundDetailParams({ visible: false });
        history.push({
          search: queryString.stringify({
            ...query,
            refundDetail: undefined,
            refundList: undefined,
          }),
        });
        refreshAccount();
      },
    });
  }, [history, query, refreshAccount, userId]);

  useEffect(() => {
    if (query.refundList) viewPaymentRefund();
  }, [query.refundList, viewPaymentRefund]);

  const checkFileType = (file) => {
    if (file.type.includes("image/") || file.type.includes("application/pdf"))
      return true;
    return false;
  };

  const handleUploadChange = (info) => {
    let fileList = [...info.fileList].reduce((acc, file) => {
      if (checkFileType(file)) {
        if (file.response?.data) {
          file.key = file.response.data?.key;
          file.url = file.response.data?.url;
        }
        acc.push(file);
      } else {
        notification.error({
          message: `不支援 ${file.name} 的檔案類型, 請上載圖片或PDF`,
        });
      }
      return acc;
    }, []);
    // console.log("uploadfileinfo", fileList)
    setFileUploadList(fileList);
  };

  const handlebalanceClearUploadChange = (info) => {
    // console.log("fileUploadList",fileUploadList)
    if (checkFileType(info.file)) {
      let fileList = [...info.fileList];
      // console.log("fileList 138",fileList)
      // only one file allowed
      // fileList = fileList.slice(-1);
      // console.log("fileList 141",fileList)
      fileList = fileList.map((file) => {
        if (file.response?.data) {
          file.key = file.response.data?.key;
          file.url = file.response.data?.url;
        }
        return file;
      });
      setbalanceClearParam((prevState) => ({
        ...prevState,
        fileList: fileList,
      }));
    } else {
      notification.error({
        message: `不支援 ${info.file.name} 的檔案類型, 請上載圖片或PDF`,
      });
    }
  };

  const handlebalanceClearCancel = () => {
    setbalanceClearParam((prevState) => ({ visible: false, fileList: [] }));
  };

  const handlebalanceClearSubmit = async () => {
    if (balanceClearParam.fileList.length === 0) {
      notification.error({ message: "請上載收款確認書" });
    } else {
      // console.log("balanceClear Submit", balanceClearParam);
      try {
        const fileList = balanceClearParam.fileList.map(
          ({ key, size, name }) => ({ key, size, name })
        );
        await balanceClear({
          variables: { userId: userId, attachment: fileList },
        });
      } catch (error) {
        return;
      }
      refreshAccount();
      setbalanceClearParam({ visible: false, fileList: [] });
    }
  };

  const handleBeforeUpload = (file) => {
    return checkFileType(file);
  };

  const resetUploadParams = () => {
    setIsUploading(false);
    setIsShowUploadModal(false);
    setFileUploadList([]);
    setSelectedPayment(undefined);
  };

  const handleUploadModalOk = useCallback(async () => {
    setIsUploading(true);
    try {
      const originalFileSize = selectedPayment?.attachment?.length ?? 0;
      const newFileSize = uploadAttachment?.length ?? 0;
      if (fileUploadList.length > 0 || originalFileSize !== newFileSize) {
        const attachment = [];
        fileUploadList.forEach((file) => {
          attachment.push({
            key: file.key,
            name: file.name,
            size: file.size,
          });
        });
        if (uploadAttachment) {
          uploadAttachment.forEach((file) => {
            attachment.push({
              key: file.key,
              name: file.name,
              size: file.size,
            });
          });
        }
        const input = {
          id: selectedPayment?.id,
          attachment: attachment,
        };
        await updatePaymentStatement({
          variables: {
            input: input,
          },
        });
        message.success("成功上載付款證明");
        refreshAccount();
      }
      resetUploadParams();
    } catch (error) {
      message.error(error.message);
      resetUploadParams();
    }
    // eslint-disable-next-line
  }, [
    fileUploadList,
    updatePaymentStatement,
    selectedPayment,
    uploadAttachment,
  ]);

  const handleUploadModalCancel = () => {
    resetUploadParams();
  };

  const handlePaymentReminder = useCallback(
    async (payment, method) => {
      try {
        switch (method) {
          case "payment_reminder":
            await sendPaymentReminder({
              variables: {
                paymentStatementId: payment?.id,
              },
            });
            break;
          case "payment_whatsapp":
            await sendStatementToWhatsapp({
              variables: {
                paymentStatementId: payment?.id,
              },
            });
            break;
          case "receipt_reminder":
            await sendReceiptReminder({
              variables: {
                paymentStatementId: payment?.id,
              },
            });
            break;
          case "receipt_whatsapp":
            await sendReceiptToWhatsapp({
              variables: {
                paymentStatementId: payment?.id,
              },
            });
            break;
          default:
            break;
        }
        refreshAccount();
      } catch (error) {
        message.error(error.message);
      }
    },
    [
      refreshAccount,
      sendPaymentReminder,
      sendReceiptReminder,
      sendReceiptToWhatsapp,
      sendStatementToWhatsapp,
    ]
  );

  const handlePaymentDownload = useCallback((file) => {
    Axios({
      url: file.url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", file.name);
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    });
  }, []);

  const onPaymentMenuItemClick = ({ key, payment }) => {
    switch (key) {
      case "payment_edit": {
        editPaymentStatement(payment);
        break;
      }
      case "payment_attachment": {
        setIsShowUploadModal(true);
        setSelectedPayment(payment);
        setUploadAttachment(payment.attachment);
        break;
      }
      // case "payment_reminder": {
      //   handlePaymentReminder(payment);
      //   break;
      // }
      // case "payment_download_receipt": {
      //   handlePaymentDownload(payment?.receipt);
      //   break;
      // }
      // case "payment_download": {
      //   handlePaymentDownload(payment?.download);
      //   break;
      // }
      default: {
        break;
      }
    }
  };

  const onAccountMenuItemClick = ({ key }) => {
    switch (key) {
      case "account_record": {
        viewTransactionRecord();
        break;
      }
      case "account_refund": {
        history.push({
          pathname: location.pathname,
          search: queryString.stringify({
            ...query,
            refundList: "visible",
          }),
        });
        // viewPaymentRefund();
        break;
      }
      default: {
        break;
      }
    }
  };

  useEffect(() => {
    console.log("getAccountData", getAccountData);
  }, [getAccountData]);

  const paymentMenu = (payment) => {
    console.log("paymentMenu, payment", payment);

    return (
      <Menu
        onClick={({ key }) => {
          onPaymentMenuItemClick({ key, payment: payment });
        }}
      >
        <Menu.Item key="payment_edit">編輯</Menu.Item>
        {/* <Menu.Item
          key="payment_download"
          disabled={payment.download ? false : true}
        >
          下載帳單
        </Menu.Item> */}
        <Menu.Item key="payment_attachment">上載/查看付款證明</Menu.Item>
        {/* <Menu.Item
          key="payment_download_receipt"
          disabled={payment.receipt ? false : true}
        >
          下載收據
        </Menu.Item> */}
        <Menu.Item key="payment_reminder" disabled={true}>
          <Popconfirm
            overlayStyle={{ width: "200px" }}
            title="確定發出付款通知?"
            onConfirm={() => handlePaymentReminder(payment, "payment_reminder")}
            okText="確定"
            cancelText="取消"
          >
            <a href="#">發出付款通知</a>
          </Popconfirm>
        </Menu.Item>
        {getAccountData?.accountGet?.whatsappOptIn && (
          <Menu.Item key="payment_whatsapp" disabled={true}>
            <Popconfirm
              overlayStyle={{ width: "200px" }}
              title="確定傳送帳單至Whatsapp?"
              onConfirm={() =>
                handlePaymentReminder(payment, "payment_whatsapp")
              }
              okText="確定"
              cancelText="取消"
            >
              <a href="#">傳送帳單至Whatsapp</a>
            </Popconfirm>
          </Menu.Item>
        )}
        {payment?.status?.id === "paid" && (
          <Menu.Item key="receipt_reminder" disabled={true}>
            <Popconfirm
              overlayStyle={{ width: "200px" }}
              title="確定發出收據通知?"
              onConfirm={() =>
                handlePaymentReminder(payment, "receipt_reminder")
              }
              okText="確定"
              cancelText="取消"
            >
              <a href="#">發出收據通知</a>
            </Popconfirm>
          </Menu.Item>
        )}
        {getAccountData?.accountGet?.whatsappOptIn &&
          payment?.status?.id === "paid" && (
            <Menu.Item key="receipt_whatsapp" disabled={true}>
              <Popconfirm
                overlayStyle={{ width: "200px" }}
                title="確定傳送收據至Whatsapp?"
                onConfirm={() =>
                  handlePaymentReminder(payment, "receipt_whatsapp")
                }
                okText="確定"
                cancelText="取消"
              >
                <a href="#">傳送收據至Whatsapp</a>
              </Popconfirm>
            </Menu.Item>
          )}
      </Menu>
    );
  };

  const accountMenu = (
    <Menu
      onClick={({ key }) => {
        onAccountMenuItemClick({ key });
      }}
    >
      <Menu.Item key="account_record">查看交易紀錄</Menu.Item>
      <Menu.Item key="account_refund">查看退款紀錄</Menu.Item>
    </Menu>
  );

  return (
    <div className="PaymentTab">
      <div>
      <Row type="flex">
        <Col className="col-layout" sm={depositDifferent ? 5 : 4}>
          <Row type="flex">
            <Col style={{ flex: 80 }}>
              <div className="header">
                <span>按金餘額（港幣）</span>
              </div>
              <div>
                <Typography.Title level={3}>
                  $
                  {amountDisplay(
                    getAccountData?.accountGet?.depositAmount ?? 0
                  )}
                  {depositDifferent
                    ? ` (${depositDifferent >= 0 ? "+$" : "-$"}${amountDisplay(
                        Math.abs(depositDifferent)
                      )})`
                    : ""}
                </Typography.Title>
              </div>
            </Col>
            {/* <div style={{marginTop: "20px"}}>
                            <Button className="btn btn-secondary" size="small" shape="circle" icon="info" onClick={() => message.info("Info...")}/>
                        </div> */}
          </Row>
        </Col>
        <Col className="col-layout" sm={1}>
          <Divider type="vertical" style={{ height: 60 }} />
        </Col>
        <Col className="col-layout" sm={3}>
          <div className="header">
            <span>租金餘額（港幣）</span>
          </div>
          <div>
            <Typography.Title level={3}>
              ${amountDisplay(getAccountData?.accountGet?.rentalAmount ?? 0)}
            </Typography.Title>
          </div>
        </Col>
        <Col className="col-layout" sm={1}>
          <Divider type="vertical" style={{ height: 60 }} />
        </Col>
        <Col className="col-layout" sm={11}>
          <div className="header">
            <span>處理中退款（港幣）</span>
          </div>
          <div>
            <Typography.Title level={3}>
              {`按金 $${amountDisplay(
                getAccountData?.accountGet?.frozenDepositAmount ?? 0
              )} + 租金 $${amountDisplay(
                getAccountData?.accountGet?.frozenRentalAmount ?? 0
              )} | 合計 $${amountDisplay(
                (getAccountData?.accountGet?.frozenDepositAmount ?? 0) +
                  (getAccountData?.accountGet?.frozenRentalAmount ?? 0)
              )}`}
            </Typography.Title>
          </div>
        </Col>
        <Col className="col-layout-btn" sm={2}>
          <Dropdown overlay={accountMenu}>
            <div>
              <Button className="btn btn-secondary">
                <Icon type="ellipsis" />
              </Button>
            </div>
          </Dropdown>
        </Col>
      </Row>
      </div>
      <div className="paging-component">
        <CustPaginationComponent
          isMinPage={query.page === 1}
          isMaxPage={isMaxPage}
          page={query.page}
          // pageSize={query.pageSize || PAGE_SIZE}
          pageSize={PAGE_SIZE}
          onPageChange={(page) => {
            history.push({
              pathname: location.pathname,
              search: queryString.stringify({
                ...query,
                page,
              }),
            });
          }}
        />
      </div>

      <Table
        className="payment-table"
        rowKey={getAccountData?.accountGet?.paymentStatement?.id ?? "id"}
        columns={columns}
        loading={getAccountLoading}
        dataSource={getAccountData?.accountGet?.paymentStatement || []}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
      {transactionRecordParams.visible && (
        <TransactionRecord {...transactionRecordParams} />
      )}
      {transferDepositParams.visible && (
        <BalanceTransfer {...transferDepositParams} />
      )}
      {paymentStatementEditParams.visible && (
        <PaymentStatementEdit {...paymentStatementEditParams} />
      )}
      {query.refundList && (
        <AccountRefundDrawer {...accountRefundDetailParams} />
      )}
      {isShowUploadModal && (
        <Modal
          title="查看付款證明"
          visible={isShowUploadModal}
          onOk={handleUploadModalOk}
          onCancel={handleUploadModalCancel}
          footer={[
            <Button key="back" onClick={handleUploadModalCancel}>
              返回
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={isUploading}
              onClick={handleUploadModalOk}
            >
              提交
            </Button>,
          ]}
        >
          <div>
            {uploadAttachment && (
              <List
                itemLayout="vertical"
                dataSource={uploadAttachment}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        key="list-download"
                        type="link"
                        icon="download"
                        onClick={() => window.open(item.url)}
                      />,
                      <Button
                        key="list-delete"
                        type="link"
                        icon="delete"
                        onClick={() => {
                          var list = [...uploadAttachment];
                          var index = list.findIndex(
                            (file) => file.key === item.key
                          );
                          if (index !== -1) {
                            list.splice(index, 1);
                            setUploadAttachment(list);
                          }
                        }}
                      />,
                    ]}
                  >
                    {item.name}
                  </List.Item>
                )}
              ></List>
            )}
          </div>
          <Upload
            accept="image/*,.pdf"
            className="ordering-attachment-uploader"
            headers={{
              "x-token": getTokenHeader(),
              "X-Requested-With": null,
            }}
            action={getFileUploadUrl()}
            fileList={fileUploadList}
            onChange={handleUploadChange}
            beforeUpload={handleBeforeUpload}
          >
            <Button>上載付款證明</Button>
          </Upload>
        </Modal>
      )}
      {balanceClearParam.visible && (
        <Modal
          title="清空餘額"
          visible={balanceClearParam.visible}
          onOk={handlebalanceClearSubmit}
          onCancel={handlebalanceClearCancel}
          footer={[
            <Button key="back" onClick={handlebalanceClearCancel}>
              取消
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={false}
              onClick={handlebalanceClearSubmit}
            >
              確認
            </Button>,
          ]}
        >
          <div>
            <Row type="flex" gutter={[16, 24]} justify="center">
              <Col>
                <Icon
                  style={{ fontSize: "100px", width: "100%" }}
                  type="warning"
                />
              </Col>
            </Row>
            <Row type="flex" gutter={[16, 24]} justify="center">
              <Col>
                <span style={{ fontSize: 24, fontWeight: "bold" }}>
                  請上載收款確認書並確認清空所有餘額
                </span>
              </Col>
            </Row>
            <Row type="flex" gutter={[16, 24]}>
              <Col>
                <Upload
                  accept="image/*,.pdf"
                  className="ordering-attachment-uploader"
                  headers={{
                    "x-token": getTokenHeader(),
                    "X-Requested-With": null,
                  }}
                  action={getFileUploadUrl()}
                  fileList={balanceClearParam.fileList}
                  onChange={handlebalanceClearUploadChange}
                  beforeUpload={handleBeforeUpload}
                  multiple={true}
                >
                  <Button>上載收款確認書</Button>
                </Upload>
              </Col>
            </Row>
          </div>
        </Modal>
      )}
    </div>
  );
};

OrderingPayment.propTypes = {
  userId: PropTypes.string,
  isLoading: PropTypes.bool,
};

OrderingPayment.defaultProps = {
  userId: "",
  isLoading: false,
};

export default OrderingPayment;
