import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
// Actions
import { setIsLoadingAction } from "@actions/storeAction";
// Components
import {
  Button,
  Drawer,
  Form,
  Input,
  notification,
  Table,
  TreeSelect,
} from "antd";
import Picker from "../../pages/stock/picker/picker";
// Style
import "./createPrescriptionDrawer.scss";
// Utils
import { classList } from "@utils/common";

const API = gql`
  query API($userId: ID) {
    assessmentResultList(userId: $userId) {
      id
      assessmentEndDate
      assessmentResultId
      assessmentStatus {
        id
      }
    }
  }
`;

const PRESCRIPTION_CREATE_ON_USER = gql`
  mutation PrescriptionCreateOnUser(
    $userId: ID
    $input: PrescriptionCreateInput
  ) {
    prescriptionCreateOnUser(userId: $userId, input: $input) {
      id
    }
  }
`;

const PRESCRIPTION_UPDATE_ON_USER = gql`
  mutation PrescriptionUpdateOnUser(
    $userId: ID
    $input: PrescriptionUpdateInput
  ) {
    prescriptionUpdateOnUser(userId: $userId, input: $input) {
      id
    }
  }
`;

function CreatePrescriptionDrawerComponent(props) {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.storeReducer);

  const {
    className,
    onClose,
    onSubmit,
    userId,
    visible,
    mode,
    prescription,
    isCreatePresciption,
  } = props;

  const [isEditing, setIsEditing] = useState(false);

  const getClassList = () =>
    classList({
      "CreatePrescriptionDrawer-Component": true,
      [className]: true,
    });

  const [assessment, setAssessment] = useState([]);
  const [count, setCount] = useState(0);
  const [isPickerShow, setIsPickerShow] = useState(false);
  const [name, setName] = useState(undefined);
  const [tableData, setTableData] = useState([
    {
      key: 0,
      deviceId: undefined,
      deviceName: undefined,
      reason: undefined,
      isEditing: false,
    },
  ]);
  const [targetRowKey, setTargetRowKey] = useState(undefined);

  // useEffect(() => {
  // }, [userId])

  const { data } = useQuery(API, { variables: { userId } });

  const [
    prescriptionCreateOnUser,
    {
      loading: prescriptionCreateOnUserLoading,
      error: prescriptionCreateOnUserError,
      data: prescriptionCreateOnUserData,
    },
  ] = useMutation(PRESCRIPTION_CREATE_ON_USER);
  const [
    prescriptionUpdateOnUser,
    {
      loading: prescriptionUpdateOnUserLoading,
      error: prescriptionUpdateOnUserError,
      data: prescriptionUpdateOnUserData,
    },
  ] = useMutation(PRESCRIPTION_UPDATE_ON_USER);

  const cols = useMemo(
    () => [
      {
        title: "貨物型號",
        dataIndex: "deviceId",
        render: (text, record) => (
          <>
            {record.deviceId && <div>{record.deviceName}</div>}
            {(mode === "add" || isEditing) && (
              <Button
                className="btn btn-secondary"
                onClick={() => onSelectDeviceClick(record.key)}
              >
                選擇型號
              </Button>
            )}
          </>
        ),
      },
      {
        title: "原因",
        dataIndex: "reason",
        render: (text, record) =>
          record.isEditing ? (
            <Input
              required
              placeholder="填寫原因"
              value={tableData.find((d) => d.key === record.key).reason}
              onChange={(evt) => onReasonChange(evt, record)}
            />
          ) : (
            <span>{text}</span>
          ),
      },
      {
        dataIndex: "action",
        render: (text, record) => {
          return record.isEditing ? (
            <Button
              type="danger"
              shape="circle"
              icon="minus"
              size="small"
              onClick={() => onDelete(record)}
            />
          ) : (
            <></>
          );
        },
      },

      // eslint-disable-next-line
    ],
    [tableData]
  );

  // console.log("tableData",tableData)

  const relatedAssessments = useMemo(() => {
    if (data) {
      return data.assessmentResultList.reduce(
        (acc, curr) => [
          ...acc,
          {
            key: curr.assessmentResultId,
            value: curr.assessmentResultId,
            title: `${curr.id}${
              curr.assessmentStatus.id === "complete"
                ? `_${moment(+curr.assessmentEndDate).format("YYYYMMDD")}`
                : ""
            }`,
          },
        ],
        []
      );
    } else {
      return [];
    }
  }, [data]);

  useEffect(() => {
    if (!isCreatePresciption && prescription) {
      setAssessment(
        prescription["relatedAssessment"].map((assessment) => {
          return assessment.id;
        })
      );
      setName(prescription.name);
      setTableData(
        prescription["prescriptionDevice"].map((devices, i) => {
          return {
            key: i,
            isEditing: false,
            deviceId: devices.device.id,
            deviceName: `${devices.device.referenceId} - ${devices.device.productInfo.nameChi}`,
            reason: devices.reason,
          };
        })
      );
    } else {
      setAssessment([]);
      setName("");
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [props, prescription]);

  useEffect(() => {
    if (mode === "add") {
      resetData();
    }
    dispatch(
      setIsLoadingAction(
        prescriptionCreateOnUserLoading || prescriptionUpdateOnUserLoading
      )
    );

    if (prescriptionCreateOnUserData) {
      notification.success({
        message: "成功新增診斷",
      });

      if (onSubmit) {
        onSubmit(prescriptionCreateOnUserData.prescriptionCreateOnUser.id);
      }
    }
    if (prescriptionUpdateOnUserData) {
      notification.success({
        message: "成功儲存診斷",
      });

      if (onSubmit) {
        onSubmit(prescriptionUpdateOnUserData.prescriptionUpdateOnUser.id);
      }
    }

    // eslint-disable-next-line
  }, [
    prescriptionCreateOnUserError,
    prescriptionCreateOnUserData,
    prescriptionUpdateOnUserLoading,
    prescriptionUpdateOnUserError,
    prescriptionUpdateOnUserData,
  ]);

  const onAdd = () => {
    const newTableData = {
      key: count + 1,
      isEditing: true,
      deviceId: undefined,
      deviceName: undefined,
      reason: undefined,
    };

    setTableData([...tableData, newTableData]);
    setCount((prev) => prev + 1);
  };

  const onAssessmentChange = (value) => {
    setAssessment(value);
  };

  const onDelete = (record) => {
    setTableData(tableData.filter((d) => d.key !== record.key));
  };

  console.log("tableData", tableData);

  const pickerOnClose = useCallback(() => {
    setIsPickerShow(false);
  }, []);

  const onDeviceChange = (device, key) => {
    console.log("device", device, key);
    let newTableData = tableData.reduce((acc, curr) => {
      if (curr.key === key) {
        curr.deviceId = device[0].id;
        curr.deviceName = `${device[0].referenceId} - ${device[0].productInfo.nameChi}`;
      }
      return [...acc, curr];
    }, []);
    setTableData(newTableData);
  };

  const onFormSubmit = async () => {
    const prescriptionDevice = tableData.reduce((acc, curr) => {
      const device = {
        deviceId: curr.deviceId,
        reason: curr.reason,
      };
      return [...acc, device];
    }, []);

    let args = {
      userId: userId,
      input: {
        name: name,
        datetime: new Date().valueOf(),
        personInCharge: store.user.tbId,
        prescriptionDevice: prescriptionDevice,
        relatedAssessmentId: assessment,
        ...(isEditing && { id: prescription.id }),
      },
    };

    if (isEditing) {
      await prescriptionUpdateOnUser({
        variables: args,
      });

      setIsEditing(false);
      setTableData(
        tableData.reduce((acc, curr) => {
          if (curr.deviceId && curr.reason) {
            curr.isEditing = false;
            return [...acc, curr];
          } else {
            return acc;
          }
        }, [])
      );
    } else {
      await prescriptionCreateOnUser({
        variables: args,
      });
    }
  };

  const onReasonChange = (evt, record) => {
    let newTableData = tableData.reduce((acc, curr) => {
      if (curr.key === record.key) {
        curr.reason = evt.target.value;
      }
      return [...acc, curr];
    }, []);
    setTableData(newTableData);
  };

  const onSelectDeviceClick = (key) => {
    setTargetRowKey(key);
    setIsPickerShow(true);
  };

  const resetData = () => {
    setTableData([
      {
        key: 0,
        deviceId: undefined,
        deviceName: undefined,
        reason: undefined,
        isEditing: true,
      },
    ]);
    setAssessment([]);
    setName(undefined);
    setCount(1);
  };

  console.log("tableData", tableData);

  return (
    <Drawer
      className={getClassList()}
      title="用戶診斷"
      placement="right"
      closable={true}
      onClose={onClose}
      visible={visible}
      width={750}
    >
      <Form onSubmit={onFormSubmit}>
        <div className="section name-container">
          <div className="title">診斷名稱</div>
          {mode === "view" && !isEditing ? (
            <span>{name}</span>
          ) : (
            <Input
              onChange={(evt) => setName(evt.target.value)}
              placeholder={"診斷名稱"}
              value={name}
            />
          )}
        </div>
        <div className="section related-assessment-container">
          <div className="title">相關評估</div>
          {mode === "view" && !isEditing ? (
            <span>{assessment.join(", ")}</span>
          ) : (
            <TreeSelect
              multiple
              onChange={onAssessmentChange}
              placeholder="請選擇"
              treeCheckable={true}
              treeData={relatedAssessments}
              value={assessment}
              filterTreeNode={(input, option) =>
                option.props.title.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            />
          )}
        </div>
        <div className="section table-header">
          <span className="title">已選擇貨物</span>
          {(mode === "add" || isEditing) && (
            <div className="btn-container">
              <Button className="btn btn-secondary" onClick={onAdd}>
                新增
              </Button>
            </div>
          )}
        </div>
        <Table columns={cols} dataSource={tableData} pagination={false} scroll={{x:"max-content"}}/>
        <div className="form-btn-container">
          {(mode === "add" || isEditing) && (
            <Button
              className="btn"
              type="primary"
              htmlType="submit"
              disabled={
                !name ||
                tableData.length === 0 ||
                assessment.length === 0 ||
                !tableData[0].deviceId ||
                !tableData[0].reason
              }
            >
              確定
            </Button>
          )}

          {mode === "view" && !isEditing && (
            <Button
              className="btn"
              type="primary"
              onClick={() => {
                setIsEditing(true);
                setTableData(
                  tableData.reduce((acc, curr) => {
                    if (curr.deviceId && curr.reason) {
                      curr.isEditing = true;
                      return [...acc, curr];
                    } else {
                      return acc;
                    }
                  }, [])
                );
              }}
            >
              編輯
            </Button>
          )}
        </div>
      </Form>
      <Picker
        pickerType="device"
        visible={isPickerShow}
        multiple={false}
        onChange={(device) => onDeviceChange(device, targetRowKey)}
        onClose={() => pickerOnClose()}
      />
    </Drawer>
  );
}

export default CreatePrescriptionDrawerComponent;

CreatePrescriptionDrawerComponent.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  prescription: PropTypes.object,
  isCreatePresciption: PropTypes.bool.isRequired,
};

CreatePrescriptionDrawerComponent.defalutProps = {
  className: "",
};
