import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// Components
import {Icon, Modal, notification, Upload} from 'antd';

// Style
import './upload.scss';

// Utils
import {getTokenHeader} from '@utils/api';
import {classList} from '@utils/common';
import {getFileUploadUrl} from '@utils/env';

function AssessmentUploadComponent(props) {
    const {
        fileAnswer,
        className,
        id,
        label,
        onChange,
    } = props;

    const [fileList, setFileList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(undefined);

    useEffect(() => {
        if (fileAnswer) {
            setFileList(parseFileList(fileAnswer));
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        onChange(parseFileList(fileList));

        // eslint-disable-next-line
    }, [fileList]);

    const getClassList = () => classList({
        'Assessment-Component': true,
        'AssessmentUpload-Component': true,
        [className]: true
    });

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const parseFileList = (fileList) => {
        return fileList.map((file, i) => {
            return {
                uid: i,
                key: file.key,
                name: file.name,
                size: file.size,
                url: file.url,
            }
        });
    };

    const onCancel = () => setPreviewVisible(false);

    const onUploadChange = ({fileList}) => {
        let fl = [...fileList];

        fl = fl.map(file => {
            if (file.error) {
                notification.error({
                    message: '上載失敗'
                });
            } else if (file.response) {
                file.name = file.response.data.name;
                file.size = file.response.data.size;
                file.key = file.response.data.key;
            }
            return file;
        });

        fl = fl.filter(file => !file.error);

        setFileList(fl);
    };

    const onPreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    return (
        <div className={getClassList()}>
            {label && (
                <label
                    className='AssessmentUpload-Component-label'
                    htmlFor={id}>
                    {label}
                </label>
            )}
            <Upload
                id={id}
                className='AssessmentUpload-Component-upload'
                action={getFileUploadUrl()}
                fileList={fileList}
                headers={{
                    'x-token': getTokenHeader(),
                    'X-Requested-With':null,
                }}
                listType='picture-card'
                onPreview={onPreview}
                onChange={onUploadChange}
            >
                <div className='AssessmentUpload-Component-upload-btn'>
                    <Icon type="camera" theme="filled"/>
                    <div className='ant-upload-text'>拖拉以上載相片</div>
                </div>
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={onCancel}>
                <img alt='Preview' style={{width: '100%'}} src={previewImage}/>
            </Modal>
        </div>
    );
}

export default AssessmentUploadComponent;

AssessmentUploadComponent.propTypes = {
    fileAnswer: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }))
    ]),
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
};

AssessmentUploadComponent.defalutProps = {
    fileAnswer: [],
    className: '',
    label: null,
    value: undefined
};
