/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Link, useHistory } from "react-router-dom";
// Components
import { Button, Dropdown, Menu, Tag } from "antd";
import CreateAssessmentModalComponent from "@components/createAssessmentModal/createAssessmentModal";
// Constants
import { PAGE, PAGE_NAME, PAGE_SIZE, ACCESS_LEVELS } from "@utils/constants";
// Layouts
import TableView from "@layouts/tableView/tableView";
// Styles
import "./userRegistered.scss";
// Utils
import { replaceUrlByParams } from "@utils/api";
import { hideHkid } from "@utils/common";
import { useAccessLevel } from "@utils/hook";

import { isEmpty, isEmptyObj } from "@utils/validate";
import CreatePrescriptionDrawerComponent from "../../../components/createPrescriptionDrawer/createPrescriptionDrawer";

const USER_LIST = gql`
  query userList($size: Int, $offset: Int, $keyword: String) {
    userList(size: $size, offset: $offset, keyword: $keyword) {
      userId
      username
      hkid
      nameChi
      nameEng
      telephone
      referralInfo {
        id
        approvalStatus
      }
      registrationDate
    }
  }
`;

const USER_COUNT = gql`
  query userCount($keyword: String) {
    userCount(keyword: $keyword)
  }
`;

function UserRegisteredPage(props) {
  let history = useHistory();
  const { isMatched: isDenied } = useAccessLevel([ACCESS_LEVELS.REFERRALS]);

  const BREADCRUMB = [
    {
      path: PAGE.USER_REGISTERED,
      name: PAGE_NAME.USER_REGISTERED,
    },
  ];

  const columns = [
    {
      title: "用戶號碼",
      dataIndex: "username",
      render: (text, record) => (
        <Link
          className="link"
          to={replaceUrlByParams(PAGE.USER_DETAIL, {
            id: record.referralInfo[0].id,
          })}
        >
          {record.username}
        </Link>
      ),
    },
    {
      title: "中文姓名",
      dataIndex: "nameChi",
    },
    {
      title: "英文姓名",
      dataIndex: "nameEng",
    },
    {
      title: "身份証號碼",
      dataIndex: "hkid",
      render: (text) => <span>{hideHkid(text)}</span>,
    },
    {
      title: "電話",
      dataIndex: "telephone",
      render: (text) => <span>{text.filter(Boolean).join(", ") || "-"}</span>,
    },
    {
      title: "審批狀況",
      dataIndex: "referralInfo[0].approvalStatus",
      render: (status) => (
        <span>
          {
            {
              pending: <Tag className="tag danger">未處理</Tag>,
              processing: <Tag className="tag warning">審批中</Tag>,
              rejected: <Tag className="tag">拒絕</Tag>,
              approved: <Tag className="tag success">接納</Tag>,
            }[status]
          }
        </span>
      ),
    },
    {
      title: "新增日期",
      dataIndex: "registrationDate",
    },
    {
      dataIndex: "action",
      render: (text, record) =>
        !isDenied && (
          <Dropdown overlay={() => renderMenu(record)}>
            <Button
              className="btn btn-secondary action-add"
              onMouseOver={() => setUserId(record.userId)}
            >
              新增
            </Button>
          </Dropdown>
        ),
    },
  ];

  const { location } = props;
  const [
    userList,
    { loading: userListLoading, error: userListError, data: userListData },
  ] = useLazyQuery(USER_LIST, {
    variables: {
      keyword: queryString.parse(location.search).keyword,
      size: +queryString.parse(location.search).pageSize + 1 || PAGE_SIZE + 1,
      offset:
        +(
          (queryString.parse(location.search).page - 1) *
          queryString.parse(location.search).pageSize
        ) || 0,
      startRegistrationDate: queryString.parse(location.search)
        .startRegistrationDate,
      endRegistrationDate: queryString.parse(location.search)
        .endRegistrationDate,
    },
  });
  const [userCount, { loading: userCountLoading, data: userCountData }] =
    useLazyQuery(USER_COUNT, {
      variables: {
        keyword: queryString.parse(location.search).keyword,
        startRegistrationDate: queryString.parse(location.search)
          .startRegistrationDate,
        endRegistrationDate: queryString.parse(location.search)
          .endRegistrationDate,
      },
    });

  const [
    isCreateAssessmentModalShow,
    setIsCreateAssessmentModalShow,
  ] = useState(false);
  const [
    isCreatePrescriptDrawerShow,
    setIsCreatePrescriptDrawerShow,
  ] = useState(false);
  const [isMaxPage, setIsMaxPage] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [userId, setUserId] = useState(undefined);

  useEffect(() => {
    const query = queryString.parse(location.search);

    history.push({
      search: `?${queryString.stringify(query)}`,
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getReferralInfoList();

    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (userListData?.userList) {
      const size = +queryString.parse(location.search).pageSize || PAGE_SIZE;
      const newUserListData = [...userListData?.userList];
      setTableData(newUserListData.slice(0, size));
      setIsMaxPage(newUserListData.length !== size + 1);
    }

    // eslint-disable-next-line
  }, [userListLoading, userListError, userListData]);

  const getReferralInfoList = () => {
    let q = queryString.parse(location.search);

    if (isEmptyObj(q)) {
      q = {
        page: 1,
        pageSize: PAGE_SIZE,
      };

      history.push({
        search: `?${queryString.stringify(q)}`,
      });
    } else {
      if (
        Object.prototype.hasOwnProperty.call(q, "keyword") &&
        isEmpty(q.keyword)
      ) {
        q = {
          page: 1,
          pageSize: PAGE_SIZE,
        };

        history.push({
          search: `?${queryString.stringify(q)}`,
        });
      } else {
        userList();
        userCount();
      }
    }
  };

  const onCreateAssessmentModalClose = () => {
    setIsCreateAssessmentModalShow(false);
  };

  const onCreateAssessmentModalSubmit = () => {
    onCreateAssessmentModalClose();
  };

  const onCreatePrescriptionDrawerClose = () => {
    setIsCreatePrescriptDrawerShow(false);
  };

  const onCreatePrescriptionDrawerSubmit = () => {
    onCreatePrescriptionDrawerClose();
  };

  const renderMenu = (user) => {
    // console.log("user",user)
    return (
      <Menu>
        <Menu.Item>
          <Button
            type="link"
            onClick={() => setIsCreateAssessmentModalShow(true)}
          >
            用戶評估
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button
            type="link"
            onClick={() => setIsCreatePrescriptDrawerShow(true)}
          >
            用戶診斷
          </Button>
        </Menu.Item>
        {user.referralInfo[0].approvalStatus !== "rejected" &&
        user.referralInfo[0].approvalStatus !== "pending" &&
        user.referralInfo[0].approvalStatus !== "processing" ? (
          <Menu.Item>
            <Button
              type="link"
              onClick={() =>
                history.push(
                  replaceUrlByParams(PAGE.ORDERING_DETAIL, { id: user.userId })
                )
              }
            >
              訂單
            </Button>
          </Menu.Item>
        ) : (
          false
        )}
      </Menu>
    );
  };

  return (
    <>
      <TableView
        {...props}
        className="User-registered-page"
        breadcrumb={BREADCRUMB}
        rowKey="username"
        columns={columns}
        isMaxPage={isMaxPage}
        isTableLoading={userListLoading}
        searchBarPlaceholder="搜尋用戶中文姓名/英文姓名/身份証號碼/電話"
        tableData={tableData}
        total={userCountData?.userCount}
      ></TableView>
      {userId && (
        <>
          {isCreateAssessmentModalShow && (
            <CreateAssessmentModalComponent
              userId={userId}
              visible={isCreateAssessmentModalShow}
              onClose={onCreateAssessmentModalClose}
              onSubmit={onCreateAssessmentModalSubmit}
            />
          )}
          {isCreatePrescriptDrawerShow && (
            <CreatePrescriptionDrawerComponent
              userId={userId}
              visible={isCreatePrescriptDrawerShow}
              onClose={onCreatePrescriptionDrawerClose}
              onSubmit={onCreatePrescriptionDrawerSubmit}
              mode="add"
            />
          )}
        </>
      )}
    </>
  );
}

export default UserRegisteredPage;

UserRegisteredPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
