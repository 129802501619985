import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import queryString from "query-string";

// Components

// Constants
import {PAGE, PAGE_NAME} from '@utils/constants';

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

// Styles
import './userCreate.scss';

// Utils
import TabsView from "../tabsView";

function UserCreatePage(props) {
    const BREADCRUMB = [
        {
            path: PAGE.USER_CREATE,
            name: PAGE_NAME.USER_CREATE
        }
    ];

    const {location} = props;

    const [userId, setUserId] = useState(undefined);
    const [hkid, setHKID] = useState(undefined);

    useEffect(() => {
        if (queryString.parse(location.search).userId) {
            setUserId(queryString.parse(location.search).userId);
        } else {
            if (queryString.parse(location.search).hkid) {
                setHKID(queryString.parse(location.search).hkid);
            }
        }

        // eslint-disable-next-line
    }, []);

    return (
        <StickyBreadcrumbPageComponent className='User-create-page' breadcrumb={BREADCRUMB}>
            <div className='content-inner'>
                <TabsView mode={'create'} userId={userId} hkid={hkid}/>
            </div>
        </StickyBreadcrumbPageComponent>
    );
}

export default UserCreatePage;

UserCreatePage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string,
        state: PropTypes.object,
    })
};
