/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import { gql } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import InventoryInspectionCreateModal from "@components/inventoryInspectionCreateModal/InventoryInspectionCreateModal";

// Actions
import { setIsLoadingAction } from "@actions/storeAction";

// Components
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Modal,
  Dropdown,
  Icon,
  notification,
  Row,
  Select,
  Table,
  message,
  Tabs,
  Tag,
  Menu,
  Typography,
} from "antd";
import CustPaginationComponent from "@components/custPagination/custPagination";
// import ViewDamageReportsDrawer from "@components/createDamageReportDrawer/createDamageReportDrawer";
import ViewDamageReportsDrawer from "@components/viewDamageReportsDrawer/viewDamageReportsDrawer";
import CleaningRecords from "@pages/workshop/inventoryDetail/cleaningRecords/cleaningRecords";
import InspectionRecords from "@pages/workshop/inventoryDetail/inspectionRecords/inspectionRecords";

// Constants
import {
  MOMENT_FORMAT,
  PAGE,
  PAGE_SIZE,
  ACCESS_LEVELS,
} from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";
import ActivityHistoryComponent from "@components/activityHistory/activityHistory";

// Styles
import "./inventoryDetail.scss";

// Utils
import { StatusTag } from "@utils/commonComponents";
import { replaceUrlByParams } from "@utils/api";
import { getPublicUrl } from "@utils/env";
import { useCallback } from "react";
import { usePopup, useAccessLevel } from "../../../utils/hook";

const INVENTORY_CLEANING_FRAGMENT = gql`
  fragment Fields on InventoryCleaning {
    id
    cleaningFlow {
      name
    }
    cleaningType {
      id
      name
    }
    cleaningFlowStage {
      seq
      cleaningStage {
        id
      }
      startDate
      endDate
      nextCleaningStage
    }
    date
  }
`;

const INVENTORY_RECORD_DISPOSAL_CREATE = gql`
  mutation InventoryRecordDisposalCreate(
    $input: InventoryRecordDisposalCreateInput
  ) {
    inventoryRecordDisposalCreate(input: $input) {
      activeDate
    }
  }
`;

const INVENTORY_RECORD_DISPOSAL_UPDATE = gql`
  mutation InventoryRecordDisposalUpdate(
    $input: InventoryRecordDisposalUpdateInput
  ) {
    inventoryRecordDisposalUpdate(input: $input) {
      followUpActionDate
    }
  }
`;

const INVENTORY_RECORD_UPDATE_ON_DEVICE = gql`
  mutation InventoryRecordUpdateOnDevice($input: InventoryRecordUpdateInput) {
    inventoryRecordUpdateOnDevice(input: $input) {
      id
    }
  }
`;

const API = gql`
  query EnumList {
    enumInventoryDisposalReasonList {
      id
      name
    }
  }
`;

const INVENTORY_RECORD_GET = gql`
  query inventoryRecordGet(
    $id: ID
    $referenceId: String
    $size: Int
    $offset: Int
  ) {
    inventoryRecordGet(id: $id, referenceId: $referenceId) {
      id
      referenceId
      firstStorageDate
      status {
        id
        name
      }
      warehouse {
        id
        name
      }
      activeInventoryCleaning {
        ...Fields
      }
      inventoryCleaningHistory {
        ...Fields
      }
      inventoryInspection {
        date
        orderingItem {
          user {
            deleted
            username
            nameChi
            referralInfo {
              id
            }
          }
        }
        id
        referenceId
        inspectionResult {
          name
          id
        }
        inspection {
          id
          name
          device {
            id
            inspection {
              id
              name
            }
          }
          part {
            id
          }
          remark
          trace {
            createDate
            lastModifiedDate
            creator {
              displayName
            }
          }
        }
        trace {
          creator {
            displayName
          }
          lastModifiedDate
        }
        manMadeDamage
        manMadeMissingParts
        suggestedClaimAmount
        reviewingStatus
      }
      product {
        id
        referenceId
        ... on Device {
          defaultPart {
            quantity
            part {
              id
              referenceId
            }
          }
          inspection {
            id
            name
          }
          productInfo {
            nameChi
            thumbnail {
              url
            }
            modelNumber
          }
        }
        ... on Part {
          inspection {
            id
            name
          }
          productInfo {
            nameChi
            thumbnail {
              url
            }
            modelNumber
          }
        }
        ... on Consumable {
          productInfo {
            nameChi
            thumbnail {
              url
            }
            modelNumber
          }
        }
        category {
          name
        }
      }
      defaultPart {
        id
        referenceId
        product {
          id
          referenceId
          ... on Part {
            productInfo {
              nameChi
            }
          }
          category {
            name
          }
        }
        status {
          id
          name
        }
      }
      extraPart {
        id
        referenceId
        product {
          id
          referenceId
          ... on Part {
            productInfo {
              nameChi
            }
          }
          category {
            name
          }
        }
        status {
          id
          name
        }
      }
      parentInventoryRecord {
        id
        referenceId
        product {
          id
          referenceId
          ... on Device {
            productInfo {
              nameChi
            }
          }
          category {
            name
          }
        }
        status {
          id
          name
        }
        defaultPart {
          id
          referenceId
          product {
            id
            referenceId
            ... on Part {
              productInfo {
                nameChi
              }
            }
            category {
              name
            }
          }
          status {
            id
            name
          }
        }
      }
      trace {
        lastModifiedDate
        activityHistory(size: $size, offSet: $offset) {
          staff {
            tbId
            displayName
          }
          content
          loggedDate
        }
      }
      inventoryRecordOrderHistory(size: $size, offset: $offset) {
        id
        referenceId
        user {
          deleted
          userId
          username
          nameChi
          referralInfo {
            id
          }
        }
        leasedDate
        returnedDate
        damageReportLastUpdateDate
        damageReportLastModifier {
          displayName
        }
      }
      inventoryRecordDisposal {
        activeDate
        disposalReason {
          id
        }
        disposalReasonRemark
        followUpAction {
          id
        }
        sellingPrice
        partyToReceived
        applicationRemarks
        applicationDate
        applicant {
          displayName
        }
        approvalDate
        approver {
          displayName
        }
        status {
          id
          name
        }
        approvalRemark
        followUpActionDate
        actionStaff {
          displayName
        }
      }
      qrcodeImageUrl
      printoutImageUrl
    }
  }
  ${INVENTORY_CLEANING_FRAGMENT}
`;

const INVENTORY_INSPECTION_CREATE = gql`
  mutation InventoryInspectionCreate($input: InventoryInspectionCreateInput) {
    inventoryInspectionCreate(input: $input) {
      id
    }
  }
`;

const INVENTORY_INSPECTION_GET = gql`
  query InventoryInspectionGet($id: ID) {
    inventoryInspectionGet(id: $id) {
      id
      referenceId
      inventoryRecord {
        id
        defaultPart {
          id
          referenceId
        }
        extraPart {
          id
          referenceId
        }
        product {
          __typename
          id
          referenceId
          ... on Device {
            defaultConsumable {
              consumable {
                id
                referenceId
              }
            }
          }
        }
      }
      inspection {
        id
        name
        device {
          id
        }
        part {
          id
        }
        inspectionCheckListQuestion {
          questionId
          title
          option
          answer {
            answer
            remark
            file {
              key
              url
              name
              size
            }
          }
        }
        remark
      }
      inspectionResult {
        id
        name
      }
      inspectionType {
        id
        name
      }
      inventoryRecordDisposal {
        activeDate
        disposalReason {
          id
          name
        }
        disposalReasonRemark
        followUpAction {
          id
          name
        }
        sellingPrice
        partyToReceived
        applicationRemarks
        applicationDate
        applicant {
          tbId
          username
          displayName
          allowedMutation
        }
        approvalDate
        applicationDate
        approver {
          tbId
          username
          displayName
          allowedMutation
        }
        status {
          id
          name
        }
        approvalRemark
        followUpActionDate
        actionStaff {
          tbId
          username
          displayName
          allowedMutation
        }
      }
      reviewResult
      remark
      date
      replacedPart {
        id
        referenceId
      }
      changingPart {
        id
        referenceId
      }
      changingConsumable {
        id
        referenceId
      }
    }
  }
`;

function InventoryDetailPage(props) {
  const dispatch = useDispatch();
  const { control, getValues, handleSubmit, setValue } = useForm();
  const [
    createInventoryInspection,
    { loading: inventoryInspectionLoading, data: inventoryInspectionData },
  ] = useMutation(INVENTORY_INSPECTION_CREATE);

  const ID = useMemo(() => props.match.params.inventoryId, [
    props.match.params.inventoryId,
  ]);

  const history = useHistory();

  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.CASE_MANAGEMENT,
    ACCESS_LEVELS.WORKSHOP_MANAGEMENT,
    ACCESS_LEVELS.FINANCIAL_MANAGEMENT,
    ACCESS_LEVELS.SERVICE_TEAM,
  ]);

  const { isMatched: cleaningAndInspectionIsDenied } = useAccessLevel([
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.CASE_MANAGEMENT,
    ACCESS_LEVELS.FINANCIAL_MANAGEMENT,
    ACCESS_LEVELS.SERVICE_TEAM,
  ]);

  const { isMatched: createInspectionIsDenied } = useAccessLevel([
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.CASE_MANAGEMENT,
    ACCESS_LEVELS.FINANCIAL_MANAGEMENT,
    ACCESS_LEVELS.SERVICE_TEAM,
  ]);

  const { isMatched: disposalApproveIsDenied } = useAccessLevel([
    ACCESS_LEVELS.ADMINISTRATION,
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.CASE_MANAGEMENT,
    ACCESS_LEVELS.OPERATION_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.CLERICAL_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.FINANCIAL_MANAGEMENT,
    ACCESS_LEVELS.SERVICE_TEAM,
    ACCESS_LEVELS.WORKSHOP_MANAGEMENT,
    ACCESS_LEVELS.ADMIN_EQUIPMENT_MANAGEMENT,
  ]);

  const { isMatched: disposalApproveIsAllowed } = useAccessLevel([
    ACCESS_LEVELS.SUPERADMIN_EQUIPMENT_MANAGEMENT,
  ]);

  const { isMatched: leaseActionIsDenied } = useAccessLevel([
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.WORKSHOP_MANAGEMENT,
    ACCESS_LEVELS.FINANCIAL_MANAGEMENT,
    ACCESS_LEVELS.SERVICE_TEAM,
  ]);

  const { isMatched: userIdLinkDisabled } = useAccessLevel([
    ACCESS_LEVELS.REFERRALS,
    ACCESS_LEVELS.OPERATION_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.CLERICAL_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.FINANCIAL_MANAGEMENT,
    ACCESS_LEVELS.SERVICE_TEAM,
    ACCESS_LEVELS.WORKSHOP_MANAGEMENT,
    ACCESS_LEVELS.ADMIN_EQUIPMENT_MANAGEMENT,
  ]);

  const { Option } = Select;

  const { location } = props;

  const { data: enumList } = useQuery(API);

  const query = useMemo(() => {
    const { page, pageSize } = queryString.parse(location.search);
    return {
      ...queryString.parse(location.search),
      page: page ? Number(page) : 1,
      pageSize: PAGE_SIZE,
    };
  }, [location.search]);

  const { loading, data, refetch } = useQuery(INVENTORY_RECORD_GET, {
    variables: {
      id: ID,
      offset: +((query.page - 1) * query.pageSize) || 0,
      size: +(query.pageSize || PAGE_SIZE) + 1,
    },
  });

  const [
    inventoryRecordGet,
    {
      loading: inventoryRecordGetLoading,
      data: inventoryRecordGetData,
      error: inventoryRecordGetError,
    },
  ] = useLazyQuery(INVENTORY_RECORD_GET);

  const [
    inventoryRecordDisposalCreate,
    {
      loading: inventoryRecordDisposalCreateLoading,
      data: inventoryRecordDisposalCreateData,
    },
  ] = useMutation(INVENTORY_RECORD_DISPOSAL_CREATE);
  const [
    inventoryRecordDisposalUpdate,
    {
      loading: inventoryRecordDisposalUpdateLoading,
      data: inventoryRecordDisposalUpdateData,
    },
  ] = useMutation(INVENTORY_RECORD_DISPOSAL_UPDATE);
  const [
    inventoryRecordUpdateOnDevice,
    {
      loading: inventoryRecordUpdateOnDeviceLoading,
      data: inventoryRecordUpdateOnDeviceData,
      error: inventoryRecordUpdateOnDeviceError,
    },
  ] = useMutation(INVENTORY_RECORD_UPDATE_ON_DEVICE);

  const [
    viewDamageReportsDrawerParams,
    setViewDamageReportsDrawerParams,
  ] = useState({
    visible: false,
  });

  const inventory = useMemo(() => {
    return data?.inventoryRecordGet;
  }, [data]);

  console.log("inventory", inventory);

  const BREADCRUMB = useMemo(
    () => [
      {
        path: replaceUrlByParams(PAGE.STOCK_INVENTORY_DETAIL, {
          inventoryId: ID,
        }),
        name: inventory?.product.productInfo.nameChi,
      },
    ],
    [ID, inventory]
  );

  const onDeletePart = useCallback(
    (record) => {
      const partTypeStr =
        record.partType === "default" ? "預設零件" : "額外零件";
      const refId = record.referenceId;
      const name = record.product.productInfo.nameChi;

      Modal.confirm({
        title: "確定移除?",
        content: (
          <div>
            <p style={{ marginTop: "20px" }}>
              {partTypeStr} - {refId}
            </p>
            <span>{name}</span>
          </div>
        ),
        okText: "確定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          const defautlPartList = inventory?.defaultPart.filter(
            (dp) => dp.referenceId !== refId
          );
          try {
            inventoryRecordUpdateOnDevice({
              variables: {
                input: {
                  id: ID,
                  productId: inventory?.product.id,
                  defaultPartInventoryRecordId: defautlPartList.map(
                    (dp) => dp.id
                  ),
                },
              },
            });
          } catch (e) {
            console.error(e);
          }
        },
      });
    },
    [ID, inventory, inventoryRecordUpdateOnDevice]
  );

  const onDeleteDevice = useCallback(
    (record) => {
      const refId = record.referenceId;
      const name = record.product.productInfo.nameChi;

      Modal.confirm({
        title: "確定移除?",
        content: (
          <p style={{ marginTop: "20px" }}>
            {name} - {refId}
          </p>
        ),
        okText: "確定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          const defautlPartList = inventory?.parentInventoryRecord?.defaultPart.filter(
            (dp) => dp.id !== ID
          );
          try {
            inventoryRecordUpdateOnDevice({
              variables: {
                input: {
                  id: inventory?.parentInventoryRecord?.id,
                  productId: inventory?.parentInventoryRecord?.product?.id,
                  defaultPartInventoryRecordId: defautlPartList.map(
                    (dp) => dp.id
                  ),
                },
              },
            });
          } catch (e) {
            console.error(e);
          }
        },
      });
    },
    [ID, inventory, inventoryRecordUpdateOnDevice]
  );

  const [inspectionCreateProps, openPopup] = usePopup({
    visible: false,
    params: {
      inventoryRecordId: ID,
      orderingItemId: null,
      inspectionType: null,
    },
  });

  const LEASE_COLS = useMemo(
    () => [
      {
        title: "用戶號碼",
        dataIndex: "user",
        render: (user, record) => {
          const redPoint = Object.values(record?.redDot ?? {}).reduce(
            (acc, flag) => {
              if (typeof flag === "boolean") return acc || flag;
              return acc;
            },
            false
          );
          return (
            <>
              {redPoint && <span className="redPoint"></span>}
              {!userIdLinkDisabled && !user?.deleted ? (
                <Link
                  className="link"
                  to={replaceUrlByParams(PAGE.USER_DETAIL, {
                    id: user.referralInfo[0].id,
                  })}
                >
                  {user.username}
                </Link>
              ) : (
                `${user.username} ${user?.deleted ? "(Deleted User)" : ""}`
              )}
            </>
          );
        },
      },
      {
        title: "中文姓名",
        dataIndex: "user.nameChi",
        render: (text) => text || "-",
      },
      {
        title: "租借日期",
        dataIndex: "leasedDate",
        render: (text, record) => (
          <span>{text ? moment(text).format(MOMENT_FORMAT) : "-"}</span>
        ),
      },
      {
        title: "終止租借日期",
        dataIndex: "returnedDate",
        render: (text) => (
          <span>{text ? moment(text).format(MOMENT_FORMAT) : "-"}</span>
        ),
      },
      {
        title: "損毀報告更新日期",
        dataIndex: "damageReportLastUpdateDate",
        render: (text, record) => {
          return <span>{text ? moment(text).format(MOMENT_FORMAT) : "-"}</span>;
        },
      },
      {
        title: "損毀報告更新人",
        dataIndex: "damageReportLastModifier.displayName",
        render: (text, record) => {
          return <span>{text ? text : "-"}</span>;
        },
      },
      {
        dataIndex: "action",
        render: (text, item, index) => {
          const onMenuItemClick = ({ key, item }) => {
            switch (key) {
              case "check_damage_report": {
                setViewDamageReportsDrawerParams({
                  visible: true,
                  isCreate: false,
                  userId: item.user.userId,
                  orderingItemId: item.id,
                });
                break;
              }
              case "create_inspection": {
                openPopup({
                  inventoryRecordId: ID,
                  inspectionType: "doorToDoorInspection",
                  orderingItemId: item?.id,
                });
                break;
              }
              default: {
                break;
              }
            }
          };
          const checkItemDisabled = (item) => {
            switch (item) {
              default:
                return false;
            }
          };

          const menu = (item) => {
            return (
              <Menu
                onClick={({ key }) => {
                  onMenuItemClick({ key, item: item });
                }}
              >
                <Menu.Item
                  key="check_damage_report"
                  disabled={checkItemDisabled(item)}
                >
                  查看/更新損毀報告
                </Menu.Item>
                {!createInspectionIsDenied &&
                  inventory?.status?.id === `leasing` && (
                    <Menu.Item key="create_inspection">
                      新增上門檢查／維修紀錄表
                    </Menu.Item>
                  )}
              </Menu>
            );
          };
          return (
            !leaseActionIsDenied && (
              <Dropdown overlay={menu(item)} placement="bottomRight">
                <Button className="btn btn-secondary">
                  <Icon type="ellipsis" />
                </Button>
              </Dropdown>
            )
          );
        },
      },
    ],
    [
      ID,
      createInspectionIsDenied,
      inventory,
      leaseActionIsDenied,
      openPopup,
      userIdLinkDisabled,
    ]
  );

  const PARTS_COLS = useMemo(
    () => [
      {
        title: "零件編號",
        dataIndex: "referenceId",
      },
      {
        title: "名稱",
        dataIndex: "product.productInfo.nameChi",
      },
      {
        title: "子分類",
        dataIndex: "product.category.name",
      },
      {
        title: "狀態",
        dataIndex: "status",
        render: (status) => <StatusTag status={status} />,
      },
      {
        title: "零件類別",
        dataIndex: "partType",
        render: (text) => partTypeChip(text),
      },
      {
        key: "action",
        render: (text, record) => (
          <Button
            size="small"
            shape="circle"
            type="danger"
            icon="minus"
            onClick={() => onDeletePart(record)}
          />
        ),
      },
    ],
    [onDeletePart]
  );

  const DEVICE_COLS = useMemo(
    () => [
      {
        title: "貨物編號",
        dataIndex: "referenceId",
      },
      {
        title: "名稱",
        dataIndex: "product.productInfo.nameChi",
      },
      {
        title: "子分類",
        dataIndex: "product.category.name",
      },
      {
        title: "狀態",
        dataIndex: "status",
        render: (status) => <StatusTag status={status} />,
      },
      {
        key: "action",
        render: (text, record) =>
          !isDenied && (
            <Button
              size="small"
              shape="circle"
              type="danger"
              icon="minus"
              onClick={() => onDeleteDevice(record)}
            />
          ),
      },
    ],
    [isDenied, onDeleteDevice]
  );

  const [activeTab, setActiveTab] = useState(
    queryString.parse(location.search).activeTab || "lease"
  );
  const [isViewMode, setIsViewMode] = useState(true);
  const [isAddPartModalShow, setIsAddPartModalShow] = useState(false);
  const [isAddDeviceModalShow, setIsAddDeviceModalShow] = useState(false);
  const [partId, setPartId] = useState(undefined);
  const [deviceId, setDeviceId] = useState(undefined);
  const [termiateStatus, setTermiateStatus] = useState(undefined);

  const [selectedCleaningId, setSelectedCleaningId] = useState(undefined);

  //prefill
  useEffect(() => {
    dispatch(setIsLoadingAction(loading));
    if (data) {
      if (data.inventoryRecordGet.inventoryRecordDisposal) {
        // setIsViewMode(true);
        setTermiateStatus(
          data.inventoryRecordGet.inventoryRecordDisposal.status.id
        );

        setValue(
          "activeDate",
          data.inventoryRecordGet.inventoryRecordDisposal?.activeDate
            ? moment(
                data.inventoryRecordGet.inventoryRecordDisposal?.activeDate
              )
            : undefined
        );
        setValue(
          "followUpAction",
          data.inventoryRecordGet.inventoryRecordDisposal?.followUpAction?.id
        );
        setValue(
          "disposalReason",
          data.inventoryRecordGet.inventoryRecordDisposal?.disposalReason?.id
        );
        setValue(
          "disposalReasonRemark",
          data.inventoryRecordGet.inventoryRecordDisposal?.disposalReasonRemark
        );
        setValue(
          "sellingPrice",
          data.inventoryRecordGet.inventoryRecordDisposal?.sellingPrice
        );
        setValue(
          "partyToReceived",
          data.inventoryRecordGet.inventoryRecordDisposal?.partyToReceived
        );
        setValue(
          "applicationRemarks",
          data.inventoryRecordGet.inventoryRecordDisposal?.applicationRemarks
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [loading, data]);

  useEffect(() => {
    dispatch(setIsLoadingAction(inventoryRecordDisposalCreateLoading));

    if (inventoryInspectionData) {
      history.push(
        replaceUrlByParams(PAGE.MOBILE_INSPECTION_FORM, {
          id: inventoryInspectionData?.inventoryInspectionCreate?.id,
        })
      );
    }

    if (inventoryRecordDisposalCreateData) {
      notification.success({
        message: "成功提交落架要求",
      });

      refetch();
    }

    // eslint-disable-next-line
  }, [
    inventoryRecordDisposalCreateLoading,
    inventoryRecordDisposalCreateData,
    inventoryInspectionData,
  ]);

  useEffect(() => {
    dispatch(setIsLoadingAction(inventoryRecordDisposalUpdateLoading));

    if (inventoryRecordDisposalUpdateData) {
      notification.success({
        message: "成功更新落架要求",
      });

      refetch();
    }

    // eslint-disable-next-line
  }, [inventoryRecordDisposalUpdateLoading, inventoryRecordDisposalUpdateData]);

  const disposalStatusChip = (type) => {
    return {
      approving: <Tag className="tag warning">審批中</Tag>,
      approved: <Tag className="tag success">已批准</Tag>,
      rejected: <Tag className="tag danger">已拒絕</Tag>,
    }[type];
  };

  const partTypeChip = (type) => {
    return {
      default: <Tag className="tag">預設零件</Tag>,
      extra: <Tag className="tag success">額外零件</Tag>,
    }[type];
  };

  const onTabChange = (tab) => {
    setActiveTab(tab);
    history.push({
      search: queryString.stringify({ activeTab: tab }),
    });
  };

  useEffect(() => {
    dispatch(setIsLoadingAction(inventoryRecordGetLoading));
    if (
      !inventoryRecordGetLoading &&
      inventoryRecordGetData &&
      !inventoryRecordGetError
    ) {
      if (partId) {
        const isDefaultPart = inventory?.product?.defaultPart?.find(
          (defaultPart) =>
            defaultPart?.part?.referenceId ===
            inventoryRecordGetData?.inventoryRecordGet?.product?.referenceId
        );
        if (!isDefaultPart) {
          notification.error({
            message: `${inventoryRecordGetData?.inventoryRecordGet?.product?.referenceId} 不是預設零件`,
            duration: 5,
          });
          return;
        }
        // add parts
        inventoryRecordUpdateOnDevice({
          variables: {
            input: {
              id: ID,
              productId: inventory.product.id,
              defaultPartInventoryRecordId: [
                ...inventory.defaultPart.map((dp) => dp.id),
                inventoryRecordGetData.inventoryRecordGet.id,
              ],
            },
          },
        });
      } else if (deviceId) {
        // add device
        inventoryRecordUpdateOnDevice({
          variables: {
            input: {
              id: inventoryRecordGetData?.inventoryRecordGet?.id,
              productId:
                inventoryRecordGetData?.inventoryRecordGet?.product?.id,
              defaultPartInventoryRecordId: [
                ...inventoryRecordGetData?.inventoryRecordGet?.defaultPart.map(
                  (dp) => dp.id
                ),
                ID,
              ],
            },
          },
        });
      } else {
        // other product type, do nothing
      }
      onAddPartCancel();
      onAddDeviceCancel();
    }

    // eslint-disable-next-line
  }, [
    inventoryRecordGetLoading,
    inventoryRecordGetData,
    inventoryRecordGetError,
  ]);

  useEffect(() => {
    dispatch(setIsLoadingAction(inventoryRecordUpdateOnDeviceLoading));

    if (
      !inventoryRecordUpdateOnDeviceLoading &&
      inventoryRecordUpdateOnDeviceData &&
      !inventoryRecordUpdateOnDeviceError
    ) {
      notification.success({
        message: "成功新增 / 移除零件",
      });
      onAddPartCancel();
      refetch();
    }

    // eslint-disable-next-line
  }, [
    inventoryRecordUpdateOnDeviceLoading,
    inventoryRecordUpdateOnDeviceData,
    inventoryRecordUpdateOnDeviceError,
  ]);

  const onAddPartSubmit = () => {
    inventoryRecordGet({
      variables: {
        referenceId: partId,
      },
    });
  };

  const onAddPartCancel = () => {
    setPartId(undefined);
    setIsAddPartModalShow(false);
  };

  const onAddDeviceSubmit = () => {
    inventoryRecordGet({
      variables: {
        referenceId: deviceId,
      },
    });
  };

  const onAddDeviceCancel = () => {
    setDeviceId(undefined);
    setIsAddDeviceModalShow(false);
  };

  const onTerminateFormSubmit = async (formData) => {
    const input = {
      inventoryRecordId: ID,
      activeDate: formData.activeDate
        ? formData.activeDate.format(MOMENT_FORMAT)
        : null,
      disposalReason: formData.disposalReason,
      disposalReasonRemark: formData.disposalReasonRemark,
      followUpAction: formData.followUpAction,
      sellingPrice: formData.sellingPrice,
      partyToReceived: formData.partyToReceived,
      applicationRemarks: formData.applicationRemarks,
    };

    await inventoryRecordDisposalCreate({
      variables: {
        input,
      },
    });
  };

  const onDisposalApprovalClick = async (status) => {
    const input = {
      inventoryRecordId: ID,
      status: status,
      approvalRemark: getValues().approvalRemark,
    };

    await inventoryRecordDisposalUpdate({
      variables: {
        input,
      },
    });
  };

  const onFollowUpActionSave = async () => {
    const input = {
      inventoryRecordId: ID,
      followUpActionDate: getValues().followUpActionDate
        ? getValues().followUpActionDate.format("YYYY-MM-DD")
        : null,
    };

    await inventoryRecordDisposalUpdate({
      variables: {
        input,
      },
    });
  };

  const isMaxPage = useMemo(() => {
    return inventoryRecordGetData?.inventoryRecordGet
      ?.inventoryRecordOrderHistory
      ? inventoryRecordGetData?.inventoryRecordGet?.inventoryRecordOrderHistory
          ?.length <= PAGE_SIZE
      : true;
  }, [inventoryRecordGetData]);

  const inventoryCleaning = useMemo(() => {
    const array = [];
    if (inventory?.activeInventoryCleaning)
      array.push(inventory?.activeInventoryCleaning);
    if (inventory?.inventoryCleaningHistory?.length)
      array.push(...inventory?.inventoryCleaningHistory);
    return array;
  }, [inventory]);

  useEffect(() => {
    if (inventoryCleaning) {
      setSelectedCleaningId(inventoryCleaning[0]?.id);
    }
  }, [inventoryCleaning]);

  return inventory ? (
    <StickyBreadcrumbPageComponent
      className="Stock-inventory-detail-page"
      breadcrumb={BREADCRUMB}
    >
      <div className="content-inner">
        <div className="info-container">
          <div className="info">
            <div className="img">
              {inventory.product.productInfo.thumbnail?.url && (
                <img
                  alt="Inventory"
                  src={inventory.product.productInfo.thumbnail?.url}
                />
              )}
            </div>
            <div className="data">
              <Row gutter={[8, 16]} type="flex">
                <Col xs={12} lg={6}>
                  <div className="data-title">器材編號</div>
                  <span className="data-content">{inventory.referenceId}</span>
                </Col>
                <Col xs={12} lg={6}>
                  <div className="data-title">型號</div>
                  <span className="data-content">
                    {inventory.product.productInfo.modelNumber}
                  </span>
                </Col>
                <Col xs={12} lg={6}>
                  <div className="data-title">首次入倉日期</div>
                  <span className="data-content">
                    {moment(inventory.firstStorageDate).format(MOMENT_FORMAT)}
                  </span>
                </Col>
                <Col xs={12} lg={6}>
                  <div className="data-title">現時狀態</div>
                  <StatusTag status={inventory?.status} />
                </Col>
                <Col xs={12} lg={6}>
                  <div className="data-title">工場</div>
                  <span className="data-content">
                    {inventory?.warehouse?.name}
                  </span>
                </Col>
                <Col span={24}>
                  {!cleaningAndInspectionIsDenied && (
                    <Link
                      to={replaceUrlByParams(PAGE.DESKTOP_INVENTORY_DETAIL, {
                        id: ID,
                      })}
                    >
                      <div className="data-title">清潔流程檢查/維修記錄</div>
                    </Link>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <div className="qrCode">
            <img
              src={data?.inventoryRecordGet.qrcodeImageUrl}
              alt="qrcode"
              className="qrCodeImg"
            />
            <br />
            {!isDenied && (
              <Button href={data?.inventoryRecordGet.printoutImageUrl}>
                下載 QR Code
              </Button>
            )}
          </div>
        </div>
        {inventory?.product?.__typename === "Device" && (
          <div className="section parts">
            <div className="section-title">
              <Typography.Title level={4}>現時配備零件</Typography.Title>
              {!isDenied && (
                <Button
                  type="primary"
                  onClick={() => setIsAddPartModalShow(true)}
                >
                  新增零件
                </Button>
              )}
            </div>
            <Modal
              cancelText="取消"
              okText="確定"
              okButtonProps={{ disabled: !partId }}
              onOk={onAddPartSubmit}
              onCancel={onAddPartCancel}
              title="新增零件"
              visible={isAddPartModalShow}
            >
              <div>
                <p>零件編號</p>
                <Input
                  value={partId}
                  onChange={(evt) => setPartId(evt.target.value)}
                />
              </div>
            </Modal>
            <Table
              columns={PARTS_COLS}
              dataSource={[
                ...data?.inventoryRecordGet.defaultPart.map((p) => ({
                  ...p,
                  partType: "default",
                })),
                ...data?.inventoryRecordGet.extraPart.map((p) => ({
                  ...p,
                  partType: "extra",
                })),
              ]}
              rowKey={"referenceId"}
              pagination={false}
              scroll={{x: "max-content"}}
            />
          </div>
        )}
        {inventory?.product?.__typename === "Part" && (
          <div className="section parts">
            <div className="section-title">
              <Typography.Title level={4}>現時配予貨物</Typography.Title>
              {!inventory?.parentInventoryRecord && (
                <Button
                  type="primary"
                  onClick={() => setIsAddDeviceModalShow(true)}
                >
                  新增貨物
                </Button>
              )}
            </div>
            <Modal
              cancelText="取消"
              okText="確定"
              okButtonProps={{ disabled: !deviceId }}
              onOk={onAddDeviceSubmit}
              onCancel={onAddDeviceCancel}
              title="新增零件"
              visible={isAddDeviceModalShow}
            >
              <div>
                <p>貨物編號</p>
                <Input
                  value={deviceId}
                  onChange={(evt) => setDeviceId(evt.target.value)}
                />
              </div>
            </Modal>
            <Table
              columns={DEVICE_COLS}
              dataSource={
                inventory?.parentInventoryRecord
                  ? [inventory?.parentInventoryRecord]
                  : []
              }
              rowKey={"referenceId"}
              pagination={false}
              scroll={{x: "max-content"}}
            />
          </div>
        )}
        <div className="section records">
          <div className="section-title">
            <Typography.Title level={4}>過往紀錄</Typography.Title>
          </div>
          <InventoryInspectionCreateModal {...inspectionCreateProps} />
          <Tabs
            className="records-table"
            defaultActiveKey={activeTab}
            onChange={onTabChange}
          >
            <Tabs.TabPane tab="租借" key="lease">
              <div className="paging-container">
                <div className="right">
                  <CustPaginationComponent
                    isMinPage={query.page === 1}
                    isMaxPage={isMaxPage}
                    page={query.page}
                    pageSize={PAGE_SIZE}
                    onPageChange={(page) => {
                      history.push({
                        pathname: location.pathname,
                        search: queryString.stringify({
                          ...query,
                          page,
                        }),
                      });
                    }}
                  />
                </div>
              </div>
              {viewDamageReportsDrawerParams?.visible && (
                <ViewDamageReportsDrawer
                  visible={viewDamageReportsDrawerParams?.visible}
                  orderingItemId={viewDamageReportsDrawerParams?.orderingItemId}
                  userId={viewDamageReportsDrawerParams.userId}
                  canCreate={true}
                  onClose={() =>
                    setViewDamageReportsDrawerParams((prev) => ({
                      ...prev,
                      visible: false,
                    }))
                  }
                  inventoryRecordId={inventory?.id}
                ></ViewDamageReportsDrawer>
              )}
              <Table
                columns={LEASE_COLS}
                dataSource={inventory.inventoryRecordOrderHistory}
                rowKey="referenceId"
                pagination={false}
                scroll={{x: "max-content"}}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              className="section"
              tab="清潔流程/記錄"
              key="cleaningRecords"
            >
              {inventoryCleaning.length > 0 ? (
                <div>
                  <Select
                    style={{ width: "100%", paddingBottom: "10px" }}
                    value={selectedCleaningId}
                    onChange={(data) => setSelectedCleaningId(data)}
                  >
                    {inventoryCleaning?.map((item) => (
                      <Select.Option key={item?.id} value={item?.id}>{`${
                        item?.date
                      } | ${item?.cleaningType?.name} ${
                        inventory?.activeInventoryCleaning?.id === item.id
                          ? "(進行中)"
                          : ""
                      }`}</Select.Option>
                    ))}
                  </Select>
                  <CleaningRecords
                    loading={inventoryRecordGetLoading}
                    selectedCleaningId={selectedCleaningId}
                  />
                </div>
              ) : (
                <div>未有清潔流程記錄</div>
              )}
            </Tabs.TabPane>
            <Tabs.TabPane
              className="section"
              tab="檢查/維修記錄"
              key="inspectionRecords"
            >
              <>
                {!createInspectionIsDenied &&
                  ![
                    "repairing",
                    "toBeDisposed",
                    "reserved",
                    "cleaning",
                    "terminated",
                  ].includes(inventory?.status.id) && (
                    <Button
                      onClick={() =>
                        openPopup({
                          inventoryRecordId: ID,
                          orderingItemId: null,
                          inspectionType: "doorToDoorInspection",
                        })
                      }
                    >
                      新增上門檢查/維修紀錄表
                    </Button>
                  )}
                <InspectionRecords
                  loading={loading}
                  inventoryRecord={inventory}
                />
              </>
            </Tabs.TabPane>
            <Tabs.TabPane tab="落架" key="terminate">
              {data?.inventoryRecordGet?.inventoryRecordDisposal ? (
                <Form
                  onSubmit={handleSubmit(onTerminateFormSubmit)}
                  className="terminate-form"
                >
                  <Row gutter={[16, 0]}>
                    <Col xs={12} md={8}>
                      <Form.Item label="生效日期">
                        <Controller
                          as={<DatePicker />}
                          control={control}
                          disabled={isViewMode}
                          defaultValue={
                            data?.inventoryRecordGet?.inventoryRecordDisposal
                              ?.activeDate
                              ? moment(
                                  data.inventoryRecordGet
                                    .inventoryRecordDisposal?.activeDate
                                )
                              : null
                          }
                          name="activeDate"
                          placeholder={undefined}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Item label="處理方法">
                        <Controller
                          as={
                            <Select>
                              <Select.Option value="donate">捐贈</Select.Option>
                              <Select.Option value="resale">轉售</Select.Option>
                              <Select.Option value="reimburse">
                                報銷
                              </Select.Option>
                            </Select>
                          }
                          control={control}
                          disabled={isViewMode}
                          defaultValue={
                            data.inventoryRecordGet.inventoryRecordDisposal
                              ?.followUpAction?.id
                          }
                          name="followUpAction"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col xs={24} md={8}>
                      <Form.Item label="落架原因">
                        <Controller
                          as={
                            <Select>
                              {enumList?.enumInventoryDisposalReasonList.map(
                                (option) => (
                                  <Select.Option
                                    key={option.id}
                                    value={option.id}
                                  >
                                    {option.name}
                                  </Select.Option>
                                )
                              )}
                            </Select>
                          }
                          control={control}
                          disabled={isViewMode}
                          defaultValue={
                            data.inventoryRecordGet.inventoryRecordDisposal
                              ?.disposalReason?.id
                          }
                          name="disposalReason"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item label="落架原因備註">
                        {isViewMode ? (
                          <span>
                            {data?.inventoryRecordGet?.inventoryRecordDisposal
                              ?.disposalReasonRemark || "-"}
                          </span>
                        ) : (
                          <Controller
                            as={<Input.TextArea />}
                            control={control}
                            disabled={isViewMode}
                            name="disposalReasonRemark"
                            defaultValue={
                              data.inventoryRecordGet.inventoryRecordDisposal
                                ?.disposalReasonRemark
                            }
                            placeholder={"填寫原因備註"}
                            rows={5}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Item label="轉售價錢（港幣）">
                        {isViewMode ? (
                          <span>
                            {data?.inventoryRecordGet?.inventoryRecordDisposal
                              ?.sellingPrice
                              ? `$ ${data.inventoryRecordGet
                                  ?.inventoryRecordDisposal.sellingPrice / 100}`
                              : "-"}
                          </span>
                        ) : (
                          <Controller
                            as={<InputNumber />}
                            control={control}
                            disabled={isViewMode}
                            defaultValue={
                              data.inventoryRecordGet.inventoryRecordDisposal
                                ?.sellingPrice
                            }
                            name="sellingPrice"
                            formatter={(val) =>
                              `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            min={0}
                            parser={(val) => val.replace(/\$\s?|(,*)/g, "")}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={8}>
                      <Form.Item label="接受捐贈／轉售者名稱">
                        {isViewMode ? (
                          <span>
                            {data?.inventoryRecordGet?.inventoryRecordDisposal
                              ?.partyToReceived || "-"}
                          </span>
                        ) : (
                          <Controller
                            as={<Input />}
                            control={control}
                            disabled={isViewMode}
                            defaultValue={
                              data.inventoryRecordGet.inventoryRecordDisposal
                                ?.partyToReceived
                            }
                            name="partyToReceived"
                            placeholder="名稱"
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item label="申請備註">
                        {isViewMode ? (
                          <span>
                            {data?.inventoryRecordGet?.inventoryRecordDisposal
                              ?.applicationRemarks || "-"}
                          </span>
                        ) : (
                          <Controller
                            as={<Input.TextArea />}
                            control={control}
                            disabled={isViewMode}
                            defaultValue={
                              data.inventoryRecordGet.inventoryRecordDisposal
                                ?.applicationRemarks
                            }
                            name="applicationRemarks"
                            placeholder={"填寫備註"}
                            rows={5}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  {termiateStatus && (
                    <>
                      <Row gutter={[16, 0]}>
                        <Col xs={8} md={6}>
                          <Form.Item label="申請日期">
                            <span>
                              {moment(
                                data?.inventoryRecordGet
                                  ?.inventoryRecordDisposal?.applicationDate
                              ).format(MOMENT_FORMAT)}
                            </span>
                          </Form.Item>
                        </Col>
                        <Col xs={8} md={6}>
                          <Form.Item label="申請人">
                            <span>
                              {data?.inventoryRecordGet?.inventoryRecordDisposal
                                ?.applicant?.displayName || "-"}
                            </span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[16, 0]}>
                        <Col xs={8} md={6}>
                          <Form.Item label="審批日期">
                            <span>
                              {data?.inventoryRecordGet?.inventoryRecordDisposal
                                ?.approvalDate
                                ? moment(
                                    data.inventoryRecordGet
                                      .inventoryRecordDisposal.approvalDate
                                  ).format(MOMENT_FORMAT)
                                : "-"}
                            </span>
                          </Form.Item>
                        </Col>
                        <Col xs={8} md={6}>
                          <Form.Item label="審批人">
                            <span>
                              {data?.inventoryRecordGet?.inventoryRecordDisposal
                                ?.approver?.displayName || "-"}
                            </span>
                          </Form.Item>
                        </Col>
                        <Col xs={8} md={6}>
                          <Form.Item label="狀態">
                            <span>
                              {disposalStatusChip(
                                data?.inventoryRecordGet
                                  ?.inventoryRecordDisposal?.status?.id
                              )}
                            </span>
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item label="審批備註">
                            {
                              {
                                approving: (
                                  <Controller
                                    as={<Input.TextArea />}
                                    control={control}
                                    name="approvalRemark"
                                    defaultValue={
                                      data.inventoryRecordGet
                                        .inventoryRecordDisposal?.approvalRemark
                                    }
                                    placeholder={"填寫備註"}
                                    rows={5}
                                  />
                                ),
                                approved: (
                                  <span>
                                    {data.inventoryRecordGet
                                      .inventoryRecordDisposal.approvalRemark ||
                                      "-"}
                                  </span>
                                ),
                                rejected: (
                                  <span>
                                    {data.inventoryRecordGet
                                      .inventoryRecordDisposal.approvalRemark ||
                                      "-"}
                                  </span>
                                ),
                              }[
                                data.inventoryRecordGet.inventoryRecordDisposal
                                  .status?.id
                              ]
                            }
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                  {termiateStatus === "approved" && (
                    <Row gutter={[16, 0]}>
                      <Col xs={8} md={6}>
                        <Form.Item label="處理日期">
                          <Controller
                            as={
                              <DatePicker
                                {...(data?.inventoryRecordGet
                                  ?.inventoryRecordDisposal
                                  ?.followUpActionDate && {
                                  disabled: true,
                                })}
                              />
                            }
                            control={control}
                            name="followUpActionDate"
                            defaultValue={
                              data?.inventoryRecordGet?.inventoryRecordDisposal
                                ?.followUpActionDate
                                ? moment(
                                    data.inventoryRecordGet
                                      .inventoryRecordDisposal
                                      .followUpActionDate
                                  )
                                : null
                            }
                            placeholder={undefined}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={8} md={6}>
                        <Form.Item label="處理員工">
                          <span>
                            {data.inventoryRecordGet.inventoryRecordDisposal
                              .actionStaff?.displayName || "-"}
                          </span>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  {!isViewMode && !termiateStatus && (
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        申請
                      </Button>
                    </Form.Item>
                  )}
                  {termiateStatus === "approving" && (
                    <Form.Item className="approve-btn-container">
                      {(disposalApproveIsAllowed ||
                        !disposalApproveIsDenied) && (
                        <Button
                          className="btn"
                          type="danger"
                          onClick={() => onDisposalApprovalClick("rejected")}
                        >
                          拒絕
                        </Button>
                      )}
                      {(disposalApproveIsAllowed ||
                        !disposalApproveIsDenied) && (
                        <Button
                          className="btn"
                          type="primary"
                          onClick={() => onDisposalApprovalClick("approved")}
                        >
                          批准
                        </Button>
                      )}
                    </Form.Item>
                  )}
                  {termiateStatus === "approved" &&
                    !data?.inventoryRecordGet?.inventoryRecordDisposal
                      ?.followUpActionDate && (
                      <Form.Item className="approve-btn-container">
                        <Button
                          className="btn"
                          type="primary"
                          onClick={onFollowUpActionSave}
                        >
                          儲存
                        </Button>
                      </Form.Item>
                    )}
                </Form>
              ) : (
                "未有落架狀態"
              )}
            </Tabs.TabPane>
            <Tabs.TabPane className="section" tab="活動記錄" key="activityLog">
              <ActivityHistoryComponent id={ID} type={"Inventory"} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </StickyBreadcrumbPageComponent>
  ) : (
    <div />
  );
}

export default InventoryDetailPage;

InventoryDetailPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }),
};
