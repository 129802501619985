import React from "react";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import PropTypes from "prop-types";

// Components
import { Button, Input, Select, Typography } from "antd";

// Styles
import "./filter.scss";

const API = gql`
  {
    enumReferralAgencyNameList
  }
`;

function Filter(props) {
  const {
    filterList,
    isFilterShow,
    onFilterSearch,
    onReferralAgencyChange,
    onReferralChange,
    onResetFilter,
    onStatusChange,
    query,
  } = props;

  const { data } = useQuery(API);

  return (
    <div className={`Filter ${isFilterShow ? "show" : "hide"}`}>
      <div className="filter-container">
        <div className="options-container">
          <Typography>篩選條件</Typography>
          <div className="options">
            {filterList.includes("referralAgency") && (
              <div className="option">
                <Typography>轉介機構</Typography>
                <Select
                  value={query.referralAgency}
                  placeholder="請選擇"
                  onChange={onReferralAgencyChange}
                  style={{ width: "300px" }}
                >
                  <Select.Option key="referralAgencyAll" value={null}>
                    全部
                  </Select.Option>
                  {data?.enumReferralAgencyNameList.map((i) => (
                    <Select.Option key={i} value={i}>
                      {i}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
            {filterList.includes("referral") && (
              <div className="option">
                <Typography>轉介人</Typography>
                <Input value={query.referral} onChange={onReferralChange} />
              </div>
            )}
            {filterList.includes("status") && (
              <div className="option">
                <Typography>審批狀態</Typography>
                <Select
                  value={query.status}
                  placeholder="請選擇"
                  onChange={onStatusChange}
                >
                  <Select.Option key="statueAll" value={null}>
                    全部
                  </Select.Option>
                  <Select.Option key="pending" value="pending">
                    未處理
                  </Select.Option>
                  <Select.Option key="processing" value="processing">
                    審批中
                  </Select.Option>
                </Select>
              </div>
            )}
          </div>
        </div>
        <div className="btn-container">
          <Button className="btn btn-secondary" onClick={onResetFilter}>
            清除設定
          </Button>
          <Button className="btn" type="primary" onClick={onFilterSearch}>
            搜尋
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Filter;

Filter.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
  filterList: PropTypes.arrayOf(PropTypes.string),
  isFilterShow: PropTypes.bool.isRequired,
  query: PropTypes.shape({
    referralAgency: PropTypes.string,
    referral: PropTypes.string,
    status: PropTypes.string,
  }),
  onFilterSearch: PropTypes.func.isRequired,
  onReferralAgencyChange: PropTypes.func.isRequired,
  onReferralChange: PropTypes.func.isRequired,
  onResetFilter: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  filterList: ["referralAgency", "referral"],
};
