/* eslint-disable no-unused-vars */
import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Constant
import { PAGE, ACCESS_LEVELS } from "@utils/constants";

// Pages
import CategoryPage from "@pages/stock/category/category";
import DemoAssessmentPage from "@pages/demo/assessment/assessment";
import DemoFormPage from "@pages/demo/form/form";
import InventoryListPage from "@pages/stock/inventory/inventory";
import InventoryDetailPage from "@pages/stock/inventoryDetail/inventoryDetail";
import DemoContract from "@pages/demo/contract/contract";
import OverviewPage from "@pages/overview/overview";
import UserAssessmentPage from "@pages/user/assessment/userAssessment";
import UserCreatePage from "@pages/user/create/userCreate";
import UserDetailPage from "@pages/user/detail/userDetail";
import UserPendingPage from "@pages/user/pending/userPending";
import UserRegisteredPage from "@pages/user/registered/userRegistered";
import UserRejectedPage from "@pages/user/rejected/userRejected";
import UserSearchPage from "@pages/user/search/userSearch";
import DemoTablePage from "@pages/demo/table/table";
import DemoLargeFormPage from "@pages/demo/largeForm/largeForm";
import DeviceListPage from "@pages/stock/device/deviceList/deviceList";
import DeviceFormPage from "@pages/stock/device/deviceForm/deviceForm";
import DeviceDetailPage from "@pages/stock/device/deviceDetail/deviceDetail";
import PartListPage from "@pages/stock/part/partList/partList";
import PartFormPage from "@pages/stock/part/partForm/partForm";
import PartDetailPage from "@pages/stock/part/partDetail/partDetail";
import ConsumableListPage from "@pages/stock/consumable/consumableList/consumableList";
import ConsumableFormPage from "@pages/stock/consumable/consumableForm/consumableForm";
import ConsumableDetailPage from "@pages/stock/consumable/consumableDetail/consumableDetail";
import StockServicePage from "@pages/stock/service/service";
import StockServiceDetailPage from "@pages/stock/service/serviceDetail";
import StoreUserDiscountPage from "@pages/stock/userDiscount/userDiscount";
import DeliveryAllPage from "@pages/stockDelivery/deliveryAll";
import DeliveryPendingPage from "@pages/stockDelivery/deliveryPending";
import DeliveryConfirmedPage from "@pages/stockDelivery/deliveryConfirmed";
import DeliveryCompletedPage from "@pages/stockDelivery/deliveryCompleted";
import OrderingDetailPage from "@pages/ordering/orderingDetail/orderingDetail";

import OrderingReservePage from "@pages/ordering/orderingList/orderingReserve";
import OrderingInServicePage from "@pages/ordering/orderingList/orderingInService";
import OrderingToBeTerminatePage from "@pages/ordering/orderingList/orderingToBeTerminate";
import OrderingTerminatedPage from "@pages/ordering/orderingList/orderingTerminated";

import WorkshopInspectionChecklist from "@pages/workshop/InspectionChecklist/list/InspectionChecklist";
import WorkshopInspectionCreate from "@pages/workshop/InspectionChecklist/create/InspectionCreate";

import WorkshopCleaningFlowList from "@pages/workshop/cleaningFlow/list/cleaningFlowList";
import WorkshopCleaningFlowEdit from "@pages/workshop/cleaningFlow/edit/cleaningFlowEdit";
import WorkshopCleaningStage from "@pages/workshop/cleaningFlow/stage/cleaningStage";

import WorkshopWarehouseList from "@pages/workshop/warehouse/list/warehouseList";

import WorkshopScannerPage from "@pages/workshop/scanner/workshopScanner";

import WorkshopDashboardPage from "@pages/workshop/dashboard/dashboard";

import DesktopInventoryDetailPage from "@pages/workshop/inventoryDetail/inventoryDetail";

import DesktopInspectionForm from "@pages/workshop/inspectionForm/inspectionForm";

import SystemReportsPage from "@pages/system/reports/reports";
import SystemToolsPage from "@pages/system/tools/tools";

import UnauthorizedPage from "@pages/responseStatus/401/401";

import BuyerTagList from "./pages/thirdPartyCleaning/tag/list/tagList";
import BuyerList from "./pages/thirdPartyCleaning/buyer/list/buyerList";

import DebugPage from "@pages/debug/debug";

// Utils
import { replaceUrlByParams } from "@utils/api";
import { isDev } from "@utils/env";
import { useAccessLevel } from "@utils/hook";
import BuyerDetail from "./pages/thirdPartyCleaning/buyer/detail/buyerDetail";
import JobList from "./pages/thirdPartyCleaning/job/list/jobList";
import JobDetail from "./pages/thirdPartyCleaning/job/detail/jobDetail";
import CleaningJobDeviceDetail from "./pages/thirdPartyCleaning/job/device/detail/deviceDetail";
import DeviceStage from "./pages/thirdPartyCleaning/job/device/stage/deviceStage";
import DeviceCategoryList from "./pages/thirdPartyCleaning/category/categoryList";
import CleaningReportsPage from "./pages/thirdPartyCleaning/reports/reports";

export default withRouter(AppMainRouter);

export const AuthenticatedRoute = (props) => {
  const user = useSelector((state) => state.storeReducer?.user);
  const { isMatched: isAuthed, matchedAccessLevels } = useAccessLevel(
    props.requiredAccessLevels ?? []
  );
  console.log("appRouter/auth", {
    userAccessLevel: user?.accessLevel ?? [],
    requiredAccessLevels: props.requiredAccessLevels,
    matchedAccessLevels,
    isAuthed,
  });
  if (!isAuthed) {
    return (
      <Redirect
        to={{
          pathname: "/401",
        }}
      />
    );
  } else {
    return <Route {...props} />;
  }
};

AuthenticatedRoute.propTypes = {
  requiredAccessLevels: PropTypes.array,
};

const {
  ADMINISTRATION,
  REFERRALS,
  CASE_MANAGEMENT,
  OPERATION_EQUIPMENT_MANAGEMENT,
  CLERICAL_EQUIPMENT_MANAGEMENT,
  FINANCIAL_MANAGEMENT,
  WORKSHOP_MANAGEMENT,
  SERVICE_TEAM,
  ADMIN_EQUIPMENT_MANAGEMENT,
  SUPERADMIN_EQUIPMENT_MANAGEMENT,
} = ACCESS_LEVELS;

function AppMainRouter(props) {
  const user = useSelector((state) => state.storeReducer?.user);
  let redirectByAccessLevel = <Redirect to={PAGE.OVERVIEW} />;
  if (user.accessLevel) {
    switch (user.accessLevel[0]) {
      case REFERRALS:
        redirectByAccessLevel = <Redirect to={PAGE.USER_SEARCH} />;
        break;
      case ADMINISTRATION:
      case CASE_MANAGEMENT:
        redirectByAccessLevel = <Redirect to={PAGE.OVERVIEW} />;
        break;
      case OPERATION_EQUIPMENT_MANAGEMENT:
        redirectByAccessLevel = <Redirect to={PAGE.STOCK_DELIVERY_ALL} />;
        break;
      case CLERICAL_EQUIPMENT_MANAGEMENT:
        redirectByAccessLevel = <Redirect to={PAGE.STOCK_INVENTORY} />;
        break;
      case FINANCIAL_MANAGEMENT:
        redirectByAccessLevel = <Redirect to={PAGE.ORDER_RESERVE} />;
        break;

      default:
        break;
    }
    return (
      <>
        <Switch>
          {/* 總覽 */}
          <AuthenticatedRoute
            requiredAccessLevels={[ADMINISTRATION, CASE_MANAGEMENT]}
            exact
            path={PAGE.OVERVIEW}
            component={OverviewPage}
          />

          {/* pages: 用戶管理 */}
          {/* 搜尋個案/用戶 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              REFERRALS,
              CASE_MANAGEMENT,
              SERVICE_TEAM,
            ]}
            exact
            path={PAGE.USER_SEARCH}
            component={UserSearchPage}
          />
          {/* 審批中轉介個案 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              REFERRALS,
              CASE_MANAGEMENT,
              SERVICE_TEAM,
            ]}
            exact
            path={PAGE.USER_PENDING}
            component={UserPendingPage}
          />
          {/* 拒絕轉介個案 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              REFERRALS,
              CASE_MANAGEMENT,
              SERVICE_TEAM,
            ]}
            exact
            path={PAGE.USER_REJECTED}
            component={UserRejectedPage}
          />
          {/* 登記用戶 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              REFERRALS,
              CASE_MANAGEMENT,
              SERVICE_TEAM,
            ]}
            exact
            path={PAGE.USER_REGISTERED}
            component={UserRegisteredPage}
          />
          {/* sub-pages: 用戶管理 */}
          <Route exact path={PAGE.USER_CREATE} component={UserCreatePage} />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.USER_DETAIL, { id: ":id" })}`}
            component={UserDetailPage}
          />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.USER_ASSESSMENT, {
              id: ":id",
              assessmentId: ":assessmentId",
            })}`}
            component={UserAssessmentPage}
          />

          {/* pages: 訂單管理 */}
          {/* 已預留 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CASE_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
              FINANCIAL_MANAGEMENT,
              SERVICE_TEAM,
            ]}
            exact
            path={PAGE.ORDER_RESERVE}
            component={OrderingReservePage}
          />
          {/* 服務中 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CASE_MANAGEMENT,
              FINANCIAL_MANAGEMENT,
              SERVICE_TEAM,
            ]}
            exact
            path={PAGE.ORDER_IN_SERVICE}
            component={OrderingInServicePage}
          />
          {/* 即將取消 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CASE_MANAGEMENT,
              FINANCIAL_MANAGEMENT,
              SERVICE_TEAM,
            ]}
            exact
            path={PAGE.ORDER_TO_BE_TERMINATE}
            component={OrderingToBeTerminatePage}
          />
          {/* 已完結/取消 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CASE_MANAGEMENT,
              FINANCIAL_MANAGEMENT,
              SERVICE_TEAM,
            ]}
            exact
            path={PAGE.ORDER_TERMINATED}
            component={OrderingTerminatedPage}
          />
          {/* sub-pages: 訂單管理 */}
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.ORDERING_DETAIL, {
              id: ":id",
            })}`}
            component={OrderingDetailPage}
          />

          {/* pages: 運送及裝拆安排 */}
          {/* 全部運送及裝拆安排 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CASE_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.STOCK_DELIVERY_ALL}
            component={DeliveryAllPage}
          />
          {/* 待確認安排 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CASE_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.STOCK_DELIVERY_PENDING}
            component={DeliveryPendingPage}
          />
          {/* 已確認安排 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CASE_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.STOCK_DELIVERY_CONFIRMED}
            component={DeliveryConfirmedPage}
          />
          {/* 已完成/取消安排 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CASE_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.STOCK_DELIVERY_COMPLETED}
            component={DeliveryCompletedPage}
          />

          {/* pages: 貨物管理 */}
          {/* 所有存貨 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              REFERRALS,
              CASE_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
              CLERICAL_EQUIPMENT_MANAGEMENT,
              FINANCIAL_MANAGEMENT,
              SERVICE_TEAM,
              ADMIN_EQUIPMENT_MANAGEMENT,
              SUPERADMIN_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.STOCK_INVENTORY}
            component={InventoryListPage}
          />
          {/* 器材 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              REFERRALS,
              CASE_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
              CLERICAL_EQUIPMENT_MANAGEMENT,
              FINANCIAL_MANAGEMENT,
              SERVICE_TEAM,
              ADMIN_EQUIPMENT_MANAGEMENT,
              SUPERADMIN_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.STOCK_DEVICE}
            component={DeviceListPage}
          />
          {/* 零件 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              REFERRALS,
              CASE_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
              CLERICAL_EQUIPMENT_MANAGEMENT,
              FINANCIAL_MANAGEMENT,
              SERVICE_TEAM,
              ADMIN_EQUIPMENT_MANAGEMENT,
              SUPERADMIN_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.STOCK_PART}
            component={PartListPage}
          />
          {/* 消耗品 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              REFERRALS,
              CASE_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
              CLERICAL_EQUIPMENT_MANAGEMENT,
              FINANCIAL_MANAGEMENT,
              SERVICE_TEAM,
              ADMIN_EQUIPMENT_MANAGEMENT,
              SUPERADMIN_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.STOCK_CONSUMABLE}
            component={ConsumableListPage}
          />
          {/* 服務 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              REFERRALS,
              CASE_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
              CLERICAL_EQUIPMENT_MANAGEMENT,
              FINANCIAL_MANAGEMENT,
              SERVICE_TEAM,
              ADMIN_EQUIPMENT_MANAGEMENT,
              SUPERADMIN_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.STOCK_SERVICE}
            component={StockServicePage}
          />
          {/* 貨物分類 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              REFERRALS,
              OPERATION_EQUIPMENT_MANAGEMENT,
              CLERICAL_EQUIPMENT_MANAGEMENT,
              ADMIN_EQUIPMENT_MANAGEMENT,
              SUPERADMIN_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.STOCK_CATEGORY}
            component={CategoryPage}
          />
          {/* 用戶折扣 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              REFERRALS,
              OPERATION_EQUIPMENT_MANAGEMENT,
              CLERICAL_EQUIPMENT_MANAGEMENT,
              ADMIN_EQUIPMENT_MANAGEMENT,
              SUPERADMIN_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.STOCK_USER_DISCOUNT}
            component={StoreUserDiscountPage}
          />
          {/* sub-pages: 貨物管理 */}
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.STOCK_INVENTORY_DETAIL, {
              inventoryId: ":inventoryId",
            })}`}
            component={InventoryDetailPage}
          />
          <Route
            exact
            path={PAGE.STOCK_DEVICE_CREATE}
            component={DeviceFormPage}
          />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.STOCK_DEVICE_DETAIL, {
              id: ":id",
            })}`}
            component={DeviceDetailPage}
          />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.STOCK_DEVICE_EDIT, {
              id: ":id",
            })}`}
            component={DeviceFormPage}
          />
          <Route exact path={PAGE.STOCK_PART_CREATE} component={PartFormPage} />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.STOCK_PART_DETAIL, {
              id: ":id",
            })}`}
            component={PartDetailPage}
          />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.STOCK_PART_EDIT, {
              id: ":id",
            })}`}
            component={PartFormPage}
          />
          <Route
            exact
            path={PAGE.STOCK_CONSUMABLE_CREATE}
            component={ConsumableFormPage}
          />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.STOCK_CONSUMABLE_DETAIL, {
              id: ":id",
            })}`}
            component={ConsumableDetailPage}
          />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.STOCK_CONSUMABLE_EDIT, {
              id: ":id",
            })}`}
            component={ConsumableFormPage}
          />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.STOCK_CATEGORY_CREATE, {
              categoryType: ":categoryType",
            })}`}
            component={CategoryPage}
          />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.STOCK_CATEGORY_RECORD, {
              categoryType: ":categoryType",
              id: ":id",
            })}`}
            component={CategoryPage}
          />
          <Route
            exact
            path={PAGE.STOCK_SERVICE_CREATE}
            component={StockServiceDetailPage}
          />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.STOCK_SERVICE_DETAIL, {
              id: ":id",
              mode: ":mode",
            })}`}
            component={StockServiceDetailPage}
          />

          {/* pages: 工場管理 */}
          {/* 工場存貨 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CLERICAL_EQUIPMENT_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
              WORKSHOP_MANAGEMENT,
              ADMIN_EQUIPMENT_MANAGEMENT,
              SUPERADMIN_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.WORKSHOP_DASHBOARD}
            component={WorkshopDashboardPage}
          />
          {/* 檢查貨物記錄表 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CLERICAL_EQUIPMENT_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
              WORKSHOP_MANAGEMENT,
              ADMIN_EQUIPMENT_MANAGEMENT,
              SUPERADMIN_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.WORKSHOP_INSPECTION_CHECKLIST}
            component={WorkshopInspectionChecklist}
          />
          {/* 清潔流程 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CLERICAL_EQUIPMENT_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
              WORKSHOP_MANAGEMENT,
              ADMIN_EQUIPMENT_MANAGEMENT,
              SUPERADMIN_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.WORKSHOP_CLEANING_FLOW}
            component={WorkshopCleaningFlowList}
          />
          {/* 清潔步驟 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CLERICAL_EQUIPMENT_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
              WORKSHOP_MANAGEMENT,
              ADMIN_EQUIPMENT_MANAGEMENT,
              SUPERADMIN_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.WORKSHOP_CLEANING_STAGE}
            component={WorkshopCleaningStage}
          />
          {/* 工場地點 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CLERICAL_EQUIPMENT_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
              WORKSHOP_MANAGEMENT,
              ADMIN_EQUIPMENT_MANAGEMENT,
              SUPERADMIN_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.WORKSHOP_WAREHOUSE}
            component={WorkshopWarehouseList}
          />
          {/* 掃瞄 QR Code */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CLERICAL_EQUIPMENT_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
              WORKSHOP_MANAGEMENT,
              ADMIN_EQUIPMENT_MANAGEMENT,
              SUPERADMIN_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.WORKSHOP_SCANNER}
            component={WorkshopScannerPage}
          />
          {/* sub-pages: 工場管理 */}
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.WORKSHOP_INSPECTION_EDIT, {
              id: ":id",
            })}`}
            component={WorkshopInspectionCreate}
          />
          <Route
            exact
            path={PAGE.WORKSHOP_INSPECTION_CREATE}
            component={WorkshopInspectionCreate}
          />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.WORKSHOP_CLEANING_FLOW_EDIT, {
              id: ":id",
            })}`}
            component={WorkshopCleaningFlowEdit}
          />
          <Route
            exact
            path={PAGE.WORKSHOP_CLEANING_FLOW_CREATE}
            component={WorkshopCleaningFlowEdit}
          />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.DESKTOP_INVENTORY_DETAIL, {
              id: ":id",
            })}`}
            component={DesktopInventoryDetailPage}
          />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.DESKTOP_INSPECTION_FORM, {
              id: ":id",
            })}`}
            component={DesktopInspectionForm}
          />

          {/* pages: 輔助器材清潔管理 */}
          <Route
            exact
            path={PAGE.THIRD_PARTY_CLEANING_BUYER_LIST}
            component={BuyerList}
          />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.THIRD_PARTY_CLEANING_BUYER_EDIT, {
              id: ":id",
            })}`}
            component={BuyerDetail}
          />
          <Route
            exact
            path={PAGE.THIRD_PARTY_CLEANING_BUYER_CREATE}
            component={BuyerDetail}
          />
          <Route
            exact
            path={PAGE.THIRD_PARTY_CLEANING_JOB_LIST}
            component={JobList}
          />
          <Route
            exact
            path={PAGE.THIRD_PARTY_CLEANING_JOB_CREATE}
            component={JobDetail}
          />
          <Route
            exact
            path={`${replaceUrlByParams(PAGE.THIRD_PARTY_CLEANING_JOB_EDIT, {
              id: ":id",
            })}`}
            component={JobDetail}
          />
          <Route
            exact
            path={`${replaceUrlByParams(
              PAGE.THIRD_PARTY_CLEANING_JOB_DEVICE_EDIT,
              {
                id: ":id",
                deviceId: ":deviceId",
              }
            )}`}
            component={CleaningJobDeviceDetail}
          />
          <Route
            exact
            path={`${replaceUrlByParams(
              PAGE.THIRD_PARTY_CLEANING_JOB_DEVICE_STAGE,
              {
                id: ":id",
                deviceId: ":deviceId",
              }
            )}`}
            component={DeviceStage}
          />
          <Route
            exact
            path={PAGE.THIRD_PARTY_CLEANING_CATEGORY_LIST}
            component={DeviceCategoryList}
          />
          <Route
            exact
            path={PAGE.THIRD_PARTY_CLEANING_REPORTS}
            component={CleaningReportsPage}
          />
          <Route
            exact
            path={PAGE.THIRD_PARTY_CLEANING_TAG_LIST}
            component={BuyerTagList}
          />

          {/* pages: 系統 */}
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CASE_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
              ADMIN_EQUIPMENT_MANAGEMENT,
              SUPERADMIN_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.SYSTEM_REPORTS}
            component={SystemReportsPage}
          />
          <AuthenticatedRoute
            requiredAccessLevels={[
              ADMINISTRATION,
              CASE_MANAGEMENT,
              OPERATION_EQUIPMENT_MANAGEMENT,
              ADMIN_EQUIPMENT_MANAGEMENT,
              SUPERADMIN_EQUIPMENT_MANAGEMENT,
            ]}
            exact
            path={PAGE.SYSTEM_TOOLS}
            component={SystemToolsPage}
          />

          {/* pages: debug */}
          {isDev() && <Route exact path={PAGE.DEBUG} component={DebugPage} />}

          {/* pages: demo */}
          <Route
            exact
            path={"/demo/assessment"}
            component={DemoAssessmentPage}
          />
          <Route exact path={"/demo/form"} component={DemoFormPage} />
          <Route exact path={"/demo/largeForm"} component={DemoLargeFormPage} />
          <Route exact path={"/demo/table"} component={DemoTablePage} />
          <Route exact path={"/demo/contract"} component={DemoContract} />
          {/* pages: http response status */}
          <Route exact path={PAGE.UNAUTHORIZED} component={UnauthorizedPage} />
          {redirectByAccessLevel}
        </Switch>
      </>
    );
  } else {
    return false;
  }
}
