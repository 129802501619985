/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import queryString from "query-string";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Layouts
import CreateAssessmentModalComponent from "@components/createAssessmentModal/createAssessmentModal";

// Styles
import "./orderingAssessment.scss";

// Utils
import { replaceUrlByParams } from "@utils/api";
import { MOMENT_FORMAT, PAGE } from "@utils/constants";
import { Button, Table, Tag, Modal, Select, Input, notification } from "antd";
import moment from "moment";
import { useMemo } from "react";
import { useImperativeHandle } from "react";

const API = gql`
  {
    enumAssessmentMethodList {
      id
      name
    }
  }
`;

const USER_GET = gql`
  query userGet($id: ID) {
    userGet(id: $id) {
      area {
        id
      }
      assessmentResult {
        id
        title
        referenceId
        assessmentResultId
        assessmentExpectedDate
        assessmentStartDate
        assessmentMethod {
          id
          name
        }
        assessmentStatus {
          id
          name
        }
        personInCharge {
          displayName
        }
        remark
      }
      buildingName
      contactPerson {
        name
        relationship {
          id
          name
        }
        telephone
        # whatsappNumber
        livingStatus {
          id
        }
        otherLivingStatus
        contactType {
          id
        }
      }
      dateOfBirth
      economicStatus {
        familyMonthlyIncome {
          id
        }
        cssaFileNum
      }
      elevatorFailToReachFloor
      estateName
      educationLevel {
        id
      }
      flat
      floor
      healthStatus {
        medicalRecord {
          id
        }
        otherHealthStatus
        idal {
          id
        }
        communicationSkill {
          id
        }
        visualLeft {
          id
        }
        visualRight {
          id
        }
        auditoryLeft {
          id
        }
        hearingAidLeft
        auditoryRight {
          id
        }
        hearingAidRight
        cognitiveAbility {
          id
        }
        otherCognitiveAbility
      }
      hkid
      gender {
        id
      }
      language {
        id
      }
      livingCondition {
        id
      }
      livingRelation {
        id
      }
      mailBilling

      maritalStatus {
        id
      }
      nameChi
      nameEng
      numOfLivingTogether
      otherLanguage
      prescription {
        id
        name
        datetime
        personInCharge {
          displayName
        }
        relatedAssessment {
          id
        }
      }
      referralInfo {
        id
        referenceId
        referralAgencyName
        referrerName
        referralDate
        personInCharge {
          displayName
        }
        approvalStatus
        terminatedOrder
        user {
          userId
          username
          nameChi
          hkid
          telephone
        }
      }
      region {
        id
      }
      residenceCategory {
        id
      }
      telephone
      userId
      username
      zone {
        id
      }
    }
  }
`;

const ASSESSMENT_RESULT_UPDATE = gql`
  mutation AssessmentResultUpdate($input: AssessmentResultUpdateInput) {
    assessmentResultUpdate(input: $input) {
      id
    }
  }
`;

const ASSESSMENT_RESULT_DELETE = gql`
  mutation AssessmentResultDelete($id: [ID]) {
    assessmentResultDelete(id: $id)
  }
`;

const OrderingAssessment = ({ userId, query, MODE, setMODE }) => {
  // const [MODE, setMODE] = useState("view");
  const [assessments, setAssessments] = useState([]);
  const [
    editModeRemovedAssessmentIds,
    setEditModeRemovedAssessmentIds,
  ] = useState([]);

  const [
    assessmentResultUpdate,
    {
      loading: assessmentResultUpdateLoading,
      data: assessmentResultUpdateData,
    },
  ] = useMutation(ASSESSMENT_RESULT_UPDATE);
  const [
    assessmentResultDelete,
    {
      loading: assessmentResultDeleteLoading,
      data: assessmentResultDeleteData,
    },
  ] = useMutation(ASSESSMENT_RESULT_DELETE);

  const { data, loading, error } = useQuery(API);

  const columns = [
    {
      title: "評估號碼",
      dataIndex: "assessmentResultId",
      render: (text, record) => (
        <Link
          className="link"
          to={{
            pathname: replaceUrlByParams(PAGE.USER_ASSESSMENT, {
              id: user?.userId,
              assessmentId: text,
            }),
            search: "?from=ordering",
          }}
          target="_blank"
        >
          {record.referenceId}
        </Link>
      ),
    },
    {
      title: "評估名稱",
      dataIndex: "title",
    },
    {
      title: "預計評估日期",
      dataIndex: "assessmentExpectedDate",
      render: (text) => (
        <span>{text ? moment(text).format("YYYY-MM-DD") : "-"}</span>
      ),
    },
    {
      title: "實際評估日期",
      dataIndex: "assessmentStartDate",
      render: (text) => (
        <span>{text ? moment(text).format("YYYY-MM-DD") : "-"}</span>
      ),
    },
    {
      title: "評估方法",
      dataIndex: "assessmentMethod",
      render: (text, record) =>
        ({
          view: <span>{text?.name || "-"}</span>,
          edit: (
            <Select
              onChange={(value) => onAssessmentMethodChange(record, value)}
              placeholder="請選擇方法"
              value={record.assessmentMethod?.id}
              style={{ minWidth: "120px" }}
            >
              {data?.enumAssessmentMethodList.map((method) => (
                <Select.Option key={method.id}>{method.name}</Select.Option>
              ))}
            </Select>
          ),
        }[MODE]),
    },
    {
      title: "負責人",
      dataIndex: "personInCharge.displayName",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "狀態",
      dataIndex: "assessmentStatus.id",
      render: (text) =>
        ({
          complete: <Tag className="tag success">完成</Tag>,
          incomplete: <Tag className="tag warning">未完成</Tag>,
        }[text]),
    },
    {
      title: "備註",
      dataIndex: "remark",
      render: (text, record) =>
        ({
          view: <span>{text ? text : "-"}</span>,
          edit: (
            <Input
              value={text}
              placeholder="填寫備註"
              onChange={(evt) => onAssessmentRemarkChange(record, evt)}
              style={{ minWidth: "120px" }}
            />
          ),
        }[MODE]),
    },
    {
      dataIndex: "action",
      render: (text, record) =>
        MODE === "edit" && (
          <Button
            type="danger"
            shape="circle"
            icon="minus"
            size="small"
            onClick={() => onAssessmentDeleteClick(record)}
          />
        ),
    },
  ];

  const [
    userGet,
    { loading: userGetLoading, data: userGetData },
  ] = useLazyQuery(USER_GET);

  const [
    isCreateAssessmentModalShow,
    setIsCreateAssessmentModalShow,
  ] = useState(false);

  const user = useMemo(() => {
    return userGetData?.userGet;
  }, [userGetData]);

  console.log("user", user);

  const refreshData = useCallback(() => {
    if (query?.tabKey === "assessment" && userId) {
      userGet({
        variables: {
          id: userId,
        },
      });
    }
  }, [query, userGet, userId]);

  useEffect(() => {
    if (user) {
      setAssessments(JSON.parse(JSON.stringify(user?.assessmentResult)));
    }
  }, [user]);

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line
  }, [query, userId]);

  // useEffect(() => {
  //   console.log("assessments",assessments);
  // }, [assessments])

  const onCreateAssessmentClick = () => {
    setIsCreateAssessmentModalShow(true);
  };

  const onCreateAssessmentModalClose = () => {
    setIsCreateAssessmentModalShow(false);
  };

  const onCreateAssessmentModalSubmit = () => {
    setIsCreateAssessmentModalShow(false);
    userGet({
      variables: {
        id: userId,
      },
    });
  };

  const onAssessmentDeleteClick = (record) => {
    Modal.confirm({
      title: "確定移除？",
      okText: "確定",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        setAssessments(
          assessments.filter(
            (a) => a.assessmentResultId !== record.assessmentResultId
          )
        );
        setEditModeRemovedAssessmentIds((state) => [
          ...state,
          record.assessmentResultId,
        ]);
      },
    });
  };

  const onAssessmentMethodChange = (record, value) => {
    setAssessments(
      assessments.reduce((acc, curr) => {
        // console.log("curr.assessmentResultId -> ", curr.assessmentResultId);
        // console.log("record?.assessmentResultId -> ", record?.assessmentResultId);
        // console.log("curr.assessmentMethod -> ", curr.assessmentMethod);
        // console.log("value -> ", value);
        if (curr.assessmentResultId === record?.assessmentResultId) {
          if (curr.assessmentMethod) {
            curr.assessmentMethod.id = value;
          } else {
            curr.assessmentMethod = {
              id: value,
            };
          }
        }

        return [...acc, JSON.parse(JSON.stringify(curr))];
      }, [])
    );
  };

  const onAssessmentRemarkChange = (record, evt) => {
    setAssessments(
      assessments.reduce((acc, curr) => {
        if (curr.assessmentResultId === record.assessmentResultId) {
          curr.remark = evt.target.value;
        }
        acc.push(curr);
        return acc;
      }, [])
    );
  };

  const assessmentsSave = async () => {
    let isChanged = false;
    if (editModeRemovedAssessmentIds?.length) {
      isChanged = true;
      await assessmentResultDelete({
        variables: {
          id: editModeRemovedAssessmentIds,
        },
      });
      setEditModeRemovedAssessmentIds([]);
    }

    assessments.forEach(async (assessment, index) => {
      // console.log("assessmentSave", assessments[index], user.assessmentResult[index])
      if (
        assessments[index].assessmentMethod.id !==
          user.assessmentResult[index].assessmentMethod.id ||
        assessments[index].remark !== user.assessmentResult[index].remark
      ) {
        if (!isChanged) isChanged = true;
        assessmentResultUpdate({
          variables: {
            input: {
              assessmentResultId: assessment.assessmentResultId,
              assessmentMethod: assessment.assessmentMethod?.id,
              remark: assessment.remark,
            },
          },
        });
      }
    });

    if (isChanged) refreshData();
    else
      notification.warn({
        message: "儲存失敗, 沒有任何變更",
      });
  };

  const restoreAssessments = () => {
    setAssessments(JSON.parse(JSON.stringify(user?.assessmentResult)));
    setEditModeRemovedAssessmentIds([]);
  };

  const onModeBtnClick = (mode) => {
    switch (mode) {
      case "edit":
        setMODE(mode);
        break;
      case "save":
        Modal.confirm({
          title: "確定儲存？",
          okText: "確定",
          okType: "danger",
          cancelText: "取消",
          onOk() {
            assessmentsSave();
            setMODE("view");
          },
        });
        break;
      case "cancel":
        Modal.confirm({
          title: "確定取消編輯？",
          content: "未儲存的更改將遺失",
          okText: "確定",
          okType: "danger",
          cancelText: "取消",
          onOk() {
            restoreAssessments();
            setMODE("view");
          },
        });
        break;
      default:
    }
  };

  return (
    <div>
      <Button className="btn btn-secondary" onClick={onCreateAssessmentClick}>
        新增
      </Button>
      <div style={{ float: "right" }}>
        {MODE === "view" && (
          <Button
            className="btn btn-secondary"
            onClick={() => onModeBtnClick("edit")}
          >
            編輯
          </Button>
        )}
        {MODE === "edit" && (
          <span>
            <Button
              className="btn btn-secondary"
              onClick={() => onModeBtnClick("cancel")}
            >
              取消
            </Button>
            <Button
              className="btn btn-secondary"
              style={{ marginLeft: "8px" }}
              onClick={() => onModeBtnClick("save")}
            >
              儲存
            </Button>
          </span>
        )}
      </div>
      {user && (
        <CreateAssessmentModalComponent
          userId={userId}
          visible={isCreateAssessmentModalShow}
          onClose={onCreateAssessmentModalClose}
          onSubmit={onCreateAssessmentModalSubmit}
        />
      )}
      <Table
        className="assessment-table"
        columns={columns}
        dataSource={assessments}
        loading={userGetLoading}
        rowKey="assessmentResultId"
        pagination={false}
        scroll={{x:"max-content"}}
      />
    </div>
  );
};

OrderingAssessment.propTypes = {
  query: PropTypes.object,
  userId: PropTypes.string,
  MODE: PropTypes.string,
  setMODE: PropTypes.func,
};

OrderingAssessment.defaultProps = {
  userId: "",
};

export default OrderingAssessment;
