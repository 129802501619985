import React, { useMemo, useEffect, useCallback, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
// Components
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

// Constants
import { PAGE, PAGE_NAME, ACCESS_LEVELS } from "@utils/constants";

// Style
import "./category.scss";

// Utils
import { useAccessLevel } from "@utils/hook";

import { classList } from "@utils/common";
import {
  Table,
  Button,
  Drawer,
  Form,
  Row,
  Input,
  Col,
  Select,
  Tag,
  Modal,
  notification,
} from "antd";
import { useLazyQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { replaceUrlByParams } from "@utils/api";
import { useForm } from "react-hook-form";
import queryString from "query-string";

const CATETORY_LIST = gql`
  query {
    enumATCategoryList {
      id
      name
    }
    mainCategoryList {
      referenceId
      id
      name
      description
      atCategory {
        id
        name
      }
      trace {
        lastModifiedDate
      }
      category {
        id
        referenceId
        name
        description
        intFundRef
        minInventory
        trace {
          lastModifiedDate
        }
      }
    }
  }
`;

const MAIN_CATEGORY_DELETE = gql`
  mutation mainCategoryDelete($id: ID) {
    mainCategoryDelete(id: $id)
  }
`;

const CATEGORY_DELETE = gql`
  mutation categoryDelete($id: ID) {
    categoryDelete(id: $id)
  }
`;

const Category = () => {
  const { categoryType = undefined, id = undefined } = useParams();
  const history = useHistory();

  const { isMatched: isDenied } = useAccessLevel([ACCESS_LEVELS.REFERRALS]);

  const [getCategories, { data, loading }] = useLazyQuery(CATETORY_LIST, {
    fetchPolicy: "no-cache",
  });
  const [
    mainCategoryDelete,
    // { data: mainCategoryDeleteData, loading: mainCategoryDeleteLoading },
  ] = useMutation(MAIN_CATEGORY_DELETE);
  const [
    categoryDelete,
    // { data: categoryDeleteData, loading: categoryDeleteLoading },
  ] = useMutation(CATEGORY_DELETE);

  const [deleteCategoryParams, setDeleteCategoryParams] = useState({
    visible: false,
  });

  const showCategoryForm = categoryType !== undefined;

  const onClose = () => {
    history.push(PAGE.STOCK_CATEGORY);
    getCategories();
  };

  const onEditMainCategoryButtonClick = (id) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(
      replaceUrlByParams(PAGE.STOCK_CATEGORY_RECORD, {
        categoryType: "main-category",
        id,
      })
    );
  };

  const onEditCategoryButtonClick = (id) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(
      replaceUrlByParams(PAGE.STOCK_CATEGORY_RECORD, {
        categoryType: "category",
        id,
      })
    );
  };

  const onCreateMainCategoryButtonClick = (id) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push({
      pathname: replaceUrlByParams(PAGE.STOCK_CATEGORY_CREATE, {
        categoryType: "main-category",
      }),
      search: queryString.stringify({
        id,
      }),
    });
  };

  const onCreateCategoryButtonClick = (id) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push({
      pathname: replaceUrlByParams(PAGE.STOCK_CATEGORY_CREATE, {
        categoryType: "category",
      }),
      search: queryString.stringify({
        id,
      }),
    });
  };

  const onClickDeleteCategory = (record) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteCategoryParams({ visible: true, record });
  };

  const onDeleteCategory = async (record) => {
    try {
      let result = null;
      if (record.__typename === "MainCategory") {
        const { data } = await mainCategoryDelete({
          variables: { id: record.id },
        });
        result = data?.mainCategoryDelete;
      }
      if (record.__typename === "Category") {
        const { data } = await categoryDelete({ variables: { id: record.id } });
        result = data?.categoryDelete;
      }
      if (result) {
        notification.success({
          message: "已刪除分類",
          duration: 5,
        });
        getCategories();
      } else
        notification.error({
          message: "分類刪除失敗",
          duration: 5,
        });
    } catch (err) {
      notification.error({
        message: "分類刪除失敗",
        duration: 5,
      });
    }
  };

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const categories = useMemo(() => {
    if (data && data.enumATCategoryList && data.mainCategoryList) {
      return data.enumATCategoryList.map((atCategory) => ({
        ...atCategory,
        category: data.mainCategoryList.filter((mainCategory) => {
          return mainCategory.atCategory.id === atCategory.id;
        }),
      }));
    }
    return [];
  }, [data]);

  const BREADCRUMB = [
    {
      path: PAGE.STOCK_CATEGORY,
      name: PAGE_NAME.STOCK_CATEGORY,
    },
  ];

  const getClassList = () =>
    classList({
      Category: true,
    });

  useEffect(() => {
    document
      .querySelectorAll(".ant-table-row-collapsed")
      .forEach((x) => x.click());
    document
      .querySelectorAll(".ant-table-row-collapsed")
      .forEach((x) => x.click());
  }, [data]);

  const columns = [
    {
      key: `name`,
      title: "分類",
      width: "50%",
      render: (text, record) => {
        return (
          <span>
            {record.referenceId && <Tag>{record.referenceId}</Tag>}
            {record.name}
          </span>
        );
      },
    },
    {
      key: `trace`,
      title: "更新日期",
      render: (text, record) => {
        return (
          <>
            {record?.trace?.lastModifiedDate
              ? moment(record?.trace?.lastModifiedDate).format(
                  "YYYY-MM-DD HH:mm"
                )
              : "-"}
          </>
        );
      },
    },
    {
      key: `minInventory`,
      title: "最低存貨量",
      render: (text, record) => {
        return <span>{record.minInventory}</span>;
      },
    },
    {
      key: `actions`,
      title: "",
      render: (record) => {
        if (isDenied) return null;
        switch (record.__typename) {
          case "ATCategoryDisplay":
            return (
              <Button
                type="link"
                size="small"
                onClick={onCreateMainCategoryButtonClick(record.id)}
                className="btn btn-secondary action-add"
              >
                新增分類
              </Button>
            );
          case "MainCategory":
            return (
              <Button
                type="link"
                size="small"
                onClick={onCreateCategoryButtonClick(record.id)}
                className="btn btn-secondary action-add"
              >
                新增子分類
              </Button>
            );
          default:
            return null;
        }
      },
    },
    {
      key: `delete`,
      title: "",
      render: (record) => {
        return !isDenied && record.__typename !== "ATCategoryDisplay" ? (
          <Button
            type="danger"
            size="small"
            onClick={onClickDeleteCategory(record)}
          >
            刪除分類
          </Button>
        ) : null;
      },
    },
  ];

  return (
    <StickyBreadcrumbPageComponent
      className={getClassList()}
      breadcrumb={BREADCRUMB}
    >
      <Modal
        title="刪除分類"
        visible={deleteCategoryParams.visible}
        onOk={() => {
          onDeleteCategory(deleteCategoryParams?.record);
          setDeleteCategoryParams((_) => ({ ..._, visible: false }));
        }}
        onCancel={() =>
          setDeleteCategoryParams((_) => ({ ..._, visible: false }))
        }
        okText="確定"
        cancelText="取消"
        okButtonProps={{ type: "danger" }}
      >
        <div>確定刪除分類 {`${deleteCategoryParams?.record?.name}`} ?</div>
        <div>所有有關子分類及貨物存貨亦會被刪除。</div>
      </Modal>
      <div className="content-inner">
        <div className="table">
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  switch (record.__typename) {
                    case "MainCategory":
                      onEditMainCategoryButtonClick(record.id)(e);
                      break;
                    case "Category":
                      onEditCategoryButtonClick(record.id)(e);
                      break;
                    default:
                  }
                },
              };
            }}
            loading={loading}
            childrenColumnName="category"
            defaultExpandAllRows={true}
            pagination={false}
            dataSource={categories}
            columns={columns}
            rowKey="id"
            scroll={{x: "max-content"}}
          ></Table>
        </div>
        <Drawer
          placement="right"
          closable={true}
          onClose={onClose}
          visible={showCategoryForm}
          width="50%"
        >
          {showCategoryForm && categoryType === "main-category" && (
            <MainCategoryForm
              mode={id ? "edit" : "create"}
              id={id}
              onAfterSubmit={onClose}
              disabled={isDenied}
            />
          )}
          {showCategoryForm && categoryType === "category" && (
            <CategoryForm
              mode={id ? "edit" : "create"}
              id={id}
              onAfterSubmit={onClose}
              disabled={isDenied}
            />
          )}
        </Drawer>
      </div>
    </StickyBreadcrumbPageComponent>
  );
};

const GET_MAIN_CATETORY = gql`
  query MainCategoryGet($id: ID) {
    mainCategoryGet(id: $id) {
      referenceId
      id
      name
      description
      remark
      atCategory {
        id
        name
      }
      trace {
        lastModifiedDate
      }
    }
  }
`;

const GET_AT_CATEGORY_LIST = gql`
  query {
    enumATCategoryList {
      id
      name
    }
  }
`;

const MAIN_CATEGORY_CREATE = gql`
  mutation MainCategoryCreate($input: MainCategoryCreateInput) {
    mainCategoryCreate(input: $input) {
      id
    }
  }
`;

const MAIN_CATEGORY_UPDATE = gql`
  mutation MainCategoryUpdate($input: MainCategoryUpdateInput) {
    mainCategoryUpdate(input: $input) {
      id
    }
  }
`;

const MainCategoryForm = (props) => {
  const { mode, id, onAfterSubmit, disabled } = props;
  const location = useLocation();

  const query = useMemo(() => {
    return {
      ...queryString.parse(location.search),
    };
  }, [location.search]);

  const atCategoryId = query.id;

  const { handleSubmit, register, setValue, watch } = useForm({});

  const [createMainCategory, { data: createdMainCategory }] = useMutation(
    MAIN_CATEGORY_CREATE
  );
  const [updateMainCategory, { data: updatedMainCategory }] = useMutation(
    MAIN_CATEGORY_UPDATE
  );

  const [getMainCategory, { data: mainCategoryData }] = useLazyQuery(
    GET_MAIN_CATETORY
  );
  const [getAtCategories, { data: atCategoryData = {} }] = useLazyQuery(
    GET_AT_CATEGORY_LIST
  );
  useEffect(() => {
    register("name", {});
    register("atCategory", {});
    register("description", {});
    register("remark", {});
  }, [register]);

  useEffect(() => {
    getAtCategories();
    if (mode === "edit") {
      getMainCategory({ variables: { id } });
    } else {
      setValue("atCategory", atCategoryId);
    }

    // eslint-disable-next-line
  }, [getAtCategories, getMainCategory, mode]);

  useEffect(() => {
    if (mainCategoryData && mainCategoryData.mainCategoryGet) {
      setValue("name", mainCategoryData.mainCategoryGet.name || "");
      setValue("atCategory", mainCategoryData.mainCategoryGet.atCategory.id);
      setValue(
        "description",
        mainCategoryData.mainCategoryGet.description || ""
      );
      setValue("remark", mainCategoryData.mainCategoryGet.remark || "");
    }
  }, [mainCategoryData, setValue]);

  const title = useMemo(() => {
    if (mode === "create") {
      return "新增分類";
    } else if (mode === "edit") {
      return "修改分類";
    }
  }, [mode]);

  const onSubmit = useCallback(
    (value) => {
      if (mode === "create") {
        const params = {
          name: value.name,
          atCategory: value.atCategory,
          description: value.description,
          remark: value.remark,
        };
        createMainCategory({ variables: { input: params } });
      } else {
        const params = {
          id: id,
          name: value.name,
          atCategory: value.atCategory,
          description: value.description,
          remark: value.remark,
        };
        updateMainCategory({ variables: { input: params } });
      }
    },
    [createMainCategory, updateMainCategory, id, mode]
  );

  useEffect(() => {
    if (createdMainCategory || updatedMainCategory) {
      onAfterSubmit();
    }
  }, [onAfterSubmit, createdMainCategory, updatedMainCategory]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h2>{title}</h2>
      <Row>
        <Row>
          <Col>
            <Form.Item>
              <span>名稱</span>
              <Input
                disabled={disabled}
                value={watch("name")}
                onChange={(e) => setValue("name", e.target.value)}
                name={"name"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <span>AT 類別</span>
              <Select
                disabled={disabled}
                label="name"
                value={watch("atCategory")}
                onChange={(value) => setValue("atCategory", value)}
                name={"atCategory"}
              >
                {(atCategoryData.enumATCategoryList || []).map(
                  ({ id, name }) => {
                    return (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <span>描述</span>
              <Input.TextArea
                disabled={disabled}
                resize="none"
                value={watch("description")}
                onChange={(e) => setValue("description", e.target.value)}
                name={"description"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <span>備註</span>
              <Input.TextArea
                disabled={disabled}
                resize="none"
                value={watch("remark")}
                onChange={(e) => setValue("remark", e.target.value)}
                name={"remark"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>{!disabled && <Button htmlType="submit">Save</Button>}</Col>
        </Row>
      </Row>
    </Form>
  );
};

const GET_CATETORY = gql`
  query CategoryGet($id: ID) {
    categoryGet(id: $id) {
      referenceId
      id
      name
      minInventory
      description
      remark
      intFundRef
      trace {
        lastModifiedDate
      }
      mainCategory {
        id
      }
    }
  }
`;

const CATEGORY_CREATE = gql`
  mutation CategoryCreate($input: CategoryCreateInput) {
    categoryCreate(input: $input) {
      id
      name
      remark
      description
      intFundRef
    }
  }
`;

const CATEGORY_UPDATE = gql`
  mutation CategoryUpdate($input: CategoryUpdateInput) {
    categoryUpdate(input: $input) {
      id
      name
      remark
      description
      intFundRef
    }
  }
`;

const CategoryForm = (props) => {
  const { mode, id, onAfterSubmit, disabled } = props;
  const location = useLocation();

  const query = useMemo(() => {
    return {
      ...queryString.parse(location.search),
    };
  }, [location.search]);

  const mainCategoryId = query.id;

  const { handleSubmit, register, setValue, watch } = useForm({});

  const [createCategory, { data: createdCategory }] = useMutation(
    CATEGORY_CREATE
  );
  const [updateCategory, { data: updatedCategory }] = useMutation(
    CATEGORY_UPDATE
  );

  const [getCategory, { data: categoryData }] = useLazyQuery(GET_CATETORY);

  useEffect(() => {
    register("name", {});
    register("minInventory", {});
    register("description", {});
    register("remark", {});
    register("intFundRef", {});
  }, [register]);

  useEffect(() => {
    if (id) {
      getCategory({ variables: { id } });
    }
  }, [getCategory, id]);

  useEffect(() => {
    if (categoryData && categoryData.categoryGet) {
      setValue("name", categoryData.categoryGet.name);
      setValue("minInventory", categoryData.categoryGet.minInventory);
      setValue("description", categoryData.categoryGet.description);
      setValue("remark", categoryData.categoryGet.remark);
      setValue("intFundRef", categoryData.categoryGet.intFundRef);
    }
  }, [categoryData, id, setValue]);

  const title = useMemo(() => {
    if (mode === "create") {
      return "新增子分類";
    } else if (mode === "edit") {
      return "修改子分類";
    }
  }, [mode]);

  const onSubmit = useCallback(
    (value) => {
      if (mode === "create") {
        const params = {
          name: value.name,
          minInventory: value.minInventory,
          description: value.description,
          mainCategoryId: mainCategoryId,
          remark: value.remark,
          intFundRef: value.intFundRef,
        };
        createCategory({ variables: { input: params } });
      } else {
        const params = {
          id: id,
          name: value.name,
          minInventory: value.minInventory,
          description: value.description,
          mainCategoryId: categoryData.categoryGet.mainCategory.id,
          remark: value.remark,
          intFundRef: value.intFundRef,
        };
        console.log("params -> ", params);
        updateCategory({ variables: { input: params } });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categoryData, createCategory, updateCategory, id, mode]
  );

  useEffect(() => {
    if (createdCategory || updatedCategory) {
      onAfterSubmit();
    }
  }, [onAfterSubmit, createdCategory, updatedCategory]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h2>{title}</h2>
      <Row>
        <Row>
          <Col>
            <Form.Item>
              <span>名稱</span>
              <Input
                disabled={disabled}
                value={watch("name")}
                onChange={(e) => setValue("name", e.target.value)}
                name={"name"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <span>最低存貨量</span>
              <Input
                disabled={disabled}
                type="number"
                resize="none"
                value={watch("minInventory")}
                onChange={(e) =>
                  setValue(
                    "minInventory",
                    e.target.value ? Number(e.target.value) : 0
                  )
                }
                name={"minInventory"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <span>intFundRef</span>
              <Input
                disabled={disabled}
                value={watch("intFundRef")}
                onChange={(e) => setValue("intFundRef", e.target.value)}
                name={"intFundRef"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <span>描述</span>
              <Input.TextArea
                disabled={disabled}
                resize="none"
                value={watch("description")}
                onChange={(e) => setValue("description", e.target.value)}
                name={"description"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <span>備註</span>
              <Input.TextArea
                disabled={disabled}
                resize="none"
                value={watch("remark")}
                onChange={(e) => setValue("remark", e.target.value)}
                name={"remark"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>{!disabled && <Button htmlType="submit">Save</Button>}</Col>
        </Row>
      </Row>
    </Form>
  );
};

MainCategoryForm.propTypes = {
  mode: PropTypes.string.isRequired,
  atCategoryId: PropTypes.string,
  id: PropTypes.string,
  onAfterSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CategoryForm.propTypes = {
  mode: PropTypes.string.isRequired,
  mainCategoryId: PropTypes.string,
  id: PropTypes.string,
  onAfterSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Category;
