/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useQuery } from "@apollo/client";
import queryString from "query-string";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Components
import CustPaginationComponent from "@components/custPagination/custPagination";

// Styles
import "./orderingActivityHistory.scss";

// Utils
import {
  Avatar,
  Button,
  Comment,
  Col,
  Divider,
  Input,
  message,
  Row,
  Select,
  Tooltip,
} from "antd";
import {
  MOMENT_DATETIME_FORMAT,
  PAGE_SIZE,
  ACCESS_LEVELS,
} from "@utils/constants";
import { traceFormattedDate } from "@utils/common";
import moment from "moment";
import { useAccessLevel } from "@utils/hook";

const API = gql`
  query EnumList {
    enumAssessmentMethodList {
      id
      name
    }
  }
`;

const ORDERING_GET = gql`
  query OrderingGet($userId: ID, $size: Int, $offSet: Int) {
    orderingGet(userId: $userId) {
      trace {
        activityHistory(size: $size, offSet: $offSet) {
          staff {
            tbId
            displayName
          }
          content
          loggedDate
        }
      }
    }
  }
`;

const ORDERING_COMMENT = gql`
  mutation OrderingComment(
    $userId: ID!
    $method: AssessmentMethod!
    $comment: String!
  ) {
    orderingComment(userId: $userId, method: $method, comment: $comment)
  }
`;

const OrderingActivityHistory = ({ userId }) => {
  const location = useLocation();
  const history = useHistory();
  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.OPERATION_EQUIPMENT_MANAGEMENT,
  ]);

  const { data: enumList } = useQuery(API);

  const [getOrdering, { data: { orderingGet: ordering } = {} }] = useLazyQuery(
    ORDERING_GET
  );

  const [
    orderingComment,
    { loading: orderingCommentLoading, data: orderingCommentData },
  ] = useMutation(ORDERING_COMMENT);

  const [isRefresh, setIsRefresh] = useState(false);
  const [statusId, setStatusId] = useState(undefined);
  const [remark, setRemark] = useState(undefined);

  const query = useMemo(() => {
    const { page, pageSize } = queryString.parse(location.search);
    return {
      ...queryString.parse(location.search),
      page: page ? Number(page) : 1,
      // pageSize: pageSize ? Number(pageSize) : PAGE_SIZE,
      pageSize: PAGE_SIZE,
    };
  }, [location.search]);

  const histories = useMemo(() => {
    return ordering?.trace?.activityHistory || [];
  }, [ordering]);

  const isMaxPage = useMemo(() => {
    return histories ? histories.length <= PAGE_SIZE : true;
  }, [histories]);

  const list = useMemo(() => {
    return histories.slice(0, PAGE_SIZE);
  }, [histories]);

  useEffect(() => {
    if (userId || isRefresh) {
      getOrdering({
        variables: {
          userId: userId,
          size: query.pageSize + 1,
          offSet: (query.page - 1) * query.pageSize,
        },
      });
      setIsRefresh(false);
    }
  }, [userId, isRefresh, query, getOrdering]);

  const onClear = () => {
    setStatusId(undefined);
    setRemark(undefined);
  };

  const onConfirm = useCallback(async () => {
    try {
      await orderingComment({
        variables: {
          userId: userId,
          method: statusId,
          comment: remark,
        },
      });
      message.success("成功新增備註");
      onClear();
      setIsRefresh(true);
    } catch (error) {
      message.error(error.message);
    }
  }, [userId, statusId, remark, orderingComment]);

  return (
    <div className="ActivityHistoryTab">
      {!isDenied && (
        <>
          <Row>
            <Col className="col-layout" xs={5} sm={2} lg={1}>
              <div className="header">
                <span className="col-title">狀態</span>
              </div>
            </Col>
            <Col xs={24} sm={20}>
              <Select
                value={statusId}
                onChange={(value) => setStatusId(value)}
                placeholder="請選擇"
                style={{ width: "50%" }}
              >
                {enumList?.enumAssessmentMethodList?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "10px" }}>
            <Col className="col-layout" xs={5} sm={2} lg={1}>
              <div className="header">
                <span className="col-title">備註</span>
              </div>
            </Col>
            <Col xs={24} sm={20}>
              <Input.TextArea
                value={remark}
                onChange={({ target: { value } }) => setRemark(value)}
                placeholder="請填寫"
                rows={6}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <div className="btn-container" style={{ marginTop: "20px" }}>
            <Row type="flex" gutter={12}>
              <Col>
                <Button className="btn btn-secondary" onClick={onClear}>
                  消除
                </Button>
              </Col>
              <Col>
                <Button
                  className="btn"
                  type="primary"
                  loading={orderingCommentLoading}
                  disabled={!(statusId && remark)}
                  onClick={onConfirm}
                >
                  儲存
                </Button>
              </Col>
            </Row>
          </div>
          <Divider />
        </>
      )}
      <div className="container">
        <div className="right">
          <CustPaginationComponent
            isMinPage={query.page === 1}
            isMaxPage={isMaxPage}
            page={query.page}
            // pageSize={query.pageSize || PAGE_SIZE}
            pageSize={PAGE_SIZE}
            onPageChange={(page) => {
              history.push({
                pathname: location.pathname,
                search: queryString.stringify({
                  ...query,
                  page,
                }),
              });
            }}
          />
        </div>
      </div>
      {list &&
        list.map((history, index) => (
          <Comment
            key={index}
            author={history?.staff?.displayName ?? "System"}
            // avatar={
            //     <Avatar>{history?.staff?.displayName[0]}</Avatar>
            // }
            content={<p>{history?.content}</p>}
            datetime={moment(history?.loggedDate).format(
              MOMENT_DATETIME_FORMAT
            )}
          />
        ))}
    </div>
  );
};

OrderingActivityHistory.propTypes = {
  userId: PropTypes.string.isRequired,
};

OrderingActivityHistory.defaultProps = {
  userId: "",
};

export default OrderingActivityHistory;
