import React, { useCallback, useEffect, useMemo } from "react";
import {
  MOMENT_DATETIME_FORMAT,
  PAGE,
  PAGE_NAME,
  PAGE_SIZE
} from "../../../../utils/constants";
import PropTypes from "prop-types";
import SearchTable from "../../../../components/newTableComponent/list/searchTable";
import { Col, DatePicker, Row, Select, Tag, Typography } from "antd";
import TableFilter from "../../../../components/newTableComponent/filter/tableFilter";
import { Controller, useForm } from "react-hook-form";

import queryString from "query-string";
import { Link, useHistory, useLocation } from "react-router-dom";

import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

import { replaceUrlByParams } from "@utils/api";
import { useListVariables } from "../../../../utils/hook";
import moment from "moment";

const cleaningJobList = gql`
  query cleaningJobList(
    $keyword: String
    $status: [CleaningJobStatus]
    $reservationStartDate: String
    $reservationEndDate: String
    $size: Int!
    $offset: Int!
    $sort: CleaningJobSort
    $reverseOrder: Boolean
  ) {
    cleaningJobList(
      keyword: $keyword
      status: $status
      reservationStartDate: $reservationStartDate
      reservationEndDate: $reservationEndDate
      size: $size
      offset: $offset
      sort: $sort
      reverseOrder: $reverseOrder
    ) {
      id
      referenceId
      serviceBuyer {
        id
        referenceId
        name
      }
      contactPerson
      telephone
      createDate
      reservationDate
      equipment {
        id
      }
      status
    }
  }
`;

const cleaningJobCount = gql`
  query cleaningJobCount(
    $keyword: String
    $status: [CleaningJobStatus]
    $reservationStartDate: String
    $reservationEndDate: String
  ) {
    cleaningJobCount(
      keyword: $keyword
      status: $status
      reservationStartDate: $reservationStartDate
      reservationEndDate: $reservationEndDate
    )
  }
`;

const BREADCRUMB = [
  {
    path: PAGE.THIRD_PARTY_CLEANING_JOB_LIST,
    name: PAGE_NAME.THIRD_PARTY_CLEANING_JOB_LIST,
  },
];

const JobListFilter = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = useMemo(
    () => queryString.parse(location.search),
    [location.search]
  );

  const defaultValues = {
    status: [],
    reservationStartDate: undefined,
    reservationEndDate: undefined,
  };

  const { control, handleSubmit, reset, setValue, register, watch } = useForm({
    defaultValues: {
      ...defaultValues,
      ...queryParams,
    },
  });

  const onSearch = useCallback(
    (data) => {
      const param = {
        ...data,
      };

      history.push({
        search: queryString.stringify({
          ...queryParams,
          ...param,
          page: 1,
        }),
      });
    },
    [history, queryParams]
  );

  const onReset = useCallback(() => {
    reset(defaultValues);
    history.push({
      search: queryString.stringify({
        ...queryParams,
        ...defaultValues,
        page: 1,
      }),
    });
  }, [defaultValues, history, queryParams, reset]);

  useEffect(() => {
    register("reservationStartDate");
    register("reservationEndDate");
  }, [register]);

  return (
    <TableFilter onReset={onReset} onSearch={handleSubmit(onSearch)}>
      <Row>
        <Col>
          <Typography>清洗狀態</Typography>
          <Controller
            control={control}
            name="status"
            render={({ onChange, value }) => (
              <Select
                style={{ width: "100%" }}
                mode="multiple"
                value={value}
                onChange={onChange}
              >
                <Select.Option value="pending">待清洗</Select.Option>
                <Select.Option value="cleaningFailure">未能清洗</Select.Option>
                <Select.Option value="completed">已完成</Select.Option>
              </Select>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Typography>預計清洗日期</Typography>
          <DatePicker.RangePicker
            value={[
              watch("reservationStartDate")
                ? moment(watch("reservationStartDate"))
                : "",
              watch("reservationEndDate")
                ? moment(watch("reservationEndDate"))
                : "",
            ]}
            onChange={(dates, dateString) => {
              setValue("reservationStartDate", dateString[0]);
              setValue("reservationEndDate", dateString[1]);
            }}
          />
        </Col>
      </Row>
    </TableFilter>
  );
};

const JobList = (props) => {
  const {
    keyword,
    reservationStartDate,
    reservationEndDate,
    status,
    ...listVariables
  } = useListVariables();

  const { data: cleaningJobListData, loading: cleaningJobListLoading } =
    useQuery(cleaningJobList, {
      variables: {
        // list all custom variables
        keyword,
        reservationStartDate,
        reservationEndDate,
        status,
        ...listVariables,
      },
    });

  const { data: cleaningJobCountData, loading: cleaningJobCountLoading } =
    useQuery(cleaningJobCount, {
      variables: { keyword, reservationStartDate, reservationEndDate, status },
    });

  const tableLoading = cleaningJobListLoading || cleaningJobCountLoading;

  const columns = [
    {
      title: "工作編號",
      dataIndex: "referenceId",
      render: (referenceId, record) => (
        <Link
          to={replaceUrlByParams(PAGE.THIRD_PARTY_CLEANING_JOB_EDIT, {
            id: record?.id,
          })}
        >
          {referenceId}
        </Link>
      ),
    },
    {
      title: "機構／用戶名稱",
      dataIndex: "serviceBuyer.name",
    },
    {
      title: "聯絡人",
      dataIndex: "contactPerson",
    },
    {
      title: "聯絡人電話",
      dataIndex: "telephone",
    },
    {
      title: "新增日期",
      dataIndex: "createDate",
      sorter: true,
      sortOrder:
        listVariables.sort === "createDate" &&
        (listVariables.reverseOrder ? "descend" : "ascend"),
      render: (timestamp) =>
        timestamp ? moment(timestamp).format(MOMENT_DATETIME_FORMAT) : "-",
    },
    {
      title: "預計清洗日期",
      dataIndex: "reservationDate",
      sorter: true,
      sortOrder:
        listVariables.sort === "reservationDate" &&
        (listVariables.reverseOrder ? "descend" : "ascend"),
      render: (timestamp) =>
        timestamp ? moment(timestamp).format(MOMENT_DATETIME_FORMAT) : "-",
    },
    {
      title: "器材數量",
      dataIndex: "equipment.length",
    },
    {
      title: "清洗狀態",
      dataIndex: "status",
      render: (text) =>
        ({
          pending: <Tag className="tag">待清洗</Tag>,
          cleaningFailure: <Tag className="tag danger">未能清洗</Tag>,
          completed: <Tag className="tag success">已完成</Tag>,
        }[text]),
    },
  ];

  const isMaxPage = useMemo(() => {
    return cleaningJobListData?.cleaningJobList <= PAGE_SIZE;
  }, [cleaningJobListData]);

  return (
    <SearchTable
      breadcrumb={BREADCRUMB}
      filter={<JobListFilter />}
      showFilterButton={false}
      filterVisible={true}
      searchInputProps={{ placeholder: "搜尋工作聯絡人電話 或 工作編號" }}
      createButton={{
        url: PAGE.THIRD_PARTY_CLEANING_JOB_CREATE,
        text: "新增",
      }}
      tableProps={{
        columns,
        rowKey: "id",
        dataSource: cleaningJobListData?.cleaningJobList,
        loading: tableLoading,
        // pagination: {
        //   total: cleaningJobCountData?.cleaningJobCount,
        // },
      }}
      total={cleaningJobCountData?.cleaningJobCount}
      isMaxPage={isMaxPage}
    />
  );
};

export default JobList;

JobList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
