/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo } from "react";
import {
  Drawer,
  Button,
  message,
  Form,
  DatePicker,
  Input,
  Row,
  Col,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import moment from "moment";

const ORDERING_ITEM_REPLACEMENT_SUBMIT = gql`
  mutation orderItemReplacementSubmit(
    $orderId: String
    $changeDate: LocalDate
    $remark: String
  ) {
    orderItemReplacementSubmit(
      orderId: $orderId
      changeDate: $changeDate
      remark: $remark
    )
  }
`;

const ChangeEquipmentUpdateDrawer = (props) => {
  const { orderingItem, onClose, isReadOnly } = props;

  const defaultValues = useMemo(() => {
    return {
      orderId: orderingItem.id,
      changeDate: orderingItem?.replacementDate ?? undefined,
      remark: orderingItem?.replacementRemark ?? "",
    };
  }, [orderingItem]);

  const { control, register, handleSubmit } = useForm({
    defaultValues,
  });

  const [onReplacementSubmit, { data, loading }] = useMutation(
    ORDERING_ITEM_REPLACEMENT_SUBMIT
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        await onReplacementSubmit({
          variables: {
            ...data,
          },
        });
        message.success("成功更新更換器材紀錄");
        onClose();
      } catch (err) {
        console.err(err);
      }
    },
    [onClose, onReplacementSubmit]
  );

  useEffect(() => {
    register({ name: "orderId" });
  }, [register]);

  const oldItem = orderingItem?.replaceFrom ?? orderingItem;
  const newItem = orderingItem?.replaceTo ?? orderingItem;

  return (
    <Drawer width="50%" visible={true} title={`額外更換器材`} onClose={onClose}>
      <Form>
        <Row>
          <Col span={6}>
            <Form.Item label="舊器材">
              {oldItem?.product?.customReferenceId ??
                oldItem?.inventoryRecord?.[0]?.referenceId}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="新器材">
              {newItem?.product?.customReferenceId ??
                newItem?.inventoryRecord?.[0]?.referenceId}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="更換類型">
          {orderingItem?.freeReplacement ? "免費更換" : "收費更換"}
        </Form.Item>
        <Form.Item label="更換日期">
          <Controller
            control={control}
            name="changeDate"
            render={({ onChange, value }) => (
              <DatePicker
                value={value ? moment(value) : undefined}
                onChange={onChange}
                disabled={isReadOnly}
              />
            )}
          />
        </Form.Item>
        <Form.Item label="更換備註">
          <Controller
            control={control}
            name="remark"
            render={({ onChange, value }) => (
              <Input.TextArea
                value={value}
                onChange={onChange}
                disabled={isReadOnly}
              />
            )}
          />
        </Form.Item>
      </Form>
      {!isReadOnly && (
        <Button
          style={{ marginTop: "8px" }}
          loading={loading}
          onClick={handleSubmit(onSubmit)}
          type="primary"
        >
          更新
        </Button>
      )}
    </Drawer>
  );
};

ChangeEquipmentUpdateDrawer.propTypes = {
  orderingItem: PropTypes.object,
  onClose: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

export default ChangeEquipmentUpdateDrawer;
