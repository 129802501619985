/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import {
  Tabs,
  Form,
  Spin,
  Row,
  Col,
  Typography,
  Select,
  Radio,
  DatePicker,
  Divider,
  Input,
  Upload,
  Icon,
  Modal,
  Button,
  Checkbox,
  notification,
  InputNumber,
} from "antd";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Controller, useForm, useWatch, useFieldArray } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { gql } from "@apollo/client";
import { getTokenHeader } from "@utils/api";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import moment from "moment";

// Components

// Constants
import { PAGE, PAGE_NAME, ACCESS_LEVELS } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

//Styles
import "./inspectionForm.scss";

// Utils
import { replaceUrlByParams } from "@utils/api";
// import CategoryFilter from "../../../../components/categoryFilter/categoryFilter";
import { getFileUploadUrl } from "@utils/env";
import { useAccessLevel } from "@utils/hook";

//gql
const INVENTORY_INSPECTION_SUBMIT = gql`
  mutation InventoryInspectionSubmit($input: InventoryInspectionUpdateInput) {
    inventoryInspectionSubmit(input: $input) {
      id
    }
  }
`;
const INVENTORY_INSPECTION_REVIEW = gql`
  mutation InventoryInspectionReview($input: InventoryInspectionUpdateInput) {
    inventoryInspectionReview(input: $input) {
      id
    }
  }
`;
const INVENTORY_INSPECTION_COMPLETE = gql`
  mutation InventoryInspectionComplete(
    $input: InventoryInspectionCompleteInput
  ) {
    inventoryInspectionComplete(input: $input) {
      id
    }
  }
`;
const INVENTORY_INSPECTION_UPDATE = gql`
  mutation InventoryInspectionUpdate($input: InventoryInspectionUpdateInput) {
    inventoryInspectionUpdate(input: $input) {
      id
    }
  }
`;

const INVENTORY_INSPECTION_GET = gql`
  query InventoryInspectionGet($id: ID) {
    inventoryInspectionGet(id: $id) {
      id
      referenceId
      inventoryRecord {
        id
        defaultPart {
          id
          referenceId
        }
        extraPart {
          id
          referenceId
        }
        product {
          __typename
          id
          referenceId
          ... on Device {
            defaultConsumable {
              consumable {
                id
                referenceId
              }
            }
          }
        }
      }
      inspection {
        id
        name
        device {
          id
        }
        part {
          id
        }
        inspectionCheckListQuestion {
          questionId
          title
          option
          answer {
            answer
            remark
            file {
              key
              url
              name
              size
            }
          }
        }
        remark
      }
      inspectionResult {
        id
        name
      }
      inspectionType {
        id
        name
      }
      inventoryRecordDisposal {
        activeDate
        disposalReason {
          id
          name
        }
        disposalReasonRemark
        followUpAction {
          id
          name
        }
        sellingPrice
        partyToReceived
        applicationRemarks
        applicationDate
        applicant {
          tbId
          username
          displayName
          allowedMutation
        }
        approvalDate
        applicationDate
        approver {
          tbId
          username
          displayName
          allowedMutation
        }
        status {
          id
          name
        }
        approvalRemark
        followUpActionDate
        actionStaff {
          tbId
          username
          displayName
          allowedMutation
        }
      }
      remark
      date
      replacedPart {
        id
        referenceId
      }
      changingPart {
        id
        referenceId
      }
      replacedConsumable {
        id
        referenceId
        product {
          id
          referenceId
        }
      }
      changingConsumable {
        id
        referenceId
      }
      alertWarehouse
      manMadeDamage
      manMadeMissingParts
      suggestedClaimAmount
      reviewingStatus
      reviewingRemark
    }
  }
`;

const INVENTORY_RECORD_GET = gql`
  query InventoryRecordGet($referenceId: String) {
    inventoryRecordGet(referenceId: $referenceId) {
      id
      referenceId
    }
  }
`;

const InspectionForm = ({ loading, inventoryInspection }) => {
  const BREADCRUMB = useMemo(
    () => [
      {
        path: PAGE.MOBILE_INSPECTION_FORM,
        name: PAGE_NAME.MOBILE_INSPECTION_FORM,
      },
    ],
    []
  );
  const { Title, Text } = Typography;
  const { TextArea } = Input;
  const { Option } = Select;

  const client = useApolloClient();
  const { id: inspectionId } = useParams();
  const history = useHistory();
  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.ADMINISTRATION,
    ACCESS_LEVELS.OPERATION_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.CLERICAL_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.WORKSHOP_MANAGEMENT,
    ACCESS_LEVELS.FINANCIAL_MANAGEMENT,
  ]);

  const { isMatched: isAllowed } = useAccessLevel([
    ACCESS_LEVELS.ADMIN_EQUIPMENT_MANAGEMENT,
    ACCESS_LEVELS.SUPERADMIN_EQUIPMENT_MANAGEMENT,
  ]);

  //api
  const [
    getInventoryInspection,
    {
      loading: inventoryInspectionGetLoading,
      error: inventoryInspectionGetError,
      data: inventoryInspectionGetData,
    },
  ] = useLazyQuery(INVENTORY_INSPECTION_GET);

  const [
    submitInventoryInspection,
    {
      loading: inventoryInspectionSubmitLoading,
      data: inventoryInspectionSubmitData,
    },
  ] = useMutation(INVENTORY_INSPECTION_SUBMIT);

  const [
    reviewInventoryInspection,
    {
      loading: inventoryInspectionReviewLoading,
      data: inventoryInspectionReviewData,
    },
  ] = useMutation(INVENTORY_INSPECTION_REVIEW);

  const [
    completeInventoryInspection,
    {
      loading: inventoryInspectionCompleteLoading,
      data: inventoryInspectionCompleteData,
    },
  ] = useMutation(INVENTORY_INSPECTION_COMPLETE);

  const [
    inventoryInspectionUpdate,
    {
      loading: inventoryInspectionUpdateLoading,
      data: inventoryInspectionUpdateData,
    },
  ] = useMutation(INVENTORY_INSPECTION_UPDATE);

  const isFormSubmiting = useMemo(
    () =>
      inventoryInspectionSubmitLoading ||
      inventoryInspectionReviewLoading ||
      inventoryInspectionCompleteLoading,
    [
      inventoryInspectionCompleteLoading,
      inventoryInspectionReviewLoading,
      inventoryInspectionSubmitLoading,
    ]
  );
  const isMutateSuccess = useMemo(
    () =>
      inventoryInspectionSubmitData ||
      inventoryInspectionReviewData ||
      inventoryInspectionCompleteData ||
      inventoryInspectionUpdateData,
    [
      inventoryInspectionCompleteData,
      inventoryInspectionReviewData,
      inventoryInspectionSubmitData,
      inventoryInspectionUpdateData,
    ]
  );

  useEffect(() => {
    if (inspectionId && !inventoryInspectionGetData) {
      getInventoryInspection({ variables: { id: inspectionId } });
    }
  }, [getInventoryInspection, inspectionId, inventoryInspectionGetData]);

  const inventoryInspectionData =
    inventoryInspectionGetData?.inventoryInspectionGet ?? null;

  const reviewingStatus = inventoryInspectionData?.reviewingStatus;

  const isPreviewMode =
    reviewingStatus === "reviewed" || reviewingStatus === "completed";

  const submitText = useMemo(() => {
    switch (reviewingStatus) {
      case "pending":
        return "完成覆檢";
      case "reviewed":
      case "completed":
        return "提交";
      default:
        // case null, reviewingStatus returns null when it's the first time submiting the form.
        return "完成維修/檢查";
    }
  }, [reviewingStatus]);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      id: inspectionId,
      inspectionCheckListAnswer: [],
      inventoryRecordDisposal: {
        inventoryRecordId: "",
        activeDate: moment().format("YYYY-MM-DD"),
        disposalReason: "",
        disposalReasonRemark: "",
        followUpAction: "donate",
        sellingPrice: 0,
        partyToReceived: "",
        applicationremarks: "",
      },
      inspectionResult: "normal",
      remark: "",
      date: null,
      partIds: [{ hangingPartId: "", replacedPartId: "" }],
      replacedConsumableId: [],
      changingConumableId: [],
      alertWarehouse: false,
      manMadeDamage: false,
      manMadeMissingParts: false,
      suggestedClaimAmount: 0,
      reviewingStatus: null,
      reviewingRemark: "",
    },
  });

  const { fields: answers } = useFieldArray({
    control,
    name: "inspectionCheckListAnswer",
    defaultValue: [],
  });

  useEffect(() => {
    const data = inventoryInspectionGetData?.inventoryInspectionGet;
    if (!data) return;
    const { id, inventoryRecordDisposal, inventoryRecord } = data;
    if (!inventoryRecord) return;
    const prefillData = {
      id,
      inspectionCheckListAnswer: (
        data?.inspection?.inspectionCheckListQuestion ?? []
      ).map(({ questionId, option, answer }) => ({
        questionId,
        option: [answer?.answer?.[0] ?? option?.[0]],
        remark: answer?.remark ?? "",
        file:
          answer?.file?.map(({ key, name, url, size }, uid) => ({
            key,
            uid,
            name,
            url,
            size,
            status: "done",
          })) ?? [],
      })),
      inventoryRecordDisposal: {
        inventoryRecordId:
          inventoryRecord?.id ?? inventoryInspectionData?.inventoryRecord?.id,
        activeDate:
          inventoryRecordDisposal?.activeDate ?? moment().format("YYYY-MM-DD"),
        disposalReason:
          inventoryRecordDisposal?.disposalReason?.id ?? "naturalDamage",
        disposalReasonRemark:
          inventoryRecordDisposal?.disposalReasonRemark ?? "",
        followUpAction: inventoryRecordDisposal?.followUpAction?.id ?? "donate",
        sellingPrice: inventoryRecordDisposal?.sellingPrice / 100 ?? 0,
        partyToReceived: inventoryRecordDisposal?.partyToReceived ?? "",
        applicationRemarks: inventoryRecordDisposal?.applicationRemarks ?? "",
      },
      inspectionResult: data?.inspectionResult?.id ?? "pending",
      remark: data?.remark ?? "",
      date: data?.date ?? null,
      partIds: data?.changingPart.map((part, index) => ({
        changingPartId: part?.id ?? "",
        changingPartReferenceId: part?.referenceId ?? "",
        replacedPartId: data?.replacedPart[index]?.id ?? "",
        replacedPartReferenceId: data?.replacedPart[index]?.referenceId ?? "",
      })),
      replacedConsumableId:
        data?.replacedConsumable?.map(({ product: { id } }) => id) ?? [],
      changingConsumableId: data?.changingConsumable.map(({ id }) => id) ?? [],
      alertWarehouse: data?.alertWarehouse,
      manMadeDamage: data?.manMadeDamage,
      manMadeMissingParts: data?.manMadeMissingParts,
      suggestedClaimAmount: data?.suggestedClaimAmount / 100 ?? 0,
      reviewingStatus: data?.reviewingStatus,
      reviewingRemark: data?.reviewingRemark,
    };

    reset(prefillData);
  }, [
    getValues,
    inventoryInspectionData,
    inventoryInspectionGetData,
    reset,
    setValue,
  ]);

  const { fields: partIds, append, remove } = useFieldArray({
    control,
    name: "partIds",
  });

  //state
  const [previewParams, setPreviewParams] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
  });

  //upload
  const handleCancel = () => setPreviewParams({ previewVisible: false });

  const photoUploadButton = (
    <div className="file-upload-btn">
      <Icon type="camera" theme="filled" />
      <div className="ant-upload-text">點擊上載相片</div>
    </div>
  );

  const onClickMutateBtn = useCallback(
    async ({ data, type }) => {
      try {
        const inventoryRecordDisposalSubmitData = {
          inventoryRecordId:
            data?.inventoryRecordDisposal?.inventoryRecordId ??
            inventoryInspectionData?.inventoryRecord?.id,
          activeDate:
            data?.inventoryRecordDisposal?.activeDate ??
            moment().format("YYYY-MM-DD"),
          disposalReasonRemark:
            data?.inventoryRecordDisposal?.disposalReasonRemark ?? "",
          followUpAction:
            data?.inventoryRecordDisposal?.followUpAction ?? "reimburse",
          applicationRemarks:
            data?.inventoryRecordDisposal?.applicationRemarks ?? 0,
        };

        if (
          data?.inventoryRecordDisposal?.followUpAction === "donate" ||
          data?.inventoryRecordDisposal?.followUpAction === "resale"
        ) {
          inventoryRecordDisposalSubmitData.partyToReceived =
            data?.inventoryRecordDisposal?.partyToReceived ?? "";
        }
        if (data?.inventoryRecordDisposal?.followUpAction === "resale") {
          inventoryRecordDisposalSubmitData.sellingPrice =
            data?.inventoryRecordDisposal?.sellingPrice * 100 ?? 0;
        }
        inventoryRecordDisposalSubmitData.disposalReason =
          data?.inventoryRecordDisposal?.disposalReason ?? "naturalDamage";

        let submitData = {
          id: inventoryInspectionData?.id,
          inspectionCheckListAnswer: data?.inspectionCheckListAnswer?.map(
            ({ file, ...answer }) => ({
              ...answer,
              file: file?.map(({ key, name, size }) => ({ key, name, size })),
            })
          ),
          ...(data?.inventoryRecordDisposal && {
            inventoryRecordDisposal: inventoryRecordDisposalSubmitData,
          }),
          inspectionResult: data?.inspectionResult ?? "",
          remark: data?.remark ?? "",
          date: data?.date ?? moment().format("YYYY-MM-DD") ?? 0,
          ...(data?.partIds?.length > 0 && {
            replacedPartId: data?.partIds?.map((part) => part?.replacedPartId),
            changingPartId: data?.partIds?.map((part) => part?.changingPartId),
          }),
          ...(data?.replacedConsumableId && {
            changingConsumableId: data?.replacedConsumableId,
            replacedConsumableId: data?.replacedConsumableId,
          }),
          ...(data?.manMadeDamage && {
            manMadeDamage: data?.manMadeDamage,
          }),
          ...(data?.manMadeMissingParts && {
            manMadeMissingParts: data?.manMadeMissingParts,
          }),
          ...(data?.alertWarehouse && {
            alertWarehouse: data?.alertWarehouse,
          }),
          suggestedClaimAmount: data?.suggestedClaimAmount * 100 ?? 0,
          reviewingRemark: data?.reviewingRemark,
        };
        if (type === "submit") {
          if (!reviewingStatus) {
            submitInventoryInspection({ variables: { input: submitData } });
          } else if (reviewingStatus === "pending") {
            reviewInventoryInspection({ variables: { input: submitData } });
          } else if (
            reviewingStatus === "reviewed" ||
            reviewingStatus === "completed"
          ) {
            submitData = {
              id: inventoryInspectionData?.id,
              ...(data?.inventoryRecordDisposal && {
                inventoryRecordDisposal: inventoryRecordDisposalSubmitData,
              }),
              suggestedClaimAmount: data?.suggestedClaimAmount * 100 ?? 0,
              reviewingRemark: data?.reviewingRemark,
            };
            completeInventoryInspection({ variables: { input: submitData } });
          }
        } else if (type === "save") {
          inventoryInspectionUpdate({ variables: { input: submitData } });
        }
      } catch (error) {
        notification.error({
          message: `${type === "submit" ? "維修/檢查" : "儲存"}失敗`,
          duration: 2,
        });
      }
    },
    [
      completeInventoryInspection,
      inventoryInspectionData,
      inventoryInspectionUpdate,
      reviewInventoryInspection,
      reviewingStatus,
      submitInventoryInspection,
    ]
  );

  useEffect(() => {
    if (isMutateSuccess) {
      notification.success({
        message: `成功提交`,
        duration: 2,
      });

      history.push({
        pathname: replaceUrlByParams(PAGE.MOBILE_INVENTORY_DETAIL, {
          id: inventoryInspectionData?.inventoryRecord?.id,
        }),
        search: "?tabKey=inspectionRecords",
      });
    }
  }, [history, inventoryInspectionData, isMutateSuccess]);

  const consumableOptions = (
    inventoryInspectionData?.inventoryRecord?.product?.defaultConsumable ?? []
  ).map(({ consumable }) => consumable);

  const [showAlertWarehouseModal, setShowAlertWarehouseModal] = useState(false);

  return (
    <Spin spinning={inventoryInspectionGetLoading}>
      <StickyBreadcrumbPageComponent
        className="device-create-page"
        breadcrumb={BREADCRUMB}
      >
        <Form>
          <Modal
            visible={showAlertWarehouseModal}
            title="是否需檢閱檢查/維修結果？"
            onCancel={() => setShowAlertWarehouseModal(false)}
            cancelText="取消"
            okText={submitText}
            okButtonProps={{
              loading: isFormSubmiting,
              className: "btn",
              type: "primary",
              onClick: handleSubmit((data) =>
                onClickMutateBtn({ data, type: "submit" })
              ),
            }}
          >
            <Controller
              name={`alertWarehouse`}
              control={control}
              valuePropName="checked"
              render={({ onChange, value, name }) => (
                <Checkbox
                  disabled={isPreviewMode}
                  onChange={(e) => onChange(e.target.checked)}
                  checked={value}
                  name={name}
                >
                  需檢閱檢查/維修結果
                </Checkbox>
              )}
            />
          </Modal>
          <div>
            <Typography>
              檢查/維修日期 <span style={{ color: "red" }}>*</span>
            </Typography>
            <Controller
              control={control}
              name="date"
              render={({ onChange, onBlur, value, name }) => {
                return (
                  <DatePicker
                    disabled={isPreviewMode}
                    onChange={(e) => onChange(moment(e).format("YYYY-MM-DD"))}
                    value={value ? moment(value, "YYYY-MM-DD") : null}
                    name={name}
                    allowClear={false}
                    placeholder="請選擇日期"
                  />
                );
              }}
              rules={{ required: "請選擇日期" }}
            />
            <p>
              <Typography.Text type="danger">
                {errors?.date?.message}
              </Typography.Text>
            </p>
          </div>
          <Divider />
          {answers.map((answer, index) => {
            const question =
              inventoryInspectionData?.inspection
                ?.inspectionCheckListQuestion?.[index];
            return (
              <div key={question?.questionId}>
                <input
                  type="hidden"
                  ref={register}
                  name={`inspectionCheckListAnswer[${index}].questionId`}
                  value={question?.questionId}
                />
                <Title level={3}>{`Q${index + 1}:${question?.title}`}</Title>
                <Controller
                  name={`inspectionCheckListAnswer[${index}].option`}
                  control={control}
                  render={({ onChange, onBlur, value, name }) => {
                    return (
                      <Select
                        disabled={isPreviewMode}
                        name={name}
                        onBlur={onBlur}
                        defaultActiveFirstOption={true}
                        onChange={(value) => onChange([value])}
                        value={value?.[0] ?? null}
                        className="v-space"
                        style={{ minWidth: "380px" }}
                      >
                        {(question?.option ?? []).map((opt, optIndex) => {
                          return (
                            <Option value={opt} key={`option_${optIndex}`}>
                              {opt}
                            </Option>
                          );
                        })}
                      </Select>
                    );
                  }}
                />
                <div className="v-space">
                  <Controller
                    name={`inspectionCheckListAnswer[${index}].remark`}
                    control={control}
                    render={({ onChange, onBlur, value, name }) => (
                      <TextArea
                        disabled={isPreviewMode}
                        onChange={(value) => onChange(value)}
                        value={value}
                        rows={6}
                      />
                    )}
                  />
                </div>
                <Controller
                  name={`inspectionCheckListAnswer[${index}].file`}
                  control={control}
                  render={({ onChange, onBlur, value, name }) => (
                    <Upload
                      disabled={isPreviewMode}
                      name="file"
                      listType="picture-card"
                      headers={{
                        "x-token": getTokenHeader(),
                        "X-Requested-With": null,
                      }}
                      className="avatar-uploader"
                      action={getFileUploadUrl()}
                      fileList={value}
                      onChange={(info) => {
                        let fileList = [...info.fileList].reduce(
                          (acc, file) => {
                            if (file.response?.data) {
                              file.key = file.response.data?.key;
                              file.url = file.response.data?.url;
                            }
                            acc.push(file);
                            return acc;
                          },
                          []
                        );
                        onChange(fileList);
                      }}
                    >
                      {photoUploadButton}
                    </Upload>
                  )}
                />
                <Modal
                  visible={previewParams?.previewVisible}
                  title={previewParams?.previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="previewImage"
                    style={{ width: "100%" }}
                    src={previewParams?.previewImage}
                  />
                </Modal>
                <Divider />
              </div>
            );
          })}

          <Title level={3}>結果</Title>
          <div className="v-space">
            <Controller
              name={`inspectionResult`}
              control={control}
              render={({ onChange, value: result, name }) => {
                return (
                  <Select
                    disabled={isPreviewMode}
                    onChange={onChange}
                    value={result}
                    name={name}
                    style={{ minWidth: "380px" }}
                  >
                    {[
                      { value: "pending", text: "待維修" },
                      {
                        value: "repairing",
                        text: "維修中",
                      },
                      {
                        value: "normal",
                        text: "正常",
                      },
                      {
                        value: "repaired",
                        text: "已維修",
                      },
                      {
                        value: "unableToRepair",
                        text: "未能維修",
                      },
                    ].map(({ value, text }) => (
                      <Option key={value} value={value}>
                        {text}
                      </Option>
                    ))}
                  </Select>
                );
              }}
            />
          </div>
          <div className="v-space">
            <Typography>備註：</Typography>
            <Controller
              name={`remark`}
              control={control}
              render={({ onChange, value, name }) => (
                <TextArea
                  disabled={isPreviewMode}
                  onChange={(e) => onChange(e)}
                  value={value}
                  name={name}
                  rows={6}
                />
              )}
            />
          </div>
          {watch("inspectionResult") === `repaired` &&
            [
              ...(inventoryInspectionData?.inventoryRecord?.defaultPart ?? []),
              ...(inventoryInspectionData?.inventoryRecord?.extraPart ?? []),
              ...(consumableOptions ?? []),
            ].length > 0 &&
            inventoryInspectionGetData?.inventoryInspectionGet?.inventoryRecord
              ?.product?.__typename === "Device" && (
              <div>
                <div className={`v-space`}>
                  <Typography className="h-space">更換零件：</Typography>
                  {partIds.map(
                    (
                      {
                        id,
                        replacedPartId,
                        changingPartId,
                        changingPartReferenceId,
                      },
                      index
                    ) => (
                      <div key={id} className={`top-padding`}>
                        <div className={`v-flex border-card padding`}>
                          <div className={`width-limiter `}>
                            <input
                              style={{
                                maxWidth: 0,
                                maxHeight: 0,
                                width: 0,
                                height: 0,
                                padding: 0,
                                margin: 0,
                                visibility: "hidden",
                              }}
                              ref={register}
                              name={`partIds[${index}].changingPartId`}
                              defaultValue={changingPartId}
                            />

                            <Controller
                              name={`partIds[${index}].replacedPartId`}
                              control={control}
                              rules={{
                                validate: {
                                  duplicated: (value) => {
                                    return (
                                      !getValues()?.partIds?.find(
                                        ({ replacedPartId }, i) =>
                                          i !== index &&
                                          replacedPartId === value
                                      ) || "選擇了重覆的零件"
                                    ); // todo check duplication
                                  },
                                },
                              }}
                              defaultValue={replacedPartId}
                              render={({ onChange, value, name }) => (
                                <Select
                                  disabled={isPreviewMode}
                                  onChange={onChange}
                                  value={value}
                                  name={name}
                                  placeholder={`選擇舊零件`}
                                >
                                  {[
                                    ...(inventoryInspectionData?.inventoryRecord
                                      ?.defaultPart ?? []),
                                    ...(inventoryInspectionData?.inventoryRecord
                                      ?.extraPart ?? []),
                                    ...(inventoryInspectionData?.replacedPart ??
                                      []),
                                  ].map(({ id, referenceId }) => (
                                    <Option value={id} key={id}>
                                      {referenceId}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                            />
                            {errors?.partIds?.[index]?.replacedPartId && (
                              <Typography.Text type="danger">
                                {
                                  errors?.partIds?.[index]?.replacedPartId
                                    .message
                                }
                              </Typography.Text>
                            )}
                          </div>
                          <Typography className="h-space">↓</Typography>
                          <div className={`width-limiter`}>
                            <Controller
                              name={`partIds[${index}].changingPartReferenceId`}
                              control={control}
                              rules={{
                                required: { value: true, message: "請填寫" },
                                validate: async (value) => {
                                  try {
                                    const { data } = await client.query({
                                      query: INVENTORY_RECORD_GET,
                                      variables: { referenceId: value },
                                    });
                                    setValue(
                                      `partIds[${index}].changingPartId`,
                                      data?.inventoryRecordGet?.id
                                    );
                                    if (!data?.inventoryRecordGet?.id) {
                                      throw new Error("錯誤零件編號");
                                    }
                                    return true;
                                  } catch (error) {
                                    return "錯誤零件編號";
                                  }
                                },
                              }}
                              defaultValue={changingPartReferenceId}
                              render={({ onChange, value, name, onBlur }) => {
                                return (
                                  <Input
                                    onBlur={onBlur}
                                    disabled={isPreviewMode}
                                    placeholder={`填寫新零件ID`}
                                    onChange={({ target: { value: input } }) =>
                                      onChange(input)
                                    }
                                    value={value}
                                    name={name}
                                  />
                                );
                              }}
                            />
                            {errors?.partIds?.[index]
                              ?.changingPartReferenceId && (
                              <Typography.Text type="danger">
                                {
                                  errors?.partIds?.[index]
                                    ?.changingPartReferenceId?.message
                                }
                              </Typography.Text>
                            )}
                          </div>
                          {!isPreviewMode && (
                            <div className="h-space grey">
                              <Icon
                                type="close"
                                key={`partIds_${index}_remove`}
                                onClick={() => {
                                  remove(index);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div>
                  {!isPreviewMode && (
                    <Button
                      onClick={() => {
                        append("");
                      }}
                    >
                      新增
                    </Button>
                  )}
                </div>
                {/* consumable */}
                {consumableOptions?.length > 0 && (
                  <div className={`v-space`}>
                    <Typography className="h-space">更換消耗品：</Typography>
                    <Controller
                      control={control}
                      name="replacedConsumableId"
                      defaultValue={[]}
                      render={({
                        onChange,
                        value: consumableIds = [],
                        name,
                      }) => (
                        <div className={`v-space`}>
                          <Select
                            mode="multiple"
                            disabled={isPreviewMode}
                            onChange={onChange}
                            value={consumableIds}
                            placeholder={`填寫消耗品ID`}
                            name={name}
                            style={{ minWidth: "380px" }}
                          >
                            {(consumableOptions ?? []).map((consumable) => (
                              <Option
                                value={consumable?.id}
                                key={consumable?.id}
                              >
                                {consumable?.referenceId}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
            )}
          {(watch().inspectionResult === "repaired" ||
            watch().inspectionResult === "unableToRepair") && (
            <div className="v-space">
              <Controller
                name={`manMadeDamage`}
                control={control}
                valuePropName="checked"
                render={({ onChange, value, name }) => (
                  <Checkbox
                    disabled={isPreviewMode}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    name={name}
                  >
                    人為損壞
                  </Checkbox>
                )}
              />
              <Controller
                name={`manMadeMissingParts`}
                control={control}
                valuePropName="checked"
                render={({ onChange, value, name }) => (
                  <Checkbox
                    disabled={isPreviewMode}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    name={name}
                  >
                    人為遺失配件
                  </Checkbox>
                )}
              />
            </div>
          )}
          {(reviewingStatus === "pending" || isPreviewMode) &&
            (watch().inspectionResult === "repaired" ||
              watch().inspectionResult === "unableToRepair") && (
              <Controller
                name={`alertWarehouse`}
                control={control}
                valuePropName="checked"
                render={({ onChange, value, name }) => (
                  <Checkbox
                    disabled={isPreviewMode}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    name={name}
                  >
                    需檢閱檢查/維修結果
                  </Checkbox>
                )}
              />
            )}

          {(watch().inspectionResult === "repaired" ||
            watch().inspectionResult === "unableToRepair") && (
            <div className="v-space">
              <Typography>建議賠償金額</Typography>
              <Controller
                name={`suggestedClaimAmount`}
                control={control}
                render={({ onChange, value, name }) => (
                  <InputNumber
                    disabled={
                      (!isAllowed && isDenied) ||
                      reviewingStatus === "completed"
                    }
                    onChange={(e) => onChange(e)}
                    value={value}
                    name={name}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    min={0}
                  />
                )}
              />
            </div>
          )}
          <div className="v-space">
            <Typography>審核備註：</Typography>
            <Controller
              name={`reviewingRemark`}
              control={control}
              render={({ onChange, value, name }) => (
                <TextArea
                  onChange={(e) => onChange(e)}
                  value={value}
                  name={name}
                  rows={6}
                />
              )}
            />
          </div>

          {watch(`inspectionResult`) === `unableToRepair` && (
            <div className="v-space">
              <Divider />
              <Title level={3}>落架</Title>

              <input
                type="hidden"
                ref={register}
                name={`inventoryRecordDisposal.inventoryRecordId`}
                value={inventoryInspectionData?.inventoryRecord?.id}
              />
              <div className="v-space">
                <Typography>生效日期：</Typography>
                <Controller
                  name={`inventoryRecordDisposal.activeDate`}
                  control={control}
                  defaultValue={moment().format("YYYY-MM-DD")}
                  render={({ onChange, value, name }) => (
                    <DatePicker
                      disabled={
                        (!isAllowed && isDenied) ||
                        reviewingStatus === "completed"
                      }
                      onChange={(e) => onChange(moment(e).format("YYYY-MM-DD"))}
                      value={moment(value, "YYYY-MM-DD")}
                      allowClear={false}
                    />
                  )}
                />
              </div>
              <div className="v-space">
                <Typography>處理方法：</Typography>
                <Controller
                  name={`inventoryRecordDisposal.followUpAction`}
                  control={control}
                  defaultValue={`reimburse`}
                  render={({ onChange, value, name }) => (
                    <Select
                      disabled={
                        (!isAllowed && isDenied) ||
                        reviewingStatus === "completed"
                      }
                      onChange={(e) => onChange(e)}
                      value={value}
                      name={name}
                    >
                      <Option value={`reimburse`}>報銷</Option>
                      <Option value={`donate`}>捐贈</Option>
                      <Option value={`resale`}>轉售</Option>
                    </Select>
                  )}
                />
              </div>
              {watch(`inventoryRecordDisposal.followUpAction`) === `resale` && (
                <div className="v-space">
                  <Typography>轉售價錢：</Typography>
                  <Controller
                    name={`inventoryRecordDisposal.sellingPrice`}
                    control={control}
                    defaultValue={1}
                    render={({ onChange, value, name }) => (
                      <InputNumber
                        disabled={
                          (!isAllowed && isDenied) ||
                          reviewingStatus === "completed"
                        }
                        onChange={(e) => onChange(e)}
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        value={value}
                        name={name}
                        min={0}
                      />
                    )}
                  />
                </div>
              )}
              {watch(`inventoryRecordDisposal.followUpAction`) === `resale` ||
              watch(`inventoryRecordDisposal.followUpAction`) === `donate` ? (
                <div className="v-space">
                  <Typography>{`${
                    watch(`inventoryRecordDisposal.followUpAction`) === `resale`
                      ? `接受轉售者`
                      : `接受捐贈者`
                  }：`}</Typography>
                  <Controller
                    name={`inventoryRecordDisposal.partyToReceived`}
                    control={control}
                    defaultValue={``}
                    render={({ onChange, value, name }) => (
                      <Input
                        disabled={
                          (!isAllowed && isDenied) ||
                          reviewingStatus === "completed"
                        }
                        onChange={(e) => onChange(e)}
                        value={value}
                        name={name}
                      />
                    )}
                  />
                </div>
              ) : (
                false
              )}
              <div className="v-space">
                <Typography>落架原因：</Typography>
                <Controller
                  name={`inventoryRecordDisposal.disposalReason`}
                  control={control}
                  defaultValue={`naturalDamage`}
                  render={({ onChange, value, name }) => (
                    <Select
                      disabled={
                        (!isAllowed && isDenied) ||
                        reviewingStatus === "completed"
                      }
                      onChange={(e) => onChange(e)}
                      value={value}
                      name={name}
                    >
                      <Option value={`naturalDamage`}>自然損壞</Option>
                      <Option value={`manMadeDamage`}>人為損壞</Option>
                      <Option value={`lostByUser`}>用戶遺失</Option>
                      <Option value={`others`}>其他</Option>
                    </Select>
                  )}
                />
              </div>
              <div className="v-space">
                <Typography>落架原因備注：</Typography>
                <Controller
                  name={`inventoryRecordDisposal.disposalReasonRemark`}
                  control={control}
                  defaultValue={``}
                  render={({ onChange, value, name }) => (
                    <TextArea
                      disabled={
                        (!isAllowed && isDenied) ||
                        reviewingStatus === "completed"
                      }
                      onChange={(e) => onChange(e)}
                      value={value}
                      name={name}
                      rows={6}
                    />
                  )}
                />
              </div>
              <div className="v-space">
                <Typography>申請備注：</Typography>
                <Controller
                  name={`inventoryRecordDisposal.applicationRemarks`}
                  control={control}
                  defaultValue={``}
                  render={({ onChange, value, name }) => (
                    <TextArea
                      disabled={
                        (!isAllowed && isDenied) ||
                        reviewingStatus === "completed"
                      }
                      onChange={(e) => onChange(e)}
                      value={value}
                      name={name}
                      rows={6}
                    />
                  )}
                />
              </div>
            </div>
          )}
          <div className="btn-container">
            <Button
              className="btn btn-secondary"
              onClick={() => {
                history.push({
                  pathname: replaceUrlByParams(PAGE.MOBILE_INVENTORY_DETAIL, {
                    id: inventoryInspectionData?.inventoryRecord?.id,
                  }),
                  search: "?tabKey=inspectionRecords",
                });
              }}
            >
              返回
            </Button>
            {watch().inspectionResult &&
              !["repairing", "pending"].includes(watch().inspectionResult) &&
              (!(reviewingStatus === "completed") ||
                !(
                  reviewingStatus === "completed" &&
                  !watch().manMadeDamage &&
                  !watch().manMadeMissingParts
                )) && (
                <Button
                  loading={isFormSubmiting}
                  className="btn"
                  type="primary"
                  onClick={handleSubmit((data) =>
                    (watch().inspectionResult === "repaired" ||
                      watch().inspectionResult === "unableToRepair") &&
                    reviewingStatus === "pending"
                      ? setShowAlertWarehouseModal(true)
                      : onClickMutateBtn({ data, type: "submit" })
                  )}
                >
                  {submitText}
                </Button>
              )}
            {!["completed", "reviewed"].includes(reviewingStatus) && (
              <Button
                loading={inventoryInspectionUpdateLoading}
                className="btn btn-secondary"
                onClick={handleSubmit((data) =>
                  onClickMutateBtn({ data, type: "save" })
                )}
              >
                更新
              </Button>
            )}
          </div>
        </Form>
      </StickyBreadcrumbPageComponent>
    </Spin>
  );
};

export default InspectionForm;

InspectionForm.propTypes = {
  loading: PropTypes.bool,
  inventoryInspection: PropTypes.array,
};
