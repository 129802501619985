/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import moment from "moment";

// Style
import "./viewDamageReportsDrawer.scss";

// Utils
import {
  Button,
  Drawer,
  Typography,
  Input,
  Select,
  Table,
  message,
  Tag,
  Menu,
  Dropdown,
  Icon,
  Spin,
} from "antd";
import { useQuery } from "@apollo/client";
import {
  MOMENT_FORMAT,
  MOMENT_DATETIME_FORMAT,
  PRODUCT_TYPE,
} from "@utils/constants";
import { replaceUrlByParams } from "@utils/api";
import { classList } from "@utils/common";

// components
import CreateDamageReportDrawer from "@components/createDamageReportDrawer/createDamageReportDrawer";


const INVENTORY_RECORD_GET = gql`
  query inventoryRecordGet(
    $id: ID
    $referenceId: String
    $size: Int
    $offset: Int
  ) {
    inventoryRecordGet(id: $id, referenceId: $referenceId) {
      id
      inventoryRecordOrderHistory(size: $size, offset: $offset) {
        id
        referenceId
        user {
          deleted
          userId
          username
          nameChi
          referralInfo {
            id
          }
        }
        leasedDate
        returnedDate
        damageReportLastUpdateDate
        damageReportLastModifier {
          displayName
        }
        damageReport {
            id
            order {
              id
            }
            damageDatetime
            address
            damageReason
            damageType {
              id
              name
            }
            damageTypeOther
            damageDetail
            damageImage {
              key
              url
              name
              size
            }
            userStatementAndSignature {
              key
              url
              name
              size
            }
            repairResult {
              id
              name
            }
            repairRemark
            trace {
              creator {
                displayName
              }
              lastModifier {
                displayName
              }
              lastModifiedDate
            }
        }
      }
      product {
        id
        referenceId
        ... on Device {
          defaultPart {
            quantity
            part {
              id
              referenceId
            }
          }
          inspection {
            id
            name
          }
          productInfo {
            nameChi
            thumbnail {
              url
            }
            modelNumber
          }
        }
        ... on Part {
          inspection {
            id
            name
          }
          productInfo {
            nameChi
            thumbnail {
              url
            }
            modelNumber
          }
        }
        ... on Consumable {
          productInfo {
            nameChi
            thumbnail {
              url
            }
            modelNumber
          }
        }
        category {
          name
        }
      }
      referenceId
    }
  }
`;

function ViewDamageReportsDrawer({
  className,
  userId,
  visible,
  // mode,
  onClose,
  onSubmit,
  orderingItemId,
  onCreateDamageReportDrawerSubmit,
  userAddress,
  canCreate,
  inventoryRecordId,
}) {
  const format = "HH:mm";
  const { TextArea } = Input;
  const InputGroup = Input.Group;
  const { Option } = Select;
  let location = useLocation();
  let history = useHistory();
  const { Text, Link } = Typography;

  const query = useMemo(() => {
    // const { page, pageSize } = queryString.parse(location.search);
    return {
      ...queryString.parse(location.search),
    };
  }, [location.search]);

  const [
    createDamageReportDrawerParams,
    setCreateDamageReportDrawerParams,
  ] = useState({ visible: false, mode: "Device" });

  const [items, setItems] = useState({});

  const onCreateDamageReportsDrawerClose = () => {
    refetch();
    setCreateDamageReportDrawerParams({ visible: false });
    history.replace({
      search: queryString.stringify({
        ...query,
        damageReport: undefined,
      }),
    });
  };

  const { loading, data, refetch } = useQuery(INVENTORY_RECORD_GET, {
    variables: {
        id: inventoryRecordId
    },
  });

  // console.log("data", data);

  const orderingItem = useMemo(
    () =>
      data?.inventoryRecordGet?.inventoryRecordOrderHistory?.find(
        (item) => item.id === orderingItemId
      ),
    [data, orderingItemId]
  );

  const mode = useMemo(() => data?.inventoryRecordGet?.product?.__typename, [data?.inventoryRecordGet?.product?.__typename]);

  console.log(orderingItem);

  const reports = useMemo(
    () =>
      [...(orderingItem?.damageReport || [])].sort((a, b) =>
        a.damageDatetime > b.damageDatetime ? -1 : 1
      ),
    [orderingItem]
  );

  console.log("reports", reports);

  //on drawer submit
  //   const onDrawerSubmit = async () => {
  //     const submit = Object.values(items).map((item) => {
  //       return {
  //         productId: item.id,
  //         quantity: item.qty,
  //         userId: userId,
  //       };
  //     });
  //     if (isCreate) {
  //       await orderingItemsCreate({
  //         variables: {
  //           input: submit,
  //         },
  //       });
  //     } else {
  //       await orderingItemsReplace({
  //         variables: {
  //           orderingItemId: orderingItemId,
  //           productId: submit[0].productId,
  //           quantity: submit[0].quantity,
  //         },
  //       });
  //     }

  //     onDrawerClose();
  //   };

  //fetch some api when the drawer is visable
  //   useEffect(() => {
  //     if (visible) {
  //       fetchSomeApi();
  //     }
  //   }, []);

  //pop up submit message
  //   useEffect(() => {
  //     if (orderingItemsCreateData && isCreate) {
  //       notification.success({
  //         message: `成功${isCreate ? "新增" : "更換"}訂單項目`,
  //       });
  //       setItems({});

  //       if (onSubmit) {
  //         onSubmit();
  //       }
  //     }
  //   }, []);
  //租賃日期
  const DamageReportsCol = useMemo(() => {
    return [
      {
        title: "損毀時間",
        dataIndex: "damageDatetime",
        render: (text, { data }) => {
          console.log("helloo", data);
          console.log("text", text);
          return <div>{text ? moment(text).format(MOMENT_FORMAT) : "-"}</div>;
        },
      },
      {
        title: "損毀報告更新日期",
        dataIndex: "trace.lastModifiedDate",
        render: (text, { data }) => {
          console.log("helloo", data);
          console.log("text", text);
          return <div>{text ? moment(text).format(MOMENT_FORMAT) : "-"}</div>;
        },
      },
      {
        title: "損毀報告更新人",
        dataIndex: "trace.lastModifier.displayName",
        render: (text, { data }) => {
          console.log("helloo", data);
          console.log("text", text);
          return <div>{text ? text : "-"}</div>;
        },
      },
      {
        title: "維修結果",
        dataIndex: "repairResult",
        render: (status, item) => {
          console.log("text, item", status);
          let statusTag = <>-</>;
          switch (status.id) {
            case "pending":
              statusTag = (
                <Tag key={item.id} className="tag">
                  {status.name}
                </Tag>
              );
              break;
            case "repairedImmediately":
              statusTag = (
                <Tag key={item.id} className="tag warning">
                  {status.name}
                </Tag>
              );
              break;
            case "notRepairedImmediately":
              statusTag = (
                <Tag key={item.id} className="tag danger">
                  {status.name}
                </Tag>
              );
              break;
            case "cancel":
              statusTag = (
                <Tag key={item.id} className="tag danger">
                  {status.name}
                </Tag>
              );
              break;
            default:
              statusTag = <>-</>;
              break;
          }
          return statusTag;
        },
      },
      {
        dataIndex: "action",
        render: (text, item) => {
          const onMenuItemClick = ({ key, item }) => {
            // console.log("item",item)
            // console.log("item.id",item.id)
            switch (key) {
              case "edit": {
                history.replace({
                  search: queryString.stringify({
                    ...query,
                    damageReport: item.id,
                  }),
                });
                // setCreateDamageReportDrawerParams({
                //   visible: true,
                //   isCreate: false,
                //   orderingItemId: orderingItemId,
                //   reportId: item.id,
                // });
                break;
              }
              default: {
                break;
              }
            }
          };
          const menu = (item) => {
            console.log("item", item);
            return (
              <Menu
                onClick={({ key }) => {
                  onMenuItemClick({ key, item: item });
                }}
              >
                <Menu.Item key="edit">編輯器材損毀報告</Menu.Item>
              </Menu>
            );
          };
          return (
            <Dropdown overlay={menu(item)} placement="bottomRight">
              <Button className="btn btn-secondary">
                <Icon type="ellipsis" />
              </Button>
            </Dropdown>
          );
        },
      },
    ];
  }, [orderingItemId]);

  const modeName = () => {
    switch (mode) {
      case "Device": {
        return "(貨物)";
      }
      case "Part": {
        return "(零件)";
      }
      //   case "Consumable": {
      //     return "消耗品";
      //   }
      default:
        return "";
    }
  };

  const onDrawerClose = () => {
    // setItems({});
    onClose();
  };

  useEffect(() => {
    if (query.damageReport)
      setCreateDamageReportDrawerParams({
        visible: true,
        isCreate: false,
        orderingItemId: orderingItemId,
        reportId: query.damageReport,
      });
  }, [orderingItemId, query.damageReport]);

  const [isCreate, setIsCreate] = useState(true);

  console.log("createDamageReportDrawerParams", createDamageReportDrawerParams);

  console.log("hi", orderingItemId);

  return (
    <Drawer
      className="ViewDamageReportsDrawer-Component"
      title={`查看器材損毀報告${modeName(mode)}`}
      placement="right"
      onClose={onDrawerClose}
      visible={visible}
      width="50vw"
    >
      <Spin spinning={loading}>
        {/* <h1>hello</h1> */}
        {createDamageReportDrawerParams?.visible && (
          <CreateDamageReportDrawer
            userId={userId}
            visible={createDamageReportDrawerParams?.visible}
            onClose={onCreateDamageReportsDrawerClose}
            onSubmit={onCreateDamageReportDrawerSubmit}
            // currentItems={orderingItem.map(({ product }) => product?.id)}
            isCreate={createDamageReportDrawerParams?.isCreate}
            orderingItemId={orderingItemId}
            userAddress={userAddress}
            // orderingData={data}
            reportId={createDamageReportDrawerParams?.reportId}
            // reports={reports}
            inventoryRecordId={inventoryRecordId}
          ></CreateDamageReportDrawer>
        )}
        {canCreate ? (
          <Button
            onClick={() =>
              setCreateDamageReportDrawerParams({
                ...createDamageReportDrawerParams,
                visible: true,
                isCreate: true,
                orderingItemId: orderingItemId,
              })
            }
          >
            新增器材損毀報告
          </Button>
        ) : (
          false
        )}
        <Table
          rowKey={(row) => row.id}
          pagination={false}
          dataSource={reports}
          columns={DamageReportsCol}
          scroll={{x:"max-content"}}
        ></Table>
      </Spin>
    </Drawer>
  );
}

ViewDamageReportsDrawer.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  // mode: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  // currentItems: PropTypes.array.isRequired,
  isCreate: PropTypes.bool.isRequired,
  orderingItemId: PropTypes.string,
  setCreateDamageReportDrawerParams: PropTypes.func,
  createDamageReportDrawerParams: PropTypes.object,
  onCreateDamageReportDrawerSubmit: PropTypes.func,
  onViewDamageReportsDrawerClose: PropTypes.func,
  userAddress: PropTypes.string,
  // reports: PropTypes.array,
  canCreate: PropTypes.bool.isRequired,
  inventoryRecordId: PropTypes.string.isRequired
};

ViewDamageReportsDrawer.defalutProps = {
  className: "",
};

export default ViewDamageReportsDrawer;
