import React, { useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import { useHistory } from "react-router-dom";

// Components
import QRCodeScanner from "@components/qrcodeScanner/qrcodeScanner";

// Constants
import { PAGE, PAGE_NAME } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

// Styles
import "./workshopScanner.scss";

// Utils
import { replaceUrlByParams } from "@utils/api";

import Text from "antd/lib/typography/Text";
import { Input, notification } from "antd";

const INVENTORY_RECORD_GET = gql`
  query inventoryRecordGet($referenceId: String) {
    inventoryRecordGet(referenceId: $referenceId) {
      id
      referenceId
    }
  }
`;

function WorkshopScanner(props) {
  const history = useHistory();
  const { Search } = Input;

  const BREADCRUMB = [
    {
      path: PAGE.WORKSHOP_SCANNER,
      name: PAGE_NAME.WORKSHOP_SCANNER,
    },
  ];

  const [scannerErrMsg, setScannerErrMsg] = useState(undefined);

  const destination = useMemo(
    () =>
      history.location.pathname === "/mobile"
        ? PAGE.MOBILE_INVENTORY_DETAIL
        : PAGE.DESKTOP_INVENTORY_DETAIL,
    [history.location.pathname]
  );

  const onGetInventoryRecordCompleted = (data) => {
    if (data?.inventoryRecordGet?.id) {
      history.push(
        replaceUrlByParams(destination, {
          id: getInventoryRecordData?.inventoryRecordGet?.id,
        })
      );
    } else {
      notification.error({ message: "不正確的器材編號" });
    }
  };

  const [
    getInventoryRecord,
    { data: getInventoryRecordData, loading: getInventoryRecordLoading },
  ] = useLazyQuery(INVENTORY_RECORD_GET, {
    onCompleted: onGetInventoryRecordCompleted,
  });

  const onScanSuccess = (data) => {
    try {
      const url = new URL(data);
      if (url.hostname === window.location.hostname) {
        const id = data.substring(data.lastIndexOf("/") + 1);
        history.push(replaceUrlByParams(destination, { id: id }));
      } else {
        setScannerErrMsg("不正確的QR Code");
      }
    } catch (err) {
      setScannerErrMsg("不正確的QR Code");
    }
  };

  const onEnterInventoryRecordId = (value) => {
    if (value) {
      try {
        getInventoryRecord({
          variables: {
            referenceId: value,
          },
        });
      } catch (err) {
        notification.error({ message: "不正確的器材編號" });
      }
    } else {
      notification.error({ message: "請輸入器材編號", duration: 3 });
    }
  };

  return (
    <StickyBreadcrumbPageComponent
      className="Workshop-Scanner-Page"
      breadcrumb={BREADCRUMB}
    >
      <div>
        <div>
          <Text style={{ display: "block" }}>
            放置 QR Code 於鏡頭中心或輸入器材編號
          </Text>
          <Search
            className="idInput"
            placeholder="請輸入器材編號"
            enterButton="確定"
            loading={getInventoryRecordLoading}
            onSearch={(value) => onEnterInventoryRecordId(value)}
          />
        </div>
        <div>{scannerErrMsg && <Text type="danger">{scannerErrMsg}</Text>}</div>
        <QRCodeScanner
          onScanSuccess={onScanSuccess}
          onError={() => setScannerErrMsg("找不到鏡頭，請於上方輸入器材編號")}
        />
        {/* <button
          onClick={() =>
            onScanSuccess(
              `https://${window.location.hostname}/console/#/stock_inventory/014ddd4d-fb0d-460b-a0dc-0854532858fb`
            )
          }
        >
          Debug Scan
        </button>
        <button onClick={() => onScanSuccess(`abc`)}>Debug Scan Invalid</button> */}
      </div>
    </StickyBreadcrumbPageComponent>
  );
}

export default WorkshopScanner;

WorkshopScanner.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
