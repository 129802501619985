import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import moment from "moment";
import "moment/locale/zh-hk";
import { useDispatch } from "react-redux";
import { useHistory, Route, Switch } from "react-router-dom";

// Actions
import { setIsLoadingAction } from "@actions/storeAction";
import { setIsLoggedInAction } from "@actions/storeAction";
import { setUserAction } from "@actions/storeAction";

// Components
import AppMainRouter from "./App.router";
import AppMobileRouter from "./App.mobileRouter";
import LoadingComponent from "@components/loading/loading";

// Constants
import { PAGE } from "@utils/constants";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

// Layouts
import BasePageComponent from "@layouts/basePage/basePage";
import MobileBasePageComponent from "@layouts/mobile/basePage/basePage";

// Pages
import LoginPage from "@pages/login/login";

// Style
import "@styles/my-theme.less";
import "./App.scss";

// Utils
import { getTokenHeader } from "@utils/api";
import { useIdleLogout } from "./utils/hook";

import { ConfigProvider } from "antd";
import zhTW from "antd/es/locale/zh_TW";

import queryString from "query-string";

library.add(fab, far, fas);

const STAFF_PROFILE = gql`
  {
    staffProfile {
      displayName
      tbId
      username
      accessLevel
    }
  }
`;

function App() {
  const dispatch = useDispatch();
  let history = useHistory();

  const [
    staffProfile,
    {
      called: staffProfileCalled,
      loading: staffProfileLoading,
      error: staffProfileError,
      data: staffProfileData,
    },
  ] = useLazyQuery(STAFF_PROFILE);

  const [isReady, setIsReady] = useState(false);

  const loginWithState = {
    pathname: "/login",
    search:
      "fromUrl=" +
      encodeURIComponent(
        queryString.parse(history.location.search).fromUrl ??
          `${history.location.pathname.substring(1)}${history.location.search}`
      ),
  };

  useEffect(() => {
    moment.locale("en");
    dispatch(setIsLoadingAction(true));

    if (getTokenHeader()) {
      staffProfile();
    } else {
      dispatch(setIsLoggedInAction(false));
      if (history.location.pathname !== "/login") history.push(loginWithState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (staffProfileError) {
      dispatch(setIsLoggedInAction(false));
      if (history.location.pathname !== "/login") history.push(loginWithState);
    } else {
      if (staffProfileData) {
        dispatch(setUserAction(staffProfileData.staffProfile));
        dispatch(setIsLoggedInAction(true));
      }
    }

    setIsReady(true);
    dispatch(setIsLoadingAction(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [
    staffProfileLoading,
    staffProfileError,
    staffProfileData,
    staffProfileCalled,
  ]);

  useIdleLogout();

  return (
    <ConfigProvider locale={zhTW}>
      <div className="App">
        <LoadingComponent />
        {isReady && (
          <Switch>
            <Route exact path={PAGE.LOGIN} component={LoginPage} />
            <Switch>
              <Route path="/mobile">
                <MobileBasePageComponent>
                  <AppMobileRouter />
                </MobileBasePageComponent>
              </Route>
              <Route
                path="/"
                render={() => (
                  <BasePageComponent>
                    <AppMainRouter />
                  </BasePageComponent>
                )}
              ></Route>
            </Switch>
          </Switch>
        )}
      </div>
    </ConfigProvider>
  );
}

export default App;
