/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import { Button, Drawer, Form, Input, notification, Typography } from "antd";

import { PAGE_NAME, ACCESS_LEVELS } from "@utils/constants";
import FormItem from "antd/lib/form/FormItem";
import { Controller, useForm } from "react-hook-form";
import TextArea from "antd/lib/input/TextArea";
import { useMutation } from "@apollo/client";
import { useAccessLevel } from "@utils/hook";

const CLEANINGSTAGE_CREATE = gql`
  mutation cleaningStageCreate($input: CleaningStageCreateInput) {
    cleaningStageCreate(input: $input) {
      id
      name
      remark
    }
  }
`;

const CLEANINGSTAGE_UPDATE = gql`
  mutation cleaningStageUpdate($input: CleaningStageUpdateInput) {
    cleaningStageUpdate(input: $input) {
      id
      name
      remark
    }
  }
`;

const CleaningStageEdit = ({
  cleaningStageDrawerParams,
  onClose = () => {},
  onSuccess = () => {},
}) => {
  const isEdit = cleaningStageDrawerParams?.stage?.id ? true : false;

  const { isMatched: isDenied } = useAccessLevel([
    ACCESS_LEVELS.WORKSHOP_MANAGEMENT,
  ]);

  const { control, errors, handleSubmit } = useForm({
    defaultValues: {
      ...cleaningStageDrawerParams.stage,
    },
  });

  const [
    cleaningStageCreate,
    { loading: cleaningStageCreateLoading },
  ] = useMutation(CLEANINGSTAGE_CREATE);
  const [
    cleaningStageUpdate,
    { loading: cleaningStageUpdateLoading },
  ] = useMutation(CLEANINGSTAGE_UPDATE);

  const isLoading = cleaningStageCreateLoading || cleaningStageUpdateLoading;

  const onSubmit = async (data) => {
    const cleaningStageUpsert = isEdit
      ? cleaningStageUpdate
      : cleaningStageCreate;
    try {
      await cleaningStageUpsert({
        variables: {
          input: { id: cleaningStageDrawerParams?.stage?.id, ...data },
        },
      });
      onClose();
      onSuccess();
      notification.success({
        message: `${isEdit ? "編輯" : "新增"}成功`,
        duration: 5,
      });
    } catch (err) {
      // console.log(err)
    }
  };

  return (
    <Drawer
      width="30%"
      title={
        isEdit
          ? PAGE_NAME.WORKSHOP_CLEANING_STAGE_EDIT
          : PAGE_NAME.WORKSHOP_CLEANING_STAGE_CREATE
      }
      visible={cleaningStageDrawerParams.visible}
      onClose={onClose}
    >
      <Form layout="horizontal">
        <FormItem required label="步驟名稱">
          <Controller
            as={<Input disabled={isDenied} />}
            name="name"
            control={control}
            rules={{ required: true }}
            defaultValue=""
          />
          {errors?.name && (
            <Typography.Text type="danger">請填寫步驟名稱</Typography.Text>
          )}
        </FormItem>
        <FormItem label="備註">
          <Controller
            as={<TextArea disabled={isDenied} />}
            name="remark"
            control={control}
            defaultValue=""
          />
          {errors?.remark && (
            <Typography.Text type="danger">請填寫備註</Typography.Text>
          )}
        </FormItem>
      </Form>
      {!isDenied && (
        <div className="btn-container">
          <Button className="btn btn-secondary" onClick={onClose}>
            取消
          </Button>
          <Button
            className="btn"
            type="primary"
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            儲存
          </Button>
        </div>
      )}
    </Drawer>
  );
};

CleaningStageEdit.propTypes = {
  cleaningStageDrawerParams: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default CleaningStageEdit;
