import { gql } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";
import { useEffect, useRef } from "react";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

// Actions
import {
  setIsLoggedInAction,
  setUserAction,
  setIsLoadingAction,
} from "@actions/storeAction";

// Utils
import { setTokenHeader } from "@utils/api";
import { useHistory, useLocation } from "react-router-dom";
import { notification } from "antd";
import { PAGE_SIZE } from "./constants";

export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const useMemoRef = (value) => {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};

export const usePopup = (defaultValues = null) => {
  const [popup, setPopup] = useState(
    defaultValues ?? {
      visible: false,
      params: {},
    }
  );

  const open = (params = {}) =>
    setPopup({
      visible: true,
      params,
    });

  const close = () =>
    setPopup({
      visible: false,
      params: {},
    });

  return [{ ...popup, onClose: close }, open, close];
};

export const useAccessLevel = (targetAccessLevels = []) => {
  const user = useSelector((state) => state.storeReducer?.user);
  const userAccessLevel = user?.accessLevel ?? [];

  const matchedAccessLevels = targetAccessLevels.reduce(
    (acc, curr) => (userAccessLevel.includes(curr) ? [...acc, curr] : [...acc]),
    []
  );

  const isMatched = !!matchedAccessLevels.length;
  // const isMatched = false;
  // const isMatched = true;

  return { isMatched, matchedAccessLevels };
};

export const useIdleLogout = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const LOGOUT = gql`
    mutation Logout {
      logout
    }
  `;
  const store = useSelector((state) => state.storeReducer);
  const { isLoggedIn } = store;

  const [
    logout,
    { loading: logoutLoading, error: logoutError, data: logoutData },
  ] = useMutation(LOGOUT);

  const onLogout = useCallback(async () => {
    try {
      dispatch(setIsLoadingAction(true));
      await logout();
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, logout]);

  const timeoutRef = useRef(null);
  const clear = useCallback(
    () => clearTimeout(timeoutRef.current),
    [timeoutRef]
  );
  const start = useCallback(
    () => (timeoutRef.current = setTimeout(onLogout, 60 * 60 * 1000)), // 60 minutes
    [onLogout]
  );

  const onUserActive = useCallback(() => {
    clear();
    if (isLoggedIn) start();
  }, [clear, isLoggedIn, start]);

  useEffect(() => {
    if (logoutData) {
      if (logoutData.logout) {
        notification.info({
          message: "你已閒置超過60分鐘，請重新登入",
        });
        setTokenHeader();
        dispatch(setIsLoggedInAction(false));
        dispatch(setUserAction());
        history.push("/login");
      }

      dispatch(setIsLoadingAction(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutLoading, logoutError, logoutData]);

  useEffect(() => {
    document.addEventListener("mousemove", onUserActive);
    document.addEventListener("mousedown", onUserActive);
    document.addEventListener("scroll", onUserActive);
    document.addEventListener("keydown", onUserActive);
    return () => {
      document.removeEventListener("mousemove", onUserActive);
      document.removeEventListener("mousedown", onUserActive);
      document.removeEventListener("scroll", onUserActive);
      document.removeEventListener("keydown", onUserActive);
    };
  }, [onUserActive]);

  useEffect(() => {
    onUserActive();
  }, [onUserActive]);

  useEffect(() => {
    return () => clear();
  }, [clear]);
};

export const useDisclosureParam = (param) => {
  const paramRef = useRef(param);
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = (_param) => {
    paramRef.current = _param;
    setIsOpen(true);
  };
  const onClose = () => {
    paramRef.current = param;
    setIsOpen(false);
  };
  const onToggle = (_param) => {
    paramRef.current = isOpen ? param : _param;
    setIsOpen((_) => !_);
  };
  return {
    param: paramRef.current,
    isOpen,
    onOpen,
    onClose,
    onToggle,
  };
};

/**
 * @param {{[key: string]: any}} customVariables - if a field's value is a function, it will be called, else it will be used as is
 * @returns {{size: number, offset: number, keyword: string, sort: string, reverseOrder: boolean, [key: string]: any}} ListVariables with customVariables
 */
export const useListVariables = (customVariables = {}) => {
  const location = useLocation();

  const customVariablesRef = useRef(customVariables);

  const listVariables = useMemo(() => {
    const queryParams = queryString.parse(location.search);
    const { keyword, page, sortField, sortOrder, ...others } = queryParams;

    const customVariablesObject = Object.entries(
      customVariablesRef.current ?? {}
    ).reduce((variable, [key, value]) => {
      variable[key] =
        typeof value === "function" ? value(queryParams[key]) : value;
      return variable;
    }, {});

    return {
      size: PAGE_SIZE,
      offset: (parseInt(page ?? 1) - 1) * PAGE_SIZE,
      keyword,
      sort: sortField,
      reverseOrder: sortOrder ? sortOrder === "descend" : undefined,
      ...others,
      ...customVariablesObject,
    };
  }, [location.search]);

  return listVariables;
};

export const useTabParam = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  return (defaultActiveKey) => ({
    activeKey: queryParams?.tabKey ?? defaultActiveKey,
    onChange: (activeKey) =>
      history.push({
        search: queryString.stringify({ ...queryParams, tabKey: activeKey }),
      }),
  });
};
