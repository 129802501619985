import React, { useEffect, useState, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

// Components
import { Table, Button, notification } from "antd";
import InputDiscountComponent from "@components/store/userDiscount/inputDiscount";

// Constants
import { PAGE, PAGE_NAME, ACCESS_LEVELS } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

// Styles
import "./userDiscount.scss";

// Utils
import { classList } from "@utils/common";
import { useAccessLevel } from "@utils/hook";

const USER_TIER_DISCOUNT_LIST = gql`
  query UserTierDiscount {
    userTierDiscountOnProduct {
      tier
      discountPercentage
    }
    userTierDiscountOnService {
      tier
      discountPercentage
    }
  }
`;

const UPDATE_USER_TIER_DISCOUNT = gql`
  mutation UpdateUserTierDiscount(
    $productDiscounts: [UserTierDiscountInput]!
    $serviceDiscounts: [UserTierDiscountInput]!
  ) {
    userTierDiscountUpdateOnProduct(input: $productDiscounts) {
      tier
      discountPercentage
    }
    userTierDiscountUpdateOnService(input: $serviceDiscounts) {
      tier
      discountPercentage
    }
  }
`;

function StoreUserDiscountPage(props) {
  const BREADCRUMB = [
    {
      path: PAGE.STOCK_USER_DISCOUNT,
      name: PAGE_NAME.STOCK_USER_DISCOUNT,
    },
  ];

  const columns = [
    {
      title: "用戶級別",
      dataIndex: "tier",
      render: (text) => {
        let grade = Math.abs(text);
        // https://talkbox.atlassian.net/browse/AG-422
        if (text === 101) {
          return <span>全費</span>;
        }
        if (text > 101) {
          const charCode = "A".charCodeAt(0) + (text - 102);
          grade = String.fromCharCode(charCode);
          return <span>資助類別{grade}</span>;
        }
        return <span>第{grade}級</span>;
      },
    },
    {
      title: "貨物折扣",
      dataIndex: "discountPercentageOnProduct",
      render: (text, userDiscount) =>
        !isDenied ? (
          <InputDiscountComponent
            tier={userDiscount.tier}
            discountPercentage={userDiscount.discountPercentageOnProduct}
            onChange={(value) => {
              upsertProductDiscounts(userDiscount.tier, value);
            }}
          />
        ) : (
          `${userDiscount.discountPercentageOnProduct}%`
        ),
    },
    {
      title: "服務折扣",
      dataIndex: "discountPercentageOnService",
      render: (text, userDiscount) =>
        !isDenied ? (
          <InputDiscountComponent
            tier={userDiscount.tier}
            discountPercentage={userDiscount.discountPercentageOnService}
            onChange={(value) =>
              upsertServiceDiscounts(userDiscount.tier, value)
            }
          />
        ) : (
          `${userDiscount.discountPercentageOnService}%`
        ),
    },
  ];

  const { data, loading } = useQuery(USER_TIER_DISCOUNT_LIST);

  const { isMatched: isDenied } = useAccessLevel([ACCESS_LEVELS.REFERRALS]);

  const [tableData, setTableData] = useState([]);
  const [productDiscounts, setProductDiscounts] = useState([]);
  const [serviceDiscounts, setServiceDiscounts] = useState([]);

  const [updateUserDiscounts, { loading: updateUserDiscountsLoading }] =
    useMutation(UPDATE_USER_TIER_DISCOUNT);

  const isLoading = loading || updateUserDiscountsLoading;

  const convertData = useCallback((json) => {
    let productJson =
      json.userTierDiscountOnProduct ??
      json.userTierDiscountUpdateOnProduct ??
      [];
    let serviceJson =
      json.userTierDiscountOnService ??
      json.userTierDiscountUpdateOnService ??
      [];
    productJson.forEach((userDiscount) => {
      userDiscount.discountPercentageOnProduct =
        userDiscount.discountPercentage;
    });
    serviceJson.forEach((userDiscount) => {
      userDiscount.discountPercentageOnService =
        userDiscount.discountPercentage;
    });

    return concatArray(productJson, serviceJson).sort((p, n) =>
      p.tier > n.tier ? 1 : -1
    );
  }, []);

  const concatArray = (arr1, arr2) => {
    return [
      ...arr1
        .concat(arr2)
        .reduce(
          (m, o) => m.set(o.tier, Object.assign(m.get(o.tier) || {}, o)),
          new Map()
        )
        .values(),
    ];
  };

  const upsertProductDiscounts = (tier, nValue) => {
    if (productDiscounts) {
      const nDiscount = parseFloat(nValue);
      if (!Number.isNaN(nDiscount)) {
        const arr = [{ tier: tier, discountPercentage: nDiscount }];
        setProductDiscounts(concatArray(productDiscounts, arr));
      }
    }
  };

  const upsertServiceDiscounts = (tier, nVAlue) => {
    if (serviceDiscounts) {
      const nDiscount = parseFloat(nVAlue);
      if (!Number.isNaN(nDiscount)) {
        const arr = [{ tier: tier, discountPercentage: nDiscount }];
        setServiceDiscounts(concatArray(serviceDiscounts, arr));
      }
    }
  };

  const handleSubmit = async (event) => {
    if (productDiscounts.length === 0 && serviceDiscounts.length === 0) {
      return;
    }
    try {
      await updateUserDiscounts({
        variables: {
          productDiscounts: productDiscounts,
          serviceDiscounts: serviceDiscounts,
        },
      });
      notification.success({
        message: "更新成功",
      });
    } catch (err) {
      notification.error({
        message: "更新失敗",
        description: err?.message,
      });
    }
  };

  const getClassList = () =>
    classList({
      "StoreUserDiscount-page": true,
    });

  useEffect(() => {
    if (data) {
      setTableData(data);
      setProductDiscounts(
        [...(data.userTierDiscountOnProduct || [])].map(
          ({ discountPercentage, tier }) => ({ discountPercentage, tier })
        )
      );
      setServiceDiscounts(
        [...(data.userTierDiscountOnService || [])].map(
          ({ discountPercentage, tier }) => ({ discountPercentage, tier })
        )
      );
    }
  }, [data]);

  return (
    <StickyBreadcrumbPageComponent
      className={getClassList()}
      breadcrumb={BREADCRUMB}
    >
      <div className="content-inner">
        <div className="save-btn">
          {!isDenied && (
            <Button type="primary" htmlType="submit" onClick={handleSubmit}>
              儲存
            </Button>
          )}
        </div>
        <Table
          loading={isLoading}
          rowKey="tier"
          columns={columns}
          dataSource={convertData(tableData)}
          pagination={false}
          scroll={{ x: "max-content" }}
        />
      </div>
    </StickyBreadcrumbPageComponent>
  );
}

export default StoreUserDiscountPage;
