/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Components
import ServiceOrderingItemsCartDrawer from "./serviceOrderingItemsCartDrawer";
import ServiceFilter from "@components/filter/serviceFilter/serviceFilter";
import CustPaginationComponent from "@components/custPagination/custPagination";
import PrescriptionRecordExpander from "@components/prescriptionRecordExpander/prescriptionRecordExpander";

// Styles
import "./createServiceOrderingItemsDrawer.scss";

// Utils
import {
  Button,
  Drawer,
  message,
  notification,
  Table,
  Tag,
  Typography,
} from "antd";
import { PAGE, PAGE_SIZE } from "@utils/constants";
import { replaceUrlByParams } from "@utils/api";
import { classList } from "@utils/common";
import queryString from "query-string";

const SERVICE_LIST = gql`
  query ServiceList(
    $categoryId: [String]
    $characteristicTag: [String]
    $referenceId: String
    $size: Int!
    $offset: Int!
  ) {
    serviceList(
      categoryId: $categoryId
      characteristicTag: $characteristicTag
      referenceId: $referenceId
      size: $size
      offset: $offset
    ) {
      id
      referenceId
      nameEng
      nameChi
      category {
        id
        name
        mainCategory {
          id
          name
        }
      }
      status {
        id
        name
      }
    }
  }
`;

const ORDERING_CREATE = gql`
  mutation OrderingItemBulkCreate($input: [OrderingItemCreateInput]) {
    orderingItemBulkCreate(inupt: $input) {
      id
    }
  }
`;

function CreateServiceOrderingItemsDrawer(props) {
  const { className, userId, visible, onClose, onSubmit, currentItems } = props;

  console.log("currentItems, CreateServiceOrderingItemsDrawer", currentItems);

  const columns = [
    {
      title: "服務編號",
      dataIndex: "referenceId",
      width: "20%",
      render: (text, service) => (
        <Link
          className="link"
          to={replaceUrlByParams(PAGE.STOCK_SERVICE_DETAIL, {
            id: service.id,
            mode: "view",
          })}
          style={{ textDecoration: "underline" }}
          target="_blank"
        >
          {text}
        </Link>
      ),
    },
    {
      title: "名稱",
      dataIndex: "nameChi",
      width: "30%",
    },
    {
      title: "分類",
      dataIndex: "category",
      width: "40%",
      render: (category) => (
        <>
          <div className="text">{category.name}</div>
          <div className="text">子類別：{category.mainCategory.name}</div>
        </>
      ),
    },
    // {
    //   title: "子類別",
    //   dataIndex: "category.mainCategory",
    //   render: (mainCategory) => <div className="text">{mainCategory.name}</div>,
    // },
    // {
    //     title: "狀態",
    //     dataIndex: "status",
    //     render: (status) => (
    //         {
    //             'inactive': <Tag className="tag warning" >{status.name}</Tag>,
    //             'readyForLease': <Tag className="tag success" >{status.name}</Tag>,
    //         }[status.id]
    //     )
    // },
    {
      dataIndex: "action",
      width: "10%",
      render: (text, service) =>
        service.status.id === "readyForLease" ? (
          <Button
            type="primary"
            shape="circle"
            icon="plus"
            size="small"
            onClick={() => {
              setItems((prev) => ({
                ...prev,
                [service.id]: {
                  ...service,
                  qty: (prev[service.id]?.qty || 0) + 1,
                },
              }));
              message.success("成功加到選取清單");
            }}
          />
        ) : (
          undefined
        ),
    },
  ];

  const [
    getServiceList,
    { loading: getServiceListLoading, data: getServiceListData },
  ] = useLazyQuery(SERVICE_LIST);

  const [orderingItemsCreate, { data: orderingItemsCreateData }] = useMutation(
    ORDERING_CREATE
  );

  const [query, setQuery] = useState({
    page: 1,
    pageSize: PAGE_SIZE,
    categoryId: undefined,
    characteristicTag: undefined,
    referenceId: undefined,
  });

  const [isCartDrawerShow, setIsCartDrawerShow] = useState(false);

  const [items, setItems] = useState({});

  const isMaxPage = useMemo(() => {
    return getServiceListData?.serviceList?.length <= PAGE_SIZE;
  }, [getServiceListData]);

  const serviceList = useMemo(() => {
    return (getServiceListData?.serviceList || []).slice(0, PAGE_SIZE);
  }, [getServiceListData]);

  const queryParamToArray = (x) => (x ? (!Array.isArray(x) ? [x] : x) : []);

  const fetchServiceList = useCallback(() => {
    const { page, pageSize, categoryId, characteristicTag, referenceId } = query;
    getServiceList({
      variables: {
        offset: +((page - 1) * pageSize) || 0,
        size: +(pageSize || PAGE_SIZE) + 1,
        categoryId: categoryId,
        characteristicTag: queryParamToArray(characteristicTag),
        referenceId: referenceId,
      },
    });

    // eslint-disable-next-line
  }, [query]);

  const onCartDrawerSubmit = useCallback(async () => {
    try {
      const input = Object.values(items).map((item) => {
        return {
          productId: item.id,
          quantity: item.qty,
          userId: userId,
        };
      });
      await orderingItemsCreate({
        variables: {
          input: input,
        },
      });
      onCartDrawerClose();
    } catch (error) {
      message.error(error.message);
    }

    // eslint-disable-next-line
  }, [userId, items]);

  useEffect(() => {
    if (visible) {
      fetchServiceList();
    }

    // eslint-disable-next-line
  }, [visible, fetchServiceList]);

  useEffect(() => {
    if (orderingItemsCreateData) {
      notification.success({
        message: "成功新增訂單項目",
      });
      setItems({});
      if (onSubmit) {
        onSubmit();
      }
    }

    // eslint-disable-next-line
  }, [orderingItemsCreateData]);

  const resetQuery = () => {
    setQuery({ page: 1, pageSize: PAGE_SIZE });
  };

  const onPageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  const onDrawerClose = () => {
    resetQuery();
    onClose();
  };

  const onCartDrawerClose = () => {
    setIsCartDrawerShow(false);
  };

  const getClassList = () =>
    classList({
      "CreateServiceOrderingItemsDrawer-Component": true,
      [className]: true,
    });

  return (
    <Drawer
      className={getClassList()}
      title="新增訂單項目資料（服務)"
      placement="right"
      onClose={onDrawerClose}
      visible={visible}
      width="90vw"
    >
      <PrescriptionRecordExpander
        userId={userId}
        isDisplay={true}
        items={items}
        setItems={setItems}
        currentItems={currentItems}
      />
      <ServiceFilter
        filterType={[""]}
        filterValue={query}
        onFilterSearch={() => fetchServiceList()}
        onResetFilter={() => resetQuery()}
        isFilterShow={true}
        onFilterValueChange={setQuery}
      />
      <div className="paginate">
        <Typography.Text>附合篩選條件貨物</Typography.Text>
        <CustPaginationComponent
          isMinPage={query.page === 1}
          isMaxPage={isMaxPage}
          page={query.page}
          // pageSize={query.pageSize || PAGE_SIZE}
          pageSize={PAGE_SIZE}
          onPageChange={onPageChange}
        />
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={serviceList}
        loading={getServiceListLoading}
        pagination={false}
        // scroll={{ x: "max-content" }}
      />
      <div className="btn-container" style={{ marginTop: "40px" }}>
        <Button
          className="btn bottom-pinned"
          type="primary"
          onClick={() => setIsCartDrawerShow(true)}
        >
          查看選取清單
        </Button>
      </div>
      <ServiceOrderingItemsCartDrawer
        visible={isCartDrawerShow}
        onClose={onCartDrawerClose}
        onSubmit={onCartDrawerSubmit}
        items={items}
        setItems={setItems}
      ></ServiceOrderingItemsCartDrawer>
    </Drawer>
  );
}

CreateServiceOrderingItemsDrawer.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  currentItems: PropTypes.array.isRequired,
};

CreateServiceOrderingItemsDrawer.defaultProps = {
  className: "",
  visible: false,
};

export default CreateServiceOrderingItemsDrawer;
