import React, { useCallback, useMemo } from "react";
import {
  MOMENT_DATETIME_FORMAT,
  PAGE,
  PAGE_NAME,
  PAGE_SIZE,
} from "../../../../utils/constants";
import PropTypes from "prop-types";
import SearchTable from "../../../../components/newTableComponent/list/searchTable";
import { Col, Row, Select, Tag, Typography } from "antd";
import TableFilter from "../../../../components/newTableComponent/filter/tableFilter";
import { Controller, useForm } from "react-hook-form";

import queryString from "query-string";
import { Link, useHistory, useLocation } from "react-router-dom";

import LZString from "lz-string";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

import { replaceUrlByParams } from "@utils/api";
import { useListVariables } from "../../../../utils/hook";
import moment from "moment";

const serviceBuyerTagList = gql`
  query serviceBuyerTagList {
    serviceBuyerTagList {
      id
      name
    }
  }
`;

const serviceBuyerList = gql`
  query serviceBuyerList(
    $serviceBuyerTagId: [String]
    $keyword: String
    $size: Int!
    $offset: Int!
    $sort: ServiceBuyerSort
    $reverseOrder: Boolean
  ) {
    serviceBuyerList(
      serviceBuyerTagId: $serviceBuyerTagId
      keyword: $keyword
      size: $size
      offset: $offset
      sort: $sort
      reverseOrder: $reverseOrder
    ) {
      id
      referenceId
      name
      isUser
      type
      telephone
      contactPerson
      serviceBuyerTag {
        id
        name
      }
      cleaningJobCount
      lastUpdateDate
    }
  }
`;

const serviceBuyerCount = gql`
  query serviceBuyerCount($serviceBuyerTagId: [String], $keyword: String) {
    serviceBuyerCount(serviceBuyerTagId: $serviceBuyerTagId, keyword: $keyword)
  }
`;

const BREADCRUMB = [
  {
    path: PAGE.THIRD_PARTY_CLEANING_BUYER_LIST,
    name: PAGE_NAME.THIRD_PARTY_CLEANING_BUYER_LIST,
  },
];

const BuyerListFilter = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = useMemo(
    () => queryString.parse(location.search),
    [location.search]
  );

  const defaultValues = {
    tags: [],
  };

  const { data, loading } = useQuery(serviceBuyerTagList);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      ...queryParams,
      ...defaultValues,
      tags: queryParams.tags
        ? LZString.decompressFromEncodedURIComponent(queryParams.tags).split(
            ","
          )
        : [],
    },
  });

  const onSearch = useCallback(
    (data) => {
      const stringTags = [...(data?.tags || [])].join(",");
      const param = {
        ...data,
        tags: stringTags
          ? LZString.compressToEncodedURIComponent(stringTags)
          : [],
      };

      history.push({
        search: queryString.stringify({
          ...queryParams,
          ...param,
          page: 1,
        }),
      });
    },
    [history, queryParams]
  );

  const onReset = useCallback(() => {
    reset(defaultValues);
    history.push({
      search: queryString.stringify({
        ...queryParams,
        ...defaultValues,
        page: 1,
      }),
    });
  }, [defaultValues, history, queryParams, reset]);

  return (
    <TableFilter onReset={onReset} onSearch={handleSubmit(onSearch)}>
      <Row>
        <Col>
          <Typography>標籤</Typography>
          <Controller
            control={control}
            name="tags"
            render={({ onChange, value }) => (
              <Select
                style={{ width: "100%" }}
                mode="multiple"
                value={value}
                onChange={onChange}
                loading={loading}
              >
                {data?.serviceBuyerTagList?.map(({ id, name }) => (
                  <Select.Option key={id}>{name}</Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
      </Row>
    </TableFilter>
  );
};

const BuyerList = (props) => {
  const { tags, keyword, ...listVariables } = useListVariables({
    tags: (tags) =>
      tags
        ? LZString.decompressFromEncodedURIComponent(tags).split(",")
        : undefined,
  });

  const { data: serviceBuyerListData, loading: serviceBuyerListLoading } =
    useQuery(serviceBuyerList, {
      variables: { ...listVariables, keyword, serviceBuyerTagId: tags },
    });

  const { data: serviceBuyerCountData, loading: serviceBuyerCountLoading } =
    useQuery(serviceBuyerCount, {
      variables: { keyword, serviceBuyerTagId: tags },
    });

  const tableLoading = serviceBuyerCountLoading || serviceBuyerListLoading;

  const columns = [
    {
      title: "機構／用戶名稱",
      dataIndex: "name",
      render: (name, record) => (
        <div>
          <Link
            to={replaceUrlByParams(PAGE.THIRD_PARTY_CLEANING_BUYER_EDIT, {
              id: record?.id,
            })}
          >
            {name}
          </Link>
          <div>
            <Typography.Text type="secondary" style={{ fontSize: "10px" }}>
              {record?.referenceId}
            </Typography.Text>
          </div>
        </div>
      ),
    },
    {
      title: "用戶電話",
      dataIndex: "telephone",
    },
    {
      title: "聯絡人姓名",
      dataIndex: "contactPerson",
    },
    {
      title: "標籤",
      dataIndex: "serviceBuyerTag",
      render: (tags) =>
        tags.map(({ id, name }, i) => <Tag key={i}>{name}</Tag>),
    },
    {
      title: "工作總數",
      dataIndex: "cleaningJobCount",
    },
    {
      title: "最後更新時間",
      dataIndex: "lastUpdateDate",
      sorter: true,
      sortOrder:
        listVariables.sort === "lastUpdateDate" &&
        (listVariables.reverseOrder ? "descend" : "ascend"),
      render: (timestamp) =>
        timestamp ? moment(timestamp).format(MOMENT_DATETIME_FORMAT) : "-",
    },
  ];

  const isMaxPage = useMemo(() => {
    return serviceBuyerListData?.serviceBuyerList?.length <= PAGE_SIZE;
  }, [serviceBuyerListData]);

  return (
    <SearchTable
      breadcrumb={BREADCRUMB}
      filter={<BuyerListFilter />}
      showFilterButton={false}
      filterVisible={true}
      searchInputProps={{ placeholder: "搜尋機構／用戶電話" }}
      createButton={{
        url: PAGE.THIRD_PARTY_CLEANING_BUYER_CREATE,
        text: "新增機構／用戶",
      }}
      tableProps={{
        columns,
        rowKey: "id",
        dataSource: serviceBuyerListData?.serviceBuyerList,
        loading: tableLoading,
        // pagination: {
        //   total: serviceBuyerCountData?.serviceBuyerCount,
        // },
      }}
      total={serviceBuyerCountData?.serviceBuyerCount}
      isMaxPage={isMaxPage}
    />
  );
};

export default BuyerList;

BuyerList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};
