import React from "react";
import PropTypes from "prop-types";

// Actions

// Components

// Layouts
import BasePage from "@layouts/basePage/basePage";

// Style

// Utils

function MobileBasePageComponent(props) {
  const { children } = props;
  return <BasePage navBarVisible={false}>{children}</BasePage>;
}

export default MobileBasePageComponent;

MobileBasePageComponent.propTypes = {
  children: PropTypes.node,
};

MobileBasePageComponent.defaultProps = {
  children: null,
};
